import React from 'react';
// import logo from '../BaseComponent/Menu/logo-mini.svg'
import logo from '../BaseComponent/Menu/Purna-School-Logo.svg'
import {ToastContainer} from 'react-toastify'


const Loader = () => {
    return (
        <>
            <div>

                <div style={{ top: "50%", left: "50%", position: "absolute", marginLeft: "-75px", marginTop: "-75px", textAlign: "center", width:"150px" }}>
                    <img src={logo} /><br />
                    {/* <div className="spinner-grow spinner-grow-sm text-muted"></div>
                    <div className="spinner-grow spinner-grow-sm text-primary"></div>
                    <div className="spinner-grow spinner-grow-sm text-success"></div>
                    <div className="spinner-grow spinner-grow-sm text-info"></div>
                    <div className="spinner-grow spinner-grow-sm text-warning"></div>
                    <div className="spinner-grow spinner-grow-sm text-danger"></div>
                    <div className="spinner-grow spinner-grow-sm text-secondary"></div> */}
                    <div className="spinner-grow spinner-grow-sm text-dark m-1"></div>
                    <div className="spinner-grow spinner-grow-sm text-dark m-1"></div>
                    <div className="spinner-grow spinner-grow-sm text-dark m-1"></div>
                    <div className="spinner-grow spinner-grow-sm text-dark m-1"></div>
                    <div className="spinner-grow spinner-grow-sm text-dark m-1"></div>
                    <div className="spinner-grow spinner-grow-sm text-dark m-1"></div>                    

                    {/* <div className="spinner-border text-primary" style={{ width: "2.5rem", height: "2.5rem" }} role="status">

                        <img src={logo} /><br />

                    </div> */}

                </div>
            </div>
            <ToastContainer position="top-right" theme="colored" />

        </>
    )
}

export default Loader;