import React, { useState } from 'react'

const ExamMarkEntryTableRow6 = ({ examMarkStatus, status, s1, s2, s3, s4, s5, s6, s1_max, s2_max, s3_max, s4_max, s5_max, s6_max, onChange }) => {

    const [statuss, setStatuss] = useState(status);
    const [s1text, sets1Text] = useState(s1);
    const [s2text, sets2Text] = useState(s2);
    const [s3text, sets3Text] = useState(s3);
    const [s4text, sets4Text] = useState(s4);
    const [s5text, sets5Text] = useState(s5);
    const [s6text, sets6Text] = useState(s6);
    const [disableText, setDisableText] = useState((status == 1) ? false : true);

    const handleTextBox = (num) => {
        sets1Text("");
        sets2Text("");
        sets3Text("");
        sets4Text("");
        sets5Text("");
        sets6Text("");
        if (num == 1) {
            setDisableText(false);
        }
        else if (num == 2) {
            setDisableText(true);
        }
    }

    return (
        <>
            <td>
                <select
                    disabled={(examMarkStatus == 1) ? true : false}
                    value={statuss}
                    onChange={e => {
                        setStatuss(e.target.value);
                        handleTextBox(e.target.value);
                    }}
                    onBlur={() => { onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) : ""}
                >
                    <option value={1}>Present</option>
                    <option value={2}>Absent</option>
                </select>
            </td>
            <td>
                <input
                    type='number'
                    disabled={disableText || examMarkStatus == 1}
                    style={{ width: "50px", fontSize: "14px", fontWeight: "600" }}
                    onBlur={() => { onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) }}
                    onChange={(e) => sets1Text((e.target.value != "") ? e.target.value : "")}
                    value={s1text}
                    min="0"
                    max={s1_max}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
            <td>
                <input
                    type='number'
                    disabled={disableText || examMarkStatus == 1}
                    style={{ width: "50px", fontSize: "14px", fontWeight: "600" }}
                    onBlur={() => { onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) }}
                    onChange={(e) => sets2Text((e.target.value != "") ? e.target.value : "")}
                    value={s2text}
                    min="0"
                    max={s2_max}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
            <td>
                <input
                    type='number'
                    disabled={disableText || examMarkStatus == 1}
                    style={{ width: "50px", fontSize: "14px", fontWeight: "600" }}
                    onBlur={() => { onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) }}
                    onChange={(e) => sets3Text((e.target.value != "") ? e.target.value : "")}
                    value={s3text}
                    min="0"
                    max={s3_max}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
            <td>
                <input
                    type='number'
                    disabled={disableText || examMarkStatus == 1}
                    style={{ width: "50px", fontSize: "14px", fontWeight: "600" }}
                    onBlur={() => { onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) }}
                    onChange={(e) => sets4Text((e.target.value != "") ? e.target.value : "")}
                    value={s4text}
                    min="0"
                    max={s4_max}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
            <td>
                <input
                    type='number'
                    disabled={disableText || examMarkStatus == 1}
                    style={{ width: "50px", fontSize: "14px", fontWeight: "600" }}
                    onBlur={() => { onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) }}
                    onChange={(e) => sets5Text((e.target.value != "") ? e.target.value : "")}
                    value={s5text}
                    min="0"
                    max={s5_max}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
            <td>
                <input
                    type='number'
                    disabled={disableText || examMarkStatus == 1}
                    style={{ width: "50px", fontSize: "14px", fontWeight: "600" }}
                    onBlur={() => { onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) }}
                    onChange={(e) => sets6Text((e.target.value != "") ? e.target.value : "")}
                    value={s6text}
                    min="0"
                    max={s6_max}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text, s2text, s3text, s4text, s5text, s6text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
        </>
    )
}

export default ExamMarkEntryTableRow6