import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import PrintIcon from '@mui/icons-material/Print';

const BudgetExternalExamConsolidatedReport = ({ futureYear, printAllBudgetEntryReport, externalExamReceiptData, externalExamPaymentData,
    totalExternalExamReceiptAmt, totalExternalExamPaymentAmt }) => {
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    let differenceAmt = parseFloat(totalExternalExamReceiptAmt) - parseFloat(totalExternalExamPaymentAmt);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div className="">
                        <form>
                            <div className="row mt-5 mb-2" >
                                <div className="">
                                    {/* <div style={{ float: "left", }}>
                                        <span className='mt-3' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{schoolNm}</span><br />
                                        <span className='mt-1' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>शाळा समिती ठराव नंबर आणि तारीख : <span style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{(resolutionNo != '' && resolutionDate != '') ? `${resolutionNo} / ${moment(resolutionDate).format("DD-MM-YYYY")}` : ''}</span></span>
                                    </div> */}
                                    <div style={{ float: "right" }}>
                                        <button
                                            style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                            type='button'
                                            onClick={() => printAllBudgetEntryReport()}
                                            className='btn btn-sm mb-2'
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                    <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span className='mt-1' style={{ fontSize: "16px", color: "#DC2C0C", fontWeight: "600", justifyContent: "center" }}>शाळा बाह्य परीक्षा विभाग, अंदाजपत्रक  {parseInt(authUser.sessionYear) + 1}-{parseInt(futureYear) + 1}</span>
                                    </div>
                                </div>
                            </div>


                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>

                                            <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">तपशील (जमा)</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">संख्या</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">शुल्क</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">रक्कम</th>

                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            externalExamReceiptData.map((item, index) => {
                                                let totalstudentsWithFee = parseFloat(item.studentCount) * parseFloat(item.fee);
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.accNameMr}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{item.studentCount}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{parseFloat(item.fee).toFixed('2')}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{parseFloat(totalstudentsWithFee).toFixed('2')}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "600", }}>एकूण</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}></td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}></td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{(totalExternalExamReceiptAmt).toFixed('2')}</td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>

                            <div div className='table-responsive mb-5' >
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>

                                            <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">तपशील (खर्च)</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">रक्कम</th>

                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            externalExamPaymentData.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.accNameMr}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{parseFloat(item.budgetNextYear).toFixed('2')}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "600", }}>एकूण</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{(totalExternalExamPaymentAmt).toFixed('2')}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">तपशील</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">किंमत</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण जमा रुपये</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(totalExternalExamReceiptAmt).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण खर्च रुपये</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(totalExternalExamPaymentAmt).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>फरक रुपये</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{parseFloat(differenceAmt).toFixed('2')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}

export default BudgetExternalExamConsolidatedReport