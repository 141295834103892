import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from "@mui/material/Paper";
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getClassesBySectionAndBranch, getDivisionsByClassAndSectionAndBranch } from "../Services/ExamMarkAllocationAPIURL";
import { useNavigate } from 'react-router-dom';
import { getSubjectList, saveSubjectListForExam, getFinalExamList, saveFinalExamList, saveGroupSettingAPIURL, getGroupSettingDataAPIURL, updateGroupSettingAPIURL, deleteGroupSubjectAPIURL } from '../Services/ExamSettingsAPIURL';
import { getSubjectData, getExamData } from '../Services/ExamReportAPIURL';
import SubjectSettingTextBox from './ExamSettingsTextBoxes/SubjectSettingTextBox';
import SscGradeMasterTable from './SscGradeMasterTable';
import { getAllExamGrades, saveExamGrade, updateExamGrade } from '../Services/ExamSettingsAPIURL';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { BaseURLInstituteMaster, BaseURLSection } from '../../CommonServices/InstituteMasterAPIURL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GroupSettingTable from './ExamSettingsTextBoxes/GroupSettingTable';
import GroupSettingModal from './ExamResultSettingModalBoxes/GroupSettingModal';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const termJSON = [
    {
        id: 1,
        nameMr: "प्रथम सत्र",
        nameEn: "First Term"
    },
    {
        id: 2,
        nameMr: "द्वितीय सत्र",
        nameEn: "Second Term"
    },

]

const viewTypeData = [
    {
        id: 1,
        viewTypeName: "Non"
    },
    {
        id: 2,
        viewTypeName: "And"
    },
    {
        id: 3,
        viewTypeName: "Or"
    }
]

const markTypeData = [
    {
        id: 1,
        markTypeName: "Mark"
    },
    {
        id: 2,
        markTypeName: "Grade"
    },
    // {
    //     id: 2,
    //     markTypeName: "SSC Grade"
    // },
    // {
    //     id: 3,
    //     markTypeName: "FS Grade"
    // }
]


const ExamResultSetting = () => {
    const classes = useStyles();
    const titleId = "Exam Result Setting";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveGroupBtnStyle = "btn btn-primary btn-sm";
    const updateGroupBtnStyle = "btn btn-primary btn-sm";
    const displayGroupNoneBtnStyle = "d-none";
    const [saveGroupBtn, setSaveGroupBtn] = useState(saveGroupBtnStyle);
    const [updateGroupBtn, setUpdateGroupBtn] = useState(displayGroupNoneBtnStyle);




    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `Grade Name Mr`, key: 'nameMr' }, { label: `Grade Name En`, key: 'nameEn' }, { label: 'From', key: 'markFrom' }, { label: 'To', key: 'markTo' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];

    const grouTableHeading = [{ label: 'Sr No', key: 'id' }, { label: `Group Name Mr`, key: 'groupNameMr' }, { label: `Group Name En`, key: 'groupNameEn' }, { label: 'Min. Mark', key: 'minPassingMark' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }]

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState("");
    const [classData, setClassData] = useState([]);
    const [divData, setDivData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [divObj, setDivObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [examList, setExamList] = useState([]);
    const [examData, setExamData] = useState([]);
    const [checkValue, setCheckValue] = useState([]);

    const [gradeId, setGradeId] = useState("");
    const [englishName, setEnglishName] = useState("");
    const [marathiName, setMarathiName] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [divisionOptObj, setDivisionOptObj] = useState(1);

    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [bMedium, setBMedium] = useState(1);
    const [branchData, setBranchData] = useState([]);
    const [branchObj, setBranchObj] = useState(null);

    const [groupId, setGroupId] = useState('');
    const [groupNameMr, setGroupNameMr] = useState("");
    const [groupNameEn, setGroupNameEn] = useState("");
    const [passMinMark, setPassMinMark] = useState("");
    const [groupSubjectData, setGroupSubjectData] = useState([]);
    const [groupSubjectObj, setGroupSubjectObj] = useState(null);
    const [subMinMark, setSubMinMark] = useState("");
    const [subTableData, setSubTableData] = useState([]);
   
    const [groupTableData, setGroupTableData] = useState([]);
    console.log(JSON.stringify(groupTableData))
    const [groupTableFilterData, setGroupTableFilterData] = useState([]);

    const [openAccordion, setOpenAccordion] = useState(0);

    const handleAccordionToggle = (index) => {
        clearGroupData();
        clearData();
        setOpenAccordion(openAccordion === index ? 0 : index);
    };

    //console.log(JSON.stringify(checkValue))
    const handleCheckChange = (val, cId, divId, sessionYr, Bid, Sid, exId, isAllocate) => {

        if (val == true) {
            const SaveObjectData = {
                classId: cId,
                divisionId: divId,
                examId: exId,
                acYear: sessionYr,
                sectionId: departmentObj.id,
                branchId: branchObj.id,
                branchMedium: bMedium,
                isAllocated: 1
            }
            //console.log("Class Div Data=" + JSON.stringify(SaveObjectData))
            setCheckValue(checkValue.concat(SaveObjectData));
        }
        else if (val == false) {
            const removedData = checkValue.filter((item) => item.examId != exId)
            setCheckValue(removedData);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        await axios(`${getSubjectData}`)
            .then((response) => {
                //console.log(JSON.stringify(response.data))
                setSubjectList(response.data);

            }).catch((error) => {
                console.log(error);
            })

        await axios(`${getExamData}`)
            .then((response) => {
                setExamData(response.data);

            }).catch((error) => {
                console.log(error);
            })

        await axios.get(getAllExamGrades)
            .then((response) => {
                setData(response.data);
                setFilterData(response.data);
            })

        await axios.get(BaseURLInstituteMaster)
            .then(res => {
                setBranchData(res.data);
            })

        await axios.get(`${BaseURLSection}`)
            .then((response) => {
                //const sortData = (response.data).filter(e => e.acApl === 0)
                setDepartmentData(response.data);
            })

    }

    const getClassData = async (dId) => {
        if (branchObj != null) {
            //console.log(`${getClassesBySectionAndBranch}?sid=${dId}&bid=${branchObj.id}`)
            await axios(`${getClassesBySectionAndBranch}?sid=${dId}&bid=${branchObj.id}`)
                .then((response) => {
                    setClassData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const getDivision = async (cId) => {
        await axios(`${getDivisionsByClassAndSectionAndBranch}?cid=${cId}&sid=${departmentObj.id}&bid=${branchObj.id}`)
            .then((response) => {
                setDivData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    //acYear=2021&sid=3&bid=1&classId=10&divId=39&termId=3

    const getResultProcess = async (e) => {
        setLoaderOption(true);
        //if (e) {
        e.preventDefault();
        // Your event handling code here
        //}
        setFilteredData([]);
        setSubjectData([]);
        setExamList([]);
        setCheckValue([]);
        setGroupTableData([]);
        setGroupTableFilterData([]);
        setOpenAccordion(0);
        clearData();
        clearGroupData();

        console.log(`${getSubjectList}?acYear=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&classId=${classObj.id}&divId=${divObj.id}&branchMedium=${bMedium}`)
        await axios(`${getSubjectList}?acYear=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&classId=${classObj.id}&divId=${divObj.id}&branchMedium=${bMedium}`)
            .then((response) => {
                //console.log("Subject Data==" + JSON.stringify(response.data))
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Subjects are not allocated to this class-division.");
                }
                else {

                    let updatedData = response.data;
                    response.data.map((e, index) => {
                        if (e.subjectPrintingNameMr == "" || e.subjectPrintingNameEn == "") {
                            updatedData[index] = ({ ...e, sectionId: departmentObj.id, branchMedium: bMedium, subjectPrintingNameMr: e.subjectNameMr, subjectPrintingNameEn: e.subjectNameEn })
                        }
                    })
                    //console.log("updatedData=====" + JSON.stringify(updatedData))
                    setSubjectData(updatedData);
                    setFilteredData(updatedData);
                    setGroupSubjectData(updatedData);
                    setLoaderOption(false);
                }

            }).catch((error) => {
                console.log(error);
            })

        console.log(`${getFinalExamList}?acYear=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&classId=${classObj.id}&divId=${divObj.id}&branchMedium=${bMedium}`)
        await axios(`${getFinalExamList}?acYear=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&classId=${classObj.id}&divId=${divObj.id}&branchMedium=${bMedium}`)
            .then((response) => {
                //console.log("Exam Data==" + JSON.stringify(response.data))
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Exams are not allocated to this class-division.");
                }
                else {
                    setExamList(response.data);

                    //singleChecked data
                    let SaveObj = (response.data).filter(e => {
                        return e.isAllocated == 1
                    })
                    let updatedData = SaveObj;
                    SaveObj.map((e, index) => {
                        updatedData[index] = ({ ...e, sectionId: departmentObj.id, branchMedium: bMedium, branchId: branchObj.id })
                    })
                    setCheckValue(checkValue.concat(updatedData));
                    setLoaderOption(false);
                }

            }).catch((error) => {
                console.log(error);
            })

        console.log(`${getGroupSettingDataAPIURL}?year=${authUser.sessionYear}&sid=${departmentObj.id}&classId=${classObj.id}&divId=${divObj.id}&branchMedium=${bMedium}`)
        await axios(`${getGroupSettingDataAPIURL}?year=${authUser.sessionYear}&sid=${departmentObj.id}&classId=${classObj.id}&divId=${divObj.id}&branchMedium=${bMedium}`)
            .then((response) => {
                //console.log("Exam Data==" + JSON.stringify(response.data))
                setGroupTableData(response.data);
                setGroupTableFilterData(response.data);

            }).catch((error) => {
                console.log(error);
            })

    }

    const retrieveData = async () => {
        setLoaderOption(true);
        setExamList([]);
        setCheckValue([]);
        //console.log(`${getFinalExamList}?acYear=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&classId=${classObj.id}&divId=${divObj.id}&termId=${termObj.id}&branchMedium=${bMedium}`)
        await axios(`${getFinalExamList}?acYear=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&classId=${classObj.id}&divId=${divObj.id}&branchMedium=${bMedium}`)
            .then((response) => {
                //console.log("Exam Data==" + JSON.stringify(response.data))
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Exams are not allocated to this class-division.");
                }
                else {
                    setExamList(response.data);

                    //singleChecked data
                    let SaveObj = (response.data).filter(e => {
                        return e.isAllocated == 1
                    })
                    let updatedData = SaveObj;
                    SaveObj.map((e, index) => {
                        updatedData[index] = ({ ...e, sectionId: departmentObj.id, branchMedium: bMedium, branchId: branchObj.id })
                    })
                    setCheckValue(updatedData);
                    setLoaderOption(false);
                }

            }).catch((error) => {
                console.log(error);
            })

        await axios(`${getGroupSettingDataAPIURL}?year=${authUser.sessionYear}&sid=${departmentObj.id}&classId=${classObj.id}&divId=${divObj.id}&branchMedium=${bMedium}`)
            .then((response) => {
                //console.log("Exam Data==" + JSON.stringify(response.data))
                setGroupTableData(response.data);
                setGroupTableFilterData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    const saveSubjectsForResult = () => {
        setLoaderOption(true);
        //console.log(JSON.stringify(subjectData));
        axios.post(`${saveSubjectListForExam}?allDivision=${divisionOptObj}`, subjectData)
            .then((response) => {
                //console.log(response.data);
                if (response.data == "SAVED") {
                    setFilteredData([]);
                    setSubjectData([]);
                    setExamList([]);
                    setDivisionOptObj(1);
                    setLoaderOption(false);
                    toast.success("Subject data updation successfully done.");
                }
            })
            .catch(error => {
                console.log("Update Fire Query: " + error)
            })
    }

    const saveData = () => {
        //console.log(JSON.stringify(checkValue));
        setLoaderOption(true);
        if (checkValue.length === 0) {
            let removeAllObjects = [{
                classId: classObj.id,
                divisionId: divObj.id,
                examId: 0,
                acYear: authUser.sessionYear,
                sectionId: departmentObj.id,
                branchId: branchObj.id,
                branchMedium: bMedium,
                isAllocated: 0
            }]
            axios.post(`${saveFinalExamList}?allDivision=${divisionOptObj}`, removeAllObjects)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        retrieveData();
                        setExamList([]);
                        setCheckValue([]);
                        setLoaderOption(false);
                        toast.success("Exam setting successfully done.");

                    } else {
                        setLoaderOption(false);
                        toast.error("Operation failed.");
                    }
                }).catch((error) => {
                    setLoaderOption(false);
                    console.log("error name: " + error);
                    toast.error("Something went wrong..please check.");
                });
        }
        else {
            console.log(JSON.stringify(checkValue));
            axios.post(`${saveFinalExamList}?allDivision=${divisionOptObj}`, checkValue)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        retrieveData();
                        setExamList([]);
                        setCheckValue([]);
                        setLoaderOption(false);
                        toast.success("Exam setting successfully done.");

                    } else {
                        setLoaderOption(false);
                        toast.error("Operation failed.");
                    }
                }).catch((error) => {
                    setLoaderOption(false);
                    console.log("error name: " + error);
                    toast.error("Something went wrong..please check.");
                });
        }
    }

    const saveBtnClick = () => {
        setLoaderOption(true);
        const saveObject = {
            id: gradeId,
            nameMr: marathiName,
            nameEn: englishName,
            markFrom: from,
            markTo: to
        };
        //console.log(JSON.stringify(saveObject))
        axios.post(`${saveExamGrade}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setOpenAccordion(1);
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });

        setMarathiName("");
        setEnglishName("");
        setGradeId("");
        setFrom("");
        setTo("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const updateBtnClick = () => {
        setLoaderOption(true);
        const updateObject = {
            id: gradeId,
            nameMr: marathiName,
            nameEn: englishName,
            markFrom: from,
            markTo: to
        };
        //console.log(JSON.stringify(updateObject))
        axios.put(`${updateExamGrade}` + gradeId, updateObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setOpenAccordion(1);
                    setLoaderOption(false);
                    toast.success("Update successfully.");
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });
        setMarathiName("");
        setEnglishName("");
        setGradeId("");
        setFrom("");
        setTo("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const clearData = () => {
        setMarathiName("");
        setEnglishName("");
        setGradeId("");
        setFrom("");
        setTo("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const getGroupSubTableData = () => {
        setGroupSubjectObj(null);
        setSubMinMark('');
        //setSubMinMark([]);
        const saveTableGroupObj = {
            subjectId: groupSubjectObj.subjectId,
            nameMr: groupSubjectObj.subjectNameMr,
            nameEn: groupSubjectObj.subjectNameEn,
            minMark: subMinMark
        }
        setSubTableData(subTableData.concat(saveTableGroupObj))
    }

    const clearGroupData = () => {
        setGroupNameMr("");
        setGroupNameEn("");
        setPassMinMark('');
        setSubTableData([]);
        setGroupSubjectObj(null);
        setSubMinMark('');
        setSaveGroupBtn(saveGroupBtnStyle);
        setUpdateGroupBtn(displayGroupNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const saveGroupSettingData = () => {
        setLoaderOption(true);
        const saveGroupObj = {
            "classId": classObj.id,
            "divisionId": divObj.id,
            "groupNameMr": groupNameMr,
            "groupNameEn": groupNameEn,
            "minPassingMark": passMinMark,
            "groupSubTableData": subTableData,
            "sessionYear": authUser.sessionYear,
            "sectionId": departmentObj.id,
            "branchMedium": bMedium
        }
        console.log(JSON.stringify(saveGroupObj))

        axios.post(`${saveGroupSettingAPIURL}?allDivision=${divisionOptObj}`, saveGroupObj)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    // setFilteredData([]);
                    // setSubjectData([]);
                    // setExamList([]);
                    // setDivisionOptObj(1);
                    setOpenAccordion(3);
                    retrieveData();
                    setGroupNameMr("");
                    setGroupNameEn("");
                    setPassMinMark('');
                    setSubTableData([]);
                    setGroupSubjectObj(null);
                    setSubMinMark('');
                    setLoaderOption(false);
                    toast.success("Group setting successfully done.");

                }
                else if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("This group is already exist.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            }).catch((error) => {
                setLoaderOption(false);
                console.log("error name: " + error);
                toast.error("Something went wrong..please check.");
            });
    }

    const updateGroupSettingData = () => {
        setLoaderOption(true);
        const updateGroupObj = {
            "id": groupId,
            "classId": classObj.id,
            "divisionId": divObj.id,
            "groupNameMr": groupNameMr,
            "groupNameEn": groupNameEn,
            "minPassingMark": passMinMark,
            "groupSubTableData": subTableData,
            "sessionYear": authUser.sessionYear,
            "sectionId": departmentObj.id,
            "branchMedium": bMedium
        }
        console.log(JSON.stringify(updateGroupObj))

        axios.put(`${updateGroupSettingAPIURL}?allDivision=${divisionOptObj}`, updateGroupObj)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    // setFilteredData([]);
                    // setSubjectData([]);
                    // setExamList([]);
                    //setDivisionOptObj(1);
                    setOpenAccordion(3);
                    retrieveData();
                    clearGroupData();
                    setLoaderOption(false);
                    toast.success("Group setting updation successfully done.");

                }
                else if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("This group is already exist.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            }).catch((error) => {
                setLoaderOption(false);
                console.log("error name: " + error);
                toast.error("Something went wrong..please check.");
            });
    }

    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleShowModal = (id) => {
        setShowModal(true);
        setDeleteId(id);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const DeleteData = (item) => {
        setLoaderOption(true);
        axios.delete(`${deleteGroupSubjectAPIURL}?id=${deleteId.id}`)
            .then((response) => {
                if (response.data != null) {
                    // setFilteredData([]);
                    // setSubjectData([]);
                    // setExamList([]);
                    // setDivisionOptObj(1);
                    //console.log(JSON.stringify(subTableData))
                    const newItem = [].concat(deleteId); // Assuming `item` is an array
                    //console.log(JSON.stringify(newItem))
                    const output = subTableData.filter(element => !newItem.some(item => item.id === element.id));

                    console.log(JSON.stringify(output));

                    setSubTableData(output)

                    getData();
                    setOpenAccordion(3);
                    setShowModal(false);
                    setLoaderOption(false);
                    toast.success("Deleted successfully");
                    console.log("Deleted");
                }
            });
    }


    const onGroupSubmit = e => {
        e.preventDefault();
        e.stopPropagation()
        if (state.button === 3) {
            saveGroupSettingData();
        }
        if (state.button === 4) {
            updateGroupSettingData();
        }
    }


    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '600px' }}>
                    {/* Form Data start */}
                    <form onSubmit={getResultProcess}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row">
                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Branch :</label>
                                <div className="col-sm-7">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={branchData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setBranchObj(newValue);
                                            setClassObj(null);
                                            setDivObj(null);
                                            setSubjectData([]);
                                            setExamList([]);
                                            setCheckValue([]);
                                            setFilteredData([]);
                                            setDivisionOptObj(1);
                                            setBMedium(1);
                                            setDepartmentObj(null);
                                        }}
                                        value={branchObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Branch" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className='col-sm-1' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Department:</label>
                                <div className="col-sm-3 ">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={departmentData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setDepartmentObj(newValue);
                                            setClassObj(null);
                                            setDivObj(null);
                                            setSubjectData([]);
                                            setExamList([]);
                                            setCheckValue([]);
                                            setFilteredData([]);
                                            setDivisionOptObj(1);
                                            setBMedium(1);
                                            if (newValue != null) {
                                                getClassData(newValue.id)
                                            }
                                        }}
                                        value={departmentObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Department" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Medium:</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin="dense">
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Medium</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={bMedium}
                                            label="Medium"
                                            onChange={(e) => {
                                                setBMedium(e.target.value);
                                                setClassObj(null);
                                                setDivObj(null);
                                                setSubjectData([]);
                                                setExamList([]);
                                                setCheckValue([]);
                                                setFilteredData([]);
                                                setDivisionOptObj(1);
                                            }}
                                        >
                                            <MenuItem value={1}>Marathi</MenuItem>
                                            <MenuItem value={2}>English</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class:</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={classData}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (bMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        value={classObj}
                                        onChange={(event, newValue) => {
                                            setClassObj(newValue);
                                            setDivObj(null);
                                            setSubjectData([]);
                                            setExamList([]);
                                            setCheckValue([]);
                                            setFilteredData([]);
                                            setDivisionOptObj(1);
                                            if (newValue != null) {
                                                getDivision(newValue.id);
                                            }
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Class" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Division:</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={divData}
                                        value={divObj}
                                        onChange={(event, newValue) => {
                                            setDivObj(newValue);
                                            if (newValue == null) {
                                                setSubjectData([]);
                                                setExamList([]);
                                                setCheckValue([]);
                                                setFilteredData([]);
                                                setDivisionOptObj(1);
                                            }
                                            //setTermObj(null);
                                        }}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (bMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option: </label>
                                <div className="col-sm-3">
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel id="demo-simple-select-label" size="small" style={{ fontSize: "14px" }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            labelId="demo-simple-select-label"
                                            required
                                            id="demo-simple-select"
                                            size='small'
                                            value={divisionOptObj}
                                            label="Select Option"
                                            onChange={(event) => {
                                                setDivisionOptObj(event.target.value);
                                                setSubjectData([]);
                                                setExamList([]);
                                                setCheckValue([]);
                                                setFilteredData([]);
                                            }}
                                        >
                                            <MenuItem value={1}>All Division</MenuItem>
                                            <MenuItem value={2}>Division Wise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-sm-4 offset-sm-1">
                                    <button type="submit" className="btn btn-primary btn-sm">Process Result</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <hr />

                    {(subjectData == null || subjectData == "" || examList == null || examList == "")
                        ?
                        <h4 className='mt-3'>No records found, Select Class-Division</h4>
                        :
                        <>
                            <div >
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne"
                                            style={{
                                                backgroundColor: "#d9e4f5",
                                                backgroundImage: "linear-gradient(315deg, #d9e4f5 0%, #f5e3e6 74%)"
                                            }}
                                        >
                                            <h5 className="mb-0">
                                                <a className="d-block w-100 collapsed"
                                                    data-toggle="collapse"
                                                    href="#collapseOne"
                                                    onClick={() => handleAccordionToggle(0)}
                                                    aria-expanded={openAccordion === 0 ? 'true' : 'false'}
                                                >
                                                    Exam Settings
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className={`collapse ${openAccordion === 0 ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body">
                                                <div className='table-responsive'
                                                    style={{
                                                        backgroundColor: "#98fcbd",
                                                        backgroundImage: "linear-gradient(315deg, #98fcbd 0%, #9cdaf8 74%)",
                                                        color: "#fff", boxShadow: "2px 5px 10px #7F8C8D",
                                                        maxWidth: "800px",

                                                    }}
                                                >
                                                    <table className="table table-borderless " >
                                                        <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", borderBottom: "2px solid #464de4" }}>
                                                            <tr>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Exam Code</th>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Exam Name</th>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="table-sm" style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                            {examList.map((item, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <tr >
                                                                            <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "25px" }}>{item.examId}</td>
                                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                                {examData.map(e => {
                                                                                    if (e.id == item.examId) {
                                                                                        return (bMedium == 1) ? e.nameMr : e.nameEn
                                                                                    }
                                                                                })}
                                                                            </td>
                                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                                <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} defaultChecked={(item.isAllocated == 1) ? true : false}
                                                                                    onChange={(e) => {
                                                                                        let val = e.target.checked;
                                                                                        handleCheckChange(val, item.classId, item.divisionId, item.acYear, item.branchId, item.sectionId, item.examId, item.isAllocated);
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="row mt-3">
                                                    <div className="col-sm-4">
                                                        <button type="button" onClick={() => saveData()} className="btn btn-md" style={{ color: "#63a4ff", border: "2px solid #63a4ff", fontWeight: "800", backgroundColor: "#F0EDEC", fontSize: "15px", boxShadow: "2px 5px 10px #7F8C8D" }} >SAVE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingTwo"
                                            style={{
                                                backgroundColor: "#e7eff9",
                                                backgroundImage: "linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%)"
                                            }}
                                        >
                                            <h5 className="mb-0">
                                                <a
                                                    className="d-block w-100 collapsed"
                                                    data-toggle="collapse"
                                                    href="#collapseTwo"
                                                    onClick={() => handleAccordionToggle(1)}
                                                    aria-expanded={openAccordion === 1 ? 'true' : 'false'}>
                                                    SSC Grade Settings
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className={`collapse ${openAccordion === 1 ? 'show' : ''}`} aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className="card-body">
                                                <div style={{
                                                    padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px",
                                                    backgroundColor: " #d9d9d9",
                                                    backgroundImage: "linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%)"
                                                }} >
                                                    {/* Form Data start */}
                                                    <form onSubmit={onSubmit}>
                                                        <div className="form-data mt-1">
                                                            <input type="hidden" value={gradeId} onChange={(e) => setGradeId(e.target.value)} name="" />
                                                            <div className="row">
                                                                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Grade Name :</label>
                                                                <div className='col-sm-3'>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        className=''
                                                                        size='small'
                                                                        id="outlined-basic"
                                                                        label="Grade Name Marathi"
                                                                        variant="outlined"
                                                                        margin='dense'
                                                                        style={{ backgroundColor: "white" }}
                                                                        value={marathiName}
                                                                        onChange={(e) => setMarathiName(e.target.value)}
                                                                        inputProps={{ maxLength: 120 }}
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    />
                                                                </div>

                                                                <div className='col-sm-3'>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        size='small'
                                                                        value={englishName}
                                                                        onChange={(e) => setEnglishName(e.target.value)}
                                                                        id="outlined-basic"
                                                                        label="Grade Name English"
                                                                        variant="outlined"
                                                                        margin='dense'
                                                                        style={{ backgroundColor: "white" }}
                                                                        inputProps={{ maxLength: 120 }}
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row my-2">
                                                                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>From :</label>
                                                                <div className='col-sm-2'>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        type="number"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        autoComplete='off'
                                                                        className=''
                                                                        size='small'
                                                                        id="outlined-basic"
                                                                        label="From"
                                                                        value={from}
                                                                        style={{ backgroundColor: "white" }}
                                                                        onChange={e => setFrom(e.target.value)}
                                                                        variant="outlined"
                                                                        margin='dense'
                                                                        inputProps={{ maxLength: 120 }}
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    />
                                                                </div>

                                                                <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                                                <div className='col-sm-2'>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        type="number"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        autoComplete='off'
                                                                        size='small'
                                                                        id="outlined-basic"
                                                                        label="To"
                                                                        style={{ backgroundColor: "white" }}
                                                                        value={to}
                                                                        onChange={e => setTo(e.target.value)}
                                                                        variant="outlined"
                                                                        margin='dense'
                                                                        inputProps={{ maxLength: 120 }}
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row mt-3">
                                                                <div className="col-sm-4 offset-sm-2">
                                                                    <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                                                    <button type="submit" onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                                                    <button type="button" onClick={() => clearData()} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                    <div className="tableData" >
                                                        <SscGradeMasterTable
                                                            TableHeading={TableHeading}
                                                            setId={setGradeId}
                                                            setMarathiName={setMarathiName}
                                                            setEnglishName={setEnglishName}
                                                            setFrom={setFrom}
                                                            setTo={setTo}
                                                            getData={getData}
                                                            data={data}
                                                            setFilteredData={setFilterData}
                                                            filteredData={filterData}
                                                            setData={setData}
                                                            saveBtnStyle={saveBtnStyle}
                                                            updateBtnStyle={updateBtnStyle}
                                                            displayNoneBtnStyle={displayNoneBtnStyle}
                                                            setSaveBtn={setSaveBtn}
                                                            setUpdateBtn={setUpdateBtn}
                                                            setOpenAccordion={setOpenAccordion}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingThree"
                                            style={{
                                                backgroundColor: "#aee1f9",
                                                backgroundImage: "linear-gradient(315deg, #aee1f9 0%, #f6ebe6 74%)"
                                            }}
                                        >
                                            <h5 className="mb-0">
                                                <a
                                                    className="d-block w-100 collapsed"
                                                    data-toggle="collapse"
                                                    href="#collapseThree"
                                                    onClick={() => handleAccordionToggle(2)}
                                                    aria-expanded={openAccordion === 2 ? 'true' : 'false'}>
                                                    Subject Settings
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className={`collapse ${openAccordion === 2 ? 'show' : ''}`} aria-labelledby="headingThree" data-parent="#accordion">
                                            <div className="card-body">
                                                <div
                                                    style={{
                                                        backgroundColor: "#045de9",
                                                        backgroundImage: "linear-gradient(315deg, #045de9 0%, #09c6f9 74%)",
                                                        color: "#fff", boxShadow: "2px 5px 10px #7F8C8D"
                                                    }}
                                                //     background-color: #0499f2;
                                                // background-image: linear-gradient(315deg, #0499f2 0%, # 74%);
                                                >
                                                    <div className='table-responsive' style={{ maxHeight: "520px" }}>
                                                        <table className="table table-borderless">
                                                            <thead className="" style={{
                                                                position: "sticky", zIndex: "2", overflow: "hidden",
                                                                top: "0", borderBottom: "2px solid #464de4",
                                                                backgroundColor: "#0499f2",
                                                                backgroundImage: "linear-gradient(315deg, #0499f2 0%, #0499f2 74%)",
                                                                //color: "#fff", boxShadow: "2px 5px 10px #7F8C8D"
                                                            }}>
                                                                <tr>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600", width: "180px" }}>Subject</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }}>Sr No</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }}>Type</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }}>Printing Name Mr</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }}>Printing Name En</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }}>Mark System</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }}>Calculation Method</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filteredData.sort((a, b) => (a.srNo * b.srNo === 0) ? ((a.srNo === b.srNo) ? (0) : ((a.srNo === 0) ? 1 : -1)) : (a.srNo - b.srNo)).map((item, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <tr>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                                    {(bMedium == 1) ? item.subjectNameMr : item.subjectNameEn}
                                                                                </td>

                                                                                <SubjectSettingTextBox viewTypeData={viewTypeData} markTypeData={markTypeData}
                                                                                    initialText1={item.srNo}
                                                                                    initialText2={item.viewType}
                                                                                    initialText3={item.subjectPrintingNameMr}
                                                                                    initialText4={item.markSystem}
                                                                                    initialText5={item.calculationMethod}
                                                                                    initialText6={item.subjectPrintingNameEn}
                                                                                    onChange={(value1, value2, value3, value4, value5, value6) => {
                                                                                        let updatedData = subjectData;
                                                                                        updatedData[index] = ({
                                                                                            ...item,
                                                                                            srNo: value1,
                                                                                            viewType: value2,
                                                                                            subjectPrintingNameMr: (value3 == "") ? item.subjectNameMr : value3,
                                                                                            subjectPrintingNameEn: (value6 == "") ? item.subjectNameEn : value6,
                                                                                            markSystem: value4,
                                                                                            calculationMethod: value5,
                                                                                            sectionId: departmentObj.id,
                                                                                            branchId: branchObj.id,
                                                                                            branchMedium: bMedium
                                                                                        });
                                                                                        setFilteredData(updatedData);
                                                                                        setSubjectData(updatedData);
                                                                                        //console.log(JSON.stringify(updatedData));
                                                                                    }} />
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    )
                                                                })}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="row mt-3">
                                                    <div className="col-sm-4">
                                                        <button type="button" className="btn btn-md" style={{ color: "#09c6f9", border: "2px solid #09c6f9", fontWeight: "800", backgroundColor: "#F0EDEC", fontSize: "15px", boxShadow: "2px 5px 10px #7F8C8D" }} onClick={() => saveSubjectsForResult()}>SAVE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingFour"
                                            style={{
                                                backgroundColor: "#aee1f9",
                                                backgroundImage: "linear-gradient(315deg, #aee1f9 0%, #f6ebe6 74%)"
                                            }}
                                        >
                                            <h5 className="mb-0">
                                                <a
                                                    className="d-block w-100 collapsed"
                                                    data-toggle="collapse"
                                                    href="#collapseFour"
                                                    onClick={() => handleAccordionToggle(3)}
                                                    aria-expanded={openAccordion === 3 ? 'true' : 'false'}>
                                                    Group Settings
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className={`collapse ${openAccordion === 3 ? 'show' : ''}`} aria-labelledby="headingFour" data-parent="#accordion">
                                            <div className="card-body">
                                                <div>
                                                    {/* Form Data start */}
                                                    <form onSubmit={onGroupSubmit}>
                                                        <div className="form-data mt-1">

                                                            <div className="row">
                                                                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Group Name :</label>
                                                                <div className='col-sm-4'>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        className=''
                                                                        size='small'
                                                                        id="outlined-basic"
                                                                        label="Group Name Marathi"
                                                                        variant="outlined"
                                                                        margin='dense'
                                                                        style={{ backgroundColor: "white" }}
                                                                        value={groupNameMr}
                                                                        onChange={(e) => setGroupNameMr(e.target.value)}
                                                                        inputProps={{ maxLength: 120 }}
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    />
                                                                </div>

                                                                <div className='col-sm-4'>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        size='small'
                                                                        value={groupNameEn}
                                                                        onChange={(e) => setGroupNameEn(e.target.value)}
                                                                        id="outlined-basic"
                                                                        label="Group Name English"
                                                                        variant="outlined"
                                                                        margin='dense'
                                                                        style={{ backgroundColor: "white" }}
                                                                        inputProps={{ maxLength: 120 }}
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row my-2">
                                                                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Passing Min. Mark :</label>
                                                                <div className='col-sm-2'>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        className=''
                                                                        size='small'
                                                                        id="outlined-basic"
                                                                        label="Passing Min. Mark"
                                                                        variant="outlined"
                                                                        margin='dense'
                                                                        style={{ backgroundColor: "white" }}
                                                                        value={passMinMark}
                                                                        onChange={(e) => setPassMinMark(e.target.value)}
                                                                        inputProps={{ maxLength: 120 }}
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px",
                                                            backgroundColor: " #d9d9d9",
                                                            backgroundImage: "linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%)"
                                                        }} >

                                                            <div className="row">
                                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                                                <div className='col-sm-3'>
                                                                    <Autocomplete
                                                                        id="combo-box-demo"
                                                                        PaperComponent={({ children }) => (
                                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                        )}
                                                                        options={groupSubjectData}
                                                                        value={groupSubjectObj}
                                                                        onChange={(event, newValue) => {
                                                                            setGroupSubjectObj(newValue);
                                                                        }}
                                                                        style={{ fontSize: '14px' }}
                                                                        getOptionLabel={option => (bMedium == 1) ? option.subjectNameMr : option.subjectNameEn}
                                                                        size="small"
                                                                        renderInput={params => (
                                                                            <TextField {...params} margin="dense" label="Select Subject" InputLabelProps={{ style: { fontSize: 14 } }} style={{ backgroundColor: "white" }} variant="outlined"
                                                                            // required 
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>

                                                                <label htmlFor="lastname3" className="" style={{ width: '120px', fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Min. Mark :</label>
                                                                <div className='col-sm-2'>
                                                                    <TextField
                                                                        // required
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        className=''
                                                                        size='small'
                                                                        id="outlined-basic"
                                                                        label="Min. Mark"
                                                                        variant="outlined"
                                                                        margin='dense'
                                                                        style={{ backgroundColor: "white" }}
                                                                        value={subMinMark}
                                                                        onChange={(e) => setSubMinMark(e.target.value)}
                                                                        inputProps={{ maxLength: 120 }}
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    />
                                                                </div>

                                                                <div className="col-sm-2 mt-3">
                                                                    <button type='button' className='btn btn-sm' onClick={() => getGroupSubTableData()} disabled={(groupSubjectObj == null || subMinMark == "") ? true : false} style={{ color: '#fff', backgroundColor: '#F34D0F' }}>ADD</button>
                                                                </div>
                                                            </div>

                                                            {(() => {
                                                                if (subTableData.length > 0) {
                                                                    return (
                                                                        <>
                                                                            <div className="table-responsive mt-4 mb-2" style={{ maxHeight: "300px" }}>
                                                                                <table className="table">
                                                                                    <thead className=''>
                                                                                        <tr>
                                                                                            <th style={{ fontSize: "14px", fontWeight: "600" }}>Sr No</th>
                                                                                            <th style={{ fontSize: "14px", fontWeight: "600" }}>Subject</th>
                                                                                            <th style={{ fontSize: "14px", fontWeight: "600" }}>Min. Mark</th>
                                                                                            <th style={{ fontSize: "14px", fontWeight: "600" }}>Delete</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {subTableData.map((item, index) => {
                                                                                            return (
                                                                                                <React.Fragment key={index}>
                                                                                                    <tr >
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "25px", width: "250px" }}>{item.subjectId}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                                                            {(bMedium == 1) ? item.nameMr : item.nameEn
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "25px", width: "250px" }}>{item.minMark}</td>
                                                                                                        <td>
                                                                                                            <Tooltip title="Delete">
                                                                                                                <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                                                                    //data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                                                                    onClick={() => handleShowModal(item)}
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                                                </span>
                                                                                                            </Tooltip>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            })()}
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-sm-4">
                                                                <button type="submit" onClick={() => (state.button = 3)} className={saveGroupBtn} >Save</button>
                                                                <button type="submit" onClick={() => (state.button = 4)} className={updateGroupBtn}>Update</button>
                                                                <button type="button" onClick={() => clearGroupData()} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>

                                                    {/* <div className='table-responsive mt-4' style={{ maxHeight: "520px" }}>
                                                    <table className="table table-borderless">
                                                        <thead className="" style={{
                                                            position: "sticky", zIndex: "2", overflow: "hidden",
                                                            top: "0", borderBottom: "2px solid #464de4",
                                                            backgroundColor: "#0499f2",
                                                            backgroundImage: "linear-gradient(315deg, #0499f2 0%, #0499f2 74%)",
                                                            //color: "#fff", boxShadow: "2px 5px 10px #7F8C8D"
                                                        }}>
                                                            <tr>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Sr No</th>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Group Name Mr</th>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Group Name En</th>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Min Mark</th>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Edit</th>
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }}>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                        </tbody>
                                                    </table>
                                                </div> */}

                                                    <div className="tableData" >
                                                        <GroupSettingTable
                                                            grouTableHeading={grouTableHeading}
                                                            setGroupId={setGroupId}
                                                            setGroupNameMr={setGroupNameMr}
                                                            setGroupNameEn={setGroupNameEn}
                                                            setPassMinMark={setPassMinMark}
                                                            setSubTableData={setSubTableData}
                                                            getData={getData}
                                                            retrieveData={retrieveData}
                                                            groupTableData={groupTableData}
                                                            setGroupTableData={setGroupTableData}
                                                            groupTableFilterData={groupTableFilterData}
                                                            setGroupTableFilterData={setGroupTableFilterData}
                                                            saveGroupBtnStyle={saveGroupBtnStyle}
                                                            updateGroupBtnStyle={updateGroupBtnStyle}
                                                            displayGroupNoneBtnStyle={displayNoneBtnStyle}
                                                            setSaveGroupBtn={setSaveGroupBtn}
                                                            setUpdateGroupBtn={setUpdateGroupBtn}
                                                            divisionOptObj={divisionOptObj}
                                                            setOpenAccordion={setOpenAccordion}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>


                {/* <SscGradeMaster /> */}
                {/* <ToastContainer position="top-right" theme="colored" /> */}

                <GroupSettingModal
                    show={showModal}
                    onClose={handleCloseModal}
                    onDelete={() => DeleteData(deleteId)}
                    message="Are you sure you want to delete this?"
                />
            </>
        )
    }

}

export default ExamResultSetting