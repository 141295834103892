import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleLabel from "../../../CommonComponent/TitleLabel";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from "../../../CommonComponent/Loader";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModalBox from "../../../CommonComponent/DeleteModalBox";
import moment from "moment";
import { getAllAccessionDataByRegisterType } from "../Services/BookIssueReturnAPIURL";
import { BaseURL, BaseURLMaster, dBaseURL, dBaseURLMaster } from "../../../CommonServices/APIURL";

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});



const BookIssueReturnTable = ({ data, setData, filteredData, AccessionData, booksStatus, setFilteredData, TableHeading,
    setId, setMemberType, setMemberId, setMemberTypeName, setDesignation, setEmployeeType, setEmployeeNature, setJoiningDate,
    setValidDate, setStudClass, setDivision, setAdmissionDate, setLeftDate, setRegisterTypeObj, setAccNo, setTitleObj,
    setAuthor, setPublisher, setBudgetType, setStatus, setIssueDate, setIsRetired, setIsLeft, setMemberName,
    natureOfAppointment, setMemberTypeData, memberTypeList, setReturnDate, registerTypeData, setAccessionData, setLoaderOption,
    setIssueBtnDisabled, setReturnBtnDisabled, setRenewalBtnDisabled, isRetired, isLeft
}) => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const UpdateData = (item) => {
        //console.log(JSON.stringify(memberTypeList))
        setLoaderOption(true);

        setId(item.id);
        setMemberType(item.memberType);
        setMemberId(item.memberId);

        setRegisterTypeObj(registerTypeData.find(e => e.id == item.registerTypeId));
        setAccNo(item.accNo);
        setIssueDate(item.issueDate);
        setReturnDate(item.returnDate);

        axios.get(`${getAllAccessionDataByRegisterType}?registerTypeId=${item.registerTypeId}&bid=${authUser.branchId}`)
            .then(res => {

                setTitleObj(res.data.find(e => e.accNo == item.accNo))
                res.data.map(e => {
                    if (e.accNo == item.accNo) {
                        setAuthor(e.authorName);
                        setPublisher(e.publisherName);
                        setBudgetType(e.budgetTypeName);
                        booksStatus.map(item => {
                            if (item.id == e.status) {
                                setStatus(item.bookstatus);
                            }
                        })
                    }
                })
                setAccessionData(res.data);
            })

        memberTypeList.map((e) => {
            if (e.typeId == item.memberType) {
                setMemberName(e.typeName)
                if (e.typeId == 1) {
                    axios.get(`${BaseURLMaster}${e.getMapping}?bid=${authUser.branchId}`)
                        .then(res => {
                            setMemberTypeData(res.data);
                            setMemberTypeName(res.data.find(e => e.employeeId == item.memberId))
                            res.data.map(e => {

                                if (e.employeeId == item.memberId) {

                                    if (item.status == 1 && e.isRetired == 1) {
                                        setIssueBtnDisabled(true);
                                        setReturnBtnDisabled(false);
                                        setRenewalBtnDisabled(true);
                                    }
                                    else {
                                        setIssueBtnDisabled(true);
                                        setReturnBtnDisabled(false);
                                        setRenewalBtnDisabled(false);
                                    }

                                    setIsRetired(e.isRetired);
                                    setEmployeeType(e.employeeType);
                                    setDesignation((authUser.branchMedium == 1) ? e.designationMr : e.designationEn);
                                    setJoiningDate(moment(e.joiningDate).format("DD/MM/YYYY"));
                                    setValidDate(moment(e.retirementDate).format("DD/MM/YYYY"));
                                    natureOfAppointment.map(item => {
                                        if (item.id == e.natureOfAppointment) {
                                            setEmployeeNature(item.natureType)
                                        }
                                    })
                                    setLoaderOption(false)
                                }
                            })
                        })
                }
                else {
                    axios.get(`${BaseURL}${e.getMapping}?sid=${(e.sectionId)}&bid=${authUser.branchId}`)
                        .then(res => {
                            setMemberTypeData(res.data);
                            setMemberTypeName(res.data.find(e => e.regCode == item.memberId))
                            res.data.map(e => {
                                if (e.regCode == item.memberId) {
                                    setIsLeft(e.isLeft);
                                    setStudClass((authUser.branchMedium == 1) ? e.currentClassMr : e.currentClassEn);
                                    setDivision((authUser.branchMedium == 1) ? e.currentDivisionMr : e.currentDivisionEn);
                                    setAdmissionDate(moment(e.admissionDate).format("DD/MM/YYYY"));
                                    setLeftDate((e.schoolLeftDate == null) ? moment(new Date()).format("DD/MM/YYYY") : moment(e.schoolLeftDate).format("DD/MM/YYYY"))

                                    if (item.status == 1 && e.isLeft == 1) {
                                        setIssueBtnDisabled(true);
                                        setReturnBtnDisabled(false);
                                        setRenewalBtnDisabled(true);
                                    }
                                    else {
                                        setIssueBtnDisabled(true);
                                        setReturnBtnDisabled(false);
                                        setRenewalBtnDisabled(false);
                                    }
                                    setLoaderOption(false)
                                }
                            })
                        })
                }
            }
        })

    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(item => (
            item.registerTypeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.authorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.titleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.issueDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.returnDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.accNo.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])


    return (
        <>
            <div className='row mt-1 my-2'>
                <div >
                    <input
                        className='form-control form-control-sm'
                        type="text"
                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                    />
                </div>
            </div>

            <div className='table-responsive'>
                <table className="table table-bordered">
                    <thead className="table-Default">
                        <tr>
                            {TableHeading.map((item, index) => {
                                return (
                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .reverse().map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.accNo}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.registerTypeName}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.titleName}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.authorName}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{(moment(item.issueDate).format("DD/MM/YYYY"))}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{(moment(item.returnDate).format("DD/MM/YYYY"))}</td>
                                            <td>
                                                <Tooltip title="Edit">
                                                    <button className='btn btn-primary btn-sm mx-1 p-1'
                                                        onClick={() => UpdateData(item)}
                                                    // disabled={(authUser.roleId === 4 || authUser.roleId === 3) ? true : false}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })}
                    </tbody>
                    <tfoot style={{ padding: "0px" }}>
                        <tr>
                            <TablePagination
                                style={{ padding: "0px" }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onRowsPerPageChange={onRowsPerPageChange}
                                onPageChange={onPageChange}
                                labelRowsPerPage="Rows Per Page :"
                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                        </tr>
                    </tfoot>
                </table>
            </div>
            {/* <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} /> */}
        </>
    )
}

export default BookIssueReturnTable