import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

const CasteCategoryAssignSelectOption = ({ casteCategory, initialText, onChange }) => {
    const authUser = useSelector((state) => state.user.value);
    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);
    const [casteCategoryName, setCasteCategoryName]= useState(casteCategory.find((e)=>e.id==text));
    

    return (
        isEdit ?
            <td style={{width:'170px'}}>
                <select value={text}
                    onChange={(e) => { setText(e.target.value); setCasteCategoryName(casteCategory.find((a)=>a.id==e.target.value)); onChange(e.target.value) }}
                    onKeyPress={(e)=>(e.key == "Enter") ? onChange(e.target.value) : ""}
                    className='form-select form-select-sm'
                    style={{ width: "130px", fontSize: "14px" }}
                    onBlur={() => { setEdit(false) }}
                    autoFocus
                >
                    {casteCategory.map((e) => {
                        return (
                            <option value={e.id}>
                                {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}
                            </option>
                        )
                    })}

                </select>
            </td>

            :
            <td style={{width:'170px'}} onClick={() => setEdit(true)}>{(authUser.branchMedium == 1) ? casteCategoryName.nameMr : casteCategoryName.nameEn}</td>
    )
}

export default CasteCategoryAssignSelectOption