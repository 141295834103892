import { BaseIP, BaseURL, BaseURLMaster, dBaseURL, dBaseURLMaster } from "../../CommonServices/APIURL";

export const studentBaseUrlStudEntryMaster = BaseURL + "administration/studentEntry/";
//export const getStudentList=studentBaseUrlStudEntryMaster+"studentEntryList/";

export const BaseURLLc = BaseURL + "administration/LC/"
export const getStudentList = BaseURLLc + "getCurrentStudentData/";
// getFeeList  ( not contain donation fee names)
export const getAwardData = BaseURLMaster + "getAwardData/";

//getBankList
export const getBankData = BaseURLMaster + "bankNames";

// GetMapping  /getTransactionNo
//@RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId,@RequestParam("studid") long studentId, @RequestParam("feesn") String feeShortName, @RequestParam("cdnm") String classDivName
export const getTransactionNo = BaseURL + "fee/getTransactionNo";

// /getFeeDetails
// (@RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("studid") long studentId, @RequestParam("fid") long feeId
export const getFeeDetails = BaseURL + "getAwardDistributionByStudentId";
export const saveAwardDistribution = BaseURL + "academics/awardDistribution/saveAwardDistribution";
//export const saveFeeCollectionData = "http://192.168.1.102:8080/institute/sss/fee/saveFeeCollectionData";

//localhost:8080/institute/sss/fee/deleteFeeCollectionEntry?id=95
export const deleteAwardData = BaseURL + "academics/awardDistribution/deleteAwardDistributionById";

//localhost:8080/institute/sss/fee/getFeeCollectionDetails?acyr=2021&sid=3&bid=1
export const getAwardDistibutionDetails = BaseURL + "academics/awardDistribution/getAwardDistributionList";
//export const getFeeCollectionDetails = "http://192.168.1.102:8080/institute/sss/fee/getFeeCollectionDetails";

//localhost:8080/institute/sss/fee/getStudentDataFeeCollection?id=1
export const getAwardDistributionById = BaseURL + "academics/awardDistribution/getAwardDistributionById";
//export const getStudentDataFeeCollectionByRegNo = "http://192.168.1.102:8080/institute/sss/fee/getStudentDataFeeCollection/";

//localhost:8080/institute/sss/fee/updateStudentDataFeeCollection/1
export const updateStudentAwardData = BaseURL + "academics/awardDistribution/updateAwardDistribution";
//export const updateStudentDataFeeCollection = "http://192.168.1.102:8080/institute/sss/fee/updateStudentDataFeeCollection/";

//localhost:8080/institute/sss/admission/reports/generateFeeReceiptReport?langId=1&id=1&acYear=2021&sid=3&bid=1
export const printAwardDistributionReportById = BaseURL + "academic/reports/awardDistributionReportById";