import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";


//http://192.168.1.111:8080/institute-sss/institute/sss/administration/getStudentAllocationData?acyr=2021&sid=3&bid=1&cdid=47
export const getStudentListRollNo = BaseURL + "administration/getStudentAllocationData";

export const getClassWithDiv = BaseURLMaster + "classWithDivisionBySectionId";
///institute/sss/master/classWithDivision


// /institute/sss/administration/

// PostMapping - /saveStudentRollNoAssignData
// @RequestBody List <StudentRollNoAssignModel> studentRollNoAssignList

export const updateRollNumAPIURS = BaseURL + "administration/saveStudentRollNoAssignData";

//saveManualStudentRollNoAssignData
export const saveManualRollNumAPIURL = BaseURL + "administration/saveManualStudentRollNoAssignData";


// /institute/sss/administration/
// PostMapping 
// saveManualStudentRollNoAssignDataReassign

export const saveManualStudentRollNoAssignDataReassignAPIURL = BaseURL + "administration/saveManualStudentRollNoAssignDataReassign";


///institute/sss/administration/studentEntry
// @GetMapping
// /getStudentDataByClassCode
// @RequestParam("cid") Long classId, @RequestParam("did") Long divisionId, @RequestParam("sesyear") Long sessionYear, @RequestParam("sid") Long sectionId, @RequestParam("bid") Long branchId
export const StudentsForManualRollNo = BaseURL + "administration/studentEntry/getStudentDataByClassCode";

// /institute/sss/administration/

// @PostMapping
// /saveShiftStudentData

// List<object>
export const saveShiftStudentsAPIURL = BaseURL + "administration/saveShiftStudentData";