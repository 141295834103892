import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import Paper from "@mui/material/Paper";
import { classWithDivisionURL, printAllExamAllocationList, printClassDivWiseExamAllocationList } from '../Services/ExamReportAPIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const ExamAllocationReport = () => {
    const classes = useStyles();
    const titleId = "Exam Allocation List";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [reportOptionId, setReportOptionId] = useState(1);
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);

    useEffect(() => {
        if (authUser.deptId != null || authUser.branchId != null) {
            getData();
        }
    }, [authUser])


    const getData = async () => {
        await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setClassDivData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    const ExamAllocationPrintAll = async () => {
        setLoaderOption(true);
        //console.log(`${printAllExamAllocationList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&branchMedium=${authUser.branchMedium}`)
        await axios.get(`${printAllExamAllocationList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data) 
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Exam Allocation Report failed: " + error)
            })
    }

    const ExamAllocationPrintClassDivWise = async () => {
        setLoaderOption(true);
        await axios.get(`${printClassDivWiseExamAllocationList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not exist with this class-Division");
                }
                else if (response.data != null) {
                    console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Exam Allocation Report failed: " + error)
            })
    }

    const getExamAllocationReport = e => {
        e.preventDefault();
        if (reportOptionId == 1) {
            ExamAllocationPrintAll();
        }
        else if (reportOptionId == 2) {
            ExamAllocationPrintClassDivWise();
        }
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={getExamAllocationReport}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={reportOptionId}
                                            label="Select Option"
                                            onChange={(e) => setReportOptionId(e.target.value)}
                                        >
                                            <MenuItem value={1}>All</MenuItem>
                                            <MenuItem value={2}>Class-division Wise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(() => {
                                if (reportOptionId == 2) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                                <div className='col-sm-3'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        options={classDivData}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                        size="small"
                                                        value={classDivObj}
                                                        onChange={(event, newvalue) => {
                                                            setClassDivObj(newvalue);
                                                        }}

                                                        renderInput={params => (
                                                            <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                required={(reportOptionId == 2) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })()}

                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default ExamAllocationReport