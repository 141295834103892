import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//get list
//localhost:8080/institute/sss/hostel/master/getAllBedMaster
export const getBedMasterAPIURL = BaseURL + "hostel/master/getAllBedMaster";

//save
// localhost:8080/institute/sss/hostel/master/saveBedMaster
// {    
//     "bedName": "B-2",
//     "roomId":3
// }
export const saveBedMsaterAPIURL = BaseURL + "hostel/master/saveBedMaster";

// /update
//localhost:8080/institute/sss/hostel/master/updateBedMaster
export const updateBedMasterAPIURL = BaseURL + "hostel/master/updateBedMaster";

//localhost:8080/institute/sss/hostel/master/deleteBedMaster?id=1
export const deleteBedMasterAPIURL = BaseURL + "hostel/master/deleteBedMaster";
