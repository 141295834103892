import React, { useState } from 'react'

const TermWiseHealthInput = ({ initialText1, initialText2, initialText3, initialText4, onChange }) => {
    //console.log(initialText1 + "   " + initialText2)
    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    const [text3, setText3] = useState(initialText3);
    const [text4, setText4] = useState(initialText4);
    const [isEdit1, setEdit1] = useState(false);
    const [isEdit2, setEdit2] = useState(false);
    const [isEdit3, setEdit3] = useState(false);
    const [isEdit4, setEdit4] = useState(false);

    const getBmi = () => {
        if (text1 == 0 && text2 == 0) {
            setText3(0);
        } else {
            let Height = parseFloat(text1) / 100
            let BMI = parseFloat(text2) / (parseFloat(Height) * parseFloat(Height))

            //console.log(parseFloat(BMI).toFixed('2'))
            setText3(parseFloat(BMI).toFixed('2'))
        }

    }

    return (
        <>
            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100px", fontWeight: "500", fontSize: "15px" }}
                    //style={(isEdit1 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit1(true)}
                    onBlur={() => {
                        setEdit1(false);
                        onChange(text1, text2, text3, text4);
                        getBmi();
                    }}

                    onChange={(e) => setText1(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4) : ""}
                    value={text1}
                />
            </td>

            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100px", fontWeight: "500", fontSize: "15px" }}
                    // style={(isEdit2 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit2(true)}
                    onBlur={() => {
                        setEdit2(false);
                        onChange(text1, text2, text3, text4);
                        getBmi();
                    }}

                    onChange={(e) => setText2(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4) : ""}
                    value={text2}
                />
            </td>

            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100px", fontWeight: "500", fontSize: "15px" }}
                    // style={(isEdit3 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit3(true)}
                    onBlur={() => {
                        setEdit3(false);
                        onChange(text1, text2, text3, text4)
                    }}

                    onChange={(e) => setText3(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4) : ""}
                    value={text3}
                />
            </td>

            <td style={{ width: "200px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "180px", fontWeight: "500", fontSize: "15px" }}
                    // style={(isEdit4 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit4(true)}
                    onBlur={() => {
                        setEdit4(false);
                        onChange(text1, text2, text3, text4)
                    }}

                    onChange={(e) => setText4(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4) : ""}
                    value={text4}
                />
            </td>
        </>
    )
}

export default TermWiseHealthInput