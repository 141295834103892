import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/hostel/transaction/getAllMaterialAllocationList?bid=36&year=2022
export const getMaterialAllocationAPIURL = BaseURL + "hostel/transaction/getAllMaterialAllocationList";

//localhost:8080/institute/sss/hostel/transaction/saveMaterialAllocation
// {
//     "regNo": 1069,
//     "materialId": 4,
//     "qty": 2,
//     "sessionYear": 2022,
//     "branchId": 36
// }
export const saveMaterialAllocationAPIURL = BaseURL + "hostel/transaction/saveMaterialAllocation";

//localhost:8080/institute/sss/hostel/transaction/updateMaterialAllocation
export const updateMaterialAllocationAPIURL = BaseURL + "hostel/transaction/updateMaterialAllocation";

// /localhost:8080/institute/sss/hostel/transaction/deleteMaterialAllocation?id=1
export const deleteMaterialAllocationAPIURL = BaseURL + "hostel/transaction/deleteMaterialAllocation";

//http://192.168.29.102:8081/institute-sss/institute/sss/hostel/transaction/getHostelAllocatedStudentList?year=2023&bid=36
export const getStudentListForHostelByBid = BaseURL + "hostel/transaction/getHostelAllocatedStudentList";