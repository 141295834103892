import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import { getItemMasterAPIURL } from '../Services/ItemMasterAPIURL';
import { getInventoryVendorMasterAPIURL } from '../Services/InventoryVendorMasterAPIURL';
import moment from 'moment';
import { getItemInwardDetailsAPIURL, saveItemInwardAPIURL, updateItemInwardAPIURL } from '../Services/ItemInwardAPIURL';


const ItemInward = () => {
    const titleId = 'Item Inward Form';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const navigate = useNavigate();
    let { inId, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "itemInwardSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "itemInwardUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const itemAddBtnStyle = "btn btn-sm";
    const itemUpdateBtnStyle = "btn btn-sm";
    const itemDisplayNoneBtnStyle = "d-none";
    const [itemSaveBtn, setItemSaveBtn] = useState(itemAddBtnStyle);
    const [itemUpdateBtn, setItemUpdateBtn] = useState(itemDisplayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabeld, setUpdateBtnDisabled] = useState(false);


    const [id, setId] = useState("");
    const [vendorData, setVendorData] = useState([]);
    const [vendorObj, setVendorObj] = useState(null);
    const [date, setDate] = useState(null);

    const [itemData, setItemData] = useState([]);
    const [itemObj, setItemObj] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [rate, setRate] = useState('');
    const [totalAmt, setTotalAmt] = useState('');

    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState(null);
    const [grossAmt, setGrossAmt] = useState('');
    const [discount, setDiscount] = useState('');
    const [gst, setGst] = useState('');
    const [otherExp, setOtherExp] = useState('');
    const [netAmt, setNetAmt] = useState('');
    const [remark, setRemark] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [tableData, setTableData] = useState([]);
    const [tableId, setTableId] = useState('');

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    //get api data
    const getApiData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setItemData(res.data);
                }).catch(err => {
                    console.log("get item master err " + err);
                })

            await axios.get(`${getInventoryVendorMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setVendorData(res.data);
                }).catch(err => {
                    console.log("get item master err " + err);
                })
        }

    }

    //get data for updation
    const getEditDetails = async () => {
        setLoaderOption(true);
        if (authUser.sessionYear != '' && authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getItemInwardDetailsAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);

                    const selectedItem = res.data.find(e => e.id == inId)

                    setData(selectedItem);

                    setId(selectedItem.id);
                    setDate(selectedItem.receiveDate);
                    setBillNo(selectedItem.billNo);
                    setBillDate(selectedItem.billDate);
                    setGrossAmt(selectedItem.grossAmount);
                    setDiscount(selectedItem.discount);
                    setGst(selectedItem.gstAmount);
                    setOtherExp(selectedItem.otherExpenses);
                    setNetAmt(selectedItem.netAmount);
                    setRemark(selectedItem.remark);

                    axios.get(`${getInventoryVendorMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            setVendorData(res.data);
                            setVendorObj(res.data.find(e => e.id == selectedItem.vendorId))
                        }).catch(err => {
                            console.log("get item master err " + err);
                        })

                    axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            setItemData(res.data);
                        }).catch(err => {
                            console.log("get item master err " + err);
                        })

                    setTableData(selectedItem.itemInwardDetails);

                    setLoaderOption(false);

                }).catch(err => {
                    setLoaderOption(false);
                    console.log("get item master err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    //navigate to table
    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/st1305/')
    }

    //clear item details textfields
    const clearItemDetails = () => {
        setItemSaveBtn(itemAddBtnStyle);
        setItemUpdateBtn(itemDisplayNoneBtnStyle);
        setItemObj(null);
        setRate('');
        setQuantity('');
        setTotalAmt('');
    }

    //get item details for update
    const getTableDataForUpdate = (item) => {
        setItemSaveBtn(itemDisplayNoneBtnStyle);
        setItemUpdateBtn(itemUpdateBtnStyle);
        setTableId(item.id);
        setQuantity(item.quantity);
        setRate(item.rate);
        setTotalAmt(item.totalAmount);

        axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setItemData(res.data);
                setItemObj(res.data.find(e => e.id == item.itemMasterId));
            }).catch(err => {
                console.log("get item master err " + err);
            })
    }

    //delete item
    const deleteItem = (id) => {
        const newTableData = data.itemInwardDetails.filter(e => e.itemMasterId != id)
        console.log(JSON.stringify(newTableData))
        setTableData(newTableData)
    }

    //get totalAmt from rate & qty
    const getTotalAmt = () => {
        let itemTotalAmt = parseFloat(rate) * parseFloat(quantity)
        setTotalAmt(parseFloat(itemTotalAmt))
    }

    //net amt calculation
    const calculateNetAmt = () => {
        let netAmount = parseFloat(grossAmt) - parseFloat(discount) - parseFloat(gst) + parseFloat(otherExp)
        setNetAmt(parseFloat(netAmount).toFixed('2'))
    }

    //save item detials
    const saveItemDetailsBtnClick = async () => {
        setItemObj(null);
        setQuantity('');
        setRate('');
        setTotalAmt('');
        setDiscount(0);
        setGst(0);
        setOtherExp(0);
        setNetAmt(0);

        const newObj = {
            itemMasterId: itemObj.id,
            itemName: itemObj.itemName,
            quantity: quantity,
            rate: rate,
            totalAmount: totalAmt
        }
        const newTableData = tableData.concat(newObj)

        const updatedTableData = newTableData.map((item, index) => {
            return {
                ...item,
                id: index + 1
            };
        });

        //console.log(JSON.stringify(updatedTableData))

        setTableData(updatedTableData);

        const finalAmount = newTableData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0);
        //console.log(finalAmount);
        setGrossAmt(parseFloat(finalAmount).toFixed(2))
        setNetAmt(parseFloat(finalAmount).toFixed(2));

    }

    //update item details
    const updateItemDetailsBtnClick = async () => {
        setDiscount(0);
        setGst(0);
        setOtherExp(0);

        await axios.get(`${getItemInwardDetailsAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                const selectedItem = res.data.find(e => e.id == inId)
                let updatedData = selectedItem.itemInwardDetails
                updatedData.map((e, index) => {
                    if (e.id == tableId) {
                        updatedData[index] = ({
                            ...e,
                            id: tableId,
                            itemMasterId: itemObj.id,
                            itemName: itemObj.itemName,
                            quantity: quantity,
                            rate: rate,
                            totalAmount: totalAmt
                        })
                    }
                })
                setTableData(updatedData)
                //console.log(JSON.stringify(selectedItem))
                console.log(JSON.stringify(updatedData))

                const final1 = updatedData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0);
                //console.log(final1);
                setGrossAmt(parseFloat(final1).toFixed(2))
                setNetAmt(parseFloat(final1).toFixed(2));
                setLoaderOption(false);

            }).catch(err => {
                console.log("get item master err " + err);
            })

    }

    //save item inward details
    const saveItemInwardBtnClick = async () => {
        setLoaderOption(true);
        if (moment(date).format("DD/MM/YYYY") != "Invalid date" && moment(billDate).format("DD/MM/YYYY") != "Invalid date") {
            let updatedData = tableData
            tableData.map((e, index) => {
                updatedData[index] = ({
                    "itemMasterId": e.itemMasterId,
                    "quantity": e.quantity,
                    "rate": e.rate,
                    "totalAmount": e.totalAmount
                })
            })

            const saveObj = {
                "itemInward": {
                    "vendorId": vendorObj.id,
                    "receiveDate": moment(date).format("YYYY-MM-DD"),
                    "billNo": billNo,
                    "billDate": moment(billDate).format("YYYY-MM-DD"),
                    "grossAmount": grossAmt,
                    "discount": discount,
                    "gstAmount": gst,
                    "otherExpenses": otherExp,
                    "netAmount": netAmt,
                    "remark": remark,
                    "sessionYear": authUser.sessionYear,
                    "sectionId": authUser.deptId,
                    "branchId": authUser.branchId
                },
                "itemInwardDetails": updatedData
            }

            console.log(JSON.stringify(saveObj))

            await axios.post(saveItemInwardAPIURL, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        clearData();
                        getData();
                        setLoaderOption(false);
                        toast.success("Saved successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Item inward save failed.")
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("save item inward err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }

    }

    //update item inward details
    const updateItemInwardBtnClick = async () => {
        setLoaderOption(true);
        if (moment(date).format("DD/MM/YYYY") != "Invalid date" && moment(billDate).format("DD/MM/YYYY") != "Invalid date") {
            let updatedData = tableData
            tableData.map((e, index) => {
                updatedData[index] = ({
                    "itemMasterId": e.itemMasterId,
                    "quantity": e.quantity,
                    "rate": e.rate,
                    "totalAmount": e.totalAmount
                })
            })

            const updateObj = {
                "itemInward": {
                    "id": id,
                    "vendorId": vendorObj.id,
                    "receiveDate": moment(date).format("YYYY-MM-DD"),
                    "billNo": billNo,
                    "billDate": moment(billDate).format("YYYY-MM-DD"),
                    "grossAmount": grossAmt,
                    "discount": discount,
                    "gstAmount": gst,
                    "otherExpenses": otherExp,
                    "netAmount": netAmt,
                    "remark": remark,
                    "sessionYear": authUser.sessionYear,
                    "sectionId": authUser.deptId,
                    "branchId": authUser.branchId
                },
                "itemInwardDetails": updatedData
            }

            console.log(JSON.stringify(updateObj))

            await axios.put(updateItemInwardAPIURL, updateObj)
                .then(res => {
                    if (res.data == "UPDATED") {
                        clearData();
                        getData();
                        setLoaderOption(false);
                        toast.success("Updated successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Item inward update failed.")
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Item inward update err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const state = {
        button: 1
    }

    //on submit function
    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveItemInwardBtnClick();
        }
        if (state.button == 2) {
            updateItemInwardBtnClick();
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row mb-4">
                                    <label className="" style={{ width: "110px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Vendor :</label>
                                    <div className='col-sm-4'>
                                        <Autocomplete
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            id="combo-box-demo"
                                            onChange={(e, newValue) => {
                                                setVendorObj(newValue);
                                            }}
                                            value={vendorObj}
                                            options={vendorData}
                                            onKeyPress={(e) => (e.key == "Enter") ? vendorObj : ""}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.vendorName}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} label="Select Vendor" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date :</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div className="row mb-2 my-2">
                                    <div className="">
                                        <h4 style={{
                                            textAlign: "left",
                                            borderBottom: "1px solid #5D6D7E",
                                            lineHeight: "0.1em",
                                            margin: "10px 0 20px",
                                            color: "#1430CF"
                                        }}><span style={{
                                            background: "#fff",
                                            padding: "0 8px",
                                        }}>Item Details</span></h4>
                                    </div>
                                </div>

                                <div className='p-2 mt-3' style={{ backgroundColor: "#FCF4E4", border: "1px solid #A55903", minHeight: "250px" }}>

                                    <div className="row my-2">
                                        <label className="" style={{ width: "100px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Item :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                id="combo-box-demo"
                                                onChange={(e, newValue) => {
                                                    setItemObj(newValue);
                                                }}
                                                value={itemObj}
                                                options={itemData}
                                                onKeyPress={(e) => (e.key == "Enter") ? itemObj : ""}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.itemName}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        label="Select Item"
                                                        margin='dense'
                                                        variant="outlined"
                                                        style={{ backgroundColor: "#F7F7F7" }}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required={(tableData.length == 0) ? true : false}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Quantity :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required={(tableData.length == 0) ? true : false}
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && quantity.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                className=''
                                                type='number'
                                                size='small'
                                                id="outlined-basic"
                                                label="Quantity"
                                                variant="outlined"
                                                margin='dense'
                                                style={{ backgroundColor: "#F7F7F7" }}
                                                value={quantity}
                                                onChange={(e) => setQuantity(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? quantity : ""}
                                                onWheel={(e) => e.target.blur()}
                                                onBlur={() => getTotalAmt()}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label htmlFor="lastname3" className="" style={{ width: "100px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Rate :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required={(tableData.length == 0) ? true : false}
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && rate.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                className=''
                                                type='number'
                                                size='small'
                                                style={{ backgroundColor: "#F7F7F7" }}
                                                id="outlined-basic"
                                                label="Rate"
                                                variant="outlined"
                                                margin='dense'
                                                value={rate}
                                                onChange={(e) => setRate(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? rate : ""}
                                                onWheel={(e) => e.target.blur()}
                                                onBlur={() => getTotalAmt()}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>

                                        <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Total Amt. :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required={(tableData.length == 0) ? true : false}
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && totalAmt.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                className=''
                                                type='number'
                                                size='small'
                                                id="outlined-basic"
                                                label="Total Amt."
                                                style={{ backgroundColor: "#F7F7F7" }}
                                                variant="outlined"
                                                margin='dense'
                                                value={totalAmt}
                                                onChange={(e) => setTotalAmt(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? totalAmt : ""}
                                                onWheel={(e) => e.target.blur()}
                                                onBlur={() => getTotalAmt()}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>

                                        <div className="col-sm-3 mt-3">
                                            <button type='button' disabled={(itemObj == null || quantity == '' || rate == '' || totalAmt == '') ? true : false} onClick={() => saveItemDetailsBtnClick()} className={itemSaveBtn} style={{ backgroundColor: "#1F883C", color: "#fff" }}>Add</button>
                                            <button type='button' disabled={(itemObj == null || quantity == '' || rate == '' || totalAmt == '') ? true : false} onClick={() => updateItemDetailsBtnClick()} className={itemUpdateBtn} style={{ backgroundColor: "#1F883C", color: "#fff" }}>Update</button>
                                            <button type='button' onClick={() => clearItemDetails()} className='btn btn-sm btn-danger ml-2'>Clear</button>
                                        </div>
                                    </div>

                                    <div className="table-responsive  mt-4 mb-2" style={{ maxHeight: "300px" }}>
                                        <table className="table">
                                            <thead className='table-default'>
                                                <tr>
                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4" }}>Sr No.</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4" }}>Item</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4" }}>Quantity</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4" }}>Rate</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4" }}>Total Amt.</th>
                                                    <th hidden={(EditFlag == 1) ? true : false} style={{ fontSize: "14px", fontWeight: "600" }}>Edit</th>
                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4" }}>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(() => {
                                                    if (tableData.length != 0) {
                                                        return (
                                                            tableData.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", }}>{index + 1}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                            {
                                                                                itemData.map(e => {
                                                                                    if (e.id == item.itemMasterId) {
                                                                                        return e.itemName
                                                                                    }
                                                                                })
                                                                            }
                                                                        </td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.quantity}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.rate}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", }}>{(item.totalAmount).toFixed('2')}</td>
                                                                        <td hidden={(EditFlag == 1) ? true : false} style={{ width: '100px' }}>
                                                                            <Tooltip title="Edit">
                                                                                <button type='button' className='btn btn-sm' onClick={() => getTableDataForUpdate(item)}>
                                                                                    <EditIcon fontSize='medium' style={{ color: '#587FF8' }} />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </td>
                                                                        <td style={{ width: '100px' }}>
                                                                            <Tooltip title="Delete">
                                                                                <button type='button' className='btn btn-sm' onClick={() => deleteItem(item.itemMasterId)}>
                                                                                    <DeleteIcon fontSize='medium' style={{ color: '#D73016' }} />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        )
                                                    }
                                                })()}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Bill No. :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && billNo.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="Bill No."
                                            variant="outlined"
                                            margin='dense'
                                            value={billNo}
                                            onChange={(e) => setBillNo(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? billNo : ""}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <label className="" style={{ width: "110px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Bill Date :</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Bill Date"
                                                value={billDate}
                                                onChange={(newValue) => {
                                                    setBillDate(newValue);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? billDate : ""}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Gross Amt.:</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && grossAmt.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="Gross Amt."
                                            variant="outlined"
                                            margin='dense'
                                            value={grossAmt}
                                            onChange={(e) => setGrossAmt(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? grossAmt : ""}
                                            onWheel={(e) => e.target.blur()}
                                            onBlur={() => calculateNetAmt()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Discount :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && discount.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="Discount"
                                            variant="outlined"
                                            margin='dense'
                                            value={discount}
                                            onChange={(e) => setDiscount(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? discount : ""}
                                            onBlur={() => calculateNetAmt()}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>GST :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            //required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && gst.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="GST"
                                            variant="outlined"
                                            margin='dense'
                                            value={gst}
                                            onChange={(e) => setGst(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? gst : ""}
                                            onWheel={(e) => e.target.blur()}
                                            onBlur={() => calculateNetAmt()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Other Exp. :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            //required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && otherExp.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="Other Exp."
                                            variant="outlined"
                                            margin='dense'
                                            value={otherExp}
                                            onChange={(e) => setOtherExp(e.target.value)}
                                            onBlur={() => calculateNetAmt()}
                                            onKeyPress={(e) => (e.key == "Enter") ? otherExp : ""}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Net Amt. :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && netAmt.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="Net Amt."
                                            variant="outlined"
                                            margin='dense'
                                            value={netAmt}
                                            onChange={(e) => setNetAmt(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? netAmt : ""}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Remark :</label>
                                    <div className='col-sm-6'>
                                        <TextField
                                            //required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && remark.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Remark"
                                            variant="outlined"
                                            margin='dense'
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? remark : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4" style={{ marginLeft: "110px" }}>
                                        <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabeld} onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default ItemInward