import React, { useState, useEffect } from 'react'
import TitleLabel from '../../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { ToastContainer, toast } from 'react-toastify';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getRegisterTypeDataAPIURL } from '../../LibraryServices/RegisterTypeMasterAPIURL';
import { getTitleMasterData } from '../../LibraryServices/TitleMasterAPIURL';
import axios from 'axios';
import { dBaseURL, BaseURL, BaseURLMaster, dBaseURLMaster } from '../../../CommonServices/APIURL';
import moment from 'moment';
import BookIssueReturnTable from './BookIssueReturnTable';
import CircularProgress from '@mui/material/CircularProgress';
import { saveBookIssueAPIURL, getBookIssueReturnDataAPIURL, getAllAccessionDataByRegisterType, saveBookReturnAPIURL, saveBookRenewalAPIURL } from '../Services/BookIssueReturnAPIURL';
import { getMemberTypeDataAPIURL } from '../../LibraryServices/MemberTypeMasterAPIURL';
import PersonIcon from '@mui/icons-material/Person';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const memberTypeJSON = [
    {
        id: 1,
        memberType: "Staff",
        getMapping: "employee/getBranchWiseEmployeeList",
        sectionId: ""
    },
    {
        id: 2,
        memberType: "Secondary Students",
        getMapping: "administration/LC/getStudentLcBaseData",
        sectionId: 3
    },
    {
        id: 3,
        memberType: "Higher-secondary Students",
        getMapping: "administration/LC/getStudentLcBaseData",
        sectionId: 4
    },
]

const natureOfAppointment = [
    {
        id: 1,
        natureType: "permanent"
    },
    {
        id: 2,
        natureType: "CHB"
    },
    {
        id: 3,
        natureType: "Special"
    }
]

// Acc Status  1=Available,2-Issue,3-Binding, 4- Withdrwal,5-Donot Issue
const booksStatus = [
    {
        id: 1,
        bookstatus: "Available"
    },
    {
        id: 2,
        bookstatus: "Issue"
    },
    {
        id: 3,
        bookstatus: "Binding"
    },
    {
        id: 4,
        bookstatus: "Withdrwal"
    },
    {
        id: 5,
        bookstatus: "Do Not Issue"
    },

]

const bookIssueReturnData = [
    // {
    //     accNo: 2,
    //     registerTypeId: 1,
    //     registerTypeName: "Register Type",
    //     titleId: 1,
    //     titleName: "New Title",
    //     status: 1,
    //     authorId: 9,
    //     authorName: "Author",
    //     issueDate: "10/11/2022",
    //     returnDate: "15/12/2022",
    //     memberType: 2,
    //     memberId: 12173
    // }
]

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const BookIssueReturn = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const [titleId, setTitleId] = useState('Book Issue Return');

    const [loaderOption, setLoaderOption] = useState(false);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const issueBtnStyle = "bookIssueReturnIssueBtn btn btn-sm btn-primary";
    const returnBtnStyle = "bookIssueReturnReturnBtn btn btn-sm";
    const renewalBtnStyle = "bookIssueReturnRenewalBtn btn btn-sm";
    //const displayNoneBtnStyle = "d-none";
    const [issueBtn, setIssueBtn] = useState(issueBtnStyle);
    const [returnBtn, setReturnBtn] = useState(returnBtnStyle);
    const [renewalBtn, setRenewalBtn] = useState(renewalBtnStyle);

    const [issueBtnDisabled, setIssueBtnDisabled] = useState(false);
    const [returnBtnDisabled, setReturnBtnDisabled] = useState(false);
    const [renewalBtnDisabled, setRenewalBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [memberType, setMemberType] = useState('');
    const [getMemberMapping, setGetMemberMapping] = useState('');
    const [memberId, setMemberId] = useState('');
    const [memberTypeName, setMemberTypeName] = useState(null);
    const [memberTypeData, setMemberTypeData] = useState([]);
    const [designation, setDesignation] = useState('');
    const [employeeType, setEmployeeType] = useState('');
    const [employeeNature, setEmployeeNature] = useState('');
    const [joiningDate, setJoiningDate] = useState('');
    const [validDate, setValidDate] = useState('');
    const [studClass, setStudClass] = useState('');
    const [division, setDivision] = useState('');
    const [admissionDate, setAdmissionDate] = useState('');
    const [leftDate, setLeftDate] = useState('');
    const [RegisterTypeObj, setRegisterTypeObj] = useState(null);
    const [registerTypeData, setRegisterTypeData] = useState([]);
    const [accNo, setAccNo] = useState('');
    const [titleData, setTitleData] = useState([]);
    const [titleObj, setTitleObj] = useState(null);
    const [author, setAuthor] = useState('');
    const [publisher, setPublisher] = useState('');
    const [budgetType, setBudgetType] = useState('');
    const [status, setStatus] = useState('');
    const [issueDate, setIssueDate] = useState(null);
    const [returnDate, setReturnDate] = useState(null);
    const [memberName, setMemberName] = useState('Member');
    const [AccessionData, setAccessionData] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isRetired, setIsRetired] = useState(0);
    const [isLeft, setIsLeft] = useState(0);

    const [memberTypeList, setMemberTypeList] = useState([]);

    const TableHeading = [
        { label: 'Acc No', key: 'accNo' },
        { label: `Register Type`, key: 'registerTypeName' },
        { label: `Title`, key: 'titleName' },
        { label: `Author`, key: 'authorName' },
        { label: `Issue Date`, key: 'issueDate' },
        { label: `Return Date`, key: 'returnDate' },
        { label: 'Edit', value: 'edit', isAction: true },
        // { label: 'Status', value: 'status', isAction: true }
    ];

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(2e3); // For demo purposes.

            if (active) {
                setOptions([...memberTypeData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);


    useEffect(() => {
        if (authUser.branchId != null) {
            getData();
        }
    }, [authUser])


    const getData = async () => {
        await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                setRegisterTypeData(res.data);
            })

        await axios.get(`${getTitleMasterData}?branchId=${authUser.branchId}`)
            .then(res => {
                setTitleData(res.data);
            })

        await axios.get(`${getMemberTypeDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                let newMemberList = res.data
                newMemberList.map((item, index) => {
                    if (item.typeId == 1) {
                        newMemberList[index] = ({
                            ...item, getMapping: "employee/getBranchWiseEmployeeList",
                            sectionId: ""
                        })
                    }
                    else if (item.typeId == 2) {
                        newMemberList[index] = ({
                            ...item, getMapping: "administration/LC/getStudentLcBaseData",
                            sectionId: 3
                        })
                    }
                    else if (item.typeId == 3) {
                        newMemberList[index] = ({
                            ...item, getMapping: "administration/LC/getStudentLcBaseData",
                            sectionId: 4
                        })
                    }

                })
                setMemberTypeList(newMemberList);
            })

        //console.log(`${getBookIssueReturnDataAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)


    }

    const mapData = (mtId) => {
        setIssueDate(new Date())
        //console.log(mtId)
        //setMemberTypeData([]);
        memberTypeList.map((item) => {
            if (item.typeId == mtId) {
                setMemberName(item.typeName)
                if (item.typeId == 1) {
                    const date = new Date();
                    date.setDate(date.getDate() + item.bookReservedDays);
                    setReturnDate(date)
                    axios.get(`${BaseURLMaster}${item.getMapping}?bid=${authUser.branchId}`)
                        .then((response) => {
                            setMemberTypeData(response.data)
                        })
                    //setGetMemberMapping(`${dBaseURLMaster}${item.getMapping}?bid=${authUser.branchId}`);

                }
                else {
                    const date = new Date();
                    date.setDate(date.getDate() + item.bookReservedDays);
                    setReturnDate(date)
                    //setGetMemberMapping(`${dBaseURL}${item.getMapping}?sid=${item.sectionId}&bid=${authUser.branchId}`);
                    axios.get(`${BaseURL}${item.getMapping}?sid=${item.sectionId}&bid=${authUser.branchId}`)
                        .then((response) => {
                            setMemberTypeData(response.data)
                        })

                }

            }
        })

        axios.get(`${getBookIssueReturnDataAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then(res => {
                let FilteredDataByMemberType = res.data.filter(e => {
                    return e.memberType == mtId
                })
                //console.log(FilteredDataByMemberType)
                if (!FilteredDataByMemberType || mtId == '') {
                    setData([]);
                    setFilteredData([]);
                }
                else {
                    setData(FilteredDataByMemberType);
                    setFilteredData(FilteredDataByMemberType);
                }
            })
    }

    const getReturnDate = (iDate) => {

        if (iDate == null) {
            setReturnDate(null);
        }
        else {
            const date = iDate;
            const dateCopy = new Date(date.getTime());
            dateCopy.setDate(dateCopy.getDate() + 6);
            setReturnDate(dateCopy)
        }
    }

    // useEffect(() => {
    //     getStudentEmployeeData();
    // }, [authUser, getMemberMapping])


    // const getStudentEmployeeData = async () => {
    //     if (getMemberMapping != "") {
    //         if (memberType == 1) {
    //             console.log(`${getMemberMapping}`)
    //             await axios.get(`${getMemberMapping}`)
    //                 .then((response) => {
    //                     setMemberTypeData(response.data)
    //                 })
    //         }
    //         else {
    //             console.log(`${getMemberMapping}`)
    //             await axios.get(`${getMemberMapping}`)
    //                 .then((response) => {
    //                     const enrollStudents = (response.data).filter(e => e.currentStatus == 0)
    //                     //console.log(JSON.stringify(response.data));
    //                     setMemberTypeData(response.data)
    //                 })
    //         }
    //     }
    // }

    const getStudentEmployeeById = (sId) => {
        if (memberType == 1) {
            setMemberTypeName(memberTypeData.find(e => e.employeeId == sId))
            memberTypeData.map(e => {
                if (e.employeeId == sId) {
                    // if (e.isRetired == 1) {
                    //     toast.error("Validity Expired.")
                    // }
                    // else {
                    setIsRetired(e.isRetired);
                    setEmployeeType(e.employeeType);
                    setDesignation((authUser.branchMedium == 1) ? e.designationMr : e.designationEn);
                    setJoiningDate(moment(e.joiningDate).format("DD/MM/YYYY"));
                    setValidDate(moment(e.retirementDate).format("DD/MM/YYYY"));
                    natureOfAppointment.map(item => {
                        if (item.id == e.natureOfAppointment) {
                            setEmployeeNature(item.natureType)
                        }
                    })
                    //}
                }
            })
        }
        else if (memberType == 2 || memberType == 3) {
            setMemberTypeName(memberTypeData.find(e => e.regCode == sId))
            memberTypeData.map(e => {
                if (e.regCode == sId) {
                    // if (e.isLeft == 1) {
                    //     toast.error("Validity Expired.");
                    // }
                    // else {
                    setIsLeft(e.isLeft);
                    setStudClass((authUser.branchMedium == 1) ? e.currentClassMr : e.currentClassEn);
                    setDivision((authUser.branchMedium == 1) ? e.currentDivisionMr : e.currentDivisionEn);
                    setAdmissionDate(moment(e.admissionDate).format("DD/MM/YYYY"));
                    setLeftDate((e.schoolLeftDate == null) ? moment(new Date()).format("DD/MM/YYYY") : moment(e.schoolLeftDate).format("DD/MM/YYYY"))
                    //}
                }
            })
        }
    }

    const getBookDetailsByAccNo = (acNo) => {
        setTitleObj(AccessionData.find(item => item.accNo == acNo))
        AccessionData.map(e => {
            if (e.accNo == acNo) {
                setAuthor(e.authorName);
                setPublisher(e.publisherName);
                setBudgetType(e.budgetTypeName);
                booksStatus.map(item => {
                    if (item.id == e.status) {
                        setStatus(item.bookstatus);
                    }
                })

                let upadatedData = data.filter(e => {
                    return e.memberId == memberId && e.memberType == memberType && e.accNo == accNo
                })
                //console.log(upadatedData)

                if (e.status == 3 || e.status == 4 || e.status == 5) {
                    setIssueBtnDisabled(true);
                    setReturnBtnDisabled(true);
                    setRenewalBtnDisabled(true);
                }
                else if (e.status == 2) {
                    if (upadatedData.length == 1 && ((memberType == 1) ? isRetired : isLeft) == 1) {
                        setIssueBtnDisabled(true);
                        setReturnBtnDisabled(false);
                        setRenewalBtnDisabled(true);
                    }
                    else if (upadatedData.length == 0 && ((memberType == 1) ? isRetired : isLeft) == 1) {
                        setIssueBtnDisabled(true);
                        setReturnBtnDisabled(true);
                        setRenewalBtnDisabled(true);
                        toast.error("Validity Expired.")
                    }
                    else if (upadatedData.length == 1) {
                        setIssueBtnDisabled(true);
                        setReturnBtnDisabled(false);
                        setRenewalBtnDisabled(false);
                    }
                    else {
                        setIssueBtnDisabled(true);
                        setReturnBtnDisabled(true);
                        setRenewalBtnDisabled(true);
                    }
                }
                else if (e.status == 1) {
                    if (((memberType == 1) ? isRetired : isLeft) == 0) {
                        setIssueBtnDisabled(false);
                        setReturnBtnDisabled(true);
                        setRenewalBtnDisabled(true);
                    }
                    else if (((memberType == 1) ? isRetired : isLeft) == 1) {
                        setIssueBtnDisabled(true);
                        setReturnBtnDisabled(true);
                        setRenewalBtnDisabled(true);
                        toast.error("Validity Expired.")
                    }
                }
                else {
                    setIssueBtnDisabled(true);
                    setReturnBtnDisabled(false);
                    setRenewalBtnDisabled(false);
                }

            }
        })
    }

    const getTitleListByRegisterId = async (reId) => {
        console.log(`${getAllAccessionDataByRegisterType}?registerTypeId=${reId}&bid=${authUser.branchId}`)
        await axios.get(`${getAllAccessionDataByRegisterType}?registerTypeId=${reId}&bid=${authUser.branchId}`)
            .then(res => {
                setAccessionData(res.data);
            })
    }

    const getBookDetailsByTitleAccNo = (newValue) => {
        setAccNo(newValue.accNo);
        booksStatus.map(item => {
            if (item.id == newValue.status) {
                setStatus(item.bookstatus);
            }
        })
        setAuthor(newValue.authorName);
        setPublisher(newValue.publisherName);
        setBudgetType(newValue.budgetTypeName);

        let upadatedData = filteredData.filter(e => {
            return e.memberId == memberId && e.memberType == memberType && e.accNo == newValue.accNo
        })
        console.log(upadatedData)

        if (newValue.status == 3 || newValue.status == 4 || newValue.status == 5) {
            setIssueBtnDisabled(true);
            setReturnBtnDisabled(true);
            setRenewalBtnDisabled(true);
        }
        else if (newValue.status == 2) {
            if (upadatedData.length == 1 && ((memberType == 1) ? isRetired : isLeft) == 1) {
                setIssueBtnDisabled(true);
                setReturnBtnDisabled(false);
                setRenewalBtnDisabled(true);
            }
            else if (upadatedData.length == 0 && ((memberType == 1) ? isRetired : isLeft) == 1) {
                setIssueBtnDisabled(true);
                setReturnBtnDisabled(true);
                setRenewalBtnDisabled(true);
                toast.error("Validity Expired.")
            }
            else if (upadatedData.length == 1) {
                setIssueBtnDisabled(true);
                setReturnBtnDisabled(false);
                setRenewalBtnDisabled(false);
            }
            else {
                setIssueBtnDisabled(true);
                setReturnBtnDisabled(true);
                setRenewalBtnDisabled(true);
            }
        }
        else if (newValue.status == 1) {
            if (((memberType == 1) ? isRetired : isLeft) == 0) {
                setIssueBtnDisabled(false);
                setReturnBtnDisabled(true);
                setRenewalBtnDisabled(true);
            }
            else if (((memberType == 1) ? isRetired : isLeft) == 1) {
                setIssueBtnDisabled(true);
                setReturnBtnDisabled(true);
                setRenewalBtnDisabled(true);
                toast.error("Validity Expired.")
            }
        }
        else {
            setIssueBtnDisabled(true);
            setReturnBtnDisabled(false);
            setRenewalBtnDisabled(false);
        }
    }

    const clearData = () => {
        setRegisterTypeObj(null);
        setDesignation('');
        setEmployeeType('');
        setEmployeeNature('');
        setJoiningDate('');
        setValidDate('');
        setStudClass('');
        setDivision('');
        setAdmissionDate('');
        setLeftDate('');
        setTitleObj(null);
        setAccNo('');
        setMemberId('');
        setMemberType('');
        setMemberTypeName(null);
        setMemberTypeData([]);
        setAccessionData([]);
        setMemberName('Member');
        setAuthor('');
        setPublisher('');
        setBudgetType('');
        setStatus('');
        setIssueDate(null);
        setReturnDate(null);
        setData([]);
        setFilteredData([]);
        setIssueBtnDisabled(false);
        setReturnBtnDisabled(false);
        setRenewalBtnDisabled(false);
    }

    const saveIssuedBookDetails = async () => {
        //toast.success("Book Issued Successfully.")
        setLoaderOption(true);
        if (moment(issueDate).format("DD/MM/YYYY") != "Invalid date" && moment(returnDate).format("DD/MM/YYYY") != "Invalid date") {
            const saveObj = {
                "memberType": memberType,
                "memberId": memberId,
                "registerTypeId": RegisterTypeObj.id,
                "accNo": accNo,
                "issueDate": moment(issueDate).format("YYYY-MM-DD"),
                "returnDate": moment(returnDate).format("YYYY-MM-DD"),
                "sessionYear": authUser.sessionYear,
                "branchId": authUser.branchId
            }
            console.log(JSON.stringify(saveObj));
            //console.log(saveBookIssueAPIURL)
            await axios.post(`${saveBookIssueAPIURL}`, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        setLoaderOption(false);
                        clearData();
                        getData();
                        toast.success("Book Issued Successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.success("Book Issued failed.")
                    }
                }).catch(err => {
                    console.log("Book Issue Return:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const saveReturnBookDetails = async () => {
        //toast.success("Book Returned Successfully.")
        setLoaderOption(true);
        if (moment(issueDate).format("DD/MM/YYYY") != "Invalid date" && moment(returnDate).format("DD/MM/YYYY") != "Invalid date") {
            const saveObj = {
                "memberType": memberType,
                "memberId": memberId,
                "registerTypeId": RegisterTypeObj.id,
                "accNo": accNo,
                "issueDate": moment(issueDate).format("YYYY-MM-DD"),
                "returnDate": moment(returnDate).format("YYYY-MM-DD"),
                "sessionYear": authUser.sessionYear,
                "branchId": authUser.branchId
            }
            console.log(JSON.stringify(saveObj));
            //console.log(saveBookIssueAPIURL)
            await axios.post(`${saveBookReturnAPIURL}`, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        setLoaderOption(false);
                        clearData();
                        getData();
                        toast.success("Book Returned Successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.success("Book Returned failed.")
                    }
                }).catch(err => {
                    console.log("Book Issue Return:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const saveRenewalBookDetails = async () => {
        //toast.success("Book Renewal Done Successfully.")
        setLoaderOption(true);
        const date = new Date();
        date.setDate(date.getDate() + 7);
        console.log(date);
        if (moment(issueDate).format("DD/MM/YYYY") != "Invalid date" && moment(returnDate).format("DD/MM/YYYY") != "Invalid date") {
            const saveObj = {
                "memberType": memberType,
                "memberId": memberId,
                "registerTypeId": RegisterTypeObj.id,
                "accNo": accNo,
                "issueDate": moment(issueDate).format("YYYY-MM-DD"),
                "returnDate": moment(date).format("YYYY-MM-DD"),
                "sessionYear": authUser.sessionYear,
                "branchId": authUser.branchId
            }
            console.log(JSON.stringify(saveObj));
            //console.log(saveBookIssueAPIURL)
            await axios.post(`${saveBookRenewalAPIURL}`, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        setLoaderOption(false);
                        clearData();
                        getData();
                        toast.success("Book Renewal Done Successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.success("Book Renewal failed.")
                    }
                }).catch(err => {
                    console.log("Book Issue Return:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveIssuedBookDetails();
        }
        if (state.button === 2) {
            saveReturnBookDetails();
        }
        if (state.button === 3) {
            saveRenewalBookDetails();
        }
    };


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />


                                {/* <div className='float-sm-end'>
                                    <iframe src="" title="" style={{ border: "1px solid #000", width: "100px", height: "100px", }}>
                                    </iframe>
                                </div> */}

                                <div className=''>
                                    <div className='row my-2'>
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member Type :</label>
                                        <div className='col-sm-3 ml-2'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Member Type</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    required
                                                    fullWidth
                                                    margin='dense'
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={memberType}
                                                    label="Member Type"
                                                    onChange={(e) => {
                                                        setMemberType(e.target.value);
                                                        mapData(e.target.value);
                                                        //console.log(e.target.value);
                                                        setMemberTypeName(null);
                                                        setMemberId('');
                                                        setAccNo('');
                                                        setAuthor('');
                                                        setTitleObj(null);
                                                        setPublisher('');
                                                        setStatus('');
                                                        setRegisterTypeObj(null);
                                                        setBudgetType('');
                                                        setDesignation('');
                                                        setEmployeeType('');
                                                        setEmployeeNature('');
                                                        setJoiningDate('');
                                                        setValidDate('');
                                                        setStudClass('');
                                                        setDivision('');
                                                        setAdmissionDate('');
                                                        setLeftDate('');
                                                        setIssueBtnDisabled(false);
                                                        setReturnBtnDisabled(false);
                                                        setRenewalBtnDisabled(false);

                                                    }}
                                                >
                                                    {
                                                        memberTypeList.filter((e) => e.sectionId == authUser.deptId || e.sectionId == '').sort((a, b) => a.typeId > b.typeId ? 1 : -1).map(e => {
                                                            return (
                                                                <MenuItem value={e.typeId}>{e.typeName}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2" hidden={(memberType == '') ? true : false}>
                                        <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member Name :</label>
                                        <div className="ml-2" style={{ width: "120px" }}>
                                            <TextField
                                                fullWidth
                                                required
                                                style={{ marginRight: "4px" }}
                                                onKeyDown={(e) => (e.key === " " && memberId.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                margin='dense'
                                                size='small'
                                                variant="outlined"
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                onKeyPress={(e) => (e.key == "Enter") ? memberId : ""}
                                                label="Code"
                                                value={memberId}
                                                onChange={(e) => {
                                                    setMemberId(e.target.value);
                                                    setMemberTypeName(null);
                                                    setAccNo('');
                                                    setAuthor('');
                                                    setTitleObj(null);
                                                    setPublisher('');
                                                    setStatus('');
                                                    setBudgetType('');
                                                    setRegisterTypeObj(null);
                                                    // setIssueDate(null);
                                                    // setReturnDate(null);
                                                    setIssueBtnDisabled(false);
                                                    setReturnBtnDisabled(false);
                                                    setRenewalBtnDisabled(false);
                                                    if (memberType == 1) {
                                                        setEmployeeType('');
                                                        setEmployeeNature('');
                                                        setDesignation('');
                                                        setJoiningDate('');
                                                        setValidDate('');
                                                    }
                                                    else if (memberType == 2 || memberType == 3) {
                                                        setStudClass('');
                                                        setDivision('');
                                                        setAdmissionDate('');
                                                        setLeftDate('');
                                                    }
                                                }}
                                                onBlur={(e) => getStudentEmployeeById(e.target.value)}
                                            />
                                        </div>

                                        <div className='col-sm-5'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={memberTypeData}
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                loading={loading}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={(option) => {
                                                    if (memberType == 1) {
                                                        return ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)
                                                    }
                                                    else {
                                                        return (authUser.branchMedium == 1) ? option.studentNameMr : option.studentNameEn
                                                    }
                                                }}
                                                renderOption={(props, option) => (
                                                    <div className='' {...props}>
                                                        <div className=''>
                                                            {
                                                                (memberType == 1)
                                                                    ?
                                                                    option.employeeId + ". " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)
                                                                    :
                                                                    (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + " " + option.currentClassMr : option.regCode + " - " + option.studentNameEn + " " + option.currentClassEn
                                                            }
                                                        </div>

                                                    </div>
                                                )}
                                                size="small"
                                                onChange={(event, newValue) => {
                                                    setMemberTypeName(newValue);
                                                    setAccNo('');
                                                    setAuthor('');
                                                    setTitleObj(null);
                                                    setPublisher('');
                                                    setStatus('');
                                                    setBudgetType('');
                                                    setRegisterTypeObj(null);
                                                    // setIssueDate(null);
                                                    // setReturnDate(null);
                                                    setIssueBtnDisabled(false);
                                                    setReturnBtnDisabled(false);
                                                    setRenewalBtnDisabled(false);
                                                    if (newValue == null) {
                                                        if (memberType == 1) {
                                                            setMemberId('');
                                                            setEmployeeType('');
                                                            setEmployeeNature('')
                                                            setDesignation('');
                                                            setJoiningDate('');
                                                            setValidDate('');
                                                        }
                                                        else if (memberType == 2 || memberType == 3) {
                                                            setMemberId('');
                                                            setStudClass('');
                                                            setDivision('');
                                                            setAdmissionDate('');
                                                            setLeftDate('');
                                                        }
                                                    }
                                                    else {
                                                        if (memberType == 1) {
                                                            setMemberId(newValue.employeeId);
                                                            setIsRetired(newValue.isRetired);
                                                            setEmployeeType(newValue.employeeType);
                                                            setDesignation((authUser.branchMedium == 1) ? newValue.designationMr : newValue.designationEn);
                                                            setJoiningDate(moment(newValue.joiningDate).format("DD/MM/YYYY"));
                                                            setValidDate(moment(newValue.retirementDate).format("DD/MM/YYYY"));
                                                            natureOfAppointment.map(e => {
                                                                if (e.id == newValue.natureOfAppointment) {
                                                                    setEmployeeNature(e.natureType)
                                                                }
                                                            })

                                                        }
                                                        else if (memberType == 2 || memberType == 3) {
                                                            setMemberId(newValue.regCode);
                                                            setIsLeft(newValue.isLeft);
                                                            setStudClass((authUser.branchMedium == 1) ? newValue.currentClassMr : newValue.currentClassEn);
                                                            setDivision((authUser.branchMedium == 1) ? newValue.currentDivisionMr : newValue.currentDivisionEn);
                                                            setAdmissionDate(moment(newValue.admissionDate).format("DD/MM/YYYY"));
                                                            setLeftDate((newValue.schoolLeftDate == null) ? moment(new Date()).format("DD/MM/YYYY") : moment(newValue.schoolLeftDate).format("DD/MM/YYYY"))

                                                        }
                                                    }
                                                }
                                                }
                                                onKeyPress={(e) => (e.key == "Enter") ? memberTypeName : ""}
                                                value={memberTypeName}
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        margin='dense' label={"Select" + " " + memberName} autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required />
                                                )}
                                            />
                                        </div>

                                        {/* <div className="col-sm-3" style={{ display: "flex", justifyContent: "end" }}>
                                            <iframe src="" title="" style={{ border: "1px solid #000", width: "100px", height: "100px", float: "right" }}>
                                            </iframe>
                                        </div> */}
                                    </div>



                                    {(() => {
                                        if (memberType == 1) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Employee Type :</label>
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 1) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && employeeType.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? employeeType : ""}
                                                                label="Employee Type"
                                                                value={employeeType}
                                                                inputProps={{ readOnly: true }}
                                                                onChange={(e) => setEmployeeType(e.target.value)}
                                                            />
                                                        </div>

                                                        <label className='' style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Designation:</label>
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 1) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && designation.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? designation : ""}
                                                                label="Designation"
                                                                inputProps={{ readOnly: true }}
                                                                value={designation}
                                                                onChange={(e) => setDesignation(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row my-2">
                                                        <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Employee Nature :</label>
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 1) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && employeeNature.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? employeeNature : ""}
                                                                label="Employee Nature"
                                                                inputProps={{ readOnly: true }}
                                                                value={employeeNature}
                                                                onChange={(e) => setEmployeeNature(e.target.value)}
                                                            />
                                                        </div>

                                                        <label className='' style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Joining Date:</label>
                                                        <div className="col-sm-2">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 1) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && joiningDate.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? joiningDate : ""}
                                                                label="Joining Date"
                                                                inputProps={{ readOnly: true }}
                                                                value={joiningDate}
                                                                onChange={(e) => setJoiningDate(e.target.value)}
                                                            />
                                                        </div>

                                                        <label className='' style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Valid Date:</label>
                                                        <div className="col-sm-2">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 1) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && validDate.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? validDate : ""}
                                                                label="Valid Date"
                                                                inputProps={{ readOnly: true }}
                                                                value={validDate}
                                                                onChange={(e) => setValidDate(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (memberType == 2 || memberType == 3) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 2 || memberType == 3) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && studClass.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? studClass : ""}
                                                                label="Class"
                                                                inputProps={{ readOnly: true }}
                                                                value={studClass}
                                                                onChange={(e) => setStudClass(e.target.value)}
                                                            />
                                                        </div>

                                                        <label className='' style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Division:</label>
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 2 || memberType == 3) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && division.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? division : ""}
                                                                label="Division"
                                                                inputProps={{ readOnly: true }}
                                                                value={division}
                                                                onChange={(e) => setDivision(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row my-2">
                                                        <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Admission Date :</label>
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 2 || memberType == 3) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && admissionDate.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? admissionDate : ""}
                                                                label="Admission Date"
                                                                inputProps={{ readOnly: true }}
                                                                value={admissionDate}
                                                                onChange={(e) => setAdmissionDate(e.target.value)}
                                                            />
                                                        </div>

                                                        <label className='' style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Left Date:</label>
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                fullWidth
                                                                required={(memberType == 2 || memberType == 3) ? true : false}
                                                                style={{ marginRight: "4px" }}
                                                                onKeyDown={(e) => (e.key === " " && leftDate.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? leftDate : ""}
                                                                label="Left Date"
                                                                inputProps={{ readOnly: true }}
                                                                value={leftDate}
                                                                onChange={(e) => setLeftDate(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}
                                </div>


                                {/* <div className="col-sm-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <iframe src="" title="" style={{ border: "1px solid #000", width: "100px", height: "100px", float: "right" }}>
                                    </iframe>
                                </div> */}



                                <div className="row mb-2 my-2 mt-4">
                                    <div className="">
                                        <h4 style={{
                                            textAlign: "left",
                                            borderBottom: "1px solid #5D6D7E",
                                            lineHeight: "0.1em",
                                            margin: "10px 0 20px",
                                            color: "#1430CF"
                                        }}><span style={{
                                            background: "#fff",
                                            padding: "0 8px",
                                        }}>Book Details</span></h4>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Register Type :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={registerTypeData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.registerTypeName}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setRegisterTypeObj(newValue);
                                                setAccNo('');
                                                setAuthor('');
                                                setTitleObj(null);
                                                setPublisher('');
                                                setBudgetType('');
                                                setStatus('');
                                                setIssueBtnDisabled(false);
                                                setReturnBtnDisabled(false);
                                                setRenewalBtnDisabled(false);
                                                if (newValue != null) {
                                                    getTitleListByRegisterId(newValue.id);
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? RegisterTypeObj : ""}
                                            value={RegisterTypeObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Register Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Accession :</label>
                                    <div className="" style={{ width: "120px" }}>
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && accNo.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? accNo : ""}
                                            label="Acc No"
                                            value={accNo}
                                            onChange={(e) => {
                                                setAccNo(e.target.value);
                                                setAuthor('');
                                                setTitleObj(null);
                                                setPublisher('');
                                                setBudgetType('');
                                                setStatus('');
                                                setIssueBtnDisabled(false);
                                                setReturnBtnDisabled(false);
                                                setRenewalBtnDisabled(false);
                                            }}
                                            onBlur={(e) => getBookDetailsByAccNo(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-sm-6'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={AccessionData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={(option) => option.titleName}
                                            renderOption={(props, option) => (
                                                <div className='' {...props}>
                                                    <div className=''>
                                                        {option.accNo + ". " + option.titleName} <br />
                                                        <div
                                                            className=''
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                fontSize: "12px",
                                                                flexDirection: "row",
                                                                justifyContent: "space-between",
                                                                padding: "0px 10px",
                                                                color: "#464964",
                                                                fontWeight:"550"
                                                                //gridRow: 1
                                                            }}
                                                        >
                                                            <span><PersonIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {option.authorName}</span>
                                                            <span className='ml-1 mr-1 mb-1' style={{ fontSize: "14px" }}>   |   </span>
                                                            <span><LibraryBooksIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {option.publisherName}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setTitleObj(newValue);
                                                if (newValue == null) {
                                                    setAuthor('');
                                                    setPublisher('');
                                                    setBudgetType('');
                                                    setAccNo('');
                                                    setStatus('');
                                                    setIssueBtnDisabled(false);
                                                    setReturnBtnDisabled(false);
                                                    setRenewalBtnDisabled(false);
                                                }
                                                else if (newValue != null) {
                                                    getBookDetailsByTitleAccNo(newValue);
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? titleObj : ""}
                                            value={titleObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Title" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Author :</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && author.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? author : ""}
                                            label="Author"
                                            inputProps={{ readOnly: true }}
                                            value={author}
                                            onChange={(e) => setAuthor(e.target.value)}
                                        />
                                    </div>

                                    <label className='' style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Publisher:</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && publisher.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? publisher : ""}
                                            label="Publisher"
                                            inputProps={{ readOnly: true }}
                                            value={publisher}
                                            onChange={(e) => setPublisher(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Budget Type :</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && budgetType.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? budgetType : ""}
                                            label="Budget Type"
                                            inputProps={{ readOnly: true }}
                                            value={budgetType}
                                            onChange={(e) => setBudgetType(e.target.value)}
                                        />
                                    </div>

                                    <label className='' style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Status:</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && status.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? status : ""}
                                            label="Status"
                                            inputProps={{ readOnly: true }}
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Issue Date:</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Issue Date"
                                                value={issueDate}
                                                onChange={(newValue) => {
                                                    setIssueDate(newValue);
                                                    setReturnDate(null);
                                                    if (newValue == null) {
                                                        setReturnDate(null);
                                                    }
                                                    else {
                                                        getReturnDate(newValue);
                                                    }

                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? issueDate : ""}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Return Date:</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Return Date"
                                                value={returnDate}
                                                onChange={(newValue) => {
                                                    setReturnDate(newValue);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? returnDate : ""}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' hidden={issueBtnDisabled} onClick={() => (state.button = 1)} className="btn btn-sm btn-primary" style={{ margin: "0px 4px" }}>Issue</button>
                                        <button type='submit' hidden={returnBtnDisabled} className="btn btn-sm" onClick={() => (state.button = 2)} style={{ margin: "0px 4px", backgroundColor: "#31820D", color: "#fff" }}>Return</button>
                                        <button type='submit' hidden={renewalBtnDisabled} className='btn btn-sm' onClick={() => (state.button = 3)} style={{ margin: "0px 4px", backgroundColor: "#CA8905", color: "#fff" }}>Renewal</button>
                                        <button type='button' className='btn btn-sm btn-danger' onClick={() => clearData()} style={{ margin: "0px 4px" }}>Cancel</button>
                                    </div>
                                </div>
                            </form>

                            {(() => {
                                if (memberType != '') {
                                    return (
                                        <>
                                            <div className='mt-5'>
                                                <BookIssueReturnTable
                                                    data={data}
                                                    setData={setData}
                                                    filteredData={filteredData}
                                                    setFilteredData={setFilteredData}
                                                    TableHeading={TableHeading}
                                                    AccessionData={AccessionData}
                                                    booksStatus={booksStatus}
                                                    setId={setId}
                                                    setMemberType={setMemberType}
                                                    setMemberId={setMemberId}
                                                    setMemberTypeName={setMemberTypeName}
                                                    setDesignation={setDesignation}
                                                    setEmployeeType={setEmployeeType}
                                                    setEmployeeNature={setEmployeeNature}
                                                    setJoiningDate={setJoiningDate}
                                                    setValidDate={setValidDate}
                                                    setStudClass={setStudClass}
                                                    setDivision={setDivision}
                                                    setAdmissionDate={setAdmissionDate}
                                                    setLeftDate={setLeftDate}
                                                    setRegisterTypeObj={setRegisterTypeObj}
                                                    setAccNo={setAccNo}
                                                    setTitleObj={setTitleObj}
                                                    setAuthor={setAuthor}
                                                    setPublisher={setPublisher}
                                                    setBudgetType={setBudgetType}
                                                    setStatus={setStatus}
                                                    setIssueDate={setIssueDate}
                                                    setReturnDate={setReturnDate}
                                                    registerTypeData={registerTypeData}
                                                    setIsRetired={setIsRetired}
                                                    setIsLeft={setIsLeft}
                                                    natureOfAppointment={natureOfAppointment}
                                                    setMemberTypeData={setMemberTypeData}
                                                    memberTypeData={memberTypeData}
                                                    memberTypeList={memberTypeList}
                                                    setMemberName={setMemberName}
                                                    setAccessionData={setAccessionData}
                                                    setLoaderOption={setLoaderOption}
                                                    setIssueBtnDisabled={setIssueBtnDisabled}
                                                    setReturnBtnDisabled={setReturnBtnDisabled}
                                                    setRenewalBtnDisabled={setRenewalBtnDisabled}
                                                    isRetired={isRetired}
                                                    isLeft={isLeft}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            })()}
                        </div>
                        <ToastContainer position="top-right" theme="colored" />
                    </div>
            }
        </>
    )
}

export default BookIssueReturn