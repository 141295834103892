const mobileApp = [
    {
        id: 1001,
        MenuType: "normal",
        PathName: "/Home/ma1001/",
        Title: "User Management",
        LinkIcon: "manage_accounts"
    },
    {
        id: 1002,
        MenuType: "normal",
        PathName: "/Home/ma1002/",
        Title: "Notice",
        LinkIcon: "receipt"
    },
    {
        id: 1003,
        MenuType: "normal",
        PathName: "/Home/ma1003/",
        Title: "Home Work",
        LinkIcon: "auto_stories"
    },
    {
        id: 1004,
        MenuType: "normal",
        PathName: "/Home/ma1004/",
        Title: "Event",
        LinkIcon: "event"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Exam",
        LinkIcon: "menu_book",
        DropDownSubMenu: [
            {
                id: 1005,
                SubPath: "/Home/ma1005/ ",
                SubName: "Exam Time Table"
            },
            {
                id: 1006,
                SubPath: "/Home/ma1006/ ",
                SubName: "Live Result"
            },
        ]
    },
]

export default mobileApp; 