import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import { delteTypeMasteAPIURL } from '../Services/TypeMasteAPIURL';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const TypeMasterTable = ({ deleteMapping, filteredData, setFilteredData, TableHeading, setId, setNameMr, setNameEn, data, getData, setData, saveBtnStyle, updateBtnStyle, displayNoneBtnStyle, setSaveBtn, setUpdateBtn }) => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const UpdateData = (item) => {
        setId(item.id);
        setNameMr(item.nameMr);
        setNameEn(item.nameEn);
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);

    }

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = async (id) => {

        await axios.delete(`${delteTypeMasteAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data == "DELETED") {
                    getData();
                    toast.success("Deleted sucessfully");
                }
            });
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(
            (item) =>
                item.nameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.nameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
        if (searchTerm == "") {
            setFilteredData(tempFilteredData);
        }
        else {
            setFilteredData(tempFilteredData.sort((a, b) => (a.id < b.id) ? 1 : -1));
        }

    }, [searchTerm]);



    return (
        <>
            <div className='row mt-1'>
                <div >
                    <input
                        className='form-control form-control-sm'
                        type="text"
                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                    />
                </div>
            </div>

            <div className='table-responsive'>
                <table className="table table-bordered">
                    <thead className="table-Default">
                        <tr>
                            {TableHeading.map((item, index) => {
                                return (
                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .reverse().map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.nameMr}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.nameEn}</td>
                                            <td>
                                                <Tooltip title="Edit">
                                                    <button className='btn btn-primary btn-sm mx-1 p-1'
                                                        onClick={() => UpdateData(item)} data-bs-toggle="tooltip"
                                                        disabled={(authUser.roleId === 4 || authUser.roleId === 3) ? true : false}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <Tooltip title="Delete">
                                                    <button className='btn btn-danger btn-sm mx-1 p-1'
                                                        data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                        onClick={() => setDeleteId(item.id)}
                                                        disabled={(authUser.roleId === 4 || authUser.roleId === 3) ? true : false}                                            >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })}
                    </tbody>
                    <tfoot style={{ padding: "0px" }}>
                        <tr>
                            <TablePagination
                                style={{ padding: "0px" }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onRowsPerPageChange={onRowsPerPageChange}
                                onPageChange={onPageChange}
                                labelRowsPerPage="Rows Per Page :"
                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                        </tr>
                    </tfoot>
                </table>
            </div>
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}


export default TypeMasterTable
