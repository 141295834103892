import { BaseURL } from "../../CommonServices/APIURL";


//localhost:8081/institute-sss/institute/sss/academics/exam/saveExamTimeTable
export const saveExamTimeTableAPIURL = BaseURL + "academics/exam/saveExamTimeTable";

//localhost:8081/institute-sss/institute/sss/academics/exam/getExamTimeTableList?year=2024&sid=3&bid=1
export const getExamTimeTableAPIURL = BaseURL + "academics/exam/getExamTimeTableList";

//localhost:8081/institute-sss/institute/sss/academics/exam/deleteExamTimeTable?id=6
export const deleteExamTimeTableAPIURL = BaseURL + "academics/exam/deleteExamTimeTable";

//localhost:8081/institute-sss/institute/sss/academics/exam/getExamTimeTableData?examId=1&termId=1&classId=5&divId=17&year=2023&sid=3&bid=1
export const getExamTimeTableByIdAPIURL = BaseURL + "academics/exam/getExamTimeTableData";

//updateExamTimeTable
export const updateExamTimeTableAPIURL = BaseURL + "academics/exam/updateExamTimeTable";

//updateExamTimeTableStatus
export const updateExamTimeTableStatusAPIURL = BaseURL + "academics/exam/updateExamTimeTableStatus";
