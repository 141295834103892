import { BaseURL, dBaseURL, dBaseURLAcMaster } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/acMaster/grpHead/saveGroupHeadMaster
// {
//     "nameMr": "nameMr",
//     "nameEn": "nameEn",
//     "groupType":1,
//     "groupHead":2,
//     "negativeBalGroup":0,
//     "index":1,
//     "sectionId":3
// }
export const saveGroupHeadAPIURL = BaseURL + "acMaster/grpHead/saveGroupHeadMaster";

//localhost:8080/institute/sss/acMaster/grpHead/getGroupHeadMasterList?sid=3
export const getGroupHeadAPIURL = BaseURL + "acMaster/grpHead/getGroupHeadMasterList";

//localhost:8080/institute/sss/acMaster/grpHead/getGroupHeadNegativeBalGroup?groupType=1&groupHead=2&sid=3
export const getNegativeBalGroupForGroupHeadAPIURL = BaseURL + "acMaster/grpHead/getGroupHeadNegativeBalGroup";

//update
//localhost:8080/institute/sss/acMaster/grpHead/updateGroupHeadMaster
export const updateGroupHeadAPIURL = BaseURL + "acMaster/grpHead/updateGroupHeadMaster";

//localhost:8080/institute/sss/acMaster/grpHead/deleteGroupHeadMaster?id=1
export const deleteGroupHeadAPIURL = BaseURL + "acMaster/grpHead/deleteGroupHeadMaster";

