import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import YearDropdown from '../../CommonComponent/LocalJSON/YearDropdown';
import { ToastContainer, toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { getMonthlyWorkingDays, SaveMonthlyWorkingDays } from '../Services/MonthlyWorkDaysAPIURL'
import MonthlyWorkDaysTextBox from './MonthlyWorkDaysTableRow/MonthlyWorkDaysTextBox';
import Loader from '../../CommonComponent/Loader';


const MonthlyWorkDays = () => {
    const titleId = "Monthly Working Days";
    const authUser = useSelector((state) => state.user.value);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [
        { label: 'Class', key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn', isAction: true },
        { label: `Working Days`, key: 'workingDays', isAction: true },
        { label: `Sundays`, key: 'sundays', isAction: true },
        { label: `Holidays`, key: 'holidays', isAction: true },
    ];

    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);

    const [monthObj, setMonthObj] = useState(null);
    const [monthId, setMonthId] = useState(null);
    const [workDays, setWorkDays] = useState(0);
    const [sundays, setSundays] = useState(0);
    const [holidays, setHolidays] = useState(0);
    const [id, setId] = useState("");

    const [filteredData, setFilteredData] = useState([]);
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    //data sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getMonthlyWorkingDays}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&mid=${monthId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            }
            )


    }

    //get student list
    const getStudentList = async (mId) => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        setWorkDays(0);
        setSundays(0);
        setHolidays(0);
        let link = getMonthlyWorkingDays + "?acyr=" + authUser.sessionYear + "&sid=" + authUser.deptId + "&bid=" + authUser.branchId + "&mid=" + mId;
        //console.log(link);
        await axios(`${getMonthlyWorkingDays}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&mid=${mId}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setLoaderOption(false);
            })
    }

    //apply same working days to all classes
    const setMonthlyWorkingDays = async () => {
        setLoaderOption(true);
        setWorkDays(0);
        setSundays(0);
        setHolidays(0);
        await axios(`${getMonthlyWorkingDays}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&mid=${monthId}`)
            .then(res => {
                let updatedData = res.data;
                (res.data).map((item, index) => {
                    updatedData[index] = ({ ...item, workingDays: workDays, sundays: sundays, holidays: holidays })
                })
                //console.log(JSON.stringify(updatedData))
                setData(updatedData);
                setFilteredData(updatedData);
                setLoaderOption(false);
            })
    }

    const saveWorkingDays = (e) => {
        setLoaderOption(true);
        e.preventDefault();
        axios.post(`${SaveMonthlyWorkingDays}`, data)
            .then((response) => {
                console.log(JSON.stringify(data));
                if (response.data == "SAVED") {
                    setFilteredData([]);
                    setData([]);
                    setMonthObj(null);
                    //setMonthId("");
                    setWorkDays(0);
                    setSundays(0);
                    setHolidays(0);
                    setLoaderOption(false);
                    toast.success("Monthly Working days sucessfully updation done.");
                }
            })
            .catch(error => {
                console.log("Update Fire Query: " + error)
                toast.error("Something went wrong.");
            })

    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.workingDays.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.sundays.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.holidays.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (data == null || data == "" || data == []) {
                    event.preventDefault();
                    let btn = document.querySelector('.monthlyWorkDaysBtn')
                    if (btn !== null) {
                        console.log('Enter is pressed!');
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }


    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                    <div className='row'>
                        <div className='col-sm-3'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={YearDropdown}
                                style={{ fontSize: '14px' }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                value={monthObj}
                                onChange={(e, newValue) => {
                                    setMonthObj(newValue);
                                    if (newValue != null) {
                                        setMonthId(newValue.id);
                                        getStudentList(newValue.id);
                                    }
                                    else if (newValue == null) {
                                        setFilteredData([]);
                                        setData([]);
                                    }


                                }}
                                getOptionLabel={option => (option.id >= 6 && option.id <= 12) ? option.name + " " + (authUser.sessionYear) : option.name + " " + (authUser.sessionYear + 1)}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Month-Year" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                            {/* <FormControl fullWidth>
                                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Month-Year</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        margin='dense'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={monthId}
                                        label="Select Month-Year"
                                        onChange={(e) =>{ 
                                            setMonthId(e.target.value);
                                            getStudentList(e.target.value);
                                        }}
                                    >
                                        {YearDropdown.map(item => {
                                            if (item.id >= 6 && item.id <= 12) {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}   {authUser.sessionYear}</MenuItem>
                                                )
                                            }
                                            else {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}   {(authUser.sessionYear) + 1}</MenuItem>
                                                )
                                            }
                                        })}
                                    </Select>
                                </FormControl> */}
                        </div>
                    </div>

                    {
                        (data == null || data == "")
                            ?
                            <h4 className='mt-5'>No records found, Select Month-Year</h4>
                            :
                            <div>
                                <div className='offset-sm-5 row mt-3 my-2'>
                                    <div className="col-sm-2">
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            size='small'
                                            id="outlined-basic"
                                            label="Work Days"
                                            variant="outlined"
                                            margin='dense'
                                            value={workDays}
                                            onChange={(e) => setWorkDays(e.target.value)}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <div className="col-sm-2">
                                        <TextField

                                            fullWidth
                                            autoComplete='off'
                                            type="number"
                                            size='small'
                                            onWheel={(e) => e.target.blur()}
                                            id="outlined-basic"
                                            label="Sundays"
                                            variant="outlined"
                                            margin='dense'
                                            value={sundays}
                                            onChange={(e) => setSundays(e.target.value)}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <div className="col-sm-2">
                                        <TextField

                                            fullWidth
                                            autoComplete='off'
                                            type="number"
                                            size='small'
                                            onWheel={(e) => e.target.blur()}
                                            id="outlined-basic"
                                            label="Holidays"
                                            variant="outlined"
                                            margin='dense'
                                            value={holidays}
                                            onChange={(e) => setHolidays(e.target.value)}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <div className="col-sm-3 mt-3">
                                        <button type="button" className="btn btn-sm btn-warning" onClick={() => { setMonthlyWorkingDays(); setFilteredData([]) }}>Apply</button>
                                    </div>
                                </div>

                                <form onSubmit={saveWorkingDays}>
                                    <div className='col-sm-10 table-responsive' style={{ maxHeight: "500px" }}>
                                        <table className="table table-bordered ">
                                            <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData
                                                    .map((item, index) => {
                                                        return (

                                                            <tr key={index}>

                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "140px" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>

                                                                <MonthlyWorkDaysTextBox initialText1={item.workingDays} initialText2={item.sundays} initialText3={item.holidays} onChange={(value1, value2, value3) => {
                                                                    let updatedData = data;
                                                                    updatedData[index] = ({ ...item, workingDays: value1, sundays: value2, holidays: value3 });
                                                                    setFilteredData(updatedData);
                                                                    setData(updatedData);
                                                                    //console.log(JSON.stringify(data));
                                                                }} />
                                                            </tr>

                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='mt-4'>
                                        <button className='monthlyWorkDaysBtn btn btn-primary btn-sm mb-2' type='submit'><AddIcon fontSize="small" />Save Changes</button>
                                    </div>
                                </form>
                            </div>
                    }

                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }


}

export default MonthlyWorkDays