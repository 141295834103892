import React, { useEffect, useState } from 'react'
import presentEmp from '../assets/dashboard/PresentEmployee.png';
import absentEmp from '../assets/dashboard/absentEmployee.png';
import leaveEmp from '../assets/dashboard/employeeLeave.png';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getEmployeeAttendanceDataAPIURL } from './Services/EmployeeTransactionsAPIURL';
import moment from 'moment';
import Loader from '../CommonComponent/Loader';

const PayrollDashboard = () => {
    const authUser = useSelector((state) => state.user.value);

    const [presentEmpData, setPresentEmpData] = useState([]);
    const [absentEmpData, setAbsentEmpData] = useState([]);
    const [leaveEmpData, setLeaveEmpData] = useState([]);

    const [loaderOption, setLoaderOption] = useState(false);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.sessionYear != '' && authUser.branchId != null) {
            setLoaderOption(true);
            await axios.get(`${getEmployeeAttendanceDataAPIURL}?option=1&attDateFrom=${moment(new Date()).format("YYYY-MM-DD")}&attDateTo=${null}&empId=0&bid=${authUser.branchId}&year=${authUser.sessionYear}`)
                .then(res => {
                    const presentEmp = res.data.filter(e => e.status == "Present")
                    setPresentEmpData(presentEmp);

                    const absentEmp = res.data.filter(e => e.status == "Absent")
                    setAbsentEmpData(absentEmp);

                    const leaveEmp = res.data.filter(e => e.status !== 'Present' && e.status !== 'Absent');
                    setLeaveEmpData(leaveEmp);

                    setLoaderOption(false);

                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Get All employee attendace" + err)
                })
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '400px' }}>
                            <div className="row">
                                <div className="col-md-4 col-xl-3">
                                    <div className="card bg-c-pink order-card">
                                        <div className="card-block">
                                            <span className="text-right" >
                                                <img src={presentEmp} className="f-left mt-2" style={{ width: "70px" }} />
                                                <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{presentEmpData.length}</span>
                                                <br /><br /><br />
                                                <h6>Total Present</h6>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-xl-3">
                                    <div className="card bg-c-blue order-card">
                                        <div className="card-block">
                                            <span className="text-right" >
                                                <img src={absentEmp} className="f-left mt-2" style={{ width: "70px" }} />
                                                <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{absentEmpData.length}</span>
                                                <br /><br /><br />
                                                <h6>Total Absent</h6>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-xl-3">
                                    <div className="card bg-c-yellow order-card">
                                        <div className="card-block">
                                            <span className="text-right" >
                                                <img src={leaveEmp} className="f-left mt-2" style={{ width: "80px" }} />
                                                <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{leaveEmpData.length}</span>
                                                <br /><br /><br />
                                                <h6 >Total Leave</h6>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </>
    )
}

export default PayrollDashboard