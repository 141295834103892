import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

//export const getClassWithDivData = BaseURLMaster + "classWithDivision/";
export const getClassWithDivData = BaseURLMaster + "classWithDivisionBySectionId/";
export const getSubjectData = BaseURLMaster + "Subjects/";

//localhost:8080/institute/sss/academics/lessonPlan/saveLessonPlan
export const saveLessonPlanAPIURL = BaseURL + "academics/lessonPlan/saveLessonPlan";

//localhost:8080/institute/sss/academics/lessonPlan/getAllLessonPlan?sessionYear=2022&sid=3&bid=1
export const getLessonPlanDetailsAPIURL = BaseURL + "academics/lessonPlan/getAllLessonPlan";

// /updateLessonPlan
export const updateLessonPlanAPIURL = BaseURL + "academics/lessonPlan/updateLessonPlan";

//localhost:8080/institute/sss/academics/lessonPlan/deleteLessonPlan?id=1
export const deleteLessonPlanAPIURL = BaseURL + "academics/lessonPlan/deleteLessonPlan";