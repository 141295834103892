const termJSON = [
    {
        id: 1,
        nameMr: "प्रथम सत्र",
        nameEn: "First Term"
    },
    {
        id: 2,
        nameMr: "द्वितीय सत्र",
        nameEn: "Second Term"
    }
]
export default termJSON;