import React, { useState } from "react";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import PrintIcon from '@mui/icons-material/Print';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Divider from "@mui/material/Divider";
import DesignServicesIcon from '@mui/icons-material/DesignServices';

export const SideMenuJson = [
    {
        id: 1,
        MenuName: "Student Entry",
        Link: "/Home/ad01/0",
        LinkIcon: <GroupAddIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 2,
        MenuName: "Bonafide",
        Link: "/Home/ad221/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 3,
        MenuName: "LC Print",
        Link: "/Home/ad222/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 4,
        MenuName: "Fee Receipt Entry",
        Link: "/Home/f311/0",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 5,
        MenuName: "Bulk Fee Receipt",
        Link: "/Home/f309/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 6,
        MenuName: "Fee Receipt Summary",
        Link: "/Home/f316/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 7,
        MenuName: "Fee Due List",
        Link: "/Home/f317/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 8,
        MenuName: "Student Attendance",
        Link: "/Home/acd414/",
        LinkIcon: <AddReactionIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 9,
        MenuName: "Catalog Print",
        Link: "/Home/acd419/",
        LinkIcon: <PrintIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 10,
        MenuName: "Exam Mark Entry",
        Link: "/Home/ex503/",
        LinkIcon: <BookmarkAddIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 11,
        MenuName: "Voucher Entry",
        Link: "/Home/ac04/0",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 12,
        MenuName: "Kird",
        Link: "/Home/ar05/",
        LinkIcon: <AssessmentIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
       
    },
   
]

export const PrincipalSideMenuJson = [
    {
        id: 1,
        MenuName: "Student Entry",
        Link: "/Home/ad01/0",
        LinkIcon: <GroupAddIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 2,
        MenuName: "Bonafide",
        Link: "/Home/ad221/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 3,
        MenuName: "LC Print",
        Link: "/Home/ad222/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 4,
        MenuName: "Fee Receipt Entry",
        Link: "/Home/f311/0",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 5,
        MenuName: "Bulk Fee Receipt",
        Link: "/Home/f309/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 6,
        MenuName: "Fee Receipt Summary",
        Link: "/Home/f316/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 7,
        MenuName: "Fee Due List",
        Link: "/Home/f317/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 8,
        MenuName: "Student Attendance",
        Link: "/Home/acd414/",
        LinkIcon: <AddReactionIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 9,
        MenuName: "Catalog Print",
        Link: "/Home/acd419/",
        LinkIcon: <PrintIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 10,
        MenuName: "Exam Mark Entry",
        Link: "/Home/ex503/",
        LinkIcon: <BookmarkAddIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 11,
        MenuName: "Voucher Entry",
        Link: "/Home/ac04/0",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 12,
        MenuName: "Kird",
        Link: "/Home/ar05/",
        LinkIcon: <AssessmentIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
      
    },
   
]

export const SupervisorSideMenuJson = [
    {
        id: 1,
        MenuName: "Student Entry",
        Link: "/Home/ad01/0",
        LinkIcon: <GroupAddIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 2,
        MenuName: "Bonafide",
        Link: "/Home/ad221/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 3,
        MenuName: "LC Print",
        Link: "/Home/ad222/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 4,
        MenuName: "Fee Receipt Entry",
        Link: "/Home/f311/0",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 5,
        MenuName: "Bulk Fee Receipt",
        Link: "/Home/f309/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 6,
        MenuName: "Fee Receipt Summary",
        Link: "/Home/f316/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 7,
        MenuName: "Fee Due List",
        Link: "/Home/f317/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 8,
        MenuName: "Student Attendance",
        Link: "/Home/acd414/",
        LinkIcon: <AddReactionIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 9,
        MenuName: "Catalog Print",
        Link: "/Home/acd419/",
        LinkIcon: <PrintIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 10,
        MenuName: "Exam Mark Entry",
        Link: "/Home/ex503/",
        LinkIcon: <BookmarkAddIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
       
    },
]

export const ClerkSideMenuJson = [
    {
        id: 1,
        MenuName: "Student Entry",
        Link: "/Home/ad01/0",
        LinkIcon: <GroupAddIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 2,
        MenuName: "Bonafide",
        Link: "/Home/ad221/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 3,
        MenuName: "LC Print",
        Link: "/Home/ad222/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 4,
        MenuName: "Fee Receipt Entry",
        Link: "/Home/f311/0",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 5,
        MenuName: "Bulk Fee Receipt",
        Link: "/Home/f309/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 6,
        MenuName: "Fee Receipt Summary",
        Link: "/Home/f316/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 7,
        MenuName: "Fee Due List",
        Link: "/Home/f317/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 8,
        MenuName: "Student Attendance",
        Link: "/Home/acd414/",
        LinkIcon: <AddReactionIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 9,
        MenuName: "Catalog Print",
        Link: "/Home/acd419/",
        LinkIcon: <PrintIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 10,
        MenuName: "Exam Mark Entry",
        Link: "/Home/ex503/",
        LinkIcon: <BookmarkAddIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 11,
        MenuName: "Voucher Entry",
        Link: "/Home/ac04/0",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 12,
        MenuName: "Kird",
        Link: "/Home/ar05/",
        LinkIcon: <AssessmentIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
       
    },
    
]

export const TeacherSideMenuJson = [
    {
        id: 1,
        MenuName: "Student Entry",
        Link: "/Home/ad01/0",
        LinkIcon: <GroupAddIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 2,
        MenuName: "Bonafide",
        Link: "/Home/ad221/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 3,
        MenuName: "LC Print",
        Link: "/Home/ad222/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 4,
        MenuName: "Fee Receipt Entry",
        Link: "/Home/f311/0",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 5,
        MenuName: "Bulk Fee Receipt",
        Link: "/Home/f309/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 6,
        MenuName: "Fee Receipt Summary",
        Link: "/Home/f316/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 7,
        MenuName: "Fee Due List",
        Link: "/Home/f317/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 8,
        MenuName: "Student Attendance",
        Link: "/Home/acd414/",
        LinkIcon: <AddReactionIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 9,
        MenuName: "Catalog Print",
        Link: "/Home/acd419/",
        LinkIcon: <PrintIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 10,
        MenuName: "Exam Mark Entry",
        Link: "/Home/ex503/",
        LinkIcon: <BookmarkAddIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
        
    },
]

export const AdminSideMenuJson = [
    {
        id: 1,
        MenuName: "Student Entry",
        Link: "/Home/ad01/0",
        LinkIcon: <GroupAddIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 2,
        MenuName: "Bonafide",
        Link: "/Home/ad221/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 3,
        MenuName: "LC Print",
        Link: "/Home/ad222/",
        LinkIcon: <CardMembershipIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 4,
        MenuName: "Fee Receipt Entry",
        Link: "/Home/f311/0",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 5,
        MenuName: "Bulk Fee Receipt",
        Link: "/Home/f309/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 6,
        MenuName: "Fee Receipt Summary",
        Link: "/Home/f316/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 7,
        MenuName: "Fee Due List",
        Link: "/Home/f317/",
        LinkIcon: <AccountBalanceWalletIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 8,
        MenuName: "Student Attendance",
        Link: "/Home/acd414/",
        LinkIcon: <AddReactionIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 9,
        MenuName: "Catalog Print",
        Link: "/Home/acd419/",
        LinkIcon: <PrintIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 10,
        MenuName: "Exam Mark Entry",
        Link: "/Home/ex503/",
        LinkIcon: <BookmarkAddIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 11,
        MenuName: "Voucher Entry",
        Link: "/Home/ac04/0",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 12,
        MenuName: "Kird",
        Link: "/Home/ar05/",
        LinkIcon: <AssessmentIcon style={{ color: "#474646", fontSize: "18px" }} />
    },   
    {
        id: 314,      
        Link: "/Home/f314/0",
        MenuName: "Dengi Pavati",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
       
    },
]

export const SansthaAcademicSideMenuJson = [

    {
        id: 8,
        MenuName: "Student Attendance",
        Link: "/Home/acd414/",
        LinkIcon: <AddReactionIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 9,
        MenuName: "Catalog Print",
        Link: "/Home/acd419/",
        LinkIcon: <PrintIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
       
    },
]

export const SansthaAccountSideMenuJson = [
    {
        id: 11,
        MenuName: "Voucher Entry",
        Link: "/Home/ac04/0",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 12,
        MenuName: "Kird",
        Link: "/Home/ar05/",
        LinkIcon: <AssessmentIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 314,
        Link: "/Home/f314/0",
        MenuName: "Dengi Pavati",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
     
    },
]

export const DedCollegeSideMenuJson = [
    {
        id: 11,
        MenuName: "Voucher Entry",
        Link: "/Home/ac04/0",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 12,
        MenuName: "Kird",
        Link: "/Home/ar05/",
        LinkIcon: <AssessmentIcon style={{ color: "#474646", fontSize: "18px" }} />
    },
    {
        id: 314,
        Link: "/Home/f314/0",
        MenuName: "Dengi Pavati",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
     
    },
]

export const DengiAccountSideMenuJson = [
    {
        id: 314,
        Link: "/Home/f314/0",
        MenuName: "Dengi Pavati",
        LinkIcon: <ReceiptIcon style={{ color: "#474646", fontSize: "18px" }} />,
        Divider: <Divider style={{ backgroundColor: "#A0C5FF" }} />
    },
    {
        id: 618,
        MenuName: "Customer Support",
        Link: "/Home/s618/",
        LinkIcon: <DesignServicesIcon style={{ color: "#474646", fontSize: "18px" }} />,
     
    },
]