import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { useSelector } from 'react-redux';
import { languagePostMapping, languageMasterGetMapping } from '../CommonServices/LanguageMasterAPIURL';
import TextField from '@mui/material/TextField';

const AddNewLanguageModal = ({ showLanguageModal, setShowLanguageModal, getApiData }) => {

    const authUser = useSelector((state) => state.user.value);
    const handleClose = () => setShowLanguageModal(false);
    const handleShow = () => setShowLanguageModal(true);

    const [languageMarathi, setLanguageMarathi] = useState("");
    const [languageEnglish, setLanguageEnglish] = useState("");
    const [languageUDise, setLanguageUDise] = useState("");


    const saveBtnClick = async (e) => {
        handleClose();
        e.preventDefault();
        const saveObject = {
            nameMr: languageMarathi,
            nameEn: languageEnglish,
            udiseNo: languageUDise
        };
        await axios.post(`${languagePostMapping}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    setLanguageMarathi("");
                    setLanguageEnglish("");
                    setLanguageUDise("");                   
                    getApiData();

                    toast.success("Language Saved sucessfully.");
                } else {
                    toast.danger("Operation failed.");
                }
            });

    };

    return (
        <>
            <Modal show={showLanguageModal} onHide={handleClose} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "20px" }}>Add New Language</Modal.Title>
                </Modal.Header>
                <Form onSubmit={saveBtnClick}>
                    <Modal.Body>
                        <TextField
                            required
                            autoComplete='off'
                            onKeyDown={(e) => (e.key === " " && languageMarathi.length === 0) ? e.preventDefault() : ""}
                            value={languageMarathi}
                            onChange={(e) => setLanguageMarathi(e.target.value)}
                            label="Language Marathi"
                            id="outlined-size-small"
                            size="small"
                            className='form-control'
                            margin="dense"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                        />

                        <TextField
                            required
                            autoComplete='off'
                            onKeyDown={(e) => (e.key === " " && languageEnglish.length === 0) ? e.preventDefault() : ""}
                            value={languageEnglish}
                            onChange={(e) => setLanguageEnglish(e.target.value)}
                            label="Language English"
                            id="outlined-size-small"
                            size="small"
                            className='form-control'
                            margin="dense"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                        />

                        <TextField
                            required
                            autoComplete='off'
                            onKeyDown={(e) => (e.key === " " && languageUDise.length === 0) ? e.preventDefault() : ""}
                            value={languageUDise}
                            onChange={(e) => setLanguageUDise(e.target.value)}
                            label="U-Dise No"
                            id="outlined-size-small"
                            size="small"
                            className='form-control'
                            margin="dense"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className="authorModalBtn">
                            Save
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>

    )
}

export default AddNewLanguageModal