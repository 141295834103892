import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import TitleLabel from "../../../CommonComponent/TitleLabel";
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import { updateSubjectEndowment, saveSubjectEndowment, getSubjectEndowment, deleteSubjectEndowment } from "../Services/EndowmentSubjectAPIURL";
import EndowmentSubjectMasterTable from "./EndowmentSubjectMasterTable";

const EndowmentSubjectMaster = () => {
    const authUser = useSelector((state) => state.user.value);


    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "endowSubMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "endowSubMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [titleId, setTitleId] = useState("Subject");


    useEffect(() => {
        getData();
    }, [])


    const [id, setId] = useState("");
    const [englishName, setEnglishName] = useState("");
    const [marathiName, setMarathiName] = useState("");
    //console.log(para);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `${titleId} Name Mr`, key: 'nameMr' }, { label: `${titleId} Name En`, key: 'nameEn' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);



    const getData = async () => {
        await axios.get(`${getSubjectEndowment}`)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
            })
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObject = {
            id: id,
            nameMr: marathiName,
            nameEn: englishName,
        };
        //console.log(JSON.stringify(saveObject))
        await axios.post(`${saveSubjectEndowment}`, saveObject)
            .then((response) => {
                setLoaderOption(false);
                if (response.data == "SAVED") {
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });

        setMarathiName("");
        setEnglishName("");
        setId("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObject = {
            id: id,
            nameMr: marathiName,
            nameEn: englishName,
        };

        await axios.put(`${updateSubjectEndowment}`, updateObject)
            .then((response) => {
                setLoaderOption(false);
                if (response.data == "SAVED") {
                    getData();
                    setLoaderOption(false);
                    toast.success("Update successfully.");
                }
            });
        setId("");
        setMarathiName("");
        setEnglishName("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const ClearData = () => {
        setId("");
        setMarathiName("");
        setEnglishName("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const handleKeyDownMr = (e) => {
        if (e.key === " " && marathiName.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownEn = (e) => {
        if (e.key === " " && englishName.length === 0) {
            e.preventDefault();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.endowSubMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.endowSubMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    {/* Form Data start */}
                    <form onSubmit={onSubmit}>
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className="row">
                                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>{titleId} Name :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={handleKeyDownMr}
                                        autoComplete='off'
                                        className=''
                                        size='small'
                                        id="outlined-basic"
                                        label={titleId + " " + "Marathi"}
                                        variant="outlined"
                                        margin='dense'
                                        value={marathiName}
                                        onChange={(e) => setMarathiName(e.target.value)}
                                        onKeyPress={(e)=>(e.key == "Enter") ? marathiName : ""}
                                        inputProps={{ maxLength: 120 }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={handleKeyDownEn}
                                        autoComplete='off'
                                        size='small'
                                        value={englishName}
                                        onChange={(e) => setEnglishName(e.target.value)}
                                        onKeyPress={(e)=>(e.key == "Enter") ? englishName : ""}
                                        id="outlined-basic"
                                        label={titleId + " " + "English"}
                                        variant="outlined"
                                        margin='dense'
                                        inputProps={{ maxLength: 120 }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 mt-3">
                                <div className="offset-sm-2">
                                    <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                    <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                    <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

                                </div>
                            </div>
                        </div>
                    </form>
                    {/* Form Data end */}

                    <div className="tableData">
                        <EndowmentSubjectMasterTable
                            deleteMapping={deleteSubjectEndowment}
                            TableHeading={TableHeading}
                            setId={setId}
                            setMarathiName={setMarathiName}
                            setEnglishName={setEnglishName}
                            getData={getData}
                            data={data}
                            setFilteredData={setFilteredData}
                            filteredData={filteredData}
                            setData={setData}
                            saveBtnStyle={saveBtnStyle}
                            updateBtnStyle={updateBtnStyle}
                            displayNoneBtnStyle={displayNoneBtnStyle}
                            setSaveBtn={setSaveBtn}
                            setUpdateBtn={setUpdateBtn}
                        />
                    </div>

                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }


}

export default EndowmentSubjectMaster