import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const AdmissionEntrancePhysical = ({ status, PhysicalMedicalType, onChange }) => {
    const authUser = useSelector((state) => state.user.value);
    const [statuss, setStatuss] = useState(status);
    
    return (
        <>
            <td style={{ width: "200px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <select
                    value={statuss}
                    onChange={e => {
                        setStatuss(e.target.value);
                    }}
                    style={{ width: "150px", fontSize: "15px" }}
                    onBlur={() => { onChange(statuss) }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss) : ""}
                >
                    {
                        PhysicalMedicalType.map(e => {
                            return (
                                <option value={e.id}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</option>
                            )
                        })
                    }
                </select>
            </td>
        </>
    )
}

export default AdmissionEntrancePhysical