import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
    Autocomplete, Box, Paper, Tab, Table, TableBody, TableCell, TableContainer,
    TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux';
import { VoucherEntryGetMapping } from '../../CommonServices/VoucherEntryAPIURL';
import { ToastContainer, toast } from 'react-toastify';
import { BaseURLSection } from '../../CommonServices/InstituteMasterAPIURL';
import { BaseURLInstituteMaster } from '../Services/SansthaAPIURL';
import axios from 'axios';
import { updateVoucherEntrySerialNo } from '../Services/VoucherEntrySerialUpdationAPIURL';
import ReceiptImage from '../../AccountMaster/Modal/r1.png'
import ChalanImage from '../../AccountMaster/Modal/c1.png';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../CommonComponent/Loader';
import { Button, Modal } from 'react-bootstrap';
import {
    AccountGroupMasterUpdate, BaseURLAccountGroupMaster,
    getGroupHeadListByGroupTypeAndGroupHeadAndSectionId
} from '../../CommonServices/AccountGroupAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import AccountGroupUpdateForm from './AccountGroupUpdateForm';
import { getReceiptPatmentGroupAPIURL } from '../../AccountMaster/Services/ReceiptPaymentGroupAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const SansthaAdminPage = () => {
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    const navigate = useNavigate();

    const TableHeading = [
        { label: 'V No क्रमांक', key: 'voucherId' },
        { label: `V Date तारीख`, key: 'voucherDate' },
        { label: `Account | खाते`, key: (authUser.branchMedium == 1) ? 'accountNameMr' : 'accountNameEn' },
        { label: `Narration | तपशील`, key: 'narration' },
        { label: `Cr. Amt जमा रक्कम`, key: 'creditAmount' },
        { label: `Dr. Amt नावे रक्कम`, key: 'debitAmount' },
        { label: `RC`, key: 'RC', isAction: true },
        // { label: 'Edit', value: 'edit', isAction: true }
    ];

    const TableHeading2 = [
        { label: 'ID', key: 'id' },
        { label: 'Group Code', key: 'groupCode' },
        { label: 'G.L Name Marathi', key: 'groupNameMr' },
        { label: 'G.L Name English', key: 'groupNameEn' },
        { label: 'Section', key: (authUser.branchMedium == 1) ? 'nameMr' : 'nameEn' },
        { label: 'Index', key: 'index' },
        { label: 'Edit', value: 'edit', isAction: true },
        // { label: 'Delete', value: 'delete', isAction: true }
    ];

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);


    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const [accFilteredData, setAccFilteredData] = useState([]);
    const [accData, setAccData] = useState([]);
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchTerm("");
        setSortedBy(null);
        setAscending(false);
    }

    const [schoolData, setSchoolData] = useState([]);
    const [schoolObj, setSchoolObj] = useState(null);
    const [sectionData, setSectionData] = useState([]);
    const [sectionObj, setSectionObj] = useState(null);
    const [modal, setModal] = useState(false);

    const [id, setId] = useState();
    const [groupCode, setGroupCode] = useState([]);
    const [groupHead, setGroupHead] = useState("");
    const [groupBook, setGroupBook] = useState("");
    const [glNameMarathi, setGlNameMarathi] = useState("");
    const [glNameEnglish, setGlNameEnglish] = useState("");
    const [index, setIndex] = useState("");
    const [isForward, setIsForward] = useState(0);
    const [deptData, setDeptData] = useState([]);
    const [sectionId, setSectionId] = useState([]);
    const [deptObj, setDeptObj] = useState([]);
    const [recAndPayGroupData, setRecAndPayGroupData] = useState([]);
    const [recAndPayGroupObj, setRecAndPayGroupObj] = useState(null);
    const [incExpAndBalSheetData, setIncExpAndBalSheetData] = useState([]);
    const [incExpAndBalSheetObj, setIncExpAndBalSheetObj] = useState(null);

    // console.log("section Object=........"+ JSON.stringify(sectionObj));
    const HandleForwardCheckbox = (e) => {
        setIsForward(e.target.checked);
        if (e.target.checked == true) {
            setIsForward(1);
        }
        else {
            setIsForward(0);
        }
    }

    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [accGroupForm, setAccGroupForm] = useState(1);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }
    


    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.narration.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.debitAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.creditAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.voucherId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.voucherDate.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(BaseURLInstituteMaster)
            .then(res => {
                setSchoolData(res.data);
            })

        await axios.get(BaseURLSection)
            .then(res => {
                const sortData = (res.data).filter(e => e.acApl === 0)
                setSectionData(res.data);
                setDeptData(res.data);
            })
    }

    //get inc. & exp. data
    const getIncExpAndBalSheetGroups = async (gType) => {
        setIncExpAndBalSheetData([]);
        await axios.get(`${getGroupHeadListByGroupTypeAndGroupHeadAndSectionId}?groupType=${gType}&sid=${sectionObj.id}`)
            .then(res => {
                setIncExpAndBalSheetData(res.data);
            }).catch(err => {
                console.log("Get Inc.,Exp. & Bal. sheet date err " + err);
            })
    }

    //get data for view btn click
    const getVocherByBranchSection = async (e) => {
        e.preventDefault();
        setValue(1);
        // if (authUser.sessionYear != '') {
        //console.log(`${VoucherEntryGetMapping}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
        setLoaderOption(true);
        setFilteredData([]);
        setData([]);
        setSearchTerm("");
        //console.log(sectionObj.id + "====" + schoolObj.id)

        await axios(`${VoucherEntryGetMapping}?sid=${sectionObj.id}&bid=${schoolObj.id}&acyr=${authUser.sessionYear}`)
            .then(res => {
                if (res.data.length == 0) {
                    setLoaderOption(false);
                    toast.warn("Data not found with this section & branch.")
                }
                else {
                    setData(res.data);
                    setFilteredData(res.data);
                    // setLoaderOption(false)
                }

            })

        await axios.get(`${BaseURLAccountGroupMaster}${sectionObj.id}`)
            .then((res) => {
                if (res.data.length == 0) {
                    setLoaderOption(false);
                    toast.warn("Data not found with this section & branch.")
                }
                else {
                    setAccData(res.data);
                    setAccFilteredData(res.data);
                    setLoaderOption(false)
                }
            })

        await axios.get(`${getReceiptPatmentGroupAPIURL}?sid=${sectionObj.id}`)
            .then(res => {
                setRecAndPayGroupData(res.data);
            }).catch(err => {
                console.log('get Receipt payment group' + err)
            })

        //}
    }

    //get saved serial numbers data
    const getSavedSerialNumbers = async () => {
        setLoaderOption(true);
        setFilteredData([]);
        setData([]);
        //console.log(sectionObj.id + "====" + schoolObj.id)
        await axios(`${VoucherEntryGetMapping}?sid=${sectionObj.id}&bid=${schoolObj.id}&acyr=${authUser.sessionYear}`)
            .then(res => {
                if (res.data.length == 0) {
                    setLoaderOption(false);
                    toast.warn("Data not found with this section & branch.")
                }
                else {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false)
                }

            })
    }

    //get acc group data after updation
    const getAccGrouDataTableUpdation = async () => {
        await axios.get(`${BaseURLAccountGroupMaster}${sectionObj.id}`)
            .then((res) => {
                if (res.data.length == 0) {
                    setLoaderOption(false);
                    toast.warn("Data not found with this section & branch.")
                }
                else {
                    setAccData(res.data);
                    setAccFilteredData(res.data);
                    setLoaderOption(false)
                }
            })
    }

    //Serial No updation function
    const setVoucherSerialNo = async (e) => {
        setModal(false);
        setLoaderOption(true);
        await axios.put(`${updateVoucherEntrySerialNo}?acyr=${authUser.sessionYear}&sid=${sectionObj.id}&bid=${schoolObj.id}`)
            .then(res => {
                if (res.data == "SAVED") {
                    getSavedSerialNumbers();
                    setLoaderOption(false);
                    toast.success("Serial no updation successfully done.")
                }
            }).catch(err => {
                console.log(err);
                setModal(false);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    //get acc group data for edit
    const UpdateData = async (item) => {
        console.log(item)
        //navigate(`/Home/sacc001/${item}/${EditFlag}`)
        setAccGroupForm(2);
        setId(item.id);
        setGlNameMarathi(item.groupNameMr);
        setGlNameEnglish(item.groupNameEn);
        setGroupCode(item.groupCode);
        setGroupBook(item.groupType);
        // console.log(item);
        setGroupHead(item.groupHead);
        setIndex(item.index);
        setIsForward(item.isForward);
        setDeptObj(item.sectionId);
        setSectionId((item.sectionId).map(e => e.id).join(','));

        axios.get(`${BaseURLSection}`)
            .then((response) => {
                setDeptData(response.data);
            })

        axios.get(`${getReceiptPatmentGroupAPIURL}?sid=${sectionObj.id}`)
            .then(res => {
                setRecAndPayGroupData(res.data);
                setRecAndPayGroupObj(res.data.find(e => e.id == item.rpGroupId))
            }).catch(err => {
                console.log('get Receipt payment group' + err)
            })

        axios.get(`${getGroupHeadListByGroupTypeAndGroupHeadAndSectionId}?groupType=${item.groupType}&sid=${sectionObj.id}`)
            .then(res => {
                setIncExpAndBalSheetData(res.data);
                setIncExpAndBalSheetObj(res.data.find(e => e.id == item.groupHeadId))
                setLoaderOption(false);
            }).catch(err => {
                console.log("Get Inc.,Exp. $ Bal. sheet data err " + err);
                setLoaderOption(false);
            })
    }

    //update Account Group function
    const updateAccountGroup = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const updateObject = {
            id: id,
            groupCode: groupCode,
            groupNameMr: glNameMarathi,
            groupNameEn: glNameEnglish,
            groupType: groupBook,
            groupHead: groupHead,
            groupHeadId: (incExpAndBalSheetObj == null) ? null : incExpAndBalSheetObj.id,
            index: index,
            isForward: isForward,
            rpGroupId: (recAndPayGroupObj == null) ? null : recAndPayGroupObj.id,
            sectionId: sectionId,
            branchId: schoolObj.id
        };
        console.log(JSON.stringify(updateObject))
        axios.put(`${AccountGroupMasterUpdate}` + id, updateObject)
            .then((response) => {
                if (response.data != null) {
                    getAccGrouDataTableUpdation();
                    setAccGroupForm(1);
                    setLoaderOption(false);
                    toast.success("Account group updation successfully done.");
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });
    }

    const onSortAccData = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setAccFilteredData([...accData.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setAccFilteredData([...accData.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setAccFilteredData([]);
        let tempFilteredData = accData.filter(
            (item) =>
                item.groupNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.groupNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.groupCode.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )

        if (searchTerm != "") {
            setAccFilteredData(tempFilteredData.sort((a, b) => (a.groupCode > b.groupCode) ? 1 : -1));
        }
        else {
            setAccFilteredData(tempFilteredData);
        }
    }, [searchTerm]);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        {/* <TitleLabel titleId={titleId} /> */}
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                            <form onSubmit={getVocherByBranchSection}>
                                <div className="row my-2">
                                    <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>School :</label>
                                    <div className="col-sm-6">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            value={schoolObj}
                                            options={schoolData}
                                            onChange={(event, newValue) => {
                                                setSchoolObj(newValue);
                                                setSectionObj(null);
                                                setData([]);
                                                setFilteredData([]);
                                                setAccData([]);
                                                setAccFilteredData([]);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? schoolObj : ""}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select School" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Section :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            value={sectionObj}
                                            options={sectionData}
                                            onChange={(event, newValue) => {
                                                setSectionObj(newValue);
                                                setData([]);
                                                setFilteredData([]);
                                                setAccData([]);
                                                setAccFilteredData([]);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? sectionObj : ""}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Section" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-11 offset-sm-1">
                                        <button type="submit" className='btn btn-sm btn-warning'><VisibilityIcon fontSize='small' /> VIEW</button>
                                        {/* <button hidden={(data.length == 0) ? true : false} style={{ float: "right" }} onClick={() => bottomFunction()} id="myBtnBottom" title="Go to bottom">
                                            <DoubleArrowRoundedIcon style={{ marginTop: "-6px" }} />
                                        </button> */}
                                    </div>
                                </div>
                            </form>

                            {
                                (data.length == 0 || accData.length == 0)
                                    ?
                                    <h4 className='mt-5'>No Records, select school & section</h4>
                                    :
                                    <>
                                        <div className='' style={{ backgroundColor: "#FDFEFE", padding: "", boxShadow: "0px 3px 10px grey", marginTop: "40px", borderRadius: "8px", minHeight: "350px" }}>
                                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                                <TabContext value={value}>
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <TabList onChange={handleChange} aria-label="lab API tabs example"
                                                            style={{
                                                                backgroundColor: "#63a4ff",
                                                                backgroundImage: "linear-gradient(315deg, #63a4ff 0%, #83eaf1 74%)",
                                                                borderTopLeftRadius: "8px",
                                                                borderTopRightRadius: "8px"
                                                            }}>
                                                            {/* <div style={{ border: "1px solid black", backgroundColor: "#fff", borderRadius: "8px", margin: "10px" }}> */}
                                                            <Tab label="Voucher Entry Serial" value={1} style={{ fontSize: "16px", fontWeight: "700", textTransform: "capitalize" }} />
                                                            {/* </div> */}
                                                            <Tab label="Account Group Updation" value={2} style={{ fontSize: "16px", fontWeight: "700", textTransform: "capitalize" }} />

                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value={1}>
                                                        <div className='mt-2'>
                                                            <div className="">
                                                                <button className='btn btn-sm'
                                                                    type='button' onClick={() => setModal(true)}
                                                                    style={{
                                                                        backgroundColor: "#6D17CB",
                                                                        backgroundImage: "linear-gradient(315deg, #6D17CB 0%, #2876F9 74%)",
                                                                        color: "#fff",
                                                                        fontSize: "14px",
                                                                        fontWeight: "600"
                                                                    }}
                                                                >
                                                                    <EditIcon fontSize="small" /> Set Serial
                                                                </button>

                                                                <input
                                                                    className='form-control form-control-sm'
                                                                    type="text"
                                                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                                    placeholder="Search Here"
                                                                    value={searchTerm}
                                                                    onChange={(event) => { setSearchTerm(event.target.value); }}
                                                                />
                                                            </div>

                                                            <div className=''>

                                                            </div>

                                                            <div className='table-responsive mt-3' style={{ maxHeight: "600px" }}>
                                                                <table className="table table-bordered">
                                                                    <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                        <tr>
                                                                            {TableHeading.map((item, index) => {
                                                                                return (
                                                                                    <th style={{ fontSize: "13px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                        {filteredData
                                                                            .map((item, index) => {
                                                                                return (

                                                                                    <tr key={index}>
                                                                                        <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.voucherId}</td>
                                                                                        <td style={{ fontSize: "14px", fontWeight: "500" }}>{moment(item.voucherDate).format("DD/MM/YY")}</td>
                                                                                        {/* <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.6" }}>{(authUser.branchMedium == 1) ? item.accountCode + " - " + item.accountNameMr : item.accountCode + " - " + item.accountNameEn}</td> */}

                                                                                        {(() => {
                                                                                            if (authUser.branchId === 17 && authUser.deptId === 4) {
                                                                                                return (
                                                                                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.6" }}>{item.accountCode + " - " + item.accountNameMr}</td>
                                                                                                )
                                                                                            }
                                                                                            else {
                                                                                                return (
                                                                                                    //<td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.6" }}>{(authUser.branchMedium == 1) ? item.accountCode + " - " + item.accountNameMr : item.accountCode + " - " + item.accountNameEn}</td>
                                                                                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.6" }}>{item.accountCode + " - " + item.accountNameMr}</td>
                                                                                                )
                                                                                            }
                                                                                        })()}
                                                                                        <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.6" }}>{item.narration}</td>
                                                                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "80px" }}>{(item.transactionMode == 1) ? parseFloat(item.creditAmount).toFixed(2) : " "}</td>
                                                                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "80px" }}>{(item.transactionMode == 2) ? parseFloat(item.debitAmount).toFixed(2) : " "}</td>

                                                                                        <td>
                                                                                            {(() => {
                                                                                                if (item.transactionMode == 1 && item.isReceipt == 1) {
                                                                                                    return (
                                                                                                        <img src={ReceiptImage} alt="Receipt Image" title="Receipt" style={{ borderRadius: "0", height: "auto", width: "30px" }} />
                                                                                                    )
                                                                                                } else if (item.transactionMode == 2 && item.isReceipt == 1) {
                                                                                                    return (
                                                                                                        <img src={ChalanImage} alt="Chalan Image" title="Chalan" style={{ borderRadius: "0", height: "auto", width: "30px" }} />
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        ""
                                                                                                    )
                                                                                                }
                                                                                            })()}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value={2}>
                                                        {(() => {
                                                            if (accGroupForm == 1) {
                                                                return (
                                                                    <>
                                                                        <div className='row mb-1' style={{ float: 'right' }}>
                                                                            <div className=''>
                                                                                <input
                                                                                    className='form-control form-control-sm'
                                                                                    type="text"
                                                                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                                                    placeholder="Search Here"
                                                                                    value={searchTerm}
                                                                                    onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='table-responsive'>
                                                                            <TableContainer component={Paper}>
                                                                                <Table className="table table-bordered">
                                                                                    <TableHead className="table-Default">
                                                                                        <TableRow>
                                                                                            {TableHeading2.map((item, index) => {
                                                                                                return (
                                                                                                    <TableCell style={{ fontSize: "14px", fontWeight: "600", lineHeight: '1.5' }} onClick={() => onSortAccData(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</TableCell>
                                                                                                )
                                                                                            })}
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                                        {accFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                                            .map((item, index) => {
                                                                                                return (
                                                                                                    <React.Fragment key={index}>
                                                                                                        <TableRow>
                                                                                                            <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</TableCell>
                                                                                                            <TableCell style={{ fontSize: "15px", fontWeight: "500", width: '80px' }}>{item.groupCode}</TableCell>
                                                                                                            <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.groupNameMr}</TableCell>
                                                                                                            <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.groupNameEn}</TableCell>
                                                                                                            <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                                                                {

                                                                                                                    item.sectionId.map((e, index) => {
                                                                                                                        if (e.id == 1) {
                                                                                                                            return (
                                                                                                                                <span className='badge' key={index} style={{ backgroundColor: "#6C3483", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        else if (e.id == 2) {
                                                                                                                            return (
                                                                                                                                <span className='badge' key={index} style={{ backgroundColor: "#2980B9", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        else if (e.id == 3) {
                                                                                                                            return (
                                                                                                                                <span className='badge' key={index} style={{ backgroundColor: "#16A085", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        else if (e.id == 4) {
                                                                                                                            return (
                                                                                                                                <span className='badge' key={index} style={{ backgroundColor: "#F39C12", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        else if (e.id == 5) {
                                                                                                                            return (
                                                                                                                                <span className='badge' key={index} style={{ backgroundColor: "#34495E", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            return (
                                                                                                                                <span className='badge' key={index} style={{ backgroundColor: "red", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    })
                                                                                                                }

                                                                                                            </TableCell>
                                                                                                            <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.index}</TableCell>

                                                                                                            <TableCell>
                                                                                                                <Tooltip title="Edit">
                                                                                                                    <button className='btn btn-primary btn-sm mx-1 p-1'
                                                                                                                        // disabled={(authUser.roleId == 5) ? false : true}
                                                                                                                        // data-bs-toggle="modal" data-bs-target="#accountUpdateModal"
                                                                                                                        data-bs-toggle="toggle"
                                                                                                                        onClick={() => UpdateData(item)}
                                                                                                                    >
                                                                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                                                                    </button>
                                                                                                                </Tooltip>
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })}
                                                                                    </TableBody>
                                                                                    <TableFooter style={{ padding: "0px" }}>
                                                                                        <TableRow>
                                                                                            <TablePagination
                                                                                                style={{ padding: "0px" }}
                                                                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                                                                count={accData.length}
                                                                                                rowsPerPage={rowsPerPage}
                                                                                                page={page}
                                                                                                onRowsPerPageChange={onRowsPerPageChange}
                                                                                                onPageChange={onPageChange}
                                                                                                labelRowsPerPage="Rows Per Page :"
                                                                                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                                                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                                                                        </TableRow>
                                                                                    </TableFooter>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            else if (accGroupForm == 2) {
                                                                return (
                                                                    <>
                                                                        <AccountGroupUpdateForm
                                                                            id={id}
                                                                            groupCode={groupCode}
                                                                            groupHead={groupHead}
                                                                            groupBook={groupBook}
                                                                            glNameMarathi={glNameMarathi}
                                                                            glNameEnglish={glNameEnglish}
                                                                            index={index}
                                                                            isForward={isForward}
                                                                            sectionData={deptData}
                                                                            sectionId={sectionId}
                                                                            sectionObj={deptObj}
                                                                            recAndPayGroupData={recAndPayGroupData}
                                                                            recAndPayGroupObj={recAndPayGroupObj}
                                                                            incExpAndBalSheetData={incExpAndBalSheetData}
                                                                            incExpAndBalSheetObj={incExpAndBalSheetObj}
                                                                            setId={setId}
                                                                            setGroupCode={setGroupCode}
                                                                            setGroupHead={setGroupHead}
                                                                            setGroupBook={setGroupBook}
                                                                            setGlNameMarathi={setGlNameMarathi}
                                                                            setGlNameEnglish={setGlNameEnglish}
                                                                            setIndex={setIndex}
                                                                            setIsForward={setIsForward}
                                                                            setSectionData={setDeptData}
                                                                            setSectionId={setSectionId}
                                                                            setSectionObj={setDeptObj}
                                                                            setRecAndPayGroupData={setRecAndPayGroupData}
                                                                            setRecAndPayGroupObj={setRecAndPayGroupObj}
                                                                            setIncExpAndBalSheetData={setIncExpAndBalSheetData}
                                                                            setIncExpAndBalSheetObj={setIncExpAndBalSheetObj}
                                                                            HandleForwardCheckbox={HandleForwardCheckbox}
                                                                            authUser={authUser}
                                                                            setAccGroupForm={setAccGroupForm}
                                                                            getIncExpAndBalSheetGroups={getIncExpAndBalSheetGroups}
                                                                            updateAccountGroup={updateAccountGroup}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </TabPanel>
                                                </TabContext>
                                            </Box>
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
            }

            <Modal show={modal}>
                <Modal.Header>
                    <Modal.Title>Are you sure, you want to set serial?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModal(false)}>Close</Button>
                    <Button variant="danger" onClick={() => setVoucherSerialNo()}>Save</Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default SansthaAdminPage