import React, { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { saveAuthorMasterAPIURL } from '../LibraryServices/AuthorMasterAPIURL';
import axios from 'axios';
import { useSelector } from 'react-redux';

function AddNewAuthorMasterModal({ showAuthorModal, setShowAuthorModal, getApiData }) {
    const authUser = useSelector((state) => state.user.value);
    const handleClose = () => setShowAuthorModal(false);
    const handleShow = () => setShowAuthorModal(true);

    const [authorName, setAuthorName] = useState("");
    const [shortName, setShortName] = useState("");

    const saveBtnClick = async (e) => {
        handleClose();
        e.preventDefault();
        //setLoaderOption(true);
        const saveObj = {
            "branchId": authUser.branchId,
            "authorName": authorName,
            "shortName": shortName
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveAuthorMasterAPIURL, saveObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    //setLoaderOption(false);
                    toast.warn(`Author with this name is already exists.`)
                }
                else if (response.data == "SAVED") {
                    setAuthorName("");
                    setShortName("");                    
                    getApiData();
                    //setLoaderOption(false);
                    toast.success("Author master saved successfully.")
                }

            }).catch(error => {
                //setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    // useEffect(() => {

    //     function handlekeydownEvent(event) {
    //         if (event.key === "Enter") {
    //             // if (password != "") {
    //             console.log('Enter is pressed!');
    //             event.preventDefault();
    //             let btn = document.querySelector('.authorModalBtn')
    //             //console.log(btn)
    //             if (btn !== null) {
    //                 btn.click();
    //             }
    //             //}
    //         }
    //     }

    //     document.addEventListener('keypress', handlekeydownEvent)
    //     return () => {
    //         document.removeEventListener('keypress', handlekeydownEvent)
    //     }
    // }, []);

    return (
        <>

            <Modal show={showAuthorModal} onHide={handleClose} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "20px" }}>Add New Author</Modal.Title>
                </Modal.Header>
                <Form onSubmit={saveBtnClick}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            {/* <Form.Label style={{ fontSize: "15px", fontWeight: "600" }}>Author Name :</Form.Label> */}
                            <Form.Control
                                type="text"
                                value={authorName}
                                onChange={(e) => setAuthorName(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? authorName : ''}
                                placeholder="Author Name"
                                autoComplete='off'
                                style={{ fontSize: "14px", fontWeight: "500" }}
                                required
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            {/* <Form.Label style={{ fontSize: "15px", fontWeight: "600" }}>Short Name :</Form.Label> */}
                            <Form.Control
                                type="text"
                                value={shortName}
                                onChange={(e) => setShortName(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? shortName : ''}
                                placeholder="Short Name"
                                autoComplete='off'
                                style={{ fontSize: "14px", fontWeight: "500" }}
                            //required
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className="authorModalBtn">
                            Save
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddNewAuthorMasterModal