import { BaseURL, BaseUrlLibraryMaster, dBaseURL } from "../../../CommonServices/APIURL";


//Issue Register Report APIURL
//localhost:8080/institute/sss/library/circulation/reports/generateBookIssueRegister?langId=1&memberType=2&bid=1&dateFrom=2022-12-10&dateTo=2023-02-13&sessionYear=2022
export const generateBookIssueRegister = BaseURL + "library/circulation/reports/generateBookIssueRegister";


//Return Register Report APIURL
//localhost:8080/institute/sss/library/circulation/reports/generateBookReturnRegister?langId=1&memberType=2&bid=1&dateFrom=2022-12-10&dateTo=2023-02-13&sessionYear=2022
export const generateBookReturnRegister = BaseURL + "library/circulation/reports/generateBookReturnRegister";


//Daily Transaction Report APIURL
//localhost:8080/institute/sss/library/circulation/reports/generateDailyBookIssueReturnRegister?langId=1&memberType=2&bid=1&dateFrom=2022-12-10&dateTo=2023-02-13&sessionYear=2022
export const generateDailyTransactionReport = BaseURL + "library/circulation/reports/generateDailyBookIssueReturnRegister";


//Defaulter List APIURL
//localhost:8080/institute/sss/library/circulation/reports/generateDefaulterRegister?langId=1&memberType=2&bid=1&sessionYear=2022&dateAsOn=2022-12-31
export const generateDefaulterRegister = BaseURL + "library/circulation/reports/generateDefaulterRegister";


//Book Barcode APIURL
//localhost:8080/institute/sss/library/circulation/reports/generateBookBarcode?registerType=1&accFrom=1&accTo=5&selectedAccNo=1,3&option=1&barcodeSize=1&noOfCopy=2&bid=1
export const generateBookBarcodeAPIURL = BaseURL + "library/circulation/reports/generateBookBarcode";


//Book Bank report APIURL
//getAllBookBankGroup
export const getBookBankGroupDetails = BaseUrlLibraryMaster + "getAllBookBankGroup";

// /localhost:8080/institute/sss/library/circulation/getBookIssuedMemberList?sessionYear=2022&bid=1
export const getBookBankIssueMemberList = BaseURL + "library/circulation/getBookIssuedMemberList";

// /localhost:8080/institute/sss/library/circulation/reports/generateBookBankIssueRegister?langId=1&dateFrom=2022-01-06&dateTo=2023-01-20&sessionYear=2022&bid=1&option=2&grpMemId=258
export const printOnlyIssuedBookBankReport = BaseURL + "library/circulation/reports/generateBookBankIssueRegister";

//localhost:8080/institute/sss/library/circulation/reports/generateBookBankIssueReturnRegister?langId=1&dateFrom=2022-01-06&dateTo=2023-01-20&sessionYear=2022&bid=1&option=2&grpMemId=256
export const printAllBookBankIssueReport = BaseURL + "library/circulation/reports/generateBookBankIssueReturnRegister";


//Withdrawal Register Report APIURL
//localhost:8080/institute/sss/library/circulation/reports/getWithdrawalRegisterPdfReport?langId=1&regType=0&option=1&withdrlType=0&dateFrom=2022-12-12&dateTo=2023-01-03&noFrom=0&noTo=0&sessionYear=2022&bid=1
export const printWithdrawalEntryPdfReport = BaseURL + "library/circulation/reports/getWithdrawalRegisterPdfReport";

// /getWithdrawalRegisterExcelReport
export const printWithdrawalEntryExcelReport = BaseURL + "library/circulation/reports/getWithdrawalRegisterExcelReport";


//Member Statement APIURL
// /localhost:8080/institute/sss/library/circulation/reports/getMemberStatementPdfReport?langId=1&sessionYear=2022&bid=1&memType=2&memFrom=1&memTo=100000
export const printMemberStatementPdfReport = BaseURL + "library/circulation/reports/getMemberStatementPdfReport";

//getMemberStatementExcelReport
export const printMemberStatementExcelReport = BaseURL + "library/circulation/reports/getMemberStatementExcelReport";