import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import { useSelector } from "react-redux";
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import makeStyles from "@mui/styles/makeStyles";
import TablePagination from '@mui/material/TablePagination'
import AddIcon from '@mui/icons-material/Add';
import termJSON from '../../CommonComponent/LocalJSON/TermData';
import Loader from '../../CommonComponent/Loader';
import { Modal, Button } from "react-bootstrap";

import {
    getClassesBySectionAndBranch,
    getDivisionsByClassAndSectionAndBranch
} from "../Services/ExamMarkAllocationAPIURL";

import {
    getSubjectsByExamAllocation,
    getSubjectExamMarkDataByTerm,
    getSubExamMarkDataByTermSubjectExam,
    getExamMarkEntryDataAllData,
    saveExamMarkEntryData
} from '../Services/ExamMarkEntryAPIURL';

import ExamMarkEntryTableRow1 from './ExamMarkEntryTable/ExamMarkEntryTableRow1';
import ExamMarkEntryTableRow2 from './ExamMarkEntryTable/ExamMarkEntryTableRow2';
import ExamMarkEntryTableRow3 from './ExamMarkEntryTable/ExamMarkEntryTableRow3';
import ExamMarkEntryTableRow4 from './ExamMarkEntryTable/ExamMarkEntryTableRow4';
import ExamMarkEntryTableRow5 from './ExamMarkEntryTable/ExamMarkEntryTableRow5';
import ExamMarkEntryTableRow6 from './ExamMarkEntryTable/ExamMarkEntryTableRow6';
import ExamMarkEntryTableRow7 from './ExamMarkEntryTable/ExamMarkEntryTableRow7';
import ExamMarkEntryTableRow8 from './ExamMarkEntryTable/ExamMarkEntryTableRow8';
import ExamMarkEntryTableRow9 from './ExamMarkEntryTable/ExamMarkEntryTableRow9';
import ExamMarkEntryTableRow10 from './ExamMarkEntryTable/ExamMarkEntryTableRow10';

import ExamMarkEntryHead1 from './ExamMarkEntryTable/ExamMarkEntryHead1';
import ExamMarkEntryHead2 from './ExamMarkEntryTable/ExamMarkEntryHead2';
import ExamMarkEntryHead3 from './ExamMarkEntryTable/ExamMarkEntryHead3';
import ExamMarkEntryHead4 from './ExamMarkEntryTable/ExamMarkEntryHead4';
import ExamMarkEntryHead5 from './ExamMarkEntryTable/ExamMarkEntryHead5';
import ExamMarkEntryHead6 from './ExamMarkEntryTable/ExamMarkEntryHead6';
import ExamMarkEntryHead7 from './ExamMarkEntryTable/ExamMarkEntryHead7';
import ExamMarkEntryHead8 from './ExamMarkEntryTable/ExamMarkEntryHead8';
import ExamMarkEntryHead9 from './ExamMarkEntryTable/ExamMarkEntryHead9';
import ExamMarkEntryHead10 from './ExamMarkEntryTable/ExamMarkEntryHead10';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    }
});


const ExamMarkEntry = () => {
    const classes = useStyles();
    const titleId = "Exam Marks Entry";
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    //Data Collection
    const [classData, setClassData] = useState([]);
    const [divisionData, setDivisionData] = useState([]);
    const [termData, setTermData] = useState(termJSON);
    const [subjectData, setSubjectData] = useState([]);
    const [examData, setExamtData] = useState([]);
    const [subExamData, setSubExamtData] = useState([]);

    //Autocomplete Objects
    const [classObj, setClassObj] = useState(null);
    const [divisionObj, setDivisionObj] = useState(null);
    const [termObj, setTermObj] = useState(null);
    const [subjectObj, setSubjectObj] = useState(null);
    const [examObj, setExamObj] = useState(null);
    const [subExamObj, setSubExamObj] = useState(null);
    const [subExamId, setSubExamId] = useState(0)

    const [minMarks, setMinMarks] = useState("0");
    const [maxMarks, setMaxMarks] = useState("0");

    const [examMarkStatus, setExamMarkStatus] = useState(0);

    //Bulk mark entry
    const [s1, setS1] = useState(0);
    const [s2, setS2] = useState(0);
    const [s3, setS3] = useState(0);
    const [s4, setS4] = useState(0);
    const [s5, setS5] = useState(0);
    const [s6, setS6] = useState(0);
    const [s7, setS7] = useState(0);
    const [s8, setS8] = useState(0);
    const [s9, setS9] = useState(0);
    const [s10, setS10] = useState(0);


    //Table Start
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const [tableHeadding, setTableHeadding] = useState([]);
    const [totalExamCount, setTotalExamCount] = useState(null);

    //Table End

    useEffect(() => {
        getClassData();
    }, [authUser])

    const getClassData = async () => {
        if (authUser.branchId != null && authUser.deptId != null) {
            await axios(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                });
        }
    }

    const getDivisionData = async (classId) => {

        await axios(`${getDivisionsByClassAndSectionAndBranch}?cid=${classId}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setDivisionData(res.data);
            });
    }

    const getSubjectData = async (tId) => {
        //console.log(`${getSubjectsByExamAllocation}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${tId}&branchMedium=${authUser.branchMedium}`)
        if (classObj != null && divisionObj != null) {
            await axios(`${getSubjectsByExamAllocation}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${tId}&branchMedium=${authUser.branchMedium}`)
                .then(res => {
                    setSubjectData(res.data);
                });
        }
    }

    const getExamData = async (subId) => {
        console.log(`${getSubjectExamMarkDataByTerm}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${termObj.id}&subjid=${subId}&branchMedium=${authUser.branchMedium}`);
        await axios(`${getSubjectExamMarkDataByTerm}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${termObj.id}&subjid=${subId}&branchMedium=${authUser.branchMedium}`)
            .then(res => {
                setExamtData(res.data);
            });
    }

    const getSubExamData = async (examId) => {
        console.log(`${getSubExamMarkDataByTermSubjectExam}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${termObj.id}&subjid=${subjectObj.id}&eid=${examId}&branchMedium=${authUser.branchMedium}`);
        await axios(`${getSubExamMarkDataByTermSubjectExam}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${termObj.id}&subjid=${subjectObj.id}&eid=${examId}&branchMedium=${authUser.branchMedium}`)
            .then(res => {
                setSubExamtData(res.data);
            });
    }

    //console.log("subExamObjsubExamObjsubExamObj : " + JSON.stringify(subExamObj))

    const getExamMarkEntryData = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setTableHeadding([]);
        setTotalExamCount([]);
        setFilteredData([]);
        setData([]);
        setSortedBy(null);
        setAscending(false);
        console.log(`${getExamMarkEntryDataAllData}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${termObj.id}&eid=${examObj.examId}&seid=${subExamId}&sbjid=${subjectObj.id}&branchMedium=${authUser.branchMedium}`)
        await axios.get(`${getExamMarkEntryDataAllData}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${termObj.id}&eid=${examObj.examId}&seid=${subExamId}&sbjid=${subjectObj.id}&branchMedium=${authUser.branchMedium}`)
            .then(res => {
                console.log(JSON.stringify(res.data.TableHeading));
                // console.log(JSON.stringify(res.data.examCount));
                setExamMarkStatus(parseInt(res.data.examMarkStatus[0]))
                if (res.data.TableData == "" || res.data.TableData == [] || res.data.TableData == null) {
                    setLoaderOption(false);
                    toast.warn("Student or Roll No not allocated.")
                }
                else {
                    let newData = res.data.TableHeading;
                    let updatedData = [...newData, {
                        "label": "Total Marks",
                        "key": "totalMarks", "action": false
                    }]
                    setTableHeadding(updatedData);
                    setTotalExamCount(res.data.examCount);
                    setFilteredData(res.data.TableData);
                    setData(res.data.TableData);
                    setS1(0);
                    setS2(0);
                    setS3(0);
                    setS4(0);
                    setS5(0);
                    setS6(0);
                    setS7(0);
                    setS8(0);
                    setS9(0);
                    setS10(0);
                    setLoaderOption(false);
                }
            });
    }

    const [modal, setModal] = useState(false);

    const handleModal = e => {
        e.preventDefault();
        if (examMarkStatus == 1) {
            setModal(false);
        }
        else {
            setModal(true);
        }

    }

    const saveExamMarkData = () => {
        setModal(false)
        setLoaderOption(true);
        //e.preventDefault();
        const saveObject = {
            "TableData": data,
            "examCount": totalExamCount
        }
        console.log(JSON.stringify(saveObject))
        axios.post(`${saveExamMarkEntryData}`, saveObject)
            .then((response) => {
                if (response.data == "SAVED") {
                    setFilteredData([]);
                    setData([]);
                    setLoaderOption(false);
                    toast.success("Mark entry done.");
                }
            })
            .catch(error => {
                console.log("Exam mark entry: " + error)
                toast.error("Something went wrong.");
            })

    }

    const bulkMarkEntryFun = async () => {
        setLoaderOption(true);
        setFilteredData([]);
        setData([]);

        await axios.get(`${getExamMarkEntryDataAllData}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${classObj.id}&did=${divisionObj.id}&tid=${termObj.id}&eid=${examObj.examId}&seid=${subExamId}&sbjid=${subjectObj.id}&branchMedium=${authUser.branchMedium}`)
            .then(res => {
                //console.log(JSON.stringify(res))
                if (res.data.TableData == "" || res.data.TableData == [] || res.data.TableData == null) {
                    setLoaderOption(false);
                    toast.warn("Student or Roll No not allocated.")
                }
                else {
                    let updatedData = res.data.TableData;
                    (res.data.TableData).map((item, index) => {
                        updatedData[index] = ({ ...item, s1: s1, s2: s2, s3: s3, s4: s4, s5: s5, s6: s6, s7: s7, s8: s8, s9: s9, s10: s10 })
                    })
                    setFilteredData(res.data.TableData);
                    setData(res.data.TableData);
                    setLoaderOption(false);
                }
            });
    }

    // useEffect(() => {
    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             console.log("Enter key was pressed. Run your function.");
    //             event.preventDefault();
    //             let btn = document.querySelectorAll('.examMarkEntryBtn')
    //             if (btn !== null) {
    //                 btn.click();
    //             }
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);


    const handelkeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            let btn = document.querySelector('.examMarkEntryBtn')
            if (btn !== null) {
                btn.click();
            }
        }
    };

    useEffect(() => {
        //console.log('useEffect Called');
        document.addEventListener("keypress", handelkeyPress);

        return () => {
            // remove the event handelkeyPress
            document.removeEventListener("keypress", handelkeyPress);
        };
    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={getExamMarkEntryData}>

                        <div className="row">
                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={classData}
                                    value={classObj}
                                    onChange={(event, newValue) => {
                                        setClassObj(newValue);
                                        setDivisionObj(null);
                                        setTermObj(null);
                                        setSubjectData([]);
                                        setSubjectObj(null);
                                        setExamtData([]);
                                        setExamObj(null);
                                        setSubExamObj(null);
                                        setSubExamtData([])
                                        setMinMarks(0);
                                        setMaxMarks(0);
                                        setTableHeadding([]);
                                        setTotalExamCount([]);
                                        setFilteredData([]);
                                        setData([]);
                                        if (newValue != null) {
                                            getDivisionData(newValue.id);
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Class" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Division: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={divisionData}
                                    value={divisionObj}
                                    onChange={(event, newValue) => {
                                        setDivisionObj(newValue);
                                        setTermObj(null);
                                        setSubjectData([]);
                                        setSubjectObj(null);
                                        setExamtData([]);
                                        setExamObj(null);
                                        setSubExamObj(null);
                                        setSubExamtData([]);
                                        setMinMarks(0);
                                        setMaxMarks(0);
                                        setTableHeadding([]);
                                        setTotalExamCount([]);
                                        setFilteredData([]);
                                        setData([]);
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={termData}
                                    value={termObj}
                                    onChange={(event, newValue) => {
                                        setTermObj(newValue);
                                        setSubjectObj(null);
                                        setExamtData([]);
                                        setExamObj(null);
                                        setSubExamObj(null);
                                        setSubExamtData([])
                                        setMinMarks(0);
                                        setMaxMarks(0);
                                        setTableHeadding([]);
                                        setTotalExamCount([]);
                                        setFilteredData([]);
                                        setData([]);
                                        if (newValue != null) {
                                            getSubjectData(newValue.id);
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Term" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={subjectData}
                                    value={subjectObj}
                                    onChange={(event, newValue) => {
                                        setSubjectObj(newValue);
                                        setExamtData([]);
                                        setExamObj(null);
                                        setSubExamObj(null);
                                        setSubExamtData([])
                                        setMinMarks(0);
                                        setMaxMarks(0);
                                        setTableHeadding([]);
                                        setTotalExamCount([]);
                                        setFilteredData([]);
                                        setData([]);
                                        if (newValue != null) {
                                            getExamData(newValue.id);
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={examData}
                                    value={examObj}
                                    onChange={(event, newValue) => {
                                        setExamObj(newValue);
                                        //console.log(JSON.stringify(newValue))                                       
                                        setSubExamObj(null);
                                        setSubExamtData([]);
                                        setTableHeadding([]);
                                        setTotalExamCount([]);
                                        setFilteredData([]);
                                        setData([]);
                                        if (newValue != null) {
                                            getSubExamData(newValue.examId);
                                            setMinMarks(newValue.minMark);
                                            setMaxMarks(newValue.maxMark);
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.examNameMr : option.examNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>SubExam: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={subExamData}
                                    value={subExamObj}
                                    onChange={(event, newValue) => {
                                        if (newValue == "" || newValue == null || newValue == []) {
                                            setSubExamObj(null);
                                            setSubExamId(0);
                                            setMinMarks(examObj.minMark);
                                            setMaxMarks(examObj.maxMark);
                                            setTableHeadding([]);
                                            setTotalExamCount([]);
                                            setFilteredData([]);
                                            setData([]);
                                        }
                                        else {
                                            setSubExamObj(newValue);
                                            setSubExamId(newValue.subExamId);
                                            setMinMarks(newValue.minMark);
                                            setMaxMarks(newValue.maxMark);
                                            //console.log(JSON.stringify(newValue));
                                            setTableHeadding([]);
                                            setTotalExamCount([]);
                                            setFilteredData([]);
                                            setData([]);
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.subExamNameMr : option.subExamNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Sub-Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Min/Max: </label>
                            </div>
                            <div className="col-sm-1">
                                <TextField
                                    required
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    value={minMarks}
                                    onChange={e => setMinMarks(e.target.value)}
                                    size='small'
                                    id="outlined-basic"
                                    label={"Min"}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14, width: '50px' } }}
                                />
                            </div>

                            <div className="col-sm-1">
                                <TextField
                                    required
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    value={maxMarks}
                                    onChange={e => setMaxMarks(e.target.value)}
                                    size='small'
                                    id="outlined-basic"
                                    label={"Max"}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14, width: '50px' } }}
                                />
                            </div>

                            <div className="col-sm-4 mt-2">
                                <button type='submit' className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} >
                                    <SearchIcon fontSize="small" />
                                    VIEW
                                </button>
                            </div>
                        </div>
                    </form>
                    <hr />
                    {
                        (data == "" || data == [] || data == null)
                            ?
                            <h4>No records found.</h4>
                            :
                            <div className='row mt-3'>
                                <form onSubmit={handleModal}>
                                    <div className='table-responsive'>
                                        <table className="table table-bordered">

                                            {(() => {
                                                if (totalExamCount == 1) {
                                                    return (
                                                        <ExamMarkEntryHead1 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 2) {
                                                    return (
                                                        <ExamMarkEntryHead2 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 3) {
                                                    return (
                                                        <ExamMarkEntryHead3 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} setS3={setS3} s3={s3} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 4) {
                                                    return (
                                                        <ExamMarkEntryHead4 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} setS3={setS3} s3={s3} setS4={setS4} s4={s4} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 5) {
                                                    return (
                                                        <ExamMarkEntryHead5 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} setS3={setS3} s3={s3} setS4={setS4} s4={s4} setS5={setS5} s5={s5} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 6) {
                                                    return (
                                                        <ExamMarkEntryHead6 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} setS3={setS3} s3={s3} setS4={setS4} s4={s4} setS5={setS5} s5={s5} setS6={setS6} s6={s6} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 7) {
                                                    return (
                                                        <ExamMarkEntryHead7 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} setS3={setS3} s3={s3} setS4={setS4} s4={s4} setS5={setS5} s5={s5} setS6={setS6} s6={s6} setS7={setS7} s7={s7} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 8) {
                                                    return (
                                                        <ExamMarkEntryHead8 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} setS3={setS3} s3={s3} setS4={setS4} s4={s4} setS5={setS5} s5={s5} setS6={setS6} s6={s6} setS7={setS7} s7={s7} setS8={setS8} s8={s8} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 9) {
                                                    return (
                                                        <ExamMarkEntryHead9 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} setS3={setS3} s3={s3} setS4={setS4} s4={s4} setS5={setS5} s5={s5} setS6={setS6} s6={s6} setS7={setS7} s7={s7} setS8={setS8} s8={s8} setS9={setS9} s9={s9} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                                else if (totalExamCount == 10) {
                                                    return (
                                                        <ExamMarkEntryHead10 bulkMarkEntryFun={bulkMarkEntryFun} setS1={setS1} s1={s1} setS2={setS2} s2={s2} setS3={setS3} s3={s3} setS4={setS4} s4={s4} setS5={setS5} s5={s5} setS6={setS6} s6={s6} setS7={setS7} s7={s7} setS8={setS8} s8={s8} setS9={setS9} s9={s9} setS10={setS10} s10={s10} examMarkStatus={examMarkStatus} />
                                                    )
                                                }
                                            })()}

                                            <thead className="table-Default">
                                                <tr>
                                                    {tableHeadding.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: '1.5' }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>

                                                {filteredData.map((item, index) => {

                                                    if (totalExamCount == 1) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1);
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow1
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s1_max={item.s1_max}
                                                                        onChange={(statuss, s1text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 2) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2);
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow2
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        onChange={(statuss, s1text, s2text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 3) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2) + parseInt((item.s3 == "") ? 0 : item.s3);
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow3
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s3={(item.s3 == 0) ? '' : item.s3}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        s3_max={item.s3_max}
                                                                        onChange={(statuss, s1text, s2text, s3text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text, s3: s3text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 4) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2) + parseInt((item.s3 == "") ? 0 : item.s3) + parseInt((item.s4 == "") ? 0 : item.s4);
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow4
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s3={(item.s3 == 0) ? '' : item.s3}
                                                                        s4={(item.s4 == 0) ? '' : item.s4}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        s3_max={item.s3_max}
                                                                        s4_max={item.s4_max}
                                                                        onChange={(statuss, s1text, s2text, s3text, s4text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text, s3: s3text, s4: s4text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 5) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2) + parseInt((item.s3 == "") ? 0 : item.s3) + parseInt((item.s4 == "") ? 0 : item.s4) + parseInt((item.s5 == "") ? 0 : item.s5);
                                                        console.log(totalMarksCount)
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow5
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s3={(item.s3 == 0) ? '' : item.s3}
                                                                        s4={(item.s4 == 0) ? '' : item.s4}
                                                                        s5={(item.s5 == 0) ? '' : item.s5}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        s3_max={item.s3_max}
                                                                        s4_max={item.s4_max}
                                                                        s5_max={item.s5_max}
                                                                        onChange={(statuss, s1text, s2text, s3text, s4text, s5text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text, s3: s3text, s4: s4text, s5: s5text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log("updatedData" + JSON.stringify(updatedData));
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 6) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2) + parseInt((item.s3 == "") ? 0 : item.s3) + parseInt((item.s4 == "") ? 0 : item.s4) + parseInt((item.s5 == "") ? 0 : item.s5) + parseInt((item.s6 == "") ? 0 : item.s6);
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow6
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s3={(item.s3 == 0) ? '' : item.s3}
                                                                        s4={(item.s4 == 0) ? '' : item.s4}
                                                                        s5={(item.s5 == 0) ? '' : item.s5}
                                                                        s6={(item.s6 == 0) ? '' : item.s6}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        s3_max={item.s3_max}
                                                                        s4_max={item.s4_max}
                                                                        s5_max={item.s5_max}
                                                                        s6_max={item.s6_max}
                                                                        onChange={(statuss, s1text, s2text, s3text, s4text, s5text, s6text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text, s3: s3text, s4: s4text, s5: s5text, s6: s6text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 7) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2) + parseInt((item.s3 == "") ? 0 : item.s3) + parseInt((item.s4 == "") ? 0 : item.s4) + parseInt((item.s5 == "") ? 0 : item.s5) + parseInt((item.s6 == "") ? 0 : item.s6) + parseInt((item.s7 == "") ? 0 : item.s7);
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow7
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s3={(item.s3 == 0) ? '' : item.s3}
                                                                        s4={(item.s4 == 0) ? '' : item.s4}
                                                                        s5={(item.s5 == 0) ? '' : item.s5}
                                                                        s6={(item.s6 == 0) ? '' : item.s6}
                                                                        s7={(item.s7 == 0) ? '' : item.s7}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        s3_max={item.s3_max}
                                                                        s4_max={item.s4_max}
                                                                        s5_max={item.s5_max}
                                                                        s6_max={item.s6_max}
                                                                        s7_max={item.s7_max}
                                                                        onChange={(statuss, s1text, s2text, s3text, s4text, s5text, s6text, s7text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text, s3: s3text, s4: s4text, s5: s5text, s6: s6text, s7: s7text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 8) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2) + parseInt((item.s3 == "") ? 0 : item.s3) + parseInt((item.s4 == "") ? 0 : item.s4) + parseInt((item.s5 == "") ? 0 : item.s5) + parseInt((item.s6 == "") ? 0 : item.s6) + parseInt((item.s7 == "") ? 0 : item.s7) + parseInt((item.s8 == "") ? 0 : item.s8);
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow8
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s3={(item.s3 == 0) ? '' : item.s3}
                                                                        s4={(item.s4 == 0) ? '' : item.s4}
                                                                        s5={(item.s5 == 0) ? '' : item.s5}
                                                                        s6={(item.s6 == 0) ? '' : item.s6}
                                                                        s7={(item.s7 == 0) ? '' : item.s7}
                                                                        s8={(item.s8 == 0) ? '' : item.s8}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        s3_max={item.s3_max}
                                                                        s4_max={item.s4_max}
                                                                        s5_max={item.s5_max}
                                                                        s6_max={item.s6_max}
                                                                        s7_max={item.s7_max}
                                                                        s8_max={item.s8_max}
                                                                        onChange={(statuss, s1text, s2text, s3text, s4text, s5text, s6text, s7text, s8text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text, s3: s3text, s4: s4text, s5: s5text, s6: s6text, s7: s7text, s8: s8text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 9) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2) + parseInt((item.s3 == "") ? 0 : item.s3) + parseInt((item.s4 == "") ? 0 : item.s4) + parseInt((item.s5 == "") ? 0 : item.s5) + parseInt((item.s6 == "") ? 0 : item.s6) + parseInt((item.s7 == "") ? 0 : item.s7) + parseInt((item.s8 == "") ? 0 : item.s8) + parseInt((item.s9 == "") ? 0 : item.s9);
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow9
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s3={(item.s3 == 0) ? '' : item.s3}
                                                                        s4={(item.s4 == 0) ? '' : item.s4}
                                                                        s5={(item.s5 == 0) ? '' : item.s5}
                                                                        s6={(item.s6 == 0) ? '' : item.s6}
                                                                        s7={(item.s7 == 0) ? '' : item.s7}
                                                                        s8={(item.s8 == 0) ? '' : item.s8}
                                                                        s9={(item.s9 == 0) ? '' : item.s9}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        s3_max={item.s3_max}
                                                                        s4_max={item.s4_max}
                                                                        s5_max={item.s5_max}
                                                                        s6_max={item.s6_max}
                                                                        s7_max={item.s7_max}
                                                                        s8_max={item.s8_max}
                                                                        s9_max={item.s9_max}
                                                                        onChange={(statuss, s1text, s2text, s3text, s4text, s5text, s6text, s7text, s8text, s9text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text, s3: s3text, s4: s4text, s5: s5text, s6: s6text, s7: s7text, s8: s8text, s9: s9text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                    else if (totalExamCount == 10) {
                                                        const totalMarksCount = parseInt((item.s1 == "") ? 0 : item.s1) + parseInt((item.s2 == "") ? 0 : item.s2) + parseInt((item.s3 == "") ? 0 : item.s3) + parseInt((item.s4 == "") ? 0 : item.s4) + parseInt((item.s5 == "") ? 0 : item.s5) + parseInt((item.s6 == "") ? 0 : item.s6) + parseInt((item.s7 == "") ? 0 : item.s7) + parseInt((item.s8 == "") ? 0 : item.s8) + parseInt((item.s9 == "") ? 0 : item.s9) + parseInt((item.s10 == "") ? 0 : item.s10);
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                    <ExamMarkEntryTableRow10
                                                                        examMarkStatus={examMarkStatus}
                                                                        status={item.status}
                                                                        s1={(item.s1 == 0) ? '' : item.s1}
                                                                        s2={(item.s2 == 0) ? '' : item.s2}
                                                                        s3={(item.s3 == 0) ? '' : item.s3}
                                                                        s4={(item.s4 == 0) ? '' : item.s4}
                                                                        s5={(item.s5 == 0) ? '' : item.s5}
                                                                        s6={(item.s6 == 0) ? '' : item.s6}
                                                                        s7={(item.s7 == 0) ? '' : item.s7}
                                                                        s8={(item.s8 == 0) ? '' : item.s8}
                                                                        s9={(item.s9 == 0) ? '' : item.s9}
                                                                        s10={(item.s10 == 0) ? '' : item.s10}
                                                                        s1_max={item.s1_max}
                                                                        s2_max={item.s2_max}
                                                                        s3_max={item.s3_max}
                                                                        s4_max={item.s4_max}
                                                                        s5_max={item.s5_max}
                                                                        s6_max={item.s6_max}
                                                                        s7_max={item.s7_max}
                                                                        s8_max={item.s8_max}
                                                                        s9_max={item.s9_max}
                                                                        s10_max={item.s10_max}
                                                                        onChange={(statuss, s1text, s2text, s3text, s4text, s5text, s6text, s7text, s8text, s9text, s10text) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, status: statuss, s1: s1text, s2: s2text, s3: s3text, s4: s4text, s5: s5text, s6: s6text, s7: s7text, s8: s8text, s9: s9text, s10: s10text });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);

                                                                            console.log(data);
                                                                        }}
                                                                    />
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{totalMarksCount}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }

                                                })}

                                            </tbody>
                                            {/* <tfoot style={{ padding: "0px" }}>
                                    <TablePagination
                                        style={{ padding: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        classes={{ toolbar: classes.toolbar, caption: classes.caption }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                </tfoot> */}
                                        </table>
                                    </div>
                                    <div className='mt-4'>
                                        <button type='submit' disabled={(examMarkStatus == 1) ? true : false} className='examMarkEntryBtn btn btn-primary btn-sm mb-2' ><AddIcon fontSize="small" />Save Marks</button>
                                    </div>
                                </form>
                            </div>
                    }
                </div>

                <Modal show={modal}>
                    <Modal.Header>
                        <Modal.Title>Are you sure, you want to save?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModal(false)}>Close</Button>
                        <Button variant="danger" onClick={saveExamMarkData}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }

}

export default ExamMarkEntry