import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import {
    getClassWithDiv, generalRegistercheckListFields,
    generalRegisterChecklistAll, generalRegisterChecklistClassDivWise
} from '../Services/GeneralRegisterAPIURL';
import Loader from '../../CommonComponent/Loader';

import makeStyles from "@mui/styles/makeStyles";
import FormControlLabel from '@mui/material/FormControlLabel';


const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const GeneralRegisterChecklist = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "General Register Checklist";

    const [loaderOption, setLoaderOption] = useState(false);
    const [languageId, setLanguageId] = useState(1);

    const [view, setView] = useState(1);
    const [option, setOption] = useState(1);
    const [data, setData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [leftFilteredData, setLeftFilteredData] = useState([]);
    const [leftSearchTerm, setLeftSearchTerm] = useState([]);
    const [right, setRight] = useState([]);
    const [rightFilteredData, setRightFilteredData] = useState([]);
    const [rightSearchTerm, setRightSearchTerm] = useState([]);
    const [subOption, setSubOption] = useState(1);
    const [year, setYear] = useState('');
    const [leftStudents, setLeftStudents] = useState(0);    


    const HandleLeftStudentsCheckbox = (e) => {
        setLeftStudents(e.target.checked);
        if (e.target.checked == true) {
            setLeftStudents(1);
        }
        else {
            setLeftStudents(0);
        }
    }

    //console.log("rightData=" + JSON.stringify(right.sort((a, b) => a.srNo > b.srNo ? 1 : -1)))
    //+"................."+"LeftData="+JSON.stringify(left))

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                })
        }
        await axios(`${generalRegistercheckListFields}`)
            .then((response) => {
                setLeft(response.data);
                setLeftFilteredData(response.data)

            }).catch((error) => {
                console.log(error);
            })

    }

    const allStudentWiseGeneralRegReport = async () => {
        setLoaderOption(true);
        //console.log(`${generalRegisterChecklistAll}?langId=${languageId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        const getCheckListData = right.sort((a, b) => a.srNo > b.srNo ? 1 : -1);
        //console.log(JSON.stringify(getCheckListData))
        await axios.post(`${generalRegisterChecklistAll}?langId=${languageId}&acYear=${(subOption == 2) ? year : authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&subOption=${subOption}&leftStudent=${leftStudents}`, getCheckListData)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Students not found.")

                }
                else if (response.data != null) {
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("General Register checklist Report failed: " + error)
            })
    }

    const classDivWiseGeneralRegReport = async () => {
        setLoaderOption(true);
        const getCheckListData = right.sort((a, b) => a.srNo > b.srNo ? 1 : -1);
        //console.log(JSON.stringify(getCheckListData))
        await axios.post(`${generalRegisterChecklistClassDivWise}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&langId=${languageId}&acYear=${(subOption == 2) ? year : authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&subOption=${subOption}&leftStudent=${leftStudents}`, getCheckListData)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Students not found for this class-division.")

                }
                else if (response.data != null) {
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("General Register checklist Report failed: " + error)
            })
    }

    const handleToggle = (value) => () => {
        //console.log(value);    
        const currentIndex = checked.indexOf(value);
        //console.log("Data="+currentIndex)       
        const newChecked = [...checked];

        if (currentIndex === -1) {
            //console.log("Checked="+JSON.stringify(value.id))           
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        //console.log(newChecked.map(e=>e.id).join(','))
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setRightFilteredData(right.concat(left));
        setLeft([]);
        setLeftFilteredData([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setRightFilteredData(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setLeftFilteredData(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setLeftFilteredData(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setRightFilteredData(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setLeftFilteredData(left.concat(right));
        setRight([]);
        setRightFilteredData([]);
    };

    useEffect(() => {
        setLeftFilteredData(
            left.filter((item) =>
                item.srNo.toString().toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                item.fieldName.toLowerCase().includes(leftSearchTerm.toLowerCase())
            ))
    }, [leftSearchTerm])

    useEffect(() => {
        setRightFilteredData(
            right.filter((item) =>
                item.srNo.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                item.fieldName.toLowerCase().includes(rightSearchTerm.toLowerCase())
            ))
    }, [rightSearchTerm])

    const leftListData = (leftdata) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>

            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "200px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={leftSearchTerm}
                        onChange={e => setLeftSearchTerm(e.target.value)}
                    /></span>
                </div>

                {leftdata.sort((a, b) => a.srNo > b.srNo ? 1 : -1).map((value) => {
                    const labelId = value.srNo;
                    return (
                        <ListItem
                            key={value.srNo}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.srNo + ". " + value.fieldName} primaryTypographyProps={{ fontSize: '16px', fontWeight: "540", lineHeight: '1.5' }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const rightListData = (rightData) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>
            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "200px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={rightSearchTerm}
                        onChange={e => setRightSearchTerm(e.target.value)}
                    /></span>
                </div>
                {rightData.sort((a, b) => a.srNo > b.srNo ? 1 : -1).map((value) => {
                    const labelId = value.srNo;
                    return (
                        <ListItem
                            key={value.srNo}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.srNo + ". " + value.fieldName} primaryTypographyProps={{ fontSize: '16px', fontWeight: "540" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>

                    <div className='row my-2'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Language :</label>
                        <div className='col-sm-3'>
                            <FormControl fullWidth margin='dense'>
                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    size='small'
                                    required
                                    fullWidth

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={languageId}
                                    label="Select Language"
                                    onChange={(e) => {
                                        setLanguageId(e.target.value);
                                    }}
                                >
                                    <MenuItem value={1}>Marathi</MenuItem>
                                    <MenuItem value={2}>English</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                    </div>
                    <div className='row'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View :</label>
                        <div className='col-sm-3'>
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>View</InputLabel>
                                <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    size='small'
                                    required
                                    fullWidth

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={view}
                                    label="View"
                                    onChange={(e) => setView(e.target.value)}
                                >
                                    {/* <MenuItem value={1}>PDF</MenuItem> */}
                                    <MenuItem value={1}>Dynamic Excel</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className='row my-2'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                        <div className='col-sm-3'>
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    size='small'
                                    required
                                    fullWidth

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={option}
                                    label="Option"
                                    onChange={(e) => {
                                        setOption(e.target.value);
                                        setClassDivObj(null);
                                    }}
                                >
                                    <MenuItem value={1}>All Students</MenuItem>
                                    <MenuItem value={2}>Class-Division Wise</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {(() => {
                        if (option == 2) {
                            return (
                                <>
                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "12px" }}>Class-Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={data}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (languageId == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                value={classDivObj}
                                                onChange={(event, newValue) => {
                                                    setClassDivObj(newValue);
                                                }}
                                                renderInput={params => (
                                                    <TextField margin='dense' {...params} label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })()}
                    <div className='row my-2'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Option :</label>
                        <div className='col-sm-3'>
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Sub-Option</InputLabel>
                                <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    size='small'
                                    required
                                    fullWidth

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={subOption}
                                    label="Sub-Option"
                                    onChange={(e) => {
                                        setSubOption(e.target.value);
                                    }}
                                >
                                    <MenuItem value={1}>All</MenuItem>
                                    <MenuItem value={2}>Year Wise</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {(() => {
                        if (subOption == 2) {
                            return (
                                <>
                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Year :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required={(subOption == 2) ? true : false}
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && year.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                value={year}
                                                onChange={e => setYear(e.target.value)}
                                                id="outlined-basic"
                                                label="Year"
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>

                                        <div className='col-sm-3 mt-1 '>
                                            <FormControlLabel control={<Checkbox checked={leftStudents} onChange={HandleLeftStudentsCheckbox} />} label="Include Left Students" />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })()}


                    <div className="row mt-3">
                        <div className='col-sm-12 offset-sm-2'>
                            <Grid container spacing={2} alignItems="center"  >
                                <Grid item >{leftListData(leftFilteredData)}</Grid>
                                <Grid item>
                                    <Grid container direction="column" alignItems="center"  >
                                        <button
                                            className='my-1'
                                            variant="outlined"
                                            size="small"
                                            onClick={handleAllRight}
                                            disabled={left.length === 0}
                                            aria-label="move all right"
                                            style={{ width: "30px" }}
                                        >
                                            ≫
                                        </button>
                                        <button
                                            className='my-1'
                                            variant="outlined"
                                            size="small"
                                            onClick={handleCheckedRight}
                                            disabled={leftChecked.length === 0}
                                            aria-label="move selected right"
                                            style={{ width: "30px" }}
                                        >
                                            &gt;
                                        </button>
                                        <button
                                            className='my-1'
                                            variant="outlined"
                                            size="small"
                                            onClick={handleCheckedLeft}
                                            disabled={rightChecked.length === 0}
                                            aria-label="move selected left"
                                            style={{ width: "30px" }}
                                        >
                                            &lt;
                                        </button>
                                        <button
                                            className='my-1'
                                            variant="outlined"
                                            size="small"
                                            onClick={handleAllLeft}
                                            disabled={right.length === 0}
                                            aria-label="move all left"
                                            style={{ width: "30px" }}
                                        >
                                            ≪
                                        </button>
                                    </Grid>
                                </Grid>
                                <Grid item>{rightListData(rightFilteredData)}</Grid>
                            </Grid>
                        </div>
                    </div>

                    {(() => {
                        if (option == 2) {
                            return (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 offset-sm-2">
                                            <button type="button" onClick={() => classDivWiseGeneralRegReport()} disabled={(classDivObj == null || classDivObj == "" || right == [] || right == null || right == "") ? true : false} className="btn btn-primary btn-sm">Show</button>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        else if (option == 1) {
                            return (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 offset-sm-2">
                                            <button type="button" onClick={() => allStudentWiseGeneralRegReport()} className="btn btn-primary btn-sm" disabled={(right == [] || right == null || right == "") ? true : false}>Show</button>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })()}

                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default GeneralRegisterChecklist