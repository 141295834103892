import React, { useState, useEffect } from 'react';
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import Loader from '../../CommonComponent/Loader';
import { classWithDivisionURL, getSubjectDataForSubMarkSheet } from '../Services/ExamReportAPIURL';
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from "@mui/styles/makeStyles";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const SelectedSubMeritList = () => {
    const titleId = "Merit List Selected Sub";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [term, setTerm] = useState('');

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [leftFilteredData, setLeftFilteredData] = useState([]);
    const [leftSearchTerm, setLeftSearchTerm] = useState('');
    const [right, setRight] = useState([]);
    const [rightFilteredData, setRightFilteredData] = useState([]);
    const [rightSearchTerm, setRightSearchTerm] = useState('');

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        await axios.get(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassData(res.data);
            })
    }

    const getSubjects = async (cId, dId) => {
        setLeft([]);
        setLeftFilteredData([]);
        setLeftSearchTerm('');
        setRight([]);
        setRightFilteredData([]);
        setRightSearchTerm('');
        //console.log(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}&branchMedium=${authUser.branchMedium}`)
        await axios(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {
                //console.log(response.data);
                let filterMarkSubjects = response.data.filter(e => e.markOrGrade == 1)
                setLeftFilteredData(filterMarkSubjects);
                setLeft(filterMarkSubjects);

            }).catch((error) => {
                console.log(error);
            })
    }

    const handleToggle = (value) => () => {
        //console.log(value);    
        const currentIndex = checked.indexOf(value);
        //console.log("Data="+currentIndex)       
        const newChecked = [...checked];

        if (currentIndex === -1) {
            //console.log("Checked="+JSON.stringify(value.id))           
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        //console.log(newChecked.map(e=>e.id).join(','))
    };

    const handleAllRight = () => {
        setRight((right.concat(left)));
        setRightFilteredData((right.concat(left)));
        setLeft([]);
        setLeftFilteredData([]);
        setLeftSearchTerm('');
        setRightSearchTerm('');
    };

    const handleCheckedRight = () => {
        setRight((right.concat(leftChecked)));
        setRightFilteredData((right.concat(leftChecked)));
        setLeft(not(left, leftChecked));
        setLeftFilteredData(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        setLeftSearchTerm('');
        setRightSearchTerm('');
    };

    const handleCheckedLeft = () => {
        setLeft((left.concat(rightChecked)));
        setLeftFilteredData((left.concat(rightChecked)));
        setRight(not(right, rightChecked));
        setRightFilteredData(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        setLeftSearchTerm('');
        setRightSearchTerm('');
    };

    const handleAllLeft = () => {
        setLeft((left.concat(right)));
        setLeftFilteredData((left.concat(right)));
        setRight([]);
        setRightFilteredData([]);
        setLeftSearchTerm('');
        setRightSearchTerm('');
    };

    useEffect(() => {
        setLeftFilteredData(
            left.filter((item) =>
                item.subjectNameMr.toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                item.subjectNameEn.toLowerCase().includes(leftSearchTerm.toLowerCase())

            ))
    }, [leftSearchTerm])

    useEffect(() => {
        setRightFilteredData(
            right.filter((item) =>
                item.subjectNameMr.toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                item.subjectNameEn.toLowerCase().includes(rightSearchTerm.toLowerCase())

            ))
    }, [rightSearchTerm])

    const leftListData = (leftdata) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>

            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "170px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={leftSearchTerm}
                        onChange={e => setLeftSearchTerm(e.target.value)}
                    /></span>
                </div>

                {leftdata.map((value) => {
                    const labelId = value.id;
                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={(authUser.branchMedium == 1) ? value.subjectNameMr : value.subjectNameEn} primaryTypographyProps={{ fontSize: '14px', fontWeight: "600" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const rightListData = (rightData) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>
            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "170px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={rightSearchTerm}
                        onChange={e => setRightSearchTerm(e.target.value)}
                    /></span>
                </div>

                {rightData.map((value) => {
                    const labelId = value.id;
                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={(authUser.branchMedium == 1) ? value.subjectNameMr : value.subjectNameEn} primaryTypographyProps={{ fontSize: '14px', fontWeight: "600" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <form>
                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={classData}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            value={classObj}
                                            onChange={(event, newValue) => {
                                                setClassObj(newValue);
                                                if (newValue != null) {
                                                    getSubjects(newValue.classId, newValue.divisionId);
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField margin='dense' {...params} label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={term}
                                                label="Select Term"
                                                onChange={(e) => {
                                                    setTerm(e.target.value);
                                                }}
                                            >
                                                {subjectMarkTermData.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {(() => {
                                    if (classObj != null && term != '') {
                                        return (
                                            <>
                                                <div className="row mt-3 mb-3">
                                                    <div className='col-sm-12 offset-sm-2' >
                                                        <Grid container spacing={2} alignItems="center" >
                                                            <Grid item >{leftListData(leftFilteredData)}</Grid>
                                                            <Grid item>
                                                                <Grid container direction="column" alignItems="center"  >
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllRight}
                                                                        disabled={left.length === 0}
                                                                        aria-label="move all right"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        ≫
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleCheckedRight}
                                                                        disabled={leftChecked.length === 0}
                                                                        aria-label="move selected right"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        &gt;
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleCheckedLeft}
                                                                        disabled={rightChecked.length === 0}
                                                                        aria-label="move selected left"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        &lt;
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllLeft}
                                                                        disabled={right.length === 0}
                                                                        aria-label="move all left"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        ≪
                                                                    </button>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>{rightListData(rightFilteredData)}</Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default SelectedSubMeritList