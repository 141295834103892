import { dBaseURL, BaseURL } from "../../../CommonServices/APIURL";

//localhost:8080/institute/sss/library/circulation/getBookListByRegisterType?registerTypeId=1&option=2&accNo=4&accNoFrom=1&accNoTo=5&bid=1&sessionYear=2022
export const getBookDetailsForWithdrawalEntry = BaseURL + "library/circulation/getBookListByRegisterType";

//localhost:8080/institute/sss/library/circulation/saveWithdrawalEntry
// [
//     {
//         "accNo": 4,
//         "registerTypeId": 1,
//         "withdrawalType": 1,
//         "withdrawalStatus": 1,
//         "withdrawalDate": "2023-01-03",
//         "remark": "Remark remark",
//         "sessionYear": 2022,
//         "branchId": 1
//     }
// ]
export const saveWithdrawalEntryAPIURL = BaseURL + "library/circulation/saveWithdrawalEntry";

// /localhost:8080/institute/sss/library/circulation/getAllWithdrawalEntryList?bid=1&sessionYear=2022
export const getAllWithdrwalEntryDetails = BaseURL + "library/circulation/getAllWithdrawalEntryList"

//localhost:8080/institute/sss/library/circulation/updateWithdrawalEntry
export const updateWithdrawalEntryAPIURL = BaseURL + "library/circulation/updateWithdrawalEntry";

// /localhost:8080/institute/sss/library/circulation/saveReintroduceEntry
export const reIntroduceBookDetailsAPIURL = BaseURL + "library/circulation/saveReintroduceEntry";