import { BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const saveAuthorMasterAPIURL = BaseURL + "library/master/saveAuthorMaster";
//localhost:8080/institute/sss/library/master/saveAuthorMaster

//localhost:8080/institute/sss/library/master/getAuthorMasterData?id=2
export const getAuthorMasterById = BaseURL + "library/master/getAuthorMasterData";

//localhost:8080/institute/sss/library/master/getAllAuthorMasterList?branchId=1
export const getAuthorMasterData = BaseURL + "library/master/getAllAuthorMasterList";

//localhost:8080/institute/sss/library/master/deleteAuthorMaster?id=2
export const deleteAuthorMaster = BaseURL + "library/master/deleteAuthorMaster";

//localhost:8080/institute/sss/library/master/updateAuthorMaster
export const updateAuthorMaster = BaseURL + "library/master/updateAuthorMaster";