import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/service/supportSignIn?username=abc&password=1234
//export const servicePanelLoginPanel="http://192.168.1.102:8080/institute/sss/service/supportSignIn";
export const servicePanelLoginPanel = BaseURL + "service/supportSignIn";

// localhost:8080/institute/sss/service/serviceForm/getAllServiceFormDataByStatusId?statusId=4

// statusId

//export const getAllServiceFormDataByStatusIdAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/getAllServiceFormDataByStatusId/";
export const getAllServiceFormDataByStatusIdAPIURL = BaseURL + "service/serviceForm/getAllServiceFormDataByStatusId/";


// localhost:8080/institute/sss/service/serviceForm/getServiceFormDataCount

//export const getServiceFormDataCountAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/getServiceFormDataCount";
export const getServiceFormDataCountAPIURL = BaseURL + "service/serviceForm/getServiceFormDataCount";

// localhost:8080/institute/sss/service/serviceForm/getRemarkDataByServiceFormId?formId=27

// formId
//export const getRemarkDataByServiceFormIdAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/getRemarkDataByServiceFormId"
export const getRemarkDataByServiceFormIdAPIURL = BaseURL + "service/serviceForm/getRemarkDataByServiceFormId"


//Notification Count Details

// userFlagCount
// /institute/sss/service/serviceForm/getServiceFormUserFlagDataCount
//export const getServiceFormUserFlagDataCountAPIURL = "http://192.168.1.102:8080/institute/sss/service/serviceForm/getServiceFormUserFlagDataCount"
export const getServiceFormUserFlagDataCountAPIURL=BaseURL+"service/serviceForm/getServiceFormUserFlagDataCount"


// adminFlageCount
// /institute/sss/service/serviceForm/getServiceFormAdminFlagDataCount

//export const getServiceFormAdminFlagDataCountAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/getServiceFormAdminFlagDataCount"
export const getServiceFormAdminFlagDataCountAPIURL = BaseURL + "service/serviceForm/getServiceFormAdminFlagDataCount"