import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";
import { classWithDivisionURL, getSubjectDataForSubMarkSheet, printPrapatraBAPIURL } from '../Services/ExamReportAPIURL';
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const PrapatraB = () => {
    const classes = useStyles();
    const titleId = "Prapatra B";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [option, setOption] = useState(1);
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState([]);
    const [classDivId, setClassDivId] = useState(null);
    const [classData, setClassData] = useState([]);

    //console.log(classDivId);
    const [classObj, setClassObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [termObj, setTermObj] = useState('');

    useEffect(() => {
        getData();
        getClassData();
    }, [authUser])

    //class-division list
    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const getClassData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                })
        }
    }

    //get subject list by class-div
    const getSubjectListByClassAndDiv = async (cid, did) => {
        //console.log(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cid}&divId=${did}&branchMedium=${authUser.branchMedium}`)
        await axios.get(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cid}&divId=${did}&branchMedium=${authUser.branchMedium}`)
            .then(res => {
                setSubjectData(res.data);
            }).catch(err => {
                console.log("Get sub by class-div" + err)
            })
    }

    //print praptra B report
    const getPrapatrabReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        // console.log(`${printPrapatraBAPIURL}?langId=${authUser.branchMedium}&optionId=${option}&subId=${(option == 2) ? subjectObj.id : 0}&viewType=${(option == 2) ? subjectObj.viewType : 0}&divId=${(option == 1) ? classDivId : classDivObj.divisionId}&termId=${termObj}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}&classId=${(option == 4) ? classObj.id : 0}`);
        await axios.get(`${printPrapatraBAPIURL}?langId=${authUser.branchMedium}&optionId=${option}&subId=${(option == 2) ? subjectObj.id : 0}&viewType=${(option == 2) ? subjectObj.viewType : 0}&divId=${(option == 1 || option == 3) ? classDivId : classDivObj.divisionId}&termId=${termObj}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}&classId=${(option == 4) ? classObj.id : 0}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                console.log('Print Prapatra B' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            {/* Form Data start */}
                            <form onSubmit={getPrapatrabReport}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Option"
                                                    onChange={(e) => {
                                                        setOption(e.target.value);
                                                        if (e.target.value == 1 || e.target.value == 3) {
                                                            setClassDivObj([]);
                                                        }
                                                        else if (e.target.value == 2) {
                                                            setClassDivObj(null);
                                                        } else if (e.target.value == 3) {
                                                            setClassDivObj(null);
                                                        }

                                                        setSubjectData([]);
                                                        setSubjectObj(null);
                                                        setClassDivId(null);
                                                        setClassObj(null);
                                                        setTermObj('');
                                                    }}
                                                >
                                                    <MenuItem value={1}>Class and Division Wise</MenuItem>
                                                    <MenuItem value={2}>Subject Wise</MenuItem>
                                                    <MenuItem value={3}>Category Wise</MenuItem>
                                                    <MenuItem value={4}>Class Wise</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={termObj}
                                                    label="Term"
                                                    onChange={(e) => {
                                                        setTermObj(e.target.value);
                                                        if (option == 1 || option == 3) {
                                                            setClassDivObj([]);
                                                        }
                                                        else if (option == 2) {
                                                            setClassDivObj(null);
                                                        }
                                                        setSubjectData([]);
                                                        setSubjectObj(null);
                                                        setClassDivId(null);
                                                    }}
                                                >
                                                    {
                                                        subjectMarkTermData.map((item, index) => {
                                                            return (
                                                                <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {(() => {
                                        if (option == 1) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                        <div className='col-sm-4'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                multiple
                                                                filterSelectedOptions
                                                                disableCloseOnSelect
                                                                options={classDivData}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                                size="small"
                                                                value={classDivObj}
                                                                onChange={(event, newValue) => {
                                                                    setClassDivObj(newValue);
                                                                    setSubjectData([]);
                                                                    setSubjectObj(null);
                                                                    if (newValue != null) {
                                                                        setClassDivId(newValue.map(e => e.divisionId).join())
                                                                    }
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin="dense" label="Select Class" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                        required={(option == 1 && classDivObj.length == 0) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                        <div className={(option == 1) ? 'col-sm-4' : 'col-sm-3'}>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                options={classDivData}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                                size="small"
                                                                value={classDivObj}
                                                                onChange={(event, newValue) => {
                                                                    setClassDivObj(newValue);
                                                                    setSubjectData([]);
                                                                    setSubjectObj(null);
                                                                    if (newValue != null) {
                                                                        getSubjectListByClassAndDiv(newValue.classId, newValue.divisionId)
                                                                    }
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin="dense" label="Select Class" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                        required={(option == 2) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 3) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                        <div className='col-sm-4'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                multiple
                                                                filterSelectedOptions
                                                                disableCloseOnSelect
                                                                options={classDivData}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                                size="small"
                                                                value={classDivObj}
                                                                onChange={(event, newValue) => {
                                                                    setClassDivObj(newValue);
                                                                    setSubjectData([]);
                                                                    setSubjectObj(null);
                                                                    if (newValue != null) {
                                                                        setClassDivId(newValue.map(e => e.divisionId).join())
                                                                    }
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin="dense" label="Select Class" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                        required={(option == 3 && classDivObj.length == 0) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 4) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                        <div className='col-sm-4'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                options={classData}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                size="small"
                                                                value={classObj}
                                                                onChange={(event, newValue) => {
                                                                    setClassDivObj(classDivData[0]);
                                                                    setClassObj(newValue)
                                                                    setSubjectData([]);
                                                                    setSubjectObj(null);
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin="dense" label="Select Class" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                        required={(option == 4) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }

                                    })()}


                                    {(() => {
                                        if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                                        <div className='col-sm-3'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                options={subjectData}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.subjectNameMr : option.subjectNameEn}
                                                                size="small"
                                                                value={subjectObj}
                                                                onChange={(event, newValue) => {
                                                                    setSubjectObj(newValue);
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin="dense" label="Select Subject" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required={(option == 2) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-1">
                                            <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default PrapatraB