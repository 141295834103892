import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

export const getStudentListForVillageUpdation = BaseURL + "administration/getStudentAddressData";

export const saveStudentListForVillageUpdation = BaseURL + "administration/saveStudentAddressData";

// GetMapping
// /institute/sss/administration/getStudentAddressData
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cdid") long classDivId

// *-----------------------------------------------------------*
// PostMapping
// /institute/sss/administration/saveStudentAddressData

// long id,
// long regCode,
// String studentNameMr,
// String studentNameEn,
//  long rollNo,
// String address,
// String city,
// long sessionYear,
// long sectionId,
// long branchId,
//  long classId,
//  long divisionId