const MasterReportData = [
    {
        id: 1,
        masterName: "Register Type Master"
    },
    {
        id: 2,
        masterName: "Title Master"
    },
    {
        id: 3,
        masterName: "Author Master"
    },
    {
        id: 4,
        masterName: "Publisher Master"
    },
    {
        id: 5,
        masterName: "Vendor Master"
    },
    {
        id: 6,
        masterName: "Binder Master"
    },
    {
        id: 7,
        masterName: "Binding Master"
    },
    {
        id: 8,
        masterName: "Department Master"
    },
    {
        id: 9,
        masterName: "Location Master"
    },
    {
        id: 10,
        masterName: "Source Master"
    },
    {
        id: 11,
        masterName: "Sub-budget Type Master"
    },
    {
        id: 12,
        masterName: "Currency Master"
    },
    {
        id: 13,        
        masterName: "Member Type Master"
    },
]

export default MasterReportData;