import { TablePagination, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const EmployeeBioAttendanceTable = ({ TableHeading, data, setData, filteredData, setFilteredData, option,
    employeeObj, schoolObj, authUser }) => {
    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(item => (
            item.empId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.attDate || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.offTime || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.onTime || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.empNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.empNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.empType.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.status.toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])


    return (
        <>
            <div class="card" style={{ boxShadow: "0px 3px 10px grey", }}>
                <div class="card-header" hidden={(option == 1 && authUser.branchId != 50) ? true : false} style={{ backgroundColor: "#E8E8E8" }}>
                    <h6 className='mt-2' hidden={(authUser.branchId != 50) ? true : false}>School Name :  <span style={{ color: "#0568D9" }}> {(schoolObj != null) ? ((authUser.branchMedium == 1) ? schoolObj.schoolNameMr : schoolObj.schoolNameEn) : ''}</span></h6>
                    <h6 className='mt-2' hidden={(option == 1) ? true : false}>Employee Name :  <span style={{ color: "#0568D9" }}>{(employeeObj != null) ? ((authUser.branchMedium == 1) ? employeeObj.employeeNameMr : employeeObj.employeeNameEn) : ''}</span></h6>
                    <h6 className='mt-2' hidden={(option == 1) ? true : false}>Employee Type :  <span style={{ color: "#0568D9" }}>{(employeeObj != null) ? ((employeeObj.employeeType == 1) ? 'Teaching' : 'Non-Teaching') : ''}</span></h6>
                </div>
                <div class="card-body">
                    <div>
                        <div>
                            <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                            />
                        </div>
                        <div className='table-responsive'>
                            <table className="table table-bordered">
                                <thead className="table-Default">
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.5", textAlign: `${item.textAlign}` }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #0030E0", borderBottom: "2px solid #0030E0" }}>
                                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .reverse().map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{(option == 1) ? item.empId : index + 1}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} hidden={(option == 2) ? true : false}>{(authUser.branchMedium == 1) ? item.empNameMr : item.empNameEn}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} hidden={(option == 2) ? true : false}>{item.empType}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", textAlign: 'center', width: "80px" }} hidden={(option == 2) ? true : false}>
                                                            {(() => {
                                                                if (item.bioStatus == 1) {
                                                                    return (
                                                                        <Tooltip title="Allocated">
                                                                            <ThumbUpIcon style={{ color: "#2C9704", cursor: "pointer" }} />
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                                else if (item.bioStatus == 0) {
                                                                    return (
                                                                        <Tooltip title="Not Allocated">
                                                                            <ThumbDownIcon style={{ color: "#DF2204", cursor: "pointer" }} />
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                            })()}
                                                        </td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }} hidden={(option == 1) ? true : false}>{(item.attDate == null) ? '-' : item.attDate}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", textAlign: 'center', width: "120px" }}>{(item.onTime == null) ? '-' : item.onTime}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", textAlign: 'center', width: "120px" }}>{(item.offTime == null) ? '-' : item.offTime}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "150px", textAlign: 'center' }}>
                                                            {(() => {
                                                                if (item.status == "Absent") {
                                                                    return (
                                                                        <span className="badge" style={{ backgroundColor: "#FD4425", cursor: "pointer", color: "#fff", fontSize: "13px" }}>{item.status}</span>
                                                                    )
                                                                }
                                                                else if (item.status == "Present") {
                                                                    return (
                                                                        <span className="badge" style={{ backgroundColor: "#019E01", cursor: "pointer", color: "#fff", fontSize: "13px" }}>{item.status}</span>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <span className="badge" style={{ backgroundColor: "#0370CB", cursor: "pointer", color: "#fff", fontSize: "13px" }}>{item.status}</span>
                                                                    )
                                                                }
                                                            })()}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeBioAttendanceTable