import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
    getCategoryDetailsByDeptReceiptPayament, getDepartmentsByBranch, getSansthaBudgetEntryDetail
} from './Services/BudgetEntryAPIURL';
import SansthaBudgetReceiptPaymentTable from './SansthaBudgetReceiptPaymentTable';
import SansthaAccountEntryTable from './SansthaAccountEntryTable';
import SansthBudgetDetailEntryTable from './SansthBudgetDetailEntryTable';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const SansthaBudgetEntryForm = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Budget Entry";
    const classes = useStyles();
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState("");
    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [optionId, setOptionId] = useState('');
    const [budgetCategoryData, setBudgetCategoryData] = useState([]);
    const [budgetCategoryObj, setBudgetCategoryObj] = useState(null);
    const [transactionLabel, setTransactionLabel] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [categoryType, setCategoryType] = useState('');

    //console.log(categoryType)

    const [sansthDeptId, setSansthDeptId] = useState(',');

    const [previousYrTotalBudget, setPreviousYrTotalBudget] = useState(0);
    const [nextYrTotalBudget, setNextYrTotalBudget] = useState(0);
    const [actualDecTotalBudget, setActualDecTotalBudget] = useState(0);
    const [accountName, setAccountName] = useState('');


    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.branchId != null && authUser.deptId != null) {
            //console.log(`${getDepartmentsByBranch}?bid=${authUser.branchId}&sid=${authUser.deptId}`)
            await axios.get(`${getDepartmentsByBranch}?bid=${authUser.branchId}&sid=${authUser.deptId}`)
                .then(res => {
                    setDepartmentData(res.data);
                })
        }
    }

    const getCategoryData = async (opId) => {
        //console.log(`${getCategoryDetailsByDeptReceiptPayament}?deptId=${departmentObj.deptId}&optionId=${opId}`)
        await axios.get(`${getCategoryDetailsByDeptReceiptPayament}?deptId=${departmentObj.deptId}&optionId=${opId}`)
            .then(res => {
                setBudgetCategoryData(res.data);

            })
    }

    const [futureYear, setFutureYear] = useState('');

    const currentYear = new Date();

    useEffect(() => {
        if (authUser.sessionYear < moment(currentYear).format("YYYY")) {
            let newYear = moment(authUser.fromDate).format("YY")
            //console.log(newYear)
            setFutureYear(parseInt(newYear) + 1);
        }
        else {
            setFutureYear(moment(currentYear).add(1, 'year').format("YY"))
        }
    }, [authUser])

    const TableHeading1 = [
        { label: 'अनु. क्र', rowspan: '2', colspan: '', key: 'srNo', isAction: true },
        { label: `${transactionLabel} तपशील `, rowspan: '', colspan: '1', key: 'accountName', isAction: true },
        { label: `${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: 'Actual Budget', value: 'actual', rowspan: '', colspan: '1', isAction: true },
        { label: `अंदाजे ${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true }
    ];

    const TableHeading2 = [
        { label: `${accountName}`, key: 'categoryId', isAction: true },
        { label: `${parseInt(authUser.sessionYear)}-${futureYear}`, value: 'budgetLastYear', isAction: true },
        { label: `31/12/${parseInt(authUser.sessionYear)}`, value: 'actualBudget1', isAction: true },
        { label: `${parseInt(authUser.sessionYear) + 1}-${parseInt(futureYear) + 1}`, value: 'budgetNextYear', isAction: true }
    ];

    const TableHeading3 = [
        { label: 'अनु. क्र', rowspan: '2', colspan: '', key: 'srNo', isAction: true },
        { label: `${transactionLabel} तपशील `, rowspan: '', colspan: '1', key: 'accountName', isAction: true },
        { label: `${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: `अंदाजे ${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true }
    ];

    const TableHeading4 = [
        { label: `${accountName}`, key: 'categoryId', isAction: true },
        { label: `${parseInt(authUser.sessionYear)}-${futureYear}`, value: 'budgetLastYear', isAction: true },
        { label: `${parseInt(authUser.sessionYear) + 1}-${parseInt(futureYear) + 1}`, value: 'budgetNextYear', isAction: true }
    ];

    const getBudgetDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setCategoryType(budgetCategoryObj.categoryType);
        setSansthDeptId(budgetCategoryObj.departmentId);
        //setAccountName(budgetCategoryObj.nameMr + " " + `(${budgetCategoryObj.nameEn})`)
        setData([]);
        setFilteredData([]);
        if (budgetCategoryObj.categoryType == 1) {
            setAccountName('');
            setActualDecTotalBudget('');
            setNextYrTotalBudget('');
            setPreviousYrTotalBudget('');
            //console.log(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Accounts are not found for this category.")
                    }
                    else {
                        setData(res.data);
                        setFilteredData(res.data);
                        //console.log(res.data)
                        res.data.map(e => {
                            setActualDecTotalBudget(e.budgetTotal);
                            setNextYrTotalBudget(e.nextYearTotal);
                            setPreviousYrTotalBudget(e.lastYearTotal);

                            budgetCategoryData.map(item => {
                                if (item.id == e.categoryId) {
                                    setAccountName(item.nameMr)
                                }
                            })
                        })
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("get detail entry err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (budgetCategoryObj.categoryType == 2) {
            setAccountName('');
            setNextYrTotalBudget('');
            setPreviousYrTotalBudget('');
            console.log(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Accounts are not found for this category.")
                    }
                    else {
                        setData(res.data);
                        setFilteredData(res.data);
                        //console.log(res.data)
                        res.data.map(e => {
                            setNextYrTotalBudget(e.nextYearTotal);
                            setPreviousYrTotalBudget(e.lastYearTotal);

                            budgetCategoryData.map(item => {
                                if (item.id == e.categoryId) {
                                    setAccountName(item.nameMr)
                                }
                            })
                        })
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("get detail entry err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (budgetCategoryObj.categoryType == 3) {
            setAccountName('');
            setActualDecTotalBudget('');
            setNextYrTotalBudget('');
            setPreviousYrTotalBudget('');
            // console.log(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data.length == 0) {
                        setActualDecTotalBudget((0).toFixed('2'));
                        setNextYrTotalBudget((0).toFixed('2'));
                        setPreviousYrTotalBudget((0).toFixed('2'));
                        setLoaderOption(false);
                        //toast.warn("Accounts are not found for this category.")
                    }
                    else {
                        setData(res.data);
                        setFilteredData(res.data);
                        //console.log(res.data)
                        res.data.map(e => {
                            setActualDecTotalBudget((e.budgetTotal).toFixed('2'));
                            setNextYrTotalBudget((e.nextYearTotal).toFixed('2'));
                            setPreviousYrTotalBudget((e.lastYearTotal).toFixed('2'));

                            budgetCategoryData.map(item => {
                                if (item.id == e.categoryId) {
                                    setAccountName(item.nameMr)
                                }
                            })
                        })
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("get detail entry err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    const getBudgetDetailsAfterDelete = async () => {
        setActualDecTotalBudget((0).toFixed('2'));
        setNextYrTotalBudget((0).toFixed('2'));
        setPreviousYrTotalBudget((0).toFixed('2'));
        // console.log(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                // if (res.data.length == 0) {

                //     setLoaderOption(false);
                // }
                // else {
                setData(res.data);
                setFilteredData(res.data);
                //console.log(res.data)
                res.data.map(e => {
                    setActualDecTotalBudget((e.budgetTotal).toFixed('2'));
                    setNextYrTotalBudget((e.nextYearTotal).toFixed('2'));
                    setPreviousYrTotalBudget((e.lastYearTotal).toFixed('2'));

                    budgetCategoryData.map(item => {
                        if (item.id == e.categoryId) {
                            setAccountName(item.nameMr)
                        }
                    })
                })
                setLoaderOption(false);
                //}
            }).catch(err => {
                console.log("get detail entry err:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <div className=''>
                                <form onSubmit={getBudgetDetails}>
                                    <div className="form-data">
                                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                        <div className="row my-2">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Department :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={departmentData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setDepartmentObj(newValue);
                                                        setOptionId('');
                                                        setBudgetCategoryObj(null);
                                                        setCategoryType('');
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={departmentObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Department" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>

                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                            <div className='col-sm-3'>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                    <Select
                                                        size='small'
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={optionId}
                                                        label="Select Option"
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                        onChange={(e) => {
                                                            setOptionId(e.target.value);
                                                            if (e.target.value == 1) {
                                                                setTransactionLabel("जमा बाजू")
                                                            }
                                                            else if (e.target.value == 2) {
                                                                setTransactionLabel("खर्च बाजू")
                                                            }
                                                            getCategoryData(e.target.value);
                                                            setBudgetCategoryObj(null);
                                                            setCategoryType('');
                                                            setData([]);
                                                            setFilteredData([]);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? optionId : ""}
                                                    >

                                                        <MenuItem value={1}>Receipt</MenuItem>
                                                        <MenuItem value={2}>Payment</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="row my-2 mb-5">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                                            <div className='col-sm-7'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={budgetCategoryData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setBudgetCategoryObj(newValue);
                                                        setCategoryType('');
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={budgetCategoryObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-sm-3 mt-3">
                                                <button type='submit' className='btn btn-sm btn-warning'>VIEW</button>
                                                {/* <button type='button' onClick={() => addAccountDetails()} hidden={(authUser.branchId != 50 || budgetCategoryObj == null) ? true : false} className='btn btn-sm ml-2' style={{ backgroundColor: "#2D9328", color: "#fff" }}>Add Accounts</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="">
                                {(() => {
                                    if (categoryType == 2) {
                                        if (data.length != 0 || filteredData.length != 0) {
                                            return (
                                                <>
                                                    <SansthaBudgetReceiptPaymentTable
                                                        TableHeading1={TableHeading3}
                                                        TableHeading2={TableHeading4}
                                                        data={data}
                                                        setData={setData}
                                                        filteredData={filteredData}
                                                        setFilteredData={setFilteredData}
                                                        departmentObj={departmentObj}
                                                        setDepartmentObj={setDepartmentObj}
                                                        setOptionId={setOptionId}
                                                        setBudgetCategoryObj={setBudgetCategoryObj}
                                                        previousYrTotalBudget={previousYrTotalBudget}
                                                        nextYrTotalBudget={nextYrTotalBudget}
                                                        setPreviousYrTotalBudget={setPreviousYrTotalBudget}
                                                        setNextYrTotalBudget={setNextYrTotalBudget}
                                                    />
                                                </>
                                            )
                                        }
                                    }
                                    else if (categoryType == 1) {
                                        if (data.length != 0 || filteredData.length != 0) {
                                            return (
                                                <>
                                                    <SansthBudgetDetailEntryTable
                                                        TableHeading1={TableHeading1}
                                                        TableHeading2={TableHeading2}
                                                        data={data}
                                                        setData={setData}
                                                        filteredData={filteredData}
                                                        setFilteredData={setFilteredData}
                                                        departmentObj={departmentObj}
                                                        setDepartmentObj={setDepartmentObj}
                                                        setOptionId={setOptionId}
                                                        setBudgetCategoryObj={setBudgetCategoryObj}
                                                        previousYrTotalBudget={previousYrTotalBudget}
                                                        nextYrTotalBudget={nextYrTotalBudget}
                                                        actualDecTotalBudget={actualDecTotalBudget}
                                                        setPreviousYrTotalBudget={setPreviousYrTotalBudget}
                                                        setNextYrTotalBudget={setNextYrTotalBudget}
                                                        setActualDecTotalBudget={setActualDecTotalBudget}
                                                    />
                                                </>
                                            )
                                        }
                                    }
                                    else if (categoryType == 3) {
                                        if (budgetCategoryObj != null) {
                                            return (
                                                <>
                                                    <SansthaAccountEntryTable
                                                        TableHeading1={TableHeading1}
                                                        TableHeading2={TableHeading2}
                                                        data={data}
                                                        setData={setData}
                                                        filteredData={filteredData}
                                                        setFilteredData={setFilteredData}
                                                        departmentObj={departmentObj}
                                                        setDepartmentObj={setDepartmentObj}
                                                        setOptionId={setOptionId}
                                                        setBudgetCategoryObj={setBudgetCategoryObj}
                                                        previousYrTotalBudget={previousYrTotalBudget}
                                                        nextYrTotalBudget={nextYrTotalBudget}
                                                        actualDecTotalBudget={actualDecTotalBudget}
                                                        setPreviousYrTotalBudget={setPreviousYrTotalBudget}
                                                        setNextYrTotalBudget={setNextYrTotalBudget}
                                                        setActualDecTotalBudget={setActualDecTotalBudget}
                                                        futureYear={futureYear}
                                                        getBudgetDetailsAfterDelete={getBudgetDetailsAfterDelete}
                                                        budgetCategoryObj={budgetCategoryObj}
                                                    />
                                                </>
                                            )

                                        }

                                    }
                                })()}


                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default SansthaBudgetEntryForm