import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { getClassDivisionURL, subjectGetData } from '../../Academic/Services/SubjectAllocationAPIURL';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { printClassAndDivisionWiseStudSeatNoReport, printAllStudSeatNoReport, printClassAndDivisionAndSubWiseStudSeatNoReport } from '../Services/ExamReportAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const StudInternalSeatNoReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Internal Seat No Report";

    const [subjectData, setSubjectData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [classObj, setClassObj] = useState(null);
    const [optionObj, setOptionObj] = useState(null);
    const [optionId, setOptionId] = useState(1);

    const [loaderOption, setLoaderOption] = useState(false);

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                })
        }

        await axios(`${subjectGetData}`)
            .then(res => {
                setSubjectData(res.data);
            })
    }

    const allStudSeatNoReport = async () => {
        setLoaderOption(true);
        //console.log(`${printAllStudSeatNoReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${printAllStudSeatNoReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Internal Seat No Report failed: " + error)
            })
    }

    const classDivWiseStudSeatNoReport = async () => {
        setLoaderOption(true);
        await axios.get(`${printClassAndDivisionWiseStudSeatNoReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classObj.classId}&divId=${classObj.divisionId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)                    
                    setLoaderOption(false);
                    toast.warn("Data not exists with this class-div.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Internal Seat No Report failed: " + error)
            })
    }

    const classDivAndSubjectWiseStudSeatNoReport = async () => {
        setLoaderOption(true);
        await axios.get(`${printClassAndDivisionAndSubWiseStudSeatNoReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classObj.classId}&divId=${classObj.divisionId}&subId=${subjectObj.id}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)                    
                    setLoaderOption(false);
                    toast.warn("Data not exists with this class-div and subject.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Internal Seat No Report failed: " + error)
            })
    }

    const getStudentInternalSeatNoReport = e => {
        e.preventDefault();
        if (optionId == 1) {
            allStudSeatNoReport();
        }
        else if (optionId == 2) {
            classDivWiseStudSeatNoReport();
        }
        else if (optionId == 3) {
            classDivAndSubjectWiseStudSeatNoReport();
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={getStudentInternalSeatNoReport}>
                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={optionId}
                                                label="Select Report Option"
                                                onChange={(e) => {
                                                    setOptionId(e.target.value);
                                                    setClassObj(null);
                                                    setSubjectObj(null);
                                                }}
                                            >

                                                <MenuItem value={1}>All</MenuItem>
                                                <MenuItem value={2}>Class-Division Wise</MenuItem>
                                                <MenuItem value={3}>Class-Division & Subject Wise</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>

                                {(() => {
                                    if (optionId == 2) {
                                        return (
                                            <>
                                                <div className='row my-2'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={classData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setClassObj(item);
                                                            }}
                                                            value={classObj}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(optionId == 2) ? true : false} />
                                                            )}
                                                        />

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    else if (optionId == 3) {
                                        return (
                                            <>
                                                <div className='row my-2'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={classData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setClassObj(item);
                                                                setSubjectObj(null);
                                                            }}
                                                            value={classObj}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(optionId == 3) ? true : false} />
                                                            )}
                                                        />

                                                    </div>
                                                </div>

                                                <div className='row my-2'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={subjectData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setSubjectObj(item);
                                                            }}
                                                            value={subjectObj}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(optionId == 3) ? true : false} />
                                                            )}
                                                        />

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default StudInternalSeatNoReport