import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const BaseURLACCMasterReportAPI = BaseURL + "accounts/accountMaster/"
export const accMasterGetAPI = BaseURLACCMasterReportAPI + "getAccountMasterReport"

//Account Ledger Report APIURL
// localhost:8080/institute/sss/accounts/accountStatement/getAccountStatementReport?fromDate=14/01/2021&toDate=24/06/2022&sid=4&bid=1&acCode=1202&acYear=2021
export const printAccountLedgerReportAPIURL = BaseURL + "accounts/accountStatement/getAccountStatementReport";

export const getPrintAccMasterAPIURL = BaseIP + "/output/";



//Account Summary Report APIURL
//localhost:8080/institute/sss/accounts/balanceSummery/getBalanceSummaryReport?acYear=2021&sid=3&bid=1&langId=1&fromDate=1/1/2022&toDate=22/2/2022&deptName=प्राथमिक विभाग
export const printAccountSummary = BaseURL + "accounts/balanceSummery/getBalanceSummaryReport";



//Receipt & payment Report APIURL
//localhost:8080/institute/sss/accounts/receiptPayment/getReceiptPaymentReport?langId=2&year=2022&sid=3&bid=1&fromDate=2022-06-01&toDate=2023-03-03
export const printReceiptPaymentReportAPIURL = BaseURL + "accounts/receiptPayment/getReceiptPaymentReport";



//Income & expenditure Report APIURL
//localhost:8080/institute/sss/accounts/receiptPayment/getIncomeExpenditureReport?langId=2&year=2022&sid=3&bid=1&fromDate=2022-01-01&toDate=2023-03-03
export const printIncomeAndExpenditureReportAPIURL = BaseURL + "accounts/receiptPayment/getIncomeExpenditureReport";



//Balance Sheet Report APIURL
//localhost:8080/institute/sss/accounts/receiptPayment/getBalanceSheetReport?langId=2&year=2022&sid=3&bid=1&fromDate=2022-01-01&toDate=2023-03-03
export const printBalanceSheetReportAPIURL = BaseURL + "accounts/receiptPayment/getBalanceSheetReport";

//getBalanceSheetScheduleDetailReport
export const printBlanceSheetScheduleReportAPIURL = BaseURL + "accounts/receiptPayment/getBalanceSheetScheduleDetailReport";

// localhost:8080/institute/sss/acMaster/grpHead/getScheduledGroupHeaderList?sid=3

export const getScheduledGroupHeaderListReportAPIURL = BaseURL + "acMaster/grpHead/getScheduledGroupHeaderList"