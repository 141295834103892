import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { getClassWithDiv } from '../../Academic/Services/StudentRollNoAPIURL';
import Checkbox from '@mui/material/Checkbox';
import { getStudentdataForSemiEnglish, saveSemiEnglishStudents } from '../Services/SemiEnglishStudentsAPIURL';
import Loader from '../../CommonComponent/Loader';



const SemiEnglishStudents = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Semi English Students";

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")

    const [loaderOption, setLoaderOption] = useState(false);
    const [classDivObj, setClassDivObj] = useState(null);
    const [classDivisionData, setClassDivisionData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [checkValue, setCheckValue] = useState([]);
    const [checkAllSub, setCheckAllSub] = useState(false);
    const [chkRegNum, setChkRegNum] = useState('');
    const [Val, setVal] = useState(false);

    //console.log("checkValue data: " + JSON.stringify(checkValue));


    const handleChkBox = (val, regNo, dId, cId, rNo) => {
        setVal(val);
        const saveObject = {
            classId: cId,
            divisionId: dId,
            regNo: regNo,
            rollNo: rNo,
            sessionYear: authUser.sessionYear,
            sectionId: authUser.deptId,
            branchId: authUser.branchId,
            isSemiEnglish: 1,
        }

        if (val == true) {
            setCheckValue(checkValue.concat(saveObject));

            //if checkbox is checked
            let updatedData = studentData;
            studentData.map((e, index) => {
                if (e.regNo == saveObject.regNo) {
                    updatedData[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isSemiEnglish: 1
                    })
                }

            })
            let checkdata = studentData.filter(e => e.isSemiEnglish === 0)
            //console.log(checkdata.length);
            //console.log("Checkvalue=" + (checkValue.length + 1) + "------" + "filteredData=" + filteredData.length)
            if (checkdata.length === 0) {
                setCheckAllSub(true);
            }

            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setStudentData(updatedData);
                setFilteredData(updatedData)
            }
        }
        else if (val == false) {
            const removedData = checkValue.filter((item) => item.regNo != regNo)
            setCheckValue(removedData);
            setCheckAllSub(false);

            //if checkbox is unChecked
            let updatedData = studentData;
            studentData.map((e, index) => {
                if (e.regNo == saveObject.regNo) {
                    updatedData[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isSemiEnglish: 0
                    })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setStudentData(updatedData);
                setFilteredData(updatedData);
            }
        }
        setChkRegNum(saveObject.regNo);
    }

    useEffect(() => {
        if (Val == true) {
            let updatedDataTrue = studentData
            studentData.map((e, index) => {
                if (e.regNo == chkRegNum) {
                    updatedDataTrue[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isSemiEnglish: 1
                    })
                }
            })
            if (searchTerm == "") {
                setChkRegNum('');
                setStudentData(updatedDataTrue);
                setFilteredData(updatedDataTrue);
            }
        }
        else if (Val == false) {
            //console.log(checkDivId)
            let updatedDataFalse = studentData
            studentData.map((e, index) => {
                if (e.regNo == chkRegNum) {
                    updatedDataFalse[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isSemiEnglish: 0
                    })
                }
            })

            //console.log(JSON.stringify(updatedDataFalse))
            if (searchTerm == "") {
                setChkRegNum('');
                setStudentData(updatedDataFalse);
                setFilteredData(updatedDataFalse);
            }
        }


        if (checkAllSub == false && Val == false) {
            let updatedDataFalse = studentData
            studentData.map((e, index) => {
                if (e.regNo == chkRegNum) {
                    updatedDataFalse[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isSemiEnglish: 0
                    })
                }
            })
            if (searchTerm == "") {
                setStudentData(updatedDataFalse);
                setFilteredData(updatedDataFalse);
            }
        }
        else if (checkAllSub == true) {
            let updatedDataTrue = studentData
            studentData.map((e, index) => {
                //if (e.divisionId == checkDivId) {
                updatedDataTrue[index] = ({
                    ...e,
                    sessionYear: authUser.sessionYear,
                    sectionId: authUser.deptId,
                    branchId: authUser.branchId,
                    isSemiEnglish: 1
                })
                //}
            })
            if (searchTerm == "") {
                setChkRegNum('');
                setStudentData(updatedDataTrue);
                setFilteredData(updatedDataTrue);
            }
        }
    }, [searchTerm, Val, checkAllSub, chkRegNum])


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.branchId != null || authUser.deptId != null) {
            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivisionData(res.data);
                })
        }
    }

    //get student data for allocation
    const getStudentData = async (cId, dId) => {
        setLoaderOption(true);
        setStudentData([]);
        setFilteredData([]);
        setCheckValue([]);
        setSearchTerm("");
        setSortedBy(null);
        setAscending(false);
        //console.log(`${getStudentdataForSemiEnglish}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}`)
        await axios.get(`${getStudentdataForSemiEnglish}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}`)
            .then((res) => {
                setStudentData(res.data);
                setFilteredData(res.data);
                setLoaderOption(false);

                //singleChecked data
                let SaveObj = (res.data).filter(e => {
                    return e.isSemiEnglish == 1
                })
                let updatedData = SaveObj;
                SaveObj.map((e, index) => {
                    updatedData[index] = ({
                        classId: e.classId,
                        divisionId: e.divisionId,
                        regNo: e.regNo,
                        rollNo: e.rollNo,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isSemiEnglish: 1,
                    })
                })
                setCheckValue(updatedData);

                //allchecked data
                let allCheckedData = (res.data).filter(e => {
                    return e.isSemiEnglish == 1
                })
                let updateData = allCheckedData;
                allCheckedData.map((e, index) => {
                    updateData[index] = ({
                        classId: e.classId,
                        divisionId: e.divisionId,
                        regNo: e.regNo,
                        rollNo: e.rollNo,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isSemiEnglish: 1,
                    })
                })
                getChekedData(updateData, cId, dId);

            })

    }

    //check all checboxes are checked or not
    const getChekedData = (list, Cid, Did) => {

        axios(`${getStudentdataForSemiEnglish}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${Cid}&divId=${Did}`)
            .then(res => {
                let updatedData = res.data;
                (res.data).map((e, index) => {
                    updatedData[index] = ({
                        classId: e.classId,
                        divisionId: e.divisionId,
                        regNo: e.regNo,
                        rollNo: e.rollNo,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isSemiEnglish: 1
                    })
                })

                const checkedData = list.length === updatedData.length && list.every((o, i) => Object.keys(o).length === Object.keys(updatedData[i]).length && Object.keys(o).every(k => o[k] === updatedData[i][k]))

                if (checkedData == true) {
                    setCheckAllSub(true);
                } else {
                    setCheckAllSub(false);
                }
            })
    }

    //check all checkboxes code
    const checkAllSubjects = (e) => {
        setCheckValue([]);
        setCheckAllSub(e.target.checked);
        if (e.target.checked == true) {
            setCheckValue([]);
            setCheckAllSub(true);
            axios.get(`${getStudentdataForSemiEnglish}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
                .then((response) => {
                    setStudentData([])
                    setFilteredData([])
                    let updatedData = response.data;
                    response.data.map((e, index) => {
                        updatedData[index] = ({
                            ...e,
                            sessionYear: authUser.sessionYear,
                            sectionId: authUser.deptId,
                            branchId: authUser.branchId,
                            isSemiEnglish: 1,
                        })
                    })
                    setCheckValue(updatedData);
                    setStudentData(updatedData)
                    setFilteredData(updatedData)
                })

            //setFilteredData(updatedData)
            //console.log("true=======" + JSON.stringify(updatedData))
        }
        else if (e.target.checked == false) {
            setCheckValue([]);
            setCheckAllSub(false);
            axios.get(`${getStudentdataForSemiEnglish}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
                .then((response) => {
                    setStudentData([])
                    setFilteredData([])
                    let updatedData = response.data;
                    response.data.map((e, index) => {
                        updatedData[index] = ({
                            ...e,
                            sessionYear: authUser.sessionYear,
                            sectionId: authUser.deptId,
                            branchId: authUser.branchId,
                            isSemiEnglish: 0
                        })
                    })
                    setStudentData(updatedData)
                    setFilteredData(updatedData)
                })

            //if all data removed from classDivData or checkvalue.length === 0           
            setCheckValue([]);
        }

    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...studentData.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...studentData.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
            setFilteredData(studentData);
            setStudentData(studentData);
        }
        getSortedData(studentData, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentdataForSemiEnglish}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => (a[key] || '').toString().toLowerCase() < (b[key] || '').toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => (a[key] || '').toString().toLowerCase() > (b[key] || '').toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === studentData.length && list.every((o, i) => Object.keys(o).length === Object.keys(studentData[i]).length && Object.keys(o).every(k => o[k] === studentData[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(studentData);
                }
            })
    }


    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = studentData.filter((item) =>
            (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.rollNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const saveStudentsForSemiEng = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        //console.log("checkValue data: " + JSON.stringify(checkValue));
        if (checkValue.length === 0) {
            const removeObj = [{
                classId: classDivObj.classId,
                divisionId: classDivObj.divisionId,
                regNo: 0,
                rollNo: 0,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
            }]
            await axios.post(`${saveSemiEnglishStudents}`, removeObj)
                .then(res => {
                    if (res.data != null) {
                        setStudentData([]);
                        setFilteredData([]);
                        setClassDivObj(null);
                        setSearchTerm("");
                        setCheckValue([]);
                        setLoaderOption(false);
                        toast.success("Semi-English student Allocation Successfully done.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    }
                }).catch(error => {
                    console.log(error);
                })
        }
        else {
            await axios.post(`${saveSemiEnglishStudents}`, checkValue)
                .then(res => {
                    if (res.data != null) {
                        setStudentData([]);
                        setFilteredData([]);
                        setClassDivObj(null);
                        setSearchTerm("");
                        setCheckValue([]);
                        setLoaderOption(false);
                        toast.success("Semi-English Student Allocation Successfully done.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    }
                }).catch(error => {
                    console.log(error);
                })
        }

    }

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (classDivObj !== null || classDivObj !== "") {
                    console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.semiEnglishBtn')
                    if (btn !== null) {
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={classDivisionData}
                                        style={{ fontSize: '14px' }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        onChange={(event, newValue) => {
                                            setClassDivObj(newValue);
                                            if (newValue != null) {
                                                getStudentData(newValue.classId, newValue.divisionId);
                                            }
                                            else if (newValue == null) {
                                                setFilteredData([]);
                                                setStudentData([]);
                                                setCheckValue([]);
                                            }
                                        }}
                                        value={classDivObj}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            {
                                (studentData == null || studentData == "" || studentData == [])
                                    ?
                                    <h4 className='mt-5'>No Records, Select Class-Division</h4>
                                    :
                                    <div>
                                        <div className='row col-sm-4 my-1' style={{ float: 'right' }}>
                                            <div>
                                                <input
                                                    className='form-control form-control-sm'
                                                    type="text"
                                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                    placeholder="Search Here"
                                                    onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                                    value={searchTerm}
                                                />
                                            </div>
                                        </div>

                                        <form onSubmit={saveStudentsForSemiEng}>
                                            <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                                <table className="table table-bordered ">
                                                    <thead className="table-Default" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                        <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                            <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort("regNo")}>Reg No{sortedBy === "regNo" ? isAscending ? '↑' : '↓' : ''}</th>
                                                            <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort((authUser.branchMedium == 1) ? "studentNameMr" : "studentNameEn")}>Student Name{sortedBy === ((authUser.branchMedium == 1) ? "studentNameMr" : "studentNameEn") ? isAscending ? '↑' : '↓' : ''}</th>
                                                            <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort("rollNo")}>Roll No{sortedBy === "rollNo" ? isAscending ? '↑' : '↓' : ''}</th>
                                                            <th style={{ fontSize: "15px", fontWeight: "600", margin: "0px", padding: "2px" }}>
                                                                <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                                                                    checked={(checkAllSub == true) ? true : false}
                                                                    onChange={checkAllSubjects}
                                                                /> Semi</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                        {filteredData
                                                            .map((item, index) => {
                                                                return (

                                                                    <tr key={index} className="table-sm">
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "10px" }} >{item.regNo}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.rollNo}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px", width: "150px" }} >
                                                                            <Checkbox defaultChecked={(item.isSemiEnglish === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                                onChange={(e) => {
                                                                                    let val = e.target.checked;
                                                                                    handleChkBox(val, item.regNo, item.divisionId, item.classId, item.rollNo);
                                                                                }}

                                                                            />
                                                                        </td>
                                                                    </tr>

                                                                )
                                                            })}
                                                    </tbody>
                                                </table>

                                            </div>

                                            <div className='mt-4'>
                                                <button className='semiEnglishBtn btn btn-primary btn-sm mb-2' type='submit' ><AddIcon fontSize="small" />Save Changes</button>
                                            </div>
                                        </form>
                                    </div>
                            }
                        </div>
                    </>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default SemiEnglishStudents;





