import {BaseIP, BaseURL, BaseURLMaster} from "./APIURL";

//Class Master API Link
export const ClassMasterGetMapping=BaseURLMaster + "Classes/";
export const ClassMasterPostMapping=BaseURLMaster + "saveClass/";
export const ClassMasterPutMapping=BaseURLMaster + "updateClass/";
export const ClassMasterDeleteMapping=BaseURLMaster + "deleteClass/";

//get Class data
export const getClassData = BaseURLMaster + "getClassesBySectionAndBranch";


///institute/sss/master/classWithDivision
export const getClassWithDiv = BaseURLMaster + "classWithDivisionBySectionId";

