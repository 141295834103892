import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import moment from 'moment';
import {
    getCategoryReportDataCategoryWiseAPIURL, getCategoryReportDataAllAPIURL, printAllBudgetEntryReportAPIURL,
    printCategoryWiseBudgetEntryReportAPIURL, printCategoryWiseAssetReportAPIURL, getCategoryBySectionAndBranch,
    getSainikSchoolHostelConsolidatedDataAPIURL, printSanikSchoolHostelConsolidatedReport, getExternalExamBudgetConsolidatedReport, printExternalExamBudgetConsolidatedReport
} from './Services/BudgetReportAPIURL';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import BudgetCategoryDetailReportTable from './BudgetReportTables/BudgetCategoryDetailReportTable';
import BudgetConsolidateReportTable from './BudgetReportTables/BudgetConsolidateReportTable';
import BudgetAssetEntryReportTable from './BudgetReportTables/BudgetAssetEntryReportTable';
import { getBudgetResolutionNoDateAPIURL } from '../Services/BudgetNoAndDateAPIURL';
import { getBudgetAssetEntryAPIURL } from '../Transaction/Services/BudgetEntryAPIURL';
import SainikSchoolConsolidatedReportTable from './SansthaBudgetReportTables/SainikSchoolConsolidatedReportTable';
import BudgetExternalExamConsolidatedReport from './BudgetExternalExamReportTable/BudgetExternalExamConsolidatedReport';


const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const BudgetReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Budget Report";
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [previousYrTotalBudget, setPreviousYrTotalBudget] = useState(0);
    const [nextYrTotalBudget, setNextYrTotalBudget] = useState(0);
    const [actualDecTotalBudget, setActualDecTotalBudget] = useState(0);
    const [actualMarchTotalBudget, setActualMarchTotalBudget] = useState(0);

    const [futureYear, setFutureYear] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [categoryObj, setCategoryObj] = useState(null);
    const [optionId, setOptionId] = useState(1);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [side, setSide] = useState([]);
    const [budgetSide, setBudgetSide] = useState([]);

    const [schoolData, setSchoolData] = useState([]);
    const [schoolNm, setSchoolNm] = useState('');

    const [categoryType, setCategoryType] = useState('');

    const [resolutionNo, setResolutionNo] = useState('');
    const [resolutionDate, setResolutionDate] = useState(null);

    const [actualSchoolReceiptFeeBudget, setActualSchoolReceiptFeeBudget] = useState(0);
    const [actualSessionReceiptFeeBudget, setActualSessionReceiptFeeBudget] = useState(0);
    const [actualSchoolPaymentFeeBudget, setActualSchoolPaymentFeeBudget] = useState(0);
    const [actualSessionPaymentFeeBudget, setActualSessionPaymentFeeBudget] = useState(0);

    const [totalAssetEntryBudget, setTotalAssetEntryBudget] = useState(0);

    const [totalActualBudget, setTotalActualBudget] = useState(0);

    const [actualSchoolReceiptHostelBudget, setActualSchoolReceiptHostelBudget] = useState(0);
    const [actualSchoolPaymentHostelBudget, setActualSchoolPaymentHostelBudget] = useState(0);

    const [studentWiseBudget, setStudentWiseBudget] = useState(0);

    const [externalExamReceiptData, setExternalExamReceiptData] = useState([]);
    const [externalExamPaymentData, setExternalExamPaymentData] = useState([]);

    const [totalExternalExamReceiptAmt, setTotalExternalExamReceiptAmt] = useState(0);
    const [totalExternalExamPaymentAmt, setTotalExternalExamPaymentAmt] = useState(0);

    const currentYear = new Date();

    useEffect(() => {
        if (authUser.sessionYear < moment(currentYear).format("YYYY")) {
            let newYear = moment(authUser.fromDate).format("YY")
            //console.log(newYear)
            setFutureYear(parseInt(newYear) + 1);
        }
        else {
            setFutureYear(moment(currentYear).add(1, 'year').format("YY"))
        }
    }, [authUser])

    //BudgetCategoryDetailReportTable
    const TableHeading2 = [
        { label: 'जमा बाजू तपशील', rowspan: (optionId == 1) ? '2' : '', colspan: (optionId == 1) ? '' : '1', key: 'receipt', isAction: true },
        { label: 'जमा', value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: 'Actual Budget', value: 'actual', rowspan: '', colspan: '3', isAction: true },
        { label: 'अंदाजे जमा', value: 'budget', rowspan: '', colspan: '1', isAction: true },
    ];

    const TableHeading3 = [
        { label: 'खर्च बाजू तपशील', rowspan: (optionId == 1) ? '2' : '', colspan: (optionId == 1) ? '' : '1', key: 'payment', isAction: true },
        { label: 'खर्च', value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: 'Actual', value: 'actual', rowspan: '', colspan: '3', isAction: true },
        { label: 'अंदाजे खर्च', value: 'budget', rowspan: '', colspan: '1', isAction: true }
    ];

    const TableHeading5 = [
        { label: `${(categoryObj != null) ? categoryObj.nameMr : ''}`, key: 'categoryId', isAction: true },
        { label: `${parseInt(authUser.sessionYear)}-${futureYear}`, value: 'budgetLastYear', isAction: true },
        { label: `31/12/${parseInt(authUser.sessionYear)}`, value: 'actualBudget1', isAction: true },
        { label: `31/03/${parseInt(authUser.sessionYear) + 1}`, value: 'actualBudget2', isAction: true },
        { label: `एकूण ${(categoryObj != null) ? ((categoryObj.optionId == 1) ? "जमा" : "खर्च") : ''}`, value: 'actualBudget2', isAction: true },
        { label: `${parseInt(authUser.sessionYear) + 1}-${parseInt(futureYear) + 1}`, value: 'budgetNextYear', isAction: true },

    ];

    //BudgetConsolidateReportTable
    const TableHeading7 = [
        { label: 'शाळा कीर्द जमा रुपये', textAlign: "", key: 'receipt', isAction: true },
        { label: 'सत्र फी कीर्द जमा रुपये', textAlign: "", key: '', isAction: true },
        { label: 'तपशील', key: 'payment', textAlign: "center", isAction: true },
        { label: 'शाळा कीर्द खर्च रुपये', textAlign: "", key: 'receipt', isAction: true },
        { label: 'सत्र फी कीर्द खर्च रुपये', textAlign: "", key: '', isAction: true },
    ]

    const TableHeading4 = [
        { label: 'रेग्युलर बी.एड. कोर्स कीर्द जमा रुपये', textAlign: "", key: 'receipt', isAction: true },
        { label: 'विनाअनुदानित कोर्सेस कीर्द जमा रुपये', textAlign: "", key: '', isAction: true },
        { label: 'तपशील', key: 'payment', textAlign: "center", isAction: true },
        { label: 'रेग्युलर बी.एड. कोर्स कीर्द खर्च रुपये', textAlign: "", key: 'receipt', isAction: true },
        { label: 'विनाअनुदानित कोर्सेस कीर्द खर्च रुपये', textAlign: "", key: '', isAction: true },
    ]

    //BudgetAssetEntryReportTable
    const TableHeading8 = [
        { label: 'तपशील', key: 'accNameMr', textAlign: "", isAction: true },
        { label: 'नगसंख्या', key: 'quantity', textAlign: "", isAction: true },
        { label: 'दर', key: 'rate', textAlign: "center", isAction: true },
        { label: 'एकूण', key: 'totalRate', textAlign: "center", isAction: true },
    ]

    //SainikSchoolConsolidatedReportTable
    const TableHeading9 = [
        { label: 'वसतिगृह कीर्द जमा रुपये', textAlign: "center", key: 'receipt', isAction: true },
        { label: 'तपशील', key: 'payment', textAlign: "center", isAction: true },
        { label: 'वसतिगृह कीर्द खर्च रुपये', textAlign: "center", key: 'payment', isAction: true },
    ]

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        await axios.get(`${BaseURLInstituteMaster}`)
            .then(res => {
                setSchoolData(res.data);
            })

        if (authUser.deptId != null && authUser.branchId != null) {
            //console.log(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {

                    let newData = res.data.filter(e => e.categoryType != 2)
                    setCategoryData(newData);
                })
        }

    }

    //get category list
    const getCategoryData = async (rId) => {
        setCategoryData([]);
        //for school All Categories list
        if (rId == 1) {
            //console.log(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {

                    let newData = res.data.filter(e => e.categoryType != 2)
                    setCategoryData(newData);
                })
        }
        //for hostel All Categories list
        else if (rId == 2) {
            //console.log(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getCategoryBySectionAndBranch}?sid=14&bid=${authUser.branchId}`)
                .then(res => {
                    let newData = res.data.filter(e => e.categoryType != 2)
                    setCategoryData(newData);
                })
        }
        //for technical All Categories list
        else if (rId == 3) {
            //console.log(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getCategoryBySectionAndBranch}?sid=16&bid=${authUser.branchId}`)
                .then(res => {
                    let newData = res.data.filter(e => e.categoryType != 2)
                    setCategoryData(newData);
                })
        }
    }

    //get budget data by category
    const getCategoryWiseDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        schoolData.map(item => {
            if (item.id == authUser.branchId) {
                setSchoolNm(item.schoolNameMr);
            }
        })

        axios.get(`${getBudgetResolutionNoDateAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&sid=${authUser.deptId}`)
            .then(res => {
                res.data.map(e => {
                    setResolutionDate(e.date);
                    setResolutionNo(e.resolutionNo);
                })
            })

        //categorywise Budget Report
        if (optionId == 2) {
            setCategoryType(categoryObj.categoryType);

            //to compare single deptId from multiple DeptId's allocated in budget account master
            //Comapres deptId with departments allocated in budget account master
            let deptId = ''
            let s = categoryObj.deptId;
            let match = s.split(',')
            let value1 = match.indexOf('6')
            let value2 = match.indexOf('10')
            let value3 = match.indexOf('9')
            //console.log(match.indexOf('6'))
            if (value2 >= 0 && authUser.deptId == 4) {
                deptId = 10
            }
            else if (value3 >= 0 && authUser.deptId == 5) {
                deptId = 9
            }
            else if (value1 >= 0) {
                deptId = 6
            }
            else if (value1 < 0 || value2 < 0 || value3 < 0) {
                deptId = authUser.deptId
            }

            //for school consolidated budget report
            // if (reportOptionId == 1) {
            if (categoryObj.categoryType == 1) {
                setActualDecTotalBudget('');
                setActualMarchTotalBudget('');
                setNextYrTotalBudget('');
                setPreviousYrTotalBudget('');
                setSide([]);
                setBudgetSide([]);
                //console.log(categoryObj.deptId)                  

                console.log(`${getCategoryReportDataCategoryWiseAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${deptId}&bid=${authUser.branchId}`)
                await axios.get(`${getCategoryReportDataCategoryWiseAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            let upadtedData = res.data
                            upadtedData.map((e, index) => {
                                upadtedData[index] = ({
                                    ...e,
                                    totalActualBudget: parseFloat(e.actualBudget1) + parseFloat(e.actualBudget2)
                                })
                            })
                            setData(upadtedData);
                            setFilteredData(upadtedData);

                            const TotalActualBudget = upadtedData.reduce((prev, curr) => prev + parseFloat(curr.totalActualBudget), 0);
                            //console.log(TotalActualBudget)
                            setTotalActualBudget(parseFloat(TotalActualBudget).toFixed('2'));
                            //console.log(res.data);

                            res.data.map(e => {
                                setActualDecTotalBudget((e.budget1Total).toFixed('2'));
                                setActualMarchTotalBudget((e.budget2Total).toFixed('2'));
                                setNextYrTotalBudget((e.nextYearTotal).toFixed('2'));
                                setPreviousYrTotalBudget((e.lastYearTotal).toFixed('2'));

                                if (e.optionId == 1) {
                                    setSide(TableHeading2);
                                    setBudgetSide(TableHeading5)
                                }
                                else if (e.optionId == 2) {
                                    setSide(TableHeading3);
                                    setBudgetSide(TableHeading5)
                                }
                            })
                            setLoaderOption(false);
                        }

                    }).catch(err => {
                        console.log("categorywise detail report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else if (categoryObj.categoryType == 3) {
                console.log(`${getBudgetAssetEntryAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${deptId}&bid=${authUser.branchId}`)
                await axios.get(`${getBudgetAssetEntryAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        if (res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setData(res.data);
                            setFilteredData(res.data);

                            const TotalAssetBudget = res.data.reduce((prev, curr) => prev + parseFloat(curr.totalRate), 0);
                            setTotalAssetEntryBudget(parseFloat(TotalAssetBudget))
                            //console.log(res.data)                       
                            setLoaderOption(false);
                        }

                    }).catch(err => {
                        console.log("categorywise asset report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
        //consolidated budget report
        else if (optionId == 1) {
            //for school consolidated budget report
            //hostel budget report
            if (authUser.deptId == 14) {
                await axios.get(`${getSainikSchoolHostelConsolidatedDataAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&sid=${authUser.deptId}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            //console.log(JSON.stringify(res.data))
                            setData(res.data);
                            setFilteredData(res.data);

                            let receiptHostelData = res.data.filter(e => e.optionId == 1)
                            let paymentHostelData = res.data.filter(e => e.optionId == 2)

                            const TotalActualReceiptHostelBudget = receiptHostelData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                            setActualSchoolReceiptHostelBudget(parseFloat(TotalActualReceiptHostelBudget).toFixed('2'));

                            const TotalActualPaymentHostelBudget = paymentHostelData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                            setActualSchoolPaymentHostelBudget(parseFloat(TotalActualPaymentHostelBudget).toFixed('2'));

                            const objIds = res.data.reduce((a, { studentCount }) => {
                                a[studentCount] = a[studentCount] || { studentCount }
                                return {
                                    ...a, ...{
                                        [studentCount]: {
                                            studentCount,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)

                            const TotalStudentCount = result.reduce((prev, curr) => prev + parseFloat(curr.studentCount), 0);
                            let studentCountValue = TotalActualPaymentHostelBudget / TotalStudentCount
                            //console.log(studentCountValue)
                            setStudentWiseBudget(parseFloat(studentCountValue).toFixed('2'))

                            setLoaderOption(false);
                        }

                    }).catch(err => {
                        console.log("consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //external exam budget report
            else if (authUser.deptId == 15) {
                await axios.get(`${getExternalExamBudgetConsolidatedReport}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warb("Data not found.");
                        }
                        else {
                            const receiptSideData = res.data.filter(e => e.optionId == 1);
                            const paymentSideData = res.data.filter(e => e.optionId == 2);

                            setExternalExamReceiptData(receiptSideData);
                            setExternalExamPaymentData(paymentSideData);

                            let updatedData = receiptSideData
                            receiptSideData.map((e, index) =>
                                updatedData[index] = ({ ...e, totalStudWitFee: parseFloat(e.studentCount) * parseFloat(e.fee) })
                            )

                            const TotalReceiptSideAmt = updatedData.reduce((prev, curr) => prev + parseFloat(curr.totalStudWitFee), 0);
                            setTotalExternalExamReceiptAmt(parseFloat(TotalReceiptSideAmt))

                            const TotalPaymentSideAmt = paymentSideData.reduce((prev, curr) => prev + parseFloat(curr.budgetNextYear), 0);
                            setTotalExternalExamPaymentAmt(parseFloat(TotalPaymentSideAmt))

                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("consolidate external exam report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //all dept's budget report
            else {
                await axios.get(`${getCategoryReportDataAllAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&sid=${authUser.deptId}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            //console.log(JSON.stringify(res.data))
                            setData(res.data);
                            setFilteredData(res.data);
                            setLoaderOption(false);

                            let upadtedReceiptData = res.data.filter(e => e.optionId == 1)
                            let upadtedPaymentData = res.data.filter(e => e.optionId == 2)

                            const TotalSchoolReceiptFeeBudget = upadtedReceiptData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                            setActualSchoolReceiptFeeBudget(parseFloat(TotalSchoolReceiptFeeBudget))
                            const TotalSessionReceiptFeeBudget = upadtedReceiptData.reduce((prev, curr) => prev + parseFloat(curr.sessionFee), 0);
                            setActualSessionReceiptFeeBudget(parseFloat(TotalSessionReceiptFeeBudget))

                            const TotalSchoolPaymentFeeBudget = upadtedPaymentData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                            setActualSchoolPaymentFeeBudget(parseFloat(TotalSchoolPaymentFeeBudget))
                            const TotalSessionPaymentFeeBudget = upadtedPaymentData.reduce((prev, curr) => prev + parseFloat(curr.sessionFee), 0);
                            setActualSessionPaymentFeeBudget(parseFloat(TotalSessionPaymentFeeBudget))
                        }

                    }).catch(err => {
                        console.log("consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
    }

    //print budget report
    const printAllBudgetEntryReport = async () => {
        setLoaderOption(true);
        //for school consolidated & categorywise budget report

        //consoliadted report print
        if (optionId == 1) {
            //hostel report print
            if (authUser.deptId == 14) {
                await axios.get(`${printSanikSchoolHostelConsolidatedReport}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&sid=${authUser.deptId}&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Hostel Consolidated Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //external exam report print
            else if (authUser.deptId == 15) {
                await axios.get(`${printExternalExamBudgetConsolidatedReport}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&sid=${authUser.deptId}&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("External Exam Consolidated Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //all dept report print
            else {
                console.log(`${printAllBudgetEntryReportAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&sid=${authUser.deptId}&langId=${authUser.branchMedium}`)
                await axios.get(`${printAllBudgetEntryReportAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&sid=${authUser.deptId}&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("All Budget category Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }

        }
        //categorywise print report
        else if (optionId == 2) {
            //to compare single deptId from multiple DeptId's allocated in budget account master
            //Comapres deptId with departments allocated in budget account master
            let deptId = ''
            let s = categoryObj.deptId;
            let match = s.split(',')
            let value1 = match.indexOf('6')
            let value2 = match.indexOf('10')
            let value3 = match.indexOf('9')
            //console.log(match.indexOf('6'))
            if (value2 >= 0 && authUser.deptId == 4) {
                deptId = 10
            }
            else if (value3 >= 0 && authUser.deptId == 5) {
                deptId = 9
            }
            else if (value1 >= 0) {
                deptId = 6
            }
            else if (value1 < 0 || value2 < 0 || value3 < 0) {
                deptId = authUser.deptId
            }


            if (categoryObj.categoryType == 1) {
                await axios.get(`${printCategoryWiseBudgetEntryReportAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget category wise Detail Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else if (categoryObj.categoryType == 3) {
                await axios.get(`${printCategoryWiseAssetReportAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget category wise Asset Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <div className=''>
                                <form onSubmit={getCategoryWiseDetails}>
                                    <div className="form-data">

                                        <div className="row my-2">
                                            <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                            <div className='col-sm-3'>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                    <Select
                                                        size='small'
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={optionId}
                                                        label="Select Option"
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                        onChange={(e) => {
                                                            setOptionId(e.target.value);
                                                            setData([]);
                                                            setFilteredData([]);
                                                            setCategoryObj(null);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? optionId : ""}
                                                    >
                                                        <MenuItem value={1}>Consolidated</MenuItem>
                                                        <MenuItem value={2} hidden={(authUser.deptId == 15) ? true : false}>Category Wise</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="row my-2" hidden={(optionId == 1) ? true : false}>
                                            <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={categoryData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setCategoryObj(newValue);
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={categoryObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    // renderOption={(props, option) => (
                                                    //     <div className='' {...props}>
                                                    //         <div className=''>
                                                    //             {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                                    //             <div
                                                    //                 className=''
                                                    //                 style={{
                                                    //                     display: "flex",
                                                    //                     flex: 1,
                                                    //                     fontSize: "11px",
                                                    //                     flexDirection: "row",
                                                    //                     //justifyContent: "space-between",
                                                    //                     padding: "0px 5px",
                                                    //                     color: "#464964",
                                                    //                     fontWeight: "550"
                                                    //                     //gridRow: 1
                                                    //                 }}
                                                    //             >
                                                    //                 <span><AccountBalanceIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {(authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}</span>
                                                    //                 <span className='ml-1 mr-1 mb-1' style={{ fontSize: "14px" }}>|</span>
                                                    //                 <span>{(option.side == "Receipt") ? <ReceiptLongIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <PaymentsIcon style={{ color: "#354AFA", fontSize: "14px" }} />} {option.side}</span>
                                                    //             </div>
                                                    //         </div>

                                                    //     </div>
                                                    // )}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(optionId == 2 && authUser.deptId != 15) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-sm-4" style={{ marginLeft: "120px" }}>
                                                <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className='mt-2'>
                                    {(() => {

                                        if (optionId == 1) {
                                            if (authUser.deptId == 14) {
                                                if (data.length != 0 || filteredData.length != 0) {
                                                    return (
                                                        <>
                                                            <SainikSchoolConsolidatedReportTable
                                                                TableHeading7={TableHeading9}
                                                                setData={setData}
                                                                setFilteredData={setFilteredData}
                                                                data={data}
                                                                filteredData={filteredData}
                                                                schoolNm={schoolNm}
                                                                resolutionNo={resolutionNo}
                                                                resolutionDate={resolutionDate}
                                                                printAllBudgetEntryReport={printAllBudgetEntryReport}
                                                                actualSchoolReceiptHostelBudget={actualSchoolReceiptHostelBudget}
                                                                actualSchoolPaymentHostelBudget={actualSchoolPaymentHostelBudget}
                                                                optionId={optionId}
                                                                studentWiseBudget={studentWiseBudget}
                                                            />

                                                        </>
                                                    )
                                                }
                                            }
                                            else if (authUser.deptId == 15) {
                                                if (externalExamReceiptData.length != 0 || externalExamPaymentData.length != 0) {
                                                    return (
                                                        <>
                                                            <BudgetExternalExamConsolidatedReport
                                                                futureYear={futureYear}
                                                                printAllBudgetEntryReport={printAllBudgetEntryReport}
                                                                externalExamReceiptData={externalExamReceiptData}
                                                                externalExamPaymentData={externalExamPaymentData}
                                                                totalExternalExamReceiptAmt={totalExternalExamReceiptAmt}
                                                                totalExternalExamPaymentAmt={totalExternalExamPaymentAmt}
                                                            />

                                                        </>
                                                    )
                                                }
                                            }
                                            else {
                                                if (data.length != 0 || filteredData.length != 0) {
                                                    return (
                                                        <>
                                                            <BudgetConsolidateReportTable
                                                                TableHeading7={(authUser.deptId == 5) ? TableHeading4 : TableHeading7}
                                                                setData={setData}
                                                                setFilteredData={setFilteredData}
                                                                data={data}
                                                                filteredData={filteredData}
                                                                schoolNm={schoolNm}
                                                                resolutionNo={resolutionNo}
                                                                resolutionDate={resolutionDate}
                                                                printAllBudgetEntryReport={printAllBudgetEntryReport}
                                                                actualSchoolReceiptFeeBudget={actualSchoolReceiptFeeBudget}
                                                                actualSessionReceiptFeeBudget={actualSessionReceiptFeeBudget}
                                                                actualSchoolPaymentFeeBudget={actualSchoolPaymentFeeBudget}
                                                                actualSessionPaymentFeeBudget={actualSessionPaymentFeeBudget}
                                                                optionId={optionId}
                                                                authUser={authUser}
                                                            />
                                                        </>
                                                    )
                                                }
                                            }
                                        }
                                        else if (optionId == 2) {
                                            if (data.length != 0 || filteredData.length != 0) {
                                                if (categoryType == 1) {
                                                    return (
                                                        <>
                                                            <BudgetCategoryDetailReportTable
                                                                setData={setData}
                                                                setFilteredData={setFilteredData}
                                                                data={data}
                                                                filteredData={filteredData}
                                                                side={side}
                                                                budgetSide={budgetSide}
                                                                previousYrTotalBudget={previousYrTotalBudget}
                                                                actualDecTotalBudget={actualDecTotalBudget}
                                                                actualMarchTotalBudget={actualMarchTotalBudget}
                                                                nextYrTotalBudget={nextYrTotalBudget}
                                                                optionId={optionId}
                                                                schoolNm={schoolNm}
                                                                printAllBudgetEntryReport={printAllBudgetEntryReport}
                                                                resolutionNo={resolutionNo}
                                                                resolutionDate={resolutionDate}
                                                                totalActualBudget={totalActualBudget}
                                                            />
                                                        </>
                                                    )
                                                }
                                                else if (categoryType == 3) {
                                                    return (
                                                        <>
                                                            <BudgetAssetEntryReportTable
                                                                TableHeading8={TableHeading8}
                                                                setData={setData}
                                                                setFilteredData={setFilteredData}
                                                                data={data}
                                                                filteredData={filteredData}
                                                                schoolNm={schoolNm}
                                                                resolutionNo={resolutionNo}
                                                                resolutionDate={resolutionDate}
                                                                printAllBudgetEntryReport={printAllBudgetEntryReport}
                                                                totalAssetEntryBudget={totalAssetEntryBudget}
                                                            />
                                                        </>
                                                    )
                                                }
                                            }
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default BudgetReport