import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from "axios";
import { ConcessionPostMapping, BaseURLMasterConsession } from "../CommonServices/ConcessionMasterAPIURL";

const AddNewConcession = ({ setConcessionData }) => {
    const [concessionMarathi, setConcessionMarathi] = useState("");
    const [concessionEnglish, setConcessionEnglish] = useState("");

    const getConcessionData = async () => {
        await axios.get(`${BaseURLMasterConsession}`)
            .then((response) => {
                setConcessionData(response.data);
            })
    }

    const saveBtnClick = async (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        const saveObject = {
            nameMr: concessionMarathi,
            nameEn: concessionEnglish,
        };
        await axios.post(`${ConcessionPostMapping}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getConcessionData();

                    // get modal
                    const modal = document.getElementById('concessionModal');

                    // change state like in hidden modal
                    modal.classList.remove('show');
                    modal.setAttribute('aria-hidden', 'true');
                    modal.setAttribute('style', 'display: none');

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                    // remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);
                    toast.success("Concession Saved sucessfully.");
                } else {
                    toast.error("Operation failed.");
                }
            });

        setConcessionMarathi("");
        setConcessionEnglish("");

    };
    return (
        <>
            <div className="modal fade" id="concessionModal" tabIndex="-1" aria-labelledby="concessionModalLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                            <h5 className="modal-title" id="concessionModalLabel">Add New Concession</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={saveBtnClick}>
                            <div className="modal-body">

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && concessionMarathi.length === 0) ? e.preventDefault() : ""}
                                    value={concessionMarathi}
                                    onChange={(e) => setConcessionMarathi(e.target.value)}
                                    label="Concession Marathi"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && concessionEnglish.length === 0) ? e.preventDefault() : ""}
                                    value={concessionEnglish}
                                    onChange={(e) => setConcessionEnglish(e.target.value)}
                                    label="Concession English"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" >Save</button>
                                <button type="reset" className="btn btn-danger" onClick={() => { setConcessionEnglish(""); setConcessionMarathi(""); }} data-bs-dismiss="modal">Cancle</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}


export default AddNewConcession