import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useSelector } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MasterFormAccountGroupTable from './MasterFormAccountGroupTable';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { BaseURLAccountGroupMaster, AccountGroupMasterSave, AccountGroupMasterUpdate, BaseURLSection, getGroupHeadListByGroupTypeAndGroupHeadAndSectionId } from '../../CommonServices/AccountGroupAPIURL';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { getReceiptPatmentGroupAPIURL } from '../Services/ReceiptPaymentGroupAPIURL';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WorkIcon from '@mui/icons-material/Work';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));


const GroupHeadData = [
    {
        id: 1,
        Head1: "Income",
        Head2: "Asset"
    },
    {
        id: 2,
        Head1: "Expenditure",
        Head2: "Liability",
    },
];

const GroupBookData = [
    {
        id: 1,
        Heading: "Income And Expenditure"
    },
    {
        id: 2,
        Heading: "Balance Sheet"
    }
]


const MasterFormAccountGroup = () => {
    const classes = useStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const titleId = "Account Group Form";
    const authUser = useSelector((state) => state.user.value);
    const branchId = authUser.branchId;

    const navigate = useNavigate();
    let { acgId, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "accountGroupSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "accountGroupUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);
    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState();
    const [data, setData] = useState([]);
    const [groupCode, setGroupCode] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [groupHead, setGroupHead] = useState("");
    const [groupBook, setGroupBook] = useState("");
    const [glNameMarathi, setGlNameMarathi] = useState("");
    const [glNameEnglish, setGlNameEnglish] = useState("");
    const [index, setIndex] = useState("");
    const [isForward, setIsForward] = useState(0);
    const [sectionData, setSectionData] = useState([]);
    const [sectionId, setSectionId] = useState([]);
    const [sectionObj, setSectionObj] = useState([]);
    const [recAndPayGroupData, setRecAndPayGroupData] = useState([]);
    const [recAndPayGroupObj, setRecAndPayGroupObj] = useState(null);
    const [incExpAndBalSheetData, setIncExpAndBalSheetData] = useState([]);
    const [incExpAndBalSheetObj, setIncExpAndBalSheetObj] = useState(null);

    // console.log("section Object=........"+ JSON.stringify(sectionObj));
    const HandleForwardCheckbox = (e) => {
        setIsForward(e.target.checked);
        if (e.target.checked == true) {
            setIsForward(1);
        }
        else {
            setIsForward(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${BaseURLAccountGroupMaster}${authUser.deptId}`)
            .then((response) => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);

                const selectedItem = response.data.find(e => e.id == acgId)

                setId(selectedItem.id);
                setGlNameMarathi(selectedItem.groupNameMr);
                setGlNameEnglish(selectedItem.groupNameEn);
                setGroupCode(selectedItem.groupCode);
                setGroupBook(selectedItem.groupType);
                // console.log(selectedItem);
                setGroupHead(selectedItem.groupHead);
                setIndex(selectedItem.index);
                setIsForward(selectedItem.isForward);
                setSectionObj(selectedItem.sectionId);
                setSectionId((selectedItem.sectionId).map(e => e.id).join(','));

                axios.get(`${BaseURLSection}`)
                    .then((response) => {
                        setSectionData(response.data);
                    })

                axios.get(`${getReceiptPatmentGroupAPIURL}?sid=${authUser.deptId}`)
                    .then(res => {
                        setRecAndPayGroupData(res.data);
                        setRecAndPayGroupObj(res.data.find(e => e.id == selectedItem.rpGroupId))
                    }).catch(err => {
                        console.log('get Receipt payment group' + err)
                    })

                axios.get(`${getGroupHeadListByGroupTypeAndGroupHeadAndSectionId}?groupType=${selectedItem.groupType}&sid=${authUser.deptId}`)
                    .then(res => {
                        setIncExpAndBalSheetData(res.data);
                        setIncExpAndBalSheetObj(res.data.find(e => e.id == selectedItem.groupHeadId))
                        setLoaderOption(false);
                    }).catch(err => {
                        console.log("Get Inc.,Exp. $ Bal. sheet data err " + err);
                        setLoaderOption(false);
                    })
            })
    }

    const getApiData = async () => {
        //console.log(`${BaseURLAccountGroupMaster}${authUser.deptId}`)
        if (authUser.deptId != null) {
            await axios.get(`${BaseURLAccountGroupMaster}${authUser.deptId}`)
                .then((response) => {
                    setData(response.data);
                    setFilteredData(response.data);
                })
        }

        await axios.get(`${getReceiptPatmentGroupAPIURL}?sid=${authUser.deptId}`)
            .then(res => {
                setRecAndPayGroupData(res.data);
            }).catch(err => {
                console.log('get Receipt payment group' + err)
            })

        await axios.get(`${BaseURLSection}`)
            .then((response) => {
                setSectionData(response.data);
            })
    }

    const getIncExpAndBalSheetGroups = async (gType) => {
        setIncExpAndBalSheetData([]);
        await axios.get(`${getGroupHeadListByGroupTypeAndGroupHeadAndSectionId}?groupType=${gType}&sid=${authUser.deptId}`)
            .then(res => {
                setIncExpAndBalSheetData(res.data);
            }).catch(err => {
                console.log("Get Inc.,Exp. $ Bal. sheet date err " + err);
            })
    }

    const saveBtnClick = () => {
        setLoaderOption(true);
        const saveObject = {
            id: id,
            groupCode: groupCode,
            groupNameMr: glNameMarathi,
            groupNameEn: glNameEnglish,
            groupType: groupBook,
            groupHead: groupHead,
            groupHeadId: (incExpAndBalSheetObj == null) ? null : incExpAndBalSheetObj.id,
            index: index,
            isForward: isForward,
            rpGroupId: (recAndPayGroupObj == null) ? null : recAndPayGroupObj.id,
            sectionId: sectionId,
            branchId: branchId
        };
        console.log(JSON.stringify(saveObject))
        axios.post(`${AccountGroupMasterSave}`, saveObject)
            .then((response) => {
                console.log(response.data)
                if (response.data != null) {
                    getData();
                    ClearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                } else {
                    setLoaderOption(false);
                    toast.danger("Operation failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Account group err" + err)
            })
    };

    const updateBtnClick = () => {
        setLoaderOption(true);
        const updateObject = {
            id: id,
            groupCode: groupCode,
            groupNameMr: glNameMarathi,
            groupNameEn: glNameEnglish,
            groupType: groupBook,
            groupHead: groupHead,
            groupHeadId: (incExpAndBalSheetObj == null) ? null : incExpAndBalSheetObj.id,
            index: index,
            isForward: isForward,
            rpGroupId: (recAndPayGroupObj == null) ? null : recAndPayGroupObj.id,
            sectionId: sectionId,
            branchId: branchId
        };
        console.log(JSON.stringify(updateObject))
        axios.put(`${AccountGroupMasterUpdate}` + id, updateObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    ClearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.");
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });
    };

    const ClearData = () => {
        setId("");
        setGlNameMarathi("");
        setGlNameEnglish("");
        setGroupHead("");
        setGroupBook("");
        setIndex("");
        setIsForward("");
        setGroupCode("");
        setSectionId([]);
        setSectionObj([]);
        setRecAndPayGroupObj(null);
        setIncExpAndBalSheetData([]);
        setIncExpAndBalSheetObj(null);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/ac01/');
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.accountGroupSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.accountGroupUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: 'Group Code', key: 'groupCode' },
        { label: 'G.L Name Marathi', key: 'groupNameMr' },
        { label: 'G.L Name English', key: 'groupNameEn' },
        { label: 'Section', key: (authUser.branchMedium == 1) ? 'nameMr' : 'nameEn' },
        { label: 'Index', key: 'index' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '500px' }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className="row">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Code :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Group Code"
                                    variant="outlined"
                                    margin='dense'
                                    onWheel={(e) => e.target.blur()}
                                    value={groupCode}
                                    onChange={e => setGroupCode(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? groupCode : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >G.L Name :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && glNameMarathi.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="G.L Name Marathi"
                                    variant="outlined"
                                    margin='dense'
                                    value={glNameMarathi}
                                    onChange={(e) => setGlNameMarathi(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? glNameMarathi : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && glNameEnglish.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="G.L Name English"
                                    variant="outlined"
                                    margin='dense'
                                    value={glNameEnglish}
                                    onChange={(e) => setGlNameEnglish(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? glNameEnglish : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Book :</label>
                            <div className='col-sm-4'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Group Book</InputLabel>
                                    <Select
                                        size='small'
                                        fullWidth
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                        value={groupBook}
                                        onChange={(e) => {
                                            setGroupBook(e.target.value);
                                            setGroupHead('');
                                            setIncExpAndBalSheetData([]);
                                            setIncExpAndBalSheetObj(null);
                                            getIncExpAndBalSheetGroups(e.target.value);
                                            // setSectionObj([]);
                                            // setRecAndPayGroupObj(null);
                                            // setIndex('');
                                            // setIsForward(0);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? groupBook : ""}
                                        label="Group Book"
                                    >
                                        {GroupBookData.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>{item.Heading}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Head :</label>
                            <div className='col-sm-4'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Group Head</InputLabel>
                                    <Select
                                        size='small'
                                        fullWidth
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                        value={groupHead}
                                        onChange={(e) => {
                                            setGroupHead(e.target.value);
                                            setIncExpAndBalSheetData([]);
                                            setIncExpAndBalSheetObj(null);
                                            // setRecAndPayGroupObj(null);
                                            // setIndex('');
                                            // setSectionObj([]);
                                            // setIsForward(0);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? groupHead : ""}
                                        label="Group Head"
                                    >
                                        {GroupHeadData.map((item) => {
                                            if (`${groupBook}` == 1) {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>{item.Head1}</MenuItem>
                                                )
                                            }
                                            else if (`${groupBook}` == 2) {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>{item.Head2}</MenuItem>
                                                )
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Section :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    multiple
                                    filterSelectedOptions
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    options={sectionData}
                                    onChange={(e, newValue) => {
                                        //console.log(newValue.map(e=>e.id).join(','))
                                        setSectionId(newValue.map(e => e.id).join(','));
                                        setSectionObj(newValue);
                                        // setRecAndPayGroupObj(null);
                                        // setIndex('');
                                        // setIsForward(0);
                                    }}
                                    disableCloseOnSelect
                                    value={sectionObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? sectionObj : ""}
                                    style={{ fontSize: '14px' }}
                                    //getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    getOptionLabel={option => option.nameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Section" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                            required={(sectionObj.length == 0) ? true : false}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2" hidden={(groupBook == '') ? true : false}>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >{(groupBook == 1) ? 'Inc. & Exp.' : 'Bal. Sheet'}  Group :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    fullWidth
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    options={incExpAndBalSheetData}
                                    onChange={(event, newValue) => {
                                        setIncExpAndBalSheetObj(newValue)
                                        // setRecAndPayGroupObj(null);
                                        // setIndex('');
                                        // setIsForward(0);
                                    }}
                                    value={incExpAndBalSheetObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? incExpAndBalSheetObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    renderOption={(props, option) => (
                                        <div className='' {...props} key={option.id}>
                                            <div className=''>
                                                {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                                <div
                                                    className=''
                                                    style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        fontSize: "12px",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        padding: "0px 10px",
                                                        color: "#464964",
                                                        fontWeight: "550"
                                                        //gridRow: 1
                                                    }}
                                                >
                                                    <span>{(option.groupType == 1) ? ((option.groupHead == 1) ? <ReceiptIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <CurrencyRupeeIcon style={{ color: "#354AFA", fontSize: "14px" }} />) : ((option.groupHead == 1) ? <WorkIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <CreditCardIcon style={{ color: "#354AFA", fontSize: "14px" }} />)} {option.groupHeadName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label={(groupBook == 1) ? 'Select Inc. & Exp. Group' : 'Select Bal. Sheet Group'} variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}

                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Rec. & Pay. Group :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    fullWidth
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    options={recAndPayGroupData}
                                    onChange={(event, newValue) => {
                                        setRecAndPayGroupObj(newValue)
                                        // setIndex('');
                                        // setIsForward(0);
                                    }}
                                    value={recAndPayGroupObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? recAndPayGroupObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    renderOption={(props, option) => (
                                        <div className='' {...props} key={option.id}>
                                            <div className=''>
                                                {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                                <div
                                                    className=''
                                                    style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        fontSize: "12px",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        padding: "0px 10px",
                                                        color: "#464964",
                                                        fontWeight: "550"
                                                        //gridRow: 1
                                                    }}
                                                >
                                                    <span>{(option.rcHeader == 1) ? <ReceiptIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <CurrencyRupeeIcon style={{ color: "#354AFA", fontSize: "14px" }} />} {option.rcHeaderName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Receipt & Payment Group" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}

                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Index :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    type="number"
                                    size='small'
                                    id="outlined-basic"
                                    label="Index"
                                    onWheel={(e) => e.target.blur()}
                                    variant="outlined"
                                    margin='dense'
                                    value={index}
                                    onChange={(e) => setIndex(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? index : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-2 mt-2'>
                                <FormControlLabel control={<Checkbox checked={isForward} onChange={HandleForwardCheckbox} />} label="Forward" />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-12 offset-sm-2">
                                <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>                    
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

};

export default MasterFormAccountGroup;
