import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { getClassesBySectionAndBranch } from '../Services/ExamMarkAllocationAPIURL';
import { classDivisionURL } from '../../Administration/Services/CategoryReportAPIURL';
import { getBoardExamMarkEntryListAPIURL } from '../Services/ExamReportAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const BoardExamMarkAllocListReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Board Exam Mark Allocation List";

    const [loaderOption, setLoaderOption] = useState(false);

    const [optionId, setOptionId] = useState(1);
    const [classData, setClassData] = useState([]);
    const [classDivObj, setClassDivObj] = useState([]);
    const [classDivId, setClassDivId] = useState([]);

    useEffect(() => {
        axios(`${classDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassData(res.data);
            })
    }, [authUser])

    const printBoardExamMarkEntryListReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        console.log(`${getBoardExamMarkEntryListAPIURL}?langId=${authUser.branchMedium}&divId=${classDivId}&option=${optionId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${getBoardExamMarkEntryListAPIURL}?langId=${authUser.branchMedium}&divId=${classDivId}&option=${optionId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    window.open(`${getMasterReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                console.log("Board Exam Entry Report err" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    if (loaderOption == true) {
        return (
            <>
                <Loader />
            </>
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={printBoardExamMarkEntryListReport}>
                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    multiple
                                    //filterSelectedOptions
                                    disableCloseOnSelect
                                    options={classData}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, item) => {
                                        setClassDivObj(item);
                                        if (item != null) {
                                            setClassDivId(item.map(e => e.divisionId).join(','));
                                        }
                                        setOptionId(1);
                                    }}
                                    // renderOption={(props, option, { selected }) => (
                                    //     <li {...props}>
                                    //         <Checkbox
                                    //             icon={icon}
                                    //             checkedIcon={checkedIcon}
                                    //             style={{ marginRight: 8 }}
                                    //             checked={selected}
                                    //         />
                                    //         {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    //     </li>
                                    // )}
                                    value={classDivObj}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 15 } }}
                                            required={(optionId == 2 && classDivObj.length == 0) ? true : false} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={optionId}
                                        label="Select Option"
                                        onChange={(e) => {
                                            setOptionId(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={1}>Class Wise Detail</MenuItem>
                                        <MenuItem value={2}>Rank Wise</MenuItem>
                                        <MenuItem value={3}>Category Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="row my-3">
                            <div className="col-sm-12 offset-sm-2">
                                <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

export default BoardExamMarkAllocListReport
