import React, { useState } from 'react'
import TextField from '@mui/material/TextField';

const NoOfStudTextfield2 = ({ initialVal1, initialVal2, text12, onChange, onInputChange }) => {

    //console.log(initialVal1 + "======" + initialVal2)
    const [mark1, setMark1] = useState(initialVal1);
    const [mark2, setMark2] = useState(initialVal2);

    return (
        <>
            <TextField
                required={(text12 > 1 || text12 <= 3) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px" }}
                onWheel={(e) => e.target.blur()}
                value={mark1}
                onChange={(e) => setMark1(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="1st टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <TextField
                required={(text12 > 1 || text12 <= 3) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px", marginLeft: '8px' }}
                onWheel={(e) => e.target.blur()}
                value={mark2}
                onChange={(e) => setMark2(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="2nd टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />
        </>
    )
}

export default NoOfStudTextfield2