const budgetSansthaJson = [

    {
        MenuType: "normal",
        PathName: "/Home/BudgetDashboard",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        DropDownSubMenu: [
            {
                id: 1005,
                SubPath: "/Home/bm1005/",
                SubName: "Budget Categories",
            },
            {
                id: 1006,
                SubPath: "/Home/bm1006/",
                SubName: "Budget Account",
            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Transaction",
        LinkIcon: "monetization_on",
        DropDownSubMenu: [
            {
                id: 1013,
                SubPath: "/Home/bt1013/",
                SubName: "Budget Entry",
            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Report",
        LinkIcon: "assignment",
        DropDownSubMenu: [
            {
                id: 1014,
                SubPath: "/Home/br1014/",
                SubName: "Sanstha Budget Report",
            },
            {
                id: 1008,
                SubPath: "/Home/br1008/",
                SubName: "Budget Consolidated Report",
            },
            {
                id: 1009,
                SubPath: "/Home/br1009/",
                SubName: "Schoolwise Budget Report",
            },
            {
                id: 1012,
                SubPath: "/Home/br1012/",
                SubName: "Sainik School Hostel Budget",
            },
            {
                id: 1015,
                SubPath: "/Home/br1015/",
                SubName: "Sanstha Consolidated Report",
            },
            {
                id: 1016,
                SubPath: "/Home/br1016/",
                SubName: "Sanstha Conso. Report (English)",
            },
        ]
    },
    {
        MenuType: "normal",
        PathName: "/Home/b1011/",
        Title: "Budget No. & Date",
        LinkIcon: "event"
    },
]

export default budgetSansthaJson;
