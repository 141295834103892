import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import Loader from '../../../CommonComponent/Loader';
import { sansthaAwardBlankEndowmentListAPIURL } from '../Services/EndowmentReportAPIURL';

const SansthaAwardBlank = () => {
    const titleId = "Sanstha Award Blank";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [year, setYear] = useState(null);

    const endowmentListSansthaAwardBlankReport = (e) => {
        e.preventDefault();
        setLoaderOption(true);
        axios.get(`${sansthaAwardBlankEndowmentListAPIURL}?sessionYear=${moment(year).format("YYYY")}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)   
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {

                toast.error("Error Occoured, Try after sometime.");
                console.log("Sanstha Award Blank Report failed: " + error)
            })
    }

    return (
        <>
            <TitleLabel titleId={titleId} />

            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                {/* Form Data start */}
                <form onSubmit={endowmentListSansthaAwardBlankReport}>
                    <div className="row">
                        <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Year :</label>
                        <div className='col-sm-3'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Year"
                                    value={year}
                                    inputFormat="yyyy"
                                    views={['year']}
                                    onChange={(newValue) => {
                                        setYear(newValue);
                                        // (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyDown={(e) => e.preventDefault()}
                                            style={{
                                                caretColor: "transparent",
                                            }}
                                            required
                                            fullWidth
                                            autoComplete='off'
                                            size="small" />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-4 offset-sm-1">
                            <button type="submit" className='btn btn-sm btn-primary'>Show</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SansthaAwardBlank