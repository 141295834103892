import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";


// localhost:8080/institute/sss/service/deleteFormData?id=1
export const deleteFormDataAPIURL=BaseURL+"service/deleteFormData"



// localhost:8080/institute/sss/service/getAllFormData
export const getAllFormDataAPIURL=BaseURL+"service/getAllFormData"


// localhost:8080/institute/sss/service/updateFormData
export const updateFormDataAPIURL=BaseURL+"service/updateFormData/"



// localhost:8080/institute/sss/service/saveFormData
export const saveFormDataAPIURL=BaseURL+"service/saveFormData/"

// formName , moduleId, id