import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import PrintIcon from '@mui/icons-material/Print';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import masterReportJSON from '../Services/masterReportJSON'
import { printMasterReportAPIURL, getMasterReportAPIURL } from '../Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';

const MasterReport = () => {

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Master Report";
    const [data, setData] = useState(masterReportJSON);
    const [masterId, setMasterId] = useState("");
    const [masterObj, setMasterObj] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState("disabled");

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);


    useEffect(() => {
        if (masterId == "" || masterId == null) {
            setBtnDisabled("disabled")
        }
        else {
            setBtnDisabled("")
        }
    }, [masterId])


    const printMasterReport = (e) => {
        e.preventDefault();
        //  localhost:8080/institute/sss/master/getMasterReport?reportOption=21&sid=3&bid=1
        setLoaderOption(true);
        axios.get(`${printMasterReportAPIURL}?reportOption=${masterId}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Master Report failed: " + error)
            })
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={printMasterReport}>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    //disableClearable

                                    options={data}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, item) => {
                                        setMasterId(item.id)
                                        setMasterObj(item)
                                    }}
                                    value={masterObj}

                                    getOptionLabel={option => option.name}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Masters" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />

                            </div>
                        </div>

                        <div className='mt-4'>
                            <button
                                type='submit'
                                className='btn btn-primary btn-sm mb-2'
                            >
                                <PrintIcon fontSize="small" /> Print
                            </button>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>

        )
    }


}

export default MasterReport