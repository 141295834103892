import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import CalculateIcon from '@mui/icons-material/Calculate';
import AddIcon from '@mui/icons-material/Add';
import { getEndowmentDataForInterest, saveEndowmentDataForInterest } from '../Services/EndowmentTransactionAPIURL';
import InterestRateTextBox from './InterestRateEndowment/InterestRateTextBox';
import moment from 'moment';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import Loader from '../../../CommonComponent/Loader';

const EndowmentInterestCalculation = () => {
    const titleId = "Endowment Interest Calculation";
    const authUser = useSelector((state) => state.user.value);

    const TableHeading = [
        { label: 'खाते क्र', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `मूळ रक्कम`, key: 'baseAmount' },
        { label: `व्याज दर`, key: 'interestRate' },
        { label: 'येणारे व्याज', key: 'interestAmount' },
        { label: 'व्यवस्थापन', key: 'managementAmount' },
        { label: 'देय रक्कम', key: 'distributionAmount' }];

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [year, setYear] = useState(null);

    const [interestRate, setInterestRate] = useState('0');
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [interestValue, setInterestValue] = useState('');
    const [prizeValue, setPrizeValue] = useState('');


    const getEndowmnetData = async (e) => {
        e.preventDefault();
        console.log(moment(year).format("YYYY"));
        setLoaderOption(true);
        setFilteredData([]);
        setData([]);
        setSearchTerm("");
        setInterestRate('0');
        setSortedBy(null);
        setAscending(false);
        await axios.get(`${getEndowmentDataForInterest}?sessionYear=${moment(year).format("YYYY")}`)
            .then(res => {
                //console.log(res.data);
                if (res.data === "NOTFOUND") {
                    setData([]);
                    setFilteredData([]);
                    setTempData([]);
                    setLoaderOption(false);
                }
                else {
                    setData(res.data);
                    setFilteredData(res.data);
                    setTempData(res.data);
                    setLoaderOption(false);
                }

            }).catch(error => {
                console.log(error);
            })

    }

    const applyInterestRate = async () => {
        //console.log("apply=====" + interestRate)
        setLoaderOption(true);
        await axios.get(`${getEndowmentDataForInterest}?sessionYear=${moment(year).format("YYYY")}`)
            .then(res => {
                setData([]);
                setFilteredData([]);
                setTempData([]);
                let updatedData = res.data;
                (res.data).map((item, index) => {
                    updatedData[index] = ({ ...item, interestRate: interestRate, interestAmount: 0, managementAmount: 0, distributionAmount: 0 })
                })
                //console.log(JSON.stringify(updatedData));
                setData(updatedData);
                setFilteredData(updatedData);
                setTempData(updatedData);
                setLoaderOption(false);
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        let updatedData = tempData;

        tempData.map((e, index) => {
            if (e.prizeCode == prizeValue) {
                updatedData[index] = ({ ...e, interestRate: interestValue })
            }
        })
        //console.log(JSON.stringify(tempData))
        setData(tempData);
        if (searchTerm == "") {
            setFilteredData(tempData);
        }
    }, [prizeValue, searchTerm])


    const saveEnodowmentInterest = async (e) => {
        console.log(JSON.stringify(data));
        e.preventDefault();
        setLoaderOption(true);
        await axios.post(saveEndowmentDataForInterest, data)
            .then(res => {
                if (res.data == "SAVED") {
                    setFilteredData([]);
                    setData([]);
                    setYear(null);
                    setLoaderOption(false);
                    toast.success("Endowment interest allocation successfully done.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                }
            }).catch(error => {
                console.log(error);
            })
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    const getSortedData = async (list, key, isAction) => {

        await axios(`${getEndowmentDataForInterest}?sessionYear=${moment(year).format("YYYY")}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key] < b[key] ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key] > b[key] ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) =>
            item.prizeCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.prizeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.baseAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.distributionAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.interestAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.interestRate.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    useEffect(() => {
        document.addEventListener("keypress", (event) => {
            if (event.key === "Enter") {
                if (data == null || data == "" || data == []) {
                    console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.myEndowInterestBtn')
                    if (btn !== null) {
                        document.querySelector('.myEndowInterestBtn').click();
                    }
                }
            }
        });
    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    {/* Form Data start */}
                    <form onSubmit={getEndowmnetData}>
                        <div className="row">
                            <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Year :</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Year"
                                        value={year}
                                        inputFormat="yyyy"
                                        views={['year']}
                                        onChange={(newValue) => {
                                            setYear(newValue);
                                            //getEndowmnetData(newValue);
                                            setFilteredData([]);
                                            setData([]);
                                            // (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                onKeyDown={(e) => e.preventDefault()}
                                                style={{
                                                    caretColor: "transparent",
                                                }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-sm-4 mt-3">
                                <button type="submit" className='btn btn-sm btn-warning'>View</button>
                            </div>
                        </div>
                    </form>
                    {
                        (data == null || data == "" || data == [])
                            ?
                            <h4 className='mt-5'>No Records, Select Year</h4>
                            :
                            <div>
                                <div className='row mt-3 offset-sm-9 mb-2'>
                                    <div className="col-sm-8">
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            size='small'
                                            id="outlined-basic"
                                            label="व्याज दर"
                                            variant="outlined"
                                            margin='dense'
                                            value={interestRate}
                                            onChange={(e) => setInterestRate(e.target.value)}
                                            //inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <div className="col-sm-3 mt-3">
                                        <button type="button" className="btn btn-sm btn-danger" onClick={() => applyInterestRate()}>Apply</button>
                                    </div>
                                </div>

                                <div className='row col-sm-4 my-1' style={{ float: 'right' }}>
                                    <div>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>

                                <form onSubmit={saveEnodowmentInterest}>
                                    <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                        <table className="table table-bordered ">
                                            <thead className="table-Default" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData
                                                    .map((item, index) => {
                                                        return (

                                                            <tr key={index} className="table-sm">
                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "10px" }} >{item.prizeCode}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.prizeName}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.baseAmount}</td>

                                                                <InterestRateTextBox initialText={item.interestRate}
                                                                    onChange={(value) => {
                                                                        let updatedData = filteredData;
                                                                        updatedData[index] = ({ ...item, interestRate: value });
                                                                        setFilteredData(updatedData);
                                                                        setData(updatedData);
                                                                        setInterestValue(value);
                                                                        setPrizeValue(updatedData[index].prizeCode)
                                                                        //console.log(JSON.stringify(updatedData));
                                                                    }} />

                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.interestAmount}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.managementAmount}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.distributionAmount}</td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>

                                    </div>

                                    <div className='mt-4'>
                                        <button className='myEndowInterestBtn btn btn-primary btn-sm mb-2' type='submit'><CalculateIcon fontSize="small" />Calculate</button>
                                    </div>
                                </form>
                            </div>
                    }


                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default EndowmentInterestCalculation