import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import {
    getStudentList, getAwardData, getTransactionNo, getFeeDetails, printFeeReceipt, getBankData, updateStudentAwardData, getAwardDistributionById, saveAwardDistribution
} from '../Services/AwardDistributionAPIURL';
import { GetYearAPIURL } from '../../CommonServices/LoginAPIURL';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Loader from "../../CommonComponent/Loader";
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from "@mui/styles/makeStyles";
import { toDate } from 'date-fns';
import { Form } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const rankData = [
    {
        id: 1,
        nameEn: "First",
        nameMr: "प्रथम"
    },
    {
        id: 2,
        nameEn: "Second",
        nameMr: "द्वितीय"
    },
    {
        id: 3,
        nameEn: "Third",
        nameMr: "तृतीय"
    },
    {
        id: 4,
        nameEn: "Fourth",
        nameMr: "उत्तेजनार्थ"
    },
    {
        id: 5,
        nameEn: "Excellent Participation",
        nameMr: "उत्कृष्ट सहभाग"
    },
]

const AwardDistributionEntry = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    let { tId, EditFlag } = useParams();
    const navigate = useNavigate();
    const titleId = "Award Distribution";

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "feeReceiptSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "feeReceiptUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState("");
    const [tNo, setTno] = useState("");

    let todaysdate = new Date();
    const [tDate, setTdate] = useState(todaysdate);
    const [studentData, setStudentData] = useState([]);
    //console.log(studentData.length)
    const [yearData, setYearData] = useState([]);
    const [bankData, setBankData] = useState([]);
    const [yearId, setYearId] = useState([]);
    const [yearObj, setYearObj] = useState(null);
    const [studentId, setStudentId] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [division, setDivision] = useState("");
    const [classData, setclassData] = useState("");
    const [awardData, setAwardData] = useState([]);
    const [awardId, setAwardId] = useState([]);
    const [awardObj, setAwardObj] = useState(null);
    const [amount, setAmount] = useState("");
    const [cashReceipt, setCashReceipt] = useState(1);
    const [pendingAmount, setPendingAmount] = useState("");
    const [receivedAmount, setReceivedAmount] = useState("");
    const [classDataEn, setClassDataEn] = useState("");
    const [feeShortName, setFeeShortName] = useState("");
    const [description, setDescription] = useState("");
    const [showGuardianNm, setShowGuardianNm] = useState(0);
    const [guardianNm, setGuardianNm] = useState("");

    const [classOnUpdate, setClassOnUpdate] = useState('');
    const [divOnUpdate, setDivOnUpdate] = useState('');
    const [classDivUpdatedName, setClassDivUpdatedName] = useState('');

    const [rank, setRank] = useState()
    const [rankType, setRankType] = useState(null);
    const [rankTypeObj, setRankTypeObj] = useState(null);

    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);

    const showAmountError = {
        display: "block"
    }
    const hideAmountError = {
        display: "none"
    }
    const [amountCheck, setAmountCheck] = useState(hideAmountError);
    const [dateCheck, setDateCheck] = useState("");



    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
        else if (EditFlag == 1) {
            getAPIData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
    }

    const getEditDetails = () => {
        setLoaderOption(true);
        axios.all([
            axios.get(`${getAwardDistributionById}?id=${tId}`)
        ]).then(
            axios.spread((respOne) => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                // setTno(respOne.data.transactionIdString);
                setTdate(respOne.data.date);
                const selectedStudent = respOne.data.studentId;
                setclassData((authUser.branchMedium == 1) ? respOne.data.classNameMr : respOne.data.classNameEn);
                setDivision((authUser.branchMedium == 1) ? respOne.data.divisionNameMr : respOne.data.divisionNameEn);
                setClassDivUpdatedName(respOne.data.classNameEn + "" + respOne.data.divisionNameEn)
                setClassOnUpdate(respOne.data.classId);
                setDivOnUpdate(respOne.data.divisionId);
                setRank(respOne.data.ranking);
                const selectedAwardId = respOne.data.awardId;
                const selectedRankType = respOne.data.ranking;
                setRankTypeObj(rankData.find(e => e.id == selectedRankType));
                setRankType(selectedRankType);


                axios(`${getAwardData}`)
                    .then(respThree => {
                        const awardDataForReceipt = (respThree.data)
                        setAwardData(awardDataForReceipt);
                        setAwardObj(respThree.data.find(e => e.id == selectedAwardId))
                        setAwardId(selectedAwardId);
                        //setLoaderOption(false);
                    })


                axios(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}`)
                    .then(respTwo => {
                        // console.log("+++++student data++++",respTwo.data)
                        setStudentData(respTwo.data);
                        setStudentObj(respTwo.data.find(e => e.regCode == selectedStudent))
                        setStudentId(selectedStudent);
                        setLoaderOption(false);
                    })

            })
        )
    }

    const getAPIData = async () => {
        //console.log(`${GetYearAPIURL}`)
        //setLoaderOption(true);
        await axios(`${GetYearAPIURL}`)
            .then(res => {
                const year = (res.data).filter(e => e.yearLabel == authUser.yearLabel)
                setYearData(res.data);
                setYearId((year && year.length > 0) ? year[0].id : '')
                //setLoaderOption(false);
            })

        await axios(`${getAwardData}`)
            .then(res => {
                const awardDataForReceipt = (res.data)
                setAwardData(awardDataForReceipt);
                //setLoaderOption(false);
            })

        await axios(`${getBankData}`)
            .then(res => {
                setBankData(res.data);
                //setLoaderOption(false);
            })

        if (authUser.deptId != null || authUser.branchId != null) {

            if (offset === 0) {
                setLoaderOption(true)
            }
            console.log(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}&limit=${limits}&offset=${offset}`)
            await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}&limit=${limits}&offset=${offset}`)
                .then((res) => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        //console.log(tempData.length)
                        setStudentData(studentData.concat(tempData));
                        setLoaderOption(false)
                        //console.log("Data : " + JSON.stringify(data))
                        // console.log("____________________________________________")
                        if (tempData.length === limits) {
                            setOffset(offset + limits);
                            setNumCall(numCal + 1);
                            //console.log("Offset ===============" + offset);
                        }
                    }
                })
        }

        const maxDate = new Date(defaultMaxDate);
        maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
        if (tDate < defaultMinDate || tDate >= maxDate) {
            setDateCheck("Invalid date");
        } else {
            setDateCheck("");
        }
    }

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const setTransactionNo = async (feeSn, FId) => {
        //console.log(`${getTransactionNo}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${studentId}&feesn=${feeSn}&cdnm=${classDataEn}`)
        await axios(`${getTransactionNo}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${studentId}&feesn=${feeSn}&cdnm=${classDataEn}`)
            .then(res => {
                setTno(res.data);
            })

        await axios(`${getFeeDetails}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${studentId}&fid=${FId}`)
            .then(res => {
                // console.log(res.data.feeAmount)
                setAmount(res.data.feeAmount);
                setPendingAmount(res.data.pendingAmount);
            })
    }

    const saveFeeCollection = async () => {
        setLoaderOption(true);
        if (moment(tDate).format("DD/MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            const saveObject = {
                date: moment(tDate).format("DD/MM/YYYY"),
                awardId: awardId,
                rank: rank,
                studentId: studentId,
                classDivName: classDataEn,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            }
            // console.log("=======================",JSON.stringify(saveObject))
            await axios.post(`${saveAwardDistribution}`, saveObject)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setLoaderOption(false);
                        toast.success("Award Saved Sucessfully.");

                    } else {
                        setLoaderOption(false);
                        toast.error("Operation failed.");
                    }
                })
                .catch(error => {
                    console.log("Save Fire Query: " + error)
                })
            setTno("");
            setStudentObj(null);
            setRank("");
            setClassDataEn("");
            setAwardObj(null);
            setYearObj(null);
            //setYearId([]);
            setclassData("");
            setDivision("");
            setAmount("");
            setPendingAmount("");
            setFeeShortName("");
            // setReceivedAmount("");
            setCashReceipt(1);
            setDescription("");
            //toast.success("valid Date")
            navigate("/Home/acd411/0")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const updateFeeCollection = async () => {
        setLoaderOption(true);
        if (moment(tDate).format("DD/MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date" && studentObj.currentClassId == classOnUpdate && studentObj.currentDivisionId == divOnUpdate) {
            const updateObject = {
                id: tId,
                awardId: awardId,
                rank: rank,
                // classDivName: classDataEn,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
                studentId: studentId,
                date: moment(tDate).format("DD/MM/YYYY"),
            };

            await axios.put(`${updateStudentAwardData}`, updateObject)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setLoaderOption(false);
                        setSaveBtn(saveBtnStyle);
                        setUpdateBtn(displayNoneBtnStyle);
                        navigate("/Home/acd411/0")
                    }
                });
        }
        else {
            if (moment(tDate).format("DD/MM/YYYY") == "Invalid date" || dateCheck == "Invalid date") {
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
            else {
                setLoaderOption(false);
                toast.warn(`You can't update student out of  ${classDivUpdatedName}  class.`)
            }
        }

    };

    const ClearData = () => {
        navigate("/Home/acd411/0")
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveFeeCollection();
        }
        if (state.button === 2) {
            updateFeeCollection();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                //console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    console.log('save Enter is pressed!');
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                    let btn1 = document.querySelector('.feeReceiptSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                }
                if (EditFlag == 2) {
                    console.log('update Enter is pressed!');
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                    let btn2 = document.querySelector('.feeReceiptUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "480px" }}>
                    <form onSubmit={onSubmit}>

                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className="row">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={tDate}
                                        minDate={defaultMinDate}
                                        maxDate={defaultMaxDate}
                                        onChange={(newValue) => {
                                            setTdate(newValue);
                                            // (moment(newValue).format("DD/MM/YYYY") == "Invalid date") ? setDateCheck("Invalid Date") : setDateCheck("")
                                            //console.log(newValue)
                                            //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck("")
                                            const maxDate = new Date(defaultMaxDate);
                                            maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                            if (newValue < defaultMinDate || newValue >= maxDate) {
                                                setDateCheck("Invalid date");
                                            } else {
                                                setDateCheck("");
                                            }
                                        }}
                                        openTo="day"
                                        // Show defaultMaxDate selected when the DatePicker is opened
                                        // If dateFrom is not set, defaultMaxDate is used
                                        onOpen={() => setTdate(tDate || defaultMaxDate)}
                                        onKeyPress={(e) => (e.key == "Enter") ? tDate : ""}
                                        // readOnly={(EditFlag == 2 && authUser.roleId !== 3) ? true : false}
                                        inputFormat="dd/MM/yyyy"
                                        // inputProps={{ readOnly: (EditFlag == 2 && authUser.roleId !== 3) ? true : false }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>


                        </div>

                        <div className="row mt-2">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Student:</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    loading={loading}
                                    options={studentData}
                                    readOnly={(EditFlag == 2 && authUser.roleId !== 3) ? true : false}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setStudentObj(newValue);
                                        setclassData('');
                                        setDivision('');
                                        if (newValue != null) {
                                            setclassData((authUser.branchMedium == 1) ? newValue.currentClassMr : newValue.currentClassEn);
                                            setDivision((authUser.branchMedium == 1) ? newValue.currentDivisionMr : newValue.currentDivisionEn);
                                            setClassDataEn(newValue.currentClassEn + newValue.currentDivisionEn);
                                            setStudentId(newValue.regCode);
                                        }
                                        if (EditFlag == 1) {
                                            setAwardObj(null);
                                            setPendingAmount("");
                                            setAmount("");
                                            setTno("");
                                            // setReceivedAmount('');
                                            setShowGuardianNm(0);
                                            setCashReceipt(1);
                                            setDescription('');
                                            setGuardianNm('');
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                    value={studentObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                    renderOption={(props, option) => (
                                        <div className='' {...props}>
                                            <div className=''>
                                                {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                            </div>

                                        </div>
                                    )}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                            required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Class"
                                    variant="outlined"
                                    margin='dense'
                                    value={classData}
                                    onChange={(e) => setclassData(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? classData : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Division"
                                    variant="outlined"
                                    margin='dense'
                                    value={division}
                                    onChange={(e) => setDivision(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? division : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>


                        <div className="row mt-2">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Award:</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={awardData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {

                                        setAwardObj(newValue);
                                        if (newValue != null) {
                                            setAwardId(newValue.id);
                                        }
                                        else if (newValue == null) {
                                            setAwardId('')
                                            setAmount('');
                                            setPendingAmount('');
                                        }

                                    }}
                                    value={awardObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? awardObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Award" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                        </div>

                        {/* <div className="row mt-2">
                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Rank :</label>
                            <div className='col-sm-4'>
                                <FormControl fullWidth margin='dense'>
                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Rank</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={rank}
                                        label="Select Rank"
                                        onChange={(e) => {
                                            setRank(e.target.value);
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px", }} value={1}>First</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px", }} value={2}>Second</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px", }} value={3}>Third</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div> */}

                        <div className="row my-2">
                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Rank :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={rankData}
                                    onChange={(event, newValue) => {
                                        setRankTypeObj(newValue);
                                        if (newValue != null) {
                                            setRank(newValue.id);
                                        }
                                    }}
                                    value={rankTypeObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? rankTypeObj : ""}
                                    style={{ fontSize: '14px' }}
                                    // getOptionLabel={option => option.rank}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Rank" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>


                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn}>Save</button>
                                <button type="submit" onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }


}

export default AwardDistributionEntry