import React from 'react'
import underConstruction from './underConstruction.jpg';

const PageNotFound = () => {
    return (
        <>
            <div className='col-sm-6 offset-sm-3'>
                <img src={underConstruction} width="100%" />
            </div>
        </>
    )
}

export default PageNotFound