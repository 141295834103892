import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { getTypeMasteAPIURL, saveTypeMasteAPIURL, updateTypeMasteAPIURL } from '../Services/TypeMasteAPIURL';
import TypeMasterTable from './TypeMasterTable';

const TypeMaster = () => {
    const titleId = "Type Master";

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "typeMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "typeMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);


    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [id, setId] = useState();
    const [nameMr, setNameMr] = useState("");
    const [nameEn, setNameEn] = useState("");

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${getTypeMasteAPIURL}`)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
            })
    }

    const saveBtnClick = () => {
        setLoaderOption(true);
        const saveObject = {
            id: id,
            nameMr: nameMr,
            nameEn: nameEn,

        };
        axios.post(`${saveTypeMasteAPIURL}`, saveObject)
            .then((response) => {
                if (response.data == "SAVED") {
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved sucessfully.");
                }
                else if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Author with this name is already exists.`)
                }
                else {
                    toast.error("Operation failed.");
                }
            });

        setNameMr("");
        setNameEn("");

        setId("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const updateBtnClick = () => {
        setLoaderOption(true);
        const updateObject = {
            id: id,
            nameMr: nameMr,
            nameEn: nameEn,
          
        };
        axios.put(`${updateTypeMasteAPIURL}`, updateObject)
            .then((response) => {
                if (response.data == "UPDATED") {
                    getData();
                    setLoaderOption(false);
                    toast.success("Update sucessfully.");
                }
                else if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Author with this name is already exists.`)
                }
            });
        setNameMr("");
        setNameEn("");

        setId("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const ClearData = () => {
        setNameMr("");
        setNameEn("");

        setId("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const handleKeyDownMr = (e) => {
        if (e.key === " " && nameMr.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownEn = (e) => {
        if (e.key === " " && nameEn.length === 0) {
            e.preventDefault();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.typeMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.tyepMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    const TableHeading = [{ label: 'ID', key: 'id' },
    { label: `Type Name Mr`, key: 'nameMr' },
    { label: `Type Name En`, key: 'nameEn' },   
    { label: 'Edit', value: 'edit', isAction: true },
    { label: 'Delete', value: 'delete', isAction: true }];

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >{titleId} Name:</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownMr}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Type Name Marathi"
                                    variant="outlined"
                                    margin='dense'
                                    value={nameMr}
                                    onChange={e => setNameMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? nameMr : ""}
                                    inputProps={{ maxLength: 120 }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownEn}
                                    autoComplete='off'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    size='small'
                                    id="outlined-basic"
                                    label="Type Name English"
                                    variant="outlined"
                                    margin='dense'
                                    value={nameEn}
                                    onChange={e => setNameEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? nameEn : ""}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>



                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

                            </div>
                        </div>
                    </form>
                    <div>
                        <TypeMasterTable
                            TableHeading={TableHeading}
                            setId={setId}
                            setNameEn={setNameEn}
                            setNameMr={setNameMr}                           
                            getData={getData}
                            data={data}
                            setFilteredData={setFilteredData}
                            filteredData={filteredData}
                            setData={setData}
                            saveBtnStyle={saveBtnStyle}
                            updateBtnStyle={updateBtnStyle}
                            displayNoneBtnStyle={displayNoneBtnStyle}
                            setSaveBtn={setSaveBtn}
                            setUpdateBtn={setUpdateBtn}
                        />
                    </div>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}


            </>
        )
    }

};


export default TypeMaster
