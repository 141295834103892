import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel';
import makeStyles from "@mui/styles/makeStyles";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { EmployeeMasterBranchWiseGetData } from '../../CommonServices/EmployeeMasterAPIURL';
import { printEmpLeaveEntryReportAPIURL } from '../Services/PayrollReportsAPIURL';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const EmployeeLeaveEntryReport = () => {
    const titleId = "Employee Leave Entry Report";
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    const [employeeData, setEmployeeData] = useState([]);
    const [employeeObj, setEmployeeObj] = useState(null);
    const [option, setOption] = useState(1);

    useEffect(() => {
        getData();
    }, [authUser]);


    const getData = async () => {
        //console.log(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`);
        if (authUser.branchId != null) {
            await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`)
                .then((response) => {
                    setEmployeeData(response.data);
                })
        }
    }

    const getEmpLeaveEntryReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        await axios.get(`${printEmpLeaveEntryReportAPIURL}?langId=${authUser.branchMedium}&option=${option}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&year=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                console.log('print emp leave entry' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            <form onSubmit={getEmpLeaveEntryReport}>
                                <div className="row my-2">
                                    <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                            <Select
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={option}
                                                label="Select Option"
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setEmployeeObj(null);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                            >
                                                <MenuItem value={1}>All Employees</MenuItem>
                                                <MenuItem value={2}>Employee Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {(() => {
                                    if (option == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Employee :</label>
                                                    <div className='col-sm-4'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={employeeData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, newValue) => {
                                                                setEmployeeObj(newValue);
                                                            }}
                                                            value={employeeObj}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                                                            renderOption={(props, option) => (
                                                                <div className='' {...props}>
                                                                    <div className=''>
                                                                        {option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="row mt-3">
                                    <div className="col-sm-4" style={{ marginLeft: "140px" }}>
                                        <button type='submit' className='btn btn-primary btn-sm'>Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default EmployeeLeaveEntryReport