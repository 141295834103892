import {  BaseURL, BaseURLMaster, BaseURLAcMaster } from "./APIURL";

//Account Master API Link
export const BaseURLAccountMaster = BaseURLAcMaster + "accmaster/accountMasterList";
// export const BaseURLAccountMaster = "http://192.168.1.102:8080/institute/sss/acmaster/accmaster/accountMasterList";
export const AccountMasterSave = BaseURLAcMaster + "accmaster/saveAccountMaster/";
//export const AccountMasterSave = "http://192.168.1.102:8080/institute/sss/acmaster/accmaster/saveAccountMaster";
export const AccountMasterUpdate = BaseURLAcMaster + "accmaster/updateAccountMaster";
// export const AccountMasterUpdate = "http://192.168.1.102:8080/institute/sss/acmaster/accmaster/updateAccountMaster";
export const AccountMasterDelete = BaseURLAcMaster + "accmaster/deleteAccountMaster/";
export const AccountCodeGetMapping = BaseURLAcMaster + "accmaster/getAccountCode/";


// /institute/sss/acmaster/accmaster/


// GetMapping   /accountMasterList

// @RequestParam("sid")  long sectionId,@RequestParam("acyr") long acYear,@RequestParam("bid") long branchId