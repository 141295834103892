import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './vendors/horizontal-layout/style.css';
import Main from './BaseComponent/Login/Main';
import PageNotFound from './CommonComponent/PageNotFound';
import { Route, Routes } from "react-router-dom";
import Home from './Home';
import ServiceIndex from './ServicePanel/Modal/ServiceIndex';
import ServiceMaster from './ServicePanel/Modal/ServiceMaster'
import AluminiRegistration from './AluminiRegistration/AluminiRegistration';
import PrivacyPolicy from './BaseComponent/Login/PrivacyPolicy';

function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/Home/*" element={<Home />} />
        <Route exact path="/service/" element={<ServiceIndex />} />
        <Route exact path="/service/s001/*" element={<ServiceMaster />} />
        <Route exact path="/alumini/" element={<AluminiRegistration />} />
      </Routes>
    </>
  );
}

export default App;
