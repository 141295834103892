import React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';

const DuplicateLcModal = ({ oldLcNo, setOldLcNo, academicYear, setAcademicYear, dupLcDate, setDupLcDate,
    languageId, setLanguageId, HandleWaterMarkCheckbox, waterMark, setWaterMark, savePrintDuplicateLc, loaderOption }) => {


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div className="modal fade" id="duplicateLcModal" tabIndex="-1" aria-labelledby="duplicateLcModalLabel" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: "#E5E7E9" }}>
                                <h4 className="modal-title" id="duplicateLcModalLabel">Duplicate LC</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={savePrintDuplicateLc}>
                                <div className="modal-body">
                                    {/* <div className="row">
                                <label className="col-sm-6" style={{ fontSize: "14px", fontWeight: "600" }}>Language:</label>
                            </div> */}
                                    <div className="row my-1">
                                        <label className="col-sm-4" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Language :</label>
                                        <div className="col-sm-8">
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                                <Select
                                                    size='small'
                                                    required
                                                    fullWidth
                                                    margin='dense'
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={languageId}
                                                    label="Select Language"
                                                    onChange={(e) => {
                                                        setLanguageId(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Marathi</MenuItem>
                                                    <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>English</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-1">
                                        <label className="col-sm-4" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Old LC No :</label>
                                        <div className="col-sm-8">
                                            <TextField
                                                required
                                                type="number"
                                                onKeyDown={(e) => (e.key === " " && oldLcNo.length === 0) ? e.preventDefault() : ""}
                                                fullWidth
                                                onWheel={(e) => e.target.blur()}
                                                value={oldLcNo}
                                                onChange={(e) => setOldLcNo(e.target.value)}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Old LC No"
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="row mt-3">
                                <label className="col-sm-6" style={{ fontSize: "14px", fontWeight: "600" }}>Academic Year:</label>
                            </div> */}
                                    <div className="row my-1">
                                        <label className="col-sm-4" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Academic Year :</label>
                                        <div className="col-sm-8">
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                onKeyDown={(e) => (e.key === " " && academicYear.length === 0) ? e.preventDefault() : ""}
                                                onWheel={(e) => e.target.blur()}
                                                value={academicYear}
                                                onChange={(e) => setAcademicYear(e.target.value)}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Academic Year"
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="row mt-3">
                                <label className="col-sm-8" style={{ fontSize: "14px", fontWeight: "600" }}>Duplicate LC Date:</label>
                            </div> */}
                                    <div className="row my-1">
                                        <label className="col-sm-4" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Duplicate LC Date :</label>
                                        <div className='col-sm-8'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date"
                                                    value={dupLcDate}
                                                    onChange={(newValue) => {
                                                        setDupLcDate(newValue);
                                                    }}
                                                    inputFormat="dd/MM/yyyy"
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin='dense'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className="col-sm-3 mx-1">
                                            <FormControlLabel control={<Checkbox checked={waterMark} onChange={HandleWaterMarkCheckbox} />} label="Watermark" />
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary btn-sm">Save & Print</button>
                                    <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={() => { setOldLcNo(''); setDupLcDate(null); setAcademicYear('') }}>Cancle</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>

    )
}

export default DuplicateLcModal