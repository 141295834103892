import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { BaseUrlLibraryMaster } from '../../CommonServices/APIURL';

const AddNewDepartmentModal = ({ showDepartmentModal, setShowDepartmentModal,getApiData }) => {
    const authUser = useSelector((state) => state.user.value);
    const handleClose = () => setShowDepartmentModal(false);
    const handleShow = () => setShowDepartmentModal(true);

    const [name, setName] = useState("");

    const saveBtnClick = async (e) => {
        handleClose();
        //setLoaderOption(true);
        e.preventDefault();
        const saveObj = {
            "name": name,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(`${BaseUrlLibraryMaster}saveDepartmentMaster`, saveObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    //setLoaderOption(false);
                    toast.warn(`This department is already exists.`)
                }
                else if (response.data == "SAVED") {
                    setName("");                    
                    getApiData();
                    //setLoaderOption(false);
                    toast.success("Saved successfully.")
                }

            }).catch(error => {
                //setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }



    return (
        <>
            <Modal show={showDepartmentModal} onHide={handleClose} size='sm'>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "20px" }}>Add New Department</Modal.Title>
                </Modal.Header>
                <Form onSubmit={saveBtnClick}>
                    <Modal.Body>
                        <TextField
                            required
                            fullWidth
                            onKeyDown={(e) => (e.key === " " && name.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            className=''
                            size='small'
                            id="outlined-basic"
                            label="Department Name"
                            variant="outlined"
                            margin='dense'
                            //style={{ width: "350px" }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onKeyPress={(e) => (e.key == "Enter") ? name : ""}
                            inputProps={{ maxLength: 120 }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className="departmentModalBtn">
                            Save
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default AddNewDepartmentModal