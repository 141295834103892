import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

// @PostMapping

// localhost:8080/institute/sss/admissionFormSale/saveAdmissionFormSaleData
//export const saveAdmissionFormSaleDataAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormSale/saveAdmissionFormSaleData";
export const saveAdmissionFormSaleDataAPIURL = BaseURL + "admissionFormSale/saveAdmissionFormSaleData";

//localhost:8080/institute/sss/admissionFormSale/getAdmissionFormSaleList?acYear=2021&sid=3&bid=1

//export const getAdmissionFormSaleListAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormSale/getAdmissionFormSaleList"
export const getAdmissionFormSaleListAPIURL = BaseURL + "admissionFormSale/getAdmissionFormSaleList"

// @PutMapping 

// localhost:8080/institute/sss/admissionFormSale/updateAdmissionFormSaleData

//export const updateAdmissionFormSaleDataAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormSale/updateAdmissionFormSaleData";
export const updateAdmissionFormSaleDataAPIURL = BaseURL + "admissionFormSale/updateAdmissionFormSaleData";


//localhost:8080/institute/sss/admissionFormSale/deleteAdmissionFormSaleData?id=3

//export const deleteAdmissionFormSaleDataAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormSale/deleteAdmissionFormSaleData"
export const deleteAdmissionFormSaleDataAPIURL = BaseURL + "admissionFormSale/deleteAdmissionFormSaleData"