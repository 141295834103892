import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/inventoryStore/transaction/getOpeningItemList?year=2022&sid=3&bid=1
export const getOpeningItemAPIURL = BaseURL + "inventoryStore/transaction/getOpeningItemList";

//localhost:8080/institute/sss/inventoryStore/transaction/saveOpeningItem
// {
//     "itemId": 1,
//     "unit": "2 kg",
//     "quantity": 2,
//     "price": 100.00,
//     "totalAmount": 100.00,
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveOpeningItemAPIURL = BaseURL + "inventoryStore/transaction/saveOpeningItem";

//localhost:8080/institute/sss/inventoryStore/transaction/updateOpeningItem
export const updateOpeningItemAPIURL = BaseURL + "inventoryStore/transaction/updateOpeningItem";

//localhost:8080/institute/sss/inventoryStore/transaction/deleteOpeningItem?id=1
export const deleteOpeningItemAPIURL = BaseURL + "inventoryStore/transaction/deleteOpeningItem";