import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";


export const generateClassTeacherAllocationReport=BaseURL+"academic/reports/generateClassTeacherAllocationReport";

// class teacher alloc
// localhost:8080/institute/sss/academic/reports/generateClassTeacherAllocationReport?langId=1&acYear=2021&sid=3&bid=1

export const generateClassAndDivisionWiseClassTeacherAllocationReport=BaseURL+"academic/reports/generateClassAndDivisionWiseClassTeacherAllocationReport";

// localhost:8080/institute/sss/academic/reports/generateClassAndDivisionWiseClassTeacherAllocationReport?langId=1&acYear=2021&sid=3&bid=1&classId=10&divId=41

export const generateEmployeeWiseClassTeacherAllocationReport=BaseURL+"academic/reports/generateEmployeeWiseClassTeacherAllocationReport";
// localhost:8080/institute/sss/academic/reports/generateEmployeeWiseClassTeacherAllocationReport?langId=1&acYear=2021&sid=3&bid=1&empId=50


//http://192.168.1.102:8080/institute/sss/