import React, { useState, useEffect } from 'react';
import TitleLabel from '../../CommonComponent/TitleLabel';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { getClassDivisionURL } from '../../Administration/Services/PramotShiftStudentsAPIURL';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { BaseURLMasterExams } from '../../CommonServices/ExamMasterAPIURL';

const allowanceData = [
    {
        id: 1,
        regCode: 10001,
        studentNameMr: "aaa",
        rollNo: 1,
        subCaste: "Maratha",
        category: "Open",
        transport: 0,
        uniform: 0,
        Attendance: 0
    },
    {
        id: 2,
        regCode: 10002,
        studentNameMr: "bbb",
        rollNo: 2,
        subCaste: "Maratha",
        category: "Open",
        transport: 0,
        uniform: 0,
        Attendance: 0
    },
    {
        id: 3,
        regCode: 10003,
        studentNameMr: "ccc",
        rollNo: 3,
        subCaste: "Maratha",
        category: "Open",
        transport: 0,
        uniform: 0,
        Attendance: 0
    },
    {
        id: 4,
        regCode: 10004,
        studentNameMr: "ddd",
        rollNo: 4,
        subCaste: "Maratha",
        category: "Open",
        transport: 0,
        uniform: 0,
        Attendance: 0
    }
]

const StudentRTEResult = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student RTE Result"

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [examData, setExamData] = useState([]);
    const [examObj, setExamObj] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [studRTEData, setStudRTEData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                })
        }

        await axios(`${BaseURLMasterExams}`)
            .then(res => {
                setExamData(res.data);
            })
    }

    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                <form >
                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row">
                        {/* <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class-Division:</label> */}
                        <div className='col-sm-3'>
                            <Autocomplete
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                id="combo-box-demo"
                                onChange={(e, newValue) => {
                                    setClassDivObj(newValue);
                                }}
                                value={classDivObj}
                                options={classDivData}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Class-Division" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <div className='col-sm-3'>
                            <Autocomplete
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                id="combo-box-demo"
                                onChange={(e, newValue) => {
                                    setExamObj(newValue);
                                }}
                                value={examObj}
                                options={examData}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Exam" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <div className="col-sm-4 mt-3">
                            <button type="button" className='btn btn-sm btn-warning'>View</button>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className='row col-sm-4 mb-1 mt-4' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>
                            <div className='table-responsive mt-4'>
                                <table className="table table-bordered">
                                    <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: "600" }}>Reg No</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600" }}>Student Name</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600" }}>Roll No</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", }}>RTE Result</th>


                                        </tr>
                                    </thead>
                                    <tbody className='studentChkBox' style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {filteredData.map((item, index) => {
                                            return (
                                                <tr key={index} className="table-sm">
                                                    <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.regCode}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.studentNameMr}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.rollNo}</td>


                                                    <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px" }}>
                                                        <Checkbox defaultChecked={(item.isAllocated === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}

                                                            onChange={(e) => {
                                                                let val = e.target.checked;
                                                                //handleChkBox1(e);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12">
                                    <button type="button" className="btn btn-sm btn-primary"><AddIcon fontSize="small" />Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default StudentRTEResult