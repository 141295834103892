import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ClipboardIcon from '@mui/icons-material/Assignment';
import { deleteStudentLeaveAPIURL } from '../Services/StudentLeaveAPIURL';
import moment from 'moment';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const StudentLeaveTable = ({ filteredData, studentData, setStudentObj, setDateFrom, setDateTo, setOtherLeave, leaveData, setLeaveObj, setSanctionChkBox,
    setFilteredData, TableHeading, setId, data, getData, updateBtnStyle, displayNoneBtnStyle, setSaveBtn, setUpdateBtn,
    clearData, setleaveId, setReadOnlyField }) => {

    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [loaderOption, setLoaderOption] = useState(false);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const UpdateData = (item) => {
        setReadOnlyField(true);
        //console.log(moment(item.dateFrom).format('YYYY-MM-DD') + "=======" + moment(item.dateTo).format('YYYY-MM-DD'))
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        setId(item.id);
        setStudentObj(studentData.find(e => e.regCode == item.regNo));
        const convertedFromDate = moment(item.dateFrom, "DD/MM/YYYY").format("YYYY-MM-DD");
        setDateFrom(convertedFromDate);
        const convertedToDate = moment(item.dateTo, "DD/MM/YYYY").format("YYYY-MM-DD");
        setDateTo(convertedToDate);
        setLeaveObj(leaveData.find(e => e.id == item.leaveId))
        setleaveId(item.leaveId)
        setOtherLeave((item.leaveId == 0) ? item.leaveReason : '')
        setSanctionChkBox(item.isSanction)
    }

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = async (id) => {
        await axios.delete(`${deleteStudentLeaveAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data == "DELETED") {
                    getData();
                    clearData();
                    toast.success("Deleted sucessfully");
                }
            });
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.leaveReason.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.dateFrom.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.dateTo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <div className='row mt-1 my-2'>
                            <div >
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                />
                            </div>
                        </div>

                        <div className='table-responsive'>
                            <table className="table table-bordered">
                                <thead className="table-Default">
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                    {
                                        filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .reverse().map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.id}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>{item.regNo + " - " + ((authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn)}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.dateFrom}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.dateTo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.leaveReason}</td>
                                                            <td style={{ fontSize: "15px", textAlign: 'center' }}>
                                                                {(() => {
                                                                    if (item.isReported == 1) {
                                                                        return (
                                                                            <Tooltip title="Reported">
                                                                                <ClipboardIcon  style={{ color: "#016FA2", cursor: "pointer" }} />
                                                                            </Tooltip>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return ""
                                                                    }
                                                                })()}
                                                            </td>
                                                            <td style={{ width: "100px", textAlign: 'center' }}>
                                                                {
                                                                    (item.isSanction == 1)
                                                                        ?
                                                                        <Tooltip title="Sanctioned">
                                                                            <ThumbUpAltIcon style={{ color: "#2C9704", cursor: "pointer" }} />
                                                                        </Tooltip>
                                                                        :
                                                                        <Tooltip title="Not Sanctioned">
                                                                            <ThumbDownAltIcon style={{ color: "#FF5F52", cursor: "pointer" }} />
                                                                        </Tooltip>
                                                                }
                                                            </td>
                                                            <td style={{ width: "100px", textAlign: 'center' }}>
                                                                <Tooltip title="Edit">
                                                                    <button className='btn btn-primary btn-sm mx-1 p-1'
                                                                        onClick={() => UpdateData(item)} data-bs-toggle="tooltip"
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </button>
                                                                </Tooltip>
                                                            </td>
                                                            <td style={{ width: "100px", textAlign: 'center' }}>
                                                                <Tooltip title="Delete">
                                                                    <button className='btn btn-danger btn-sm mx-1 p-1'
                                                                        data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                        onClick={() => setDeleteId(item.id)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </button>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>
            }
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default StudentLeaveTable