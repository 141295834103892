import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

// /localhost:8080/institute/sss/payroll/master/getLeaveMasterList
export const getLeaveMasterAPIURL = BaseURL + "payroll/master/getLeaveMasterList";

// localhost:8080/institute/sss/payroll/master/saveLeaveMaster
// {
//     "leaveName": "leaveName"
// }
export const saveLeaveMasterAPIURL = BaseURL + "payroll/master/saveLeaveMaster";

//localhost:8080/institute/sss/payroll/master/updateLeaveMaster
// {
//     "id": 1,
//     "leaveName": "leaveName new"
// }
export const updateLeaveMasterAPIURL = BaseURL + "payroll/master/updateLeaveMaster";

// /localhost:8080/institute/sss/payroll/master/deleteLeaveMaster?id=1
export const deleteLeaveMasterAPIURL = BaseURL + "payroll/master/deleteLeaveMaster";