import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import BudgetDetailedEntryInput from "./BudgetEntryTextBoxes/BudgetDetailedEntryInput";
import { saveBudgetEntryAPIURL } from "./Services/BudgetEntryAPIURL";

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const BudgetDetailEntryTable = ({ TableHeading1, TableHeading2, data, setData, filteredData, setFilteredData,
    departmentObj, setDepartmentObj, setOptionId, setBudgetCategoryObj, previousYrTotalBudget,
    nextYrTotalBudget, actualDecTotalBudget, actualMarchTotalBudget, setPreviousYrTotalBudget,
    setNextYrTotalBudget, setActualDecTotalBudget, setActualMarchTotalBudget, totalActualBudget, setTotalActualBudget }) => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);


    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const saveBudgetEntryDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        let updatedData = data
        updatedData.map((item, index) => {
            updatedData[index] = ({
                "categoryId": item.categoryId,
                "actualBudget1": item.actualBudget1,
                "actualBudget2": item.actualBudget2,
                "nextYearBudget": item.budgetNextYear,
                "srNo": item.srNo,
                "sessionYear": authUser.sessionYear,
                "sectionId": departmentObj.deptId,
                "branchId": authUser.branchId,
                "sessionSectionId": authUser.deptId
            })
        })
        console.log(JSON.stringify(updatedData))
        await axios.post(saveBudgetEntryAPIURL, updatedData)
            .then(res => {
                if (res.data == "SAVED") {
                    setData([]);
                    setFilteredData([]);
                    setDepartmentObj(null)
                    setOptionId('')
                    setBudgetCategoryObj(null)
                    setLoaderOption(false);
                    toast.success("Budget entry updated successfully.");
                }
            }).catch(err => {
                console.log("Budget entry save err:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            data.filter((item) =>
                item.accNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.accNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.budgetLastYear.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.budgetNextYear.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.budget1Total.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.budget2Total.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData)
        //setFilteredData(data.filter((item) => item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.categoryNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.genderLabel.toLowerCase().includes(searchTerm.toLowerCase()) || item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) || item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    }, [searchTerm])

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                event.preventDefault();
                let btn = document.querySelector('.budgetEntryDetailTableBtn')
                if (btn !== null) {
                    btn.click();
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div className="">
                        <div className='row mb-2' style={{ float: 'right' }}>
                            <div>
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                    value={searchTerm}
                                />
                            </div>
                        </div>
                        <form onSubmit={saveBudgetEntryDetails}>
                            <div className='table-responsive mb-3' style={{ maxHeight: "500px" }}>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            {TableHeading1.map((item, index) => {
                                                return (
                                                    <th rowspan={item.rowspan} colspan={item.colspan} style={{ fontSize: "15px", fontWeight: "600", textAlign: "center" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            {TableHeading2.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            filteredData.sort((a, b) => (a.srNo > b.srNo) ? 1 : -1).map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "80px" }}>{item.srNo}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.accNameMr}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "120px", textAlign: "right" }}>{item.budgetLastYear}</td>
                                                            <BudgetDetailedEntryInput initialText1={item.actualBudget1} initialText2={item.actualBudget2} initialText3={item.budgetNextYear}
                                                                initialText4={parseFloat(item.actualBudget1) + parseFloat(item.actualBudget2)}
                                                                onChange={(value1, value2, value3, value4) => {
                                                                    let updatedData = filteredData
                                                                    updatedData[index] = ({
                                                                        ...item,
                                                                        actualBudget1: value1,
                                                                        actualBudget2: value2,
                                                                        budgetNextYear: value3,
                                                                        totalBudgetValue: value4
                                                                    })

                                                                    console.log(updatedData);

                                                                    const TotalDecActualBudget = updatedData.reduce((prev, curr) => prev + parseFloat(curr.actualBudget1), 0);
                                                                    setActualDecTotalBudget(parseFloat(TotalDecActualBudget))
                                                                    const TotalMarchActualBudget = updatedData.reduce((prev, curr) => prev + parseFloat(curr.actualBudget2), 0);
                                                                    setActualMarchTotalBudget(parseFloat(TotalMarchActualBudget))
                                                                    const TotalNextYrBudget = updatedData.reduce((prev, curr) => prev + parseFloat(curr.budgetNextYear), 0);
                                                                    setNextYrTotalBudget(parseFloat(TotalNextYrBudget))
                                                                    const TotalActualBudget = updatedData.reduce((prev, curr) => prev + parseFloat(curr.totalBudgetValue), 0);
                                                                    //console.log(TotalActualBudget)
                                                                    setTotalActualBudget(parseFloat(TotalActualBudget));

                                                                    setData(updatedData)
                                                                }}
                                                            />
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "600" }}></td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>एकूण</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(previousYrTotalBudget).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualDecTotalBudget).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualMarchTotalBudget).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(totalActualBudget).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(nextYrTotalBudget).toFixed('2')}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div className='mt-2'>
                                <button className='budgetEntryDetailTableBtn btn btn-primary btn-sm mb-2' type="submit"><AddIcon fontSize="small" />Save Changes</button>
                            </div>
                        </form>
                    </div>
            }
        </>

    )
}

export default BudgetDetailEntryTable