import { TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import ItemCategoryMasterTable from './ItemCategoryMasterTable';
import { saveItemCategoryMasterAPIURL, updateItemCategoryMasterAPIURL, getItemCategoryMasterAPIURL } from '../Services/ItemCategoryMasterAPIURL';



const ItemCategoryMaster = () => {
    const titleId = 'Item Category Master';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Item Category Name`, key: 'categoryName' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }
    ];

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "itemCategoryMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "itemCategoryMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState("");
    const [itemCategoryName, setItemCategoryName] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            //console.log(`${getItemCategoryMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getItemCategoryMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Item category master get err " + err);
                })
        }
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setItemCategoryName('');
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "categoryName": itemCategoryName,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.post(saveItemCategoryMasterAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("This item category is already exists.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Item Category save failed.")
                }
            }).catch(err => {
                console.log("Item Category Master save Err " + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "categoryName": itemCategoryName,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.put(updateItemCategoryMasterAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("This item category is already exists.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Item category master update failed.")
                }
            }).catch(err => {
                console.log("Item category master update err " + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                //console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.itemCategoryMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.itemCategoryMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        {/* Form Data start */}
                        <form onSubmit={onSubmit}>
                            <div className="form-data mt-1">
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Item Category Name :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && itemCategoryName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Item Category Name"
                                            variant="outlined"
                                            margin='dense'
                                            value={itemCategoryName}
                                            onChange={(e) => setItemCategoryName(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? itemCategoryName : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="mt-3">
                            <ItemCategoryMasterTable
                                TableHeading={TableHeading}
                                data={data}
                                filteredData={filteredData}
                                setFilteredData={setFilteredData}
                                setId={setId}
                                setItemCategoryName={setItemCategoryName}
                                getData={getData}
                                clearData={clearData}
                                setSaveBtn={setSaveBtn}
                                setUpdateBtn={setUpdateBtn}
                                updateBtnStyle={updateBtnStyle}
                                displayNoneBtnStyle={displayNoneBtnStyle}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ItemCategoryMaster