import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { toast } from 'react-toastify';
import { AccountGroupMasterDelete, BaseURLAccountGroupMaster, getGroupHeadListByGroupTypeAndGroupHeadAndSectionId } from '../../CommonServices/AccountGroupAPIURL';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import TitleLabel from '../../CommonComponent/TitleLabel';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const MasterFormAccountGroupTable = () => {

    const titleId = "Account Group"
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const navigate = useNavigate();

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    //console.log(sectionName);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const UpdateData = async (item, EditFlag) => {
        // setSaveBtn(displayNoneBtnStyle);
        // setUpdateBtn(updateBtnStyle);
        // setId(item.id);
        // setGlNameMarathi(item.groupNameMr);
        // setGlNameEnglish(item.groupNameEn);
        // setGroupCode(item.groupCode);
        // setGroupBook(item.groupType);
        // // console.log(item);
        // setGroupHead(item.groupHead);
        // setIndex(item.index);
        // setIsForward(item.isForward);
        // setSectionObj(item.sectionId);
        // setSectionId((item.sectionId).map(e => e.id).join(','));
        // setRecAndPayGroupObj(recAndPayGroupData.find(e => e.id == item.rpGroupId))

        // await axios.get(`${getGroupHeadListByGroupTypeAndGroupHeadAndSectionId}?groupType=${item.groupType}&sid=${authUser.deptId}`)
        //     .then(res => {
        //         setIncExpAndBalSheetData(res.data);
        //         setIncExpAndBalSheetObj(res.data.find(e => e.id == item.groupHeadId))
        //     }).catch(err => {
        //         console.log("Get Inc.,Exp. $ Bal. sheet data err " + err);
        //     })
        navigate(`/Home/ac01F/${item}/${EditFlag}`)
    }

    const getData = async () => {
        if (authUser.deptId != null) {
            await axios.get(`${BaseURLAccountGroupMaster}${authUser.deptId}`)
                .then((response) => {
                    setData(response.data);
                    setFilteredData(response.data);
                })
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const [deleteId, setDeleteId] = useState("");
    const DeleteData = (id) => {
        axios.delete(`${AccountGroupMasterDelete}` + id)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setSearchTerm('');
                    toast.success("Deleted sucessfully");
                }
            });
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(
            (item) =>
                item.groupNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.groupNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.groupCode.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )

        if (searchTerm != "") {
            setFilteredData(tempFilteredData.sort((a, b) => (a.groupCode > b.groupCode) ? 1 : -1));
        }
        else {
            setFilteredData(tempFilteredData);
        }
    }, [searchTerm]);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: 'Group Code', key: 'groupCode' },
        { label: 'G.L Name Marathi', key: 'groupNameMr' },
        { label: 'G.L Name English', key: 'groupNameEn' },
        { label: 'Section', key: (authUser.branchMedium == 1) ? 'nameMr' : 'nameEn' },
        { label: 'Index', key: 'index' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                <div className=''>
                    <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate('/Home/ac01F/0/1')}><AddIcon fontSize="small" /> New Account Group</button>
                </div>

                <div className='row mb-1' style={{ float: 'right' }}>
                    <div className=''>
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                        />
                    </div>
                </div>

                <div className='table-responsive'>
                    <TableContainer component={Paper}>
                        <Table className="table table-bordered">
                            <TableHead className="table-Default">
                                <TableRow>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <TableCell style={{ fontSize: "14px", fontWeight: "600", lineHeight: '1.5' }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <TableRow>
                                                    <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</TableCell>
                                                    <TableCell style={{ fontSize: "15px", fontWeight: "500", width: '80px' }}>{item.groupCode}</TableCell>
                                                    <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.groupNameMr}</TableCell>
                                                    <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.groupNameEn}</TableCell>
                                                    <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        {

                                                            item.sectionId.map((e, index) => {
                                                                if (e.id == 1) {
                                                                    return (
                                                                        <span className='badge' key={index} style={{ backgroundColor: "#6C3483", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                    )
                                                                }
                                                                else if (e.id == 2) {
                                                                    return (
                                                                        <span className='badge' key={index} style={{ backgroundColor: "#2980B9", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                    )
                                                                }
                                                                else if (e.id == 3) {
                                                                    return (
                                                                        <span className='badge' key={index} style={{ backgroundColor: "#16A085", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                    )
                                                                }
                                                                else if (e.id == 4) {
                                                                    return (
                                                                        <span className='badge' key={index} style={{ backgroundColor: "#F39C12", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                    )
                                                                }
                                                                else if (e.id == 5) {
                                                                    return (
                                                                        <span className='badge' key={index} style={{ backgroundColor: "#34495E", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <span className='badge' key={index} style={{ backgroundColor: "red", borderRadius: "10px", fontWeight: "600", fontSize: "10px", margin: "4px", padding: "6px" }}>{(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}</span>
                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </TableCell>
                                                    <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.index}</TableCell>

                                                    <TableCell>
                                                        <Tooltip title="Edit">
                                                            <button className='btn btn-primary btn-sm mx-1 p-1'
                                                                disabled={(authUser.roleId == 5 || authUser.roleId == 7) ? false : true}
                                                                onClick={() => UpdateData(item.id, 2)} data-bs-toggle="tooltip" >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Delete">
                                                            <button className='btn btn-danger btn-sm mx-1 p-1'
                                                                disabled={(authUser.roleId == 5) ? false : true}
                                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={() => setDeleteId(item.id)}                                                >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        )
                                    })}
                            </TableBody>
                            <TableFooter style={{ padding: "0px" }}>
                                <TableRow>
                                    <TablePagination
                                        style={{ padding: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        labelRowsPerPage="Rows Per Page :"
                                        classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default MasterFormAccountGroupTable;
