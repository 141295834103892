import { dBaseURL, BaseURL, dBaseURLMaster, BaseURLMaster } from "../../../CommonServices/APIURL";

//Budget Report APIURL
//getAllBudgetCategoryByBranch
//sid  bid
export const getCategoryBySectionAndBranch = BaseURL + "budget/master/getAllBudgetCategoryByBranch";

//localhost:8080/institute/sss/budget/transaction/getBudgetDetailByCategory?categoryId=4&sessionYear=2022&sid=3&bid=50
export const getCategoryReportDataCategoryWiseAPIURL = BaseURL + "budget/transaction/getBudgetDetailByCategory";

//category detail report getdata
//localhost:8080/institute/sss/budget/transaction/getBudgetDetailAllCategory?sessionYear=2022&bid=1
export const getCategoryReportDataAllAPIURL = BaseURL + "budget/transaction/getBudgetDetailAllCategory";

//print consolidated report
//localhost:8080/institute/sss/budget/transaction/reports/getAllBudgetConsolidatedReport?sessionYear=2022&bid=1&langId=1
export const printAllBudgetEntryReportAPIURL = BaseURL + "budget/transaction/reports/getAllBudgetConsolidatedReport";

//print categorywise detail report
//localhost:8080/institute/sss/budget/transaction/reports/getCategoryWiseBudgetConsolidatedReport?categoryId=4&sessionYear=2022&sid=3&bid=1&langId=1
export const printCategoryWiseBudgetEntryReportAPIURL = BaseURL + "budget/transaction/reports/getCategoryWiseBudgetConsolidatedReport";

//print categorywise asset report
// /localhost:8080/institute/sss/budget/transaction/reports/getAssetCategoryWiseBudgetConsolidatedReport?sessionYear=2022&bid=1&sid=3&langId=1&categoryId=12
export const printCategoryWiseAssetReportAPIURL = BaseURL + "budget/transaction/reports/getAssetCategoryWiseBudgetConsolidatedReport";



//School Wise Budget Report APIURL
//localhost:8080/institute/sss/master/getSchoolNameWithSection
export const getSchoolListForBudget = BaseURLMaster + "getSchoolNameWithSection";




//Budget Consolidate report for sanstha APIURL
//school receipt get & print APIURL
// /http://192.168.1.102:8080/institute/sss/budget/transaction/getBudgetDetailAllSchoolDetailSchoolReceipt?langId=1&sessionYear=2022
export const getBudgetDetailAllSchoolReceiptDetail = BaseURL + "budget/transaction/getBudgetDetailAllSchoolDetailSchoolReceipt";

// /localhost:8080/institute/sss/budget/transaction/reports/getAllSchoolBudgetSchoolFeeReceiptReport?langId=1&sessionYear=2022
export const printBudgetDetailAllSchoolReceiptDetail = BaseURL + "budget/transaction/reports/getAllSchoolBudgetSchoolFeeReceiptReport";

//school payment get & print APIURL
//getBudgetDetailAllSchoolDetailSchoolPayment
export const getBudgetDetailAllSchoolPaymentDetail = BaseURL + "budget/transaction/getBudgetDetailAllSchoolDetailSchoolPayment";

// /getAllSchoolBudgetSchoolFeePaymentReport
export const printBudgetDetailAllSchoolPaymentDetail = BaseURL + "budget/transaction/reports/getAllSchoolBudgetSchoolFeePaymentReport";

//session receipt get & print APIURL
//getBudgetDetailAllSchoolDetailSessionReceipt
export const getBudgetDetailAllsessionReceiptDetail = BaseURL + "budget/transaction/getBudgetDetailAllSchoolDetailSessionReceipt";

//getAllSchoolBudgetSessionFeeReceiptReport
export const printBudgetDetailAllsessionReceiptDetail = BaseURL + "budget/transaction/reports/getAllSchoolBudgetSessionFeeReceiptReport";

//session payment get & print APIURL
//getBudgetDetailAllSchoolDetailSessionPayment
export const getBudgetDetailAllSessionPaymentDetail = BaseURL + "budget/transaction/getBudgetDetailAllSchoolDetailSessionPayment";

//getAllSchoolBudgetSessionFeePaymentReport
export const printBudgetDetailAllSessionPaymentDetail = BaseURL + "budget/transaction/reports/getAllSchoolBudgetSessionFeePaymentReport";



//department wise sanstha budget consolidate Report APIURL
//localhost:8080/institute/sss/budget/transaction/getDepartmentListForBudget
export const getDepartmentListForBudget = BaseURL + "budget/transaction/getDepartmentListForBudget";

//school receipt get & print APIURL
//localhost:8080/institute/sss/budget/transaction/getBudgetDetailAllSchoolDetailSchoolReceiptByDepartment?sessionYear=2022&sid=2
export const getDepartmentWiseAllSchoolReceiptDetail = BaseURL + "budget/transaction/getBudgetDetailAllSchoolDetailSchoolReceiptByDepartment";

//localhost:8080/institute/sss/budget/transaction/reports/getAllSchoolBudgetSchoolFeeReceiptReportByDepartment?langId=1&sessionYear=2022&sid=2
export const printDepartmentWiseAllSchoolReceiptDetail = BaseURL + "budget/transaction/reports/getAllSchoolBudgetSchoolFeeReceiptReportByDepartment";

//school payment get & print APIURL
//getBudgetDetailAllSchoolDetailSchoolPaymentByDepartment
export const getDepartmentWiseAllSchoolPaymentDetail = BaseURL + "budget/transaction/getBudgetDetailAllSchoolDetailSchoolPaymentByDepartment";

//getAllSchoolBudgetSchoolFeePaymentReportByDepartment
export const printDepartmentWiseAllSchoolPaymentDetail = BaseURL + "budget/transaction/reports/getAllSchoolBudgetSchoolFeePaymentReportByDepartment";

//session receipt get & print APIURL
//getBudgetDetailAllSchoolDetailSessionReceiptByDepartment
export const getDepartmentWiseAllSessionReceiptDetail = BaseURL + "budget/transaction/getBudgetDetailAllSchoolDetailSessionReceiptByDepartment";

//getAllSchoolBudgetSessionFeeReceiptReportByDepartment
export const printDepartmentWiseAllSessionReceiptDetail = BaseURL + "budget/transaction/reports/getAllSchoolBudgetSessionFeeReceiptReportByDepartment";

//session payment get & print APIURL
//getBudgetDetailAllSchoolDetailSessionPaymentByDepartment
export const getDepartmentWiseAllSessionPaymentDetail = BaseURL + "budget/transaction/getBudgetDetailAllSchoolDetailSessionPaymentByDepartment";

//getAllSchoolBudgetSessionFeePaymentReportByDepartment
export const printDepartmentWiseAllSessionPaymentDetail = BaseURL + "budget/transaction/reports/getAllSchoolBudgetSessionFeePaymentReportByDepartment";


//sanstha consolidated report APIURL
//http://192.168.1.102:8080/institute/sss/budget/transaction/getSansthaBudgetReportData?sessionYear=2022&sid=12&bid=50
export const getSansthaConsolidatedReportDataAPIURL = BaseURL + "budget/transaction/getSansthaBudgetReportData";

// /localhost:8080/institute/sss/budget/transaction/reports/getSansthaConsolidatedReport?langId=1&sessionYear=2022&sid=12&bid=50
export const printSansthaConolidatedReportAPIURL = BaseURL + "budget/transaction/reports/getSansthaConsolidatedReport";

//localhost:8080/institute/sss/budget/transaction/reports/getSansthaCategoryWiseReport?langId=1&sessionYear=2022&sid=12&bid=50&categoryId=12
export const printSansthaCategoryWiseBudgetReportAPIURL = BaseURL + "budget/transaction/reports/getSansthaCategoryWiseReport";

//sainik school hostel report APIURL
//http://192.168.1.102:8080/institute/sss/budget/transaction/getHostelBudgetDetailAllCategory?sessionYear=2022&sid=14&bid=36
export const getSainikSchoolHostelConsolidatedDataAPIURL = BaseURL + "budget/transaction/getHostelBudgetDetailAllCategory";

//localhost:8080/institute/sss/budget/transaction/reports/getHostelBudgetConsolidatedReport?langId=1&sessionYear=2022&sid=14&bid=36
export const printSanikSchoolHostelConsolidatedReport = BaseURL + "budget/transaction/reports/getHostelBudgetConsolidatedReport";


//Sanstha Consolidated report APIURL
//get क्रमांक २ ब पाच report
//localhost:8080/institute/sss/budget/transaction/getSansthaConsolidatedBudgetNoTwoB?sessionYear=2022&sid=12&bid=50
export const getSansthaConsolidatedBudgetNoTwoB = BaseURL + "budget/transaction/getSansthaConsolidatedBudgetNoTwoB";

//print क्रमांक २ ब पाच report
// /localhost:8080/institute/sss/budget/transaction/reports/getSansthaConsolidatedBudgetNoTwoBReport?sessionYear=2022&sid=12&bid=50
export const printSansthaConsolidatedBudgetNoTwoBReport = BaseURL + "budget/transaction/reports/getSansthaConsolidatedBudgetNoTwoBReport";

//get क्रमांक १ अ २ report
//localhost:8080/institute/sss/budget/transaction/getSansthaConsolidatedBudgetNoOneA?sessionYear=2022&sid=12&bid=50
export const getSansthaConsolidatedBudgetNoOneA = BaseURL + "budget/transaction/getSansthaConsolidatedBudgetNoOneA";

//print क्रमांक १ अ २ report
//getSansthaConsolidatedBudgetNoOneAReport
export const printSansthaConsolidatedBudgetNoOneAReport = BaseURL + "budget/transaction/reports/getSansthaConsolidatedBudgetNoOneAReport";

//get क्रमांक १ ब ३ report
// /localhost:8080/institute/sss/budget/transaction/getSansthaConsolidatedBudgetNoOneB?sessionYear=2022&sid=12&bid=50
export const getSansthaConsolidatedBudgetNoOneB = BaseURL + "budget/transaction/getSansthaConsolidatedBudgetNoOneB";

//print क्रमांक १ ब ३ report
// /localhost:8080/institute/sss/budget/transaction/reports/getSansthaConsolidatedBudgetNoOneBReport?sessionYear=2022&sid=12&bid=50
export const printSansthaConsolidatedBudgetNoOneBReport = BaseURL + "budget/transaction/reports/getSansthaConsolidatedBudgetNoOneBReport";

//get object of trust report
//localhost:8080/institute/sss/budget/transaction/getSansthaConsolidatedBudgetObjectOfTrust?sessionYear=2022
export const getSansthaConsolidatedObjectOfTrust = BaseURL + "budget/transaction/getSansthaConsolidatedBudgetObjectOfTrust";

//print object of trust report
//localhost:8080/institute/sss/budget/transaction/reports/getSansthaConsolidatedBudgetObjectOfTrustReport?sessionYear=2022
export const printSansthaConsolidatedObjectOfTrustReport = BaseURL + "budget/transaction/reports/getSansthaConsolidatedBudgetObjectOfTrustReport";

//get miscellanious report
//localhost:8080/institute/sss/budget/transaction/getSansthaConsolidatedBudgetMiscellaneousPayment?sessionYear=2022&sid=12&bid=50
export const getSansthaConsolidatedMiscellaneousPayment = BaseURL + "budget/transaction/getSansthaConsolidatedBudgetMiscellaneousPayment";

//print miscellanious report
//localhost:8080/institute/sss/budget/transaction/reports/getSansthaConsolidatedBudgetMiscellaneousPaymentReport?sessionYear=2022&sid=12&bid=50
export const printSansthaConsolidatedMiscellaneousPaymentReport = BaseURL + "budget/transaction/reports/getSansthaConsolidatedBudgetMiscellaneousPaymentReport";

//get defference report
//localhost:8080/institute/sss/budget/transaction/getSansthaConsolidatedBudgetDifferenceAmount?sessionYear=2022&sid=12&bid=50
export const getSansthaConsolidatedBudgetDifferenceAmount = BaseURL + "budget/transaction/getSansthaConsolidatedBudgetDifferenceAmount";

//localhost:8080/institute/sss/budget/transaction/reports/getSansthaConsolidatedBudgetAmountDifferenceReport?sessionYear=2022&sid=12&bid=50
export const printSansthaConsolidatedBudgetDifferenceAmount = BaseURL + "budget/transaction/reports/getSansthaConsolidatedBudgetAmountDifferenceReport";

//get ExternalExam budget Report
//localhost:8080/institute/sss/budget/transaction/getExternalExamBudgetReportData?sessionYear=2022&sid=15&bid=50
export const getExternalExamBudgetConsolidatedReport = BaseURL + "budget/transaction/getExternalExamBudgetReportData";

//print ExternalExam budget Report
//localhost:8080/institute/sss/budget/transaction/reports/getExternalExamConsolidatedReport?sessionYear=2022&sid=15&bid=50&langId=1
export const printExternalExamBudgetConsolidatedReport = BaseURL + "budget/transaction/reports/getExternalExamConsolidatedReport";



//Sanstha consolidated report in English
//localhost:8080/institute/sss/budget/transaction/reports/getSansthaBudgetEngReport?sessionYear=2022&sid=12&bid=50
//get santha Budget Details in eng.
export const getBudgetDetailsForSansthaConsoInEng = BaseURL + "budget/transaction/reports/getSansthaBudgetEngReportData";

//print santha Budget Details in eng.
export const printBudgetDetailsForSansthaConsoInEng = BaseURL + "budget/transaction/reports/getSansthaBudgetEngReport";



