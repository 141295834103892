import React, { useState } from 'react'

const AdmissionEntranceInterview = ({ initialText1, initialText2, initialText3, initialText4, initialText6, onChange }) => {

    const [isEdit1, setEdit1] = useState(false);
    const [isEdit2, setEdit2] = useState(false);
    const [isEdit3, setEdit3] = useState(false);
    const [isEdit4, setEdit4] = useState(false);

    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    const [text3, setText3] = useState(initialText3);
    const [text4, setText4] = useState(initialText4);
    const [text6, setText6] = useState(initialText6);


    const interviewOnChange = () => {
        let convertMark = (parseInt(text1) + parseInt(text2) + parseInt(text3) + parseInt(text4)) / 4;
        setText6(convertMark);
    }

    return (
        <>
            <td style={{ width: "130px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100px", fontSize: "15px" }}
                    // style={(isEdit1 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => {
                        if (text1 == "" || text1 == null) {
                            setText1(0)
                        }
                        //setEdit1(false);
                        interviewOnChange();
                        onChange(text1, text2, text3, text4, text6)
                    }}
                    //autoFocus
                    onChange={(e) => setText1(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4, text6) : ""}
                    value={text1}
                    min="0"
                />
            </td>

            <td style={{ width: "130px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100px", fontSize: "15px" }}
                    // style={(isEdit2 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    // onFocus={() => setEdit2(true)}
                    onBlur={() => {
                        if (text2 == "" || text2 == null) {
                            setText2(0)
                        }
                        // setEdit2(false);
                        interviewOnChange();
                        onChange(text1, text2, text3, text4, text6)
                    }}
                    //autoFocus
                    onChange={(e) => setText2(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4, text6) : ""}
                    value={text2}
                    min="0"
                />
            </td>

            <td style={{ width: "130px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100px", fontSize: "15px" }}
                    // style={(isEdit3 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    // onFocus={() => setEdit3(true)}
                    onBlur={() => {
                        if (text3 == "" || text3 == null) {
                            setText3(0)
                        }
                        // setEdit3(false);
                        interviewOnChange();
                        onChange(text1, text2, text3, text4, text6)
                    }}
                    //autoFocus
                    onChange={(e) => setText3(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4, text6) : ""}
                    value={text3}
                    min="0"
                />
            </td>

            <td style={{ width: "130px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100px", fontSize: "15px" }}
                    // style={(isEdit4 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    // onFocus={() => setEdit4(true)}
                    onBlur={() => {
                        if (text4 == "" || text4 == null) {
                            setText4(0)
                        }
                        // setEdit4(false);
                        interviewOnChange();
                        onChange(text1, text2, text3, text4, text6)
                    }}
                    //autoFocus
                    onChange={(e) => setText4(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4, text6) : ""}
                    value={text4}
                    min="0"
                />
            </td>

            <td style={{ width: "130px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                {text6}
            </td>
        </>
    )
    // :
    // <td style={{ width: "130px", height: "70px", fontWeight: "500", fontSize: "15px" }} onClick={() => setEdit(true)}>{text}</td>
}

export default AdmissionEntranceInterview