import React, { useState } from 'react'

const InterestRateTextBox = ({ initialText, onChange }) => {

    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);

    return (
        <>
            <td style={{ width: "80px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    //style={{ width: "130px", fontSize: "15px" }}
                    style={(isEdit === true) ? { width: "120px", fontSize: "15px", } : { width: "120px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit(true)}
                    onBlur={() => {
                        if (text == "" || text == null) {
                            setText(0)
                        }
                        setEdit(false);
                        onChange(text);
                    }}
                    //autoFocus
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text) : ""}
                    value={text}
                    min="0"
                />
            </td>
        </>
    )
}

export default InterestRateTextBox