import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import { deleteReceiptPaymentGroupAPIURL, getNegativeBalGroupAPIURL, getReceiptPatmentGroupAPIURL } from '../Services/ReceiptPaymentGroupAPIURL';
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { useNavigate } from 'react-router-dom';
import TitleLabel from '../../CommonComponent/TitleLabel';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const ReceiptPaymentGroupMasterTable = () => {

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Receipt Payment Group"
    const classes = useStyles();
    const navigate = useNavigate();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const UpdateData = async (item, EditFlag) => {
        // setId(item.id);
        // setSaveBtn(displayNoneBtnStyle);
        // setUpdateBtn(updateBtnStyle);
        // setGroupHead(item.rcHeader)
        // setGroupNameMr(item.nameMr)
        // setGroupNameEn(item.nameEn)
        // setIndex(item.ind)


        // await axios.get(`${getNegativeBalGroupAPIURL}?rcHeader=${item.rcHeader}&sid=${authUser.deptId}`)
        //     .then(res => {
        //         setNegativeBalGroupData(res.data);
        //         //console.log(res.data.map(e => e.id) + "======" + item.negativeBalGroup)
        //         setNegativeBalGroupObj(res.data.find(e => e.id == item.negativeBalGroup))
        //     }).catch(err => {
        //         console.log('get negative bal.' + err)
        //     })
        navigate(`/Home/ac618F/${item}/${EditFlag}`)
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null) {
            await axios.get(`${getReceiptPatmentGroupAPIURL}?sid=${authUser.deptId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log('get Receipt payment group' + err)
                })
        }
    }

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = (id) => {
        axios.delete(`${deleteReceiptPaymentGroupAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setSearchTerm('');
                    toast.success("Deleted sucessfully");
                }
            });
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) => (
            item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.ind.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.nameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.nameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.rcHeaderName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.negativeBalGroupEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.negativeBalGroupMr.toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: 'Group Name Marathi', key: 'nameMr' },
        { label: 'Group Name English', key: 'nameEn' },
        { label: 'Group Head', key: 'rcHeaderName' },
        { label: 'Negative Bal. Group', key: (authUser.branchMedium == 1) ? 'negativeBalGroupMr' : 'negativeBalGroupEn' },
        { label: 'Index', key: 'ind' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                <div className=''>
                    <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate('/Home/ac618F/0/1')}><AddIcon fontSize="small" /> New Receipt Payment</button>
                </div>

                <div className='row mb-1' style={{ float: 'right' }}>
                    <div className=''>
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                        />
                    </div>
                </div>

                <div className='table-responsive'>
                    <table className="table table-bordered">
                        <thead className="table-Default">
                            <tr>
                                {TableHeading.map((item, index) => {
                                    return (
                                        <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.5" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                            {
                                filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .reverse().map((item, index) => {
                                        return (

                                            <tr key={index}>
                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{item.nameMr}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{item.nameEn}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "120px" }}>
                                                    {(() => {
                                                        if (item.rcHeader == 1) {
                                                            return (
                                                                <span className='badge' style={{ backgroundColor: "#018D0A", borderRadius: "10px", fontWeight: "600", fontSize: "12px", margin: "4px", padding: "6px", color: "#fff" }}>{item.rcHeaderName}</span>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <span className='badge' style={{ backgroundColor: "#FE5246", borderRadius: "10px", fontWeight: "600", fontSize: "12px", margin: "4px", padding: "6px", color: "#fff" }}>{item.rcHeaderName}</span>
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px", lineHeight: "1.5", textAlign: `${(item.negativeBalGroupMr == "" && item.negativeBalGroupEn == "") ? 'center' : ''}` }}>{(authUser.branchMedium == 1) ? ((item.negativeBalGroupMr == "") ? ' - ' : item.negativeBalGroupMr) : ((item.negativeBalGroupEn == "") ? ' - ' : item.negativeBalGroupEn)}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.ind}</td>

                                                <td>
                                                    <Tooltip title="Edit">
                                                        <span className='btn btn-primary btn-sm mx-1 p-1'
                                                            onClick={() => UpdateData(item.id, 2)} data-bs-toggle="tooltip">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </span>
                                                    </Tooltip>
                                                </td>
                                                <td>
                                                    <Tooltip title="Delete">
                                                        <span className='btn btn-danger btn-sm mx-1 p-1'
                                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                            onClick={() => setDeleteId(item.id)}                                                >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </span>
                                                    </Tooltip>
                                                </td>
                                            </tr>

                                        )
                                    })}
                        </tbody>
                        <tfoot style={{ padding: "0px" }}>
                            <tr>
                                <TablePagination
                                    style={{ padding: "0px" }}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                    onPageChange={onPageChange}
                                    labelRowsPerPage="Rows Per Page :"
                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <DeleteModalBox DeleteData={DeleteData} deleteId={deleteId} />
        </>
    )
}

export default ReceiptPaymentGroupMasterTable