import React, { useState } from 'react'

const MultipleFeeReceiptInput = ({ s1, s2, s3, onChange }) => {


    const [s1text, sets1Text] = useState(s1);
    const [s2text, sets2Text] = useState(s2);
    const [s3text, sets3Text] = useState(s3);
    // const [s4text, sets4Text] = useState(s4);



    return (
        <>

            <td style={{ width: '120px' }}>
                <input
                    type='number'

                    style={{ width: "80px", fontSize: "14px", fontWeight: "500" }}
                    onBlur={() => { onChange(s1text, s2text, s3text) }}
                    onChange={(e) => sets1Text(e.target.value)}
                    value={s1text}
                    min="0"

                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(s1text, s2text, s3text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
            <td style={{ width: '120px' }}>
                <input
                    type='number'

                    style={{ width: "80px", fontSize: "14px", fontWeight: "500" }}
                    onBlur={() => { onChange(s1text, s2text, s3text) }}
                    onChange={(e) => sets2Text(e.target.value)}
                    value={s2text}
                    min="0"

                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(s1text, s2text, s3text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
            <td>
                <input
                    type='text'

                    style={{ width: "180px", fontSize: "14px", fontWeight: "500" }}
                    onBlur={() => { onChange(s1text, s2text, s3text) }}
                    onChange={(e) => sets3Text(e.target.value)}
                    value={s3text}
                    min="0"

                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(s1text, s2text, s3text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
            {/* <td>
                <input
                    type='number'

                    style={{ width: "80px", fontSize: "14px", fontWeight: "500" }}
                    onBlur={() => { onChange(s1text, s2text, s3text) }}
                    onChange={(e) => sets4Text(e.target.value)}
                    value={s4text}
                    min="0"

                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(s1text, s2text, s3text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td> */}
        </>
    )
}


export default MultipleFeeReceiptInput
