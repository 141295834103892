import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Loader from "../../CommonComponent/Loader";
import Paper from "@mui/material/Paper";
import { BaseURLAccountMaster } from '../../CommonServices/AccountMasterAPIURL';
import {
    VoucherEntryNoGetMapping, AccountCodeGetMapping, VoucherEntryGetData, VoucherEntryPostMapping,
    VoucherEntryPutMapping, getClosingBalanceAmtAPIURL, getAccClosingBalAmtAPIURL
} from '../../CommonServices/VoucherEntryAPIURL';
import TransactionModeData from '../../CommonComponent/LocalJSON/TransactionModeData';
import { BaseURLNarrationMaster } from '../../CommonServices/NarrationMasterAPIURL'


const filter = createFilterOptions();

const ReaceiptData = [
    {
        id: 1,
        Label1: "पावती / Receipt :",
        Label2: "चलन / Payment :"
    },
]

const VoucherEntryForm = () => {
    const authUser = useSelector((state) => state.user.value);
    let { vno, EditFlag } = useParams();
    const [titleId, setTitleId] = useState("");
    const navigate = useNavigate();
    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    const [dateCheck, setDateCheck] = useState(false)

    //console.log(moment(defaultMinDate).format("DD/MM/YYYY"))

    // const sessionDate=authUser.fromDate;
    // console.log("aaaaaaaaa"+sessionDate)

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Add Voucher")
        }
        else if (EditFlag == 2) {
            setTitleId("Update Voucher")
        }
        getData();

    }, [vno, authUser]);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "voucherEntrySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "voucherEntryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);
    //console.log("saveBtnDisabled" + saveBtnDisabled + "updateBtnDisabled" + updateBtnDisabled)

    const [accountData, setAccountData] = useState([]);
    const [narrationData, setNarrationData] = useState([]);
    const [DateValue, setDateValue] = useState(null);
    //new Date(`${authUser.sessionYear}/04/01`)

    const [voucherId, setVoucherId] = useState([]);
    const [amount, setAmount] = useState(null);
    const [narration, setNarration] = useState("");
    const [isNarrationSave, setIsNarrationSave] = useState(0);
    const [transactionMode, setTransactionMode] = useState(1);
    const [isReceipt, setIsReceipt] = useState(2);
    const [id, setId] = useState();
    const [accountCode, setAccountCode] = useState(null);
    const [accountCodeObj, setAccountCodeObj] = useState(null);

    const [closingAmt, setClosingAmt] = useState('');
    const [accClosingAmt, setAccClosingAmt] = useState('');
    const [accClosingFlag, setAccClosingFlag] = useState('');


    const showAmountError = {
        display: "block"
    }
    const hideAmountError = {
        display: "none"
    }
    const [amountCheck, setAmountCheck] = useState(hideAmountError);

    const HandleNarrationCheckbox = (e) => {
        setIsNarrationSave(e.target.checked);
        if (e.target.checked == true) {
            setIsNarrationSave(1);
        }
        else {
            setIsNarrationSave(0);
        }
    }

    const firstUrl = `${BaseURLAccountMaster}?sid=${authUser.deptId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`;
    //console.log(firstUrl);
    const secondUrl = `${VoucherEntryGetData}?vno=${vno}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`;

    const getEditDetails = () => {
        setLoaderOption(true);
        axios.all([
            axios.get(secondUrl)
        ]).then(
            axios.spread((respOne) => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setVoucherId(respOne.data.voucherId);
                setDateValue(respOne.data.voucherDate);
                //console.log("Date====="+DateValue)
                setNarration(respOne.data.narration);
                setTransactionMode(respOne.data.transactionMode);
                setAmount(respOne.data.transactionMode == 1 ? respOne.data.creditAmount : respOne.data.debitAmount)
                setIsReceipt(respOne.data.isReceipt);
                setIsNarrationSave(respOne.data.isNarrationSave);
                const SelectedAccount = respOne.data.accountCode;

                axios.get(`${firstUrl}`)
                    .then((respTwo) => {
                        setAccountData((respTwo.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1));
                        setAccountCodeObj(respTwo.data.find(e => e.accountCode == SelectedAccount));
                        setAccountCode(SelectedAccount);
                    })

                axios.get(`${BaseURLNarrationMaster}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then((respThree) => {
                        setNarrationData(respThree.data);
                        setLoaderOption(false);
                    })

                axios.get(`${getClosingBalanceAmtAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setClosingAmt(res.data);
                        setLoaderOption(false);
                    })

                axios.get(`${getAccClosingBalAmtAPIURL}?accCode=${SelectedAccount}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        console.log(res.data);
                        setAccClosingAmt(parseFloat(res.data.closing).toFixed('2'));
                        setAccClosingFlag(res.data.flag)
                        setLoaderOption(false);
                    })
            })
        )
    }

    const getDetails = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != '') {
            await axios(`${VoucherEntryNoGetMapping}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
                .then(res => {
                    setVoucherId(res.data);
                    //setLoaderOption(false);
                })

            await axios.get(`${firstUrl}`)
                .then((respTwo) => {
                    setAccountData((respTwo.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1));
                    //setLoaderOption(false);
                })
            //console.log(`${BaseURLNarrationMaster}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${BaseURLNarrationMaster}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((respThree) => {
                    setNarrationData(respThree.data);
                    //setLoaderOption(false);

                })

            await axios.get(`${getClosingBalanceAmtAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClosingAmt(res.data);
                    setLoaderOption(false);
                })
        }
    }

    const getAccClosingAmt = async (accCode) => {
        await axios.get(`${getAccClosingBalAmtAPIURL}?accCode=${accCode}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                console.log(res.data);
                setAccClosingAmt(parseFloat(res.data.closing).toFixed('2'));
                setAccClosingFlag(res.data.flag)
                setLoaderOption(false);
            })
    }

    const getData = async () => {
        if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
        else if (EditFlag == 1) {
            getDetails();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
            // if (dateCheck > defaultMaxDate || dateCheck < defaultMinDate) {
            //     setDateCheck("Invalid date")
            // }
            // else {
            //     setDateCheck(null)
            // }
        }
    }

    const saveBtnClick = () => {
        setLoaderOption(true);
        if (amount >= 0 && amount != null && moment(DateValue).format("DD/MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {

            const saveObject = {
                voucherId: voucherId,
                voucherDate: moment(DateValue).format("DD/MM/YYYY"),
                accountCode: accountCode,
                amount: amount,
                narration: narration,
                isNarrationSave: isNarrationSave,
                transactionMode: transactionMode,
                isReceipt: isReceipt,
                acYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            };
            //console.log(JSON.stringify(saveObject));
            axios.post(`${VoucherEntryPostMapping}`, saveObject)
                .then((response) => {

                    if (response.data != null) {
                        getData();
                        setAccountCode(null);
                        setAccountCodeObj(null)
                        setAmount(null);
                        setNarration("");
                        setIsNarrationSave(0);
                        setTransactionMode(1);
                        setIsReceipt(2);
                        setLoaderOption(false);
                        toast.success("Voucher Entry saved successfully.");
                    } else {
                        toast.error("Operation failed.");
                    }
                });
            setSaveBtn(saveBtnStyle);
            setUpdateBtn(displayNoneBtnStyle);
            //toast.success("Proper Date")
        }
        else {
            if (amount < 0 || amount == null) {
                setLoaderOption(false);
                toast.error("Amount should be grater than 0/Zero")
            }
            else if (moment(DateValue).format("DD/MM/YYYY") === "Invalid date" || dateCheck == "Invalid date") {
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
        }
    };

    const updateBtnClick = () => {
        setLoaderOption(true);
        //console.log("dateCheck=" + moment(defaultMinDate).format("DD/MM/YYYY").getTime() < moment(DateValue).format("DD/MM/YYYY").getTime())
        if (amount >= 0 && amount != null && moment(DateValue).format("DD/MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            const updateObject = {
                voucherId: voucherId,
                voucherDate: moment(DateValue).format("DD/MM/YYYY"),
                accountCode: accountCode,
                amount: amount,
                narration: narration,
                isNarrationSave: isNarrationSave,
                transactionMode: transactionMode,
                isReceipt: isReceipt,
                acYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            };

            axios.put(`${VoucherEntryPutMapping}?vno=${vno}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`, updateObject)
                .then((response) => {
                    if (response.data != null) {
                        //toast("Update successfully.");
                        //console.log("Update successfully."); 
                        getData();
                        setLoaderOption(false);
                        navigate("/Home/ac04/2");

                    }

                });
            //toast.success("proper date")
        }
        else {
            if (amount < 0 || amount == null) {
                setLoaderOption(false);
                toast.error("Amount should be grater than 0/Zero")
            }
            else if (moment(DateValue).format("DD/MM/YYYY") === "Invalid date" || dateCheck == "Invalid date") {
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
        }
    };

    const ClearData = () => {
        navigate("/Home/ac04/0")
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const textFieldStyle = {
        color: closingAmt < 0 ? "#EA2C02" : "#0B9202",
        fontWeight: "550",
    };

    const textFieldStyle1 = {
        color: accClosingFlag == 2 ? "#EA2C02" : "#0B9202",
        fontWeight: "550",
    };


    if (loaderOption == true) {
        return <Loader />

    }
    else {

        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 0px 2px grey", marginTop: "16px", minHeight: "480px" }}>

                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className="row">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: '16px' }} >Closing Amount :</label>
                            {/* <div className="" style={{ width: '120px' }}> */}
                            {/* <p className='' style={{ color: (closingAmt < 0) ? "#EA2C02" : "#0B9202", borderRadius: "3px", fontWeight: "620", fontSize: "15px", margin: "4px", padding: "5px", border: '1px solid #EBEDEF', lineHeight: "1.4", textAlign: 'center' }}>{(closingAmt < 0) ? parseFloat(-closingAmt).toFixed('2') : parseFloat(closingAmt).toFixed('2')}</p> */}
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Closing Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={(closingAmt < 0) ? parseFloat(-closingAmt).toFixed('2') : parseFloat(closingAmt).toFixed('2')}
                                    //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                                    // onChange={e => setVoucherId(e.target.value)}
                                    onKeyPress={(e) => (e.key === "Enter") ? closingAmt : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: true }}
                                    InputProps={{
                                        style: textFieldStyle,
                                    }}
                                />

                            </div>
                            {/* </div> */}
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >क्रमांक / Voucher No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="क्रमांक / Voucher No"
                                    variant="outlined"
                                    margin='dense'
                                    value={voucherId}
                                    onChange={e => setVoucherId(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? voucherId : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >तारीख / Date :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="तारीख / Date"
                                        value={DateValue}
                                        minDate={defaultMinDate}
                                        maxDate={defaultMaxDate}
                                        onChange={(newValue) => {
                                            setDateValue(newValue);
                                            //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                            const maxDate = new Date(defaultMaxDate);
                                            maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                            if (newValue < defaultMinDate || newValue >= maxDate) {
                                                setDateCheck("Invalid date");
                                            } else {
                                                setDateCheck("");
                                            }
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? DateValue : ""}
                                        inputFormat="dd/MM/yyyy"
                                        openTo="day"
                                        // Show defaultMaxDate selected when the DatePicker is opened
                                        // If dateFrom is not set, defaultMaxDate is used
                                        onOpen={() => setDateValue(DateValue || defaultMaxDate)}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                                {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                            </div>

                        </div>

                        <div className="row my-3">
                            <div className='offset-sm-2 col-sm-4'>
                                <FormControl>
                                    <RadioGroup row name="customized-radios" value={transactionMode}
                                        onKeyPress={(e) => (e.key == "Enter") ? transactionMode : ""}
                                        onChange={(e) => setTransactionMode(parseInt(e.target.value))}>
                                        {TransactionModeData.map((item) => {
                                            return (
                                                <FormControlLabel value={item.id} control={<Radio color='primary' />} label={item.Label} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            {(() => {
                                if (transactionMode == 1) {
                                    return (
                                        <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>पावती / Receipt :</label>
                                    )
                                }
                                else if (transactionMode == 2) {
                                    return (
                                        <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>चलन / Payment :</label>
                                    )
                                }
                            })()}

                            <div className='col-sm-3'>
                                <RadioGroup
                                    row
                                    name="customized-radios"
                                    value={isReceipt}
                                    onKeyPress={(e) => (e.key == "Enter") ? isReceipt : ""}
                                    onChange={(e) => setIsReceipt(parseInt(e.target.value))}>
                                    <FormControlLabel value={1} control={<Radio color='primary' />} label="हो / Yes" />
                                    <FormControlLabel value={2} control={<Radio color='primary' />} label="नाही / No" />
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >खाते / Account :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    value={accountCodeObj}
                                    options={accountData}
                                    onChange={(event, newValue) => {
                                        setAccountCodeObj(newValue);
                                        setAccClosingAmt('');
                                        setAccClosingFlag('');
                                        if (newValue != null) {
                                            setAccountCode(newValue.accountCode);
                                            getAccClosingAmt(newValue.accountCode);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? accountCodeObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.accountCode + " - " + option.accountNameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="खाते / Account" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >रक्कम / Amount :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    type='number'
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="रक्कम / Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={amount}
                                    //step={0.00}                                  
                                    onChange={e => {
                                        setAmount(parseFloat(e.target.value));
                                        //setAmountCheck((parseFloat(e.target.value) > 0) ? false : true)
                                        (parseFloat(e.target.value) >= 0) ? setAmountCheck(hideAmountError) : setAmountCheck(showAmountError)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? amount : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                //InputProps={{ inputProps: { min: 1 } }}
                                />
                                <span style={amountCheck}><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>* Amount should be greater than 0</em></span>
                            </div>
                        </div>

                        {(() => {
                            if (accountCodeObj != null) {
                                return (
                                    <>
                                        <div className="row my-3">
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: '16px' }} >Acc. Closing Amount :</label>
                                            {/* <div className="col-sm-1">
                                                <p className='' style={{ backgroundColor: (closingAmt < 0) ? "#FA6969" : "#1EA400", borderRadius: "4px", fontWeight: "600", fontSize: "14px", margin: "4px", padding: "5px", color: "#fff", lineHeight: "1.4", textAlign: 'center' }}>{(closingAmt < 0) ? parseFloat(-closingAmt).toFixed('2') : parseFloat(closingAmt).toFixed('2')}</p>
                                            </div> */}
                                            <div className='col-sm-4'>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    autoComplete='off'
                                                    size='small'
                                                    id="outlined-basic"
                                                    label="Acc. Closing Amount"
                                                    variant="outlined"
                                                    margin='dense'
                                                    value={accClosingAmt}
                                                    //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                                                    // onChange={e => setVoucherId(e.target.value)}
                                                    onKeyPress={(e) => (e.key === "Enter") ? accClosingAmt : ""}
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    inputProps={{ readOnly: true }}
                                                    InputProps={{
                                                        style: textFieldStyle1,
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >तपशील / Narration :</label>
                            <div className='col-sm-10'>

                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    freeSolo
                                    disableClearable
                                    onKeyDown={(e) => (e.key === " " && narration.length === 0) ? e.preventDefault() : ""}
                                    options={(narration === "") ? [] : narrationData}
                                    value={narration}
                                    onChange={(event, newValue) => {
                                        //setNarrationId(newValue.id);
                                        //console.log(newValue.narrationDetail);
                                        setNarration(newValue.narrationDetail);
                                    }}
                                    inputValue={narration}
                                    onInputChange={(event, newInputValue) => {
                                        //setNarrationId(newInputValue.id);
                                        //console.log(newInputValue);
                                        setNarration(newInputValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? narration : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => {

                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            //console.log("option........"+JSON.stringify(option.inputValue))
                                            return option.narrationId + " . " + option.narrationDetail;
                                        }
                                        // Regular option
                                        return option.narrationId + " . " + option.narrationDetail;
                                    }

                                    }
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} multiline rows={5} margin='dense' label="तपशील / Narration" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className='col-sm-12 offset-sm-2'>
                                <FormControlLabel control={<Checkbox checked={isNarrationSave} onChange={HandleNarrationCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Save Narration To Master" />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-12 offset-sm-2">
                                <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }


};

export default VoucherEntryForm;
