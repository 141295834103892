import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const getClassDivisionURL = BaseURLMaster + "classWithDivisionBySectionId/";

export const BaseURLStudentAttendance = BaseURL + "academics/catlog/";
export const studentAttendanceGetMapping = BaseURLStudentAttendance + "getStudentMonthlyAttendance";
export const studentAttendancePostMapping = BaseURLStudentAttendance + "saveStudentMonthlyAttendance/"



// ------------ Student Attendance -----------


// /institute/sss/academics/catlog/

// GetMapping     /getStudentMonthlyAttendance

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cid") long classId,@RequestParam("did") long divisionId,@RequestParam("mid") long monthId
// ------------------------------------------------------------

// PostMapping   /saveStudentMonthlyAttendance

//  private long regCode;
//     private String  studentNameMr;
//     private String  studentNameEn;
//     private  long genderId;
//     private  long rollNo;
//     private  long presentDays;
//     private  long monthId;
//     private long classId;
//     private long divisionId;
//     private long sessionYear;
//     private long sectionId;
//     private long branchId;