import React, { useEffect, useState } from 'react'
import Loader from '../../../CommonComponent/Loader';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import subjectMarkTermData from '../../../CommonComponent/LocalJSON/SubjectMarkTermData';
import makeStyles from "@mui/styles/makeStyles";
import { getClassDivisionURL } from '../../../Academic/Services/StudentAttendanceAPIURL';
import axios from 'axios';
import { getStudentList } from '../../../CommonServices/LeavingCertificateAPIURL';
import { getSubjectDataAPIURL } from '../../../Library/Acquisition/Services/AccessionEntryAPIURL';
import { getSubjectObsMasterAPIURL } from '../../Services/SubjectObsMasterAPIURL';
import { toast } from 'react-toastify';
import { saveSubjectWiseObservationAPIURL, getSubjectWiseObservationAPIURL, getClassDivSubAndTermWiseSubWiseObsAPIURL, getStudentsByClassDivAPIURL } from '../../Services/AfterExamAPIURL';
import SubjectWiseObsTable from './SubjectWiseObsTable';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const SubjectWiseObservation = () => {
    const titleId = "Subject Wise Observation";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    // const [open, setOpen] = useState(false);
    // const [options, setOptions] = useState([]);
    // const loading = open && options.length === 0;

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "subWiseObsSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "subWiseObsUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [termObj, setTermObj] = useState('');
    const [observationData, setObservationData] = useState([]);
    const [observation, setObservation] = useState('');

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                }).catch(err => {
                    console.log("Get class-div err " + err);
                })
        }

        await axios.get(getSubjectDataAPIURL)
            .then(res => {
                setSubjectData(res.data);
            }).catch(err => {
                console.log("get subject list err " + err);
            })
    }

    const getStudentListByClassDiv = async (cId, dId) => {
        //setLoaderOption(true);
        await axios.get(`${getStudentsByClassDivAPIURL}?classId=${cId}&divId=${dId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                //console.log(cId + "=========" + dId);
                //const studentsByClassDivData = response.data.filter(e => e.currentClassId == cId && e.currentDivisionId == dId)
                //console.log(JSON.stringify(studentsByClassDivData));
                setStudentData(response.data);
                setLoaderOption(false);
            }).catch(err => {
                console.log("Get student list " + err)
            })
    }

    // useEffect(() => {
    //     let active = true;

    //     if (!loading) {
    //         return undefined;
    //     }

    //     (async () => {
    //         await sleep(1e3); // For demo purposes.

    //         if (active) {
    //             setOptions([...studentData]);
    //         }
    //     })();

    //     return () => {
    //         active = false;
    //     };
    // }, [loading]);

    // useEffect(() => {
    //     if (!open) {
    //         setOptions([]);
    //     }
    // }, [open]);

    const getObsDataByClassAndSubject = async (subId) => {
        if (classDivObj != null) {
            await axios.get(`${getSubjectObsMasterAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    const filterData = res.data.filter(e => e.classId == classDivObj.classId && e.subjectId == subId)
                    //console.log(JSON.stringify(filterData))
                    setObservationData(filterData);
                }).catch(err => {
                    console.log("get sub obs master " + err)
                })
        }
    }

    const getSavedObservationData = async (termId) => {
        if (subjectObj != null && classDivObj != null) {
            //console.log(`${getClassDivSubAndTermWiseSubWiseObsAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&subId=${subjectObj.id}`)
            await axios.get(`${getClassDivSubAndTermWiseSubWiseObsAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&subId=${subjectObj.id}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Get Observation " + err);
                })
        }
    }

    const getUpdatedObsTableData = async () => {
        if (subjectObj != null && classDivObj != null && termObj != '') {
            //console.log(`${getClassDivSubAndTermWiseSubWiseObsAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&subId=${subjectObj.id}`)
            await axios.get(`${getClassDivSubAndTermWiseSubWiseObsAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&subId=${subjectObj.id}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Get Observation " + err);
                })
        }
    }

    const clearData = () => {
        setClassDivObj(null);
        setSubjectObj(null);
        setStudentData([]);
        setStudentObj(null);
        setTermObj('');
        setObservation("");
        setObservationData([]);
        setData([]);
        setFilteredData([]);
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "regNo": studentObj.regNo,
            "termId": termObj,
            "subjectId": subjectObj.id,
            "observation": observation,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.post(saveSubjectWiseObservationAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Save subject wise obs " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "regNo": studentObj.regNo,
            "termId": termObj,
            "subjectId": subjectObj.id,
            "observation": observation,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.post(saveSubjectWiseObservationAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    getUpdatedObsTableData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Save subject wise obs " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    // useEffect(() => {
    //     function handlekeydownEvent(event) {
    //         if (event.key === "Enter") {
    //             console.log("Enter is pressed!")
    //             event.preventDefault();

    //             let btn1 = document.querySelector('.subWiseObsSaveBtn')
    //             if (btn1 !== null) {
    //                 btn1.click();
    //             }
    //         }
    //     }
    //     document.addEventListener('keypress', handlekeydownEvent)
    //     return () => {
    //         document.removeEventListener('keypress', handlekeydownEvent)
    //     }
    // }, []);

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        else if (state.button == 2) {
            updateBtnClick();
        }
    }

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Student`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Observation`, key: 'observation' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className='row'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Class-Division :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={classDivData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setClassDivObj(newValue);
                                                setSubjectObj(null);
                                                setStudentData([]);
                                                setStudentObj(null);
                                                setTermObj('');
                                                setObservation('');
                                                setObservationData([]);
                                                setData([]);
                                                setFilteredData([]);
                                                if (newValue != null) {
                                                    getStudentListByClassDiv(newValue.classId, newValue.divisionId);
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? classDivObj : ""}
                                            value={classDivObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Subject :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={subjectData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setSubjectObj(newValue);
                                                setStudentObj(null);
                                                setTermObj('');
                                                setObservation('');
                                                setObservationData([]);
                                                setData([]);
                                                setFilteredData([]);
                                                if (newValue != null) {
                                                    getObsDataByClassAndSubject(newValue.id);
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? subjectObj : ""}
                                            value={subjectObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                    <div className='col-sm-2'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                size='small'
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                required
                                                id="demo-simple-select"
                                                value={termObj}
                                                label="Term"
                                                onChange={(e) => {
                                                    setTermObj(e.target.value);
                                                    setStudentObj(null);
                                                    setObservation('');
                                                    setData([]);
                                                    setFilteredData([]);
                                                    getSavedObservationData(e.target.value);
                                                }}
                                            >
                                                {subjectMarkTermData.filter(e => e.id != 3).map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                    <div className="col-sm-4">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            // open={open}
                                            // onOpen={() => {
                                            //     setOpen(true);
                                            // }}
                                            // onClose={() => {
                                            //     setOpen(false);
                                            // }}
                                            // loading={loading}
                                            options={studentData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.regNo + " - " + ((authUser.branchMedium == 1) ? option.studentNameMr + " " + option.classMr + " " + option.divisionMr : option.studentNameEn + " " + option.classEn + " " + option.divisionEn)}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                console.log("student obj ===  ", newValue)
                                                setStudentObj(newValue);
                                                setObservation('');
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                            value={studentObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Student" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                // <TextField {...params}
                                                //     InputProps={{
                                                //         ...params.InputProps,
                                                //         endAdornment: (
                                                //             <React.Fragment>
                                                //                 {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                //                 {params.InputProps.endAdornment}
                                                //             </React.Fragment>
                                                //         ),
                                                //     }}
                                                //     margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                //     required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Observation :</label>
                                    <div className="col-sm-7">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            freeSolo
                                            disableClearable
                                            onKeyDown={(e) => (e.key === " " && observation.length === 0) ? e.preventDefault() : ""}
                                            onKeyPress={(e) => (e.key == "Enter") ? observation : ""}
                                            options={(observation == "") ? [] : observationData}
                                            value={observation}
                                            onChange={(event, newValue) => {
                                                setObservation(((studentObj != null) ? ((studentObj.genderId == 1) ? newValue.boysObservation : newValue.girlsObservation) : ''));
                                            }}
                                            inputValue={observation}
                                            onInputChange={(event, newInputValue) => {
                                                setObservation(newInputValue);
                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => {
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    //console.log("option........"+JSON.stringify(option.inputValue))
                                                    return ((studentObj != null) ? ((studentObj.genderId == 1) ? option.boysObservation : option.girlsObservation) : '');
                                                }
                                                // Regular option
                                                return ((studentObj != null) ? ((studentObj.genderId == 1) ? option.boysObservation : option.girlsObservation) : '');
                                            }}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params}
                                                    multiline
                                                    rows={2}
                                                    margin='dense' label="Observation" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3">
                                {(() => {
                                    if (data.length != 0 || filteredData.length != 0 || termObj != '') {
                                        return (
                                            <SubjectWiseObsTable
                                                TableHeading={TableHeading}
                                                data={data}
                                                filteredData={filteredData}
                                                setFilteredData={setFilteredData}
                                                getData={getData}
                                                setId={setId}
                                                setClassDivObj={setClassDivObj}
                                                classDivData={classDivData}
                                                setTermObj={setTermObj}
                                                setStudentObj={setStudentObj}
                                                studentData={studentData}
                                                setObservation={setObservation}
                                                clearData={clearData}
                                                setSaveBtn={setSaveBtn}
                                                setUpdateBtn={setUpdateBtn}
                                                displayNoneBtnStyle={displayNoneBtnStyle}
                                                updateBtnStyle={updateBtnStyle}
                                            />
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default SubjectWiseObservation