import React, { useState } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { getMinorityWiseSchoolStatisticsReport, getCategoryWiseSchoolStatisticsReport } from '../Services/UdiesEReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const SchoolStatistics = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "School Statistics";

    const [loaderOption, setLoaderOption] = useState(false);

    const [optionId, setOptionId] = useState('');


    const printMinorityWiseSchoolStatiscticsReport = async () => {
        setLoaderOption(true);
        await axios.get(`${getMinorityWiseSchoolStatisticsReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data) 
                    setLoaderOption(false);
                    toast.warn("Data not exists.")

                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("MinorityWise school statistics Report failed: " + error)
            })
    }

    const printCategoryWiseSchoolStatiscticsReport = async () => {
        setLoaderOption(true);
        await axios.get(`${getCategoryWiseSchoolStatisticsReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data) 
                    setLoaderOption(false);
                    toast.warn("Data not exists.")

                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("CategoryWise school statistics Report failed: " + error)
            })
    }

    const printSchoolStatisticsReport = (e) => {
        e.preventDefault();
        if (optionId == 1) {
            printMinorityWiseSchoolStatiscticsReport();
        }
        else if (optionId == 2) {
            printCategoryWiseSchoolStatiscticsReport();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            <form onSubmit={printSchoolStatisticsReport}>
                                <div className='row'>
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={optionId}
                                                label="Select Option"
                                                onChange={(e) => {
                                                    setOptionId(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={1}>Minority Wise</MenuItem>
                                                <MenuItem value={2}>Category Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-1">
                                        <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default SchoolStatistics