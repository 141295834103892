import React, { useState, useEffect } from 'react'
import TitleLabel from '../../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getRegisterTypeDataAPIURL } from '../../LibraryServices/RegisterTypeMasterAPIURL';
import { getTitleMasterData } from '../../LibraryServices/TitleMasterAPIURL';
import { BaseURL, BaseURLMaster, dBaseURL } from '../../../CommonServices/APIURL';
import { getMemberTypeDataAPIURL } from '../../LibraryServices/MemberTypeMasterAPIURL';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment/moment';
import { getAllAccessionDataByRegisterType } from '../Services/BookIssueReturnAPIURL';
import PersonIcon from '@mui/icons-material/Person';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { getBookBankGrouDataAPIURL, saveBookBankIssueAPIURL, returnBookBankIssueAPIURL, getBookBankIssueDetailsAPIURL } from '../Services/BookBankIssueAPIURL';
import BookBankIssueTable from './BookBankIssueTable';


function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const BookBankIssue = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const { acId, EditFlag } = useParams();

    const [titleId, setTitleId] = useState('Book Bank Issue');

    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [
        { label: 'Acc No', key: 'accNo' },
        { label: `Title`, key: 'titleName' },
        { label: `Author`, key: 'authorName' },
        { label: `Publisher`, key: 'publisherName' },
        { label: `Issue Date`, key: 'issueDate' },
        { label: 'Edit', value: 'edit', isAction: true },
        // { label: 'Status', value: 'status', isAction: true }
    ];

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "bookBanklIssueSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "bookbankIssueUpdateBtn btn btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(updateBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    const [id, setId] = useState('');
    const [groupCode, setGroupCode] = useState('');
    const [groupData, setGroupData] = useState([]);
    const [groupObj, setGroupObj] = useState(null);
    const [memberTypeList, setMemberTypeList] = useState([]);
    const [memberTypeData, setMemberTypeData] = useState([]);
    const [memberType, setMemberType] = useState('');
    const [memberName, setMemberName] = useState('Member');
    const [memberTypeName, setMemberTypeName] = useState(null);
    const [memberId, setMemberId] = useState('');
    const [RegisterTypeObj, setRegisterTypeObj] = useState(null);
    const [registerTypeData, setRegisterTypeData] = useState([]);
    const [AccessionData, setAccessionData] = useState([]);
    const [titleData, setTitleData] = useState([]);
    const [titleObj, setTitleObj] = useState(null);
    const [accNo, setAccNo] = useState('');
    const [author, setAuthor] = useState('');
    const [publisher, setPublisher] = useState('');
    const [isRetired, setIsRetired] = useState(0);
    const [isLeft, setIsLeft] = useState(0);
    const [issueDate, setIssueDate] = useState(null);
    const [returnDate, setReturnDate] = useState(new Date());

    console.log(issueDate)

    useEffect(() => {
        getData();
    }, [authUser])

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(2e3); // For demo purposes.

            if (active) {
                setOptions([...memberTypeData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const getTitleListByRegisterId = async (reId) => {
        console.log(`${getAllAccessionDataByRegisterType}?registerTypeId=${reId}&bid=${authUser.branchId}`)
        await axios.get(`${getAllAccessionDataByRegisterType}?registerTypeId=${reId}&bid=${authUser.branchId}`)
            .then(res => {
                setAccessionData(res.data);
            })
    }

    const getBookDetailsByAccNo = (acNo) => {
        setTitleObj(AccessionData.find(item => item.accNo == acNo))
        AccessionData.map(e => {
            if (e.accNo == acNo) {
                setAuthor(e.authorName);
                setPublisher(e.publisherName);

                let upadatedData = data.filter(e => {
                    return e.memberId == memberId && e.memberType == memberType && e.accNo == accNo && e.bookBankGroupId == groupObj.id
                })
                //console.log(upadatedData)

                if (e.status == 3 || e.status == 4 || e.status == 5) {
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(true);
                }
                else if (e.status == 2 || e.status == 6) {
                    if (upadatedData.length == 1 && ((memberType == 1) ? isRetired : isLeft) == 1) {
                        setSaveBtnDisabled(true);
                        setUpdateBtnDisabled(false);
                    }
                    else if (upadatedData.length == 0 && ((memberType == 1) ? isRetired : isLeft) == 1) {
                        setSaveBtnDisabled(true);
                        setUpdateBtnDisabled(true);
                        toast.error("Validity Expired.")
                    }
                    else if (upadatedData.length == 1) {
                        setSaveBtnDisabled(true);
                        setUpdateBtnDisabled(false);
                    }
                    else {
                        setSaveBtnDisabled(true);
                        setUpdateBtnDisabled(true);
                    }
                }
                else if (e.status == 1) {
                    if (((memberType == 1) ? isRetired : isLeft) == 0) {
                        setSaveBtnDisabled(false);
                        setUpdateBtnDisabled(true);
                    }
                    else if (((memberType == 1) ? isRetired : isLeft) == 1) {
                        setSaveBtnDisabled(true);
                        setUpdateBtnDisabled(true);
                        toast.error("Validity Expired.")
                    }
                }
                else {
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        })
    }

    const getBookDetailsByTitleAccNo = (newValue) => {
        setAccNo(newValue.accNo);
        setAuthor(newValue.authorName);
        setPublisher(newValue.publisherName);
        setReturnDate(new Date());

        let upadatedData = filteredData.filter(e => {
            return e.memberId == memberId && e.memberType == memberType && e.accNo == newValue.accNo && e.bookBankGroupId == groupObj.id
        })
        console.log(upadatedData)

        if (newValue.status == 3 || newValue.status == 4 || newValue.status == 5) {
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(true);

        }
        else if (newValue.status == 2 || newValue.status == 6) {
            if (upadatedData.length == 1 && ((memberType == 1) ? isRetired : isLeft) == 1) {
                setSaveBtnDisabled(true);
                setUpdateBtnDisabled(false);

            }
            else if (upadatedData.length == 0 && ((memberType == 1) ? isRetired : isLeft) == 1) {
                setSaveBtnDisabled(true);
                setUpdateBtnDisabled(true);

                toast.error("Validity Expired.")
            }
            else if (upadatedData.length == 1) {
                setSaveBtnDisabled(true);
                setUpdateBtnDisabled(false);
            }
            else {
                setSaveBtnDisabled(true);
                setUpdateBtnDisabled(true);
            }
        }
        else if (newValue.status == 1) {
            if (((memberType == 1) ? isRetired : isLeft) == 0) {
                setSaveBtnDisabled(false);
                setUpdateBtnDisabled(true);

            }
            else if (((memberType == 1) ? isRetired : isLeft) == 1) {
                setSaveBtnDisabled(true);
                setUpdateBtnDisabled(true);
                toast.error("Validity Expired.")
            }
        }
        else {
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getData = async () => {
        await axios.get(`${getMemberTypeDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                let newMemberList = res.data
                newMemberList.map((item, index) => {
                    if (item.typeId == 1) {
                        newMemberList[index] = ({
                            ...item, getMapping: "employee/getBranchWiseEmployeeList",
                            sectionId: ""
                        })
                    }
                    else if (item.typeId == 2) {
                        newMemberList[index] = ({
                            ...item, getMapping: "administration/LC/getStudentLcBaseData",
                            sectionId: 3
                        })
                    }
                    else if (item.typeId == 3) {
                        newMemberList[index] = ({
                            ...item, getMapping: "administration/LC/getStudentLcBaseData",
                            sectionId: 4
                        })
                    }

                })
                setMemberTypeList(newMemberList);
            })

        await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                setRegisterTypeData(res.data);
            })

        await axios.get(`${getBookBankGrouDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                setGroupData(res.data);
            })
    }

    const mapData = (mtId) => {
        setIssueDate(new Date())
        //console.log(mtId)
        //setMemberTypeData([]);
        memberTypeList.map((item) => {
            if (item.typeId == mtId) {
                setMemberName(item.typeName)
                if (item.typeId == 1) {
                    axios.get(`${BaseURLMaster}${item.getMapping}?bid=${authUser.branchId}`)
                        .then((response) => {
                            setMemberTypeData(response.data)
                        })
                }
                else {
                    console.log(`${dBaseURL}${item.getMapping}?sid=${item.sectionId}&bid=${authUser.branchId}`)
                    axios.get(`${dBaseURL}${item.getMapping}?sid=${item.sectionId}&bid=${authUser.branchId}`)
                        .then((response) => {
                            setMemberTypeData(response.data)
                        })

                }

            }
        })

        axios.get(`${getBookBankIssueDetailsAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then(res => {
                let FilteredDataByMemberType = res.data.filter(e => {
                    return e.memberType == mtId
                })
                //console.log(FilteredDataByMemberType)
                if (!FilteredDataByMemberType || mtId == '') {
                    setData([]);
                    setFilteredData([]);
                }
                else {
                    setData(FilteredDataByMemberType);
                    setFilteredData(FilteredDataByMemberType);
                }
            })

    }

    const getStudentEmployeeById = (sId) => {
        if (memberType == 1) {
            setMemberTypeName(memberTypeData.find(e => e.employeeId == sId))

            memberTypeData.map(e => {
                if (e.employeeId == sId) {
                    setIsRetired(e.isRetired);
                }
            })
        }
        else if (memberType == 2 || memberType == 3) {
            setMemberTypeName(memberTypeData.find(e => e.regCode == sId))

            memberTypeData.map(e => {
                if (e.regCode == sId) {
                    setIsLeft(e.isLeft);
                }
            })
        }
    }

    const clearData = () => {
        setId('');
        setGroupCode('');
        setGroupObj(null);
        //setMemberTypeList([]);
        setMemberTypeData([]);
        setMemberType('');
        setMemberName('Member');
        setMemberTypeName(null);
        setMemberId('');
        setRegisterTypeObj(null);
        setAccessionData([]);
        setTitleObj(null);
        setAccNo('');
        setAuthor('');
        setPublisher('');
        setIsRetired(0);
        setIsLeft(0);
        setIssueDate(null);
        setReturnDate(null);
    }

    const issueBookFromBookbankIssue = async () => {
        setLoaderOption(true);
        if (moment(issueDate).format("DD/MM/YYYY") != 'Invalid date') {
            const saveObj = {
                "memberType": memberType,
                "memberId": memberId,
                "registerTypeId": RegisterTypeObj.id,
                "accNo": accNo,
                "issueDate": moment(issueDate).format("YYYY-MM-DD"),
                "returnDate": null,
                "bookBankGroupId": groupObj.id,
                "sessionYear": authUser.sessionYear,
                "branchId": authUser.branchId
            }
            console.log(JSON.stringify(saveObj))
            await axios.post(saveBookBankIssueAPIURL, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        getData();
                        clearData();
                        setLoaderOption(false);
                        toast.success("Book Issued Successfully.")
                    }
                }).catch(err => {
                    console.log("Book Bank Issue error:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please chcek.")
        }
    }

    const returnBookFromBookbankIssue = async () => {
        setLoaderOption(true);
        if (moment(issueDate).format("DD/MM/YYYY") != 'Invalid date' && moment(returnDate).format("DD/MM/YYYY") != 'Invalid date') {
            const saveObj = {
                "memberType": memberType,
                "memberId": memberId,
                "registerTypeId": RegisterTypeObj.id,
                "accNo": accNo,
                "issueDate": moment(issueDate).format("YYYY-MM-DD"),
                "returnDate": (returnDate == null) ? moment(new Date()).format("YYYY-MM-DD") : moment(returnDate).format("YYYY-MM-DD"),
                "bookBankGroupId": groupObj.id,
                "sessionYear": authUser.sessionYear,
                "branchId": authUser.branchId
            }
            console.log(JSON.stringify(saveObj))
            await axios.post(returnBookBankIssueAPIURL, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        getData();
                        clearData();
                        setLoaderOption(false);
                        toast.success("Book Return Successfully Done.")
                    }
                }).catch(err => {
                    console.log("Book Bank return error:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please chcek.")
        }
    }

    const state = {
        button: 1
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.button === 1) {
            issueBookFromBookbankIssue();
        }
        if (state.button === 2) {
            returnBookFromBookbankIssue();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Book-bank Group :</label>
                                    {/* <div className="" style={{ width: "120px" }}>
                                <TextField
                                    fullWidth
                                    required
                                    style={{ marginRight: "4px" }}
                                    onKeyDown={(e) => (e.key === " " && groupCode.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    margin='dense'
                                    size='small'
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    onKeyPress={(e) => (e.key == "Enter") ? groupCode : ""}
                                    label="Code"
                                    value={groupCode}
                                    onChange={(e) => {
                                        setGroupCode(e.target.value);
                                        setRegisterTypeObj(null);
                                        setAccessionData([]);
                                        setTitleObj(null);
                                        setAccNo('');
                                        setAuthor('');
                                        setPublisher('');
                                        setGroupObj(null);
                                    }}
                                //onBlur={(e) => getBookDetailsByAccNo(e.target.value)}
                                />
                            </div> */}

                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={groupData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={(option) => option.name}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setGroupObj(newValue);
                                                setRegisterTypeObj(null);
                                                setGroupCode('');
                                                setAccessionData([]);
                                                setTitleObj(null);
                                                setAccNo('');
                                                setAuthor('');
                                                setPublisher('');
                                                // setIssueDate(null);
                                                // setReturnDate(null);
                                                setSaveBtnDisabled(false);
                                                setUpdateBtnDisabled(true);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? groupObj : ""}
                                            value={groupObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Book-bank Group" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required />
                                            )}
                                        />
                                    </div>
                                </div>

                                {/* <div className='my-2 mt-4' style={{ backgroundColor: "#fff", borderRadius: "5px", boxShadow: "0px 5px 12px grey", marginTop: "10px", minHeight: "300px" }}> */}

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member Type :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Member Type</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                margin='dense'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={memberType}
                                                label="Member Type"
                                                onChange={(e) => {
                                                    setMemberType(e.target.value);
                                                    mapData(e.target.value);
                                                    setMemberId('');
                                                    setMemberTypeName(null);
                                                    setRegisterTypeObj(null);
                                                    setAccessionData([]);
                                                    setTitleObj(null);
                                                    setAccNo('');
                                                    setAuthor('');
                                                    setPublisher('');
                                                    // setIssueDate(null);
                                                    // setReturnDate(null);
                                                    setSaveBtnDisabled(false);
                                                    setUpdateBtnDisabled(true);
                                                    //console.log(e.target.value);                                           

                                                }}
                                            >
                                                {
                                                    memberTypeList.filter((e) => e.sectionId == authUser.deptId || e.sectionId == '').sort((a, b) => a.typeId > b.typeId ? 1 : -1).map(e => {
                                                        return (
                                                            <MenuItem value={e.typeId}>{e.typeName}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member :</label>
                                    <div className="" style={{ width: "120px" }}>
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && memberId.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? memberId : ""}
                                            label="Code"
                                            value={memberId}
                                            onChange={(e) => {
                                                setMemberId(e.target.value);
                                                setMemberTypeName(null);
                                                setRegisterTypeObj(null);
                                                setAccessionData([]);
                                                setTitleObj(null);
                                                setAccNo('');
                                                setAuthor('');
                                                setPublisher('');
                                                // setIssueDate(null);
                                                // setReturnDate(null);
                                                setSaveBtnDisabled(false);
                                                setUpdateBtnDisabled(true);
                                            }}
                                            onBlur={(e) => getStudentEmployeeById(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-sm-6'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={memberTypeData}
                                            open={open}
                                            onOpen={() => {
                                                setOpen(true);
                                            }}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            loading={loading}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={(option) => {
                                                if (memberType == 1) {
                                                    return ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)
                                                }
                                                else {
                                                    return (authUser.branchMedium == 1) ? option.studentNameMr : option.studentNameEn
                                                }
                                            }}
                                            renderOption={(props, option) => (
                                                <div className='' {...props}>
                                                    <div className=''>
                                                        {
                                                            (memberType == 1)
                                                                ?
                                                                option.employeeId + ". " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)
                                                                :
                                                                (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + " " + option.currentClassMr : option.regCode + " - " + option.studentNameEn + " " + option.currentClassEn
                                                        }
                                                    </div>

                                                </div>
                                            )}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setMemberTypeName(newValue);
                                                setSaveBtnDisabled(false);
                                                setUpdateBtnDisabled(true);
                                                setMemberId('');
                                                setRegisterTypeObj(null);
                                                setAccessionData([]);
                                                setTitleObj(null);
                                                setAccNo('');
                                                setAuthor('');
                                                setPublisher('');
                                                // setIssueDate(null);
                                                // setReturnDate(null);
                                                if (newValue != null) {
                                                    if (memberType == 1) {
                                                        setMemberId(newValue.employeeId);
                                                        setIsRetired(newValue.isRetired);
                                                    }
                                                    else if (memberType == 2 || memberType == 3) {
                                                        setMemberId(newValue.regCode);
                                                        setIsLeft(newValue.isLeft);
                                                    }
                                                }

                                            }
                                            }
                                            onKeyPress={(e) => (e.key == "Enter") ? memberTypeName : ""}
                                            value={memberTypeName}
                                            renderInput={params => (
                                                <TextField {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    margin='dense' label={"Select" + " " + memberName} autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required />
                                            )}
                                        />
                                    </div>
                                </div>



                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Register Type :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={registerTypeData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.registerTypeName}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setRegisterTypeObj(newValue);
                                                setAccNo('');
                                                setAuthor('');
                                                setTitleObj(null);
                                                setPublisher('');
                                                setSaveBtnDisabled(false);
                                                setUpdateBtnDisabled(true);
                                                // setIssueDate(null);
                                                // setReturnDate(null);
                                                if (newValue != null) {
                                                    getTitleListByRegisterId(newValue.id);
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? RegisterTypeObj : ""}
                                            value={RegisterTypeObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Register Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Accession :</label>
                                    <div className="" style={{ width: "120px" }}>
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && accNo.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? accNo : ""}
                                            label="Acc No"
                                            value={accNo}
                                            onChange={(e) => {
                                                setAccNo(e.target.value);
                                                setAuthor('');
                                                setTitleObj(null);
                                                setPublisher('');
                                                setSaveBtnDisabled(false);
                                                setUpdateBtnDisabled(true);
                                                // setIssueDate(null);
                                                // setReturnDate(null);
                                            }}
                                            onBlur={(e) => getBookDetailsByAccNo(e.target.value)}
                                        />
                                    </div>

                                    <div className='col-sm-6'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={AccessionData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={(option) => option.titleName}
                                            renderOption={(props, option) => (
                                                <div className='' {...props}>
                                                    <div className=''>
                                                        {option.accNo + ". " + option.titleName} <br />
                                                        <div
                                                            className=''
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                fontSize: "12px",
                                                                flexDirection: "row",
                                                                justifyContent: "space-between",
                                                                padding: "0px 10px",
                                                                color: "#464964",
                                                                fontWeight: "550"
                                                                //gridRow: 1
                                                            }}
                                                        >
                                                            <span><PersonIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {option.authorName}</span>
                                                            <span className='ml-1 mr-1 mb-1' style={{ fontSize: "14px" }}>   |   </span>
                                                            <span><LibraryBooksIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {option.publisherName}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setTitleObj(newValue);
                                                setSaveBtnDisabled(false);
                                                setUpdateBtnDisabled(true);
                                                if (newValue == null) {
                                                    setAuthor('');
                                                    setPublisher('');
                                                    setAccNo('');
                                                    setIssueDate(null);
                                                    setReturnDate(null);
                                                }
                                                else if (newValue != null) {
                                                    getBookDetailsByTitleAccNo(newValue);
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? titleObj : ""}
                                            value={titleObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Title" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Author:</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && author.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? author : ""}
                                            label="Author"
                                            inputProps={{ readOnly: true }}
                                            value={author}
                                            onChange={(e) => setAuthor(e.target.value)}
                                        />
                                    </div>

                                    <label className='' style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Publisher:</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            style={{ marginRight: "4px" }}
                                            onKeyDown={(e) => (e.key === " " && publisher.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? publisher : ""}
                                            label="Publisher"
                                            inputProps={{ readOnly: true }}
                                            value={publisher}
                                            onChange={(e) => setPublisher(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Issue Date:</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Issue Date"
                                                value={issueDate}
                                                onChange={(newValue) => {
                                                    setIssueDate(newValue);
                                                    // setReturnDate(null);
                                                    // if (newValue == null) {
                                                    //     setReturnDate(null);
                                                    // }
                                                    // else {
                                                    //     getReturnDate(newValue);
                                                    // }
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? issueDate : ""}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <label className="" hidden={(updateBtnDisabled == true) ? true : false} style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Return Date:</label>
                                    <div className='col-sm-3' hidden={(updateBtnDisabled == true) ? true : false}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Return Date"
                                                value={returnDate}
                                                onChange={(newValue) => {
                                                    setReturnDate(newValue);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? returnDate : ""}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required={(updateBtnDisabled == false) ? true : false}
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' hidden={saveBtnDisabled} disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className='btn btn-sm btn-primary' style={{ margin: "0px 4px" }}>Issue</button>
                                        <button type='submit' hidden={updateBtnDisabled} disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className='btn btn-sm' style={{ margin: "0px 4px", backgroundColor: "#31820D", color: "#fff" }}>Return</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger' style={{ margin: "0px 4px" }}>cancel</button>
                                    </div>
                                </div>
                            </form>

                            {
                                (memberType != '')
                                    ?
                                    <div className="mt-5">
                                        <BookBankIssueTable
                                            data={data}
                                            filteredData={filteredData}
                                            TableHeading={TableHeading}
                                            setFilteredData={setFilteredData}
                                            setId={setId}
                                            setMemberType={setMemberType}
                                            setMemberId={setMemberId}
                                            setAccNo={setAccNo}
                                            setIssueDate={setIssueDate}
                                            setReturnDate={setReturnDate}
                                            setTitleObj={setTitleObj}
                                            setAuthor={setAuthor}
                                            setPublisher={setPublisher}
                                            setAccessionData={setAccessionData}
                                            memberTypeList={memberTypeList}
                                            setMemberName={setMemberName}
                                            setMemberTypeData={setMemberTypeData}
                                            setMemberTypeName={setMemberTypeName}
                                            setSaveBtnDisabled={setSaveBtnDisabled}
                                            setUpdateBtnDisabled={setUpdateBtnDisabled}
                                            setLoaderOption={setLoaderOption}
                                            setIsLeft={setIsLeft}
                                            setIsRetired={setIsRetired}
                                            setRegisterTypeObj={setRegisterTypeObj}
                                            setGroupObj={setGroupObj}
                                            groupData={groupData}
                                            registerTypeData={registerTypeData}
                                        />
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export default BookBankIssue