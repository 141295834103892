import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { getClassWithDiv } from '../../Academic/Services/StudentRollNoAPIURL';
import { getStudentAadharList, saveStudentAadharList } from '../Services/StudentAadharAPIURL';
import AadharNoAssignTextBox from './StudentAadharRow/AadharNoAssignTextBox';
import Loader from '../../CommonComponent/Loader';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    }
});



const StudentAadhar = () => {

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student - Aadhaar";

    const [loaderOption, setLoaderOption] = useState(false);
    const classes = useStyles();

    const TableHeading = [
        { label: 'Reg No', key: 'regCode' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Roll No`, key: 'rollNo' },
        { label: `Aadhaar`, key: 'aadharNo', isAction: true }
    ];

    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);


    //console.log("division data: "+JSON.stringify(divisionData));
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")

    const [tempData, setTempData] = useState([]);
    const [regNum, setRegNum] = useState('');
    const [aadhar, setAadhar] = useState('');


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setDivisionData(res.data);
            }
            )

    }


    const [filteredData, setFilteredData] = useState(null);
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentAadharList}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${classDivObj.divisionId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData = data.filter((item) =>
            item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData)
    }, [searchTerm])

    const getDivisionData = (divId) => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        //let link = getStudentAadharList + "?acyr=" + authUser.sessionYear + "&sid=" + authUser.deptId + "&bid=" + authUser.branchId + "&cdid=" + divId;
        //console.log(link);
        axios(`${getStudentAadharList}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${divId}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setTempData(res.data);
                setLoaderOption(false);
            })
    }

    useEffect(() => {
        let updatedData = tempData;

        tempData.map((e, index) => {
            if (e.regCode == regNum) {
                updatedData[index] = ({ ...e, aadharNo: aadhar })
            }

        })
        setData(updatedData);
        if (searchTerm == "") {
            setFilteredData(updatedData)
        }
    }, [searchTerm, regNum])



    const saveAadharNo = (e) => {
        e.preventDefault();
        //console.log("aaaa")

        setLoaderOption(true);

        console.log(JSON.stringify(data))
        axios.post(`${saveStudentAadharList}`, data)
            .then((response) => {
                if (response.data == "SAVED") {

                    setFilteredData([]);
                    setData([]);
                    setTempData([]);
                    setClassDivObj(null);
                    setLoaderOption(false);
                    toast.success("Aadhar No sucessfully updation done.");
                }
            })
            .catch(error => {
                console.log("Update Fire Query: " + error)
                toast.error("Something went wrong.");
            })

    }


    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (classDivObj != null || classDivObj != "") {
                    console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.studAddharBtn')
                    if (btn !== null) {
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={divisionData}
                                style={{ fontSize: '14px' }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                    getDivisionData(newValue.divisionId);
                                    setSearchTerm("");
                                    setClassDivObj(newValue);
                                    setAscending(false);
                                    setSortedBy(null);
                                }}
                                value={classDivObj}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>
                    {
                        (data == null || data == "") ?
                            <h4 className='mt-5'>No records found, Select Class-Division</h4>
                            :
                            <div>
                                <div className='row col-sm-4 my-1' style={{ float: 'right' }}>
                                    <div>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>

                                <form onSubmit={saveAadharNo}>
                                    <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                        <table className="table table-bordered ">
                                            <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData
                                                    .map((item, index) => {
                                                        return (

                                                            <tr key={index}>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regCode}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.rollNo}</td>
                                                                <AadharNoAssignTextBox initialText={item.aadharNo} onChange={(value) => {
                                                                    let updatedData = filteredData;
                                                                    updatedData[index] = ({ ...item, aadharNo: value });
                                                                    setFilteredData(updatedData);
                                                                    setData(updatedData);
                                                                    setAadhar(value);
                                                                    setRegNum(updatedData[index].regCode)
                                                                    //console.log(JSON.stringify(data));
                                                                }} />

                                                            </tr>

                                                        )
                                                    })}
                                            </tbody>
                                            <tfoot style={{ padding: "0px" }}>
                                                {/* <TablePagination
                                    style={{ padding: "0px" }}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                    onPageChange={onPageChange}
                                    classes={{ toolbar: classes.toolbar, caption: classes.caption }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }} /> */}
                                            </tfoot>
                                        </table>


                                    </div>

                                    <div className='mt-4'>
                                        <button className='studAddharBtn btn btn-primary btn-sm mb-2' type='submit'><AddIcon fontSize="small" />Save Changes</button>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default StudentAadhar