import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../../CommonComponent/Loader';
import { ClassMasterGetMapping } from '../../../CommonServices/ClassMasterAPIURL';
import { printClassWiseEndowmentReportAPIURL } from '../Services/EndowmentReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';

const ClassWiseEndowmentReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "ClassWise Endowment Report";

    const [loaderOption, setLoaderOption] = useState(false);
    const [optionId, setOptionId] = useState(1);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(`${ClassMasterGetMapping}`)
            .then((res) => {
                setClassData(res.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const getClassWiseEndowment = async () => {
        setLoaderOption(true);
        await axios.get(`${printClassWiseEndowmentReportAPIURL}?sessionYear=${authUser.sessionYear}&classId=${classObj.id}`)
            .then(res => {
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Endowment for this class not found.")
                }
                else if (res.data != null) {
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                    setLoaderOption(false);
                }
            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                            <div className="row my-1">
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={classData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        value={classObj}
                                        onChange={(event, newValue) => {
                                            setClassObj(newValue);
                                        }}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-1">
                                    <button type="button" disabled={(classObj == null || classObj == "") ? true : false} onClick={() => getClassWiseEndowment()} className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default ClassWiseEndowmentReport