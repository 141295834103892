import { BaseURL, dBaseURL } from "../../CommonServices/APIURL"

//localhost:8080/institute/sss/hostel/transaction/getAllStudentLeaveList?bid=36&year=2022
export const getStudentLeaveAPIURL = BaseURL + "hostel/transaction/getAllStudentLeaveList";

// /localhost:8080/institute/sss/hostel/transaction/saveStudentLeave
// {    
//     "regNo": 1069,
//     "leaveReason": "leaveReason",
//     "dateFrom": "2023-03-01",
//     "dateTo": "2023-03-04",
//     "isSanction": 1,
//     "sessionYear":2022,
//     "branchId": 36
// }
export const saveStudentLeaveAPIURL = BaseURL + "hostel/transaction/saveStudentLeave";


//localhost:8080/institute/sss/hostel/transaction/updateStudentLeave
export const updateStudentLeaveAPIURL = BaseURL + "hostel/transaction/updateStudentLeave";

// /localhost:8080/institute/sss/hostel/transaction/deleteStudentLeave?id=1
export const deleteStudentLeaveAPIURL = BaseURL + "hostel/transaction/deleteStudentLeave";