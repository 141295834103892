import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleLabel from "../../../CommonComponent/TitleLabel";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from "../../../CommonComponent/Loader";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBoardExamEntryDetailsAPIURL } from "../../Services/BoardExamMarkEntryAPIURL";

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const BoardExamMarkEntryTable = () => {
    const titleId = "Board Exam Mark Entry";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const TableHeading = [
        { label: 'Seat No', key: 'seatNo' },
        { label: `Reg No`, key: 'regNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
        { label: `Out of Marks`, key: 'totalMaxMark' },
        { label: 'Edit', value: 'edit', isAction: true },
        // { label: 'Status', value: 'status', isAction: true }
    ];


    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.sessionnYear != '' && authUser.deptId != null && authUser.branchId != null) {
            //console.log(`${getBoardExamEntryDetailsAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getBoardExamEntryDetailsAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Board Exam Mark entry get" + err);
                })
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) => (
            item.seatNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.totalMaxMark.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.divNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.divNameEn.toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData)
    }, [searchTerm])

    const getStudentDetails = async (item, flag) => {
        navigate(`/Home/ex539F/${item}/${flag}`)
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <div className=''>
                                <button className='btn btn-primary btn-sm mb-3' style={{ float: 'left' }} onClick={() => navigate(`/Home/ex539F/0/1`)} ><AddIcon fontSize="small" />New Exam Entry</button>
                            </div>
                            <div className='row mt-2' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className='table-responsive'>
                                    <table className="table table-bordered">
                                        <thead className="table-Default">
                                            <tr>
                                                {TableHeading.map((item, index) => {
                                                    return (
                                                        <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .reverse().map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.seatNo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.regNo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>{item.classNameMr + " " + item.divNameMr}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>{item.totalMaxMark}</td>
                                                            <td style={{ width: "100px" }}>
                                                                <Tooltip title="Edit">
                                                                    <button className='btn btn-primary btn-sm mx-1 p-1'
                                                                        onClick={() => getStudentDetails(item.seatNo, 2)} data-bs-toggle="tooltip"
                                                                    //disabled={(authUser.roleId === 4 || authUser.roleId === 3) ? true : false}
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </button>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <TablePagination
                                                    style={{ padding: "0px" }}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    count={data.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onRowsPerPageChange={onRowsPerPageChange}
                                                    onPageChange={onPageChange}
                                                    labelRowsPerPage="Rows Per Page :"
                                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default BoardExamMarkEntryTable