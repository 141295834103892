import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { LoginURL } from '../../CommonServices/LoginAPIURL';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { Box } from '@mui/material';

const SansthaAdminModal = (props) => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const handleClose = () => {
        setPassword('');
        props.setShowModal(false);
        setPath(false);
        navigate('/Home/ac04/0')
    };

    const [password, setPassword] = useState('')

    const [path, setPath] = useState(false);

    useEffect(() => {
        props.sendingMenu.map(e => {
            if (e.pathName == "/Home/sacc618/") {
                setPath(true);
            }
            else {
                setPath(false);
            }
        })
    }, [])


    const loginAdmin = async (e) => {
        e.preventDefault();
        // if (password != "") {
        console.log(`${LoginURL}?username=${authUser.username}&password=${password}`)
        axios
            .get(`${LoginURL}?username=${authUser.username}&password=${password}`)
            .then((response) => {
                if (response.data == "Username and Password not valid") {
                    toast.error("Invalid User Id or Password");
                }
                else if (response.data != null) {
                    setPassword('');
                    props.setShowModal(false);
                    setPath(false);
                    toast.success("Login Successfully.");
                    navigate('/Home/sacc001/')
                }
                else {
                    console.log("else")
                    toast.error("Operation failed.");
                }

            })
            .catch((error) => {
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
        // }
        // else {
        //     toast.warn("Password field should not be null or blank.")
        // }

    }

    useEffect(() => {
        if (authUser.deptId == 12 && authUser.branchId == 50 && path == true) {

            function handlekeydownEvent(event) {
                if (event.key === "Enter") {
                    // if (password != "") {
                    console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.sansthaAdminModalBtn')
                    //console.log(btn)
                    if (btn !== null) {
                        btn.click();
                    }
                    //}
                }
            }

            document.addEventListener('keypress', handlekeydownEvent)
            return () => {
                document.removeEventListener('keypress', handlekeydownEvent)
            }
        }
    }, [authUser]);

    return (
        <>

            <Modal show={props.showModal} onHide={handleClose} size="sm">
                <Form onSubmit={loginAdmin}>
                    <Modal.Body>
                        <Form.Group className="mb-2" controlId="exampleForm.ControlInput2">
                            <Form.Label style={{ fontSize: "15px", fontWeight: "600" }}>Password :</Form.Label>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <LockRoundedIcon sx={{ color: '#0776EA', mr: 1, my: 0.5 }} />
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onKeyPress={(e) => (e.key == "Enter") ? password : ''}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete="off"
                                    style={{ fonSize: "14px", fontWeight: "600" }}
                                    required={true}
                                />
                            </Box>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type='submit' className='sansthaAdminModalBtn'>
                            Login
                        </Button>
                        <Button variant="danger" type='button' onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer position="top-right" theme="colored" />
        </>
    );
}

export default SansthaAdminModal

