import React, { useState } from 'react';
import { useSelector } from "react-redux";

const StudentCwsnSelectOption = ({ cwsnData, initialText, onChange }) => {
    const authUser = useSelector((state) => state.user.value);
    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);
    const [cwsnName, setCwsnName] = useState(cwsnData.find((e) => e.id == text));

    return (
        //isEdit ?
        <td style={{ width: '170px' }} onClick={() => setEdit(true)}>
            <select value={text}
                onChange={(e) => { setText(e.target.value); setCwsnName(cwsnData.find((a) => a.id == e.target.value)); onChange(e.target.value) }}
                onKeyPress={(e)=>(e.key == "Enter") ? onChange(e.target.value) : ""}
                onFocus={() => setEdit(true)}
                className={(isEdit == true) ? 'form-select form-select-sm' : ''}
                style={(isEdit == true) ? { width: "130px", fontSize: "14px", fontWeight: "500" } :
                    { width: "130px", fontSize: "14px", fontWeight: "500", border: "0px", appearance: "none" }
                }
                onBlur={() => { setEdit(false) }}
            //autoFocus
            >
                {cwsnData.map((e) => {
                    return (
                        <option value={e.id}>
                            {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}
                        </option>
                    )
                })}

            </select>
        </td>

        // :
        // <td style={{ width: '170px', fontSize: "14px", fontWeight: "500" }} onClick={() => setEdit(true)}>{(authUser.branchMedium == 1) ? cwsnName.nameMr : cwsnName.nameEn}</td>
    )
}

export default StudentCwsnSelectOption