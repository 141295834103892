import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel'
import { ToastContainer, toast } from 'react-toastify';
import SansthaConsolidatedTable1 from './SansthaConsolidatedAllTables/SansthaConsolidatedTable1';
import SansthaConsolidatedTable2 from './SansthaConsolidatedAllTables/SansthaConsolidatedTable2';
import SansthaConsolidatedTable3 from './SansthaConsolidatedAllTables/SansthaConsolidatedTable3';
import SansthaConsolidatedTable4 from './SansthaConsolidatedAllTables/SansthaConsolidatedTable4';
import SansthaConsolidatedTable5 from './SansthaConsolidatedAllTables/SansthaConsolidatedTable5';
import SansthaConsolidatedTable6 from './SansthaConsolidatedAllTables/SansthaConsolidatedTable6';
import {
    getSansthaConsolidatedBudgetNoTwoB, printSansthaConsolidatedBudgetNoTwoBReport,
    getSansthaConsolidatedBudgetNoOneA, printSansthaConsolidatedBudgetNoOneAReport,
    getSansthaConsolidatedBudgetNoOneB, printSansthaConsolidatedBudgetNoOneBReport,
    getSansthaConsolidatedObjectOfTrust, printSansthaConsolidatedObjectOfTrustReport,
    getSansthaConsolidatedMiscellaneousPayment, printSansthaConsolidatedMiscellaneousPaymentReport,
    getSansthaConsolidatedBudgetDifferenceAmount, printSansthaConsolidatedBudgetDifferenceAmount
} from './Services/BudgetReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import moment from 'moment';

const SansthaConsolidatedReport = () => {
    const titleId = "Sanstha Consolidated Report"
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);
    const [option, setOption] = useState(1);

    //btn style states
    const [btn1, setBtn1] = useState('#15A7C1');
    const [btn2, setBtn2] = useState('#063ACF');
    const [btn3, setBtn3] = useState('#063ACF');
    const [btn4, setBtn4] = useState('#063ACF');
    const [btn5, setBtn5] = useState('#063ACF');
    const [btn6, setBtn6] = useState('#063ACF');

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [receiptData, setReceiptData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);

    //total amounts states
    const [totalBudgetTwoB, setTotalBudgetTwoB] = useState(0);
    const [totalBookMapsAmt, setTotalBookMapsAmt] = useState(0);
    const [totalFurnitreAmt, setTotalFurnitreAmt] = useState(0);
    const [totalSalaryAmt, setTotalSalaryAmt] = useState(0);
    const [totalPaymentObjOfTrustAmt, setTotalPaymentObjOfTrustAmt] = useState(0);
    const [totalPaymentMiscellanious, setTotalPaymentMiscellanious] = useState(0);
    const [totalReceiptAmt, setTotalReceiptAmt] = useState(0);
    const [totalPaymentAmt, setTotalPaymentAmt] = useState(0);

    const [futureYear, setFutureYear] = useState('');
    const currentYear = new Date();

    useEffect(() => {
        if (authUser.sessionYear < moment(currentYear).format("YYYY")) {
            let newYear = moment(authUser.fromDate).format("YY")
            //console.log(newYear)
            setFutureYear(parseInt(newYear) + 1);
        }
        else {
            setFutureYear(moment(currentYear).add(1, 'year').format("YY"))
        }
    }, [authUser])


    //get flag from session
    useEffect(() => {
        setOption(JSON.parse(window.sessionStorage.getItem("Flag")));
    }, []);
    //store flag to session
    useEffect(() => {
        window.sessionStorage.setItem("Flag", option);
    }, [option]);

    useEffect(() => {
        if (authUser.sessionYear != "" && authUser.deptId != null && authUser.branchId != null) {
            getSansthaConsolidatedreport(option);
        }
    }, [authUser])

    //get Consolidated Budget Data
    const getSansthaConsolidatedreport = async (val) => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        if (val == 2) {
            setBtn1('#063ACF');
            setBtn2('#15A7C1');
            setBtn3('#063ACF');
            setBtn4('#063ACF');
            setBtn5('#063ACF');
            setBtn6('#063ACF');
            setOption(2);

            await axios.get(`${getSansthaConsolidatedBudgetNoOneA}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        setLoaderOption(false);
                        setData(res.data);
                        setFilteredData(res.data);

                        const TotalBookMaps = res.data.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
                        setTotalBookMapsAmt(parseFloat(TotalBookMaps))

                        const TotalFurnitureMaps = res.data.reduce((prev, curr) => prev + parseFloat(curr.amount2), 0);
                        setTotalFurnitreAmt(parseFloat(TotalFurnitureMaps))

                    }
                }).catch(err => {
                    console.log("Sanstha Consolidated budget one report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (val == 3) {
            setBtn1('#063ACF');
            setBtn2('#063ACF');
            setBtn3('#15A7C1');
            setBtn4('#063ACF');
            setBtn5('#063ACF');
            setBtn6('#063ACF');
            setOption(3);

            await axios.get(`${getSansthaConsolidatedBudgetNoOneB}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        setLoaderOption(false);
                        setData(res.data);
                        setFilteredData(res.data);

                        const TotalSalary = res.data.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
                        setTotalSalaryAmt(parseFloat(TotalSalary))
                    }
                }).catch(err => {
                    console.log("Sanstha Consolidated BudgetNoOneB" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (val == 4) {
            setBtn1('#063ACF');
            setBtn2('#063ACF');
            setBtn3('#063ACF');
            setBtn4('#15A7C1');
            setBtn5('#063ACF');
            setBtn6('#063ACF');
            setOption(4);

            await axios.get(`${getSansthaConsolidatedObjectOfTrust}?sessionYear=${authUser.sessionYear}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        setLoaderOption(false);
                        setData(res.data);
                        setFilteredData(res.data);

                        const TotalObjOfTrust = res.data.reduce((prev, curr) => prev + parseFloat(curr.total_amount), 0);
                        setTotalPaymentObjOfTrustAmt(parseFloat(TotalObjOfTrust))
                    }
                }).catch(err => {
                    console.log("Sanstha Consolidated BudgetNoOneB" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (val == 5) {
            setBtn1('#063ACF');
            setBtn2('#063ACF');
            setBtn3('#063ACF');
            setBtn4('#063ACF');
            setBtn5('#15A7C1');
            setBtn6('#063ACF');
            setOption(5);

            await axios.get(`${getSansthaConsolidatedBudgetDifferenceAmount}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        setLoaderOption(false);

                        const receiptSideData = res.data.filter(e => e.option_id == 1)
                        setReceiptData(receiptSideData);

                        const paymentSideData = res.data.filter(e => e.option_id == 2)
                        setPaymentData(paymentSideData);

                        const TotalRamt = receiptSideData.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
                        setTotalReceiptAmt(parseFloat(TotalRamt))

                        const TotalPamt = paymentSideData.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
                        setTotalPaymentAmt(parseFloat(TotalPamt))
                    }
                }).catch(err => {
                    console.log("Sanstha Consolidated BudgetNoOneB" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (val == 6) {
            setBtn1('#063ACF');
            setBtn2('#063ACF');
            setBtn3('#063ACF');
            setBtn4('#063ACF');
            setBtn5('#063ACF');
            setBtn6('#15A7C1');
            setOption(6);

            await axios.get(`${getSansthaConsolidatedMiscellaneousPayment}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        setLoaderOption(false);
                        setData(res.data);
                        setFilteredData(res.data);

                        const TotalMiscellanious = res.data.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
                        setTotalPaymentMiscellanious(parseFloat(TotalMiscellanious))

                    }
                }).catch(err => {
                    console.log("Sanstha Consolidated miscelinious report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setBtn1('#15A7C1');
            setBtn2('#063ACF');
            setBtn3('#063ACF');
            setBtn4('#063ACF');
            setBtn5('#063ACF');
            setBtn6('#063ACF');
            setOption(1);

            await axios.get(`${getSansthaConsolidatedBudgetNoTwoB}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        setLoaderOption(false);
                        setData(res.data);
                        setFilteredData(res.data);

                        const TotalBudgetTwoB = res.data.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
                        setTotalBudgetTwoB(parseFloat(TotalBudgetTwoB).toFixed('2'))

                    }
                }).catch(err => {
                    console.log("Sanstha Consolidated BudgetNoTwoB" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    //print consolidated budget data
    const printSanthaConsolidatedReport = async () => {
        setLoaderOption(true);
        if (option == 2) {
            await axios.get(`${printSansthaConsolidatedBudgetNoOneAReport}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found");
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Sanstha budget one report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (option == 3) {
            await axios.get(`${printSansthaConsolidatedBudgetNoOneBReport}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found");
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Sanstha budget two B report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (option == 4) {
            await axios.get(`${printSansthaConsolidatedObjectOfTrustReport}?sessionYear=${authUser.sessionYear}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found");
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Sanstha budget two B report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (option == 5) {
            await axios.get(`${printSansthaConsolidatedBudgetDifferenceAmount}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found");
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Sanstha budget two B report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (option == 6) {
            await axios.get(`${printSansthaConsolidatedMiscellaneousPaymentReport}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found");
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Sanstha budget miscellanious report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            await axios.get(`${printSansthaConsolidatedBudgetNoTwoBReport}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found");
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Sanstha budget two B report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <button type='button' onClick={() => getSansthaConsolidatedreport(1)} className='btn btn-sm mr-2' style={{ backgroundColor: `${btn1}`, color: "#fff" }}>क्रमांक २ ब पाच</button>
                                    <button type='button' onClick={() => getSansthaConsolidatedreport(2)} className='btn btn-sm mr-2' style={{ backgroundColor: `${btn2}`, color: "#fff" }}>क्रमांक १ अ २</button>
                                    <button type='button' onClick={() => getSansthaConsolidatedreport(3)} className='btn btn-sm mr-2' style={{ backgroundColor: `${btn3}`, color: "#fff" }}>क्रमांक १ ब ३</button>
                                    <button type='button' onClick={() => getSansthaConsolidatedreport(4)} className='btn btn-sm mr-2' style={{ backgroundColor: `${btn4}`, color: "#fff" }}>ऑब्जेक्ट ऑफ ट्रस्ट</button>
                                    <button type='button' onClick={() => getSansthaConsolidatedreport(5)} className='btn btn-sm mr-2' style={{ backgroundColor: `${btn5}`, color: "#fff" }}>तूट</button>
                                    <button type='button' onClick={() => getSansthaConsolidatedreport(6)} className='btn btn-sm mr-2' style={{ backgroundColor: `${btn6}`, color: "#fff" }}>मिसलेनियस खर्च</button>
                                </div>
                            </div>

                            <div className="">
                                {
                                    (() => {
                                        if (option == 1) {
                                            return (
                                                <SansthaConsolidatedTable1
                                                    futureYear={futureYear}
                                                    filteredData={filteredData}
                                                    totalBudgetTwoB={totalBudgetTwoB}
                                                    printSanthaConsolidatedReport={printSanthaConsolidatedReport}
                                                />
                                            )
                                        }
                                        else if (option == 2) {
                                            return (
                                                <SansthaConsolidatedTable2
                                                    futureYear={futureYear}
                                                    filteredData={filteredData}
                                                    printSanthaConsolidatedReport={printSanthaConsolidatedReport}
                                                    totalBookMapsAmt={totalBookMapsAmt}
                                                    totalFurnitreAmt={totalFurnitreAmt}
                                                />
                                            )
                                        }
                                        else if (option == 3) {
                                            return (
                                                <SansthaConsolidatedTable3
                                                    futureYear={futureYear}
                                                    filteredData={filteredData}
                                                    printSanthaConsolidatedReport={printSanthaConsolidatedReport}
                                                    totalSalaryAmt={totalSalaryAmt}
                                                />
                                            )
                                        }
                                        else if (option == 4) {
                                            return (
                                                <SansthaConsolidatedTable4
                                                    futureYear={futureYear}
                                                    filteredData={filteredData}
                                                    printSanthaConsolidatedReport={printSanthaConsolidatedReport}
                                                    totalPaymentObjOfTrustAmt={totalPaymentObjOfTrustAmt}
                                                />
                                            )
                                        }
                                        else if (option == 5) {
                                            return (
                                                <SansthaConsolidatedTable5
                                                    futureYear={futureYear}
                                                    receiptData={receiptData}
                                                    paymentData={paymentData}
                                                    printSanthaConsolidatedReport={printSanthaConsolidatedReport}
                                                    totalReceiptAmt={totalReceiptAmt}
                                                    totalPaymentAmt={totalPaymentAmt}
                                                />
                                            )
                                        }
                                        else if (option == 6) {
                                            return (
                                                <SansthaConsolidatedTable6
                                                    futureYear={futureYear}
                                                    filteredData={filteredData}
                                                    printSanthaConsolidatedReport={printSanthaConsolidatedReport}
                                                    totalPaymentMiscellanious={totalPaymentMiscellanious}
                                                />
                                            )
                                        }
                                    })()
                                }
                            </div>
                        </div>


                    </>
            }
        </>
    )
}

export default SansthaConsolidatedReport