import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/inventoryStore/master/getStoreVendorMasterList?sid=3&bid=1
export const getInventoryVendorMasterAPIURL = BaseURL + "inventoryStore/master/getStoreVendorMasterList";

//localhost:8080/institute/sss/inventoryStore/master/saveStoreVendorMaster
// {
//     "vendorName": "vendorName1",
//     "email": "email",
//     "contact": "contact",
//     "gstNo": "gstNo",
//     "panNo": "panNo",
//     "address": "address",
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveInventoryVendorMasterAPIURL = BaseURL + "inventoryStore/master/saveStoreVendorMaster";

//localhost:8080/institute/sss/inventoryStore/master/updateStoreVendorMaster
export const updateInventoryVendorMasterAPIURL = BaseURL + "inventoryStore/master/updateStoreVendorMaster";

//localhost:8080/institute/sss/inventoryStore/master/deleteStoreVendorMaster?id=1
export const deleteInventoryVendorMasterAPIURL = BaseURL + "inventoryStore/master/deleteStoreVendorMaster";
