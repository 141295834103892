import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from "axios";
import { CastePostMapping, BaseURLMasterCastes } from "../CommonServices/CasteMasterAPIURL";

const AddNewCaste = ({ setCasteData }) => {
    const [casteMarathi, setCasteMarathi] = useState("");
    const [casteEnglish, setCasteEnglish] = useState("");

    const getCasteData = async () => {
        await axios.get(`${BaseURLMasterCastes}`)
            .then((response) => {
                setCasteData(response.data);
            })
    }

    const saveBtnClick = async (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        const saveObject = {
            nameMr: casteMarathi,
            nameEn: casteEnglish,
        };
        await axios.post(`${CastePostMapping}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getCasteData();

                    // get modal
                    const modal = document.getElementById('casteModal');

                    // change state like in hidden modal
                    modal.classList.remove('show');
                    modal.setAttribute('aria-hidden', 'true');
                    modal.setAttribute('style', 'display: none');

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                    // remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);
                    toast.success("Caste Saved sucessfully.");
                } else {
                    toast.error("Operation failed.");
                }
            });

        setCasteMarathi("");
        setCasteEnglish("");

    };
    return (
        <>
            <div className="modal fade" id="casteModal" tabIndex="-1" aria-labelledby="casteModalLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                            <h5 className="modal-title" id="casteModalLabel">Add New Caste</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={saveBtnClick}>
                            <div className="modal-body">

                                <TextField
                                    required
                                    onKeyDown={(e) => (e.key === " " && casteMarathi.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    value={casteMarathi}
                                    onChange={(e) => setCasteMarathi(e.target.value)}
                                    label="Caste Marathi"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && casteEnglish.length === 0) ? e.preventDefault() : ""}
                                    value={casteEnglish}
                                    onChange={(e) => setCasteEnglish(e.target.value)}
                                    label="Caste English"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" >Save</button>
                                <button type="reset" className="btn btn-danger" onClick={() => { setCasteMarathi(""); setCasteEnglish("") }} data-bs-dismiss="modal">Cancle</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewCaste;