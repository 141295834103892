import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
// Table 

import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip';
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';

//APIURL
import { BaseURLMasterCasteCategory } from '../../CommonServices/CasteCategoryMasterAPIURL';
import { getClassWithDiv } from '../../CommonServices/ClassMasterAPIURL';
import { saveAdmissionFormSaleDataAPIURL, getAdmissionFormSaleListAPIURL, updateAdmissionFormSaleDataAPIURL, deleteAdmissionFormSaleDataAPIURL } from '../Services/AdmFormSaleAPIURL';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const AdmissionFormSale = () => {
    const titleId = "Admission Form Sale";
    const authUser = useSelector((state) => state.user.value);

    //Table Data

    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Form No`, key: 'formNo' },
        { label: `Name`, key: (authUser.branchMedium == 1) ? 'firstNameMr' : 'firstNameEn' },
        { label: `Gender`, key: 'gender' },
        { label: `Mobile No`, key: 'MobileNo' },
        { label: `Class`, key: 'classNameEn' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }
    ];


    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    //Get Data 
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [classDivData, setClassDivData] = useState([]);
    const [classData, setClassData] = useState([]);

    //Form Data
    const [deleteId, setDeleteId] = useState("");
    const [id, setId] = useState("");
    const [formNo, setFormNo] = useState("");
    const [gender, setGender] = useState(1);
    const [firstNameMr, setFirstNameMr] = useState("");
    const [middleNameMr, setMiddleNameMr] = useState("");
    const [lastNameMr, setLastNameMr] = useState("");
    const [motherNameMr, setMotherNameMr] = useState("");
    const [firstNameEn, setFirstNameEn] = useState("");
    const [middleNameEn, setMiddleNameEn] = useState("");
    const [lastNameEn, setLastNameEn] = useState("");
    const [motherNameEn, setMotherNameEn] = useState("");
    const [mobile, setMobile] = useState("");
    const [percentage, setPercentage] = useState("");
    const [categoryObj, setCategoryObj] = useState(null);
    const [classObj, setClassObj] = useState(null);
    const [price, setPrice] = useState("");

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.firstNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.middleNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.lastNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.motherNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.firstNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.middleNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.lastNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.motherNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.mobileNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.formNo.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const getData = async () => {
        await axios.get(BaseURLMasterCasteCategory)
            .then((res) => {
                if (res.data !== null) {
                    setCategoryData(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })

        await axios.get(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((res) => {
                if (res.data !== null) {
                    setClassDivData(res.data)
                    //console.log("class div:" + JSON.stringify(res.data))
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    const getTableData = async () => {
        await axios.get(`${getAdmissionFormSaleListAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((res) => {
                if (res.data !== null) {
                    //console.log("table data:" + JSON.stringify(res.data))
                    setData(res.data);
                    setFilteredData(res.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        if (authUser.sessionYear !== null && authUser.deptId !== null && authUser.branchId !== null) {
            getData();
            getTableData();
        }

    }, [authUser])


    const state = {
        button: 0
    };
    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveData();
        }
        if (state.button === 2) {
            updateData();
        }
    };

    const saveData = async () => {
        const saveObj = {
            "formNo": formNo,
            "firstNameMr": firstNameMr,
            "middleNameMr": middleNameMr,
            "lastNameMr": lastNameMr,
            "motherNameMr": motherNameMr,
            "firstNameEn": firstNameEn,
            "middleNameEn": middleNameEn,
            "lastNameEn": lastNameEn,
            "motherNameEn": motherNameEn,
            "gender": gender,
            "mobileNo": mobile,
            "percentage": percentage,
            "categoryId": (categoryObj == null) ? 0 : categoryObj.id,
            "classId": (classObj == null) ? 0 : classObj.divisionId,
            "formPrice": price,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId

        }
        await axios.post(saveAdmissionFormSaleDataAPIURL, saveObj)
            .then((res) => {
                if (res.data === "SAVED") {
                    toast.success("Saved Sucessfully");
                    clearFun();
                    getTableData();
                }
                else if (res.data === "ALREADYEXIST") {
                    toast.success("Form Number Already Exist.");
                }
                else {
                    toast.error("Something went wrong");
                }
            })
    }
    const updateData = async () => {
        const updateObj = {
            "id": id,
            "formNo": formNo,
            "firstNameMr": firstNameMr,
            "middleNameMr": middleNameMr,
            "lastNameMr": lastNameMr,
            "motherNameMr": motherNameMr,
            "firstNameEn": firstNameEn,
            "middleNameEn": middleNameEn,
            "lastNameEn": lastNameEn,
            "motherNameEn": motherNameEn,
            "gender": gender,
            "mobileNo": mobile,
            "percentage": percentage,
            "categoryId": (categoryObj == null) ? 0 : categoryObj.id,
            "classId": (classObj == null) ? 0 : classObj.divisionId,
            "formPrice": price,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId

        }
        await axios.put(updateAdmissionFormSaleDataAPIURL, updateObj)
            .then((res) => {
                if (res.data === "SAVED") {
                    toast.success("Updated Sucessfully");
                    getTableData();
                    clearFun();
                }
                else {
                    toast.error("Something went wrong");
                }
            })
    }

    const DeleteData = async (id) => {
        await axios.delete(`${deleteAdmissionFormSaleDataAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data === "DELETED") {
                    getTableData();
                    toast.error("Deleted sucessfully");
                }
            });

    }

    const clearFun = () => {
        setId("");
        setFormNo("");
        setGender(1);
        setFirstNameMr("");
        setMiddleNameMr("");
        setLastNameMr("");
        setMotherNameMr("");
        setFirstNameEn("");
        setMiddleNameEn("");
        setLastNameEn("");
        setMotherNameEn("");
        setMobile("");
        setPercentage("");
        setCategoryObj(null);
        setClassObj(null);
        setPrice("");

        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
    }

    const setUpdateData = (item) => {
        setId(item.id);
        setFormNo(item.formNo);
        setGender(item.genderId);
        setFirstNameMr(item.firstNameMr);
        setMiddleNameMr(item.middleNameMr);
        setLastNameMr(item.lastNameMr);
        setMotherNameMr(item.motherNameMr);
        setFirstNameEn(item.firstNameEn);
        setMiddleNameEn(item.middleNameEn);
        setLastNameEn(item.lastNameEn);
        setMotherNameEn(item.motherNameEn);
        setMobile(item.mobileNo);
        setPercentage(item.percentage);
        setCategoryObj(categoryData.find((e) => e.id == item.categoryId));
        setClassObj(classDivData.find((e) => e.divisionId == item.classId));
        setPrice(item.formPrice);

        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        setSaveBtnDisabled(true);
        setUpdateBtnDisabled(false);
    }

    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                <form onSubmit={onSubmit}>

                    <div className="row">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Form No :</label>
                        <div className='col-sm-3'>
                            <TextField
                                required
                                value={formNo}
                                onChange={(e) => setFormNo(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                                fullWidth
                                type="number"
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Form No"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>

                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "12px" }} >Gender :</label>
                        <div className='col-sm-3 mt-1'>
                            <RadioGroup row aria-label="gender" margin="dense" name="customized-radios" value={gender} onChange={(e) => setGender(parseInt(e.target.value))}>
                                <FormControlLabel value={1} control={<Radio color='primary' />} label="Male" />
                                <FormControlLabel value={2} control={<Radio color='primary' />} label="Female" />
                            </RadioGroup>
                        </div>

                    </div>

                    <div className="row">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >First Name :</label>
                        <div className='col-sm-3'>
                            <TextField
                                required={(authUser.branchMedium == 1) ? true : false}
                                value={firstNameMr}
                                onChange={(e) => setFirstNameMr(e.target.value)}
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="First Name Mr"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>

                        <div className='col-sm-3'>
                            <TextField
                                required={(authUser.branchMedium == 2) ? true : false}
                                value={firstNameEn}
                                onChange={(e) => setFirstNameEn(e.target.value)}
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="First Name En"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Middle Name :</label>
                        <div className='col-sm-3'>
                            <TextField
                                required={(authUser.branchMedium == 1) ? true : false}
                                value={middleNameMr}
                                onChange={(e) => setMiddleNameMr(e.target.value)}
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Middle Name Mr"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>

                        <div className='col-sm-3'>
                            <TextField
                                required={(authUser.branchMedium == 2) ? true : false}
                                value={middleNameEn}
                                onChange={(e) => setMiddleNameEn(e.target.value)}
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Middle Name En"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Last Name :</label>
                        <div className='col-sm-3'>
                            <TextField
                                required={(authUser.branchMedium == 1) ? true : false}
                                value={lastNameMr}
                                onChange={(e) => setLastNameMr(e.target.value)}
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Last Name Mr"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>

                        <div className='col-sm-3'>
                            <TextField
                                required={(authUser.branchMedium == 2) ? true : false}
                                value={lastNameEn}
                                onChange={(e) => setLastNameEn(e.target.value)}
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Last Name En"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Name :</label>
                        <div className='col-sm-3'>
                            <TextField
                                // required={(authUser.branchMedium == 1) ? true : false}
                                value={motherNameMr}
                                onChange={(e) => setMotherNameMr(e.target.value)}
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Mother Name Mr"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <TextField
                                // required={(authUser.branchMedium == 2) ? true : false}
                                value={motherNameEn}
                                onChange={(e) => setMotherNameEn(e.target.value)}
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Mother Name En"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mobile :</label>
                        <div className='col-sm-3'>
                            <TextField
                                // required
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                                fullWidth
                                type="number"
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Mobile No"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                            />
                        </div>

                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Percentage:</label>
                        <div className='col-sm-3'>
                            <TextField
                                //required
                                value={percentage}
                                onChange={(e) => setPercentage(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                                fullWidth
                                type="number"
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Percentage"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                        <div className="col-sm-3">
                            <Autocomplete
                                id="combo-box-demo"
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                options={categoryData}
                                value={categoryObj}
                                onChange={(event, newValue) => {
                                    setCategoryObj(newValue)
                                }}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => option.nameMr + " | " + option.nameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                    //required 
                                    />
                                )}
                            />
                        </div>

                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                        <div className="col-sm-3">
                            <Autocomplete
                                id="combo-box-demo"
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                options={classDivData}
                                value={classObj}
                                onChange={(event, newValue) => {
                                    setClassObj(newValue)
                                }}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                    //required 
                                    />
                                )}
                            />
                        </div>

                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Price :</label>
                        <div className='col-sm-2'>
                            <TextField
                                required
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                                fullWidth
                                type="number"
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Form Price"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 mt-3 offset-sm-2">
                            <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} disabled={saveBtnDisabled}>Save</button>
                            <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} disabled={updateBtnDisabled}>Update</button>
                            <button type="button" className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} onClick={() => clearFun()} >Cancel</button>
                        </div>
                    </div>
                </form>
                <hr />
                <div className="col-sm-12">
                    <div className='row mt-1'>
                        <div>
                            <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                            />
                        </div>
                    </div>

                    <div className='table-responsive'>
                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>

                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.formNo}</td>

                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        {
                                                            (authUser.branchMedium == 1)
                                                                ?
                                                                item.firstNameMr + " " + item.middleNameMr + " " + item.lastNameMr
                                                                :
                                                                item.firstNameEn + " " + item.middleNameEn + " " + item.lastNameEn
                                                        }
                                                    </td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.gender}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.mobileNo}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500", textAlign: `${(item.classNameMr == null && item.classNameEn == null) ? 'center' : ''}` }}>{(item.classNameMr == null && item.classNameEn == null) ? '-' : item.classNameMr + " / " + item.classNameEn}</td>

                                                    <td>
                                                        <Tooltip title="Edit">
                                                            <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                onClick={() => setUpdateData(item)} data-bs-toggle="tooltip">
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                    <td>
                                                        <Tooltip title="Delete">
                                                            <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={() => setDeleteId(item.id)}                                                >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })}
                            </tbody>
                            <tfoot style={{ padding: "0px" }}>
                                <tr>
                                    <TablePagination
                                        style={{ padding: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        labelRowsPerPage="Rows Per Page :"
                                        classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div >
            {/* <ToastContainer position="top-right" theme='colored' /> */}
            < DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default AdmissionFormSale