import React, { useState, useEffect } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';

//APIURL
import { getEntranceExamMarksDataByFormNoAPIURL, updateEntranceExamMarksAPIURL } from '../Services/AdmFormEntranceAPIURL';
import { getClassWithDiv } from '../../Academic/Services/StudentRollNoAPIURL';
import AdmissionEntranceWritten from './AdmissionEntranceTables/AdmissionEntranceWritten';
import AdmissionEntranceInterview from './AdmissionEntranceTables/AdmissionEntranceInterview';
import AdmissionEntrancePhysical from './AdmissionEntranceTables/AdmissionEntrancePhysical';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const AdmissionEntranceForm = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Admission Entrance";
    const classes = useStyles();

    const navigate = useNavigate();

    const entranceType = [
        {
            "id": 1,
            "name": "Written"
        },
        {
            "id": 2,
            "name": "Interview"
        },
        {
            "id": 3,
            "name": "Physical"
        },
        {
            "id": 4,
            "name": "Medical"
        }
    ]

    const PhysicalMedicalType = [
        {
            "id": 0,
            "nameMr": "अपात्र",
            "nameEn": "Not Qualified"
        },
        {
            "id": 1,
            "nameMr": "पात्र",
            "nameEn": "Qualified"
        }
    ]

    const [typeObj, setTypeObj] = useState(null);
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);

    const [loaderOption, setLoaderOption] = useState(false);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [tempData, setTempData] = useState([]);

    const [formNo, setFormNo] = useState('');
    const [interview1, setInterview1] = useState("");
    const [interview2, setInterview2] = useState("");
    const [interview3, setInterview3] = useState("");
    const [interview4, setInterview4] = useState("");
    const [convertedMark, setConvertedMark] = useState("");
    const [entrance, setEntrance] = useState("");
    const [physicalObj, setPhysicalObj] = useState(null);
    const [medicalObj, setMedicalObj] = useState(null);

    //get student details
    const getStudentDetailsForAdmEntrance = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        setTempData([]);
        setSearchTerm('');
        setAscending(false);
        setSortedBy(null);
        console.log(`${getEntranceExamMarksDataByFormNoAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&divId=${classDivObj.divisionId}`)
        await axios.get(`${getEntranceExamMarksDataByFormNoAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&divId=${classDivObj.divisionId}`)
            .then((res) => {
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.");
                }
                else {
                    setFilteredData(res.data);
                    setData(res.data);
                    setTempData(res.data);
                    setLoaderOption(false);
                }
            })
    }

    //save marks
    const saveEntranceMark = async (e) => {
        e.preventDefault();
        setLoaderOption(true);

        console.log(JSON.stringify(data))
        await axios.put(updateEntranceExamMarksAPIURL, data)
            .then((res) => {
                if (res.data === "SAVED") {
                    clearFun();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Sucessfully")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                console.log(err);
            })
    }

    //get subject
    const getData = async () => {
        await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassDivData(res.data);
            })
    }

    useEffect(() => {
        if (authUser.deptId !== null && authUser.branchId !== null) {
            getData();
        }
    }, [authUser])

    //clear states
    const clearFun = () => {
        setData([]);
        setFilteredData([]);
        setTempData([]);
    }

    //sorting function
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getEntranceExamMarksDataByFormNoAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&divId=${classDivObj.divisionId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key] < b[key] ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key] > b[key] ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    //for written type table heading
    const WrittenTableHeading = [
        { label: 'Form No', key: 'formNo' },
        { label: 'Seat No', key: 'seatNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'firstNameMr' : 'firstNameEn' },
        { label: `Written Marks`, key: 'entranceMarks' },
    ];

    //for interview type table heading
    const interviewTableHeading = [
        { label: 'Form No', key: 'formNo' },
        { label: 'Seat No', key: 'seatNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'firstNameMr' : 'firstNameEn' },
        { label: `Interview1`, key: 'interviewMarks1' },
        { label: `Interview2`, key: 'interviewMarks2' },
        { label: `Interview3`, key: 'interviewMarks3' },
        { label: `Interview4`, key: 'interviewMarks4' },
        { label: `Converted`, key: 'totalInterviewMark' },
    ];

    //for physical & medical type table heading
    const physicalTableHeading = [
        { label: 'Form No', key: 'formNo' },
        { label: 'Seat No', key: 'seatNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'firstNameMr' : 'firstNameEn' },
        { label: `Status`, key: 'physicalStatus' },
    ];

    //search function
    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            data.filter((item) =>
                (item.firstNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.firstNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.middleNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.middleNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.lastNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.lastNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.formNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.seatNo || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.medicalStatusMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.physicalStatusMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.medicalStatusEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.physicalStatusEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.entranceMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.interviewMarks1 || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.interviewMarks2 || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.interviewMarks3 || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.interviewMarks4 || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.totalInterviewMark || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData)
        //setFilteredData(data.filter((item) => item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.categoryNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.genderLabel.toLowerCase().includes(searchTerm.toLowerCase()) || item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) || item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    }, [searchTerm])

    //set value after search
    useEffect(() => {
        setFormNo('');
        if (typeObj != null) {
            let updatedData = tempData;
            if (typeObj.id == 1) {
                tempData.map((e, index) => {
                    if (e.formNo == formNo) {
                        updatedData[index] = ({ ...e, entranceMarks: entrance })
                    }
                })
                //console.log(JSON.stringify(tempData))
                setData(tempData);
                if (searchTerm == "") {
                    //console.log("After Clear written=======" + JSON.stringify(tempData))
                    setFilteredData(tempData);
                }
            }
            else if (typeObj.id == 2) {
                tempData.map((e, index) => {
                    if (e.formNo == formNo) {
                        updatedData[index] = ({ ...e, interviewMarks1: interview1, interviewMarks2: interview2, interviewMarks3: interview3, interviewMarks4: interview4, totalInterviewMark: convertedMark })
                    }
                })
                //console.log(JSON.stringify(tempData))
                setData(tempData);
                if (searchTerm == "") {
                    //console.log("After Clear Interview=======" + JSON.stringify(tempData))
                    setFilteredData(tempData);
                }
            }
            else if (typeObj.id == 3) {
                tempData.map((e, index) => {
                    if (e.formNo == formNo) {
                        updatedData[index] = ({ ...e, physicalStatus: physicalObj })
                    }
                })
                //console.log(JSON.stringify(tempData))
                setData(tempData);
                if (searchTerm == "") {
                    //console.log("After Clear physical=======" + JSON.stringify(tempData))
                    setFilteredData(tempData);
                }
            }
            else if (typeObj.id == 4) {
                tempData.map((e, index) => {
                    if (e.formNo == formNo) {
                        updatedData[index] = ({ ...e, medicalStatus: medicalObj })
                    }
                })
                //console.log(JSON.stringify(tempData))
                setData(tempData);
                if (searchTerm == "") {
                    //console.log("After Clear medical=======" + JSON.stringify(tempData))
                    setFilteredData(tempData);
                }
            }
        }
    }, [formNo, searchTerm])


    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: `${(data.length == 0) ? '350px' : '500px'}` }}>
                            <div>
                                <form onSubmit={getStudentDetailsForAdmEntrance}>
                                    <div className="row">
                                        <label className="" style={{ width: '140px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class-Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={classDivData}
                                                style={{ fontSize: '14px' }}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    //console.log(newValue.classId + " " + newValue.divisionId);
                                                    setClassDivObj(newValue);
                                                    setData([]);
                                                    setFilteredData([]);
                                                    setTempData([]);
                                                    setTypeObj(null);
                                                }}
                                                value={classDivObj}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Type :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={entranceType}
                                                value={typeObj}
                                                onChange={(event, newValue) => {
                                                    setTypeObj(newValue);
                                                    setData([]);
                                                    setFilteredData([]);
                                                    setTempData([]);
                                                }}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.name}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4" style={{ marginLeft: '140px' }}>
                                            <button type='submit' className='btn btn-sm' style={{ backgroundColor: "#0D8900", color: '#fff' }}>
                                                View
                                            </button>
                                            <button type='button' className='btn btn-danger btn-sm ml-2' onClick={() => navigate('/Home/adm322/')}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>


                                <div className="" style={{ marginTop: "30px" }}>
                                    {(() => {
                                        if (data.length != 0 || filteredData.length != 0) {
                                            return (
                                                <>
                                                    <div className='row mb-1' style={{ float: 'right' }}>
                                                        <div>
                                                            <input
                                                                className='form-control form-control-sm'
                                                                type="text"
                                                                style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                                placeholder="Search Here"
                                                                onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); }}
                                                                value={searchTerm}
                                                            />
                                                        </div>
                                                    </div>

                                                    <form onSubmit={saveEntranceMark}>
                                                        <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                                            <table className="table table-bordered ">
                                                                <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                    <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                                        {
                                                                            (() => {
                                                                                if (typeObj.id == 1) {
                                                                                    return (
                                                                                        <>
                                                                                            {WrittenTableHeading.map((item, index) => {
                                                                                                return (
                                                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => { onSort(item.key, item.isAction) }} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                else if (typeObj.id == 2) {
                                                                                    return (
                                                                                        <>
                                                                                            {interviewTableHeading.map((item, index) => {
                                                                                                return (
                                                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => { onSort(item.key, item.isAction) }} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <>
                                                                                            {physicalTableHeading.map((item, index) => {
                                                                                                return (
                                                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => { onSort(item.key, item.isAction) }} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })()
                                                                        }

                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                    {filteredData
                                                                        .map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td style={{ fontSize: "15px", fontWeight: "500", width: `${(typeObj.id == 2) ? "100px" : "140px"}` }}>{item.formNo}</td>
                                                                                    <td style={{ fontSize: "15px", fontWeight: "500", width: `${(typeObj.id == 2) ? "100px" : "150px"}`, textAlign: `${(item.seatNo == null) ? 'center' : ''}` }}>{(item.seatNo == null) ? ' - ' : item.seatNo}</td>
                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                                        {(authUser.branchMedium == 1) ? item.firstNameMr + " " + item.middleNameMr + " " + item.lastNameMr : item.firstNameEn + " " + item.middleNameEn + " " + item.lastNameEn}
                                                                                    </td>

                                                                                    {(() => {
                                                                                        if (typeObj.id == 1) {
                                                                                            return (
                                                                                                <AdmissionEntranceWritten initialText={item.entranceMarks} onChange={(value) => {
                                                                                                    let updatedData = filteredData;
                                                                                                    updatedData[index] = ({ ...item, entranceMarks: value });
                                                                                                    setData(updatedData);
                                                                                                    setFilteredData(updatedData);
                                                                                                    setEntrance(value);
                                                                                                    setFormNo(updatedData[index].formNo)
                                                                                                    //console.log(JSON.stringify(updatedData));
                                                                                                }} />
                                                                                            )
                                                                                        }
                                                                                        else if (typeObj.id == 2) {
                                                                                            return (
                                                                                                <AdmissionEntranceInterview
                                                                                                    initialText1={item.interviewMarks1}
                                                                                                    initialText2={item.interviewMarks2}
                                                                                                    initialText3={item.interviewMarks3}
                                                                                                    initialText4={item.interviewMarks4}
                                                                                                    initialText6={item.totalInterviewMark}
                                                                                                    onChange={(value1, value2, value3, value4, value6) => {
                                                                                                        const newTotalValue = Math.round((parseInt(value1) + parseInt(value2) + parseInt(value3) + parseInt(value4)) / 4);
                                                                                                        console.log(value6);
                                                                                                        let updatedData = filteredData;
                                                                                                        updatedData[index] = ({
                                                                                                            ...item,
                                                                                                            interviewMarks1: value1,
                                                                                                            interviewMarks2: value2,
                                                                                                            interviewMarks3: value3,
                                                                                                            interviewMarks4: value4,
                                                                                                            totalInterviewMark: newTotalValue
                                                                                                        });
                                                                                                        setData(updatedData);
                                                                                                        setFilteredData(updatedData);
                                                                                                        setInterview1(value1);
                                                                                                        setInterview2(value2);
                                                                                                        setInterview3(value3);
                                                                                                        setInterview4(value4);
                                                                                                        setConvertedMark(newTotalValue);
                                                                                                        setFormNo(updatedData[index].formNo)
                                                                                                        //console.log(JSON.stringify(updatedData));
                                                                                                    }} />
                                                                                            )
                                                                                        }
                                                                                        else if (typeObj.id == 3) {
                                                                                            return (
                                                                                                <AdmissionEntrancePhysical status={item.physicalStatus} PhysicalMedicalType={PhysicalMedicalType}
                                                                                                    onChange={(value) => {
                                                                                                        let updatedData = filteredData;
                                                                                                        updatedData[index] = ({ ...item, physicalStatus: value });
                                                                                                        setData(updatedData);
                                                                                                        setFilteredData(updatedData);
                                                                                                        setPhysicalObj(value);
                                                                                                        setFormNo(updatedData[index].formNo)
                                                                                                        //console.log(JSON.stringify(updatedData));
                                                                                                    }} />
                                                                                            )
                                                                                        }
                                                                                        else if (typeObj.id == 4) {
                                                                                            return (
                                                                                                <AdmissionEntrancePhysical status={item.medicalStatus} PhysicalMedicalType={PhysicalMedicalType} onChange={(value) => {
                                                                                                    let updatedData = filteredData;
                                                                                                    updatedData[index] = ({ ...item, medicalStatus: value });
                                                                                                    setData(updatedData);
                                                                                                    setFilteredData(updatedData);
                                                                                                    setMedicalObj(value);
                                                                                                    setFormNo(updatedData[index].formNo)
                                                                                                    //console.log(JSON.stringify(updatedData));
                                                                                                }} />
                                                                                            )
                                                                                        }
                                                                                    })()}

                                                                                </tr>
                                                                            )
                                                                        })}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className='col-sm-2'>
                                                                <button className='btn btn-primary'><AddIcon fontSize="small" />Save Changes</button>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>

                        </div>

                    </div >
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default AdmissionEntranceForm