import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getDivisionsByClassAndSectionAndBranch } from '../../Exam/Services/ExamMarkAllocationAPIURL';
import moment from 'moment';
import { printEbcReportClassWiseAPIURL, printEbcReportAllClassAPIURL, printEbcReportClassDivWiseAPIURL } from '../Services/EbcReportAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getDivision } from '../../CommonServices/LeavingCertificateAPIURL';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { faSlash } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const EBCReport = () => {
    const classes = useStyles();
    const titleId = "EBC Report";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [reportOptionId, setReportOptionId] = useState(1);
    const [id, setId] = useState();
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [divisionObj, setDivisionObj] = useState([]);
    const [divisionId, setDivisionId] = useState([]);
    const [withTutionFee, setWithTutionFee] = useState(0);
    const [gender, setGender] = useState(0);

    const HandleTutionFeeCheckbox = (e) => {
        setWithTutionFee(e.target.checked);
        if (e.target.checked == true) {
            setWithTutionFee(1);
        }
        else if (e.target.checked == false) {
            setWithTutionFee(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const getDivisions = async (cId) => {
        await axios.get(`${getDivisionsByClassAndSectionAndBranch}?cid=${cId}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setDivisionData(res.data);
            })
    }

    const getAllClassEbcReport = async () => {
        setLoaderOption(true);
        // if (moment(applicationDate).format("DD/MM/YYYY") !== "Invalid date") {
        await axios.get(`${printEbcReportAllClassAPIURL}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&withTuitionFee=${withTutionFee}&genderId=${gender}`)
            .then(res => {
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (res.data != null) {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                }
            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
        // }
        // else {
        //     setLoaderOption(false);
        //     toast.error("Improper date, please check.")
        // }

    }

    const getClassWiseEbcReport = async () => {
        setLoaderOption(true);
        console.log(`${printEbcReportClassWiseAPIURL}?langId=${authUser.branchMedium}&classId=${classObj.id}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&withTuitionFee=${withTutionFee}&genderId=${gender}`)
        await axios.get(`${printEbcReportClassWiseAPIURL}?langId=${authUser.branchMedium}&classId=${classObj.id}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&withTuitionFee=${withTutionFee}&genderId=${gender}`)
            .then(res => {
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (res.data != null) {
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                    setLoaderOption(false);
                }
            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const getClassDivWiseEbcReport = async () => {
        setLoaderOption(true);
        console.log(`${printEbcReportClassDivWiseAPIURL}?langId=${authUser.branchMedium}&divId=${divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classObj.id}&withTuitionFee=${withTutionFee}&genderId=${gender}`)
        await axios.get(`${printEbcReportClassDivWiseAPIURL}?langId=${authUser.branchMedium}&divId=${divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classObj.id}&withTuitionFee=${withTutionFee}&genderId=${gender}`)
            .then(res => {
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (res.data != null) {
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                    setLoaderOption(false);
                }
            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })

    }

    const getClassWiseClassDivisionWiseEBC = (e) => {
        e.preventDefault();
        if (reportOptionId == 1) {
            getAllClassEbcReport();
        }
        else if (reportOptionId == 2) {
            getClassWiseEbcReport();
        }
        else if (reportOptionId == 3) {
            getClassDivWiseEbcReport();
        }
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={getClassWiseClassDivisionWiseEBC}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Gender :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Gender</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={gender}
                                            label="Select Gender"
                                            onChange={(e) => {
                                                setGender(e.target.value)
                                                setReportOptionId(1);
                                                setClassObj(null);
                                                setDivisionObj([]);
                                                setWithTutionFee(0);
                                            }}
                                        >
                                            <MenuItem value={0}>All</MenuItem>
                                            <MenuItem value={1}>Male</MenuItem>
                                            <MenuItem value={2}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={reportOptionId}
                                            label="Select Option"
                                            onChange={(e) => {
                                                setReportOptionId(e.target.value);
                                                setClassObj(null);
                                                setDivisionObj([]);
                                                setWithTutionFee(0);
                                            }}
                                        >
                                            <MenuItem value={1}>All</MenuItem>
                                            <MenuItem value={2}>Class Wise</MenuItem>
                                            <MenuItem value={3}>Class-Division Wise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-sm-3 mt-2" hidden={(reportOptionId == 1) ? false : true}>
                                    <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={withTutionFee} onChange={HandleTutionFeeCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="With Tution Fee" />
                                </div>
                            </div>

                            {(() => {
                                if (reportOptionId == 2 || reportOptionId == 3) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                <div className='col-sm-3'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        options={classData}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        size="small"
                                                        value={classObj}
                                                        onChange={(event, newvalue) => {
                                                            setClassObj(newvalue);
                                                            setDivisionObj([]);
                                                            setWithTutionFee(0);
                                                            if (newvalue != null) {
                                                                getDivisions(newvalue.id);
                                                            }
                                                        }}
                                                        renderInput={params => (
                                                            <TextField {...params} margin="dense" label="Select Class" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                required={(reportOptionId == 2 || reportOptionId == 3) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row my-2" hidden={(reportOptionId == 2) ? true : false}>
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Division :</label>
                                                <div className='col-sm-4'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        multiple
                                                        filterSelectedOptions
                                                        disableCloseOnSelect
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        options={divisionData}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        size="small"
                                                        value={divisionObj}
                                                        onChange={(event, newvalue) => {
                                                            setDivisionObj(newvalue);
                                                            setWithTutionFee(0);
                                                            if (newvalue != []) {
                                                                setDivisionId(newvalue.map(e => e.id).join(','))
                                                            }
                                                        }}

                                                        renderInput={params => (
                                                            <TextField {...params} margin="dense" label="Select Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                required={(reportOptionId == 3 && divisionObj.length == 0) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mt-2">
                                                <div className="col-sm-3 offset-sm-2">
                                                    <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={withTutionFee} onChange={HandleTutionFeeCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="With Tution Fee" />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })()}

                            <div className="row mt-2">
                                <div className="col-sm-12 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }


}

export default EBCReport