import React from 'react';
//Note here this modal box require two parameters i.e ID and DeleteData Function
const DeleteEmpLeaveEntryModal = (props) => {
    //console.log(props.DeleteData())
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header mt-3">
                            <h4 className="modal-title" id="exampleModalLabel">Are you sure you want to delete this?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" onClick={() => props.DeleteData()} className="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteEmpLeaveEntryModal