import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";


//http://192.168.1.102:8080/institute/sss/inventoryStore/transaction/getItemInwardList?year=2022&sid=3&bid=1
export const getItemInwardDetailsAPIURL = BaseURL + "inventoryStore/transaction/getItemInwardList";

//localhost:8080/institute/sss/inventoryStore/transaction/saveItemInward
// {
//     "itemInward": {
//         "vendorId": 1,
//         "receiveDate": "2023-05-03",
//         "billNo": "123",
//         "billDate": "2023-05-01",
//         "grossAmount": 100.00,
//         "discount": 10.00,
//         "gstAmount": 50.00,
//         "otherExpenses": 20,
//         "netAmount": 50,
//         "remark": "remark",
//         "sessionYear": 2022,
//         "sectionId": 3,
//         "branchId": 1
//     },
//     "itemInwardDetails": [
//         {
//             "itemMasterId": 1,
//             "quantity": 2,
//             "rate": 10,
//             "totalAmount": 20
//         }
//     ]
// }
export const saveItemInwardAPIURL = BaseURL + "inventoryStore/transaction/saveItemInward";

//localhost:8080/institute/sss/inventoryStore/transaction/updateItemInward
export const updateItemInwardAPIURL = BaseURL + "inventoryStore/transaction/updateItemInward";

//localhost:8080/institute/sss/inventoryStore/transaction/deleteItemInward?id=1
export const deleteItemInwardAPIURL = BaseURL + "inventoryStore/transaction/deleteItemInward";