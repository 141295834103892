import { BaseIP, BaseURL, BaseURLMaster, dBaseURLMaster, dBaseURL } from "./APIURL";

//Institute Master API link
export const BaseURLInstituteMaster = BaseURLMaster + "inst/Institutes/";
//export const BaseURLInstituteMaster = "http://192.168.1.102:8080/institute/sss/master/inst/Institutes/";
export const InstituteMasterSave = BaseURLMaster + "inst/saveInstitute/";
export const InstituteMasterUpdate = BaseURLMaster + "inst/updateInstitute/";

//Institute Master getData for Update
export const getInstituteDataById = BaseURLMaster + "inst/Institutes/";

//Section get APIURL
export const BaseURLSection = BaseURLMaster + "inst/Sections/";

//ClassAllocation APIURL
export const instClassAllocationAPI = BaseURLMaster + "inst/InstitutesClasAllocationData";
export const saveClassAllocation = BaseURLMaster + "inst/saveInstitutesClasAllocationData/";
//InstitutesClasAllocationData

//city getmapping
export const getCityData = BaseURLMaster + "Cities/"

// localhost:8080/institute/sss/system/saveInstituteDepartment
// {
//     "branchId":12,
//     "departmentId":3,
//     "endowmentId":2
// }
export const saveBranchDepartmentsForEndow = BaseURL + "system/saveInstituteDepartment";

//localhost:8080/institute/sss/system/updateInstituteDepartment
export const updateBranchDepartmentsForEndow = BaseURL + "system/updateInstituteDepartment";

//localhost:8080/institute/sss/system/getInstituteDepartmentData?bid=1
export const getBranchDepartmentsForEndow = BaseURL + "system/getInstituteDepartmentData";

//localhost:8080/institute/sss/system/deleteInstituteDepartment?id=1
export const deleteBranchDepartmentsForEndow = BaseURL + "system/deleteInstituteDepartment";


//Department Allocation APIURL
//localhost:8080/institute/sss/master/saveDepartmentAllocationToBranch
// {
//     "departmentId":3,
//     "branchId":1
// }
export const saveInstituteDepartmentAllocation = BaseURLMaster + "saveDepartmentAllocationToBranch";

//localhost:8080/institute/sss/master/getDepartmentAllocationToBranch?bid=1
export const getInstituteDepartmentAllocation = BaseURLMaster + "getDepartmentAllocationToBranch";

//localhost:8080/institute/sss/master/deleteDepartmentAllocationToBranch?id=1
export const deleteInstituteDepartmentAllocation = BaseURLMaster + "deleteDepartmentAllocationToBranch";


export const saveFooterSignature = BaseURL + "system/saveFooterSignature";

export const getFooterSignature = BaseURL + "system/getFooterSignature";