import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";
import MasterFormAccountMasterTable from './MasterFormAccountMasterTable';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { StyledEngineProvider } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import { BaseURLAccountGroupMaster } from '../../CommonServices/AccountGroupAPIURL';
import { BaseURLAccountMaster, AccountMasterSave, AccountMasterUpdate, AccountCodeGetMapping } from '../../CommonServices/AccountMasterAPIURL';
import Loader from '../../CommonComponent/Loader';
import { useNavigate, useParams } from 'react-router-dom';

const CheckBoxData = [
    {
        id: 1,
        LabelName1: "R",
        LabelName2: "P",

    },
]

const MasterFormAccountMaster = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Account Master Form";
    const branchId = authUser.branchId;
    const sectionId = authUser.deptId;

    const navigate = useNavigate();
    let { accId, EditFlag } = useParams();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "accountMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "accountMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState();
    const [accountNameMr, setAccountNameMr] = useState("");
    const [accountNameEn, setAccountNameEn] = useState("");
    const [openingBalance, setOpeningBalance] = useState('');
    const [isReceipt, setIsReceipt] = useState(0);
    const [isPayment, setIsPayment] = useState(0);
    const [accountCode, setAccountCode] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [groupData, setGroupData] = useState([]);
    const [selGroupUpdateId, setGroupSelUpdate] = useState();
    const [groupId, setGroupId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")

    const [groupDisableFlag, setGroupDisableFlag] = useState(false);

    const HandleReceiptCheckBox = (e) => {
        setIsReceipt(e.target.checked);
        if (e.target.checked == true) {
            setIsReceipt(1);
        }
        else {
            setIsReceipt(0);
        }
    }

    const HandlePaymentCheckBox = (e) => {
        setIsPayment(e.target.checked);
        if (e.target.checked == true) {
            setIsPayment(1);
        }
        else {
            setIsPayment(0);
        }
    }

    const accountCodeGet = async (val) => {
        //console.log(`${AccountCodeGetMapping}?groupId=${val}&sid=${sectionId}&bid=${authUser.branchId}`);
        //@RequestParam("groupId") long groupId,@RequestParam("sid") long sid,@RequestParam("bid") long bid
        //console.log(val);
        if (val != null) {
            await axios.get(`${AccountCodeGetMapping}?groupId=${val}&sid=${sectionId}&bid=${authUser.branchId}`)
                .then(res => {
                    setAccountCode(res.data);
                })
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getApiData = async () => {
        // @RequestParam("sid")  long sectionId,@RequestParam("acyr") long acYear,@RequestParam("bid") long branchId
        //console.log(`${BaseURLAccountMaster}?sid=${sectionId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`)
        if (sectionId != null && authUser.sessionYear != "" && authUser.branchId != null) {
            await axios.get(`${BaseURLAccountMaster}?sid=${sectionId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then((response) => {
                    setData(response.data);
                    setFilteredData(response.data);
                })
        }
        if (sectionId != null) {
            await axios.get(`${BaseURLAccountGroupMaster}${sectionId}`)
                .then((response) => {
                    setGroupData(response.data);
                })
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${BaseURLAccountMaster}?sid=${authUser.deptId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then((response) => {
                setSaveBtnDisabled(true);
                setUpdateBtnDisabled(false);
                setGroupDisableFlag((authUser.roleId == 5 || authUser.roleId == 7 || authUser.roleId == 3) ? false : true);
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                //console.log(JSON.stringify(response.data))

                const selectedItem = response.data.find(e => e.id == accId);
                //console.log("accId===" + accId + "====" + "data===" + JSON.stringify(selectedItem))

                setId(selectedItem.id);
                setAccountNameMr(selectedItem.accountNameMr);
                setAccountNameEn(selectedItem.accountNameEn);
                setOpeningBalance(selectedItem.openingBalance);
                const selectedGroup = selectedItem.groupId
                //console.log(selectedGroup);             
                setAccountCode(selectedItem.accountCode);
                setGroupSelUpdate(selectedItem.groupId)
                setIsReceipt(selectedItem.isReceipt);
                setIsPayment(selectedItem.isPayment);

                axios.get(`${BaseURLAccountGroupMaster}${sectionId}`)
                    .then((response) => {
                        setGroupData(response.data);
                        setGroupId(response.data.find(e => e.id == selectedGroup))
                        setLoaderOption(false);
                    })
            })
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObject = {
            id: id,
            groupId: selGroupUpdateId,
            accountNameMr: accountNameMr,
            accountNameEn: accountNameEn,
            openingBalance: openingBalance,
            isReceipt: isReceipt,
            isPayment: isPayment,
            accountCode: accountCode,
            sectionId: sectionId,
            branchId: branchId
        };
        console.log(JSON.stringify(saveObject))
        await axios.post(`${AccountMasterSave}?acyr=${authUser.sessionYear}`, saveObject)
            .then((response) => {
                //console.log(response.data)
                if (response.data == "SAVED") {
                    getData();
                    ClearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                }
                else if (response.data == "EXIST") {
                    setLoaderOption(false);
                    toast.error("This account is already exists..");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Account master save err " + err);
                toast.error("Something went wrong, please check.")
            })
    };

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObject = {
            groupId: selGroupUpdateId,
            accountNameMr: accountNameMr,
            accountNameEn: accountNameEn,
            openingBalance: openingBalance,
            accountCode: accountCode,
            isReceipt: isReceipt,
            isPayment: isPayment,
            sectionId: sectionId,
            branchId: branchId,
        };
        console.log(JSON.stringify(updateObject));
        await axios.put(`${AccountMasterUpdate}?accId=${accId}&acyr=${authUser.sessionYear}`, updateObject)
            .then((response) => {
                //console.log("updated.................."+response.data);
                if (response.data == "SAVED") {
                    getData();
                    ClearData();
                    setLoaderOption(false);
                    toast.success("Updated sucessfully.");
                }
                else if (response.data == "EXIST") {
                    setLoaderOption(false);
                    toast.error("This account is already exists..");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Account master update err " + err);
                toast.error("Something went wrong, please check.")
            })
    };

    const ClearData = () => {
        setGroupDisableFlag(false);
        setAccountNameMr("");
        setAccountNameEn("");
        setId("");
        setOpeningBalance("");
        setIsReceipt("");
        setIsPayment("");
        setAccountCode("");
        setGroupSelUpdate("");
        setSearchTerm("");
        setGroupId(null);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/ac02/');
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.accountMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.accountMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    // बँक ग्रुप चे अकाऊंट हे शाळा प्रमाणे तयार करता येतील व इतर ग्रुप चे अकाऊंट हे संस्था स्थरावर असतील


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <StyledEngineProvider injectFirst>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '500px' }}>

                        <div className="row">
                            <div className="col-sm-7">
                                <p className='' style={{ backgroundColor: "#FA6969", borderRadius: "4px", fontWeight: "600", fontSize: "13px", margin: "4px", padding: "4px", color: "#fff", lineHeight: "1.4" }}>* बँक ग्रुप चे अकाऊंट हे शाळा प्रमाणे तयार करता येतील व इतर ग्रुप चे अकाऊंट हे संस्था स्थरावर असतील.</p>
                            </div>
                        </div>

                        <form onSubmit={onSubmit}>
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group:</label>
                                <div className='col-sm-4'>
                                    <Autocomplete
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        disabled={groupDisableFlag}
                                        id="combo-box-demo"
                                        onChange={(e, newValue) => {
                                            setGroupId(newValue);
                                            if (newValue !== null) {
                                                setGroupSelUpdate(newValue.id);
                                                accountCodeGet(newValue.id);
                                            }
                                            else if (newValue == null) {
                                                setAccountCode('');
                                            }
                                            //console.log(newValue);
                                        }}
                                        value={groupId}
                                        options={groupData}
                                        onKeyPress={(e) => (e.key == "Enter") ? groupId : ""}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.groupCode + " - " + option.groupNameMr : option.groupCode + " - " + option.groupNameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} label="Select Group" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Account Code :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        type="number"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Account Code"
                                        variant="outlined"
                                        margin='dense'
                                        value={accountCode}
                                        onChange={e => setAccountCode(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? accountCode : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120, readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Account Name:</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        disabled={groupDisableFlag}
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && accountNameMr.length === 0) ? e.preventDefault() : ""}
                                        type="text"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Account Name Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        value={accountNameMr}
                                        onChange={(e) => setAccountNameMr(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? accountNameMr : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        disabled={(groupDisableFlag)}
                                        onKeyDown={(e) => (e.key === " " && accountNameEn.length === 0) ? e.preventDefault() : ""}
                                        type="text"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Account Name English"
                                        variant="outlined"
                                        margin='dense'
                                        value={accountNameEn}
                                        onChange={(e) => setAccountNameEn(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? accountNameEn : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Opening Balance:</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && openingBalance.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Opening Balance"
                                        variant="outlined"
                                        margin='dense'
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        value={openingBalance}
                                        onChange={(e) => setOpeningBalance(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? openingBalance : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    //inputProps={{ max: 8 }}
                                    //onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8) }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <div className='offset-sm-2 col-sm-3'>
                                    {/* {CheckBoxData.map((item) => {
                                        return (
                                            <> */}
                                    <FormControlLabel control={<Checkbox checked={isReceipt} size='small'
                                        onChange={HandleReceiptCheckBox} />} label="R" />
                                    <FormControlLabel control={<Checkbox checked={isPayment} size='small'
                                        onChange={HandlePaymentCheckBox} />} label="P" />
                                    {/* </>
                                        )
                                    })} */}
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-2">
                                    <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                    <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                    <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                </div>
                            </div>
                        </form>

                        {/* <div>
                            <MasterFormAccountMasterTable
                                TableHeading={TableHeading}
                                data={data}
                                setFilteredData={setFilteredData}
                                filteredData={filteredData}
                                groupData={groupData}
                                groupId={groupId}
                                getData={getData}
                                setData={setData}
                                setAccountNameMr={setAccountNameMr}
                                setAccountNameEn={setAccountNameEn}
                                setOpeningBalance={setOpeningBalance}
                                setAccountCode={setAccountCode}
                                setIsReceipt={setIsReceipt}
                                setIsPayment={setIsPayment}
                                setId={setId}
                                setGroupId={setGroupId}
                                setSelUpdate={setGroupSelUpdate}
                                CheckBoxData={CheckBoxData}
                                saveBtnStyle={saveBtnStyle}
                                updateBtnStyle={updateBtnStyle}
                                displayNoneBtnStyle={displayNoneBtnStyle}
                                setSaveBtn={setSaveBtn}
                                setUpdateBtn={setUpdateBtn}
                                setGroupDisableFlag={setGroupDisableFlag}
                                setSaveBtnDisabled={setSaveBtnDisabled}
                                setUpdateBtnDisabled={setUpdateBtnDisabled}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                            />
                        </div> */}
                    </div>
                    <ToastContainer position="top-right" theme="colored" />
                </StyledEngineProvider>
            </>
        )
    }

};

export default MasterFormAccountMaster;
