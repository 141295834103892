import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Loader from "../../CommonComponent/Loader";
import Paper from "@mui/material/Paper";
import { BaseURLAccountMaster } from '../../CommonServices/AccountMasterAPIURL';
import {
    VoucherEntryNoGetMapping, AccountCodeGetMapping, VoucherEntryGetData, VoucherEntryPostMapping,
    VoucherEntryPutMapping, getClosingBalanceAmtAPIURL, getAccClosingBalAmtAPIURL, getDualSideVoucherEntryTypeAPI
} from '../../CommonServices/VoucherEntryAPIURL';
import TransactionModeData from '../../CommonComponent/LocalJSON/TransactionModeData';
import { BaseURLNarrationMaster } from '../../CommonServices/NarrationMasterAPIURL'


// Forms
import CashEntryForm from './DualVoucherForms/CashEntryForm';
import BankEntryForm from './DualVoucherForms/BankEntryForm';
import JVEntryForm from './DualVoucherForms/JVEntryForm';


const filter = createFilterOptions();

const ReaceiptData = [
    {
        id: 1,
        Label1: "पावती / Receipt :",
        Label2: "चलन / Payment :"
    },
]



const DualVoucherEntryForm = () => {
    const authUser = useSelector((state) => state.user.value);
    let { vno, EditFlag } = useParams();
    const [titleId, setTitleId] = useState("Dual Voucher Entry");
    const navigate = useNavigate();

    // Form Type
    const [formType, setFormType] = useState(0)

    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Dual Add Voucher")
            setFormType(1)
        }
        else if (EditFlag == 2) {
            setTitleId("Dual Update Voucher")

            if (authUser.deptId !== null && authUser.branchId !== null && authUser.sessionYear !== null) {

                axios.get(`${getDualSideVoucherEntryTypeAPI}?vno=${vno}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
                    .then((res) => {
                        if (res.data !== null) {
                            setFormType(res.data)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }

        }

    }, [vno, authUser]);









    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 0px 2px grey", marginTop: "16px", minHeight: "280px" }}>
                <div className="row">
                    <div className="col-sm-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Voucher Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formType}
                                label="Voucher Type"
                                onChange={(e) => setFormType(e.target.value)}
                                size='small'
                            >
                                <MenuItem value={1}>Cash</MenuItem>
                                <MenuItem value={2}>Bank</MenuItem>
                                <MenuItem value={3}>JV Entry</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>


                {(() => {

                    if (formType == 0) {
                        return ""
                    }
                    else if (formType == 1) {
                        return <CashEntryForm />
                    }
                    else if (formType == 2) {
                        return <BankEntryForm />
                    }
                    else if (formType == 3) {
                        return <JVEntryForm />
                    }

                })()}

            </div>
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}




export default DualVoucherEntryForm