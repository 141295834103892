import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../../CommonServices/APIURL";

//localhost:8080/institute/sss/endowment/endowmentSubjects
export const getSubjectEndowment = BaseURL + "endowment/endowmentSubjects";

//localhost:8080/institute/sss/endowment/saveEndowmentSubjects
export const saveSubjectEndowment = BaseURL + "endowment/saveEndowmentSubjects";

//localhost:8080/institute/sss/endowment/updateEndowmentSubject
export const updateSubjectEndowment = BaseURL + "endowment/updateEndowmentSubject";

//localhost:8080/institute/sss/endowment/deleteEndowmentSubject/2
export const deleteSubjectEndowment = BaseURL + "endowment/deleteEndowmentSubject/";