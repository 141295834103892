import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";
import { dBaseURL } from "./ExamReportAPIURL";

//get Exam
export const getExamData = BaseURLMaster + "Exams/";

//get subExam By examId
export const getSubExamByExamId= BaseURLMaster + "getSubExamsByExamId/";

//get Class DivisionList
export const BaseUrlExamAllocation= BaseURL + "academics/exam/"
export const getClassDivision= BaseUrlExamAllocation + "getClassAndDivisionList";
//export const getClassDivision= dBaseURL + "academics/exam/getClassAndDivisionList";
export const saveClassDivByExam= BaseUrlExamAllocation + "saveExamAllocation/";
//export const saveClassDivByExam= dBaseURL + "academics/exam/saveExamAllocation/";

// Exam Allocation
// /institute/sss/academics/exam/
// Class Division list
// GetMapping   /getClassAndDivisionList
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("examid") long examId, @RequestParam("subexamid") long subExamId

// -------------------------------------------------
// Save Exam Allocation
// PostMapping /saveExamAllocation
// @RequestBody List <ExamAllocation> examAllocations
// long id
// long classId
// long divisionId
// long examId
// long subExamId
// long sessionYear
// long sectionId
//  long branchId