import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { getClassWithDiv, generalRegisterReport, generalRegisterClassDivWise, generalRegExcelByClassDiv, generalRegExcelByAllStudents } from '../Services/GeneralRegisterAPIURL';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const GeneralRegister = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "General Register";

    const [loaderOption, setLoaderOption] = useState(false);


    const [view, setView] = useState(1);
    const [option, setOption] = useState(1);
    const [data, setData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [languageId, setLanguageId] = useState(1);
    const [subOption, setSubOption] = useState(1);
    const [year, setYear] = useState('');

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                })
        }
    }

    const allStudentWiseReport = async () => {
        setLoaderOption(true);
        await axios.get(`${generalRegisterReport}?langId=${languageId}&subOption=${subOption}&acYear=${(subOption == 2) ? year : authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")

                }
                else if (response.data != null) {
                    console.log(response.data)
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("General Register Report failed: " + error)
            })
    }

    const printGeneralregisterClassDivWise = async () => {
        setLoaderOption(true);
        //console.log(`${generalRegisterClassDivWise}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
        await axios.get(`${generalRegisterClassDivWise}?langId=${languageId}&subOption=${subOption}&acYear=${(subOption == 2) ? year : authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found for this class-division.")

                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("General Register Report failed: " + error)
            })
    }

    const allStudentWiseExcelReport = async () => {
        setLoaderOption(true);
        await axios.get(`${generalRegExcelByAllStudents}?langId=${languageId}&subOption=${subOption}&acYear=${(subOption == 2) ? year : authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")

                }
                else if (response.data != null) {

                    //console.log(`${getMasterReportAPIURL}${response.data}`)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("General Register Report failed: " + error)
            })
    }

    const printGeneralregisterExcelClassDivWise = async () => {
        setLoaderOption(true);
        // console.log(`${generalRegExcelByClassDiv}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
        await axios.get(`${generalRegExcelByClassDiv}?langId=${languageId}&subOption=${subOption}&acYear=${(subOption == 2) ? year : authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
            .then((response) => {
                //console.log(response.data)
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found for this class-division.")

                }
                else if (response.data != null) {
                    //console.log(response.data)     

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("General Register Report failed: " + error)
            })
    }

    const onSubmit = e => {
        e.preventDefault();
        if (option == 2 && view == 1) {
            printGeneralregisterClassDivWise();
        }
        else if (option == 1 && view == 1) {
            allStudentWiseReport();
        }
        else if (option == 2 && view == 2) {
            printGeneralregisterExcelClassDivWise();
        }
        else if (option == 1 && view == 2) {
            allStudentWiseExcelReport();
        }
    }

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>

                    <form onSubmit={onSubmit}>
                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Language :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={languageId}
                                        label="Select Language"
                                        onChange={(e) => {
                                            setLanguageId(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={1}>Marathi</MenuItem>
                                        <MenuItem value={2}>English</MenuItem>
                                    </Select>
                                </FormControl>

                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>View</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={view}
                                        label="View"
                                        onChange={(e) => {
                                            setView(e.target.value);
                                            setOption(1);
                                            setClassDivObj(null);
                                        }}
                                    >
                                        <MenuItem value={1}>PDF</MenuItem>
                                        <MenuItem value={2}>Excel</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={option}
                                        label="Option"
                                        onChange={(e) => {
                                            setOption(e.target.value);
                                            setClassDivObj(null);
                                        }}
                                    >
                                        <MenuItem value={1}>All Students</MenuItem>
                                        <MenuItem value={2}>Class-Division Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                            {(() => {
                                if (option == 2) {
                                    return (
                                        <>

                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "12px" }}>Class-Division :</label>
                                            <div className='col-sm-3'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    options={data}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                    size="small"
                                                    value={classDivObj}
                                                    onChange={(event, newValue) => {
                                                        setClassDivObj(newValue);
                                                    }}
                                                    renderInput={params => (
                                                        <TextField {...params} label="Select Class-Division" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required={(option == 2) ? true : false} />
                                                    )}
                                                />
                                            </div>

                                        </>
                                    )
                                }
                                // else if (option == 1 && view == 1) {
                                //     return (
                                //         <>
                                //             <div className="row mt-3">
                                //                 <div className="col-sm-4 offset-sm-2">
                                //                     <button type="button" onClick={() => allStudentWiseReport()} className="btn btn-primary btn-sm">Show</button>
                                //                 </div>
                                //             </div>
                                //         </>
                                //     )
                                // }
                                // else if (option == 2 && view == 2) {
                                //     return (
                                //         <>
                                //             <div className="row my-2">
                                //                 <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "12px" }}>Class-Division :</label>
                                //                 <div className='col-sm-3'>
                                //                     <Autocomplete
                                //                         id="combo-box-demo"
                                //                         PaperComponent={({ children }) => (
                                //                             <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                //                         )}
                                //                         options={data}
                                //                         style={{ fontSize: '14px' }}
                                //                         getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                //                         size="small"
                                //                         value={classDivObj}
                                //                         onChange={(event, newValue) => {
                                //                             setClassDivObj(newValue);
                                //                         }}
                                //                         renderInput={params => (
                                //                             <TextField {...params} label="Select Class-Division" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                //                                 required={(option == 2 && view == 2) ? true : false} />
                                //                         )}
                                //                     />
                                //                 </div>
                                //             </div>

                                //             {/* <div className="row mt-3">
                                //                 <div className="col-sm-4 offset-sm-2">
                                //                     <button type="button" onClick={() => printGeneralregisterExcelClassDivWise()} disabled={(classDivObj == null || classDivObj == "") ? true : false} className="btn btn-primary btn-sm">Show</button>
                                //                 </div>
                                //             </div> */}
                                //         </>
                                //     )
                                // }
                                // else if (option == 1 && view == 2) {
                                //     return (
                                //         <>
                                //             <div className="row mt-3">
                                //                 <div className="col-sm-4 offset-sm-2">
                                //                     <button type="button" onClick={() => allStudentWiseExcelReport()} className="btn btn-primary btn-sm">Show</button>
                                //                 </div>
                                //             </div>
                                //         </>
                                //     )
                                // }
                            })()}
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Sub-Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={subOption}
                                        label="Sub-Option"
                                        onChange={(e) => {
                                            setSubOption(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={1}>All</MenuItem>
                                        <MenuItem value={2}>Year Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                            {(() => {
                                if (subOption == 2) {
                                    return (
                                        <>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Year :</label>
                                            <div className='col-sm-3'>
                                                <TextField
                                                    required={(subOption == 2) ? true : false}
                                                    fullWidth
                                                    onKeyDown={(e) => (e.key === " " && year.length === 0) ? e.preventDefault() : ""}
                                                    autoComplete='off'
                                                    size='small'
                                                    value={year}
                                                    onChange={e => setYear(e.target.value)}
                                                    id="outlined-basic"
                                                    label="Year"
                                                    variant="outlined"
                                                    margin='dense'
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            })()}
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-4 offset-sm-2">
                                <button type="submit" className="btn btn-primary btn-sm">Show</button>
                            </div>
                        </div>
                    </form>

                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default GeneralRegister