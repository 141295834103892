import React, { useState } from 'react'
import TextField from '@mui/material/TextField';

const NoOfStudTextfield3 = ({ initialVal1, initialVal2, initialVal3, text12, onChange, onInputChange }) => {

    const [mark1, setMark1] = useState(initialVal1);
    const [mark2, setMark2] = useState(initialVal2);
    const [mark3, setMark3] = useState(initialVal3);

    return (
        <>
            <TextField
                required={(text12 > 1 || text12 <= 4) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px" }}
                onWheel={(e) => e.target.blur()}
                value={mark1}
                onChange={(e) => setMark1(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2, mark3),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="1st टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <TextField
                required={(text12 > 1 || text12 <= 4) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px", marginLeft: '8px' }}
                onWheel={(e) => e.target.blur()}
                value={mark2}
                onChange={(e) => setMark2(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2, mark3),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="2nd टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <TextField
                required={(text12 > 1 || text12 <= 4) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px", marginLeft: '8px' }}
                onWheel={(e) => e.target.blur()}
                value={mark3}
                onChange={(e) => setMark3(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2, mark3),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="3rd टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />
        </>
    )
}


export default NoOfStudTextfield3