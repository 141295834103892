import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import PrintIcon from '@mui/icons-material/Print';
import { getFeeList, printYearlyFeeCollectionReport } from '../Services/FeeReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const YearlyFeeCollection = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Yearly Fee Collection";

    const [loaderOption, setLoaderOption] = useState(false);


    const getYearlyFeeCollectionReport = async () => {
        setLoaderOption(true);
        axios.get(`${printYearlyFeeCollectionReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)   
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                toast.error("Error Occurred, Try after sometime.");
                console.log("Yearly Fee Collection Report failed: " + error)
            })
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <div className=''>
                                <button
                                    type='button'
                                    onClick={() => getYearlyFeeCollectionReport()}
                                    className='btn btn-primary btn-sm mb-2'
                                //disabled={btnDisabled}
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>

                                {/* <button type="submit" className="btn btn-danger btn-md" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/")}>Exit</button> */}
                            </div>
                        </div>
                    </div>
            }

            <ToastContainer position="top-right" theme="colored" />

        </>
    )
}

export default YearlyFeeCollection