import { dBaseURL, BaseURL } from "../CommonServices/APIURL";

//localhost:8080/institute/sss/alumniRegistration/saveAlumniRegistration
// {
//     "firstName": "firstName",
//     "middleName": "middleName",
//     "lastName": "lastName",
//     "email": "email",
//     "mobileNo": "+919545875878",
//     "yearOfPassing": "2022-23",
//     "dateOfBirth": "1999-12-12",
//     "profession": "profession",
//     "maritalStatus": "maritalStatus",
//     "city": "city",
//     "state": "state",
//     "pinCode": 416304,
//     "address": "address",
//     "sessionYear": 2022,
//     "branchId": 1
// }
export const saveAluminiRegistrationAPIURL = BaseURL + "alumniRegistration/saveAlumniRegistration";