import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment/moment";
import PrintIcon from '@mui/icons-material/Print';

const SainikSchoolConsolidatedReportTable = ({ TableHeading7, setData, setFilteredData, data, filteredData, optionId,
    schoolNm, resolutionNo, resolutionDate, printAllBudgetEntryReport, actualSchoolReceiptHostelBudget,
    actualSchoolPaymentHostelBudget, studentWiseBudget }) => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);





    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div className="">
                        <form>
                            <div className="row mt-5" >
                                <div className="mb-1">
                                    <div style={{ float: "left", }}>
                                        <span className='mt-3' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{schoolNm}</span><br />
                                        <span className='' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>ठराव नंबर आणि तारीख : <span style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{(resolutionNo != '' && resolutionDate != '') ? `${resolutionNo} / ${moment(resolutionDate).format("DD-MM-YYYY")}` : ''}</span></span>
                                    </div>
                                    <div style={{ float: "right" }}>
                                        <button
                                            style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                            type='button'
                                            onClick={() => printAllBudgetEntryReport()}
                                            className='btn btn-sm'
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                <span className='mb-2' style={{ fontSize: "16px", color: "#DC2C0C", fontWeight: "600" }}>सैनिक शाळा वसतिगृह अंदाज पत्रक {authUser.sessionYear}</span>
                            </div> */}

                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            {TableHeading7.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `${item.textAlign}` }} scope="col">{item.label}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            filteredData.map((item, index) => {
                                                return (
                                                    <>
                                                        {(() => {
                                                            if (item.optionId == 1) {
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "250px" }}>{(item.schoolFee).toFixed('2')}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4", textAlign: "center" }}>{item.accNameMr}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "250px" }}>0.00</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                            else if (item.optionId == 2) {
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "250px" }}>0.00</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4", textAlign: "center" }}>{item.accNameMr}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "250px" }}>{(item.schoolFee).toFixed('2')}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                        })()}

                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualSchoolReceiptHostelBudget)}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", lineHeight: "1.4", textAlign: "center" }}>एकूण</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualSchoolPaymentHostelBudget)}</td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>

                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">तपशील</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">वसतिगृह किर्द</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण जमा रुपये (शाळा फी कीर्द)</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>
                                                {(actualSchoolReceiptHostelBudget)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण खर्च रुपये (शाळा फी कीर्द)</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>
                                                {(actualSchoolPaymentHostelBudget)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>फरक रुपये (शाळा फी कीर्द)</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>
                                                {(parseFloat(actualSchoolReceiptHostelBudget) - parseFloat(actualSchoolPaymentHostelBudget)).toFixed('2')}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">तपशील</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">वसतिगृह किर्द</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण खर्च रुपये (सत्र फी कीर्द)</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>
                                                {(actualSchoolPaymentHostelBudget)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>प्रति विद्यार्थी येणार खर्च</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>
                                                {studentWiseBudget}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}


export default SainikSchoolConsolidatedReportTable