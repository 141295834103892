import React, { useState } from 'react'


const BoardExamEntryInput = ({ initialText1, initialText2, initialText3, markTypeData, onChange }) => {

    const txtBox = {
        borderRadius: "5px",
        padding: "4px",
        fontSize: "15px",
        fontWeight: "500",

    }

    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    const [text3, setText3] = useState(initialText3);


    return (
        <>
            <td style={{ width: '170px', fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <select value={text1}
                    onChange={(e) => { setText1(e.target.value); setText3((e.target.value == 1) ? 0 : ''); setText2(0) }}
                    className='form-select form-select-sm'
                    style={{ width: "130px", fontSize: "14px" }}
                    onBlur={() => { onChange(text1, text2, text3) }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                //autoFocus
                >
                    {markTypeData.map((e) => {
                        return (
                            <option value={e.id} key={e.id}>
                                {e.markTypeName}
                            </option>
                        )
                    })}

                </select>
            </td>

            <td style={{ width: "120px", height: "70px", fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <input
                    className='form-control form-control-sm'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit3(true)}
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    onBlur={() => { onChange(text1, text2, text3) }}
                    style={txtBox}
                    onChange={(e) => { setText2(e.target.value); }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                    value={text2}
                    min='0'
                />
            </td>


            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <input
                    type={(text1 == 1) ? "number" : "text"}
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit5(true)}
                    onBlur={() => { onChange(text1, text2, text3) }}
                    style={txtBox}
                    onChange={(e) => setText3(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                    value={text3}
                    min='0'
                />
            </td>


        </>
    )
}

export default BoardExamEntryInput