import React from 'react'
import { Modal, Button } from "react-bootstrap";

const AccTransferModal = (props) => {
    return (
        <>
            <Modal show={props.modal}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to update A/C opening to next year ({parseInt(props.authUser.sessionYear) + 1}) ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setModal(false)}>Close</Button>
                    <Button variant="danger" onClick={() => props.saveAccClosingTransfer()}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AccTransferModal