import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { getRegisterTypeDataAPIURL } from '../../LibraryServices/RegisterTypeMasterAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import { generateBookBarcodeAPIURL } from '../Services/CirculationReportAPIURL';

const useStyles = makeStyles({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
});

const barcodeSizeAllData = [
    {
        id: 1,
        barcodeSizeName: "A4 Paper - 65 Sticker"
    },
    {
        id: 2,
        barcodeSizeName: "A4 Paper - 48 Sticker"
    },
    {
        id: 3,
        barcodeSizeName: "Roll Paper - 2 Sticker"
    },
]

const BookBarcode = () => {
    const titleId = "Book Barcode";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState('');
    const [option, setOption] = useState(1);
    const [registerTypeData, setRegisterTypeData] = useState([]);
    const [registerTypeObj, setRegisterTypeObj] = useState(null);
    const [barcodeSize, setBarcodeSize] = useState(null);
    const [selectiveAccNo, setSelectiveAccNo] = useState('');
    const [accNoFrom, setAccNoFrom] = useState('');
    const [accNoTo, setAccNoTo] = useState('');
    const [noOfCopies, setNoOfCopies] = useState(1);


    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {

        if (authUser.branchId != null) {
            await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setRegisterTypeData(res.data);
                })
        }
    }

    const printBookBarcodeReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        console.log(`${generateBookBarcodeAPIURL}?registerType=${registerTypeObj.id}&accFrom=${(option == 2) ? 0 : accNoFrom}&accTo=${(option == 2) ? 0 : accNoTo}&selectedAccNo=${selectiveAccNo}&option=${option}&barcodeSize=${barcodeSize.id}&noOfCopy=${noOfCopies}&bid=${authUser.branchId}`)
        await axios.get(`${generateBookBarcodeAPIURL}?registerType=${registerTypeObj.id}&accFrom=${(option == 2) ? 0 : accNoFrom}&accTo=${(option == 2) ? 0 : accNoTo}&selectedAccNo=${selectiveAccNo}&option=${option}&barcodeSize=${barcodeSize.id}&noOfCopy=${noOfCopies}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.");
                }
                else {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                }
            }).catch(err => {
                console.log("Book Barcode error:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }


    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <form onSubmit={printBookBarcodeReport}>
                                <div className="form-data">

                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Register Type :</label>
                                        <div className="col-sm-3">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={registerTypeData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={(option) => option.registerTypeName}
                                                size="small"
                                                onChange={(event, newValue) => {
                                                    setRegisterTypeObj(newValue);
                                                    setOption(1);
                                                    setBarcodeSize(null);
                                                    setSelectiveAccNo('');
                                                    setAccNoFrom('');
                                                    setAccNoTo('');
                                                    setNoOfCopies(1);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? registerTypeObj : ""}
                                                value={registerTypeObj}
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Register Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                        <div className='col-sm-3' >
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    fullWidth
                                                    required
                                                    margin="dense"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Option"
                                                    onChange={(e) => {
                                                        setOption(e.target.value);
                                                        setBarcodeSize(null);
                                                        setSelectiveAccNo('');
                                                        setAccNoFrom('');
                                                        setAccNoTo('');
                                                        setNoOfCopies(1);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? option : ''}
                                                >
                                                    <MenuItem value={1}>Multiple</MenuItem>
                                                    <MenuItem value={2}>Selective</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>


                                    {(() => {
                                        if (option == 1) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Acc No. From :</label>
                                                        <div className="col-sm-2">
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                required={(option == 1) ? true : false}
                                                                value={accNoFrom}
                                                                onChange={(e) => setAccNoFrom(e.target.value)}
                                                                onKeyPress={(e) => (e.key == "Enter") ? accNoFrom : ''}
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="From"
                                                                variant="outlined"
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />
                                                        </div>

                                                        <label className="" style={{ width: "90px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >To :</label>
                                                        <div className="col-sm-2">
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                required={(option == 1) ? true : false}
                                                                value={accNoTo}
                                                                onChange={(e) => setAccNoTo(e.target.value)}
                                                                onKeyPress={(e) => (e.key == "Enter") ? accNoTo : ''}
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="To"
                                                                variant="outlined"
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Acc No. :</label>
                                                        <div className="col-sm-5">
                                                            <TextField
                                                                fullWidth
                                                                required={(option == 2) ? true : false}
                                                                value={selectiveAccNo}
                                                                onChange={(e) => setSelectiveAccNo(e.target.value)}
                                                                onKeyPress={(e) => (e.key == "Enter") ? selectiveAccNo : ''}
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Acc No"
                                                                variant="outlined"
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Barcode size :</label>
                                        <div className="col-sm-5">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={barcodeSizeAllData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={(option) => option.barcodeSizeName}
                                                size="small"
                                                onChange={(event, newValue) => {
                                                    setBarcodeSize(newValue);
                                                    setNoOfCopies(1);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? barcodeSize : ""}
                                                value={barcodeSize}
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Barcode size" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >No. Of Copies :</label>
                                        <div className="col-sm-2">
                                            <TextField
                                                fullWidth
                                                required
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                value={noOfCopies}                                               
                                                onChange={(e) => setNoOfCopies(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? noOfCopies : ''}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="No. Of Copies"
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                InputProps={{ inputProps: { min: 1 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default BookBarcode