import React, { useEffect, useState } from 'react'
import { getHostelDetailsAPIURL } from '../CommonServices/DashboardAPIURL';
import { useSelector } from 'react-redux';
import axios from 'axios';
import building from '../assets/dashboard/building.png'
import room from '../assets/dashboard/room.png'
import bed from '../assets/dashboard/bed.png'
import allocatedBed from '../assets/dashboard/allocatedBed.png'



const HostelDashboard = () => {
    const authUser = useSelector((state) => state.user.value);
    const [data, setData] = useState([]);

    useEffect(() => {
        getAPIData();
    }, [authUser])


    const getAPIData = async () => {
        // console.log(`${getHostelDetailsAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`);
        await axios(`${getHostelDetailsAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then(res => {
                setData(res.data);
            })
    }

    return (
        <>
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                <div className="row">
                    <div className="col-md-4 col-xl-3">
                        <div className="card bg-c-pink order-card">
                            <div className="card-block">

                                <span className="text-right" >
                                    <img src={building} className="f-left mt-2" style={{ width: "80px" }} />
                                    <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{data.noOfBuilding}</span>
                                    <br /><br /><br />
                                    <h6>No. Of Buildings</h6>
                                </span>
                                {/* <p className="m-b-0">Completed Orders<span className="f-right">351</span></p> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-xl-3">
                        <div className="card bg-c-blue order-card">
                            <div className="card-block">

                                <span className="text-right" >
                                    <img src={room} className="f-left mt-2" style={{ width: "80px" }} />
                                    <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{data.noOfRooms}</span>
                                    <br /><br /><br />
                                    <h6>No. Of Rooms</h6>
                                </span>
                                {/* <p className="m-b-0">Completed Orders<span className="f-right">351</span></p> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-xl-3">
                        <div className="card bg-c-yellow order-card">
                            <div className="card-block">

                                <span className="text-right" >
                                    <img src={bed} className="f-left mt-2" style={{ width: "80px" }} />
                                    <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{data.noOfBeds}</span>
                                    <br /><br /><br />
                                    <h6>No. Of Beds</h6>
                                </span>
                                {/* <p className="m-b-0">Completed Orders<span className="f-right">351</span></p> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-xl-3">
                        <div className="card bg-c-green order-card">
                            <div className="card-block">

                                <span className="text-right" >
                                    <img src={allocatedBed} className="f-left mt-2" style={{ width: "80px" }} />
                                    <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{data.allocatedBed}</span>
                                    <br /><br /><br />
                                    <h6>Allocated Bed</h6>
                                </span>
                                {/* <p className="m-b-0">Completed Orders<span className="f-right">351</span></p> */}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-4 col-xl-3">
                        <div className="card bg-c-five order-card">
                            <div className="card-block">

                                <span className="text-right" >
                                    <img src={bed} className="f-left mt-2" style={{ width: "80px" }} />
                                    <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{data.vacantBed}</span>
                                    <br /><br /><br />
                                    <h6>Vecant Bed</h6>
                                </span>
                                {/* <p className="m-b-0">Completed Orders<span className="f-right">351</span></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HostelDashboard