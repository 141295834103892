import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import PrintIcon from '@mui/icons-material/Print';

const SansthaConsolidatedBudgetReportTable = ({ futureYear, receiptData, paymentData, setReceiptData, setPaymentData,
    differenceAmtLastYr, differenceAmtNextYr, receiptTotalLastYr, paymentTotalLastYr, receiptTotalNextYr, paymentTotalNextYr,
    printSansthReport }) => {
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div className="">
                        <form>
                            <div className="row mt-5 mb-2" >
                                <div className="">
                                    {/* <div style={{ float: "left", }}>
                                        <span className='mt-3' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{schoolNm}</span><br />
                                        <span className='mt-1' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>शाळा समिती ठराव नंबर आणि तारीख : <span style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{(resolutionNo != '' && resolutionDate != '') ? `${resolutionNo} / ${moment(resolutionDate).format("DD-MM-YYYY")}` : ''}</span></span>
                                    </div> */}
                                    <div style={{ float: "right" }}>
                                        <button
                                            style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                            type='button'
                                            onClick={() => printSansthReport()}
                                            className='btn btn-sm mb-2'
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                    <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span className='mt-1' style={{ fontSize: "16px", color: "#DC2C0C", fontWeight: "600", justifyContent: "center" }}>संस्था कार्यालयाचे सन  {parseInt(authUser.sessionYear) + 1}-{parseInt(futureYear) + 1}  चे अंदाजपत्रक जमा बाजू</span>
                                    </div>
                                </div>
                            </div>


                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>

                                            <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">तपशील (जमा)</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">{parseInt(authUser.sessionYear)}-{futureYear} चा अंदाज</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">{parseInt(authUser.sessionYear) + 1}-{parseInt(futureYear) + 1} चा अंदाज</th>

                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            receiptData.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.accNameMr}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{(item.budgetLastYear).toFixed('2')}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{(item.budgetNextYear).toFixed('2')}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "600", }}>एकूण</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{receiptTotalLastYr}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{receiptTotalNextYr}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "600", }}>तूट</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{(differenceAmtLastYr < 0) ? Math.abs(parseFloat(differenceAmtLastYr)).toFixed('2') : parseFloat(0).toFixed('2')}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{(differenceAmtNextYr < 0) ? Math.abs(parseFloat(differenceAmtNextYr)).toFixed('2') : parseFloat(0).toFixed('2')}</td>
                                        </tr>
                                        <tr style={{ borderTop: "2px solid #959595" }}>
                                            <td style={{ fontSize: "14px", fontWeight: "600", }}>सर्व एकूण</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{Math.abs(parseFloat(receiptTotalLastYr) - parseFloat((differenceAmtLastYr < 0) ? differenceAmtLastYr : 0)).toFixed('2')}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{Math.abs(parseFloat(receiptTotalNextYr) - parseFloat((differenceAmtNextYr < 0) ? differenceAmtNextYr : 0)).toFixed('2')}</td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>

                            <div className="mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <span className='mt-1' style={{ fontSize: "16px", color: "#DC2C0C", fontWeight: "600", textAlign: "center" }}>संस्था कार्यालयाचे सन  {parseInt(authUser.sessionYear) + 1}-{parseInt(futureYear) + 1}  चे अंदाजपत्रक खर्च बाजू</span>
                            </div>

                            <div div className='table-responsive mb-5' >
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>

                                            <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">तपशील (खर्च)</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">{parseInt(authUser.sessionYear)}-{futureYear} चा अंदाज</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">{parseInt(authUser.sessionYear) + 1}-{parseInt(futureYear) + 1} चा अंदाज</th>

                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            paymentData.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.accNameMr}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{(item.budgetLastYear).toFixed('2')}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{(item.budgetNextYear).toFixed('2')}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "600", }}>एकूण</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{paymentTotalLastYr}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{paymentTotalNextYr}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "600", }}>शिल्लक</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{(differenceAmtLastYr > 0) ? differenceAmtLastYr : parseFloat(0).toFixed('2')}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{(differenceAmtNextYr > 0) ? differenceAmtNextYr : parseFloat(0).toFixed('2')}</td>
                                        </tr>
                                        <tr style={{ borderTop: "2px solid #959595" }}>
                                            <td style={{ fontSize: "14px", fontWeight: "600", }}>सर्व एकूण</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{(parseFloat(paymentTotalLastYr) + parseFloat((differenceAmtLastYr > 0) ? differenceAmtLastYr : 0)).toFixed('2')}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right", width: "180px" }}>{(parseFloat(paymentTotalNextYr) + parseFloat((differenceAmtNextYr > 0) ? differenceAmtNextYr : 0)).toFixed('2')}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}

export default SansthaConsolidatedBudgetReportTable