import { BaseURL, dBaseURL } from '../../CommonServices/APIURL';

//localhost:8080/institute/sss/payroll/master/getHolidayMarkingList?year=2022&bid=1
export const getHolidayMarkingAPIURL = BaseURL + "payroll/master/getHolidayMarkingList";

//localhost:8080/institute/sss/payroll/master/saveHolidayMarking
// {
//     "holidayType": 2,
//     "holidayDateFrom": "2023-08-15",
//     "holidayDateTo": "2023-03-31",
//     "holidayDay": "Sunday",
//     "detail": "Independance Day",
//     "sessionYear": 2022,
//     "branchId": 1
// }
export const saveHolidayMarkingAPIURL = BaseURL + "payroll/master/saveHolidayMarking";

//localhost:8080/institute/sss/payroll/master/deleteHolidayMarking?id=1&holidayType=1&year=2022&bid=1
export const deleteHolidayMarkingAPIURL = BaseURL + "payroll/master/deleteHolidayMarking";
