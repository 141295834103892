import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import { getSubjects } from '../Services/CatalogReportAPIURL'
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const CatalogSubject = () => {
    const classes = useStyles();
    const titleId = "Catalog - Subject";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(authUser.fromDate);
    const defaultMaxDate = new Date(authUser.toDate);

    const [dateFrom, setDateFrom] = useState(null);
    console.log(moment(dateFrom).format("MM/YYYY"))
    const [id, setId] = useState();
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        await axios(`${getSubjects}`)
            .then((response) => {
                setSubjectData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form >
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row">
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date"
                                            value={dateFrom}
                                            views={['year', 'month']}
                                            inputFormat="MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={subjectData}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        value={subjectObj}
                                        onChange={(event, newvalue) => {
                                            setSubjectObj(newvalue);
                                        }}

                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Subject" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-1">
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }


}

export default CatalogSubject