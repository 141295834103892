import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { saveTitleMaster } from '../LibraryServices/TitleMasterAPIURL';
import { getAuthorMasterData } from '../LibraryServices/AuthorMasterAPIURL';
import { getPublisherMasterData } from '../LibraryServices/PublisherMasterAPIURL';

const AddNewTitleMasterModal = ({ showTitleModal, setShowTitleModal, getApiData }) => {
    const authUser = useSelector((state) => state.user.value);
    const handleClose = () => setShowTitleModal(false);
    const handleShow = () => setShowTitleModal(true);

    const [titleName, setTitleName] = useState('');
    const [subTitleName, setSubTitleName] = useState("");
    const [authorData, setAuthorData] = useState([]);
    const [authorObj, setAuthorObj] = useState([]);
    const [authorId, setAuthorId] = useState(null);
    const [publisherData, setPublisherData] = useState([]);
    const [publisherObj, setPublisherObj] = useState(null);

    useEffect(() => {
        getData();
    }, [authUser])
    

    const getData = async () => {
        if (authUser.branchId != null) {
            //console.log(`${getAuthorMasterData}?branchId=${authUser.branchId}`)
            await axios.get(`${getAuthorMasterData}?branchId=${authUser.branchId}`)
                .then(res => {
                    setAuthorData(res.data);
                })

            //console.log(`${getPublisherMasterData}?branchId=${authUser.branchId}`)
            await axios.get(`${getPublisherMasterData}?branchId=${authUser.branchId}`)
                .then(res => {
                    setPublisherData(res.data);
                })
        }
    }

    const saveBtnClick = async (e) => {
        //setLoaderOption(true);
        handleClose();
        e.preventDefault();
        const saveObj = {
            "titleName": titleName,
            "subTitleName": subTitleName,
            "authorId": authorId,
            "publisherId": publisherObj.id,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveTitleMaster, saveObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    //setLoaderOption(false);
                    toast.warn(`This title is already exists.`)
                }
                else if (response.data == "SAVED") {
                    setTitleName('');
                    setSubTitleName("");
                    setAuthorObj([]);
                    setAuthorId(null);
                    setPublisherObj(null);                   
                    getApiData();
                    //setLoaderOption(false);
                    toast.success("Saved successfully.")
                }

            }).catch(error => {
                //setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }



    return (
        <>
            <Modal show={showTitleModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "20px" }}>Add New Title</Modal.Title>
                </Modal.Header>
                <Form onSubmit={saveBtnClick}>
                    <Modal.Body>
                        <TextField
                            fullWidth
                            required
                            onKeyDown={(e) => (e.key === " " && titleName.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            margin='dense'
                            size='small'
                            variant="outlined"
                            style={{ width: "400px" }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onKeyPress={(e) => (e.key == "Enter") ? titleName : ""}
                            label="Title Name"
                            value={titleName}
                            onChange={(e) => setTitleName(e.target.value)}
                        />


                        <TextField
                            fullWidth
                            onKeyDown={(e) => (e.key === " " && subTitleName.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            margin='dense'
                            size='small'
                            style={{ width: "400px" }}
                            variant="outlined"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onKeyPress={(e) => (e.key == "Enter") ? subTitleName : ""}
                            label="Sub-Title Name"
                            value={subTitleName}
                            onChange={(e) => setSubTitleName(e.target.value)}
                        />

                        <Autocomplete
                            id="combo-box-demo"
                            multiple
                            disableCloseOnSelect
                            filterSelectedOptions
                            options={authorData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            style={{ fontSize: '14px', width: "400px" }}
                            getOptionLabel={option => option.authorName}
                            size="small"
                            onChange={(event, newValue) => {
                                setAuthorObj(newValue);
                                setAuthorId(newValue.map(e => e.id).join(','))
                            }}
                            onKeyPress={(e) => (e.key == "Enter") ? authorObj : ""}
                            value={authorObj}
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Select Author" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(authorObj.length == 0) ? true : false} />
                            )}
                        />

                        <Autocomplete
                            id="combo-box-demo"
                            options={publisherData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            style={{ fontSize: '14px', width: "400px" }}
                            getOptionLabel={option => option.name}
                            size="small"
                            onChange={(event, newValue) => {
                                setPublisherObj(newValue);
                            }}
                            onKeyPress={(e) => (e.key == "Enter") ? publisherObj : ""}
                            value={publisherObj}
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Select Publisher" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                            )}
                        />


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className="titleModalBtn">
                            Save
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default AddNewTitleMasterModal