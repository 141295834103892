import { BaseURL } from "../../CommonServices/APIURL";


//http://192.168.29.102:8081/institute-sss/institute/sss/academics/timeTable/saveTimeTableSlot
export const saveTimeTbaleSlotAPIURL = BaseURL + "academics/timeTable/saveTimeTableSlot";

//http://192.168.195.102:8081/institute-sss/institute/sss/academics/timeTable/getTimeTableSlot?year=2023&sid=3&bid=1
export const getTimeTableSlotDataAPIURL = BaseURL + "academics/timeTable/getTimeTableSlot";

//http://192.168.29.102:8081/institute-sss/institute/sss/academics/timeTable/getTimeTableSlotById?id=1
export const getTimeTableSlotForEditAPIURL = BaseURL + "academics/timeTable/getTimeTableSlotById";

//http://192.168.29.102:8081/institute-sss/institute/sss/academics/timeTable/updateTimeTableSlotDetail

// {
//     "id": 10,
//     "timeTableSlotId": 2,
//     "toTime": "09:10:00",
// }

export const updateTimePeriodTableSlotDataAPIURL = BaseURL + "academics/timeTable/updateTimeTableSlotDetail";

//updateTimeTableSlot
export const updateTimeSlotDataAPIURL = BaseURL + "academics/timeTable/updateTimeTableSlot";


//Total Time Table Report APIURL
//http://192.168.29.102:8081/institute-sss/institute/sss/academic/reports/timeTable/getTimeTableSlotReport?langId=1&year=2023&sid=3&bid=1
export const getTotalTimeSlotReportAPIURL = BaseURL + "academic/reports/timeTable/getTimeTableSlotReport";


//Employee time table
//localhost:8081/institute-sss/institute/sss/academic/reports/timeTable/getTimeTableReport?langId=1&option=1&empId=0&classId=0&divId=0&year=2023&sid=3&bid=1
export const printEmployeeTimeTableReportAPIURL = BaseURL + "academic/reports/timeTable/getTimeTableReport";

//http://192.168.195.102:8081/institute-sss/institute/sss/academic/reports/timeTable/getTeacherLectCountReport?langId=1&option=2&empId=25&year=2023&sid=3&bid=1
export const printTeacherLectureCountReportAPIURL = BaseURL + "academic/reports/timeTable/getTeacherLectCountReport";
 