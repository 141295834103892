import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../../CommonComponent/Loader';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import { generateDailyTransactionReport } from '../Services/CirculationReportAPIURL';

const useStyles = makeStyles({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
});

const DailyTransaction = () => {
    const titleId = "Daily Transaction";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState('');
    const [memberType, setMemberType] = useState(0);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const printDailyTransactionReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment(dateTo).format("DD/MM/YYYY") != 'Invalid date') {
            await axios.get(`${generateDailyTransactionReport}?langId=${authUser.branchMedium}&memberType=${memberType}&bid=${authUser.branchId}&dateFrom=${moment(dateFrom).format("YYYY-MM-DD")}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}&sessionYear=${authUser.sessionYear}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setLoaderOption(false);
                        window.open(`${getExportedReportAPIURL}${res.data}`)
                    }
                }).catch(err => {
                    console.log("Daily transaction err:" + err)
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "250px" }}>
                            {/* Form Data start */}
                            <form onSubmit={printDailyTransactionReport}>
                                <div className="form-data">

                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                    <div className='row my-2'>
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member Type :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Member Type</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    required
                                                    fullWidth
                                                    margin='dense'
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={memberType}
                                                    label="Member Type"
                                                    onChange={(e) => {
                                                        setMemberType(e.target.value);
                                                        setDateFrom(null);
                                                        setDateTo(null);
                                                    }}
                                                >
                                                    <MenuItem value={0}>All</MenuItem>
                                                    <MenuItem value={1}>Staff</MenuItem>
                                                    <MenuItem value={2} hidden={(authUser.deptId != 3) ? true : false}>Secondary Students</MenuItem>
                                                    <MenuItem value={3} hidden={(authUser.deptId != 4) ? true : false}>Higher-Secondary Students</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Date From :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="From"
                                                    value={dateFrom}
                                                    onChange={(newValue) => {
                                                        setDateFrom(newValue);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? dateFrom : ""}
                                                    inputFormat="dd/MM/yyyy"
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin='dense'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label className="" style={{ width: "60px", fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >To :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="To"
                                                    value={dateTo}
                                                    onChange={(newValue) => {
                                                        setDateTo(newValue);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? dateTo : ""}
                                                    inputFormat="dd/MM/yyyy"
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin='dense'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default DailyTransaction