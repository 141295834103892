import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';

const StudentAllowanceCheckBox = ({initialText1,initialText2,initialText3,onChange}) => {
    const [chkBox1, setChkBox1] = useState(initialText1);
    const [chkBox2, setChkBox2] = useState(initialText2);
    const [chkBox3, setChkBox3] = useState(initialText3);

    const handleChkBox1 = (e) => {
        setChkBox1(e.target.checked);
        if (e.target.checked == true) {
            setChkBox1(1);
        }
        else if (e.target.checked == false) {
            setChkBox1(0);
        }
    }

    const handleChkBox2 = (e) => {
        setChkBox2(e.target.checked);
        if (e.target.checked == true) {
            setChkBox2(1);
        }
        else if (e.target.checked == false) {
            setChkBox2(0);
        }
    }

    const handleChkBox3 = (e) => {
        setChkBox3(e.target.checked);
        if (e.target.checked == true) {
            setChkBox3(1);
        }
        else if (e.target.checked == false) {
            setChkBox3(0);
        }
    }

    return (
        <>
            <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px",width:"100px",textAlign:"center" }}>
                <Checkbox defaultChecked={(chkBox1 === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                    onBlur={()=>onChange(chkBox1,chkBox2,chkBox3)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(chkBox1,chkBox2,chkBox3) : ""}
                    onChange={(e) => {
                        let val = e.target.checked;
                        handleChkBox1(e);
                    }}
                />
            </td>
            <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px",width:"100px",textAlign:"center" }}>
                <Checkbox defaultChecked={(chkBox2 === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                    onBlur={()=>onChange(chkBox1,chkBox2,chkBox3)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(chkBox1,chkBox2,chkBox3) : ""}
                    onChange={(e) => {
                        let val = e.target.checked;
                        handleChkBox2(e);
                    }}
                />
            </td>
            <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px",width:"100px",textAlign:"center" }}>
                <Checkbox defaultChecked={(chkBox3 === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                    onBlur={()=>onChange(chkBox1,chkBox2,chkBox3)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(chkBox1,chkBox2,chkBox3) : ""}
                    onChange={(e) => {
                        let val = e.target.checked;
                        handleChkBox3(e);
                    }}
                />
            </td>
        </>
    )
}

export default StudentAllowanceCheckBox