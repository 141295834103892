import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

// In notice form show user(Role) list using following API
// /institute/sss/user/role/
// @GetMapping("/getRoles")

export const getUserRolesAPIURL=BaseURL+"user/role/getRoles";

//set 0 id in dropdawn as All option for user and branch

//Institutes get APIURL
export const BaseURLInstituteMaster=BaseURLMaster + "inst/Institutes/";


//Sanstha Message Save
//http://192.168.1.102:8080/institute/sss/sanstha/notice/uploadNoticeWithoutFile

export const sansthaMessageAPIURL =BaseURL+"sanstha/notice/uploadNotice";

export const sansthaMessageWithoutFileAPIURL =BaseURL+"sanstha/notice/uploadNoticeWithoutFile";

//getSansthaNoticeData
//roleId,bid

export const getSansthaNoticeDataAPIURL =BaseURL+"sanstha/notice/getSansthaNoticeData";


//Output File

export const sansthaNoticeFileLoaction=BaseIP+"/notices/";
 
//Get all notice records

export const getSansthaNoticeAllDataAPIURL=BaseURL+"sanstha/notice/getSansthaNoticeAllData"


// updateSansthaNoticeStatus
// statusId
// id

export const updateSansthaNoticeStatusAPIURL=BaseURL+"sanstha/notice/updateSansthaNoticeStatus";


//deleteSansthaNotice

export const deleteSansthaNoticeAPIURL=BaseURL+"sanstha/notice/deleteSansthaNotice/";



//Update Data===========================
//updateSansthaNoticeDataWithoutFile
//updateSansthaNoticeData


export const updateSansthaNoticeDataWithoutFileAPIURL=BaseURL+"sanstha/notice/updateSansthaNoticeDataWithoutFile";

export const updateSansthaNoticeDataAPIURL=BaseURL+"sanstha/notice/updateSansthaNoticeData";