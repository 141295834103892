import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";


//Admission Merit List APIURL
//localhost:8080/institute/sss/admission/reports/generateAdmissionMeritList?langId=1&divId=17&meritType=2&qualifyStatus=1&acYear=2022&sid=3&bid=1
export const admissionMeritListClassDivWiseReport = BaseURL + "admission/reports/generateAdmissionMeritList";

//localhost:8080/institute/sss/admission/reports/generateAdmissionMeritListForAllStudent?langId=1&meritType=2&qualifyStatus=1&acYear=2022&sid=3&bid=1
export const allAdmissionMeritListReport = BaseURL + "admission/reports/generateAdmissionMeritListForAllStudent";

//localhost:8080/institute/sss/admission/reports/generateAdmissionMeritListExcelFile?langId=1&divId=17&meritType=2&qualifyStatus=1&acYear=2022&sid=3&bid=1
export const printClassDivWiseAdmMeritListExcelReport = BaseURL + "admission/reports/generateAdmissionMeritListExcelFile";

//localhost:8080/institute/sss/admission/reports/generateAdmissionMeritListExcelFileForAllStudent?langId=1&meritType=2&qualifyStatus=1&acYear=2022&sid=3&bid=1
export const printAdmMeritListExcelReport = BaseURL + "admission/reports/generateAdmissionMeritListExcelFileForAllStudent";



//Admission Form sale Report APIURL
//localhost:8080/institute/sss/admission/reports/generateAdmissionFormSaleList?langId=1&divId=17&acYear=2022&sid=3&bid=1
export const printAdmFormSaleClassDivWiseAPIURL = BaseURL + "admission/reports/generateAdmissionFormSaleList";

//localhost:8080/institute/sss/admission/reports/generateAllClassAdmissionFormSaleList?langId=1&acYear=2022&sid=3&bid=1
export const printAdmFormSaleReportAPIURL = BaseURL + "admission/reports/generateAllClassAdmissionFormSaleList";



//Admission Form Receive Report APIURL
//localhost:8080/institute/sss/admission/reports/generateAllClassAdmissionFormReceiveList?langId=1&acYear=2022&sid=3&bid=1
export const printAdmFormReceivePdfAPIURL = BaseURL + "admission/reports/generateAllClassAdmissionFormReceiveList";

//localhost:8080/institute/sss/admission/reports/generateAdmissionFormReceiveList?langId=1&divId=17&acYear=2022&sid=3&bid=1
export const printAdmFormReceiveClassDivWisePdfAPIURL = BaseURL + "admission/reports/generateAdmissionFormReceiveList";

//localhost:8080/institute/sss/admission/reports/generateAdmissionFormReceiveListExcel?langId=1&divId=21&acYear=2022&sid=3&bid=36&phNo=12
export const printAdmFormReceiveClassDivWiseExcelReportAPIURL = BaseURL + "admission/reports/generateAdmissionFormReceiveListExcel";

//localhost:8080/institute/sss/admission/reports/generateAllClassAdmissionFormReceiveListExcel?langId=1&acYear=2022&sid=3&bid=36&phNo=12
export const printAdmFormReceiveExcelReportAPIURL = BaseURL + "admission/reports/generateAllClassAdmissionFormReceiveListExcel";
