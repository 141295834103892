import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import Loader from '../../CommonComponent/Loader';
import { getClassWithDiv } from '../../Academic/Services/StudentRollNoAPIURL';
import AddIcon from '@mui/icons-material/Add';
import GraceExamMarkInput from './GraceExamMarkEntryInputBox/GraceExamMarkInput';
import { getStudentDetailsForGraceExamMarkEntry, saveGraceExamMarkEntryAPIURL, getSubjectListByMarkAllocated } from '../Services/GraceExamMarkEntryAPIURL';
import { toast } from 'react-toastify';

const GraceExamMarkEntry = () => {
    const titleId = "Grace Exams Marks Entry";
    const authUser = useSelector((state) => state.user.value);

    //States
    const [loaderOption, setLoaderOption] = useState(false);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalOutOfMarks, setTotalOutOfMarks] = useState('');
    const [annualOutOfMarks, setAnnualOutOfMarks] = useState('');
    const [regNo, setRegNo] = useState('');
    const [graceMarks, setGraceMarks] = useState('');
    const [finalMarks, setFinalMarks] = useState('');

    //Sorting function
    const onSort = (key, isAction) => {

        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
            //console.log("Data============" + JSON.stringify(data))

        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentDetailsForGraceExamMarkEntry}?classId=${classObj.classId}&divId=${classObj.divisionId}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key] < b[key] ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key] > b[key] ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    useEffect(() => {
        getClassData();
    }, [authUser])

    //get class-div data
    const getClassData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                })
        }
    }

    //get subjects by class-div
    const getSubjectListByClassDiv = async (cId, dId) => {
        //console.log(`${getSubjectListByMarkAllocated}?classId=${cId}&divId=${dId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&medium=${authUser.branchMedium}`)
        await axios.get(`${getSubjectListByMarkAllocated}?classId=${cId}&divId=${dId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&medium=${authUser.branchMedium}`)
            .then((response) => {
                setSubjectData(response.data);
            })
    }

    //getstudent details for mark entry
    const getStudentDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setTotalOutOfMarks('');
        setAnnualOutOfMarks('');
        setData([]);
        setFilteredData([]);
        setTempData([]);
        setSearchTerm('');
        setAscending(false);
        setSortedBy(null);
        console.log(`${getStudentDetailsForGraceExamMarkEntry}?classId=${classObj.classId}&divId=${classObj.divisionId}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}`)
        await axios.get(`${getStudentDetailsForGraceExamMarkEntry}?classId=${classObj.classId}&divId=${classObj.divisionId}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (res.data.length == 0) {
                    setLoaderOption(false);
                    toast.warn('Students not found for this class-div.')
                }
                else {
                    setData(res.data);
                    setFilteredData(res.data);
                    setTempData(res.data);

                    //make duplicate values single in single array
                    const objIds = res.data.reduce((a, { studentNameMr, studentNameEn, divisionId, classId, subjectId, annualMaxMarks,
                        maxMarks, regNo, marks, graceMarks, finalMarks, rollNo, annualMarks }) => {
                        a[divisionId] = a[divisionId] || { allStudents: [] }
                        return {
                            ...a, ...{
                                [divisionId]: {
                                    annualMaxMarks,
                                    maxMarks,
                                    // allStudents: a[divisionId].allStudents.concat({
                                    //     studentNameMr: studentNameMr,
                                    //     studentNameEn: studentNameEn,
                                    //     divisionId: divisionId,
                                    //     classId: classId,
                                    //     subjectId: subjectId,
                                    //     regNo: regNo,
                                    //     marks: marks,
                                    //     graceMarks: graceMarks,
                                    //     finalMarks: finalMarks,
                                    //     rollNo: rollNo,
                                    //     annualMarks: annualMarks,
                                    // }),
                                }
                            }
                        }
                    }, {})
                    const result = Object.values(objIds)

                    //console.log(JSON.stringify(result))

                    result.map(e => {
                        setTotalOutOfMarks(e.maxMarks);
                        setAnnualOutOfMarks(e.annualMaxMarks);
                    })
                    setLoaderOption(false);
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Get students for grace exam mark entry " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    //save mark entry
    const saveGraceExamMarkEntry = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        let updatedData = data
        updatedData.map((e, index) => {
            updatedData[index] = {
                "classId": e.classId,
                "divisionId": e.divisionId,
                "regNo": e.regNo,
                "subjectId": e.subjectId,
                "graceMarks": e.graceMarks,
                "finalMarks": e.finalMarks,
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
        })
        console.log(JSON.stringify(updatedData))
        await axios.post(saveGraceExamMarkEntryAPIURL, updatedData)
            .then(res => {
                if (res.data == "SAVED") {
                    setData([]);
                    setFilteredData([]);
                    setLoaderOption(false);
                    toast.success("Grace exam mark entry done successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                toast.error("something went wrong, please check.");
                console.log("Grace marks save " + err);
            })
    }

    //search function
    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) =>
            (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.rollNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.marks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.graceMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.finalMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.annualMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    //set value after search
    useEffect(() => {
        setRegNo('');

        let updatedData = tempData;
        updatedData.map((e, index) => {
            if (e.regNo == regNo) {
                updatedData[index] = ({ ...e, graceMarks: graceMarks, finalMarks: finalMarks })
            }
        })

        setData(tempData);

        if (searchTerm == "") {
            setFilteredData(tempData);
        }

    }, [searchTerm, regNo])

    //Table Head
    const TableHeading = [
        { label: 'Roll No', key: 'rollNo', textAlign: '' },
        { label: 'Reg No', key: 'regNo', textAlign: '' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn', textAlign: '' },
        { label: `Total Marks (${totalOutOfMarks})`, key: 'marks', textAlign: 'center' },
        { label: `Annual Marks (${annualOutOfMarks})`, key: 'annualMarks', textAlign: 'center' },
        { label: `Grace Marks`, key: 'graceMarks', textAlign: '' },
        { label: `Final Marks`, key: 'finalMarks', textAlign: '' },
    ];

    //function to prevent enter press for submit button
    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    // useEffect(() => {
    //     function handlekeydownEvent(event) {
    //         if (event.key === "Enter") {
    //             console.log("Enter is pressed!")
    //             if (classData !== null || classData !== "") {
    //                 console.log('Enter is pressed!');
    //                 event.preventDefault();
    //                 let btn = document.querySelector('.graceExamBtn')
    //                 if (btn !== null) {
    //                     btn.click();
    //                 }
    //             }
    //         }
    //     }

    //     document.addEventListener('keypress', handlekeydownEvent)
    //     return () => {
    //         document.removeEventListener('keypress', handlekeydownEvent)
    //     }

    // }, []);   

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={getStudentDetails}>
                                <div className="row">
                                    <label className="" style={{ width: '140px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={classData}
                                            value={classObj}
                                            onChange={(event, newValue) => {
                                                setClassObj(newValue);
                                                setSubjectObj(null);
                                                setData([]);
                                                setFilteredData([]);
                                                setTempData([]);
                                                if (newValue != null) {
                                                    getSubjectListByClassDiv(newValue.classId, newValue.divisionId);
                                                }
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={subjectData}
                                            value={subjectObj}
                                            onChange={(event, newValue) => {
                                                setSubjectObj(newValue);
                                                setData([]);
                                                setFilteredData([]);
                                                setTempData([]);
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <div className="col-sm-2 mt-3">
                                        <button type='submit' className='btn btn-sm btn-warning'>VIEW</button>
                                    </div>
                                </div>
                            </form>

                            {(() => {
                                if (data.length != 0 || filteredData.length != 0) {
                                    return (
                                        <>
                                            <div className="" style={{ marginTop: "30px" }}>
                                                <div className='row mb-1' style={{ float: 'right' }}>
                                                    <div>
                                                        <input
                                                            className='form-control form-control-sm'
                                                            type="text"
                                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                            placeholder="Search Here"
                                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); }}
                                                            value={searchTerm}
                                                        />
                                                    </div>
                                                </div>

                                                <form onSubmit={saveGraceExamMarkEntry}>
                                                    <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                                        <table className="table table-bordered ">
                                                            <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                                    {TableHeading.map((item, index) => {
                                                                        return (
                                                                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: '1.5', textAlign: `${item.textAlign}` }} onClick={() => { onSort(item.key, item.isAction) }} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                {filteredData
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.rollNo}</td>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.regNo}</td>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "120px" }}>{item.marks}</td>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "140px" }}>{item.annualMarks}</td>
                                                                                <GraceExamMarkInput
                                                                                    initialText={item.graceMarks}
                                                                                    initialText1={item.finalMarks}
                                                                                    totalannualMarks={item.annualMarks}
                                                                                    onChange={(value, value1) => {
                                                                                        const finalMarksSum = parseInt(item.annualMarks) + parseInt(value)
                                                                                        let updatedData = filteredData;
                                                                                        updatedData[index] = ({ ...item, graceMarks: value, finalMarks: finalMarksSum });
                                                                                        setData(updatedData);
                                                                                        setFilteredData(updatedData);

                                                                                        setRegNo(updatedData[index].regNo);
                                                                                        setGraceMarks(value);
                                                                                        setFinalMarks(finalMarksSum);
                                                                                        //console.log(JSON.stringify(updatedData));
                                                                                    }}
                                                                                />
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className='col-sm-2'>
                                                            <button type='submit' onKeyDown={handleKeyDown} className='graceExamBtn btn btn-primary'><AddIcon fontSize="small" />Save Changes</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    )
                                }
                            })()}
                        </div>
                    </>
            }
        </>
    )
}

export default GraceExamMarkEntry