import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { printIncomeAndExpenditureReportAPIURL } from '../Services/AccountMasterReportAPI';
import Loader from '../../CommonComponent/Loader';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import moment from 'moment';
import { toast } from 'react-toastify';
import axios from 'axios';


const IncomeAndExpenditure = () => {
    const titleId = "Income And Expenditure Report";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    const [loaderOption, setLoaderOption] = useState(false);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [id, setId] = useState();
    const [dateCheckFrom, setDateCheckFrom] = useState('');
    const [dateCheckTo, setDateCheckTo] = useState('');

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 2, 31);

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])

    const getIncomeAndExpenditureReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment(dateTo).format("DD/MM/YYYY") != 'Invalid date' && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            await axios.get(`${printIncomeAndExpenditureReportAPIURL}?langId=2&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Print Income & expenditure err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={getIncomeAndExpenditureReport}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date From"
                                                    value={dateFrom}
                                                    minDate={defaultMinDate}
                                                    maxDate={defaultMaxDate}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateFrom(newValue);
                                                        const maxDate = new Date(defaultMaxDate);
                                                        maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                        if (newValue < defaultMinDate || newValue >= maxDate) {
                                                            setDateCheckFrom("Invalid date");
                                                        } else {
                                                            setDateCheckFrom("");
                                                        }
                                                    }}
                                                    openTo="day"
                                                    // Show defaultMaxDate selected when the DatePicker is opened
                                                    // If dateFrom is not set, defaultMaxDate is used
                                                    onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date To :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date To"
                                                    value={dateTo}
                                                    minDate={defaultMinDate}
                                                    maxDate={defaultMaxDate}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateTo(newValue);
                                                        const maxDate = new Date(defaultMaxDate);
                                                        maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                        if (newValue < defaultMinDate || newValue >= maxDate) {
                                                            setDateCheckTo("Invalid date");
                                                        } else {
                                                            setDateCheckTo("");
                                                        }
                                                    }}
                                                    openTo="day"
                                                    // Show defaultMaxDate selected when the DatePicker is opened
                                                    // If dateFrom is not set, defaultMaxDate is used
                                                    onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-12" style={{ marginLeft: "110px" }}>
                                            <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                            <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => navigate("/Home/ac04/0")}>Exit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
};

export default IncomeAndExpenditure;
