import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/inventoryStore/master/getItemMasterList?sid=3&bid=1
export const getItemMasterAPIURL = BaseURL + "inventoryStore/master/getItemMasterList";

//localhost:8080/institute/sss/inventoryStore/master/saveItemMaster
export const saveItemMasterAPIURL = BaseURL + "inventoryStore/master/saveItemMaster";

// /localhost:8080/institute/sss/inventoryStore/master/updateItemMaster
export const updateItemMasterAPIURL = BaseURL + "inventoryStore/master/updateItemMaster";

//localhost:8080/institute/sss/inventoryStore/master/deleteItemMaster?id=3
export const deleteItemMasterAPIURL = BaseURL + "inventoryStore/master/deleteItemMaster";