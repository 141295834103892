import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../../CommonComponent/Loader';
import { printEndowmentWithConditionReport, printEndowmentByPrizeCodeAPIURL } from '../Services/EndowmentReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const EndowmentWithConditions = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Endowment Transactions";

    const [loaderOption, setLoaderOption] = useState(false);
    const [optionId, setOptionId] = useState(1);
    const [prizeCode, setPrizeCode] = useState('');


    const allCoditionWiseEndowmentReport = async () => {
        setLoaderOption(true);
        //console.log(`${generateSubjectAllocationReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${printEndowmentWithConditionReport}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Endowment Report failed: " + error)
            })
    }

    const endowmentWithPrizeCodeReport = async () => {
        setLoaderOption(true);
        //console.log(`${generateSubjectAllocationReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${printEndowmentByPrizeCodeAPIURL}?prizeCode=${prizeCode}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found with this prize code.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Endowment Report failed: " + error)
            })
    }

    const printEndowmentReport = (e) => {
        e.preventDefault();
        if (optionId == 1) {
            allCoditionWiseEndowmentReport();
        }
        else if (optionId == 2) {
            endowmentWithPrizeCodeReport();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <form onSubmit={printEndowmentReport}>
                            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Report Option:</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth >
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={optionId}
                                                margin='dense'
                                                label="Select Report Option"
                                                onChange={(e) => {
                                                    setOptionId(e.target.value);
                                                }}
                                            >

                                                <MenuItem value={1}>All</MenuItem>
                                                <MenuItem value={2}>Prize Code Wise</MenuItem>

                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>

                                {(() => {
                                    if (optionId == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Prize Code:</label>
                                                    <div className='col-sm-3'>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            margin='dense'
                                                            onChange={e => setPrizeCode(e.target.value)}
                                                            onWheel={(e) => e.target.blur()}
                                                            type="number"
                                                            autoComplete='off'
                                                            size='small'
                                                            id="outlined-basic"
                                                            label="PrizeCode"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            value={prizeCode}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="col-sm-12 mt-3">
                                    <div className="offset-sm-2">
                                        <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default EndowmentWithConditions