import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const getFeeListData = BaseURLMaster + "getFeeList/";
export const dBaseURl = "http://192.168.1.102:8080/institute/sss/"

//fee demand APIURL
export const BaseURLFee = BaseURL + "fee/"
export const getFeeDemandData = BaseURLFee + "getFeeDemandData/";
export const saveFeeDemandData = BaseURLFee + "saveFeeDemandData/";

//Student Wise fee demand APIURl
//localhost:8080/institute/sss/fee/getStudentWiseFeeDemandList?feeId=1&classId=6&divId=21&acYear=2022&sid=3&bid=1
export const getStudentListForStudWiseFeeDemand = BaseURL + "fee/getStudentWiseFeeDemandList";

// localhost:8080/institute/sss/fee/saveStudentWiseFeeDemandData
// {
//         "classId":6,
//         "divisionId":21,
//         "regNo":12466,
//         "feeId":1,
//         "amount":2500,
//         "acYear":2022,
//         "sectionId":3,
//         "branchId":1
//     }
export const saveStudentsForStudWiseFeeDemand = BaseURL + "fee/saveStudentWiseFeeDemandData";

//Ebc Fee Demand
//localhost:8080/institute/sss/fee/findEBCFeeDemandEntryData?feeId=50&sessionYear=2022&sid=4&bid=17
export const getClassDivDataForEbcFeeDemand = BaseURL + "fee/findEBCFeeDemandEntryData";

// localhost:8080/institute/sss/fee/saveEBCFeeDemandEntry
// [
//     {
//         "classId": 5,
//         "divisionId": 17,
//         "feeId": 50,
//         "feeAmount": 50,
//         "sessionYear": 2022,
//         "sectionId": 3,
//         "branchId": 1
//     }
// ]
export const saveEbcFeeDemandSectionWiseAPI = BaseURL + "fee/saveEBCFeeDemandEntry";