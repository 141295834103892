import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TitleLabel from "../../CommonComponent/TitleLabel";
import MasterFormStudentGradeTable from './MasterFormStudentGradeTable';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { BaseURLMasterExamGrades, ExamGradesSaveExamGrades, ExamGradesUpdateExamGrades } from '../../CommonServices/ExamGradesAPIURL';
import Loader from '../../CommonComponent/Loader';

const MasterFormStudentGrade = () => {
    const titleId = "Student Grade";

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "studGradeSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "studGradeUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `${titleId} Name Mr`, key: 'nameMr' }, { label: `${titleId} Name En`, key: 'nameEn' }, { label: 'From', key: 'markFrom' }, { label: 'To', key: 'markTo' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];


    const [id, setId] = useState("");
    const [englishName, setEnglishName] = useState("");
    const [marathiName, setMarathiName] = useState("");
    const [markFrom, setMarkFrom] = useState("");
    const [markTo, setMarkTo] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        axios.get(BaseURLMasterExamGrades)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
            })
    }

    const saveBtnClick = () => {
        setLoaderOption(true);
        const saveObject = {
            id: id,
            nameMr: marathiName,
            nameEn: englishName,
            markFrom: markFrom,
            markTo: markTo
        };
        axios.post(ExamGradesSaveExamGrades, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved sucessfully.");
                } else {
                    toast.error("Operation failed.");
                }
            });

        setMarathiName("");
        setEnglishName("");
        setId("");
        setMarkFrom("");
        setMarkTo("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const updateBtnClick = () => {
        setLoaderOption(true);
        const updateObject = {
            id: id,
            nameMr: marathiName,
            nameEn: englishName,
            markFrom: markFrom,
            markTo: markTo
        };
        axios.put(ExamGradesUpdateExamGrades + id, updateObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setLoaderOption(false);
                    toast.success("Update sucessfully.");
                }
            });
        setId("");
        setMarathiName("");
        setEnglishName("");
        setMarkFrom("");
        setMarkTo("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const ClearData = () => {
        setId("");
        setMarathiName("");
        setEnglishName("");
        setMarkFrom("");
        setMarkTo("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const handleKeyDownMr = (e) => {
        if (e.key === " " && marathiName.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownEn = (e) => {
        if (e.key === " " && englishName.length === 0) {
            e.preventDefault();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.studGradeSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.studGradeUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={e => setId(e.target.value)} />
                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Grade Name:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownMr}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Grade Name Marathi"
                                    variant="outlined"
                                    value={marathiName}
                                    onChange={(e) => setMarathiName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? marathiName : ""}
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownEn}
                                    autoComplete='off'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    size='small'
                                    id="outlined-basic"
                                    label="Grade Name English"
                                    variant="outlined"
                                    value={englishName}
                                    onChange={(e) => setEnglishName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? englishName : ""}
                                    margin='dense'
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>From:</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="From"
                                    variant="outlined"
                                    value={markFrom}
                                    onChange={(e) => setMarkFrom(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? markFrom : ""}
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>To:</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="To"
                                    variant="outlined"
                                    value={markTo}
                                    onChange={(e) => setMarkTo(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? markTo : ""}
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>

                    <div className="tableData">
                        <MasterFormStudentGradeTable TableHeading={TableHeading} setId={setId}
                            setMarathiName={setMarathiName}
                            setEnglishName={setEnglishName}
                            setMarkFrom={setMarkFrom}
                            setMarkTo={setMarkTo}
                            getData={getData}
                            data={data}
                            setFilteredData={setFilteredData}
                            filteredData={filteredData}
                            setData={setData}
                            saveBtnStyle={saveBtnStyle}
                            updateBtnStyle={updateBtnStyle}
                            displayNoneBtnStyle={displayNoneBtnStyle}
                            setSaveBtn={setSaveBtn}
                            setUpdateBtn={setUpdateBtn}
                        />
                    </div>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

};

export default MasterFormStudentGrade;
