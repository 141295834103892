import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";


// /localhost:8080/institute/sss/academics/boardExamMarkEntry/getStudentListBoardSeatNoWise?sessionYear=2022&sid=3&bid=15
export const getStudentListWithSeatNo = BaseURL + "academics/boardExamMarkEntry/getStudentListBoardSeatNoWise";

//localhost:8080/institute/sss/academics/boardExamMarkEntry/getBoardExamMarkEntryData?sessionYear=2022&sid=3&bmedium=1&classId=10&divId=37&bid=15&regNo=1234
export const getSubjectDataByClassId = BaseURL + "academics/boardExamMarkEntry/getBoardExamMarkEntryData";

//localhost:8080/institute/sss/academics/boardExamMarkEntry/getFinalBoardExamMarkEntryData?sessionYear=2022&sid=3&bid=15
export const getBoardExamEntryDetailsAPIURL = BaseURL + "academics/boardExamMarkEntry/getFinalBoardExamMarkEntryData";

//localhost:8080/institute/sss/academics/boardExamMarkEntry/saveBoardExamMarkEntryData
// {
//     "regNo": 123,
//     "seatNo": "F030204",
//     "subjectId": 4,
//     "markSystem": 1,
//     "obtainedMarks": 50,
//     "maxMarks": 100,
//     "grade": null,
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveBoardExamMarkEntryAPIURL = BaseURL + "academics/boardExamMarkEntry/saveBoardExamMarkEntryData";