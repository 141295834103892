import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

const MotherTongueAssignSelectOption = ({ motherTongue, initialText, onChange }) => {
    const authUser = useSelector((state) => state.user.value);
    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);
    const [motherTongueName, setMotherTongueName] = useState(motherTongue.find((e) => e.id == text));


    return (
        isEdit ?
            <td style={{ width: '170px' }}>
                <select value={text}
                    onChange={(e) => { setText(e.target.value); setMotherTongueName(motherTongue.find((a) => a.id == e.target.value)); onChange(e.target.value) }}
                    onKeyPress={(e) => (e.key == "Enter") ?  onChange(e.target.value) : ""}
                    className='form-select form-select-sm'
                    style={{ width: "130px", fontSize: "14px" }}
                    onBlur={() => { setEdit(false) }}
                    autoFocus
                >
                    {motherTongue.map((e) => {
                        return (
                            <option value={e.id}>
                                {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn}
                            </option>
                        )
                    })}

                </select>
            </td>

            :
            <td style={{ width: '170px' }} onClick={() => setEdit(true)}>{(authUser.branchMedium == 1) ? motherTongueName.nameMr : motherTongueName.nameEn}</td>
    )
}


export default MotherTongueAssignSelectOption