import { BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const getUrlForSameLabelForms=BaseURL + "library/master/";

//publisher master list (table)
//GetMapping
//localhost:8080/institute/sss/library/master/getAllPublisherMasterList?branchId=1
export const getPublisherMasterData = BaseURL + "library/master/getAllPublisherMasterList";

// save publisher master
// PostMapping
// localhost:8080/institute/sss/library/master/savePublisherMaster
// {
//     "publisherName": "amrut",
//     "address": "miraj",
//     "city": 3,
//     "mobileNo": "+9834862393",
//     "email": "amrut@gmail.com",
//     "branchId": 1
// }
export const savePublisherMasterData = BaseURL + "library/master/savePublisherMaster";

// single publisher master data
// GetMapping
// localhost:8080/institute/sss/library/master/getPublisherMasterData?id=2
export const getPublisherMasterDataById = BaseURL + "library/master/getPublisherMasterData";

// update publisher master
// PutMapping
// localhost:8080/institute/sss/library/master/updatePublisherMaster
// {
//     "id": 1,
//     "publisherName": "daud",
//     "address": "bisur",
//     "city": 1,
//     "mobileNo": "+9011038",
//     "email": "daud@gmail.com",
//     "branchId": 1
// }
export const updatePublisherMasterData = BaseURL + "library/master/updatePublisherMaster";

// delete publisher master
// DeleteMapping
// localhost:8080/institute/sss/library/master/deletePublisherMaster?id=1
export const deletePublisherMasterData = BaseURL + "library/master/deletePublisherMaster";