import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { classWithDivisionURL, printSubjectWiseObservationReport } from '../Services/SubjectWiseObservationReportAPIURL';
import { getSubjectDataAPIURL } from '../../Library/Acquisition/Services/AccessionEntryAPIURL';

import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
        },
        "& li": {
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const SubjectWiseObservationReport = () => {
    const classes = useStyles();
    const titleId = "Subject Observation Report";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //const [reportOptionId, setReportOptionId] = useState(1);
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [termObj, setTermObj] = useState('');
    const [subjectObj, setSubjectObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);


    useEffect(() => {
        getData();
    }, [authUser])

    //class-division list & exam list
    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);
                }).catch((error) => {
                    console.log(error);
                })


            await axios.get(getSubjectDataAPIURL)
                .then(res => {
                    setSubjectData(res.data);
                }).catch(err => {
                    console.log("get subject list err " + err);
                })
        }
    }


    //All subjects & Term wise --
    const termWiseObservationReportApi = async () => {
        setLoaderOption(true);

        // console.log("api = ", `${printSubjectWiseObservationReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&&subId=${subjectObj.id}`);
        await axios.get(`${printSubjectWiseObservationReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&&subId=${subjectObj.id}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject Wise Observation Report Failed: " + error)
            })
    }



    //call reports onSubmit
    const getResultSheetReport = e => {
        e.preventDefault();
        termWiseObservationReportApi();
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={getResultSheetReport}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class - Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={classDivData}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                value={classDivObj}
                                                onChange={(event, newValue) => {
                                                    setClassDivObj(newValue);
                                                    setTermObj('');
                                                }}
                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                                )} />
                                        </div>
                                        <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Subject :</label>
                                        <div className="col-sm-3">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={subjectData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                size="small"
                                                onChange={(event, newValue) => {
                                                    setSubjectObj(newValue);
                                                    setTermObj('');
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? subjectObj : ""}
                                                value={subjectObj}
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )} />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    size='small'
                                                    fullWidth
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={termObj}
                                                    label="Term"
                                                    required
                                                    onChange={(e) => {
                                                        setTermObj(e.target.value);
                                                    }}>
                                                    {subjectMarkTermData.filter(e => e.id != 3).map((item, index) => {
                                                        return (
                                                            <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn} </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default SubjectWiseObservationReport