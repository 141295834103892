import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { Icon } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ApiIcon from '@mui/icons-material/Api';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import PrintIcon from '@mui/icons-material/Print';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import './ToggleSideMenu.css';
import { SideMenuJson, PrincipalSideMenuJson, SupervisorSideMenuJson, ClerkSideMenuJson, TeacherSideMenuJson, AdminSideMenuJson, SansthaAcademicSideMenuJson, SansthaAccountSideMenuJson, DedCollegeSideMenuJson } from '../BaseComponent/Menu/Service/SideTestingMenuJSON';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    paper: {
        // backgroundColor: "#045de9",
        // backgroundImage: "linear-gradient(315deg, #09c6f9 0%, #045de9 74%)",
        backgroundColor: '#fde7f9', backgroundImage: 'linear-gradient(315deg, #fde7f9 0%, #aacaef 74%)'
    }
});


const ToggleSideMenu = () => {
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const [state, setState] = useState({ right: false });
    const [sideMenuJsonFile, setSideMenuJsonFile] = useState([])
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        if (authUser.roleId === 3 && authUser.deptId === 5) {
            setSideMenuJsonFile(DedCollegeSideMenuJson)
        }
        else if (authUser.roleId === 1) {
            setSideMenuJsonFile(PrincipalSideMenuJson);
        }
        else if (authUser.roleId === 2) {
            setSideMenuJsonFile(SupervisorSideMenuJson);
        }
        else if (authUser.roleId === 3) {
            setSideMenuJsonFile(ClerkSideMenuJson);
        }
        else if (authUser.roleId === 4) {
            setSideMenuJsonFile(TeacherSideMenuJson);
        }
        else if (authUser.roleId === 5) {
            setSideMenuJsonFile(AdminSideMenuJson);
        }
        else if (authUser.roleId === 6) {
            setSideMenuJsonFile(SansthaAcademicSideMenuJson);
        }
        else if (authUser.roleId === 7) {
            setSideMenuJsonFile(SansthaAccountSideMenuJson);
        }

    }, [authUser])


    const list = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            {/* <List>
                {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {["All mail", "Trash", "Spam"].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> */}
            {/* <Divider /> */}
            <List style={{ padding: "0px", margin: "0px" }}>
                {
                    sideMenuJsonFile.map((item, index) => {
                        return (
                            <span key={index.toString()}>
                                <NavLink className="nav-link" to={item.Link} style={{ padding: "0px", margin: "0px" }}>
                                    <ListItem button style={{ fontSize: "12px", color: "#000" }}>
                                        <ListItemIcon style={{ padding: "0px", margin: "0px", minWidth: "30px", }}>
                                            {item.LinkIcon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.MenuName} primaryTypographyProps={{ fontSize: '14px', fontWeight: "500" }} style={{ padding: "0px", margin: "0px" }} />
                                    </ListItem>
                                </NavLink>
                                {item.Divider}
                            </span>
                        )
                    })
                }
            </List>
            {/* <List>
                <NavLink className="nav-link" to={"/Home/ad01/0"}>
                    <ListItem button>
                        <ListItemIcon>
                            <GroupAddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Student Entry" />
                    </ListItem>
                </NavLink>
            </List> */}
        </Box>
    );

    const btnToggle = {
        //backgroundColor: "#464DE4",
        color: "white",
        fontSize: "24px",
        fontWeight: 600,
        position: "fixed",
        top: "50%",
        right: "0px",
        marginTop: "-8px",
        borderRadius: "10px 0px 0px 10px",
        cursor: "pointer",
        border: "1px solid #CFDFFF",
        borderRight: "0px",
        boxShadow: "0px 2px 10px #948D8D",
        backgroundColor: "#045de9",
        backgroundImage: "linear-gradient(315deg, #09c6f9 0%, #045de9 74%)",
    }

    return (
        <>

            <span style={btnToggle} onClick={toggleDrawer("right", true)}>
                <ApiIcon id="appIconToggle" style={{ margin: "6px", fontSize: "30px" }} />
            </span>
            <Drawer
                anchor={"right"}
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
                classes={{ paper: classes.paper }}
            >
                <span style={{ textAlign: "center", backgroundColor: '#e7eff9', backgroundImage: 'linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%)', padding: "4px", fontWeight: "600", boxShadow: "0px 1px 12px grey" }}>HOT LINKS</span>

                {list("right")}
            </Drawer>
        </>
    )
}

export default ToggleSideMenu