import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

// certificate loan
// localhost:8080/institute/sss/accounts/certificateLoan/getCertificateLoanReport?acYear=2021&sid=3&bid=1&deptName=
export const printLoanCertificate = BaseURL + "accounts/certificateLoan/getCertificateLoanReport";

// certificate mudran
// localhost:8080/institute/sss/accounts/certificateLoan/getCertificateMudranReport?acYear=2021&sid=3&bid=1&deptName=
export const printMudranCertificate = BaseURL + "accounts/certificateLoan/getCertificateMudranReport";

// certficate anamat
// localhost:8080/institute/sss/accounts/certificateLoan/getCertificateAnamatReport?acYear=2021&sid=3&bid=1&deptName=
export const printAnamatCertificate = BaseURL + "accounts/certificateLoan/getCertificateAnamatReport";

// certificate taslamat
// localhost:8080/institute/sss/accounts/certificateLoan/getCertificateTaslamatReport?acYear=2021&sid=3&bid=1&deptName=
export const printTaslamatCertificate = BaseURL + "accounts/certificateLoan/getCertificateTaslamatReport";