import React, { useState, useEffect } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getStudentList, getStudentListBySessionYear, getStudentListDaud } from '../../CommonServices/StudentRegistrationAPIURL';
import { ClassMasterGetMapping, getClassWithDiv } from '../../CommonServices/ClassMasterAPIURL';
import { CasteCategoryAPIURL, getClassData } from '../../CommonServices/StudentRegistrationAPIURL';
import { BaseURLMasterMinorities } from '../../CommonServices/MinorityMasterAPIURL';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});


const MasterFormStudentTable = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Registered List";
    const classes = useStyles();
    let { toastFlag } = useParams();
    const navigate = useNavigate();
    //Note:This Data For get Api As per requrment
    // const [dataYear, setdataYear] = useState(0)
    const [loaderOption, setLoaderOption] = useState(false);
    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [searchTerm, setSearchTerm] = useState("")
    const [classData, setClassData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [casteCategoryData, setCasteCategoryData] = useState([]);
    const [minorityData, setMinorityData] = useState([]);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const [selectedMenu1, setSelectedMenu1] = useState("Gender")
    const [selectedMenu2, setSelectedMenu2] = useState("Category")
    const [selectedMenu3, setSelectedMenu3] = useState("Minority")
    const [selectedMenu4, setSelectedMenu4] = useState("Class-Division")
    const [selectedMenu5, setSelectedMenu5] = useState("Status")

    const maleGenderFilter = data.filter(e => e.genderId == 1)
    const femaleGenderFilter = data.filter(e => e.genderId == 2)

    const enrollStatusStudFilter = data.filter(e => e.currentStatus == 0)
    const leftStatusStudFilter = data.filter(e => e.currentStatus == 1)


    useEffect(() => {

        if (toastFlag == 1) {
            toast.success("Student registration sucessfully done.")
            navigate("/Home/ad01/0")
        }
        else if (toastFlag == 2) {
            toast.success("Student updation sucessfully done.")
            //console.log("falg 2...............")
            navigate("/Home/ad01/0")
        }
        //getData();
        // navigate("/Home/ad01/0")
    }, [authUser]);

    //Student Entry required data
    const [countData, setCountData] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);
    const [yearNo, setYearNo] = useState(authUser.sessionYear || null);
    const [typeNo, setTypeNo] = useState(1);

    const getData = async () => {
        if (offset === 0) {
            setLoaderOption(true)
            setFilteredData([].concat([]))
            setData([].concat([]))
        }
        //console.log(`${getStudentListBySessionYear}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}&sessionYear=${yearNo}&type=${typeNo}`)
        await axios.get(`${getStudentListBySessionYear}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}&sessionYear=${yearNo}&type=${typeNo}`)
            .then((res) => {
                if (res.data !== null) {
                    const tempData = res.data;
                    setData(data.concat(tempData));
                    setFilteredData(filteredData.concat(tempData))
                    setLoaderOption(false)
                    if (tempData.length === limits) {
                        setOffset(offset + limits);
                        setNumCall(numCal + 1);
                    }
                }
            })

    }
    useEffect(() => {
        setYearNo(authUser.sessionYear);
    }, [authUser.sessionYear]);

    const getApiData = async () => {
        await axios.get(`${CasteCategoryAPIURL}`)
            .then((response) => {
                // console.log("caste category id :" + editCasteCategoryId + "====" + JSON.stringify(response.data))
                setCasteCategoryData(response.data);
            })
            .catch(error => {
                console.log("caste category:" + error)
            })

        await axios.get(`${BaseURLMasterMinorities}`)
            .then((response) => {
                setMinorityData(response.data);
            })
            .catch(error => {
                console.log("Minority:" + error)
            })

        await axios.get(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setClassData(response.data);

            })
            .catch(error => {
                console.log("Class Master:" + error)
            })
    }

    useEffect(() => {
        getApiData();
    }, [authUser])



    useEffect(() => {
        if (yearNo !== null && authUser.deptId !== null && authUser.branchId !== null) {
            getData();
        }

    }, [numCal, authUser, yearNo])


    // const getData = async () => {
    //     setLoaderOption(true);
    //     if (authUser.deptId != null && authUser.branchId != null) {
    //         await axios(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
    //             .then(res => {
    //                 setData(res.data);
    //                 setFilteredData(res.data)
    //                 setLoaderOption(false)
    //             })
    //     }
    //     // await axios(`${ClassMasterGetMapping}`)
    //     //     .then(res => {
    //     //         setClassData(res.data);
    //     //     })
    // }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter((item) =>
                item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.currentClassNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.currentClassNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.currentStatusName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.birthDate.toString().toLowerCase().includes(searchTerm.toLowerCase())
            ))
    }, [searchTerm])


    const UpdateData = (item, EditFlag) => {
        navigate(`/Home/ad01F/${item}/${EditFlag}`);
    }

    //filter data by some fields
    const getFilteredData = (sId, value) => {
        setAscending(false);
        setSortedBy(null);
        // setSearchTerm("");
        setPage(0);
        if (sId == 1) {
            setSelectedMenu2("Category")
            setSelectedMenu3("Minority")
            setSelectedMenu4("Class-Division")
            setSelectedMenu5("Status")
            setFilteredData([]);
            setPage(0);
            if (value == 1) {
                setSelectedMenu1('Male' + " " + `(${maleGenderFilter.length})`);
                setFilteredData(maleGenderFilter);
            }
            else if (value == 2) {
                setSelectedMenu1('Female' + " " + `(${femaleGenderFilter.length})`);
                setFilteredData(femaleGenderFilter);
            }
        }
        else if (sId == 2) {
            setSelectedMenu1('Gender');
            setSelectedMenu3("Minority")
            setSelectedMenu4("Class-Division")
            setSelectedMenu5("Status")
            setFilteredData([]);
            setPage(0);

            const filterStudByCategory = data.filter(e => e.casteCategoryId == value.id)
            setSelectedMenu2(((authUser.branchMedium == 1) ? value.nameMr : value.nameEn) + " " + `(${filterStudByCategory.length})`);

            setFilteredData(filterStudByCategory);
        }
        else if (sId == 3) {
            setSelectedMenu1('Gender');
            setSelectedMenu2("Category")
            setSelectedMenu4("Class-Division")
            setSelectedMenu5("Status")
            setFilteredData([]);

            const filterStudByMinority = data.filter(e => e.minorityId == value.id)
            setSelectedMenu3(((authUser.branchMedium == 1) ? value.nameMr : value.nameEn) + " " + `(${filterStudByMinority.length})`);

            setFilteredData(filterStudByMinority);
        }
        else if (sId == 4) {
            setSelectedMenu1('Gender');
            setSelectedMenu2("Category")
            setSelectedMenu3("Minority")
            setSelectedMenu5("Status")
            setFilteredData([]);
            setPage(0);
            const filterStudByClass = data.filter(e => e.currentClassId == value.classId && e.currentDivisionId == value.divisionId)
            setSelectedMenu4(((authUser.branchMedium == 1) ? value.classNameMr + " " + value.divisionNameMr : value.classNameEn + " " + value.divisionNameEn) + " " + `(${filterStudByClass.length})`);

            setFilteredData(filterStudByClass);
        }
        else if (sId == 5) {
            setSelectedMenu1('Gender');
            setSelectedMenu2("Category")
            setSelectedMenu3("Minority")
            setSelectedMenu4("Class-Division")
            setFilteredData([]);
            setPage(0);
            if (value == 1) {
                setSelectedMenu5('Enroll' + " " + `(${enrollStatusStudFilter.length})`);
                setFilteredData(enrollStatusStudFilter);
            }
            else if (value == 2) {
                setSelectedMenu5('Left' + " " + `(${leftStatusStudFilter.length})`);
                setFilteredData(leftStatusStudFilter);
            }
        }
        else if (sId == 6) {
            setSelectedMenu1('Gender');
            setSelectedMenu2("Category")
            setSelectedMenu3("Minority")
            setSelectedMenu4("Class-Division")
            setSelectedMenu5("Status")
            setFilteredData([]);
            setData([]);
            setOffset(0);
            setNumCall(0);
            getData();
        }
    }

    const handleClickStudentData = (year, type) => {
        setFilteredData([]);
        setData([]);
        setOffset(0);
        setNumCall(0);
        setYearNo(year);
        setTypeNo(type);
    }

    const TableHeading = [{ label: 'Reg No', key: 'regCode' }, { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' }, { label: `Class-Division`, key: 'currentClassId' }, { label: `Date Of Birth`, key: 'birthDate' }, { label: `Status`, key: 'currentStatus' }, { label: 'Edit', value: 'edit', isAction: true }];

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>

                        <div className="mb-4">
                            <div className="btn-group mr-2 mt-2">
                                <button type="button" style={{ backgroundColor: "#29A01E", color: "#fff" }} className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu1}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }} onClick={() => getFilteredData(1, 1)}>Male ({maleGenderFilter.length})</span></li>
                                    <li><span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }} onClick={() => getFilteredData(1, 2)}>Female ({femaleGenderFilter.length})</span></li>
                                </ul>
                            </div>

                            <div className="btn-group mr-2 mt-2">
                                <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#FA9932", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu2}
                                </button>
                                <ul className="dropdown-menu" style={{ height: "300px", overflowY: "scroll" }}>
                                    {
                                        casteCategoryData.map((e, index) => {
                                            return (
                                                <li><span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }} onClick={() => { getFilteredData(2, e) }}>
                                                    {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({(data.filter(e1 => e1.casteCategoryId == e.id)).length})
                                                </span></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                            <div className="btn-group mr-2 mt-2">
                                <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#A654BF", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu3}
                                </button>
                                <ul className="dropdown-menu" style={{ height: "250px", overflowY: "scroll" }}>
                                    {
                                        minorityData.map((e, index) => {
                                            return (
                                                <li><span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }} onClick={() => { getFilteredData(3, e) }}>
                                                    {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({(data.filter(e1 => e1.minorityId == e.id)).length})
                                                </span></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                            <div className="btn-group mr-2 mt-2">
                                <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#F9543A", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu4}
                                </button>
                                <ul className="dropdown-menu" style={{ height: "180px", overflowY: "scroll" }}>
                                    {
                                        classData.map((e, index) => {
                                            return (
                                                <li><span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }} onClick={() => { getFilteredData(4, e) }}>
                                                    {(authUser.branchMedium == 1) ? e.classNameMr + " " + e.divisionNameMr : e.classNameEn + " " + e.divisionNameEn} ({(data.filter(e1 => e1.currentClassId == e.classId && e1.currentDivisionId == e.divisionId)).length})
                                                </span></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                            <div className="btn-group mr-2 mt-2">
                                <button type="button" style={{ backgroundColor: "#F7CF5A", color: "#000" }} className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu5}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }} onClick={() => getFilteredData(5, 1)}>Enroll ({enrollStatusStudFilter.length})</span></li>
                                    <li><span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }} onClick={() => getFilteredData(5, 2)}>Left ({leftStatusStudFilter.length})</span></li>
                                </ul>
                            </div>



                            <button className='btn mr-2 mt-2' style={{ backgroundColor: "#02C1C1", color: "#fff" }} onClick={() => getFilteredData(6, 1)}>Reset</button>
                        </div>

                        <div className=''>
                            <button className='btn btn-primary btn-sm mb-2 mt-3' style={{ float: 'left' }} onClick={() => navigate("/Home/ad01F/0/1")} ><AddIcon fontSize="small" />New Entry</button>
                        </div>

                        <div className='row mb-1 mt-3' style={{ float: 'right' }}>

                            <div>
                                <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                                    <button type="button" title="Current Year" class="btn btn-success" onClick={() => handleClickStudentData(parseInt(authUser.sessionYear), 1)}>C</button>
                                    <button type="button" title="Previous Year" class="btn btn-primary" onClick={() => handleClickStudentData(parseInt(authUser.sessionYear) - 1, 2)}>P</button>
                                    <button type="button" title="All" class="btn btn-warning" onClick={() => handleClickStudentData(parseInt(authUser.sessionYear), 3)}>A</button>
                                </div>
                                <input
                                    className='form-control form-control-sm mx-2'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                    value={searchTerm}
                                />
                            </div>
                        </div>
                        <div className='table-responsive'>
                            <table className="table table-bordered">
                                <thead className="table-Default">
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regCode}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.currentClassNameMr + " " + item.currentDivNameMr : item.currentClassNameEn + " " + item.currentDivNameEn}</td>

                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{moment(item.birthDate).format("DD/MM/YYYY")}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        {
                                                            (item.currentStatus == 0) ? <span className='badge' style={{ backgroundColor: "#1EC40D", borderRadius: "10px", fontWeight: "600" }}>Enroll</span> : <span className='badge' style={{ backgroundColor: "#C91B1B", borderRadius: "10px", fontWeight: "600" }}>Left</span>
                                                        }
                                                        {/* item.currentStatus==0 ? <span className="badge bg-success"></span>:<span className="badge bg-danger"></span>  */}

                                                    </td>
                                                    <td>
                                                        <Tooltip title="Edit">
                                                            <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                onClick={() => UpdateData(item.regCode, 2)} data-bs-toggle="tooltip">
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>

            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}

        </>
    )
    //}
};

export default MasterFormStudentTable;
