import { dBaseURL, BaseURL, dBaseURLMaster, BaseURLMaster } from "../../../CommonServices/APIURL";

//localhost:8080/institute/sss/master/getDepartmentListByBranchId?bid=1
export const getDepartmentsByBranch = BaseURLMaster + "getDepartmentListByBranchId";

//localhost:8080/institute/sss/budget/master/getAllBudgetCategoryByDepartmentIdAndOptionId?deptId=3&optionId=1
export const getCategoryDetailsByDeptReceiptPayament = BaseURL + "budget/master/getAllBudgetCategoryByDepartmentIdAndOptionId";

//get and save detail budget entry
//localhost:8080/institute/sss/budget/transaction/getBudgetAccount?categoryId=1&sessionYear=2022&sid=3&bid=1
export const getAccountDetailsByCategoryIdAPIURL = BaseURL + "budget/transaction/getBudgetDetail";

//localhost:8080/institute/sss/budget/transaction/saveBudgetAccount
// {
//     "categoryId": 4,
//     "actualBudget1": 2000,
//     "actualBudget2": 3000 ,
//     "nextYearBudget":5000,
//     "resolutionNo": 1,
//     "date":"2022-12-30",
//     "sessionYear":2022,
//     "sectionId":3,
//     "branchId":1,
//     "srNo":1
// }
export const saveBudgetEntryAPIURL = BaseURL + "budget/transaction/saveBudgetDetail";


//get and save asset budget entry
// /localhost:8080/institute/sss/budget/transaction/getBudgetAssetEntry?bid=1&sessionYear=2022&sid=3&categoryId=4
export const getBudgetAssetEntryAPIURL = BaseURL + "budget/transaction/getBudgetAssetEntry";

// localhost:8080/institute/sss/budget/transaction/saveBudgetAssetEntry
// [
//     {
//         "categoryId":1,
//         "quantity":1,
//         "rate":200,
//         "srNo":1,
//         "sessionYear":2022,
//         "sectionId":3,
//         "branchId":1
//     }
// ]
export const saveBudgetAssetEntryAPIURL = BaseURL + "budget/transaction/saveBudgetAssetEntry";

//get and save account budget entry
//localhost:8080/institute/sss/budget/transaction/getBudgetAccountEntry?bid=1&sessionYear=2022&sid=3&categoryId=4
export const getBudgetAccountEntryAPIURL = BaseURL + "budget/transaction/getBudgetAccountEntry";

// localhost:8080/institute/sss/budget/transaction/saveBudgetAccountEntry
// [
//     {
//         "categoryId":1,
//         "amount":200,
//         "srNo":1,
//         "sessionYear":2022,
//         "sectionId":3,
//         "branchId":1
//     }
// ]
export const saveBudgetAccountEntryAPIURL = BaseURL + "budget/transaction/saveBudgetAccountEntry";


//Sansth Budget Entry APIURL
//localhost:8080/institute/sss/budget/transaction/saveSansthaBudgetDetail
// [
//     {
//         "categoryId": 1,
//         "actualBudget": 1200,
//         "nextYearBudget": 2000,
//         "srNo": 1,
//         "accName": "",
//         "sessionYear": 2022,
//         "sectionId": 12,
//         "branchId": 50
//     }
// ]
export const saveSansthBudgetDetailEntry = BaseURL + "budget/transaction/saveSansthaBudgetDetail";

//getSansthaBudgetDetail
export const getSansthaBudgetEntryDetail = BaseURL + "budget/transaction/getSansthaBudgetDetail";

//localhost:8080/institute/sss/budget/transaction/deleteSansthaBudgetEntry?id=191
export const deleteSansthaBudgetEntryAPIURL = BaseURL + "budget/transaction/deleteSansthaBudgetEntry";
