import { Autocomplete, Paper, TextField } from '@mui/material';
import { clear } from '@testing-library/user-event/dist/clear';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { getItemMasterAPIURL } from '../Services/ItemMasterAPIURL';
import { getOpeningItemAPIURL, saveOpeningItemAPIURL, updateOpeningItemAPIURL } from '../Services/OpeningItemAPIURL';

const OpeningItem = () => {
    const titleId = 'Opening Item Form';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);
    const navigate = useNavigate();
    let { opId, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "openingItemSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "openingItemUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState("");
    const [itemData, setItemData] = useState([]);
    const [itemObj, setItemObj] = useState(null);
    const [unit, setUnit] = useState('');
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [totalAmt, setTotalAmt] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        if (authUser.sessionYear != '' && authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getOpeningItemAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);

                    const selectedItem = res.data.find(e => e.id == opId)

                    setId(selectedItem.id);
                    setPrice(selectedItem.price);
                    setQuantity(selectedItem.quantity);
                    setTotalAmt(selectedItem.totalAmount);
                    setUnit(selectedItem.unit);

                    axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            setItemData(res.data);
                            setItemObj(res.data.find(e => e.id == selectedItem.itemId));
                        }).catch(err => {
                            console.log("get item master err " + err)
                        })
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("get opening item master err " + err)
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    const getApiData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setItemData(res.data);
                }).catch(err => {
                    console.log("get item master err " + err)
                })
        }

        if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != '') {
            await axios.get(`${getOpeningItemAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("get opening item master err " + err)
                })
        }
    }

    const getTotalAmt = () => {
        let totalAmount = parseFloat(quantity) * parseFloat(price);
        setTotalAmt(parseFloat(totalAmount));
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setItemObj(null);
        setUnit('');
        setQuantity('');
        setPrice('');
        setTotalAmt('');
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        navigate('/Home/st1304/');
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "itemId": itemObj.id,
            "unit": unit,
            "quantity": quantity,
            "price": price,
            "totalAmount": totalAmt,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.post(saveOpeningItemAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Item with this details is already exists.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Opening item save err " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "itemId": itemObj.id,
            "unit": unit,
            "quantity": quantity,
            "price": price,
            "totalAmount": totalAmt,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.put(updateOpeningItemAPIURL, saveObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.");
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Item with this details is already exists.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Update failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Opening item Update err " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        else if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                //console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.openingItemSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.openingItemUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    }, [])

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Item :</label>
                                    <div className='col-sm-4'>
                                        <Autocomplete
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            id="combo-box-demo"
                                            onChange={(e, newValue) => {
                                                setItemObj(newValue);
                                                setUnit('');
                                                if (newValue != null) {
                                                    setUnit(newValue.unit)
                                                }
                                            }}
                                            value={itemObj}
                                            options={itemData}
                                            onKeyPress={(e) => (e.key == "Enter") ? itemObj : ""}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.itemName}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} label="Select Item" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Unit :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && unit.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Unit"
                                            variant="outlined"
                                            margin='dense'
                                            value={unit}
                                            onChange={(e) => setUnit(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? unit : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Quantity :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && quantity.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="Quantity"
                                            variant="outlined"
                                            margin='dense'
                                            value={quantity}
                                            onChange={(e) => {
                                                setQuantity(e.target.value);
                                                setTotalAmt('');
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? quantity : ""}
                                            onBlur={(e) => getTotalAmt()}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Price :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && price.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="Price"
                                            variant="outlined"
                                            margin='dense'
                                            value={price}
                                            onChange={(e) => {
                                                setPrice(e.target.value);
                                                setTotalAmt('');
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? price : ""}
                                            onBlur={(e) => getTotalAmt()}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <label htmlFor="lastname3" className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Total Amt. :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && totalAmt.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            type='number'
                                            size='small'
                                            id="outlined-basic"
                                            label="Total Amt."
                                            variant="outlined"
                                            margin='dense'
                                            value={totalAmt}
                                            onChange={(e) => setTotalAmt(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? totalAmt : ""}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-1">
                                        <button type='submit' onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn}>Save</button>
                                        <button type='submit' onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default OpeningItem