import React, { useEffect, useState } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { serviceLogin, serviceLogout, userNotification } from '../../reduxFeature/user';

//table
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';


//
import { useParams } from 'react-router-dom';
//API Call
import {
    viewFileAPIURL,
    updateServiceFormStatusAPIURL
} from '../Service/UserErrorSubmissionAPIURL';

import { getAllServiceFormDataByStatusIdAPIURL, getRemarkDataByServiceFormIdAPIURL } from '../Service/allEnquiryAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});



const AdminErrorHandlingForm = () => {

    const authUser = useSelector((state) => state.user.service)

    const dispatch = useDispatch();



    let { statusType } = useParams();
    const [titleName, setTitleName] = useState("")


    useEffect(() => {
        if (statusType === "1") {
            setTitleName("Pending Enqueries")
        }
        else if (statusType === "2") {
            setTitleName("In Process")
        }
        else if (statusType === "3") {
            setTitleName("Completed")
        }
        else if (statusType === "4") {
            setTitleName("Closed")
        }
        else if (statusType === "5") {
            setTitleName("Rejected")
        }
        else if (statusType === "0") {
            setTitleName("All Enqueries")
        }
        getData();
    }, [])


    //Table Start

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Date`, key: 'raisedDate' },
        { label: `Branch Name`, key: 'branchName' },
        { label: `Department`, key: 'deptName' },
        { label: `User`, key: 'userName' },
        { label: `Module`, key: 'moduleName' },
        { label: `Form`, key: 'formName' },
        { label: 'View', value: 'type', isAction: true },
        { label: 'Status', value: 'status', isAction: true }];

    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.raisedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.moduleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.formName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.branchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.branchId.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    //Table End

    //Main Data

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [remarkData, setRemarkData] = useState([]);

    //Review
    const [remarks, setRemarks] = useState("");
    const [remarkOpt, setRemarkOpt] = useState("");
    const [remarkId, setRemarkId] = useState("");

    const clearData = () => {
        setRemarks("");
        setRemarkOpt("");
    }

    const [viewData, setViewData] = useState({
        "date": "",
        "moduleName": "",
        "formName": "",
        "details": "",
        "fileName": "",
        "status": ""
    })


    const getData = async () => {
        await axios.get(`${getAllServiceFormDataByStatusIdAPIURL}?statusId=${statusType}`)
            .then((res) => {
                if (res.data !== null) {
                    setData(res.data);
                    setFilteredData(res.data);
                    console.log(res.data)
                }
            })
    }

    const getRemarkData = async (id) => {
        await axios.get(`${getRemarkDataByServiceFormIdAPIURL}?formId=${id}&userType=11`)
            .then((res) => {
                if (res.data !== null) {
                    setRemarkData(res.data)
                    console.log(JSON.stringify(res.data))
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const updateTask = async (e) => {
        e.preventDefault();
        let userType = 11;

        await axios.post(`${updateServiceFormStatusAPIURL}?statusId=${remarkOpt}&id=${remarkId}&remark=${remarks}&userType=${userType}`)
            .then((res) => {
                if (res.data === "SAVED") {
                    toast.success("Sucessfully Saved")
                    document.getElementById("closeBtn").click();
                    getData();
                }
                else {
                    toast.error("Something went wrong")
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            <TitleLabel titleId={titleName} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 20px #ABB2B9", marginTop: "16px", minHeight: "200px" }}>
                <div className='mt-1'>
                    <div className='row mt-1'>
                        <div>
                            <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setSearchTerm(event.target.value); }}
                            />
                        </div>
                    </div>

                    <div className='table-responsive' >
                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <th style={{ fontSize: "13px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <tr style={(item.adminFlag === 1) ? { fontWeight: 600, backgroundColor: "#F4ECF7" } : { fontWeight: 500 }}>
                                                    <td style={{ fontSize: "13px" }}>{item.id}</td>
                                                    <td style={{ fontSize: "13px" }}>{item.raisedDate}</td>

                                                    <td style={{ fontSize: "13px" }}>{item.branchId + " - " + item.branchName}</td>
                                                    <td style={{ fontSize: "13px" }}>{item.deptName}</td>
                                                    <td style={{ fontSize: "13px" }}>{item.userName}</td>

                                                    <td style={{ fontSize: "13px" }}>{item.moduleName}</td>
                                                    <td style={{ fontSize: "13px" }}>{item.formName}</td>

                                                    <td style={{ fontSize: "13px", fontWeight: "500" }}>
                                                        <Tooltip title="View">
                                                            <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                data-bs-toggle="modal" data-bs-target="#exampleModalView"
                                                                onClick={() => {
                                                                    const TempData = {
                                                                        "date": item.raisedDate,
                                                                        "moduleName": item.moduleName,
                                                                        "formName": item.formName,
                                                                        "details": item.description,
                                                                        "fileName": item.fileName,
                                                                        "status": item.statusId

                                                                    }
                                                                    setViewData(TempData);
                                                                    getRemarkData(item.id);
                                                                    let updateData = data;
                                                                    updateData[index] = ({ ...item, adminFlag: 0 })
                                                                    setFilteredData(updateData);
                                                                    setData(updateData);
                                                                    if (item.adminFlag !== 0 && authUser.notificationCount > 0) {
                                                                        dispatch(userNotification({ notificationCount: authUser.notificationCount - 1 }));
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{ fontSize: "13px", fontWeight: "500" }}>
                                                        {(() => {
                                                            if (item.statusId === 1) {
                                                                return (
                                                                    <button
                                                                        type="button"
                                                                        className='btn btn-sm btn-primary p-1'
                                                                        style={{ fontSize: "12px" }}
                                                                        data-bs-toggle="modal" data-bs-target="#exampleModalUpdate"
                                                                        onClick={() => { setRemarkId(item.id); clearData() }}
                                                                    >
                                                                        Pending
                                                                    </button>
                                                                )
                                                            }
                                                            else if (item.statusId === 2) {
                                                                return (
                                                                    <button
                                                                        type="button"
                                                                        className='btn btn-sm btn-warning p-1'
                                                                        style={{ fontSize: "12px" }}
                                                                        data-bs-toggle="modal" data-bs-target="#exampleModalUpdate"
                                                                        onClick={() => { setRemarkId(item.id); clearData() }}
                                                                    >
                                                                        InProcess
                                                                    </button>
                                                                )
                                                            }
                                                            else if (item.statusId === 3) {
                                                                return "Completed"
                                                            }
                                                            else if (item.statusId === 4) {
                                                                return "Closed"
                                                            }
                                                            else if (item.statusId === 5) {
                                                                return "Rejected"
                                                            }
                                                            else {
                                                                return ""
                                                            }
                                                        })()}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })}
                            </tbody>
                            <tfoot style={{ padding: "0px" }}>
                                <tr>
                                    <TablePagination
                                        style={{ padding: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        labelRowsPerPage="Rows Per Page :"
                                        classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            {/* View Modal */}
            <div className="modal fade" id="exampleModalView" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content  p-0">
                        <div className="modal-header  p-1" style={{ backgroundColor: "#E3C6EF" }}>
                            <h4 className="modal-title" id="exampleModalLabel"></h4>
                        </div>
                        <div className="modal-body p-2" style={{ fontSize: "15px" }}>
                            <div className="row mt-1">
                                <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Date: </div>
                                <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>{viewData.date}</div>
                            </div>

                            <div className="row mt-1">
                                <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Module: </div>
                                <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>{viewData.moduleName}</div>
                            </div>

                            <div className="row mt-1">
                                <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Form: </div>
                                <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>{viewData.formName}</div>
                            </div>

                            <div className="row mt-1">
                                <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Status: </div>
                                <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>
                                    {(() => {
                                        if (viewData.status === 1) {
                                            return "Pending"
                                        }
                                        else if (viewData.status === 2) {
                                            return "In Process"
                                        }
                                        else if (viewData.status === 3) {
                                            return "Completed"
                                        }
                                        else if (viewData.status === 4) {
                                            return "Closed"
                                        }
                                        else if (viewData.status === 5) {
                                            return "Rejected"
                                        }
                                    })()}
                                </div>
                            </div>
                            {
                                (viewData.fileName !== "") ?
                                    <div className="row mt-1">
                                        <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>File: </div>
                                        <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>
                                            <span
                                                onClick={() => window.open(`${viewFileAPIURL}${viewData.fileName}`)}
                                                className='badge rounded-pill bg-danger' style={{ cursor: "pointer" }}>View File</span>
                                        </div>
                                    </div>
                                    : ""

                            }

                            <div className="row mt-1">
                                <div className='col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Details: </div>
                                <div className='col-sm-10 text-wrap text-break' style={{ fontWeight: 500, fontSize: "15px" }}>
                                    <p className="text-wrap text-break">{viewData.details}</p>
                                </div>
                            </div>

                            {
                                (remarkData !== null && remarkData.lenght > 0) ?
                                    <div className="row mt-1">
                                        <div className='col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Remarks: </div>
                                        <div className='col-sm-12' style={{ fontWeight: 500, fontSize: "15px" }}>
                                            {
                                                remarkData.map((item, index) => {
                                                    return (
                                                        (item.userType === 11) ?
                                                            <div className='container' key={index}>
                                                                <div style={{ fontSize: "10px", textAlign: "right", width: "100%", display: "inline-block" }}>{item.remarkDate}</div>
                                                                <div className='m-0 p-1' style={{ backgroundColor: "#EBDEF0", borderRadius: "8px 0px 0px 8px", width: "85%", float: "right" }}>
                                                                    <div className='col-sm-12' style={{ fontSize: "14px", textAlign: "left" }}>{item.remark}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='container' key={index}>
                                                                <div style={{ fontSize: "10px", textAlign: "left", width: "100%", display: "inline-block" }}>{item.remarkDate}</div>
                                                                <div className='m-0 p-1' style={{ backgroundColor: "#D5F5E3", borderRadius: "0px 8px 8px 0px", width: "85%", float: "left" }}>
                                                                    <div className='col-sm-12' style={{ fontSize: "14px", textAlign: "left" }}>{item.remark}</div>
                                                                </div>
                                                            </div>

                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                    : ""
                            }


                        </div>
                        <div className="modal-footer p-0">
                            <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* update task modal */}
            <div className="modal fade" id="exampleModalUpdate" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content  p-0">
                        <div className="modal-header  p-1" style={{ backgroundColor: "#FFC433" }}>
                            <h4 className="modal-title" id="exampleModalLabel"></h4>
                        </div>
                        <div className="modal-body p-4" style={{ fontSize: "13px", fontWeight: 500 }}>
                            <h4>Task Updation</h4>
                            <p>1. If the problem statement given by user is appropriate, then select option "In Process"</p>
                            <p>2. If the problem has been solved by technical team, then select option "Completed"</p>
                            <p>3. If the problem statement is in-appropriate, then select option "Rejected" and save it.</p>
                            <form onSubmit={updateTask}>
                                <div className="row">
                                    <select value={remarkOpt} onChange={(e) => setRemarkOpt(e.target.value)} className='form-select form-select-sm' required>
                                        <option value="">Select Option</option>
                                        <option value="2">In-Process</option>
                                        <option value="3">Completed</option>
                                        <option value="5">Rejected</option>
                                    </select>
                                </div>

                                {
                                    (remarkOpt === "3" || remarkOpt === "5") ?
                                        <div className="row">
                                            <TextField
                                                fullWidth
                                                value={remarks}
                                                onChange={(e) => setRemarks(e.target.value)}
                                                multiline
                                                rows={3}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Remarks"
                                                variant="outlined"
                                                margin='dense'
                                                required
                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                            />
                                        </div>
                                        : ""
                                }
                                <div className="mt-2">
                                    <button type="submit" className='btn btn-sm btn-primary'>SAVE</button>
                                    <button type="button" id='closeBtn' className="btn btn-danger btn-sm mx-2" data-bs-dismiss="modal">Close</button>
                                </div>
                            </form>

                        </div>
                        {/* <div className="modal-footer p-0">
                            <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default AdminErrorHandlingForm