import React, { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { savePublisherMasterData } from '../LibraryServices/PublisherMasterAPIURL';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getCityData } from '../../CommonServices/InstituteMasterAPIURL';

function AddNewPublisherModal({ showPublisherModal, setShowPublisherModal, getApiData }) {
    const authUser = useSelector((state) => state.user.value);
    const handleClose = () => setShowPublisherModal(false);
    const handleShow = () => setShowPublisherModal(true);

    const [publisherName, setPublisherName] = useState("");
    const [address, setAddress] = useState("");
    const [cityData, setCityData] = useState([]);
    const [cityObj, setCityObj] = useState(null);
    const [mobileNo, setMobileNo] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        getData();
    }, [])
    

    const getData = async () => {
        await axios.get(getCityData)
            .then(res => {
                setCityData(res.data);
            })
    }

    const saveBtnClick = async (e) => {
        handleClose();
        e.preventDefault();
        //setLoaderOption(true);
        const saveObj = {
            "branchId": authUser.branchId,
            "name": publisherName,
            "address": address,
            "city": cityObj.id,
            "mobileNo": mobileNo,
            "email": email,
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(savePublisherMasterData, saveObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    //setLoaderOption(false);
                    toast.warn(`Publisher with this name is already exists.`)
                }
                else if (response.data == "SAVED") {
                    setPublisherName("");
                    setAddress("");
                    setCityObj(null);
                    setMobileNo("");
                    setEmail("");                    
                    getApiData();
                    //setLoaderOption(false);
                    toast.success("Publisher master saved successfully.")
                }

            }).catch(error => {
                //setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    // useEffect(() => {

    //     function handlekeydownEvent(event) {
    //         if (event.key === "Enter") {
    //             // if (password != "") {
    //             console.log('Enter is pressed!');
    //             event.preventDefault();
    //             let btn = document.querySelector('.publisherModalBtn')
    //             //console.log(btn)
    //             if (btn !== null) {
    //                 btn.click();
    //             }
    //             //}
    //         }
    //     }

    //     document.addEventListener('keypress', handlekeydownEvent)
    //     return () => {
    //         document.removeEventListener('keypress', handlekeydownEvent)
    //     }
    // }, []);


    return (
        <>

            <Modal show={showPublisherModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "20px" }}>Add New Publisher</Modal.Title>
                </Modal.Header>
                <Form onSubmit={saveBtnClick}>
                    <Modal.Body>

                        {/* <div className="row my-2">                            
                            <div className="col-sm-3"> */}
                        {/* <label style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Publisher Name :</label> */}
                        <TextField
                            fullWidth
                            required
                            onKeyDown={(e) => (e.key === " " && publisherName.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            margin='dense'
                            size='small'
                            style={{ width: "280px" }}                           
                            variant="outlined"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onKeyPress={(e) => (e.key == "Enter") ? publisherName : ""}
                            label="Publisher Name"
                            value={publisherName}
                            onChange={(e) => setPublisherName(e.target.value)}
                        />
                        {/* </div>
                        </div> */}

                        {/* <div className="row my-1"> */}
                        {/* <label className='' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Address :</label> */}
                        {/* <div className="col-sm-5"> */}
                        <TextField
                            fullWidth
                            required
                            multiline
                            rows={2}
                            onKeyDown={(e) => (e.key === " " && address.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            margin='dense'
                            size='small'
                            variant="outlined"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onKeyPress={(e) => (e.key == "Enter") ? address : ""}
                            label="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        {/* </div>
                        </div> */}

                        {/* <div className='row my-1'> */}
                        {/* <label className="" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >City :</label> */}
                        {/* <div className="col-sm-3"> */}
                        <Autocomplete
                            id="combo-box-demo"
                            options={cityData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            style={{ fontSize: '14px', width: "280px" }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            onChange={(event, newValue) => {
                                setCityObj(newValue);
                            }}
                            onKeyPress={(e) => (e.key == "Enter") ? cityObj : ""}
                            value={cityObj}
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Select City" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                            )}
                        />
                        {/* </div>
                        </div> */}

                        {/* <div className="row my-1"> */}
                        {/* <label className="" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mobile No :</label> */}
                        {/* <div className='col-sm-3'> */}
                        <TextField
                            required
                            fullWidth
                            onKeyDown={(e) => (e.key === " " && mobileNo.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            type="number"
                            size='small'
                            id="outlined-basic"
                            label="Mobile No"
                            style={{ width: "280px" }}
                            variant="outlined"
                            margin='dense'
                            value={mobileNo}
                            onChange={(e) => {
                                setMobileNo(e.target.value);
                            }}
                            onKeyPress={(e) => (e.key == "Enter") ? mobileNo : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                        />
                        {/* </div> */}

                        {/* <label className="" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Email :</label> */}
                        {/* <div className='col-sm-3'> */}
                        <ValidatorForm>
                            <TextValidator
                                required
                                fullWidth
                                onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                label="Email"
                                autoComplete='off'
                                size='small'
                                margin='dense'
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? email : ""}
                                name="email"
                                style={{ width: "280px" }}
                                value={email}
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                                validators={['isEmail']}
                                errorMessages={['email is not valid']}
                            />
                        </ValidatorForm>
                        {/* </div>
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className="publisherModalBtn">
                            Save
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddNewPublisherModal