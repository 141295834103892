import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const classWithDivisionURL = BaseURLMaster + "classWithDivisionBySectionId";
export const getClassBySectionBranch = BaseURLMaster + "getClassesBySectionAndBranch";
export const getFeeList = BaseURLMaster + "getFeeList/";


//Fee Receipt Summary APIURL
//localhost:8081/institute/sss/admission/reports/generateFeeReceiptSummeryReport?langId=2&feeId=2&fromDate=12/06/2021&toDate=21/03/2022&acYear=2021&sid=3&bid=1
export const printFeeReceiptSummary = BaseURL + "admission/reports/generateFeeReceiptSummeryReport";


//Fee due list APIURL
//localhost:8081/institute/sss/admission/reports/generateAllFeeDueListReport?langId=1&feeId=2&acYear=2021&sid=3&bid=1
export const printFeeDueList = BaseURL + "admission/reports/generateAllFeeDueListReport";

//localhost:8080/institute/sss/admission/reports/generateClassAndDivisionWiseFeeDueListReport?langId=1&feeId=2&acYear=2021&sid=3&bid=1&classId=10&divisionId=40
export const printClassDivisionFeeDueList = BaseURL + "admission/reports/generateClassAndDivisionWiseFeeDueListReport";

//localhost:8080/institute/sss/admission/reports/generateStudentWiseFeeDueListReport?langId=1&acYear=2021&sid=3&bid=1&feeId=1&regNo=10002
export const printStudentWiseFeeDueList = BaseURL + "admission/reports/generateStudentWiseFeeDueListReport";

//For FeeType 2
//http://192.168.29.102:8081/institute-sss/institute/sss/admission/reports/generateMultiFeeDueReport?langId=1&option=1&classId=0&divId=0&year=2023&sid=3&bid=1&feeType=2&regNo=0
export const printMultiFeeDueListReport = BaseURL + "admission/reports/generateMultiFeeDueReport";


//bulk fee receipt APIURL
//localhost:8080/institute/sss/admission/reports/generateBulkFeeReceiptReport?langId=1&acYear=2021&sid=3&bid=1&fromDate=01/03/2022&toDate=14/03/2022
export const printBulkFeeReceipt = BaseURL + "admission/reports/generateBulkFeeReceiptReport";

//localhost:8080/institute/sss/admission/reports/generateBulkFeeReceiptReportByClassAndDivision?langId=1&acYear=2021&sid=3&bid=1&classId=10&divId=37
export const printBulkFeeReceiptClassDivWise = BaseURL + "admission/reports/generateBulkFeeReceiptReportByClassAndDivision"


//fee demand summary APIURL
//localhost:8080/institute/sss/admission/reports/generateFeeDemandSummeryReport?langId=1&acYear=2021&sid=3&bid=1
export const printFeeDemandSummary = BaseURL + "admission/reports/generateFeeDemandSummeryReport";

//localhost:8080/institute/sss/admission/reports/generateClassWiseDemandSummeryReport?langId=1&acYear=2021&sid=3&bid=1&classId=10
export const printFeeDemandSummaryClassWise = BaseURL + "admission/reports/generateClassWiseDemandSummeryReport"

//localhost:8080/institute/sss/admission/reports/generateFeeWiseDemandSummeryReport?langId=1&acYear=2021&sid=3&bid=1&feeId=2
export const printFeeWiseFeeDemandSummary = BaseURL + "admission/reports/generateFeeWiseDemandSummeryReport";


//fee receipt summary APIURL
//localhost:8080/institute/sss/admission/reports/generateClassWiseFeeReceiptSummeryReport?langId=1&acYear=2021&sid=3&bid=1&feeId=2&classId=10&divisionId=37
export const printFeeReceiptSummaryClassDivWise = BaseURL + "admission/reports/generateClassWiseFeeReceiptSummeryReport";


//fee receipt summary excel report APIURL
//localhost:8080/institute/sss/admission/reports/generateExcelFeeReceiptSummeryReport?langId=1&feeId=1&fromDate=12/06/2021&toDate=21/10/2022&acYear=2021&sid=3&bid=1
export const printFeeReceiptExcelReport = BaseURL + "admission/reports/generateExcelFeeReceiptSummeryReport";


//Yearly fee collection APIURL
//localhost:8080/institute/sss/admission/reports/generateYearlyFeeCollectionReport?langId=1&acYear=2022&sid=3&bid=1
export const printYearlyFeeCollectionReport = BaseURL + "admission/reports/generateYearlyFeeCollectionReport";


//Sanstha Fee Receipt Summary
//localhost:8080/institute/sss/admission/reports/generateSansthaFeeReceiptSummary?langId=1&feeId=40&fromDate=01/07/2022&toDate=16/07/2022&acYear=2022&sid=12&bid=50
export const sansthaFeeReceiptDateWiseReport = BaseURL + "admission/reports/generateSansthaFeeReceiptSummary"

//http://192.168.29.102:8081/institute-sss/institute/sss/admission/reports/generateBulkMultiFeeReceiptReport?langId=1&option=1&classId=0&divId=0&dateFrom=2024-03-01&dateTo=2024-03-08&year=2023&sid=3&bid=1
export const bulkMultiFeeReceiptAPIURL = BaseURL + "admission/reports/generateBulkMultiFeeReceiptReport";


//Fee collection summary report
//http://192.168.29.102:8081/institute-sss/institute/sss/admission/reports/generateDailyFeeCollection?langId=1&option=1&dateFrom=2024-01-01&dateTo=2024-05-09&year=2023&sid=3&bid=1&reportOption=1&feeType=2
export const printFeeCollectionSummaryReportAPIURL = BaseURL + "admission/reports/generateDailyFeeCollection";