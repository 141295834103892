import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { ToastContainer, toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import { BaseURLInstituteMaster, InstituteMasterSave, InstituteMasterUpdate } from '../../CommonServices/InstituteMasterAPIURL';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const CityData = [
    {
        id: 1,
        cityNameMr: "सांगली",
        cityNameEn: "Sangli"
    },
    {
        id: 2,
        cityNameMr: "मिरज",
        cityNameEn: "Miraj"
    }
]


const InstituteMasterTable = () => {
    const titleId = "Institute Master Table"
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    let { toastFlag } = useParams();
    const navigate = useNavigate();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "instituteSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "instituteUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [loaderOption, setLoaderOption] = useState(false);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }



    useEffect(() => {
        if (toastFlag == 1) {
            toast.success("Saved successfully.")
            navigate("/Home/m129/0")
        }
        else if (toastFlag == 2) {
            toast.success("Updated successfully.")
            navigate("/Home/m129/0")
        }
        getData();

    }, [])


    const getData = () => {
        //console.log(`${BaseURLInstituteMaster}`)
        setLoaderOption(true);
        axios.get(BaseURLInstituteMaster)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
                setLoaderOption(false);
            })
    }

    const UpdateData = (item, EditFlag) => {
        navigate(`/Home/m129F/${item}/${EditFlag}`);

    }



    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.schoolNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.schoolNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.cityMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.cityEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.udiseNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: 'School Name Mr', key: 'schoolNameMr' }, { label: 'School Name En', key: 'schoolNameEn' }, { label: 'City', key: 'city' }, { label: 'U-Dise No', key: 'udiseNo' }, { label: 'Edit', value: 'edit', isAction: true }];

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                        <div className="">
                            <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/m129F/0/1")}><AddIcon fontSize="small" />Institute Entry</button>
                        </div>

                        <div className='row col-sm-4 mb-1' style={{ float: 'right' }}>
                            <div>
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(event) => { setSearchTerm(event.target.value);setPage(0);  }}
                                />
                            </div>
                        </div>

                        <div className='table-responsive'>
                            <table className="table table-bordered">
                                <thead className="table-Default">
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                    {filteredData.sort((a, b) => (a.id < b.id) ? 1 : -1).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{item.schoolNameMr}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{item.schoolNameEn}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.cityMr : item.cityEn}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.udiseNo}</td>
                                                        <td>
                                                            <Tooltip title="Edit">
                                                                <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                    onClick={() => UpdateData(item.id, 2)} data-bs-toggle="tooltip">
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </span>
                                                            </Tooltip>
                                                        </td>

                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default InstituteMasterTable;
