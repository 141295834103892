import React, { useState, useEffect } from 'react'
import TitleLabel from '../../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getRegisterTypeDataAPIURL } from '../../LibraryServices/RegisterTypeMasterAPIURL';
import { getAccessionRegisterDataAPIURL } from '../../Acquisition/Services/AccessionEntryAPIURL';
import axios from 'axios';
import { getAllAccessionDataByRegisterType } from '../Services/BookIssueReturnAPIURL';
import PersonIcon from '@mui/icons-material/Person';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import moment from 'moment';
import { getBookDetailsForWithdrawalEntry, saveWithdrawalEntryAPIURL } from '../Services/WithdrawalEntryAPIURL';

const useStyles = makeStyles(theme => ({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    },
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const WithdrawalEntry = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const [titleId, setTitleId] = useState('Withdrawal Entry');

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "accEntryLibrarySaveBtn btn btn-warning btn-sm";
    const updateBtnStyle = "accEntryLibraryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const TableHeading = [
        { label: 'Acc No', key: 'accNo' },
        { label: `Title`, key: 'title' },
        { label: `Author`, key: 'author' },
        { label: 'Publisher', value: 'publisher' },
        { label: 'Status', value: 'status', isAction: true },
    ];

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [option, setOption] = useState('');
    const [registerData, setRegisterData] = useState([]);
    const [titleData, setTitleData] = useState([]);
    const [registerObj, setRegisterObj] = useState(null);
    const [accCode, setAccCode] = useState('');
    const [accNoFrom, setAccNoFrom] = useState('');
    const [accNoTo, setAccNoTo] = useState('');
    const [titleObj, setTitleObj] = useState(null);
    const [amount, setAmount] = useState('');
    const [remark, setRemark] = useState('');
    const [withdrawalType, setWithdrawalType] = useState('');
    const [withdrawalDate, setWithdrawalDate] = useState(null);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [checkValue, setCheckValue] = useState([]);
    const [chkAccNum, setChkAccNum] = useState('');
    const [Val, setVal] = useState(false);

    //console.log(JSON.stringify(checkValue));

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
            setFilteredData(data);
            setData(data);
        }
        getSortedData(data, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getBookDetailsForWithdrawalEntry}?registerTypeId=${registerObj.id}&option=${option}&accNo=${(option == 2) ? titleObj.accNo : 0}&accNoFrom=${(option == 1) ? accNoFrom : 0}&accNoTo=${(option == 1) ? accNoTo : 0}&bid=${authUser.branchId}&sessionYear=${authUser.sessionYear}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => (a[key] || '').toString().toLowerCase() < (b[key] || '').toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => (a[key] || '').toString().toLowerCase() > (b[key] || '').toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    useEffect(() => {
        if (authUser.branchId != null) {
            getData();
        }
    }, [authUser])


    const getData = async () => {
        await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                setRegisterData(res.data);
            })

        await axios.get(`${getAccessionRegisterDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                setTitleData(res.data);
            })
    }

    const getTitleListByRegisterId = async (reId) => {
        //console.log(`${getAllAccessionDataByRegisterType}?registerTypeId=${reId}&bid=${authUser.branchId}`)
        await axios.get(`${getAllAccessionDataByRegisterType}?registerTypeId=${reId}&bid=${authUser.branchId}`)
            .then(res => {
                setTitleData(res.data);
            })
    }

    const getTitleByAccNo = (aId) => {
        setTitleObj(titleData.find(e => e.accNo == aId));
    }

    const handleChkBox = (val, item) => {
        setVal(val);

        const saveObj = {
            "accNo": item.accNo,
            "registerTypeId": item.registerTypeId,
            "withdrawalType": withdrawalType,
            "withdrawalStatus": 1,
            "withdrawalDate": moment(withdrawalDate).format("YYYY-MM-DD"),
            "remark": remark,
            "amount": (withdrawalType != 3) ? 0 : amount,
            "sessionYear": authUser.sessionYear,
            "branchId": authUser.branchId
        }

        if (val == true) {


            setCheckValue(checkValue.concat(saveObj))

            let updatedData = data;
            data.map((e, index) => {
                if (e.accNo == saveObj.accNo) {
                    updatedData[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        branchId: authUser.branchId,
                        withdrawalStatus: 1
                    })
                }

            })

            if (searchTerm == "") {
                setData(updatedData);
                setFilteredData(updatedData)
            }
        }
        else if (val == false) {
            // const removedData = checkValue.filter((e) => e.accNo != item.accNo)
            // setCheckValue(removedData);
            const removeObj = {
                "accNo": item.accNo,
                "registerTypeId": item.registerTypeId,
                "withdrawalType": withdrawalType,
                "withdrawalStatus": 0,
                "withdrawalDate": moment(withdrawalDate).format("YYYY-MM-DD"),
                "remark": remark,
                "amount": (withdrawalType != 3) ? 0 : amount,
                "sessionYear": authUser.sessionYear,
                "branchId": authUser.branchId
            }
            setCheckValue(checkValue.concat(removeObj))

            //if checkbox is unChecked
            let updatedData = data;
            data.map((e, index) => {
                if (e.accNo == removeObj.accNo) {
                    updatedData[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        branchId: authUser.branchId,
                        withdrawalStatus: 0
                    })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setData(updatedData);
                setFilteredData(updatedData);
            }
        }
        setChkAccNum(saveObj.accNo);
    }

    useEffect(() => {
        if (Val == true) {
            let updatedDataTrue = data
            data.map((e, index) => {
                if (e.accNo == chkAccNum) {
                    updatedDataTrue[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        branchId: authUser.branchId,
                        withdrawalStatus: 1
                    })
                }
            })
            if (searchTerm == "") {
                setChkAccNum('');
                setData(updatedDataTrue);
                setFilteredData(updatedDataTrue);
            }
        }
        else if (Val == false) {
            //console.log(checkDivId)
            let updatedDataFalse = data
            data.map((e, index) => {
                if (e.accNo == chkAccNum) {
                    updatedDataFalse[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        branchId: authUser.branchId,
                        withdrawalStatus: 0
                    })
                }
            })

            //console.log(JSON.stringify(updatedDataFalse))
            if (searchTerm == "") {
                setChkAccNum('');
                setData(updatedDataFalse);
                setFilteredData(updatedDataFalse);
            }
        }
    }, [searchTerm, Val, chkAccNum])

    const addBookWithdrawalEntry = async (e) => {
        e.preventDefault();
        setData([]);
        setFilteredData([]);
        setCheckValue([]);
        setWithdrawalType('');
        setWithdrawalDate(null);
        setAmount('');
        setRemark('');
        await axios.get(`${getBookDetailsForWithdrawalEntry}?registerTypeId=${registerObj.id}&option=${option}&accNo=${(option == 2) ? titleObj.accNo : 0}&accNoFrom=${(option == 1) ? accNoFrom : 0}&accNoTo=${(option == 1) ? accNoTo : 0}&bid=${authUser.branchId}&sessionYear=${authUser.sessionYear}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Book not found with this details.")
                }
                else {
                    setData(res.data);
                    setFilteredData(res.data);

                    // res.data.map(e => {
                    //     setWithdrawalType(e.withdrawalType);
                    //     setWithdrawalDate(e.withdrawalDate);
                    //     setAmount(e.amount);
                    //     setRemark(e.remark);
                    // })

                    let allocatedData = res.data.filter(e => e.withdrawalStatus == 1)
                    let updatedData = allocatedData
                    updatedData.map((e, index) => {
                        updatedData[index] = ({ ...e, withdrawalStatus: 1 })
                    })
                    setCheckValue(updatedData);
                    setLoaderOption(false);
                }
            }).catch(err => {
                console.log("Withdrawal add books err:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const saveWithdrawalEntry = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const checkedChkBoxCount = document.querySelector(".studentChkBox");
        let checkChkBox = checkedChkBoxCount.querySelectorAll('input[type=checkbox]:checked').length;
        //console.log(checkChkBox)
        if (checkChkBox != 0 && moment(withdrawalDate).format("DD/MM/YYYY") != 'Invalid date') {

            let updatedData = checkValue
            updatedData.map((e, index) => {
                updatedData[index] = ({
                    ...e,
                    "withdrawalType": withdrawalType,
                    "withdrawalDate": moment(withdrawalDate).format("YYYY-MM-DD"),
                    "remark": remark,
                    "amount": (withdrawalType != 3) ? 0 : amount,

                })
            })
            console.log(JSON.stringify(updatedData))
            await axios.post(saveWithdrawalEntryAPIURL, updatedData)
                .then(res => {
                    if (res.data == "SAVED") {
                        setLoaderOption(false);
                        setData([]);
                        setFilteredData([]);
                        setOption('');
                        setRegisterObj(null);
                        setAccCode('');
                        setAccNoFrom('');
                        setAccNoTo('');
                        setTitleObj(null);
                        toast.success("Saved Successfully.")
                    }
                }).catch(err => {
                    console.log("Withdrawal entry save err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            if (moment(withdrawalDate).format("DD/MM/YYYY") == 'Invalid date') {
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
            else {
                setLoaderOption(false);
                toast.warn("You have to select at least 1 book for withdrawal.")
            }
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) =>
            (item.authorName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.titleName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.publisherName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.accNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData);
    }, [searchTerm])
    //withdrawal Type 1.missing,2.damage,3.cash deposite,4.write off 

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <form onSubmit={addBookWithdrawalEntry}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Register Type :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={registerData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.registerTypeName}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setRegisterObj(newValue);
                                                setOption('');
                                                setAccCode('');
                                                setAccNoFrom('');
                                                setAccNoTo('');
                                                setTitleObj(null);
                                                setAmount('');
                                                setRemark('');
                                                setWithdrawalType('');
                                                setWithdrawalDate(null);
                                                if (newValue != null) {
                                                    getTitleListByRegisterId(newValue.id);
                                                }

                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? registerObj : ""}
                                            value={registerObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Register Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                margin='dense'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Option"
                                                value={option}
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setAccCode('');
                                                    setAccNoFrom('');
                                                    setAccNoTo('');
                                                    setTitleObj(null);
                                                    setAmount('');
                                                    setRemark('');
                                                    setWithdrawalType('');
                                                    setWithdrawalDate(null);
                                                }}
                                            >
                                                <MenuItem value={1}>Multiple</MenuItem>
                                                <MenuItem value={2}>Single</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {(() => {
                                    if (option == 1) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Acc No. From :</label>
                                                    <div className="col-sm-3">
                                                        <TextField
                                                            fullWidth
                                                            required={(option == 1) ? true : false}
                                                            type="number"
                                                            onWheel={(e) => e.target.blur()}
                                                            onKeyDown={(e) => (e.key === " " && accNoFrom.length === 0) ? e.preventDefault() : ""}
                                                            onKeyPress={(e) => (e.key == "Enter") ? accNoFrom : ""}
                                                            value={accNoFrom}
                                                            onChange={(e) => setAccNoFrom(e.target.value)}
                                                            autoComplete='off'
                                                            margin='dense'
                                                            size='small'
                                                            variant="outlined"
                                                            label="From"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                        />
                                                    </div>

                                                    <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                                    <div className="col-sm-3">
                                                        <TextField
                                                            fullWidth
                                                            required={(option == 1) ? true : false}
                                                            type="number"
                                                            onWheel={(e) => e.target.blur()}
                                                            onKeyDown={(e) => (e.key === " " && accNoTo.length === 0) ? e.preventDefault() : ""}
                                                            onKeyPress={(e) => (e.key == "Enter") ? accNoTo : ""}
                                                            value={accNoTo}
                                                            onChange={(e) => setAccNoTo(e.target.value)}
                                                            autoComplete='off'
                                                            margin='dense'
                                                            size='small'
                                                            variant="outlined"
                                                            label="To"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    else if (option == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Accession :</label>
                                                    <div className="" style={{ width: "120px" }}>
                                                        <TextField
                                                            fullWidth
                                                            required={(option == 2) ? true : false}
                                                            style={{ marginRight: "4px" }}
                                                            onKeyDown={(e) => (e.key === " " && accCode.length === 0) ? e.preventDefault() : ""}
                                                            autoComplete='off'
                                                            margin='dense'
                                                            type="number"
                                                            onWheel={(e) => e.target.blur()}
                                                            size='small'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            //InputProps={{ inputProps: { min: 1 } }}
                                                            onKeyPress={(e) => (e.key == "Enter") ? accCode : ""}
                                                            label="Acc No"
                                                            value={accCode}
                                                            onChange={(e) => {
                                                                setAccCode(e.target.value);
                                                                setTitleObj(null);
                                                                setAccNoFrom('');
                                                                setAccNoTo('');
                                                                setTitleObj(null);
                                                                setAmount('');
                                                                setRemark('');
                                                                setWithdrawalType('');
                                                                setWithdrawalDate(null);
                                                            }}
                                                            onBlur={(e) => getTitleByAccNo(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className='' style={{ width: "525px" }}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={titleData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={(option) => option.titleName}
                                                            renderOption={(props, option) => (
                                                                <div className='' {...props}>
                                                                    <div className=''>
                                                                        {option.accNo + ". " + option.titleName} <br />
                                                                        <div
                                                                            className=''
                                                                            style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                fontSize: 12,
                                                                                flexDirection: "row",
                                                                                justifyContent: "space-between",
                                                                                padding: "0px 10px",
                                                                                //gridRow: 1
                                                                            }}
                                                                        >
                                                                            <span><PersonIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {option.authorName}</span>
                                                                            <span className='ml-1 mr-1 mb-1' style={{ fontSize: "14px" }}>   |   </span>
                                                                            <span><LibraryBooksIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {option.publisherName}</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )}
                                                            size="small"
                                                            onChange={(event, newValue) => {
                                                                setTitleObj(newValue);
                                                                setAccCode('');
                                                                setAccNoFrom('');
                                                                setAccNoTo('');
                                                                setAmount('');
                                                                setRemark('');
                                                                setWithdrawalType('');
                                                                setWithdrawalDate(null);
                                                                if (newValue != null) {
                                                                    setAccCode(newValue.accNo);
                                                                }

                                                            }}
                                                            onKeyPress={(e) => (e.key == "Enter") ? titleObj : ""}
                                                            value={titleObj}
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Title" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(option == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' className='btn btn-sm btn-primary'><AddIcon fontSize='small' />Add</button>
                                        <button type='button' className='btn btn-sm btn-danger' style={{ margin: "0px 4px" }} onClick={() => navigate('/Home/lc904/')}><FastRewindIcon fontSize='small' />Back</button>
                                        {/* <button type='button' className='btn btn-sm btn-danger' onClick={() => clearData()} style={{ margin: "0px 4px" }}>Cancel</button> */}
                                    </div>
                                </div>
                            </form>

                            <div className="">
                                {(() => {
                                    if (filteredData.length != 0 || data.length != 0) {
                                        return (
                                            <>
                                                <div className="row mb-1 mt-4">
                                                    <div className="">
                                                        <h4 style={{
                                                            textAlign: "left",
                                                            borderBottom: "1px solid #5D6D7E",
                                                            lineHeight: "0.1em",
                                                            margin: "10px 0 20px",
                                                            color: "#0543E7",
                                                        }}><span style={{
                                                            background: "#fff",
                                                            padding: "0 8px",
                                                        }}>Withdrawal Details</span></h4>
                                                    </div>
                                                </div>

                                                <form onSubmit={saveWithdrawalEntry}>
                                                    <div className='mb-4'>
                                                        <div className='row my-1'>
                                                            <div>
                                                                <input
                                                                    className='form-control form-control-sm'
                                                                    type="text"
                                                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                                    placeholder="Search Here"
                                                                    value={searchTerm}
                                                                    onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='table-responsive' style={{ maxHeight: "300px" }}>
                                                            <table className="table table-bordered">
                                                                <thead className="table-Default" style={{ borderBottom: "2px solid #464de4" }}>
                                                                    <tr>
                                                                        {TableHeading.map((item, index) => {
                                                                            return (
                                                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                                            )
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='studentChkBox' style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                    {filteredData.map((item, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <tr>
                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.accNo}</td>
                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.titleName}</td>
                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.authorName}</td>
                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.publisherName}</td>
                                                                                    <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px", width: "150px" }} >
                                                                                        <Checkbox defaultChecked={(item.withdrawalStatus === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                                                                                            onChange={(e) => {
                                                                                                let val = e.target.checked;
                                                                                                handleChkBox(val, item);
                                                                                            }}

                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className='row my-2'>
                                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Withdrawal Type :</label>
                                                        <div className='col-sm-3'>
                                                            <FormControl fullWidth margin='dense'>
                                                                <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Type</InputLabel>
                                                                <Select
                                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                                    size='small'
                                                                    required
                                                                    fullWidth
                                                                    margin='dense'
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="Type"
                                                                    value={withdrawalType}
                                                                    onChange={(e) => {
                                                                        setWithdrawalType(e.target.value);
                                                                        setAmount('');
                                                                        setRemark('');
                                                                        setWithdrawalDate(null);
                                                                    }}
                                                                >
                                                                    <MenuItem value={1}>Missing</MenuItem>
                                                                    <MenuItem value={2}>Damage</MenuItem>
                                                                    <MenuItem value={3}>Cash Deposite</MenuItem>
                                                                    <MenuItem value={4}>Write Off</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <label className='col-sm-1' hidden={(withdrawalType == 3) ? false : true} style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Amount :</label>
                                                        <div className="col-sm-2" hidden={(withdrawalType == 3) ? false : true}>
                                                            <TextField
                                                                fullWidth
                                                                required={(withdrawalType == 3) ? true : false}
                                                                onKeyDown={(e) => (e.key === " " && amount.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                type='number'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputProps={{ inputProps: { min: 1 } }}
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? amount : ""}
                                                                label="Amount"
                                                                value={amount}
                                                                onChange={(e) => {
                                                                    setAmount(e.target.value);
                                                                }}

                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row my-2">
                                                        <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Withdrawal Date :</label>
                                                        <div className="col-sm-3">
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="Date"
                                                                    inputFormat="dd/MM/yyyy"
                                                                    value={withdrawalDate}
                                                                    onKeyDown={(e) => (e.key === " " && withdrawalDate.length === 0) ? e.preventDefault() : ""}
                                                                    onKeyPress={(e) => (e.key == "Enter") ? withdrawalDate : ""}
                                                                    onChange={(newValue) => {
                                                                        setRemark('');
                                                                        if (newValue === null) {
                                                                            setWithdrawalDate(new Date());
                                                                        }
                                                                        else if (newValue !== null) {
                                                                            setWithdrawalDate(newValue);
                                                                        }

                                                                    }}
                                                                    // disableOpenPicker
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            margin='dense'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required
                                                                            //style={{ width: "130px", marginLeft: "15px", backgroundColor: "#fff" }}
                                                                            fullWidth
                                                                            autoComplete='off'
                                                                            size="small" />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>

                                                        <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Remark :</label>
                                                        <div className="col-sm-6">
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                onKeyDown={(e) => (e.key === " " && remark.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? remark : ""}
                                                                label="Remark"
                                                                value={remark}
                                                                onChange={(e) => {
                                                                    setRemark(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className="col-sm-4">
                                                            <button type='submit' className={saveBtn}><AddIcon fontSize="small" />Save Changes</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default WithdrawalEntry