import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

//get Exam
export const getExamData = BaseURLMaster + "Exams/";


// Get class by Sectio and Branch

export const getClassesBySectionAndBranch = BaseURLMaster + "getClassesBySectionAndBranch";

export const getDivisionsByClassAndSectionAndBranch = BaseURLMaster + "getDivisionsByClassAndSectionAndBranch";

export const getAllocatedExamData = BaseURL + "academics/exam/getAllocatedExamData";
// Exam Mark Allocation

// Class API -
// GetMapping
// /institute/sss/master/getClassesBySectionAndBranch

// @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId

// -------------------------
// Division Data -

//  GetMapping
// /institute/sss/master/getDivisionsByClassAndSectionAndBranch

// @RequestParam("cid") long classId,@RequestParam("sid") long sectionId, @RequestParam("bid") long branchId

// ----------------------
// Exam Data

//  GetMapping
// /institute/sss/academics/exam/getAllocatedExamData

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cid") long classId, @RequestParam("did") long divisionId

export const getAllocatedSubExamData = BaseURL + "academics/exam/getAllocatedSubExamData";

// GetMapping /institute/sss/academics/exam//getAllocatedSubExamData
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cid") long classId, @RequestParam("did") long divisionId, @RequestParam("examid") long examId
// --------------------------------------------------

export const getSubjectListByClassAndDivision = BaseURL + "academics/subjectAllocation/getSubjectListByClassAndDivision";
// GetMapping
// /institute/sss/academics/subjectAllocation/getSubjectListByClassAndDivision

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cid") long classId, @RequestParam("did") long divisionId



export const getSubExamMarkData = BaseURL + "academics/exam/getSubExamMarkData";


// /institute/sss/academics/exam/

//  GetMapping  /getSubExamMarkData
// @RequestParam("acyr") long acYear, 
// @RequestParam("sid") long sectionId, 
// @RequestParam("bid") long branchId, 
// @RequestParam("cid") long classId, 
// @RequestParam("did") long divisionId, 
// @RequestParam("eid") long examId, 
// @RequestParam("tid") long termId, 
// @RequestParam("sbjid") long subjectId


export const saveSubExamMarkAllocationData = BaseURL + "academics/exam/saveSubExamMarkAllocationData";

// /institute/sss/academics/exam/

// /saveSubExamMarkAllocationData


//  List<SubjectMaster> subjectMasterList;
//     List <ExamMarkAllocationDto> examMarkAllocationDtoList;


export const getSubjectExamMarkDataAfterSave = BaseURL + "academics/exam/getSubjectExamMarkData";

// /institute/sss/academics/exam/

// GetMapping - /getSubjectExamMarkData


