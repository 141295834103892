import { dBaseUrlLibraryMaster, BaseUrlLibraryMaster, dBaseURL, BaseURL } from "../../../CommonServices/APIURL";

export const getBookBankGrouDataAPIURL = BaseUrlLibraryMaster + "getAllBookBankGroup"

//localhost:8080/institute/sss/library/circulation/saveIssuedBookBank
// {
//     "memberType": 1,
//     "memberId": 258,
//     "registerTypeId": 2,
//     "accNo": 2,
//     "issueDate": "2022-12-15",
//     "returnDate": null,
//     "sessionYear": 2022,
//     "branchId": 1
// }
export const saveBookBankIssueAPIURL = BaseURL + "library/circulation/saveIssuedBookBank";

//localhost:8080/institute/sss/library/circulation/saveReturnedBookBank
export const returnBookBankIssueAPIURL = BaseURL + "library/circulation/saveReturnedBookBank";

//localhost:8080/institute/sss/library/circulation/getIssuedBookBankList?sessionYear=2022&bid=1
export const getBookBankIssueDetailsAPIURL = BaseURL + "library/circulation/getIssuedBookBankList";