import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import TitleLabel from "../../CommonComponent/TitleLabel";
import MasterFormExamTable from './MasterFormExamTable';
import { ToastContainer, toast } from 'react-toastify';
import { BaseURLMasterExams, ExamSaveExam, ExamUpdateExam } from '../../CommonServices/ExamMasterAPIURL';
import axios from 'axios';
import Loader from '../../CommonComponent/Loader';

const MasterFormExam = () => {
    const titleId = "Exam"

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "examMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "examMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState("");
    const [englishName, setEnglishName] = useState("");
    const [marathiName, setMarathiName] = useState("");
    const [shortName, setShortName] = useState("");
    const [index, setIndex] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        axios.get(BaseURLMasterExams)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
            })
    }

    const saveBtnClick = () => {
        setLoaderOption(true);
        const saveObject = {
            id: id,
            nameMr: marathiName,
            nameEn: englishName,
            shortName: shortName,
            index: index
        };
        axios.post(ExamSaveExam, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                } else {
                    toast.error("Operation failed.");
                }
            });

        setMarathiName("");
        setEnglishName("");
        setId("");
        setShortName("");
        setIndex("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const updateBtnClick = () => {
        setLoaderOption(true);
        const updateObject = {
            id: id,
            nameMr: marathiName,
            nameEn: englishName,
            shortName: shortName,
            index: index
        };
        axios.put(ExamUpdateExam + id, updateObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setLoaderOption(false);
                    toast.success("Update successfully.");
                }
            });
        setId("");
        setMarathiName("");
        setEnglishName("");
        setShortName("");
        setIndex("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const ClearData = () => {
        setId("");
        setMarathiName("");
        setEnglishName("");
        setShortName("");
        setIndex("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const handleKeyDownMr = (e) => {
        if (e.key === " " && marathiName.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownEn = (e) => {
        if (e.key === " " && englishName.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownSname = (e) => {
        if (e.key === " " && shortName.length === 0) {
            e.preventDefault();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.examMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.examMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `${titleId} Name Mr`, key: 'nameMr' }, { label: `${titleId} Name En`, key: 'nameEn' }, { label: 'ShortName', key: 'shortName' }, { label: 'Index', key: 'index' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={onSubmit}>
                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >{titleId} Name:</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownMr}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Exam Name Marathi"
                                    variant="outlined"
                                    margin='dense'
                                    value={marathiName}
                                    onChange={(e) => setMarathiName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? marathiName : ""}
                                    inputProps={{ maxLength: 120 }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownEn}
                                    autoComplete='off'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    size='small'
                                    id="outlined-basic"
                                    label="Exam Name English"
                                    variant="outlined"
                                    margin='dense'
                                    value={englishName}
                                    onChange={(e) => setEnglishName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? englishName : ""}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Short Name:</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownSname}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Short Name"
                                    variant="outlined"
                                    value={shortName}
                                    onChange={(e) => setShortName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? shortName : ""}
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Index:</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Index"
                                    variant="outlined"
                                    value={index}
                                    margin='dense'
                                    onChange={(e) => setIndex(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? index : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                                <button type="submit" className={updateBtn} onClick={() => (state.button = 2)}>Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>

                    <div className="tableData">
                        <MasterFormExamTable TableHeading={TableHeading} setId={setId}
                            setMarathiName={setMarathiName}
                            setEnglishName={setEnglishName}
                            setShortName={setShortName}
                            setIndex={setIndex}
                            getData={getData}
                            data={data}
                            setData={setData}
                            setFilteredData={setFilteredData}
                            filteredData={filteredData}
                            saveBtnStyle={saveBtnStyle}
                            updateBtnStyle={updateBtnStyle}
                            displayNoneBtnStyle={displayNoneBtnStyle}
                            setSaveBtn={setSaveBtn}
                            setUpdateBtn={setUpdateBtn}
                        />
                    </div>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

};

export default MasterFormExam;
