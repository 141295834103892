import React, { useState } from 'react'

const SansthaBudgetAccountEntryInput = ({ initialText1, initialText2, initialText3, initialText4, onChange }) => {

    const txtBox = {
        borderRadius: "5px",
        padding: "4px",
        fontSize: "14px",
        fontWeight: "500",
        //textAlign: "right",
        border: "1px solid #B2ADAD",
        mozAppearance: 'none',

    }

    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    const [text3, setText3] = useState(initialText3);
    const [text4, setText4] = useState(initialText4);

    return (
        <>



            <td style={{ width: "280px", height: "70px", }} className='p-2'>
                <input
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    required={true}
                    onBlur={() => { onChange(text1, text3, text4) }}
                    style={{ fontSize: "14px", fontWeight: "500", border: "1px solid #B2ADAD", borderRadius: "5px" }}
                    onChange={(e) => setText1(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text3, text4) : ""}
                    value={text1}
                />
            </td>

            <td style={{ fontSize: "14px", fontWeight: "500", width: "120px", textAlign: "right" }}>{initialText2}</td>

            <td style={{ width: "120px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    //required={true}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => { onChange(text1, text3, text4) }}
                    style={txtBox}
                    onChange={(e) => setText3(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text3, text4) : ""}
                    value={text3}
                />
            </td>

            <td style={{ width: "120px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    //required={true}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => { onChange(text1, text3, text4) }}
                    style={txtBox}
                    onChange={(e) => setText4(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text3, text4) : ""}
                    value={text4}
                />
            </td>
        </>
    )
}


export default SansthaBudgetAccountEntryInput