import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const getClassWithDiv = BaseURLMaster + "classWithDivisionBySectionId";

//http://192.168.1.102:
//generalRegister report
//localhost:8080/institute/sss/administration/reports/generateGeneralRegister?langId=1&sid=3&bid=1&acYear=2021
export const generalRegisterReport = BaseURL + "administration/reports/generateGeneralRegister";

//localhost:8080/institute/sss/administration/reports/generateGeneralRegisterByClassAndDivision?langId=1&sid=3&bid=1&acYear=2021&classId=10&divId=37
export const generalRegisterClassDivWise = BaseURL + "administration/reports/generateGeneralRegisterByClassAndDivision";

//localhost:8080/institute/sss/administration/reports/generateExcelGeneralRegisterByClassAndDivision?classId=10&divId=37&langId=1&acYear=2021&sid=3&bid=1
export const generalRegExcelByClassDiv = BaseURL + "administration/reports/generateExcelGeneralRegisterByClassAndDivision";

//localhost:8080/institute/sss/administration/reports/generateExcelAllStudentGeneralRegister?langId=1&acYear=2021&sid=3&bid=1
export const generalRegExcelByAllStudents = BaseURL + "administration/reports/generateExcelAllStudentGeneralRegister";


//General Register checklist
//localhost:8080/institute/sss/administration/reports/generalRegisterFields
export const generalRegistercheckListFields = BaseURL + "administration/reports/generalRegisterFields";

//localhost:8080/institute/sss/administration/reports/generateExcelAllStudentGeneralRegisterDynamic?langId=1&acYear=2021&sid=3&bid=1
export const generalRegisterChecklistAll = BaseURL + "administration/reports/generateExcelAllStudentGeneralRegisterDynamic";

//localhost:8080/institute/sss/administration/reports/generateExcelByClassAndDivisionStudentGeneralRegisterDynamic?classId=10&divId=37&langId=1&acYear=2021&sid=3&bid=1
export const generalRegisterChecklistClassDivWise = BaseURL + "administration/reports/generateExcelByClassAndDivisionStudentGeneralRegisterDynamic";