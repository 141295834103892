import React from 'react'
import { useSelector } from 'react-redux';
import FeeReceiptTable from './FeeReceiptTable';
import MultiFeeReceiptTable from './MultiFeeReceiptTable';

const FeeReceiptTables = () => {
    const authUser = useSelector((state) => state.user.value);
    // console.log(authUser)

    let disableEditFlag = sessionStorage.getItem('FormProcessingFlag'); // Assuming it's a string
    disableEditFlag = JSON.parse(disableEditFlag); // Parse string to object

    // if (authUser.feeType != "" || authUser.feeType != null) {
    //     if (authUser.feeType === 2) {
    //         return (

    //             <MultiFeeReceiptTable />
    //         )
    //     }
    //     else {
    //         return (
    //             <FeeReceiptTable />

    //         )
    //     }
    // }

  
    if (disableEditFlag && disableEditFlag[4] == 1) {
        return (

            <MultiFeeReceiptTable />
        )
    }
    else {
        return (
            <FeeReceiptTable />

        )
    }
  
}

export default FeeReceiptTables
