import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getCityData } from '../../CommonServices/InstituteMasterAPIURL';
import { saveVendorMaster } from '../LibraryServices/VendorMasterAPIURL';

const AddNewVendorModal = ({ showVendorModal, setShowVendorModal, getApiData }) => {
    const authUser = useSelector((state) => state.user.value);
    const handleClose = () => setShowVendorModal(false);
    const handleShow = () => setShowVendorModal(true);

    const [vendorName, setVendorName] = useState("");
    const [cityData, setCityData] = useState([]);
    const [cityObj, setCityObj] = useState(null);
    const [address, setAddress] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(getCityData)
            .then(res => {
                setCityData(res.data);
            })
    }

    const saveBtnClick = async (e) => {
        handleClose();
        e.preventDefault();
        //setLoaderOption(true);
        const saveObj = {
            "branchId": authUser.branchId,
            "name": vendorName,
            "address": address,
            "city": cityObj.id,
            "mobileNo": mobileNo,
            "email": email,
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveVendorMaster, saveObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    //setLoaderOption(false);
                    toast.warn(`Vendor with this name is already exists.`)
                }
                else if (response.data == "SAVED") {
                    setVendorName("");
                    setAddress("");
                    setCityObj(null);
                    setMobileNo("");
                    setEmail("");                    
                    getApiData();
                    //setLoaderOption(false);
                    toast.success("saved successfully.")
                }

            }).catch(error => {
                //setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            <Modal show={showVendorModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "20px" }}>Add New Vendor</Modal.Title>
                </Modal.Header>
                <Form onSubmit={saveBtnClick}>
                    <Modal.Body>
                        <TextField
                            fullWidth
                            required
                            onKeyDown={(e) => (e.key === " " && vendorName.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            margin='dense'
                            style={{ width: "280px" }}
                            size='small'
                            variant="outlined"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onKeyPress={(e) => (e.key == "Enter") ? vendorName : ""}
                            label="Vendor Name"
                            value={vendorName}
                            onChange={(e) => setVendorName(e.target.value)}
                        />

                        <TextField
                            fullWidth
                            required
                            multiline
                            rows={2}
                            onKeyDown={(e) => (e.key === " " && address.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            margin='dense'
                            size='small'
                            variant="outlined"                            
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onKeyPress={(e) => (e.key == "Enter") ? address : ""}
                            label="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />

                        <Autocomplete
                            id="combo-box-demo"
                            options={cityData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            style={{ fontSize: '14px', width: "280px" }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            onChange={(event, newValue) => {
                                setCityObj(newValue);
                            }}
                            onKeyPress={(e) => (e.key == "Enter") ? cityObj : ""}
                            value={cityObj}
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Select City" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                            )}
                        />

                        <TextField
                            required
                            fullWidth
                            onKeyDown={(e) => (e.key === " " && mobileNo.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            type="number"
                            size='small'
                            id="outlined-basic"
                            style={{ width: "280px" }}
                            label="Mobile No"
                            variant="outlined"
                            margin='dense'
                            value={mobileNo}
                            onChange={(e) => {
                                setMobileNo(e.target.value);
                            }}
                            onKeyPress={(e) => (e.key == "Enter") ? mobileNo : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                        />

                        <ValidatorForm>
                            <TextValidator
                                required
                                fullWidth
                                onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                label="Email"
                                autoComplete='off'
                                size='small'
                                margin='dense'
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? email : ""}
                                name="email"
                                style={{ width: "280px" }}
                                value={email}
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                                validators={['isEmail']}
                                errorMessages={['email is not valid']}
                            />
                        </ValidatorForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className="vendorModalBtn">
                            Save
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default AddNewVendorModal