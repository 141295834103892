import { BaseIP, BaseURL, BaseURLMaster,dBaseURL } from "../../CommonServices/APIURL";


export const getSansthaFeeListAPIURL = BaseURLMaster + "getSansthaFeeList/";

// -private long id;
// -private long receiptId;
// -private String receiptDate;
// -private String receiptsName;
// -private long feeId;
// -private double receivedAmount;
// -private long isCash;
// -private String description;    
// -private long sessionYear;
// -private long sectionId;
// -private long branchId;
// -private String chequeData;

// ==============================================
// /institute/sss/sansthafee/
// @PostMapping("/saveSansthaOtherFeeData")

export const saveSansthaOtherFeeDataAPIURL = BaseURL + "sansthafee/saveSansthaOtherFeeData";

// =============================================== 
//  @GetMapping("/getSansthaOtherFeeDataList")

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId
export const getSansthaOtherFeeDataListAPIURL = BaseURL + "sansthafee/getSansthaOtherFeeDataList";


// ---------------------------------------------------------------
// @GetMapping("/getSansthFeeDetail")
// @RequestParam("tid") long transactionId, @RequestParam("acyr") long acYear
export const getSansthFeeDetailAPIURL = BaseURL + "sansthafee/getSansthFeeDetail";

// -----------------------------------------------------------
// @PutMapping("/updateSansthOtherFeeData")
// Object

export const updateSansthOtherFeeDataAPIURL = BaseURL + "sansthafee/updateSansthOtherFeeData";

//localhost:8080/institute/sss/admission/reports/generateSansthaFeeReceiptReportById?langId=1&id=2&acYear=2022&sid=12&bid=50
export const sansthaFeeReceiptPrintAPIURL = BaseURL + "admission/reports/generateSansthaFeeReceiptReportById"