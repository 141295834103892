import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Icon } from "@mui/material";


const NavItem = (props) => {

    return (
        <>
            <li className="nav-item">
                <NavLink
                    to={props.PathName}
                    onClick={() => {
                        props.refreshMenu();
                        props.handleMenuClick(props.Title, props.PathName)
                        if (props.PathName == "/Home/sacc618/") {
                            props.setShowModal(true);
                        }
                    }}
                    className="nav-link"
                >
                    <i><Icon style={{ fontSize: "16px", color: "#464de4", verticalAlign: "middle", marginRight: "2px" }}>{props.LinkIcon}</Icon></i>
                    <span className="menu-title"> {props.Title}</span>
                </NavLink>

            </li>

        </>
    )
}
export default NavItem;
