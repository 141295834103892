import React, { useState } from 'react'

const StudentAttendanceTextBox = ({ initialText, onChange }) => {
    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);

    return (

        <>
            <td style={{ width: "200px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm'
                    //style={{ width: "80px",fontSize: "15px" }}
                    style={(isEdit === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit(true)}
                    onBlur={() => {
                        if (text == "" || text == null) {
                            setText(0)
                        }
                        setEdit(false);
                        onChange(text)
                    }}
                    //autoFocus 
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text) : ""}
                    value={text}
                    min="0"
                />
            </td>
        </>

    )
    // :
    // <td style={{ width: "200px", height: "70px", fontWeight: "500", fontSize: "15px" }} onClick={() => setEdit(true)}>{text}</td>
}

export default StudentAttendanceTextBox