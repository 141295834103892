import React from 'react'
import PrintIcon from '@mui/icons-material/Print';
import { useSelector } from 'react-redux';

const SansthaConsolidatedTable4 = ({ futureYear, filteredData, printSanthaConsolidatedReport, totalPaymentObjOfTrustAmt }) => {
    const authUser = useSelector((state) => state.user.value);

    return (
        <>
            <div className='card mt-5'>
                <div className="card-header" style={{ backgroundColor: "#E0E0E0" }}>
                    <div className="">
                        {/* <span className='mt-2' style={{ float: "left", fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>३ खर्च बाजू एक्सपेन्सेस व ऑब्जेक्ट ऑफ ट्रस्ट</span> */}
                        <div className='mt-2' style={{ float: "left", }}>
                            <span className='mt-3' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>३ खर्च बाजू एक्सपेन्सेस व ऑब्जेक्ट ऑफ ट्रस्ट</span><br />
                            {/* <span className='' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>वर्ष : {parseInt(authUser.sessionYear) + 1}-{parseInt(futureYear) + 1}</span> */}
                        </div>
                        <div style={{ float: "right" }}>
                            <button
                                style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                type='button'
                                onClick={() => printSanthaConsolidatedReport()}
                                className='btn btn-sm mb-2'
                            //disabled={btnDisabled}
                            >
                                <PrintIcon fontSize="small" /> Print
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body" style={{ backgroundColor: "#FCFBFB" }}>
                    <div className='table-responsive mb-5'>
                        <table className="table table-bordered border-dark">
                            <thead>
                                <tr>
                                    <th style={{ fontSize: "14px", fontWeight: "600" }}>३ खर्च बाजू एक्सपेन्सेस व ऑब्जेक्ट ऑफ ट्रस्ट</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600" }}></th>
                                    <th style={{ fontSize: "14px", fontWeight: "600" }}></th>
                                </tr>
                                <tr>
                                    <th style={{ fontSize: "14px", fontWeight: "600" }}>तपशील</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "right" }}>एकूण खर्च</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "right" }}>एकूण</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.ac_name}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{parseFloat(item.amount).toFixed('2')}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}></td>
                                                </tr>
                                                <tr key={index}>
                                                    <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.ac_name2}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{parseFloat(item.amount2).toFixed('2')}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}></td>
                                                </tr>
                                                <tr key={index} style={{ backgroundColor: "#EEEEEE" }}>
                                                    <td style={{ fontSize: "14px", fontWeight: "500" }}></td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}></td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{parseFloat(item.total_amount).toFixed('2')}</td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{ fontSize: "14px", fontWeight: "600" }}>एकूण</td>
                                    <td style={{ fontSize: "14px", fontWeight: "600" }}></td>
                                    <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right" }}>{(totalPaymentObjOfTrustAmt).toFixed('2')}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SansthaConsolidatedTable4