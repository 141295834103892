import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { EmployeeMasterGetMapping } from '../../CommonServices/ClassTeacherAllocationAPIURL';
import { getLeaveMasterAPIURL } from '../Services/LeaveMasterAPIURL';
import EmployeeLeaveOpeningTable from './EmployeeLeaveOpeningTable';
import { saveEmployeeLeaveOpeningAPIURL, getEmployeeLeaveOpeningAPIURL, updateEmployeeLeaveOpeningAPIURL } from '../Services/EmployeeTransactionsAPIURL';
import { toast } from 'react-toastify';
import { EmployeeMasterBranchWiseGetData } from '../../CommonServices/EmployeeMasterAPIURL';

const EmployeeLeaveOpening = () => {
  const titleId = "Employee Leave Opening"
  const authUser = useSelector((state) => state.user.value);

  const TableHeading = [
    { label: 'ID', key: 'id' },
    { label: `Employee Name`, key: (authUser.branchMedium == 1) ? 'empNameMr' : 'empNameEn' },
    { label: `Leave Name`, key: 'leaveName' },
    { label: `Opening Qty.`, key: 'leaveQty' },
    { label: `Remarks`, key: 'remark' },
    { label: 'Edit', value: 'edit', isAction: true },
    { label: 'Delete', value: 'delete', isAction: true }];


  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "leaveOpeningSaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "leaveOpeningUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [loaderOption, setLoaderOption] = useState(false);

  const [id, setId] = useState('');
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [leaveTypeObj, setLeaveTypeObj] = useState(null);
  const [remark, setRemark] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeObj, setEmployeeObj] = useState(null);
  const [openingQty, setOpeningQty] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [readOnlyOnUpdate, setReadOnlyOnUpdate] = useState(false);

  useEffect(() => {
    getData();
  }, [authUser])


  const getData = async () => {
    if (authUser.sessionYear != '' && authUser.branchId != null) {
      await axios.get(`${getEmployeeLeaveOpeningAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        .then(res => {
          setData(res.data);
          setFilteredData(res.data);
        }).catch(err => {
          console.log('get employee leave opening err' + err)
        })
    }

    if (authUser.branchId != null) {
      await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`)
        .then((response) => {
          setEmployeeData(response.data);
        })
    }

    await axios.get(`${getLeaveMasterAPIURL}`)
      .then((response) => {
        setLeaveTypeData(response.data);
      })


  }

  const clearData = () => {
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
    setReadOnlyOnUpdate(false);
    setId('');
    setLeaveTypeObj(null);
    setRemark('');
    setEmployeeObj(null);
    setOpeningQty('');
  }

  const saveBtnClick = async () => {
    setLoaderOption(true);
    const saveObj = {
      "empId": employeeObj.employeeId,
      "leaveId": leaveTypeObj.id,
      "leaveQty": openingQty,
      "remark": remark,
      "sessionYear": authUser.sessionYear,
      "branchId": authUser.branchId
    }
    console.log(JSON.stringify(saveObj))
    await axios.post(saveEmployeeLeaveOpeningAPIURL, saveObj)
      .then(res => {
        if (res.data == "SAVED") {
          setLoaderOption(false);
          clearData();
          getData();
          toast.success("Saved Successfully.");
        }
        else if (res.data == "ALREADYEXISTS") {
          setLoaderOption(false);
          toast.warn('Leave opening with this details is already exists.')
        }
        else {
          setLoaderOption(false);
          toast.error("Employee leave opening save failed.")
        }
      }).catch(err => {
        setLoaderOption(false);
        console.log("Employee leave opening save" + err);
        toast.error("Something went wrong, please check.")
      })
  }

  const updateBtnClick = async () => {
    setLoaderOption(true);
    const updateObj = {
      "id": id,
      "empId": employeeObj.employeeId,
      "leaveId": leaveTypeObj.id,
      "leaveQty": openingQty,
      "remark": remark,
      "sessionYear": authUser.sessionYear,
      "branchId": authUser.branchId
    }
    console.log(JSON.stringify(updateObj))
    await axios.put(updateEmployeeLeaveOpeningAPIURL, updateObj)
      .then(res => {
        if (res.data == "UPDATED") {
          setLoaderOption(false);
          clearData();
          getData();
          toast.success("Updated Successfully.");
        }
        else if (res.data == "ALREADYEXISTS") {
          setLoaderOption(false);
          toast.warn('Leave opening with this details is already exists.')
        }
        else {
          setLoaderOption(false);
          toast.error("Employee leave opening update failed.")
        }
      }).catch(err => {
        setLoaderOption(false);
        console.log("Employee leave opening update" + err);
        toast.error("Something went wrong, please check.")
      })
  }


  const state = {
    button: 1
  }

  const onSubmit = e => {
    e.preventDefault();
    if (state.button == 1) {
      saveBtnClick();
    }
    if (state.button == 2) {
      updateBtnClick();
    }
  }

  useEffect(() => {

    function handlekeydownEvent(event) {
      if (event.key === "Enter") {
        console.log("Enter is pressed!")
        event.preventDefault();

        let btn1 = document.querySelector('.leaveOpeningSaveBtn')
        if (btn1 !== null) {
          btn1.click();
        }
        let btn2 = document.querySelector('.leaveOpeningUpdateBtn')
        if (btn2 !== null) {
          btn2.click();
        }

      }
    }

    document.addEventListener('keypress', handlekeydownEvent)
    return () => {
      document.removeEventListener('keypress', handlekeydownEvent)
    }

  }, []);

  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
              <form onSubmit={onSubmit}>
                <input type='hidden' value={id} onChange={(e) => setId(e.target.value)} />
                <div className="row">
                  <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Employee:</label>
                  <div className='col-sm-4'>
                    <Autocomplete
                      id="combo-box-demo"
                      options={employeeData}
                      PaperComponent={({ children }) => (
                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                      )}
                      readOnly={(readOnlyOnUpdate == true) ? true : false}
                      onChange={(event, newValue) => {
                        setEmployeeObj(newValue);
                        setLeaveTypeObj(null);
                        setRemark('');
                        setOpeningQty('');
                      }}
                      value={employeeObj}
                      style={{ fontSize: '14px' }}
                      getOptionLabel={option => option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                      renderOption={(props, option) => (
                        <div className='' {...props}>
                          <div className=''>
                            {option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                          </div>
                        </div>
                      )}
                      size="small"
                      renderInput={params => (
                        <TextField {...params} margin='dense' label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                      )}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Leave :</label>
                  <div className='col-sm-3'>
                    <Autocomplete
                      id="combo-box-demo"
                      options={leaveTypeData}
                      PaperComponent={({ children }) => (
                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                      )}
                      onChange={(event, newValue) => {
                        setLeaveTypeObj(newValue);
                        setRemark('');
                        setOpeningQty('');
                      }}
                      value={leaveTypeObj}
                      style={{ fontSize: '14px' }}
                      getOptionLabel={option => option.leaveName}
                      size="small"
                      renderInput={params => (
                        <TextField {...params} margin='dense' label="Select Leave" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                      )}
                    />
                  </div>

                  <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Opening Quantity :</label>
                  <div className='col-sm-2'>
                    <TextField
                      required
                      fullWidth
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => (e.key === " " && openingQty.length === 0) ? e.preventDefault() : ''}
                      autoComplete='off'
                      className=''
                      size='small'
                      id="outlined-basic"
                      label="Opening Quantity"
                      variant="outlined"
                      margin='dense'
                      value={openingQty}
                      onChange={(e) => setOpeningQty(e.target.value)}
                      onKeyPress={(e) => (e.key == "Enter") ? openingQty : ""}
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Remarks:</label>
                  <div className='col-sm-7'>
                    <TextField
                      required
                      fullWidth
                      onKeyDown={(e) => (e.key === " " && remark.length === 0) ? e.preventDefault() : ''}
                      autoComplete='off'
                      className=''
                      size='small'
                      id="outlined-basic"
                      label="Remarks"
                      variant="outlined"
                      margin='dense'
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      onKeyPress={(e) => (e.key == "Enter") ? remark : ""}
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-4 offset-sm-1">
                    <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                    <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>update</button>
                    <button type='button' className='btn btn-sm btn-danger ml-2' onClick={() => clearData()}>Cancel</button>
                  </div>
                </div>
              </form>

              <div className="mt-4">
                <EmployeeLeaveOpeningTable
                  TableHeading={TableHeading}
                  data={data}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  setSaveBtn={setSaveBtn}
                  setUpdateBtn={setUpdateBtn}
                  updateBtnStyle={updateBtnStyle}
                  displayNoneBtnStyle={displayNoneBtnStyle}
                  getData={getData}
                  setLoaderOption={setLoaderOption}
                  setId={setId}
                  setLeaveTypeObj={setLeaveTypeObj}
                  setRemark={setRemark}
                  setEmployeeObj={setEmployeeObj}
                  setOpeningQty={setOpeningQty}
                  leaveTypeData={leaveTypeData}
                  employeeData={employeeData}
                  setReadOnlyOnUpdate={setReadOnlyOnUpdate}
                />
              </div>
            </div>
          </>
      }
    </>
  )
}

export default EmployeeLeaveOpening