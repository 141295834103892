import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const StudentBatch = () => {
    const titleId = "Students Batch";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [batchData, setBatchData] = useState([]);
    const [batchObj, setBatchObj] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const TableHeading = [
        { label: 'Reg No', key: 'regCode' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Class`, key: 'class' },
        { label: `Roll No`, key: 'rollNo' },
    ];

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                })
        }
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                <form>
                    <div className='row'>
                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                        <div className='col-sm-3'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={classData}
                                style={{ fontSize: '14px' }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                    setClassObj(newValue);
                                }}
                                value={classObj}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Batch :</label>
                        <div className='col-sm-3'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={batchData}
                                style={{ fontSize: '14px' }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                    setBatchObj(newValue)
                                }}
                                value={batchObj}
                                getOptionLabel={option => option.nameMr}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} margin='dense' label="Select Batch" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <div className="col-sm-4 mt-3">
                            <button type='submit' className='btn btn-sm btn-warning'>View</button>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <div className='row col-sm-4 my-1' style={{ float: 'right' }}>
                            <div>
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    onChange={(event) => { setSearchTerm(event.target.value) }}
                                    value={searchTerm}
                                />
                            </div>
                        </div>
                        <div className='table-responsive' style={{ maxHeight: "500px" }}>
                            <table className="table table-bordered ">
                                <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                    <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                            </table>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-4">
                                <button type='button' className='btn btn-sm btn-primary'><AddIcon fontSize="small" />Save Chages</button>
                            </div>
                        </div>
                    </div>
                </form>

                {/* <div className="col-sm-12 mt-3">
                    <div className="">
                        <button type="button" className="btn btn-warning" data-toggle="modal" data-target="#myModal">
                            Add Student
                        </button>
                        <div className="modal" id="myModal">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header" style={{ backgroundColor: '' }}>
                                        <h4 className="modal-title" >Add Student</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>

                                    <div className="modal-body">
                                        <div className='row'>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student:</label>
                                            <div className='col-sm-10'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={studentData}
                                                    style={{ fontSize: '14px' }}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        //getDivisionData(newValue.divisionId)
                                                        setStudentId(newValue.id);
                                                        setStudentObj(newValue)

                                                    }}
                                                    value={studentObj}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" >Save</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button type="submit" className={saveBtn} style={{ margin: '0px 4px' }}>Save</button>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default StudentBatch