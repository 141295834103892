import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/admissionFormSale/getAdmissionFormSale?acYear=2021&sid=3&bid=1&formNo=12

//export const getAdmissionFormSaleAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormSale/getAdmissionFormSale";
export const getAdmissionFormSaleAPIURL = BaseURL + "admissionFormSale/getAdmissionFormSale";

//localhost:8080/institute/sss/admissionFormReceive/saveAdmissionFormReceiveData
//export const saveAdmissionFormReceiveDataAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormReceive/saveAdmissionFormReceiveData";
export const saveAdmissionFormReceiveDataAPIURL = BaseURL + "admissionFormReceive/saveAdmissionFormReceiveData";

//localhost:8080/institute/sss/admissionFormReceive/getAllAdmissionFormReceiveList?acYear=2021&sid=3&bid=1
//export const getAllAdmissionFormReceiveListAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormReceive/getAllAdmissionFormReceiveList";
export const getAllAdmissionFormReceiveListAPIURL = BaseURL + "admissionFormReceive/getAllAdmissionFormReceiveList";

//localhost:8080/institute/sss/admissionFormReceive/getAdmissionFormReceive?acYear=2021&sid=3&bid=1&formNo=2
//export const getAdmissionFormReceiveAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormReceive/getAdmissionFormReceive";
export const getAdmissionFormReceiveAPIURL = BaseURL + "admissionFormReceive/getAdmissionFormReceive";


//localhost:8080/institute/sss/admissionFormReceive/updateAdmissionFormReceiveData
//export const updateAdmissionFormReceiveDataAPIURL="http://192.168.1.102:8080/institute/sss/admissionFormReceive/updateAdmissionFormReceiveData";
export const updateAdmissionFormReceiveDataAPIURL = BaseURL + "admissionFormReceive/updateAdmissionFormReceiveData";


//localhost:8080/institute/sss/admissionFormReceive/deleteAdmissionFormReceiveData?id=2
//export const deleteAdmissionFormReceiveData="http://192.168.1.102:8080/institute/sss/admissionFormReceive/deleteAdmissionFormReceiveData";

export const deleteAdmissionFormReceiveData = BaseURL + "admissionFormReceive/deleteAdmissionFormReceiveData";