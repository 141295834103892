import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { printEbcSummaryClassWiseAPIURL, printClassDivWiseEBCSummaryReport } from '../Services/EbcReportAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getDivisionsByClassAndSectionAndBranch } from '../../Exam/Services/ExamMarkAllocationAPIURL';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles(theme => ({
  select: {
    maxHeight: 160,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  },

}));

const EBCSummary = () => {
  const classes = useStyles();
  const titleId = "EBC Summary";
  const authUser = useSelector((state) => state.user.value);

  // loader on/off
  const [loaderOption, setLoaderOption] = useState(false);

  const [id, setId] = useState();
  const [classData, setClassData] = useState([]);
  const [multiClassObj, setMultiClassObj] = useState([]);
  const [multiclassId, setMultiClassId] = useState([]);
  const [reportOptionId, setReportOptionId] = useState(1);
  const [divisionData, setDivisionData] = useState([]);
  const [divisionObj, setDivisionObj] = useState([]);
  const [divisionId, setDivisionId] = useState([]);
  const [classObj, setClassObj] = useState(null);
  //console.log(classId)
  const [withTutionFee, setWithTutionFee] = useState(0);

  const HandleTutionFeeCheckbox = (e) => {
    setWithTutionFee(e.target.checked);
    if (e.target.checked == true) {
      setWithTutionFee(1);
    }
    else if (e.target.checked == false) {
      setWithTutionFee(0);
    }
  }

  const getDivisions = async (cId) => {
    await axios.get(`${getDivisionsByClassAndSectionAndBranch}?cid=${cId}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setDivisionData(res.data);
      })
  }

  useEffect(() => {
    getData();
  }, [authUser])

  const getData = async () => {
    if (authUser.deptId != null || authUser.branchId != null) {
      await axios(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        .then((response) => {
          setClassData(response.data);

        }).catch((error) => {
          console.log(error);
        })
    }

  }

  const getEbcSummaryReportClassWise = async () => {
    setLoaderOption(true);
    console.log(`${printEbcSummaryClassWiseAPIURL}?langId=${authUser.branchMedium}&classId=${multiclassId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&withTuitionFee=${withTutionFee}`)
    await axios.get(`${printEbcSummaryClassWiseAPIURL}?langId=${authUser.branchMedium}&classId=${multiclassId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&withTuitionFee=${withTutionFee}`)
      .then(res => {
        if (res.data === "NOTFOUND") {
          setLoaderOption(false);
          toast.warn("Data not found.")
        }
        else if (res.data !== null) {
          window.open(`${getExportedReportAPIURL}${res.data}`);
          setLoaderOption(false);
        }
      }).catch(error => {
        console.log(error);
        setLoaderOption(false);
        toast.error("Something went wrong, please check.")
      })
  }

  const getEbcSummaryReportClassDivisionWise = async () => {
    setLoaderOption(true);
    console.log(`${printClassDivWiseEBCSummaryReport}?langId=${authUser.branchMedium}&divId=${divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&withTuitionFee=${withTutionFee}`)
    await axios.get(`${printClassDivWiseEBCSummaryReport}?langId=${authUser.branchMedium}&divId=${divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&withTuitionFee=${withTutionFee}`)
      .then(res => {
        if (res.data === "NOTFOUND") {
          setLoaderOption(false);
          toast.warn("Data not found.")
        }
        else if (res.data !== null) {
          window.open(`${getExportedReportAPIURL}${res.data}`);
          setLoaderOption(false);
        }
      }).catch(error => {
        console.log(error);
        setLoaderOption(false);
        toast.error("Something went wrong, please check.")
      })
  }

  const getOptionWiseEbcSummaryReport = (e) => {
    e.preventDefault();
    if (reportOptionId == 1) {
      getEbcSummaryReportClassWise();
    }
    else if (reportOptionId == 2) {
      getEbcSummaryReportClassDivisionWise();
    }
  }

  if (loaderOption == true) {
    return <><Loader /></>
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />

        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
          {/* Form Data start */}
          <form onSubmit={getOptionWiseEbcSummaryReport}>
            <div className="form-data">
              <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

              <div className="row">
                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                <div className='col-sm-3'>
                  <FormControl fullWidth margin='dense'>
                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                    <Select
                      MenuProps={{ classes: { paper: classes.select } }}
                      size='small'
                      required
                      fullWidth                      
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reportOptionId}
                      label="Select Option"
                      onChange={(e) => {
                        setReportOptionId(e.target.value);
                        setClassObj(null);
                        setMultiClassObj([]);
                        setDivisionObj([]);
                        setWithTutionFee(0);
                      }}
                    >
                      <MenuItem value={1}>Class Wise</MenuItem>
                      <MenuItem value={2}>Class-Division Wise</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {(() => {
                if (reportOptionId == 1) {
                  return (
                    <>
                      <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                        <div className='col-sm-4'>
                          <Autocomplete
                            id="combo-box-demo"
                            multiple
                            filterSelectedOptions
                            disableCloseOnSelect
                            options={classData}
                            style={{ fontSize: '14px' }}
                            PaperComponent={({ children }) => (
                              <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            onChange={(event, item) => {
                              setMultiClassObj(item);
                              setWithTutionFee(0);
                              setMultiClassId(item.map(e => e.id).join(','));
                            }}
                            value={multiClassObj}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            renderInput={params => (
                              <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                required={(reportOptionId == 1 && multiClassObj.length == 0) ? true : false} />
                            )}
                          />
                        </div>
                      </div>
                    </>
                  )
                }
                else if (reportOptionId == 2) {
                  return (
                    <>
                      <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                        <div className='col-sm-3'>
                          <Autocomplete
                            id="combo-box-demo"
                            PaperComponent={({ children }) => (
                              <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            options={classData}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            value={classObj}
                            onChange={(event, newvalue) => {
                              setClassObj(newvalue);
                              setWithTutionFee(0);
                              setDivisionObj([]);
                              if (newvalue != null) {
                                getDivisions(newvalue.id);
                              }

                            }}

                            renderInput={params => (
                              <TextField {...params} margin="dense" label="Select Class" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                required={(reportOptionId == 2) ? true : false} />
                            )}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Division :</label>
                        <div className='col-sm-4'>
                          <Autocomplete
                            id="combo-box-demo"
                            multiple
                            filterSelectedOptions
                            disableCloseOnSelect
                            PaperComponent={({ children }) => (
                              <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            options={divisionData}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            value={divisionObj}
                            onChange={(event, newvalue) => {
                              setDivisionObj(newvalue);
                              setWithTutionFee(0);
                              if (newvalue != []) {
                                setDivisionId(newvalue.map(e => e.id).join(','))
                              }
                            }}

                            renderInput={params => (
                              <TextField {...params} margin="dense" label="Select Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                required={(reportOptionId == 2 && divisionObj.length == 0) ? true : false} />
                            )}
                          />
                        </div>
                      </div>
                    </>
                  )
                }
              })()}

              <div className="row mt-2">
                <div className="col-sm-3 offset-sm-2">
                  <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={withTutionFee} onChange={HandleTutionFeeCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="With Tution Fee" />
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-sm-12 offset-sm-2">
                  <button type="submit" className="btn btn-primary btn-sm">Show</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* <ToastContainer position="top-right" theme="colored" /> */}
      </>
    )
  }

}

export default EBCSummary