import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate, useParams } from 'react-router-dom';
import TimePicker from '@mui/lab/TimePicker';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BaseURLMasterExams } from '../../CommonServices/ExamMasterAPIURL';
import { getSubjectData } from '../Services/LessonPlanAPIURL';
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import { getDivisionsByClassAndSectionAndBranch } from '../../Exam/Services/ExamMarkAllocationAPIURL';
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';
import { subjectGetData } from '../Services/SubjectAllocationAPIURL';
import Tooltip from '@mui/material/Tooltip';
import { getExamTimeTableByIdAPIURL, saveExamTimeTableAPIURL, updateExamTimeTableAPIURL } from '../Services/ExamTimeTableAPIURL';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 200,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  }
}));

const ExamTimeTableForm = () => {
  const authUser = useSelector((state) => state.user.value);
  const [loaderOption, setLoaderOption] = useState(false);
  const titleId = 'Exam Time Table'
  const classes = useStyles();
  const { exId, EditFlag } = useParams();
  const navigate = useNavigate();

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "mobileExamTimeSaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "mobileExamTimeUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

  //for amount details section
  const saveDisabled = "btn p-0 mt-3 ml-2";
  const updateDisabled = "btn p-0 mt-3 ml-2";
  const noneBtnStyle = "d-none";
  const [saveBtnSubject, setSaveBtnSubject] = useState(saveDisabled);
  const [updateBtnSubject, setUpdateBtnSubject] = useState(noneBtnStyle);

  const [id, setId] = useState(null);
  const [examData, setExamData] = useState([]);
  const [examObj, setExamObj] = useState(null);
  const [option, setOption] = useState(null);
  const [dateValue, setDateValue] = useState(null);

  const [subjectData, setSubjectData] = useState([]);
  const [subjectObj, setSubjectObj] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [classData, setClassData] = useState([]);
  // console.log(JSON.stringify(subjectData))
  const [classObj, setClassObj] = useState(null);
  const [divisionData, setDivisionData] = useState([]);
  const [divisionObj, setDivisionObj] = useState([]);
  const [divisionId, setDivisionId] = useState([]);
  const [termObj, setTermObj] = useState('');
  const [startTimes, setStartTimes] = useState(null);
  const [endTimes, setEndTimes] = useState(null);
  // console.log(time);
  const [tableData, setTableData] = useState([]);
  //console.log("tableData===" + JSON.stringify(tableData))
  const [tableId, setTableId] = useState('');

  const handleStartTimeChange = (newValue) => {
    setStartTime(newValue);
    if (newValue != null) {
      const hours = String(newValue.getHours()).padStart(2, "0");
      const minutes = String(newValue.getMinutes()).padStart(2, "0");
      const seconds = String(newValue.getSeconds()).padStart(2, "0");
      const times = `${hours}:${minutes}:${seconds}`;
      setStartTimes(times);
    }

  };

  const handleEndTimeChange = (newValue) => {
    setEndTime(newValue);
    if (newValue != null) {
      const hours = String(newValue.getHours()).padStart(2, "0");
      const minutes = String(newValue.getMinutes()).padStart(2, "0");
      const seconds = String(newValue.getSeconds()).padStart(2, "0");
      const times = `${hours}:${minutes}:${seconds}`;
      setEndTimes(times);
    }

  };

  useEffect(() => {
    if (EditFlag == 1) {
      getData();
      setSaveBtnDisabled(false);
      setUpdateBtnDisabled(true);
    }
    else if (EditFlag == 2) {
      getEditDetails();
      setSaveBtnDisabled(true);
      setUpdateBtnDisabled(false);
    }
  }, [authUser])


  const getData = async () => {
    setLoaderOption(true);
    await axios.get(`${subjectGetData}`)
      .then((response) => {
        setSubjectData(response.data);
        setLoaderOption(false);
      })

    await axios.get(BaseURLMasterExams)
      .then((response) => {
        setExamData(response.data);
        setLoaderOption(false);
      })

    await axios.get(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setClassData(res.data);
        setLoaderOption(false);
      })
  }

  const getDivisionData = async (cId) => {
    console.log(`${getDivisionsByClassAndSectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${cId}`)
    await axios.get(`${getDivisionsByClassAndSectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${cId}`)
      .then(res => {
        setDivisionData(res.data);
      })
  }

  const getEditDetails = async () => {
    setLoaderOption(true);
    console.log(`${getExamTimeTableByIdAPIURL}?id=${exId}`)
    await axios.get(`${getExamTimeTableByIdAPIURL}?id=${exId}`)
      .then(res => {
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);

        // setDateValue(res.data.date);
        const selectedExam = res.data.examId;
        setTermObj(res.data.termId);
        const selectedClass = res.data.classId;
        const selectedDiv = res.data.divId;

        setTableData(res.data.subjectData);
        setDivisionId(selectedDiv)

        axios.get(`${getDivisionsByClassAndSectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${selectedClass}`)
          .then(res => {
            setDivisionData(res.data);
            const filteredDivisions = res.data.filter(item => item.id === selectedDiv);
            // setDivisionId(res.data.map(e=>e.id).join(','))
            setDivisionObj(filteredDivisions)
          })

        axios.get(`${subjectGetData}`)
          .then((response) => {
            setSubjectData(response.data);
            setLoaderOption(false);
          })

        axios.get(BaseURLMasterExams)
          .then((response) => {
            setExamObj(response.data.find(e => e.id == selectedExam))
            setExamData(response.data);
            setLoaderOption(false);
          })

        axios.get(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
          .then(res => {
            setClassObj(res.data.find(e => e.id == selectedClass))
            setClassData(res.data);
            setLoaderOption(false);
          })
      })
  }

  const getTableData = () => {
    clearAllValues();
    const saveObj = {
      "id": tableId + 1,
      "subjectId": subjectObj.id,
      "subjectNameMr": subjectObj.nameMr,
      "subjectNameEn": subjectObj.nameEn,
      "option": option,
      "date": moment(dateValue).format("YYYY-MM-DD"),
      "startTime": startTimes,
      "endTime": endTimes
    }
    console.log(JSON.stringify(saveObj))
    setTableData(tableData.concat(saveObj))
  }

  const deletSubjectData = (id) => {
    setLoaderOption(true);
    const newTableData = tableData.filter(e => e.subjectId != id)
    console.log(JSON.stringify(newTableData));
    setTableData(newTableData);
    setLoaderOption(false);
  }

  const clearAllValues = () => {
    // setSaveBtnSubject(saveBtnDisabled);
    setSubjectObj(null);
    setOption(null);
    setDateValue(null);
    setStartTime(null);
    setEndTime(null);
  }

  const saveBtnClick = async () => {
    setLoaderOption(true);
    // let saveObj = []
    const saveObj = tableData.map((item) => ({
      "examId": examObj.id,
      "termId": termObj, // Assuming termObj has an id property
      "classId": classObj.id,
      "divId": divisionId,
      "subjectId": item.subjectId,
      "option": item.option,
      "date": item.date,
      "startTime": item.startTime,
      "endTime": item.endTime,
      "sessionYear": authUser.sessionYear,
      "sectionId": authUser.deptId,
      "branchId": authUser.branchId

    }));

    console.log(JSON.stringify(saveObj));
    await axios.post(saveExamTimeTableAPIURL, saveObj)
      .then(res => {
        if (res.data == "SAVED") {
          navigate("/Home/acd429/")
          setLoaderOption(false);
          toast.success("Saved Successfully.")
        }

      }).catch(err => {
        console.log("Exam Time Table save error" + err);
        setLoaderOption(false);
        toast.error("Something went wrong, please check.")
      })

  }

  const updateBtnClick = async () => {
    // setLoaderOption(true);
    // let saveObj = []
    const updateObj = tableData.map((item) => ({
      "id": exId,
      "examId": examObj.id,
      "termId": termObj, // Assuming termObj has an id property
      "classId": classObj.id,
      "divId": divisionId,
      "subjectId": item.subjectId,
      "option": item.option,
      "date": item.date,
      "startTime": item.startTime,
      "endTime": item.endTime,
      "sessionYear": authUser.sessionYear,
      "sectionId": authUser.deptId,
      "branchId": authUser.branchId

    }));

    console.log(JSON.stringify(updateObj));
    await axios.put(updateExamTimeTableAPIURL, updateObj)
      .then(res => {
        if (res.data == "SAVED") {
          navigate("/Home/acd429/")
          setLoaderOption(false);
          toast.success("Updated Successfully.")
        }

      }).catch(err => {
        console.log("Exam Time Table save error" + err);
        setLoaderOption(false);
        toast.error("Something went wrong, please check.")
      })
  }

  const clearData = () => {
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
    setExamObj(null);
    setClassObj(null);
    setDivisionData([]);
    setDivisionObj(null);
    setTermObj(null);
    setTableData([]);
    navigate("/Home/acd429/")
  }

  const state = {
    button: 1
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (state.button == 1) {
      saveBtnClick();
    }
    if (state.button == 2) {
      updateBtnClick();
    }
  }

  return (
    <>
      {
        (loaderOption === true) ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
              {/* Form Data start */}
              <form onSubmit={onSubmit}>
                <div className="form-data">
                  <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                  <div className='row my-2'>
                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                    <div className='col-sm-3'>
                      <Autocomplete
                        id="combo-box-demo"
                        options={classData}
                        style={{ fontSize: '14px' }}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                        )}
                        onChange={(event, item) => {
                          setClassObj(item);
                          setDivisionObj([]);
                          if (item != null) {
                            getDivisionData(item.id);
                          }

                        }}
                        value={classObj}
                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                        size="small"
                        renderInput={params => (
                          <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                            required />
                        )}
                      />
                    </div>

                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Division :</label>
                    <div className='col-sm-3'>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        //filterSelectedOptions
                        disableCloseOnSelect
                        options={divisionData}
                        style={{ fontSize: '14px' }}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                        )}
                        onChange={(event, item) => {
                          setDivisionObj(item);
                          if (item != null) {
                            setDivisionId(item.map(e => e.id).join(','));
                          }
                        }}
                        // renderOption={(props, option, { selected }) => (
                        //     <li {...props}>
                        //         <Checkbox
                        //             icon={icon}
                        //             checkedIcon={checkedIcon}
                        //             style={{ marginRight: 8 }}
                        //             checked={selected}
                        //         />
                        //         {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                        //     </li>
                        // )}
                        value={divisionObj}
                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                        size="small"
                        renderInput={params => (
                          <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 15 } }}
                            required={(divisionObj.length == 0) ? true : false} />
                        )}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                    <div className='col-sm-3'>
                      <FormControl fullWidth margin='dense'>
                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                        <Select
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          size='small'
                          fullWidth
                          labelId="demo-simple-select-label"
                          required
                          id="demo-simple-select"
                          value={termObj}
                          label="Term"
                          onChange={(e) => {
                            setTermObj(e.target.value);
                          }}
                        >
                          {subjectMarkTermData.map((item, index) => {
                            return (
                              <MenuItem value={item.id} key={index} hidden={(item.id == 3) ? true : false}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Exam :</label>
                    <div className='col-sm-3'>
                      <Autocomplete
                        id="combo-box-demo"
                        options={examData}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                        )}
                        onChange={(event, newValue) => {
                          setExamObj(newValue);
                        }}
                        value={examObj}
                        onKeyPress={(e) => (e.key == "Enter") ? examObj : ''}
                        style={{ fontSize: '14px' }}
                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                        size="small"
                        renderInput={params => (
                          <TextField {...params} margin='dense' label="Select Exam" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                        )}
                      />
                    </div>
                  </div>

                  <div className="row py-2 mb-4 mt-4" style={{ backgroundColor: "#F5EEF8", border: "1px solid #BEBEBE" }}>
                    <div className="row">
                      <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Subject :</label>
                      <div className='col-sm-3'>
                        <Autocomplete
                          id="combo-box-demo"
                          options={subjectData}
                          PaperComponent={({ children }) => (
                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                          )}
                          onChange={(event, newValue) => {
                            setSubjectObj(newValue);
                          }}
                          value={subjectObj}
                          onKeyPress={(e) => (e.key == "Enter") ? subjectObj : ''}
                          style={{ fontSize: '14px' }}
                          getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                          size="small"
                          renderInput={params => (
                            <TextField {...params} style={{ backgroundColor: '#fff' }} margin='dense' label="Select Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                            // required 
                            />
                          )}
                        />
                      </div>

                      <label className="" style={{ width: '100px', fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                      <div className='col-sm-2' >
                        <FormControl fullWidth margin="dense">
                          <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                          <Select
                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                            size='small'
                            fullWidth
                            margin="dense"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={option}
                            label="Option"
                            style={{ backgroundColor: '#fff' }}
                            onChange={(e) => {
                              setOption(e.target.value);
                            }}
                            onKeyPress={(e) => (e.key == "Enter") ? option : ''}
                          >
                            <MenuItem value={1}>Theory</MenuItem>
                            <MenuItem value={2}>Practical</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                      <div className='col-sm-2'>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Date"
                            value={dateValue}
                            inputFormat="dd/MM/yyyy"
                            onChange={(newValue) => {
                              setDateValue(newValue);
                            }}
                            onKeyPress={(e) => (e.key == "Enter") ? dateValue : ''}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                //required
                                fullWidth
                                autoComplete='off'
                                style={{ backgroundColor: '#fff' }}
                                size="small" />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div className="row my-2">
                      <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Starting Time :</label>
                      <div className="col-sm-2">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            label="Time"
                            value={startTime}
                            onChange={handleStartTimeChange}
                            ampm={false} // Set ampm prop to false to remove AM/PM indicator
                            inputFormat="HH:mm"
                            renderInput={(params) =>
                              <TextField
                                size='small'
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                //required
                                fullWidth
                                style={{ backgroundColor: '#fff' }}
                                autoComplete='off'
                                {...params} />}

                          />
                        </LocalizationProvider>
                      </div>

                      <label htmlFor="lastname3" className="" style={{ width: '100px', fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>End Time :</label>
                      <div className="col-sm-2">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            label="Time"
                            value={endTime}
                            onChange={handleEndTimeChange}
                            ampm={false} // Set ampm prop to false to remove AM/PM indicator
                            inputFormat="HH:mm"
                            renderInput={(params) =>
                              <TextField
                                size='small'
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                // required
                                fullWidth
                                style={{ backgroundColor: '#fff' }}
                                autoComplete='off'
                                {...params} />}

                          />
                        </LocalizationProvider>
                      </div>

                      <div className="col-sm-4">
                        <button type='button' className={saveBtnSubject} onClick={() => { getTableData(); }} disabled={(subjectObj == null || option == null || dateValue == null || startTime == null || endTime == null) ? true : false}>
                          <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                        </button>
                        {/* <button type='button' className={updateBtnSubject}>
                          <CheckCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                        </button> */}
                        <button type="button" className='btn p-0 mt-3' onClick={() => clearAllValues()}>
                          <CancelIcon fontSize='medium' style={{ color: "#FA4A3F" }} />
                        </button>
                      </div>
                    </div>

                    <div className="table-responsive mt-4 mb-2" style={{ maxHeight: "300px" }}>
                      <table className="table">
                        <thead className=''>
                          <tr>
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Sr. No.</th>
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Subject</th>
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Option</th>
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Date</th>
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Start Time</th>
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>End Time</th>
                            {/* <th style={{ fontSize: "14px", fontWeight: "600", minWidth: "20px" }}>Edit</th> */}
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(() => {
                            if (tableData.length != 0) {
                              return (
                                tableData.map((item, index) => {
                                  //console.log(item.validityDate)
                                  return (
                                    <tr key={index}>
                                      <td style={{ fontSize: "15px", fontWeight: "500" }}>{index + 1}</td>
                                      <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                        {
                                          subjectData.map(e => {
                                            if (e.id == item.subjectId) {
                                              return (authUser.branchMedium == 1) ? e.nameMr : e.nameEn
                                            }
                                          })
                                        }
                                        {/* {(authUser.branchMedium == 1) ? item.subjectNameMr : item.subjectNameEn} */}
                                      </td>
                                      <td style={{ fontSize: "15px", fontWeight: "500" }}>{
                                        (item.option == 1)
                                          ?
                                          "Theory"
                                          :
                                          "Practical"
                                      }</td>
                                      <td style={{ fontSize: "15px", fontWeight: "500" }}>{moment(item.date).format("DD/MM/YYYY")}</td>
                                      <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.startTime}</td>
                                      <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.endTime}</td>

                                      <td >
                                        <Tooltip title="Delete">
                                          <button type='button' className='btn btn-sm' onClick={() => deletSubjectData(item.subjectId)}>
                                            <DeleteIcon fontSize='medium' style={{ color: '#D73016' }} />
                                          </button>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  )
                                })
                              )
                            }
                          })()}

                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-sm-12">
                      <button type="submit" onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn} style={{ margin: '0px 4px' }}>Save</button>
                      <button type="submit" onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn} style={{ margin: '0px 4px' }}>Update</button>
                      <button type="button" className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/acd429/")}>Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </>
      }
    </>
  )
}

export default ExamTimeTableForm
