const labelName = [

    {
        id: 7,
        title: "Caste Category",
        GetMapping:"Categories",
        PostMapping:"saveCategory",
        PutMapping:"updateCategory",
        DeleteMapping:"deleteCategory"            
    },
    {
        id: 11,
        title: "Domicile",
        GetMapping:"Domiciles",
        PostMapping:"saveDomicile",
        PutMapping:"updateDomicile",
        DeleteMapping:"deleteDomicile"            
    },
    {
        id: 12,
        title: "Religion",
        GetMapping:"Religions",
        PostMapping:"saveReligion",
        PutMapping:"updateReligion",
        DeleteMapping:"deleteReligion"            
    },
    
    {
        id: 16,
        title: "Subject",
        GetMapping:"Subjects",
        PostMapping:"saveSubject",
        PutMapping:"updateSubject",
        DeleteMapping:"deleteSubject"            
    },
    {
        id: 20,
        title: "Language",
        GetMapping:"Languages",
        PostMapping:"saveLanguage",
        PutMapping:"updateLanguage",
        DeleteMapping:"deleteLanguage"            
    },
    {
        id: 22,
        title: "CWSN",
        GetMapping:"Cwsns",
        PostMapping:"saveCwsn",
        PutMapping:"updateCwsn",
        DeleteMapping:"deleteCwsn"         
    },
    {
        id: 127,
        title: "Minority",
        GetMapping:"Minorities",
        PostMapping:"saveMinority",
        PutMapping:"updateMinority",
        DeleteMapping:"deleteMinority"            
    }
];

export default labelName;