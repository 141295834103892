const optionJSON = [
    {
        id: 1,
        optionName: "All",
        getMapping: "",
    },
    {
        id: 2,
        optionName: "Title",
        getMapping: "getAllTitleMasterList",
    },
    {
        id: 3,
        optionName: "Author",
        getMapping: "getAllAuthorMasterList",
    },
    {
        id: 4,
        optionName: "Publisher",
        getMapping: "getAllPublisherMasterList",
    },
    {
        id: 5,
        optionName: "Vendor",
        getMapping: "getAllVendorMasterList",
    },
    {
        id: 6,
        optionName: "Purchase Date",
        getMapping: "",
    },
    {
        id: 7,
        optionName: "Source",
        getMapping: "getAllSourceMasterList",
    },
    {
        id: 8,
        optionName: "Subject",
        getMapping: "Subjects",
    },
    {
        id: 9,
        optionName: "Department",
        getMapping: "getAllDepartmentMasterList",
    },
    {
        id: 10,
        optionName: "Language",
        getMapping: "Languages",
    },
]

export default optionJSON;