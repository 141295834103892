import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//monthWise
//localhost:8080/institute/sss/payroll/employee/report/getEmployeeAttendanceMonthWiseReport?langId=1&option=1&empId=25&year=2022&bid=1&monthData=02/2023
export const printMonthWiseEmpAttendreportAPIURL = BaseURL + "payroll/employee/report/getEmployeeAttendanceMonthWiseReport";

//dateWise
//localhost:8080/institute/sss/payroll/employee/report/getEmployeeAttendanceListReport?langId=1&option=2&attDateFrom=2023-03-02&attDateTo=2023-03-18&empId=25&year=2022&bid=1
export const printDateWiseEmpAttendreportAPIURL = BaseURL + "payroll/employee/report/getEmployeeAttendanceListReport";


//Employee Leave Entry
//localhost:8080/institute/sss/payroll/employee/report/getEmployeeLeaveReport?langId=1&option=2&empId=25&year=2022&bid=1
export const printEmpLeaveEntryReportAPIURL = BaseURL + "payroll/employee/report/getEmployeeLeaveReport";