import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Loader from '../../../CommonComponent/Loader';
import { printEndowmentReportAPIURL } from '../Services/EndowmentReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';


const EndowmentList = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const titleId = "Endowment List";

    const [loaderOption, setLoaderOption] = useState(false);

    const printEndowmentMasterReport = () => {
        setLoaderOption(true);
        axios.get(`${printEndowmentReportAPIURL}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)   
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {

                toast.error("Error Occoured, Try after sometime.");
                console.log("Endowment Master Report failed: " + error)
            })
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                            <div className=''>
                                <button
                                    type='button'
                                    onClick={() => printEndowmentMasterReport()}
                                    className='btn btn-primary btn-sm mb-2'
                                //disabled={btnDisabled}
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>

                                {/* <button type="submit" className="btn btn-danger btn-md" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/")}>Exit</button> */}
                            </div>
                        </div>
                    </div>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default EndowmentList