import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { NarrationMasterDelete } from '../../CommonServices/NarrationMasterAPIURL';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';



const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});


const MasterFormNarrationMasterTable = ({ filteredData, searchTerm, setSearchTerm, setFilteredData, setNarrationId, TableHeading, setId, setNarrationDetail, data, getData, setData, saveBtnStyle, updateBtnStyle, displayNoneBtnStyle, setSaveBtn, setUpdateBtn }) => {
    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const UpdateData = (item) => {
        setId(item.id);
        setNarrationDetail(item.narrationDetail);
        setNarrationId(item.narrationId);
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);

    }

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = (id) => {

        axios.delete(`${NarrationMasterDelete}` + id)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setSearchTerm("");
                    toast.success("Deleted sucessfully");
                }
            });

    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.narrationDetail.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.narrationId.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    return (
        <>
            <div className='row mt-1 mb-2'>
                <div >
                    <input
                        className='form-control form-control-sm'
                        type="text"
                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                    />
                </div>
            </div>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table table-bordered">
                    <TableHead className="table-Default" sx={{ backgroundColor: '#F3F1F0' }}>
                        <TableRow>
                            {TableHeading.map((item, index) => {
                                return (
                                    <TableCell style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .reverse().map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <TableCell style={{ fontSize: "15px", fontWeight: "500" }}>{item.narrationId}</TableCell>
                                            <TableCell style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.6" }}>{item.narrationDetail}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Edit">
                                                    <span className='btn btn-primary btn-sm mx-1 p-1'
                                                        onClick={() => UpdateData(item)} data-bs-toggle="tooltip">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Delete">
                                                    <span className='btn btn-danger btn-sm mx-1 p-1'
                                                        data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                        onClick={() => setDeleteId(item.id)}                                                >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })}
                    </TableBody>
                </Table>
                <TableFooter style={{ padding: "0px", float: 'right' }}>
                    <TableRow>
                        <TablePagination
                            style={{ padding: "0px" }}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onRowsPerPageChange={onRowsPerPageChange}
                            onPageChange={onPageChange}
                            labelRowsPerPage="Rows Per Page :"
                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                    </TableRow>
                </TableFooter>
            </TableContainer>

            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default MasterFormNarrationMasterTable;
