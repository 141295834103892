import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { classWithDivURL, printCatalog, printCatalogMainPage } from '../Services/CatalogReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const CatalogReport = () => {
    const classes = useStyles();
    const titleId = "Catalog Report";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(`${authUser.sessionYear}/06/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    const [dateFrom, setDateFrom] = useState(null);

    const [dateCheck, setDateCheck] = useState(null);
    //console.log(`date=${moment(dateFrom).format("MM/YYYY")}`)
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [printBlank, setPrintBlank] = useState(0);
    const [pageFormat, setPageFormat] = useState(0);
    const [blankLinesBoysGirls, setBlankLinesBoysGirls] = useState(0);
    const [blankLinesCatalogEnd, setBlankLinesCatalogEnd] = useState(0);
    const [option, setOption] = useState(1);

    const HandlePrintBlankCheckbox = (e) => {
        setPrintBlank(e.target.checked);
        if (e.target.checked == true) {
            setPrintBlank(1);
        }
        else {
            setPrintBlank(0);
        }
    }


    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${classWithDivURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const catalogReportPrint = async () => {

        if (moment(dateFrom).format("MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            setLoaderOption(true);
            //console.log(`${printCatalog}?did=${classDivObj.divisionId}&mdata=${moment(dateFrom).format("MM/YYYY")}&blgb=${blankLinesBoysGirls}&ble=${blankLinesCatalogEnd}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${printCatalog}?did=${classDivObj.divisionId}&mdata=${moment(dateFrom).format("MM/YYYY")}&blgb=${blankLinesBoysGirls}&ble=${blankLinesCatalogEnd}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&printPortrait=${pageFormat}`)
                .then((response) => {
                    if (response.data === "Warring : Class Teacher not assigned ") {
                        setLoaderOption(false);
                        toast.warn("Class Teacher not assigned for this class-division.")
                        //console.log("aaaaaaaaaa")
                    }
                    else if (response.data === "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Roll no not assigned to students for this class-division.")
                        //console.log("aaaaaaaaaa")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }
                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Catalog failed: " + error)
                })
            //console.log("valid Date")
        }
        else {
            toast.error("Improper date, Please check.");
        }

    }

    const catalogReportPrintMainPage = async () => {
        if (moment(dateFrom).format("MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            setLoaderOption(true);
            //console.log(`${printCatalogMainPage}?did=${classDivObj.divisionId}&mdata=${moment(dateFrom).format("MM/YYYY")}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${printCatalogMainPage}?did=${classDivObj.divisionId}&mdata=${moment(dateFrom).format("MM/YYYY")}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {

                    if (response.data === "Warring : Class Teacher not assigned ") {
                        setLoaderOption(false);
                        toast.warn("Class Teacher not assigned for this class-division.")
                    }
                    else if (response.data === "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Roll no not assigned to students for this class-division.")
                        //console.log("aaaaaaaaaa")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }
                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Catalog failed: " + error)
                })
            // toast.success("proper date, Please check.");
        }
        else {
            toast.error("Improper date, Please check.");
        }

    }

    const getCatalogReport = e => {
        e.preventDefault();
        if (option == 1) {
            catalogReportPrintMainPage();
        }
        else if (option == 2) {
            catalogReportPrint();
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={getCatalogReport}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row mt-2">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    fullWidth

                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Select Option"
                                                    onChange={(e) => setOption(e.target.value)}
                                                >
                                                    <MenuItem value={1}>Main Page</MenuItem>
                                                    <MenuItem value={2}>Detailed Pages</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date"
                                                    value={dateFrom}
                                                    minDate={defaultMinDate}
                                                    maxDate={defaultMaxDate}
                                                    views={['year', 'month']}
                                                    inputFormat="MM/yyyy"
                                                    mask="__/____"
                                                    disableMaskedInput={false}
                                                    onChange={(newValue) => {
                                                        setDateFrom(newValue);
                                                        //console.log(moment(newValue).format("MM/YYYY"))
                                                        (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                            {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                                        </div>

                                        <label className="" style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={classDivData}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                value={classDivObj}
                                                onChange={(event, newvalue) => {
                                                    setClassDivObj(newvalue);
                                                }}

                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {(() => {
                                        if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row mt-3">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Page Format :</label>
                                                        <div className='col-sm-3'>
                                                            <FormControl fullWidth margin='dense'>
                                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                                <Select MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                                    size='small' labelId="demo-simple-select-label" required id="demo-simple-select" value={pageFormat}
                                                                    label="Select Option" onChange={(e) => {
                                                                        setPageFormat(e.target.value);
                                                                    }}>
                                                                    <MenuItem value={0}>Landscape</MenuItem>
                                                                    <MenuItem value={1}>Portrait</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div className='col-sm-2'>
                                                            <FormControlLabel control={<Checkbox checked={printBlank} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 540, fontSize: "16px", marginTop: "2px" } }} label="Print Blank" />
                                                        </div>
                                                    </div>


                                                    <div className="row my-2">
                                                        <label className="col-sm-4" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >No. of Blank Lines Between Boys and Girls :</label>
                                                        <div className='col-sm-3'>
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="No. Of Lines"
                                                                variant="outlined"
                                                                margin='dense'
                                                                value={blankLinesBoysGirls}
                                                                onChange={e => setBlankLinesBoysGirls(e.target.value)}
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row my-2">
                                                        <label className="col-sm-4" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >No. of Blank Lines at the End of Catalog :</label>
                                                        <div className='col-sm-3'>
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="No. Of Lines"
                                                                variant="outlined"
                                                                margin='dense'
                                                                value={blankLinesCatalogEnd}
                                                                onChange={e => setBlankLinesCatalogEnd(e.target.value)}
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                inputProps={{ maxLength: 120 }}

                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                    <div className="row mt-3">
                                        <div className={(option == 1) ? "col-sm-4 offset-sm-1" : "col-sm-4"}>
                                            <button type="submit" className="btn btn-primary btn-sm" >Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}




export default CatalogReport