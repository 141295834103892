import { BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";


// /localhost:8080/institute/sss/library/master/getAllTitleMasterList?branchId=1
export const getTitleMasterData = BaseURL + "library/master/getAllTitleMasterList";

// get single Title Master
// GetMapping
// localhost:8080/institute/sss/library/master/getTitleMasterData?id=1
export const getTitleMasterById = BaseURL + "library/master/getTitleMasterData";

// save Title master
// PostMapping
// localhost:8080/institute/sss/library/master/saveTitleMaster
// {
//     "titleName": "novel123",
//     "subTitleName": "novel",
//     "authorId": "1,2",
//     "publisherId": 1,
//     "branchId": 1
// }
export const saveTitleMaster = BaseURL + "library/master/saveTitleMaster";

// update title master
// PutMapping
// localhost:8080/institute/sss/library/master/updateTitleMaster
// {
//     "id": 1,
//     "titleName": "book12",
//     "subTitleName": "book",
//     "authorId": "1,2,3",
//     "publisherId": 1,
//     "branchId": 1
// }
export const updateTitleMaster = BaseURL + "library/master/updateTitleMaster";

// delete title master
// DeleteMapping
// localhost:8080/institute/sss/library/master/deleteTitleMaster?id=1
export const deleteTitleMaster = BaseURL + "library/master/deleteTitleMaster";