import React, { useEffect, useState } from 'react'
import './LibraryDashboard.css';
import book from '../assets/dashboard/book.png';
import LibraryBook from '../assets/dashboard/LibraryBook.png';
import bookAuthor from '../assets/dashboard/bookAuthor.png';
import author from '../assets/dashboard/author.png';
import bookTitle from '../assets/dashboard/bookTitle.png';
import registerType from '../assets/dashboard/registerType.png';
import { useSelector } from "react-redux";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { getRegisterTypeDataAPIURL } from './LibraryServices/RegisterTypeMasterAPIURL';
import axios from 'axios';
import { getAccessionRegisterDataAPIURL } from './Acquisition/Services/AccessionEntryAPIURL';

const LibraryDashboard = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const [registerTypeData, setRegisterTypeData] = useState([]);
    const [accessionData, setAccessionData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.branchId != null) {
            await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setRegisterTypeData(res.data);
                })

            //console.log(`${getAccessionRegisterDataAPIURL}?branchId=${authUser.branchId}`)
            await axios.get(`${getAccessionRegisterDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setAccessionData(res.data);
                })
        }
    }

    return (
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
            <div className="row">
                <div className="col-md-4 col-xl-4">
                    <div className="card bg-c-one order-card-height">
                        <div className="card-body card-block">
                            <span className="text-right" >
                                <img src={book} className="f-left" style={{ width: "100px" }} />
                                <h5>Total Books</h5>
                                <span className="f-right" style={{ fontSize: "18px", fontWeight: "600" }}>0
                                    <br />
                                    0.00
                                </span>
                            </span>
                        </div>
                        <div className="card-footer"
                            style={{
                                // backgroundColor: `#FB8085`,
                                // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                height: "35px",
                                borderTopLeftRadius: "20px",
                                borderTopRightRadius: "20px",
                                textAlign: "center",
                                padding: "5px",
                                cursor: "pointer"
                            }}
                        >
                            <div onClick={() => navigate('/Home/la901/')}>
                                <span className="badge rounded-pill badge-text">More Info <ArrowForwardIcon fontSize='small' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-8">
                    <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "160px", marginBottom: "30px" }}>
                        {/* Header */}
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                            <span><AccountBalanceIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                            <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Register Type</span>
                        </div>
                        {/* Body */}
                        <div className='bodyWrapper' style={{ maxHeight: "180px", overflowY: "scroll", margin: "4px 0px" }}>
                            <div>
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9", }}>
                                            <tr>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Sr No.</th>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Regsiter Type</th>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Count</th>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Prize</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                registerTypeData.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{index + 1}</td>
                                                            <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.registerTypeName}</td>
                                                            <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{(accessionData.filter(e => e.registerTypeName == item.registerTypeName)).length}</td>
                                                            <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-1">
                <div className="col-md-4 col-xl-4">
                    <div className="card bg-c-four order-card-height">
                        <div className="card-body card-block">
                            <span className="text-right" >
                                <img src={bookTitle} className="f-left" style={{ width: "100px" }} />
                                <h5 className='mt-3'>Total Title</h5>
                                <span className="f-right" style={{ fontSize: "18px", fontWeight: "600" }}>0
                                    {/* <br />
                                    1500 */}
                                </span>
                            </span>
                        </div>
                        <div className="card-footer"
                            style={{
                                // backgroundColor: `#FB8085`,
                                // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                height: "35px",
                                borderTopLeftRadius: "20px",
                                borderTopRightRadius: "20px",
                                textAlign: "center",
                                padding: "5px",
                                cursor: "pointer"
                            }}
                        >
                            <div onClick={() => navigate('/Home/la901/')}>
                                <span className="badge rounded-pill badge-text">More Info <ArrowForwardIcon fontSize='small' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-xl-4">
                    <div className="card bg-c-three order-card-height">
                        <div className="card-body card-block">
                            <span className="text-right" >
                                <img src={author} className="f-left" style={{ width: "100px" }} />
                                <h5 className='mt-3'>Total Authors</h5>
                                <span className="f-right" style={{ fontSize: "18px", fontWeight: "600" }}>0
                                    {/* <br />
                                    1500 */}
                                </span>
                            </span>
                        </div>
                        <div className="card-footer"
                            style={{
                                // backgroundColor: `#FB8085`,
                                // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                height: "35px",
                                borderTopLeftRadius: "20px",
                                borderTopRightRadius: "20px",
                                textAlign: "center",
                                padding: "5px",
                                cursor: "pointer"
                            }}
                        >
                            <div onClick={() => navigate('/Home/la901/')}>
                                <span className="badge rounded-pill badge-text">More Info <ArrowForwardIcon fontSize='small' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-xl-4">
                    <div className="card bg-c-eight order-card-height">
                        <div className="card-body card-block">
                            <span className="text-right" >
                                <img src={bookAuthor} className="f-left" style={{ width: "100px" }} />
                                <h5 className='mt-3'>Total Publishers</h5>
                                <span className="f-right" style={{ fontSize: "18px", fontWeight: "600" }}>0
                                    {/* <br />
                                    1500 */}
                                </span>
                            </span>
                        </div>
                        <div className="card-footer"
                            style={{
                                // backgroundColor: `#FB8085`,
                                // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                height: "35px",
                                borderTopLeftRadius: "20px",
                                borderTopRightRadius: "20px",
                                textAlign: "center",
                                padding: "5px",
                                cursor: "pointer"
                            }}
                        >
                            <div onClick={() => navigate('/Home/la901/')}>
                                <span className="badge rounded-pill badge-text">More Info <ArrowForwardIcon fontSize='small' /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-1">
                <div className="col-md-4 col-xl-4">
                    <div className="card bg-c-five order-card-height">
                        <div className="card-body card-block">
                            <span className="text-right" >
                                <img src={LibraryBook} className="f-left" style={{ width: "100px" }} />
                                <h5 className="mt-3">Total Issued</h5>
                                <span className="f-right" style={{ fontSize: "18px", fontWeight: "600" }}>0
                                    {/* <br />
                                    1500 */}
                                </span>
                            </span>
                        </div>
                        <div className="card-footer"
                            style={{
                                // backgroundColor: `#FB8085`,
                                // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                height: "35px",
                                borderTopLeftRadius: "20px",
                                borderTopRightRadius: "20px",
                                textAlign: "center",
                                padding: "5px",
                                cursor: "pointer"
                            }}
                        >
                            <div onClick={() => navigate('/Home/la901/')}>
                                <span className="badge rounded-pill badge-text">More Info <ArrowForwardIcon fontSize='small' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-xl-4">
                    <div className="card bg-c-six order-card-height">
                        <div className="card-body card-block">
                            <span className="text-right" >
                                <img src={LibraryBook} className="f-left" style={{ width: "100px" }} />
                                <h5 className="mt-3">Total Withdrawal</h5>
                                <span className="f-right" style={{ fontSize: "18px", fontWeight: "600" }}>0
                                    {/* <br />
                                    1500 */}
                                </span>
                            </span>
                        </div>
                        <div className="card-footer"
                            style={{
                                // backgroundColor: `#FB8085`,
                                // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                height: "35px",
                                borderTopLeftRadius: "20px",
                                borderTopRightRadius: "20px",
                                textAlign: "center",
                                padding: "5px",
                                cursor: "pointer"
                            }}
                        >
                            <div onClick={() => navigate('/Home/la901/')}>
                                <span className="badge rounded-pill badge-text">More Info <ArrowForwardIcon fontSize='small' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-xl-4">
                    <div className="card bg-c-seven order-card-height">
                        <div className="card-body card-block">
                            <span className="text-right" >
                                <img src={LibraryBook} className="f-left" style={{ width: "100px" }} />
                                <h5 className="mt-3">Available Books</h5>
                                <span className="f-right" style={{ fontSize: "18px", fontWeight: "600" }}>0
                                    {/* <br />
                                    1500 */}
                                </span>
                            </span>
                        </div>
                        <div className="card-footer"
                            style={{
                                // backgroundColor: `#FB8085`,
                                // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                height: "35px",
                                borderTopLeftRadius: "20px",
                                borderTopRightRadius: "20px",
                                textAlign: "center",
                                padding: "5px",
                                cursor: "pointer"
                            }}
                        >
                            <div onClick={() => navigate('/Home/la901/')}>
                                <span className="badge rounded-pill badge-text">More Info <ArrowForwardIcon fontSize='small' /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default LibraryDashboard