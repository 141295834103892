import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//term Wise Health APIURL
//http://192.168.1.102:8080/institute/sss/academics/exam/getTermWiseHealthList?classId=5&divId=17&year=2022&sid=3&bid=1&termId=1
export const getStudentsForTermWiseHealthAPIURL = BaseURL + "academics/exam/getTermWiseHealthList";

//localhost:8080/institute/sss/academics/exam/saveTermWiseHealth
// [
//     {
//         "regNo": 12707,
//         "medicalOpinion": "medical opinion",
//         "weight": "50",
//         "bmi": "15",
//         "height": "165",
//         "termId": 1,
//         "date": "2023-05-06",
//         "sessionYear": 2022,
//         "sectionId": 3,
//         "branchId": 1
//     }
// ]
export const saveTermwiseHealthAPIURL = BaseURL + "academics/exam/saveTermWiseHealth";



//term Wise observation APIURL
//localhost:8080/institute/sss/academics/exam/getTermWiseObservationByRegNo?regNo=12451&termId=1&year=2022&sid=3&bid=1
export const getTermWiseObservationDataAPIURL = BaseURL + "academics/exam/getTermWiseObservationByRegNo";

// /localhost:8080/institute/sss/academics/exam/saveTermWiseObservation
// {
//     "regNo": 12451,
//     "termId": 1,
//     "specialProgress": "Boys english reading is very good",
//     "hobbies": "Boys hobby",
//     "needsImprovement": "Needs improvement",
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveTermWiseObservationAPIURL = BaseURL + "academics/exam/saveTermWiseObservation";

//localhost:8080/institute/sss/academics/exam/getStudentListByClassAndDivision?classId=5&divId=17&year=2022&sid=3&bid=1
export const getStudentsByClassDivAPIURL = BaseURL + "academics/exam/getStudentListByClassAndDivision";

// /localhost:8080/institute/sss/academics/exam/getTermWiseObservationByRegNo?classId=5&divId=17&year=2022&sid=3&bid=1&termId=1
export const getStudentListForTermWiseObsAPIURL = BaseURL + "academics/exam/getTermWiseObservationByClassId";

//localhost:8080/institute/sss/academics/exam/deleteTermWiseObservation?id=3
export const deleteTermWiseObsAPIURL = BaseURL + "academics/exam/deleteTermWiseObservation";

//Subject Wise observation APIURL
//localhost:8080/institute/sss/academics/exam/getSubjectWiseObservationByRegNo?regNo=12451&termId=2&year=2022&sid=3&bid=1&subId=3
export const getSubjectWiseObservationAPIURL = BaseURL + "academics/exam/getSubjectWiseObservationByRegNo";

// /localhost:8080/institute/sss/academics/exam/saveSubjectWiseObservation
// {
//     "regNo": 12451,
//     "termId": 2 ,
//     "subjectId": 3,
//     "observation": "Observation marathi new",
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveSubjectWiseObservationAPIURL = BaseURL + "academics/exam/saveSubjectWiseObservation";

//localhost:8080/institute/sss/academics/exam/getSubjectWiseObservationByClassId?classId=5&divId=17&year=2022&sid=3&bid=1&termId=1&subId=1
export const getClassDivSubAndTermWiseSubWiseObsAPIURL = BaseURL + "academics/exam/getSubjectWiseObservationByClassId";

//localhost:8080/institute/sss/academics/exam/deleteSubjectWiseObservation?id=3
export const deleteSubjectWiseObsAPIURL = BaseURL + "academics/exam/deleteSubjectWiseObservation";

