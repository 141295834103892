const masterReportJSON=[
	{
		id:1,
		name:"Academic Qual Master"
	},
	{
		id:2,
		name:"Caste Master"
	},
	{
		id:3,
		name:"Class Master"
	},
	{
		id:4,
		name:"Concession Master"
	},
	{
		id:5,
		name:"Designation Master"
	},
	{
		id:6,
		name:"Disibility Master"
	},
	{
		id:7,
		name:"Leaving Remark Master"
	},
	{
		id:8,
		name:"Mothertongue Master"
	},
	{
		id:9,
		name:"Nationality Master"
	},
	{
		id:10,
		name:"Occupation Master"
	},
	{
		id:11,
		name:"Professional Qual Master"
	},
	{
		id:12,
		name:"Progress Conduct Master"
	},
	{
		id:13,
		name:"Stream Master"
	},
	{
		id:14,
		name:"Category Master"
	},
	{
		id:15,
		name:"CWSN Master"
	},
	{
		id:16,
		name:"Domicile Master"
	},
	{
		id:17,
		name:"Language Master"
	},
	{
		id:18,
		name:"Minority Master"
	},
	{
		id:19,
		name:"Religion Master"
	},
	{
		id:20,
		name:"Subject Master"
	},
	{
		id:21,
		name:"Division Master"
	},
	{
		id:22,
		name:"Sub-Caste Master"
	},
	{
		id:23,
		name:"Exam Grade Master"
	},
	{
		id:24,
		name:"Exam Master"
	},
	{
		id:25,
		name:"Sub Exam Master"
	},
	{
		id:26,
		name:"Fee Master"
	}
];

export default masterReportJSON;