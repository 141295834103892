import { dBaseUrlLibraryMaster, BaseUrlLibraryMaster } from "../../CommonServices/APIURL";

// /localhost:8080/institute/sss/library/master/getAllMemberTypeMasterList?branchId=1
export const getMemberTypeDataAPIURL = BaseUrlLibraryMaster + "getAllMemberTypeMasterList";

// /localhost:8080/institute/sss/library/master/getMemberTypeMasterData?id=1
export const getMemberTypeDataById = BaseUrlLibraryMaster + "getMemberTypeMasterData";

// localhost:8080/institute/sss/library/master/saveMemberTypeMaster
// {
//     "memberTypeName": "new member",
//     "bookReservedDays": 5,
//     "bookMaxCount": 4,
//     "barcodeId": "new barcode",
//     "branchId": 1
// }
export const saveMemberTypeDataAPIURL = BaseUrlLibraryMaster + "saveMemberTypeMaster";

// localhost:8080/institute/sss/library/master/updateMemberTypeMaster
// {
//     "id":1,
//     "memberTypeName": "Daud",
//     "bookReservedDays": 5,
//     "bookMaxCount": 4,
//     "barcodeId": "new barcode",
//     "branchId": 1
// }
export const updateMemberTypeDataAPIURL = BaseUrlLibraryMaster + "updateMemberTypeMaster";

//localhost:8080/institute/sss/library/master/deleteMemberTypeMaster?id=2
export const deleteMemberTypeDataAPIURL = BaseUrlLibraryMaster + "deleteMemberTypeMaster";