import React, { useEffect } from 'react'
import MasterHeader from './BaseComponent/Menu/MasterHeader';
import MasterContent from './MasterContent';
import MasterFooter from './BaseComponent/Menu/MasterFooter';
import { login } from './reduxFeature/user';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
const Home = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const authUser = useSelector((state) => state.user.value);
    useEffect(() => {

        const getData = sessionStorage.getItem("inst-sss-d");
        if (getData) {

            const localUserData = (JSON.parse(getData));
            dispatch(login({ roleId: localUserData.roleId, roleName: localUserData.roleName, deptId: localUserData.deptId, deptName: localUserData.deptName, branchId: localUserData.branchId, branchName: localUserData.branchName, yearLabel: localUserData.yearLabel, sessionYear: localUserData.sessionYear, fromDate: localUserData.fromDate, toDate: localUserData.toDate, accApl: localUserData.accApl, branchMedium: localUserData.branchMedium, username: localUserData.username, accountingType: localUserData.accountingType, feeType: localUserData.feeType, trustId: localUserData.trustId }));
        }
        else {
            navigate("/");
        }
    }, []);


    return (
        <>
            <div className="container-scroller">
                <MasterHeader />
                <MasterContent />
                <MasterFooter />
            </div>
        </>
    )
};

export default Home;


