import {BaseIP, BaseURL, BaseURLMaster} from "./APIURL";

// Class Master API Link
export const documentMasterGetMapping=BaseURLMaster + "getStudentDocuments/";
export const documentMasterPostMapping=BaseURLMaster + "saveStudentDocument/";
export const documentMasterPutMapping=BaseURLMaster + "updateStudentDocument/";
export const documentMasterDeleteMapping=BaseURLMaster + "deleteStudentDocument/";


// export const documentMasterGetMapping="http://192.168.1.102:8080/institute/sss/master/getStudentDocuments/";
// export const documentMasterPostMapping="http://192.168.1.102:8080/institute/sss/master/saveStudentDocument/";
// export const documentMasterPutMapping="http://192.168.1.102:8080/institute/sss/master/updateStudentDocument/";
// export const documentMasterDeleteMapping="http://192.168.1.102:8080/institute/sss/master/deleteStudentDocument/";
