import { BaseURL, dBaseURL } from "../../../CommonServices/APIURL";

//localhost:8080/institute/sss/budget/master/getAllBudgetAccount
export const allBudgetAccountList = BaseURL + "budget/master/getAllBudgetAccount";

//localhost:8080/institute/sss/budget/master/getBudgetAccount?id=1
export const getBudgetAccountById = BaseURL + "budget/master/getBudgetAccount";

//localhost:8080/institute/sss/budget/master/saveBudgetAccount
// {
//     "budgetCategoryId": 4,
//     "nameMr": "name Mr",
//     "nameEn": "name Mr",
//     "srNo": 1
// }
export const saveBudgetAccountAPIURL = BaseURL + "budget/master/saveBudgetAccount";

//localhost:8080/institute/sss/budget/master/updateBudgetAccount
export const updateBudgetAccountAPIURL = BaseURL + "budget/master/updateBudgetAccount";

//localhost:8080/institute/sss/budget/master/deleteBudgetAccount?id=1
export const deleteBudgetAccountAPIURL = BaseURL + "budget/master/deleteBudgetAccount";
