import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const classWithDivisionURL = BaseURLMaster + "classWithDivisionBySectionId";
export const getExamData = BaseURLMaster + "Exams/";
export const getSubjectData = BaseURLMaster + "Subjects/";


//Exam Allocation Report API
//localhost:8080/institute/sss/academic/reports/generateAllClassAndDivisionWiseExamAllocationReport?langId=1&acYear=2021&sid=3&bid=1
export const printAllExamAllocationList = BaseURL + "academic/reports/generateAllClassAndDivisionWiseExamAllocationReport";

//localhost:8080/institute/sss/academic/reports/generateClassAndDivisionWiseExamAllocationReport?langId=1&acYear=2021&sid=3&bid=1&classId=10&divId=39
export const printClassDivWiseExamAllocationList = BaseURL + "academic/reports/generateClassAndDivisionWiseExamAllocationReport";



//Exam Mark Allocation Report API
//localhost:8080/institute/sss/academic/reports/generateClassDivisionExamWiseExamMarkAllocationReport?langId=2&acYear=2021&sid=3&bid=1&examId=9&classId=5&divisionId=17
export const printExamMarkAllocationList = BaseURL + "academic/reports/generateClassDivisionExamWiseExamMarkAllocationReport";



//subject mark sheet and result sheet report API 
//get Subject list for subject mark sheet report
//localhost:8080/institute/sss/academic/reports/getSubjectListForExam?sid=3&bid=1&acYear=2021&classId=10&divId=37
export const getSubjectDataForSubMarkSheet = BaseURL + "academic/reports/getSubjectListForExam";

//localhost:8080/institute/sss/academic/reports/generateSubjectAndTermWiseMarksSheetReport?langId=1&subId=1&classId=10&divId=37&termId=1&acYear=2021&sid=3&bid=1&printBlank=1
export const printSubjectMarkSheet = BaseURL + "academic/reports/generateSubjectAndTermWiseMarksSheetReport";

//localhost:8080/institute/sss/academic/reports/generateSubjectAndTermAndExamWiseMarksSheetReport?langId=1&subId=1&classId=10&divId=37&termId=1&acYear=2021&sid=3&bid=1&examId=4&printBlank=1
export const printSubjectMarkSheetExamWise = BaseURL + "academic/reports/generateSubjectAndTermAndExamWiseMarksSheetReport";

//localhost:8080/institute/sss/academic/reports/generateSubjectWiseFinalMarksSheetReport?langId=1&subId=1&classId=10&divId=37&acYear=2021&sid=3&bid=1&printBlank=1
export const printSubjectMarkSheetTermWise = BaseURL + "academic/reports/generateSubjectWiseFinalMarksSheetReport";

//localhost:8080/institute/sss/academic/reports/generateAllSubjectFinalMarksSheetReport?langId=1&classId=5&divId=17&acYear=2021&sid=3&bid=1&printBlank=1
export const PrintAllAndFinalSubjectMarkSheet = BaseURL + "academic/reports/generateAllSubjectFinalMarksSheetReport";

//localhost:8080/institute/sss/academic/reports/generateAllSubjectAndTermWiseFinalMarksSheetReport?langId=1&classId=5&divId=17&termId=1&acYear=2021&sid=3&bid=1&printBlank=1
export const printAllAndTermWiseSubMarkSheet = BaseURL + "academic/reports/generateAllSubjectAndTermWiseFinalMarksSheetReport";

//localhost:8080/institute/sss/academic/reports/generateFinalMarksSheetReportAboveEightAllSubject?langId=1&classId=5&divId=17&acYear=2021&sid=3&bid=1&printBlank=1
export const printAllFinalAndAboveEightSubMarkSheet = BaseURL + "academic/reports/generateFinalMarksSheetReportAboveEightAllSubject";

//localhost:8080/institute/sss/academic/reports/generateFinalMarksSheetReportAboveEightAllSubjectAndTerm?langId=1&classId=5&divId=17&termId=1&acYear=2021&sid=3&bid=1&printBlank=1
export const printAllAboveEightAndTermWiseSubMarkSheet = BaseURL + "academic/reports/generateFinalMarksSheetReportAboveEightAllSubjectAndTerm";

//localhost:8080/institute/sss/academic/reports/generateFinalMarksSheetReportAboveEight?langId=1&subId=4&classId=5&divId=17&acYear=2021&sid=3&bid=1&printBlank=1
export const printAboveEightSubjectwiseSubMarkSheet = BaseURL + "academic/reports/generateFinalMarksSheetReportAboveEightSubjectWise";

//localhost:8080/institute/sss/academic/reports/getOneThirdSheetReport?langId=2&classId=16&divId=70&subId=1&viewType=2&sessionYear=2022&sid=4&bid=10&medium=1
export const printSubjectWiseAndFinalAboveTenSubMarkSheet = BaseURL + "academic/reports/getOneThirdSheetReport";

//generateFinalMarksSheetReportJrCollegeAllSubject
export const printFinalMarkSheetAllSubjectJrCollegeReport = BaseURL + "academic/reports/generateFinalMarksSheetReportJrCollegeAllSubject";



//Praptra A Report API
//localhost:8080/institute/sss/academic/reports/generatePrapatraReport?langId=1&classId=5&divId=17&acYear=2021&sid=3&bid=1
export const printPraptraClassDivWise = BaseURL + "academic/reports/generatePrapatraReport";



//Progress card Report API
//localhost:8080/institute/sss/academic/reports/generateProgressCard?langId=1&classId=5&divId=17&termId=1&acYear=2021&sid=3&bid=1
export const printProgressCardClassDivAndTermWise = BaseURL + "academic/reports/generateProgressCard";

//localhost:8080/institute/sss/academic/reports/generateFinalProgressCard?langId=1&classId=5&divId=17&acYear=2021&sid=3&bid=1
export const printFinalProgressCardClassDivAndTermWise = BaseURL + "academic/reports/generateFinalProgressCard";

//localhost:8080/institute/sss/academic/reports/generateProgressCardByRegNo?langId=1&termId=2&acYear=2022&sid=3&bid=1&regNo=12516
export const printProgressCardStudentWiseAndTermWise = BaseURL + "academic/reports/generateProgressCardByRegNo";

//localhost:8080/institute/sss/academic/reports/generateFinalProgressCardByRegNo?langId=1&acYear=2022&sid=3&bid=1&regNo=12516
export const printFinalProgressCardStudentWise = BaseURL + "academic/reports/generateFinalProgressCardByRegNo";

//localhost:8080/institute/sss/academic/reports/generateProgressCardExamWise?langId=1&classId=16&divId=70&acYear=2022&sid=4&bid=17&termId=1&examId=4&branchMedium=2
export const printProgressCardExamWiseClassDivWise = BaseURL + "academic/reports/generateProgressCardExamWise";

//localhost:8080/institute/sss/academic/reports/generateProgressCardByExamWiseAndRegNo?langId=1&acYear=2022&sid=4&bid=17&termId=1&examId=4&regNo=9051&branchMedium=2
export const printProgressCardExamWiseStudentAPIURL = BaseURL + "academic/reports/generateProgressCardByExamWiseAndRegNo";

//localhost:8080/institute/sss/academic/reports/generateFinalProgressCardJrCollege?langId=2&classId=16&divId=70&acYear=2022&sid=4&bid=17&branchMedium=2&date=12/04/2022
export const printFinalProgressCardJrCollegeClassDivWise = BaseURL + "academic/reports/generateFinalProgressCardJrCollege";

//localhost:8080/institute/sss/academic/reports/generateFinalProgressCardJrCollegeByRegNo?langId=2&acYear=2022&sid=4&bid=17&regNo=9099&branchMedium=2&date=12/04/2022
export const printFinalProgressCardJrCollegeStudentWise = BaseURL + "academic/reports/generateFinalProgressCardJrCollegeByRegNo";

//http://localhost:8081/institute-sss/institute/sss/academics/progressCard/getProgressCardOptions?sid=3&bid=1
export const getProgressCardOptionsAPIURL = BaseURL + "academics/progressCard/getProgressCardOptions";



//Internal seat no Report
//localhost:8080/institute/sss/academic/reports/getAllClassAndDivisionWiseInternalSeatNoAllocationReport?sessionYear=2022&sid=3&bid=1&langId=1
export const printAllStudSeatNoReport = BaseURL + "academic/reports/getAllClassAndDivisionWiseInternalSeatNoAllocationReport";

//localhost:8080/institute/sss/academic/reports/getClassAndDivisionWiseInternalSeatNoAllocationReport?classId=5&divId=17&sessionYear=2022&sid=3&bid=1&langId=1
export const printClassAndDivisionWiseStudSeatNoReport = BaseURL + "academic/reports/getClassAndDivisionWiseInternalSeatNoAllocationReport";

//localhost:8080/institute/sss/academic/reports/getSubjectAndClassAndDivisionWiseInternalSeatNoAllocationReport?classId=5&divId=17&sessionYear=2022&sid=3&bid=1&langId=1&subId=1
export const printClassAndDivisionAndSubWiseStudSeatNoReport = BaseURL + "academic/reports/getSubjectAndClassAndDivisionWiseInternalSeatNoAllocationReport";



//Result Sheet
//localhost:8080/institute/sss/academic/reports/generateTermAndExamWiseMarksSheetReportAllSubject?langId=2&classId=16&divId=70&acYear=2022&sid=4&bid=17&termId=1&printBlank=12&examId=4&branchMedium=2
export const printTermAndExamWiseResultSheetReport = BaseURL + "academic/reports/generateTermAndExamWiseMarksSheetReportAllSubject";

//localhost:8080/institute/sss/academic/reports/getTermWiseExamList?classId=16&divId=70&acYear=2022&sid=4&termId=1&branchMedium=2
export const getExamListForResultSheet = BaseURL + "academic/reports/getTermWiseExamList";

//localhost:8080/institute/sss/academics/resultData/getLastProcessResultUpdatedDate?classId=10&divId=37&sid=3&bid=36&termId=1&sessionYear=2022
export const getLastProcessResultUpdatedDate = BaseURL + "academics/resultData/getLastProcessResultUpdatedDate"

//localhost:8080/institute/sss/academics/resultData/processResultDataByClassAndDivision?classId=10&divId=37&sid=3&bid=36&termId=1&branchMedium=1&sessionYear=2022
export const processResultSheetAPIURL = BaseURL + "academics/resultData/processResultDataByClassAndDivision"



//Board Seat No Report APIURL
// /localhost:8080/institute/sss/academic/reports/getClassAndDivisionWiseBoardSeatNoAllocationReport?classId=5&divId=17&sessionYear=2022&sid=3&bid=1&langId=1
export const printClassDivWiseStudBoardSeatNoreport = BaseURL + "academic/reports/getClassAndDivisionWiseBoardSeatNoAllocationReport";

//localhost:8080/institute/sss/academic/reports/getAllClassAndDivisionWiseBoardSeatNoAllocationReport?sessionYear=2022&sid=3&bid=1&langId=1
export const printAllStudBoardSeatNoreport = BaseURL + "academic/reports/getAllClassAndDivisionWiseBoardSeatNoAllocationReport";



//Board Internal Assessment Report APIURL
//localhost:8080/institute/sss/academic/reports/generateBoardSubjectAndExamWiseMarksSheet?langId=1&classId=9&divId=36&termId=1&acYear=2022&sid=3&bid=10&branchMedium=1&subId=3&printBlank=1&viewType=3&examId=4&subExamId=13,14,18&isBoardSeatNoWise=1
export const printBoardInternalAssessmentReport = BaseURL + "academic/reports/generateBoardSubjectAndExamWiseMarksSheet";



//Prapatra B Report APIURL
//localhost:8080/institute/sss/academic/reports/generatePrapatraBReport?langId=1&optionId=1&subId=1&viewType=1&classId=5&divId=17&termId=1&year=2022&sid=3&bid=15&medium=1
export const printPrapatraBAPIURL = BaseURL + "academic/reports/generatePrapatraBReport";



//Exam Mark Status APIURL
//localhost:8080/institute/sss/academics/exam/getDepartmentListByBranchIdForExamStatus?bid=17
export const getDepartmentListByBranchIdAPIURL = BaseURL + "academics/exam/getDepartmentListByBranchIdForExamStatus";

// /localhost:8080/institute/sss/academics/exam/saveExamMarkStatus?option=1
// [
//     {
//         "status": 1,
//         "sessionYear": 2022,
//         "sectionId": 3,
//         "branchId": 1
//     }
// ]
export const saveExamMarkStatusDeptWiseAPIURL = BaseURL + "academics/exam/saveExamMarkStatus";


//localhost:8080/institute/sss/academics/exam/getAllSchoolExamMarkStatus?year=2022
export const getAllSchoolExamMarkStatusAPIURL = BaseURL + "academics/exam/getAllSchoolExamMarkStatus";


//Board Exam Mark Entry Report
//http://192.168.29.102:8081/institute-sss/institute/sss/academic/reports/generateBoardExamMarkEntryReport?langId=1&divId=37,38,39,40&option=1&year=2023&sid=3&bid=1
export const getBoardExamMarkEntryListAPIURL = BaseURL + "academic/reports/generateBoardExamMarkEntryReport";

//Board Exam Mark summary
//generateBoardExamMarkEntrySummaryReport
export const getBoardExamMarkEntrySummaryReportAPIURL = BaseURL + "academic/reports/generateBoardExamMarkEntrySummaryReport";