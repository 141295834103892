import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { width } from '@mui/system';
import { useSelector } from "react-redux";
import moment from 'moment';

const EditableTimeTableRow = ({ editFormData, handleEditFormChange, handleCancelClick, state, index, isRecess, recessLabel }) => {
    const authUser = useSelector((state) => state.user.value);
    return (
        <>
            <tr style={{ backgroundColor: (isRecess == 1) ? '#D6EAF8' : "#F4ECF7", height: "50px" }}>
                <td style={{ height: "50px", width: "80px", fontSize: "14px", fontWeight: "500" }}>{index + 1}</td>

                {/* <td style={{ width: "180px", height: "50px" }}>
                    <input type="text" className='form-control form-control-sm' style={{ width: "120px" }} name='accountName' value={editFormData.fromTime + " == " + editFormData.toTime} onChange={handleEditFormChange}/>
                </td> */}

                <td style={{ height: "50px", textAlign: 'center', fontSize: "14px", fontWeight: "500" }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>{moment(editFormData.fromTime, 'HH:mm:ss').format('HH:mm')}</span>
                        <span style={{ margin: '0 5px' }}>==</span>
                        <input
                            type="text"
                            className='form-control form-control-sm'
                            style={{ width: "120px", fontSize: "14px" }}
                            name='toTime'
                            autoComplete='off'
                            value={editFormData.toTime}
                            onChange={handleEditFormChange}
                        />
                        {isRecess === 1 && <span style={{ fontWeight: "bold" }}>{recessLabel}</span>}
                    </div>
                </td>


                <td style={{ padding: "0px", width: "80px" }}>
                    <Tooltip title="Save">
                        <button type='submit' onClick={() => (state.button = 3)} className='btn btn-sm mx-1 p-1' data-bs-toggle="tooltip" style={{ backgroundColor: "#27AE60", color: "white" }}>
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </Tooltip>

                    <Tooltip title="Cancle">
                        <span onClick={handleCancelClick} className='btn btn-sm ml-1 p-1' data-bs-toggle="tooltip" style={{ backgroundColor: "#CC0505", color: "white" }}>
                            <FontAwesomeIcon icon={faWindowClose} onClick={handleCancelClick} />
                        </span>
                    </Tooltip>
                </td>

            </tr>
        </>
    )
}


export default EditableTimeTableRow
