import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { getClassWithDiv } from '../../Administration/Services/GeneralRegisterAPIURL';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import { admissionMeritListClassDivWiseReport, allAdmissionMeritListReport, printClassDivWiseAdmMeritListExcelReport, printAdmMeritListExcelReport } from '../Services/AdmisionReportAPIURL';
import { BaseURLMasterCastes } from '../../CommonServices/CasteMasterAPIURL';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { BaseURLMasterCasteCategory } from '../../CommonServices/CasteCategoryMasterAPIURL';

const AdmissionMeritList = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Admission Merit List";

    const [loaderOption, setLoaderOption] = useState(false);


    const [view, setView] = useState(1);
    const [option, setOption] = useState(1);
    const [status, setStatus] = useState(3);
    const [meritListType, setMeritListType] = useState(1);
    const [data, setData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [casteOption, setCasteOption] = useState(0);
    const [casteData, setCasteData] = useState([]);
    const [casteObj, setCasteObj] = useState(null);
    const [noOfStudents, setNoOfStudents] = useState(0);
    const [categoryData, setCategoryData] = useState([]);
    const [categoryObj, setCategoryObj] = useState(null);
    const [withPhNoChkBox, setWithPhNoChkBox] = useState(0);

    const HandleWithPhNoCheckbox = e => {
        setWithPhNoChkBox(e.target.checked);
        if (e.target.checked == true) {
            setWithPhNoChkBox(1);
        }
        else if (e.target.checked == false) {
            setWithPhNoChkBox(0);
        }
    }

    // casteWise casteId noOfStudent

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                })
        }

        await axios.get(BaseURLMasterCastes)
            .then(res => {
                setCasteData(res.data);
            }).catch(err => {
                console.log('get Caste err ' + err);
            })

        await axios.get(BaseURLMasterCasteCategory)
            .then(res => {
                setCategoryData(res.data);
            }).catch(err => {
                console.log("get category err " + err);
            })
    }

    const generateAdmMeritListClassDivWiseReport = async () => {
        setLoaderOption(true);
        let casteCategoryId = 0
        if (casteOption == 1) {
            casteCategoryId = casteObj.id
        }
        else if (casteOption == 2) {
            casteCategoryId = categoryObj.id
        }
        console.log(`${admissionMeritListClassDivWiseReport}?langId=${authUser.branchMedium}&divId=${classDivObj.divisionId}&meritType=${meritListType}&qualifyStatus=${status}&casteWise=${casteOption}&casteId=${casteCategoryId}&noOfStudent=${(noOfStudents == '') ? 0 : noOfStudents}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
        await axios.get(`${admissionMeritListClassDivWiseReport}?langId=${authUser.branchMedium}&divId=${classDivObj.divisionId}&meritType=${meritListType}&qualifyStatus=${status}&casteWise=${casteOption}&casteId=${casteCategoryId}&noOfStudent=${(noOfStudents == '') ? 0 : noOfStudents}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("class div wise adm pdf report : " + error)
            })
    }

    const generateAdmMeritListReport = async () => {
        setLoaderOption(true);
        let casteCategoryId = 0
        if (casteOption == 1) {
            casteCategoryId = casteObj.id
        }
        else if (casteOption == 2) {
            casteCategoryId = categoryObj.id
        }
        console.log(`${allAdmissionMeritListReport}?langId=${authUser.branchMedium}&meritType=${meritListType}&qualifyStatus=${status}&casteWise=${casteOption}&casteId=${casteCategoryId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
        await axios.get(`${allAdmissionMeritListReport}?langId=${authUser.branchMedium}&meritType=${meritListType}&qualifyStatus=${status}&casteWise=${casteOption}&casteId=${casteCategoryId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("adm pdf report : " + error)
            })
    }

    const generateAdmMeritListClassDivWiseExcelReport = async () => {
        setLoaderOption(true);
        let casteCategoryId = 0
        if (casteOption == 1) {
            casteCategoryId = casteObj.id
        }
        else if (casteOption == 2) {
            casteCategoryId = categoryObj.id
        }
        console.log(`${printClassDivWiseAdmMeritListExcelReport}?langId=${authUser.branchMedium}&divId=${classDivObj.divisionId}&meritType=${meritListType}&qualifyStatus=${status}&casteWise=${casteOption}&casteId=${casteCategoryId}&noOfStudent=${(noOfStudents == '') ? 0 : noOfStudents}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
        await axios.get(`${printClassDivWiseAdmMeritListExcelReport}?langId=${authUser.branchMedium}&divId=${classDivObj.divisionId}&meritType=${meritListType}&qualifyStatus=${status}&casteWise=${casteOption}&casteId=${casteCategoryId}&noOfStudent=${(noOfStudents == '') ? 0 : noOfStudents}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("class div wise adm excel report : " + error)
            })
    }

    const generateAdmMeritListExcelReport = async () => {
        setLoaderOption(true);
        let casteCategoryId = 0
        if (casteOption == 1) {
            casteCategoryId = casteObj.id
        }
        else if (casteOption == 2) {
            casteCategoryId = categoryObj.id
        }
        console.log(`${printAdmMeritListExcelReport}?langId=${authUser.branchMedium}&meritType=${meritListType}&qualifyStatus=${status}&casteWise=${casteOption}&casteId=${casteCategoryId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
        await axios.get(`${printAdmMeritListExcelReport}?langId=${authUser.branchMedium}&meritType=${meritListType}&qualifyStatus=${status}&casteWise=${casteOption}&casteId=${casteCategoryId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("adm excel report : " + error)
            })
    }

    const getAdmissionMeritListReport = e => {
        e.preventDefault();
        if (view == 1) {
            if (option == 1) {
                generateAdmMeritListReport();
            }
            else if (option == 2) {
                generateAdmMeritListClassDivWiseReport();
            }
        }
        else if (view == 2) {
            if (option == 1) {
                generateAdmMeritListExcelReport();
            }
            else if (option == 2) {
                generateAdmMeritListClassDivWiseExcelReport();
            }
        }
    }


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                    <form onSubmit={getAdmissionMeritListReport}>
                        <div className='row'>
                            <label className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>View</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={view}
                                        label="View"
                                        onChange={(e) => {
                                            setView(e.target.value);
                                            setOption(1);
                                            setStatus(3);
                                            setMeritListType(1);
                                            setCasteOption(0);
                                            setCasteObj(null);
                                            setNoOfStudents(0);
                                            setClassDivObj(null);
                                            setCategoryObj(null);
                                            setWithPhNoChkBox(0);
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>PDF</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Excel</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Merit List Type :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Merit List Type</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={meritListType}
                                        label="Merit List Type"
                                        onChange={(e) => {
                                            setMeritListType(e.target.value);
                                            setClassDivObj(null);
                                            setOption(1);
                                            setStatus(3);
                                            setCasteOption(0);
                                            setCasteObj(null);
                                            setNoOfStudents(0);
                                            setCategoryObj(null);
                                            setWithPhNoChkBox(0);
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Entrance</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Final</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={3}>SSC</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row my-2'>
                            {(() => {
                                if (meritListType == 1) {
                                    return (
                                        <>
                                            <label className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Status :</label>
                                            <div className='col-sm-3'>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Status</InputLabel>
                                                    <Select
                                                        size='small'
                                                        required={(meritListType == 1) ? true : false}
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={status}
                                                        label="Status"
                                                        onChange={(e) => {
                                                            setStatus(e.target.value);
                                                            setClassDivObj(null);
                                                            setOption(1);
                                                            setCasteOption(0);
                                                            setCasteObj(null);
                                                            setCategoryObj(null);
                                                            setNoOfStudents(0);
                                                            setWithPhNoChkBox(0);
                                                        }}
                                                    >
                                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Qualified</MenuItem>
                                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Disqualified</MenuItem>
                                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={3}>All</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </>
                                    )
                                }
                            })()}

                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={option}
                                        label="Option"
                                        onChange={(e) => {
                                            setOption(e.target.value);
                                            setClassDivObj(null);
                                            setCategoryObj(null);
                                            setCasteObj(null);
                                            setNoOfStudents(0);
                                            setCasteOption(0);
                                            setWithPhNoChkBox(0);
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>All Students</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Class-Division Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row my-2' hidden={(option == 1) ? true : false}>
                            <label className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={data}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                    size="small"
                                    value={classDivObj}
                                    onChange={(event, newValue) => {
                                        setClassDivObj(newValue);
                                        setWithPhNoChkBox(0);
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                            required={(option == 2) ? true : false} />
                                    )}
                                />
                            </div>

                            <label className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Sub-Option</InputLabel>
                                    <Select
                                        size='small'
                                        required={(option == 2) ? true : false}
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={casteOption}
                                        label="Sub-Option"
                                        onChange={(e) => {
                                            setCasteOption(e.target.value);
                                            setCasteObj(null);
                                            setCategoryObj(null);
                                            setNoOfStudents(0);
                                            setWithPhNoChkBox(0);
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={0}>All</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Caste Wise</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Category Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="row my-2" hidden={(option == 2) ? true : false}>
                            <label className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Sub-Option</InputLabel>
                                    <Select
                                        size='small'
                                        required={(option == 1) ? true : false}
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={casteOption}
                                        label="Sub-Option"
                                        onChange={(e) => {
                                            setCasteOption(e.target.value);
                                            setCasteObj(null);
                                            setCategoryObj(null);
                                            setNoOfStudents(0);
                                            setWithPhNoChkBox(0);
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={0}>All</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Caste Wise</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Category Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            {
                                (() => {
                                    if (option == 1) {
                                        if (casteOption == 1) {
                                            return (
                                                <>
                                                    <label className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Caste :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            options={casteData}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            renderOption={(props, option) => (
                                                                <Box component="li" {...props} key={option.id}>
                                                                    {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                </Box>
                                                            )}
                                                            size="small"
                                                            value={casteObj}
                                                            onChange={(event, newValue) => {
                                                                setCasteObj(newValue);
                                                                setNoOfStudents(0);
                                                                setWithPhNoChkBox(0);
                                                            }}
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Caste" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(casteOption == 1 && option == 1) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (casteOption == 2) {
                                            return (
                                                <>
                                                    <label className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Category :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            options={categoryData}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            renderOption={(props, option) => (
                                                                <Box component="li" {...props} key={option.id}>
                                                                    {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                </Box>
                                                            )}
                                                            size="small"
                                                            value={categoryObj}
                                                            onChange={(event, newValue) => {
                                                                setCategoryObj(newValue);
                                                                setNoOfStudents(0);
                                                                setWithPhNoChkBox(0);
                                                            }}
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Category" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(casteOption == 2 && option == 1) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }
                                    }
                                })()
                            }
                        </div>

                        {(() => {
                            if (casteOption == 1) {
                                return (
                                    <>
                                        <div className="row my-2">
                                            <label className="" hidden={(option == 1) ? true : false} style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Caste :</label>
                                            <div className='col-sm-3' hidden={(option == 1) ? true : false}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    options={casteData}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" {...props} key={option.id}>
                                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        </Box>
                                                    )}
                                                    size="small"
                                                    value={casteObj}
                                                    onChange={(event, newValue) => {
                                                        setCasteObj(newValue);
                                                        setNoOfStudents(0);
                                                        setWithPhNoChkBox(0);
                                                    }}
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Caste" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required={(casteOption == 1 && option == 2) ? true : false} />
                                                    )}
                                                />
                                            </div>

                                            <label hidden={(option == 1) ? true : false} className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>No.Of Students :</label>
                                            <div className="col-sm-3" hidden={(option == 1) ? true : false}>
                                                <TextField
                                                    // required={(casteOption == 1) ? true : false}
                                                    fullWidth
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    autoComplete='off'
                                                    size='small'
                                                    id="outlined-basic"
                                                    label="No.Of Students"
                                                    margin='dense'
                                                    variant="outlined"
                                                    // InputProps={{ inputProps: { min: 1 } }}                                                    
                                                    value={noOfStudents}
                                                    onChange={e => setNoOfStudents(e.target.value)}
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            else if (casteOption == 2) {
                                return (
                                    <>
                                        <div className="row my-2">
                                            <label className="" hidden={(option == 1) ? true : false} style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Category :</label>
                                            <div className='col-sm-3' hidden={(option == 1) ? true : false}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    options={categoryData}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" {...props} key={option.id}>
                                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        </Box>
                                                    )}
                                                    size="small"
                                                    value={categoryObj}
                                                    onChange={(event, newValue) => {
                                                        setCategoryObj(newValue);
                                                        setNoOfStudents(0);
                                                        setWithPhNoChkBox(0);
                                                    }}
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Category" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required={(casteOption == 2 && option == 2) ? true : false} />
                                                    )}
                                                />
                                            </div>

                                            <label hidden={(option == 1) ? true : false} className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>No.Of Students :</label>
                                            <div className="col-sm-3" hidden={(option == 1) ? true : false}>
                                                <TextField
                                                    // required={(casteOption == 2) ? true : false}
                                                    fullWidth
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    autoComplete='off'
                                                    size='small'
                                                    id="outlined-basic"
                                                    label="No.Of Students"
                                                    margin='dense'
                                                    variant="outlined"
                                                    value={noOfStudents}
                                                    onChange={e => setNoOfStudents(e.target.value)}
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}

                        <div className="row mt-2" hidden={(meritListType == 2 || meritListType==3) ? true : false}>
                            <div className='col-sm-4' style={{ marginLeft: '150px' }}>
                                <FormControlLabel control={<Checkbox checked={withPhNoChkBox} onChange={HandleWithPhNoCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="With Phone No." />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-4" style={{ marginLeft: '150px' }}>
                                <button type="submit" className="btn btn-primary btn-sm">Show</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }

}

export default AdmissionMeritList