import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";


export const getStudentSaralList=BaseURL+"administration/getStudentSaralAllocationData";


export const saveStudentSaralList=BaseURL+"administration/saveStudentSaral";

    // /institute/sss/administration/

    // GetMapping  /getStudentSaralAllocationData

    // @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cdid") long classDivId

    // --------------------------------------------------

    // PostMapping  /saveStudentSaral

    // private long id;
    //     private long regCode;
    //     private String  studentNameMr;
    //     private String  studentNameEn;
    //     private  long rollNo;
    //     private String saralNo;
    //     private long sessionYear;
    //     private long sectionId;
    //     private long branchId;
    //     private long classId;
    //     private long divisionId;
