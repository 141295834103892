import { BaseIP, BaseURL, BaseURLMaster, dBaseURL, dBaseURLMaster } from "../../CommonServices/APIURL";

export const studentBaseUrlStudEntryMaster = BaseURL + "administration/studentEntry/";
//export const getStudentList=studentBaseUrlStudEntryMaster+"studentEntryList/";

export const BaseURLLc = BaseURL + "administration/LC/"
export const getStudentList = BaseURLLc + "getStudentLcBaseData/";
export const getStudentListForEdit = BaseURLLc + "getStudentLcBaseDataEdit/";
export const getCurrentStudentList = BaseURLLc + "getCurrentStudentData/";
// getFeeList  ( not contain donation fee names)
export const getFeeData = BaseURLMaster + "getFeeList/";

//getBankList
export const getBankData = BaseURLMaster + "bankNames";

// GetMapping  /getTransactionNo
//@RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId,@RequestParam("studid") long studentId, @RequestParam("feesn") String feeShortName, @RequestParam("cdnm") String classDivName
export const getTransactionNo = BaseURL + "fee/getTransactionNo";

// /getFeeDetails
// (@RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("studid") long studentId, @RequestParam("fid") long feeId
export const getFeeDetails = BaseURL + "fee/getFeeDetails";
export const saveFeeCollectionData = BaseURL + "fee/saveFeeCollectionData";
//export const saveFeeCollectionData = "http://192.168.1.102:8080/institute/sss/fee/saveFeeCollectionData";

//localhost:8080/institute/sss/fee/deleteFeeCollectionEntry?id=95
export const deleteFeeReceipt = BaseURL + "fee/deleteFeeCollectionEntry";

//localhost:8080/institute/sss/fee/getFeeCollectionDetails?acyr=2021&sid=3&bid=1
export const getFeeCollectionDetails = BaseURL + "fee/getFeeCollectionDetails";
//export const getFeeCollectionDetails = "http://192.168.1.102:8080/institute/sss/fee/getFeeCollectionDetails";

//localhost:8080/institute/sss/fee/getStudentDataFeeCollection?id=1
export const getStudentDataFeeCollectionByRegNo = BaseURL + "fee/getStudentDataFeeCollection/";
//export const getStudentDataFeeCollectionByRegNo = "http://192.168.1.102:8080/institute/sss/fee/getStudentDataFeeCollection/";

//localhost:8080/institute/sss/fee/updateStudentDataFeeCollection/1
export const updateStudentDataFeeCollection = BaseURL + "fee/updateStudentDataFeeCollection/";
//export const updateStudentDataFeeCollection = "http://192.168.1.102:8080/institute/sss/fee/updateStudentDataFeeCollection/";

//localhost:8080/institute/sss/admission/reports/generateFeeReceiptReport?langId=1&id=1&acYear=2021&sid=3&bid=1
export const printFeeReceipt = BaseURL + "admission/reports/generateFeeReceiptReport";


//Multiple fee receipt API
//institute/sss/multiFee/getMultiFeeTransactionNo
// parameter name aadhi hote tasach 
// acyr
// sid
// bid
// studid
// cdnm
export const getTranscNoForMultiFeeReceiptAPIURL = BaseURL + "multiFee/getMultiFeeTransactionNo";

//http://192.168.29.102:8081/institute-sss/institute/sss/multiFee/getMultiFeeDetails?feeType=2&regNo=11884&classId=10&year=2023&sid=3&bid=1
export const getMultiFeeDetailsAPIURL = BaseURL + "multiFee/getMultiFeeDetails";

//http://192.168.29.102:8081/institute-sss/institute/sss/multiFee/saveMultiFeeCollectionData
export const saveMultiFeeReceiptAPIURL = BaseURL + "multiFee/saveMultiFeeCollectionData";

//http://192.168.29.102:8081/institute-sss/institute/sss/multiFee/getMultiFeeCollectionList?year=2023&sid=3&bid=1
export const getMultiFeeCollectionListAPIURL = BaseURL + "multiFee/getMultiFeeCollectionList";

//http://192.168.29.102:8081/institute-sss/institute/sss/multiFee/getMultiFeeCollectionEntry?id=1
export const getMultiFeeReceiptDataByIdAPIURL = BaseURL + "multiFee/getMultiFeeCollectionEntry";

//http://192.168.29.102:8081/institute-sss/institute/sss/multiFee/deleteMultiFeeCollectionEntry?id=1
export const deleteMultiFeeCollectionAPIURL = BaseURL + "multiFee/deleteMultiFeeCollectionEntry";

//http://192.168.29.102:8081/institute-sss/institute/sss/multiFee/updateMultiFeeCollectionData
export const updateMultiFeeReceiptAPIURL = BaseURL + "multiFee/updateMultiFeeCollectionData";

//http://192.168.29.102:8081/institute-sss/institute/sss/admission/reports/generateMultiFeeReceiptReport?langId=2&feeCollId=3
export const printMuktipleFeeReceiptAPIURL = BaseURL + "admission/reports/generateMultiFeeReceiptReport";