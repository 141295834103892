import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { printMudranCertificate } from '../Services/CertificateAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import Loader from '../../CommonComponent/Loader';
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { BaseURLAccountMaster } from '../../CommonServices/AccountMasterAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const MudranCertificate = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();
    const titleId = "Mudran";

    const [loaderOption, setLoaderOption] = useState(false);

    const [option, setOption] = useState(1);
    const [accountData, setAccountData] = useState([]);
    const [accCodeObj, setAccCodeObj] = useState(null);

    useEffect(() => {
        getAccountData();

    }, [authUser]);


    const getAccountData = async () => {
        //console.log(BaseURLAccountMaster + authUser.deptId);
        if (authUser.deptId != null && authUser.sessionYear != '' && authUser.branchId != null) {
            await axios(`${BaseURLAccountMaster}?sid=${authUser.deptId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then((response) => {
                    setAccountData((response.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1));
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const mudranCertificatePrint = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        await axios.get(`${printMudranCertificate}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&option=${option}&accCode=${(option == 2) ? accCodeObj.accountCode : 0}`)
            .then((response) => {
                //setLoaderOption(false);
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found, please check.")
                }
                else if (response.data != null) {
                    //console.log(response.data)
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Taslamat Certificate failed: " + error)
            })
        //toast.success("Valid")       
    }


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={mudranCertificatePrint}>
                        {(() => {
                            if (authUser.branchId == 50) {
                                return (
                                    <>
                                        <div className="row">
                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                            <div className='col-sm-3'>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                                    <Select
                                                        MenuProps={{ classes: { paper: classes.select } }}
                                                        size='small'
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={option}
                                                        label="Option"
                                                        onChange={(e) => {
                                                            setOption(e.target.value);
                                                        }}
                                                    >

                                                        <MenuItem value={1}>All</MenuItem>
                                                        <MenuItem value={2}>Account Wise</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        {(() => {
                                            if (option == 2) {
                                                return (
                                                    <>
                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Account :</label>
                                                            <div className='col-sm-3'>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                    )}
                                                                    options={accountData}
                                                                    value={accCodeObj}
                                                                    onChange={(event, newvalue) => {
                                                                        setAccCodeObj(newvalue);
                                                                    }}
                                                                    style={{ fontSize: '14px' }}
                                                                    //getOptionLabel={option => (authUser.branchMedium == 1) ? option.accountCode + " - " + option.accountNameMr : option.accountCode + " - " + option.accountNameEn}
                                                                    getOptionLabel={option => option.accountCode + " - " + option.accountNameMr}
                                                                    size="small"
                                                                    renderInput={params => (
                                                                        <TextField {...params} margin='dense' label="Select Account" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 2) ? true : false} />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </>
                                )
                            }

                        })()}

                        <div className={(authUser.branchId == 50) ? 'row mt-3' : 'row'}>
                            <div className={(authUser.branchId == 50) ? 'col-sm-4 offset-sm-1' : "col-sm-4"}>
                                <button
                                    type='submit'
                                    className='btn btn-primary btn-sm mb-2'
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>
                                {/* <button type="submit" className="btn btn-danger btn-md" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/")}>Exit</button> */}
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }

}

export default MudranCertificate