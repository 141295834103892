import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";

const ReadOnlyRow = ({ bank, handleEditClick }) => {
    const authUser = useSelector((state) => state.user.value);
    return (
        <>
            <tr style={{ height: "65px" }}>
                <td style={{ width: "80px" }}>{bank.regCode}</td>
                <td style={{ lineHeight: "1.3" }}>{(authUser.branchMedium == 1) ? bank.studentNameMr : bank.studentNameEn}</td>
                <td style={{ width: "80px" }}>{bank.rollNo}</td>
                <td style={{ width: "180px" }}>{bank.accountName}</td>
                <td style={{ width: "180px" }}>{bank.bankAccountNo}</td>
                <td style={{ width: "180px" }}>{bank.bankName}</td>
                <td style={{ width: "180px" }}>{bank.branchName}</td>
                <td style={{ width: "150px" }}>{bank.bankIFSCCode}</td>
                <td style={{ padding: "0px", width: "100px" }}>
                    <Tooltip title="Edit">
                        <span className='btn btn-primary btn-sm mx-1 p-1' data-bs-toggle="tooltip" onClick={(event) => handleEditClick(event, bank)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </span>
                    </Tooltip>
                </td>
            </tr>
        </>
    )
}

export default ReadOnlyRow