import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../../CommonServices/APIURL";

export const getPrizeCodeAPIURL = BaseURL + "endowment/getMaxPrizeCodeForEndowment";

//localhost:8080/institute/sss/endowment/saveAllEndowmentData
export const saveEndowmentMasterAPIURL = BaseURL + "endowment/saveAllEndowmentData";

//localhost:8080/institute/sss/endowment/getEndowmentDataList
export const getEndowmentmasterDataAPIURL = BaseURL + "endowment/getEndowmentDataList";

//localhost:8080/institute/sss/endowment/getEndowmentsDataById/1
export const getEndowmentDataForUpdateAPIURL = BaseURL + "endowment/getEndowmentsDataById/";

//localhost:8080/institute/sss/endowment/updateEndowmentDataById
export const updateEndowmentmasterAPIURL = BaseURL + "endowment/updateEndowmentDataById";

//localhost:8080/institute/sss/endowment/deleteEndowmentDataById?id=1
export const deleteEndowmentMasterAPIURL = BaseURL + "endowment/deleteEndowmentDataById"

//localhost:8080/institute/sss/endowment/getSchoolNameByEndomentId?endowmentId=1
export const getSchoolNameByEndowmentId = BaseURL + "endowment/getSchoolNameByEndomentId";

//localhost:8080/institute/sss/endowment/savePrizeDistributionData
export const savePrizeDistributionData = BaseURL + "endowment/savePrizeDistributionData";

//deletePrizeDistributionDataByPrizeCodeAndEtranNo
// prizeCode
// etranNo
export const deletePrizeDistributionData = BaseURL + "endowment/deletePrizeDistributionDataByPrizeCodeAndEtranNo";

// {
//     "prizeCode": 1,
//     "branchId": 1,
//     "amountInPercent": 100,
//     "prizeFrom": 1,
//     "prizeDescription": "prizeDescription sanstha",
//     "classId": 3,
//     "subjectId": 1,
//     "genderId":1
// }

//localhost:8080/institute/sss/endowment/getAllPrizeDistributionDataByPrizeCode?prizeCode=5
export const getPrizeDistributionCondition = BaseURL + "endowment/getAllPrizeDistributionDataByPrizeCode";

//localhost:8080/institute/sss/endowment/saveEndowmentMasterDetail
export const saveEndowmentAmountDetails = BaseURL + "endowment/saveEndowmentMasterDetail";

//localhost:8080/institute/sss/endowment/updateEndowmentMasterDetail
export const updateEndowmentAmountDetails = BaseURL + "endowment/updateEndowmentMasterDetail";

//localhost:8080/institute/sss/endowment/deleteEndowmentMasterDetail?id=1
export const deleteEndowmentAmountDetails = BaseURL + "endowment/deleteEndowmentMasterDetail";

//localhost:8080/institute/sss/endowment/getEndowmentMasterDetail?prizeCode=7
export const getEndowmentAmountDetails = BaseURL + "endowment/getEndowmentMasterDetail";

//localhost:8080/institute/sss/endowment/getEndowmentDashboardData
export const EndowDashboardData = BaseURL + "endowment/getEndowmentDashboardData";

//http://192.168.1.102:8080/institute/sss/endowment/getSchoolNameList
export const getSchoolListForEndowment = BaseURL + "endowment/getSchoolNameList";
