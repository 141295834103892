import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

//http://192.168.1.102:8080/institute/sss/
export const dBaseURL = "http://192.168.1.102:8080/institute/sss/";

//localhost:8080/institute/sss/academics/StudentWiseSubjectAllocation/getStudentWiseSubjectAllocatedData?sid=3&bid=3&acYear=2022&classId=10&divId=37&subId=1
export const getStudentListForStudWiseSub = BaseURL + "academics/StudentWiseSubjectAllocation/getStudentWiseSubjectAllocatedData";

//localhost:8080/institute/sss/academics/StudentWiseSubjectAllocation/saveStudentWiseSubjectAllocatedData
export const saveStudentListForStudWiseSub = BaseURL + "academics/StudentWiseSubjectAllocation/saveStudentWiseSubjectAllocatedData";


//Report APIURL
//localhost:8080/institute/sss/academic/reports/generateStudentWiseSubjectReportBySubIdAndClassId?classId=6&divId=21&acYear=2022&sid=3&bid=1&langId=1&subId=3
export const classDivAndSubjectWiseStudWiseSubAPIURL = BaseURL + "academic/reports/generateStudentWiseSubjectReportBySubIdAndClassId";

//localhost:8080/institute/sss/academic/reports/generateStudentWiseSubjectReportBySubIdAndAllClass?acYear=2022&sid=3&bid=1&langId=1&subId=3
export const subjectWiseStudentWiseSubjectAPIURL = BaseURL + "academic/reports/generateStudentWiseSubjectReportBySubIdAndAllClass";

//localhost:8080/institute/sss/academic/reports/generateStudentWiseSubjectReportByClassId?classId=6&divId=21&acYear=2022&sid=3&bid=1&langId=1
export const classWiseCountStudentWiseSubjectAPIURL = BaseURL + "academic/reports/generateStudentWiseSubjectReportByClassId";