import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

const ExamMarkAllocationTableRow = ({ initialText1, initialText2, onChange, setMaxMarkCount, setMinMarkCount, minMarkCount, maxMarkCount }) => {

    const [isEdit, setEdit] = useState(false);

    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    
   const txtBox={    
    borderRadius:"5px",    
    padding:"4px",
    fontSize:"15px",
    fontWeight:"600",  
   }
    
        return (
            <>
                <td style={{ width: "100px"}} className='p-2'>
                    <input
                        type='number'                        
                        style={txtBox}
                        className="form-control form-control-sm"
                        onBlur={() => { onChange(text1, text2)}}
                        onChange={(e) =>{ setText1(e.target.value)}}
                        value={text1}
                        onWheel={(e) => e.target.blur()} 
                    />
                </td>
                <td style={{ width: "100px" }} className='p-2'>
                    <input
                        type='number'
                        style={txtBox}
                        className="form-control form-control-sm"
                        onBlur={() => { onChange(text1, text2) }}
                        onChange={(e) => setText2(e.target.value)}
                        value={text2}
                        onWheel={(e) => e.target.blur()} 
                    />
                </td>                
            </>
        )
    
}

export default ExamMarkAllocationTableRow