import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import { getClassDivisionURL, getStudentsForPramot, saveStudentsForPromotion } from '../Services/PramotShiftStudentsAPIURL';
import ConfirmBoxStudentPromotion from './ConfirmBoxStudentPromotion';
import Loader from '../../CommonComponent/Loader';
import moment from 'moment';

const PramotShiftStudents = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Pramot / Shift Students";

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [fromClassDivObj, setFromClassDivObj] = useState(null);
    const [toClassDivObj, setToClassDivObj] = useState(null);
    const [toClassId, setToClassId] = useState();
    const [toDivId, setToDivId] = useState();
    const [toClassName, setToClassName] = useState("");
    const [fromClassName, setFromClassName] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [checkAllSub, setCheckAllSub] = useState(false);

    const fromYear = authUser.sessionYear;
    const toYear = authUser.sessionYear + 1;

    const currentYear = new Date();
    const futureYear = moment(currentYear).add(1, 'year').format("YYYY")

    //to check count of checked checkboxes
    const checkedData = studentData.filter(e => e.isPromoted === 1);
    const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(checkedData.length);

    const [checkValue, setCheckValue] = useState([]);
    //console.log(JSON.stringify(checkValue))

    const [tempData, setTempData] = useState([]);
    const [regNumFalse, setRegNumFalse] = useState('');
    const [regNumTrue, setRegNumTrue] = useState('');
    const [Val, setVal] = useState(false);

    //console.log("Val======" + Val + "searchterm===" + searchTerm + "regNumFalse=====" + regNumFalse + "regNumTrue=====" + regNumTrue + "checkAllSub=========" + checkAllSub)
    //console.log("Val======" + Val + "checkAllSub=========" + checkAllSub)

    const handleCheckChange = (val, Id, regNo, studNameMr, studNameEn, rNo, cId, dId, toCid, toDid, sYear, bId, sId) => {
        const checkedChkBoxCount = document.querySelector(".studentChkBox");
        setTotalSelectedCheckboxes(checkedChkBoxCount.querySelectorAll('input[type=checkbox]:checked').length);
        setVal(val);

        const SaveObjectData = {
            id: Id,
            regCode: regNo,
            studentNameMr: studNameMr,
            studentNameEn: studNameEn,
            rollNo: rNo,
            classId: cId,
            divisionId: dId,
            toClassId: toClassId,
            toDivisionId: toDivId,
            sessionYear: sYear,
            sectionId: sId,
            branchId: bId
        }

        if (val == true) {
            setCheckValue(checkValue.concat(SaveObjectData));

            setRegNumTrue(SaveObjectData.regCode);
            //console.log("checkValue=======" + JSON.stringify(SaveObjectData.divisionId));
            setRegNumFalse('');

            let checkdata = studentData.filter(e => e.isPromoted === 0)
            //console.log(checkdata.length);
            //console.log("Checkvalue=" + (checkValue.length + 1) + "------" + "filteredData=" + filteredData.length)
            if (checkdata.length === 1 || checkdata.length === 0) {
                setCheckAllSub(true);
            }

            //if checkbox is checked
            let updatedData = tempData;
            tempData.map((e, index) => {
                if (e.regCode == regNumTrue) {
                    updatedData[index] = ({
                        ...e, isPromoted: 1
                    })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setStudentData(updatedData);
                setFilteredData(updatedData)
            }
        }
        else if (val == false) {
            const removedData = checkValue.filter((item) => item.regCode != regNo)
            setCheckValue(removedData);
            setCheckAllSub(false);
            setRegNumFalse(SaveObjectData.regCode);
            setRegNumTrue('');

            //if checkbox is unChecked
            let updatedData = tempData;
            tempData.map((e, index) => {
                if (e.regCode == regNumFalse) {
                    updatedData[index] = ({
                        ...e, isPromoted: 0
                    })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setStudentData(updatedData);
                setFilteredData(updatedData);
            }

        }
    }


    useEffect(() => {
        //if checkbox is checked after search
        let updatedData2 = tempData;
        tempData.map((e, index) => {
            if (e.regCode == regNumTrue) {
                updatedData2[index] = ({
                    ...e, isPromoted: 1
                })
            }

        })

        //if checkbox is unChecked after search
        let updatedData = tempData;
        tempData.map((e, index) => {
            if (e.regCode == regNumFalse) {
                updatedData[index] = ({
                    ...e, isPromoted: 0
                })
            }

        })

        if (Val == false) {
            setRegNumTrue('');
            //setCheckAllSub(false);
            if (searchTerm == "") {
                setStudentData(updatedData);
                setFilteredData(updatedData);
            }
        }
        else if (Val == true) {
            setRegNumFalse('');
            if (searchTerm == "") {
                setStudentData(updatedData2);
                setFilteredData(updatedData2);
            }
        }

        //updated Data after search and check or uncheck checkbox if all checkboxes checked
        if (checkAllSub == false) {
            setRegNumTrue('');

            let updatedData3 = tempData;
            tempData.map((e, index) => {
                if (e.regCode == regNumFalse) {
                    updatedData3[index] = ({
                        ...e,
                        isPromoted: 0
                    })
                }
            })
            //console.log("updatedData-----" + JSON.stringify(updatedData))
            if (searchTerm == "") {
                setRegNumTrue('');
                setStudentData(updatedData3);
                setFilteredData(updatedData3);
            }
        }
        else if (checkAllSub == true) {
            setRegNumFalse('');

            let updatedData4 = tempData;
            tempData.map((e, index) => {

                updatedData4[index] = ({
                    ...e,
                    isPromoted: 1
                })

            })
            //console.log("updatedData4=========" + JSON.stringify(updatedData4))
            if (searchTerm == "") {
                setStudentData(updatedData4);
                setFilteredData(updatedData4);
            }
        }

    }, [searchTerm, regNumFalse, regNumTrue, checkAllSub, Val])


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                })
        }
        if (toYear >= futureYear) {
            toast.warn("This facility is not available for this year.")
        }
    }

    const getStudentData = async () => {

        if (fromClassDivObj == null || fromClassDivObj == "" || toClassDivObj == null || toClassDivObj == "") {
            //console.log(`${getStudentsForPramot}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${fromClassDivObj.divisionId}`)
            toast.warn("Please select from or to class-division first.");
        }
        else {
            setLoaderOption(true);
            setStudentData([]);
            setFilteredData([]);
            setAscending(false);
            console.log(`${getStudentsForPramot}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${fromClassDivObj.divisionId}`)
            await axios(`${getStudentsForPramot}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${fromClassDivObj.divisionId}`)
                .then(res => {
                    setStudentData(res.data);
                    setFilteredData(res.data);
                    setTempData(res.data);
                    setLoaderOption(false);
                    setSortedBy(null);
                    setSearchTerm("");
                    let SaveObj = (res.data).filter(e => {
                        return e.isPromoted == 1
                    })
                    let updatedData = SaveObj;
                    SaveObj.map((e, index) => {
                        updatedData[index] = ({
                            id: e.id,
                            regCode: e.regCode,
                            studentNameMr: e.studentNameMr,
                            studentNameEn: e.studentNameEn,
                            rollNo: e.rollNo,
                            classId: e.classId,
                            divisionId: e.divisionId,
                            toClassId: e.toClassId,
                            toDivisionId: e.toDivisionId,
                            sessionYear: e.sessionYear,
                            sectionId: e.sectionId,
                            branchId: e.branchId
                        })
                    })
                    setCheckValue(updatedData);

                    //allchecked data
                    let allCheckedData = (res.data).filter(e => {
                        return e.isPromoted == 1
                    })
                    let updateData = allCheckedData;
                    allCheckedData.map((e, index) => {
                        updateData[index] = ({
                            id: e.id,
                            regCode: e.regCode,
                            studentNameMr: e.studentNameMr,
                            studentNameEn: e.studentNameEn,
                            rollNo: e.rollNo,
                            classId: e.classId,
                            divisionId: e.divisionId,
                            toClassId: e.toClassId,
                            toDivisionId: e.toDivisionId,
                            sessionYear: e.sessionYear,
                            sectionId: e.sectionId,
                            branchId: e.branchId,
                            isPromoted: 1
                        })
                    })
                    getChekedData(updateData);

                }).catch(error => {
                    console.log(error)
                })
        }
    }


    const checkAllSubjects = (e) => {

        if (toClassDivObj == null || toClassDivObj == "") {
            toast.warn("Please select class-division first.")
        }
        else {
            setCheckAllSub(e.target.checked);
            setCheckValue([]);
            if (e.target.checked == true) {
                setCheckValue([]);
                setCheckAllSub(true);
                axios(`${getStudentsForPramot}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${fromClassDivObj.divisionId}`)
                    .then(res => {
                        setStudentData([])
                        setFilteredData([])
                        let updatedData = res.data;
                        (res.data).map((item, index) => {
                            updatedData[index] = ({
                                id: item.id,
                                regCode: item.regCode,
                                studentNameMr: item.studentNameMr,
                                studentNameEn: item.studentNameEn,
                                rollNo: item.rollNo,
                                classId: item.classId,
                                divisionId: item.divisionId,
                                toClassId: toClassId,
                                toDivisionId: toDivId,
                                sessionYear: item.sessionYear,
                                sectionId: item.sectionId,
                                branchId: item.branchId,
                                isPromoted: 1
                            })
                            // setRegNumTrue(updatedData[index].regCode);
                            // setRegNumFalse('');
                        })
                        //console.log(JSON.stringify(updatedData))
                        setCheckValue(updatedData);
                        //retrieveData(e, updatedData);


                        //updated Data after search and check or uncheck checkbox if all checkboxes checked
                        let updatedData4 = tempData;
                        tempData.map((e, index) => {
                            updatedData4[index] = ({
                                ...e,
                                isPromoted: 1
                            })
                        })
                        //console.log("updatedData4=========" + JSON.stringify(updatedData4))
                        if (searchTerm == "") {
                            setStudentData(updatedData4);
                            setFilteredData(updatedData4);
                        }
                        else {
                            setStudentData(updatedData);
                            setFilteredData(updatedData);
                        }

                    })
            }
            else if (e.target.checked == false) {
                setCheckValue([]);
                setCheckAllSub(false)
                axios(`${getStudentsForPramot}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${fromClassDivObj.divisionId}`)
                    .then(res => {
                        setStudentData([])
                        setFilteredData([])
                        let updatedData = res.data;
                        (res.data).map((item, index) => {
                            updatedData[index] = ({
                                id: item.id,
                                regCode: item.regCode,
                                studentNameMr: item.studentNameMr,
                                studentNameEn: item.studentNameEn,
                                rollNo: item.rollNo,
                                classId: item.classId,
                                divisionId: item.divisionId,
                                toClassId: toClassId,
                                toDivisionId: toDivId,
                                sessionYear: item.sessionYear,
                                sectionId: item.sectionId,
                                branchId: item.branchId,
                                isPromoted: 0
                            })
                            // setRegNumFalse(updatedData[index].regCode);
                            // setRegNumTrue('');
                        })
                        //console.log(JSON.stringify(updatedData))                   
                        //retrieveData(e, updatedData);

                        //updated Data after search and check or uncheck checkbox if all checkboxes checked
                        let updatedData3 = tempData;
                        tempData.map((e, index) => {
                            //if (e.regCode == regNumFalse) {
                            updatedData3[index] = ({
                                ...e,
                                isPromoted: 0
                            })
                            //}
                        })
                        //console.log("updatedData3-----" + JSON.stringify(updatedData3))
                        if (searchTerm == "") {
                            setStudentData(updatedData3);
                            setFilteredData(updatedData3);
                        }
                        else {
                            setStudentData(updatedData);
                            setFilteredData(updatedData);
                        }

                        //if all data removed from classDivData or checkvalue.length === 0
                        setCheckValue([]);

                    })
            }
        }
    }

    const retrieveData = async (event, savedData) => {
        //setCheckAllSub(event.target.checked);
        if (event.target.checked == true) {
            //setCheckAllSub(true);
            setFilteredData([]);
            setStudentData([]);
            let SaveObj = savedData.filter(e => {
                return e.isPromoted == 1
            })
            let updatedData = SaveObj;
            SaveObj.map((e, index) => {
                updatedData[index] = {
                    id: e.id,
                    regCode: e.regCode,
                    studentNameMr: e.studentNameMr,
                    studentNameEn: e.studentNameEn,
                    rollNo: e.rollNo,
                    classId: e.classId,
                    divisionId: e.divisionId,
                    toClassId: (toClassDivObj == null) ? 0 : toClassDivObj.classId,
                    toDivisionId: (toClassDivObj == null) ? 0 : toClassDivObj.divisionId,
                    sessionYear: e.sessionYear,
                    sectionId: e.sectionId,
                    branchId: e.branchId,
                    isPromoted: 1
                }
            })
            setStudentData(updatedData);
            setFilteredData(updatedData);
        }
        else if (event.target.checked == false) {
            //setCheckAllSub(false);
            setFilteredData([]);
            setStudentData([]);
            let SaveObj = savedData.filter(e => {
                return e.isPromoted == 0
            })
            let updatedData = SaveObj;
            SaveObj.map((e, index) => {
                updatedData[index] = {
                    id: e.id,
                    regCode: e.regCode,
                    studentNameMr: e.studentNameMr,
                    studentNameEn: e.studentNameEn,
                    rollNo: e.rollNo,
                    classId: e.classId,
                    divisionId: e.divisionId,
                    toClassId: (toClassDivObj == null) ? 0 : toClassDivObj.classId,
                    toDivisionId: (toClassDivObj == null) ? 0 : toClassDivObj.divisionId,
                    sessionYear: e.sessionYear,
                    sectionId: e.sectionId,
                    branchId: e.branchId,
                    isPromoted: 0
                }
            })
            setStudentData(updatedData);
            setFilteredData(updatedData);
        }
    }

    const getChekedData = (list) => {

        axios(`${getStudentsForPramot}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${fromClassDivObj.divisionId}`)
            .then(res => {
                let updatedData = res.data;
                (res.data).map((item, index) => {
                    updatedData[index] = {
                        id: item.id,
                        regCode: item.regCode,
                        studentNameMr: item.studentNameMr,
                        studentNameEn: item.studentNameEn,
                        rollNo: item.rollNo,
                        classId: item.classId,
                        divisionId: item.divisionId,
                        toClassId: item.toClassId,
                        toDivisionId: item.toDivisionId,
                        sessionYear: item.sessionYear,
                        sectionId: item.sectionId,
                        branchId: item.branchId,
                        isPromoted: 1
                    }
                })
                //console.log("list........" + JSON.stringify(list)+"updatedData======"+JSON.stringify(updatedData))
                const checkedData = list.length === updatedData.length && list.every((o, i) => Object.keys(o).length === Object.keys(updatedData[i]).length && Object.keys(o).every(k => o[k] === updatedData[i][k]))

                if (checkedData == true) {
                    setCheckAllSub(true);
                } else {
                    setCheckAllSub(false);
                }
            })
    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            studentData.filter(
                (item) =>
                    item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())

            )
        setFilteredData(tempFilteredData);
    }, [searchTerm]);

    const [modal, setModal] = useState(false);


    const promotStudent = (e) => {
        e.preventDefault();
        //setSearchTerm('');
        const checkedChkBoxCount = document.querySelector(".studentChkBox");
        const checkedCount = Object.values(studentData).filter((isChecked) => isChecked.isPromoted).length;
        console.log(checkedCount);
        //setTotalSelectedCheckboxes(checkedChkBoxCount.querySelectorAll('input[type=checkbox]:checked').length);
        setTotalSelectedCheckboxes(checkedCount);
        setModal(true);
    }

    const confirmStudent = () => {
        if (toClassDivObj == null || toClassDivObj == "") {
            toast.warn("Please select class-division first.")
        }
        else if (checkValue.length === 0) {
            toast.warn("Please select atleast one student to promot.")
        }
        else {
            console.log("SaveObject=====" + JSON.stringify(checkValue))
            setLoaderOption(true);

            //toast.success("Proceed for bulk LC.")
            axios.post(`${saveStudentsForPromotion}`, checkValue)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        //navigate('/Home/ad210/');
                        setFromClassDivObj(null);
                        setToClassDivObj(null);
                        setFilteredData([]);
                        setStudentData([]);
                        setTempData([]);
                        setCheckValue([]);
                        setSearchTerm("");
                        setModal(false);
                        setLoaderOption(false);
                        toast.success("Student promoted Successfully.");
                    }
                    else {
                        toast.error("LC Default Failed.")
                    }
                }).catch((error) => {
                    console.warn("Error:" + error)
                })
        }
    }

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);

    const onSort = (key, isAction) => {

        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...studentData.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...studentData.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
            //console.log("studentData============" + JSON.stringify(studentData))

        }
        setFilteredData(studentData);
        setStudentData(studentData);
        getSortedData(studentData, key, isAction);
    }

    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentsForPramot}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${fromClassDivObj.divisionId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("studentData============" + JSON.stringify(studentData))
                }
                setFilteredData([]);
                const checkData = list.length === studentData.length && list.every((o, i) => Object.keys(o).length === Object.keys(studentData[i]).length && Object.keys(o).every(k => o[k] === studentData[i][k]))

                //console.log("checkedData......" + JSON.stringify(checkData))
                if (checkData == true) {
                    setFilteredData(studentData);
                }
            })
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                const checkedChkBoxCount = document.querySelector(".studentChkBox");
                //console.log(checkedChkBoxCount.querySelectorAll('input[type=checkbox]:checked').length);
                setTotalSelectedCheckboxes(checkedChkBoxCount.querySelectorAll('input[type=checkbox]:checked').length);
                setModal(true);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);


    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>


                    {/* <span hidden={(toYear >= futureYear) ? false : true} className='badge' style={{ backgroundColor: "#FA6969", borderRadius: "6px", fontWeight: "520", fontSize: "13px", margin: "4px", padding: "6px" }}>* This facility is not available for this year.</span> */}

                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >From Class-Division:</label>
                        <div className='col-sm-3'>
                            <Autocomplete
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                id="combo-box-demo"
                                onChange={(e, newValue) => {
                                    setFromClassDivObj(newValue);
                                    setFromClassName(newValue.classNameEn + " " + newValue.divisionNameEn);
                                    setToClassDivObj(null);
                                    setStudentData([]);
                                    setFilteredData([]);
                                    setSearchTerm("");
                                    setAscending(false);
                                    setSortedBy(null);
                                }}
                                value={fromClassDivObj}
                                options={classDivData}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="From Class-Division" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >From Year :</label>
                        <div className='col-sm-2'>
                            <TextField
                                fullWidth
                                margin='dense'
                                value={fromYear}
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="From Year"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120, readOnly: true }}
                            />

                        </div>
                    </div>

                    <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >To Class-Division:</label>
                        <div className='col-sm-3'>
                            <Autocomplete
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                id="combo-box-demo"
                                onChange={(e, newValue) => {
                                    setToClassDivObj(newValue);
                                    //setClassName((authUser.branchMedium == 1) ? newValue.classNameMr : newValue.classNameEn);
                                    //setDivName((authUser.branchMedium == 1) ? newValue.divisionNameMr : newValue.divisionNameEn)
                                    setToClassName(newValue.classNameEn + " " + newValue.divisionNameEn);
                                    setToClassId(newValue.classId);
                                    setToDivId(newValue.divisionId);
                                    setStudentData([]);
                                    setFilteredData([]);
                                }}
                                value={toClassDivObj}
                                options={classDivData}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="To Class-Division" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >To Year :</label>
                        <div className='col-sm-2'>
                            <TextField
                                fullWidth
                                margin='dense'
                                value={toYear}
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="To Year"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120, readOnly: true }}
                            />
                        </div>

                        <div className="col-sm-3 mt-3">
                            <button type="button" className={saveBtn} disabled={(toYear >= futureYear) ? true : false} onClick={() => getStudentData()}>View</button>
                        </div>
                    </div>

                    {(studentData == null || studentData == [] || studentData == "")
                        ?
                        <h4 className='mt-5'>No data found, Please select class-division.</h4>
                        :
                        <div>
                            <div>
                                <div className='row col-sm-4 mb-1 mt-5' style={{ float: 'right' }}>
                                    <div>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>


                                <form onSubmit={promotStudent}>
                                    <div className='table-responsive mt-4' style={{ maxHeight: "500px" }}>
                                        <table className="table table-bordered">
                                            <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort("regCode")}>Reg No{sortedBy === "regCode" ? isAscending ? '↑' : '↓' : ''}</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort((authUser.branchMedium == 1) ? "studentNameMr" : "studentNameEn")}>Student Name{sortedBy === ((authUser.branchMedium == 1) ? "studentNameMr" : "studentNameEn") ? isAscending ? '↑' : '↓' : ''}</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort("rollNo")}>Roll No{sortedBy === "rollNo" ? isAscending ? '↑' : '↓' : ''}</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600", margin: "0px", padding: "2px" }}>
                                                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                                                            checked={(checkAllSub == true) ? true : false}
                                                            onChange={checkAllSubjects} /> Status</th>
                                                </tr>
                                            </thead>
                                            <tbody className='studentChkBox' style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData.map((item, index) => {
                                                    return (
                                                        <tr key={index} className="table-sm">
                                                            <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "10px" }} >{item.regCode}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.rollNo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px" }}>
                                                                <Checkbox defaultChecked={(item.isPromoted === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                    onChange={(e) => {
                                                                        let val = e.target.checked;
                                                                        handleCheckChange(val, item.id, item.regCode, item.studentNameMr, item.studentNameEn, item.rollNo, item.classId, item.divisionId, item.toClassId, item.toDivisionId, item.sessionYear, item.branchId, item.sectionId);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn btn-sm"
                                                style={{
                                                    backgroundColor: "#045de9",
                                                    backgroundImage: "linear-gradient(315deg, #045de9 0%, #09c6f9 74%)",
                                                    fontWeight: "550"
                                                }}
                                            >Pramot Students</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
                <ToastContainer position="top-right" theme="colored" />
                <ConfirmBoxStudentPromotion setModal={setModal} modal={modal} toClassName={toClassName} fromClassName={fromClassName} confirmStudent={confirmStudent} totalSelectedCheckboxes={totalSelectedCheckboxes} />
            </>
        )
    }

}

export default PramotShiftStudents