import { BaseUrlLibraryMaster, dBaseUrlLibraryMaster } from "../../CommonServices/APIURL";


//localhost:8080/institute/sss/library/master/getAllSubBudgetTypeMasterList?branchId=1
export const getSubBudgetMasterDataAPIURL = BaseUrlLibraryMaster + "getAllSubBudgetTypeMasterList";

//localhost:8080/institute/sss/library/master/getSubBudgetTypeMasterData?id=2
export const getSubBudgetMasterDataById = BaseUrlLibraryMaster + "getSubBudgetTypeMasterData";

//localhost:8080/institute/sss/library/master/saveSubBudgetTypeMaster
// {
//     "subBudgetTypeName": "BudgetType",
//     "shortName": "subBudgetType",
//     "branchId": 1
// }
export const saveSubBudgetMasterAPIURL = BaseUrlLibraryMaster + "saveSubBudgetTypeMaster";

//localhost:8080/institute/sss/library/master/updateSubBudgetTypeMaster
export const updateSubBudgetMasterAPIURL = BaseUrlLibraryMaster + "updateSubBudgetTypeMaster";

//localhost:8080/institute/sss/library/master/deleteSubBudgetTypeMaster?id=3
export const deleteSubBudgetMasterAPIURL = BaseUrlLibraryMaster + "deleteSubBudgetTypeMaster";