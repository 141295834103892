import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

export const viewFileAPIURL=BaseIP+"/zData/support/";

// localhost:8080/institute/sss/service/serviceForm/saveServiceFormDataWithFile

// moduleId,formId,description,file,roleId,bid,sid

//export const saveServiceFormDataWithFileAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/saveServiceFormDataWithFile"
export const saveServiceFormDataWithFileAPIURL=BaseURL+"service/serviceForm/saveServiceFormDataWithFile"



// localhost:8080/institute/sss/service/serviceForm/saveServiceFormDataWithoutFile

// moduleId,formId,description,userId,branchId,sectionId

//export const saveServiceFormDataWithoutFileAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/saveServiceFormDataWithoutFile"
export const saveServiceFormDataWithoutFileAPIURL=BaseURL+"service/serviceForm/saveServiceFormDataWithoutFile"



// localhost:8080/institute/sss/service/serviceForm/updateServiceFormDataWithFile

// id,moduleId,formId,description,file,roleId,bid,sid



// localhost:8080/institute/sss/service/serviceForm/updateServiceFormDataWithoutFile

// id,moduleId,formId,description,roleId,bid,sid



// localhost:8080/institute/sss/service/serviceForm/deleteServiceFormData

// id
//export const deleteServiceFormDataAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/deleteServiceFormData";
export const deleteServiceFormDataAPIURL=BaseURL+"service/serviceForm/deleteServiceFormData";



//localhost:8080/institute/sss/service/serviceForm/getServiceFormData?roleId=1&bid=1&sid=3

//export const getServiceFormDataAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/getServiceFormData";
export const getServiceFormDataAPIURL=BaseURL+"service/serviceForm/getServiceFormData";


//localhost:8080/institute/sss/service/serviceForm/updateServiceFormStatus?statusId=1&id=21&remark=demotesting

//export const updateServiceFormStatusAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/updateServiceFormStatus"
export const updateServiceFormStatusAPIURL=BaseURL+"service/serviceForm/updateServiceFormStatus"