import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import PrintIcon from '@mui/icons-material/Print';

const BudgetConsolidateReportTable = ({ TableHeading7, setData, setFilteredData, data, filteredData, optionId,
    schoolNm, resolutionNo, resolutionDate, printAllBudgetEntryReport, actualSchoolReceiptFeeBudget, actualSessionReceiptFeeBudget,
    actualSchoolPaymentFeeBudget, actualSessionPaymentFeeBudget,authUser }) => {   

    const [loaderOption, setLoaderOption] = useState(false);

    let totalSchoolfeesKird = (actualSchoolReceiptFeeBudget).toFixed('2') - (actualSchoolPaymentFeeBudget).toFixed('2')
    let totalSsessionfeesKird = (actualSessionReceiptFeeBudget).toFixed('2') - (actualSessionPaymentFeeBudget).toFixed('2')


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div className="">
                        <form>
                            <div className="row mt-5 mb-2" >
                                <div className="">
                                    <div style={{ float: "left", }}>
                                        <span className='mt-3' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{schoolNm}</span><br />
                                        <span className='mt-1' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{(authUser.deptId == 5) ? "महाविद्यालय विकास" : "शाळा"}  समिती ठराव नंबर आणि तारीख : <span style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{(resolutionNo != '' && resolutionDate != '') ? `${resolutionNo} / ${moment(resolutionDate).format("DD-MM-YYYY")}` : ''}</span></span>
                                    </div>
                                    <div style={{ float: "right" }}>
                                        <button
                                            style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                            type='button'
                                            onClick={() => printAllBudgetEntryReport()}
                                            className='btn btn-sm mb-2'
                                        //disabled={btnDisabled}
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            {TableHeading7.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `${item.textAlign}`, lineHeight: "1.5" }} scope="col">{item.label}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            filteredData.map((item, index) => {
                                                return (
                                                    <>
                                                        {(() => {
                                                            if (item.optionId == 1) {
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{(item.schoolFee).toFixed('2')}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{(item.sessionFee).toFixed('2')}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4", textAlign: "center" }}>{item.accNameMr}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>0.00</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>0.00</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                            else if (item.optionId == 2) {
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>0.00</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>0.00</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4", textAlign: "center" }}>{item.accNameMr}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{(item.schoolFee).toFixed('2')}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "180px" }}>{(item.sessionFee).toFixed('2')}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                        })()}

                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualSchoolReceiptFeeBudget).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualSessionReceiptFeeBudget).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", lineHeight: "1.4", textAlign: "center" }}>एकूण</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualSchoolPaymentFeeBudget).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualSessionPaymentFeeBudget).toFixed('2')}</td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>

                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">तपशील</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">{(authUser.deptId == 5) ? "रेग्युलर बी.एड.कोर्स कीर्द" : "शाळा फी किर्द"}</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण जमा रुपये   {(authUser.deptId == 5) ? " (रेग्युलर बी.एड.कोर्स कीर्द)" : " (शाळा फी कीर्द)"}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(actualSchoolReceiptFeeBudget).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण खर्च रुपये   {(authUser.deptId == 5) ? " (रेग्युलर बी.एड.कोर्स कीर्द)" : " (शाळा फी कीर्द)"}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(actualSchoolPaymentFeeBudget).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>फरक रुपये   {(authUser.deptId == 5) ? " (रेग्युलर बी.एड.कोर्स कीर्द)" : " (शाळा फी कीर्द)"}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(totalSchoolfeesKird).toFixed('2')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">तपशील</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">{(authUser.deptId == 5) ? "विनाअनुदानित कोर्सेस कीर्द" : "सत्र फी किर्द"}</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण जमा रुपये   {(authUser.deptId == 5) ? " (विनाअनुदानित कोर्सेस कीर्द)" : " (सत्र फी कीर्द)"}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(actualSessionReceiptFeeBudget).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>एकूण खर्च रुपये   {(authUser.deptId == 5) ? " (विनाअनुदानित कोर्सेस कीर्द)" : " (सत्र फी कीर्द)"}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(actualSessionPaymentFeeBudget).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "500", }}>फरक रुपये   {(authUser.deptId == 5) ? " (विनाअनुदानित कोर्सेस कीर्द)" : " (सत्र फी कीर्द)"}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(totalSsessionfeesKird).toFixed('2')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}

export default BudgetConsolidateReportTable