import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from "axios";
import { MinoritiesSaveMinorities, BaseURLMasterMinorities } from "../CommonServices/MinorityMasterAPIURL";


const AddNewMinority = ({ setMinorityData }) => {

    const [minorityMarathi, setMinorityMarathi] = useState("");
    const [minorityEnglish, setMinorityEnglish] = useState("");
    const [minorityUDise, setMinorityUDise] = useState("");


    const getMinorityData = async () => {
        await axios.get(`${BaseURLMasterMinorities}`)
            .then((response) => {
                setMinorityData(response.data);
            })
    }

    const saveBtnClick = async (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        const saveObject = {
            nameMr: minorityMarathi,
            nameEn: minorityEnglish,
            udiseNo: minorityUDise
        };
        await axios.post(`${MinoritiesSaveMinorities}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getMinorityData();
                    
                    // get modal
                    const modal = document.getElementById('minorityModal');

                    // change state like in hidden modal
                    modal.classList.remove('show');
                    modal.setAttribute('aria-hidden', 'true');
                    modal.setAttribute('style', 'display: none');

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                    // remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);
                    toast.success("Minority Saved sucessfully.");
                } else {
                    toast.danger("Operation failed.");
                }
            });

        setMinorityMarathi("");
        setMinorityEnglish("");
        setMinorityUDise("");

    };

    return (
        <>
            <div className="modal fade" id="minorityModal" tabIndex="-1" aria-labelledby="minorityModalLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                            <h5 className="modal-title" id="minorityModalLabel">Add New Minority</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={saveBtnClick}>
                            <div className="modal-body">

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && minorityMarathi.length === 0) ? e.preventDefault() : ""}
                                    value={minorityMarathi}
                                    onChange={(e) => setMinorityMarathi(e.target.value)}
                                    label="Minority Marathi"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && minorityEnglish.length === 0) ? e.preventDefault() : ""}
                                    value={minorityEnglish}
                                    onChange={(e) => setMinorityEnglish(e.target.value)}
                                    label="Minority English"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && minorityUDise.length === 0) ? e.preventDefault() : ""}
                                    value={minorityUDise}
                                    onChange={(e) => setMinorityUDise(e.target.value)}
                                    label="U-Dise No"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Save</button>
                                <button type="reset" className="btn btn-danger" onClick={()=>{setMinorityMarathi("");setMinorityEnglish("");setMinorityUDise("");}} data-bs-dismiss="modal">Cancle</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewMinority