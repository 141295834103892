import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel';
import makeStyles from "@mui/styles/makeStyles";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { EmployeeMasterGetMapping } from '../../CommonServices/ClassTeacherAllocationAPIURL';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import { printDateWiseEmpAttendreportAPIURL, printMonthWiseEmpAttendreportAPIURL } from '../Services/PayrollReportsAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { EmployeeMasterBranchWiseGetData } from '../../CommonServices/EmployeeMasterAPIURL';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const EmployeeAttendanceReport = () => {

    const titleId = "Employee Attendance Report";
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    const [employeeData, setEmployeeData] = useState([]);
    const [employeeObj, setEmployeeObj] = useState(null);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [option, setOption] = useState(1);
    const [schoolData, setSchoolData] = useState([]);
    const [schoolObj, setSchoolObj] = useState(null);
    const [attendFor, setAttendFor] = useState(1);

    useEffect(() => {
        getData();
    }, [authUser]);


    const getData = async () => {
        console.log(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`);
        if (authUser.branchId != null && authUser.branchId != 50) {
            await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`)
                .then((response) => {
                    setEmployeeData(response.data);
                })
        }

        await axios.get(BaseURLInstituteMaster)
            .then(res => {
                //const newData = [{ id: 1, schoolNameMr: "All", schoolNameEn: "All" }, ...res.data]
                setSchoolData(res.data);
            })
    }

    //get employee list by school id for sanstha
    const getEmployeeDetails = async (schoolid) => {
        await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${schoolid}`)
            .then((response) => {
                setEmployeeData(response.data);
            })
    }

    const getEmpAttendaceDateWiseReport = async () => {
        setLoaderOption(true);
        if (moment(fromDate).format('YYYY-MM-DD') != 'Invalid date' && moment(toDate).format('YYYY-MM-DD') != 'Invalid date') {
            console.log(`${printDateWiseEmpAttendreportAPIURL}?langId=${authUser.branchMedium}&option=${option}&attDateFrom=${moment(fromDate).format('YYYY-MM-DD')}&attDateTo=${(option == 1) ? null : moment(toDate).format('YYYY-MM-DD')}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&year=${authUser.sessionYear}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}`)
            await axios.get(`${printDateWiseEmpAttendreportAPIURL}?langId=${authUser.branchMedium}&option=${option}&attDateFrom=${moment(fromDate).format('YYYY-MM-DD')}&attDateTo=${(option == 1) ? null : moment(toDate).format('YYYY-MM-DD')}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&year=${authUser.sessionYear}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setLoaderOption(false);
                        console.log(res.data);
                        window.open(`${getExportedReportAPIURL}${res.data}`)
                    }
                }).catch(err => {
                    console.log('Emp attend date wise Report Error:' + err)
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error('Inproper date, please check.')
        }
    }

    const getMonthWiseEmpAttendaceReport = async () => {
        setLoaderOption(true);
        if (moment(fromDate).format('YYYY-MM-DD') != 'Invalid date') {
            console.log(`${printMonthWiseEmpAttendreportAPIURL}?langId=${authUser.branchMedium}&option=${option}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&year=${authUser.sessionYear}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}&monthData=${moment(fromDate).format("MM/YYYY")}`)
            await axios.get(`${printMonthWiseEmpAttendreportAPIURL}?langId=${authUser.branchMedium}&option=${option}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&year=${authUser.sessionYear}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}&monthData=${moment(fromDate).format("MM/YYYY")}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setLoaderOption(false);
                        console.log(res.data);
                        window.open(`${getExportedReportAPIURL}${res.data}`)
                    }
                }).catch(err => {
                    console.log('Emp attend month wise Report Error:' + err)
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error('Improper date, please check.')
        }
    }

    const getEmpAttendanceReport = (e) => {
        e.preventDefault();
        if (attendFor == 1) {
            getEmpAttendaceDateWiseReport();
        }
        else {
            getMonthWiseEmpAttendaceReport();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            <form onSubmit={getEmpAttendanceReport}>
                                <div className="row" hidden={(authUser.branchId != 50) ? true : false}>
                                    <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School :</label>
                                    <div className='col-sm-6'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={schoolData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            onChange={(event, newValue) => {
                                                setSchoolObj(newValue);
                                                setOption(1);
                                                setAttendFor(1);
                                                setEmployeeData([]);
                                                setEmployeeObj(null);
                                                setFromDate(new Date());
                                                setToDate(new Date());

                                                if (newValue != null) {
                                                    getEmployeeDetails(newValue.id);
                                                }
                                            }}
                                            value={schoolObj}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => ((authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn)}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select School" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(authUser.branchId == 50) ? true : false} />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                            <Select
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={attendFor}
                                                label="Select Option"
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                onChange={(e) => {
                                                    setAttendFor(e.target.value)
                                                    setOption(1);
                                                    setEmployeeObj(null);
                                                    setFromDate(new Date());
                                                    setToDate(new Date());


                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? attendFor : ""}
                                            >
                                                <MenuItem value={1}>Date Wise</MenuItem>
                                                <MenuItem value={2}>Month Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                            <Select
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={option}
                                                label="Select Option"
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setEmployeeObj(null);
                                                    setFromDate(new Date());
                                                    setToDate(new Date());


                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                            >
                                                <MenuItem value={1}>All Employees</MenuItem>
                                                <MenuItem value={2}>Employee Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {(() => {
                                    if (option == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Employee :</label>
                                                    <div className='col-sm-4'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={employeeData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, newValue) => {
                                                                setEmployeeObj(newValue);
                                                                setFromDate(new Date());
                                                                setToDate(new Date());

                                                            }}
                                                            value={employeeObj}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                                                            renderOption={(props, option) => (
                                                                <div className='' {...props}>
                                                                    <div className=''>
                                                                        {option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="row my-2">
                                    {(() => {
                                        if (attendFor == 1) {
                                            return (
                                                <>
                                                    <label htmlFor="lastname3" className="" style={{ width: "140px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                                    <div className='col-sm-3'>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Date From"
                                                                value={fromDate}
                                                                onChange={(newValue) => {
                                                                    setFromDate(newValue);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? fromDate : ""}
                                                                inputFormat="dd/MM/yyyy"
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        margin='dense'
                                                                        variant="outlined"
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        size="small" />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    <label htmlFor="lastname3" className="" style={{ width: "140px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                                    <div className='col-sm-3'>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Date From"
                                                                value={fromDate}
                                                                onChange={(newValue) => {
                                                                    setFromDate(newValue);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? fromDate : ""}
                                                                views={['year', 'month']}
                                                                inputFormat="MM/yyyy"
                                                                mask="__/____"
                                                                disableMaskedInput={false}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        margin='dense'
                                                                        variant="outlined"
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required
                                                                        fullWidth
                                                                        autoComplete='off'
                                                                        size="small" />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                    <label hidden={(option == 1 || attendFor == 2) ? true : false} htmlFor="lastname3" className="" style={{ width: "80px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                    <div hidden={(option == 1 || attendFor == 2) ? true : false} className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                value={toDate}
                                                onChange={(newValue) => {
                                                    setToDate(newValue);


                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? toDate : ""}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required={(option == 2 || attendFor == 2) ? true : false}
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4" style={{ marginLeft: "140px" }}>
                                        <button type='submit' className='btn btn-primary btn-sm'>Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </>
            }
        </>
    )
}

export default EmployeeAttendanceReport