import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

//getDonationFeeList   ( Only for Donation fee list)
export const getDonationFeeList = BaseURLMaster + "getDonationFeeList/";
export const getSansthaFeeListAPIURL = BaseURLMaster + "getSansthaFeeList/";

// /institute/sss/donationFee/
// -- save 
// PostMapping /saveDonationCollectionData
// String transactionDate;
//     String donatorName;
//     String donatorAddress;
//     long feeId;
//     double receivedAmount;
//     long isCash;
//     String description;
//     long sessionYear;
//     long sectionId;
//     long branchId;
export const saveDengiPavati = BaseURL + "donationFee/saveDonationCollectionData/";

// /institute/sss/donationFee/
// GetMapping /getDonationDetail
// @RequestParam("tid") long transactionId,@RequestParam("acyr") long acYear
export const getDataforEditDetails = BaseURL + "donationFee/getDonationDetail";

// /institute/sss/donationFee/
// PutMapping  /updateDonationCollectionData
//@RequestBody DonationFeeEntryDto 
export const updateDataforDengiPavati = BaseURL + "donationFee/updateDonationCollectionData";

// -- table list
//  @GetMapping /getDonationDataList
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId
export const getDonationDataList = BaseURL + "donationFee/getDonationDataList";
//export const getDonationDataList = "http://192.168.1.102:8080/institute/sss/donationFee/getDonationDataList/";

// /institute/sss/admission/reports
// /generateDengiPavti
// @RequestParam("langId") long id, @RequestParam("recid") long recId , @RequestParam("acYear") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId
export const printDengiPavati = BaseURL + "admission/reports/generateDengiPavti";


//localhost:8080/institute/sss/admission/reports/generateDengiPavtiDateWise?langId=1&acYear=2021&sid=3&bid=1&fromDate=5/04/2021&toDate=15/01/2022
export const printDengiPavatiFromTo = BaseURL + "admission/reports/generateDengiPavtiDateWise";

//localhost:8080/institute/sss/admission/reports/generateDengiPavtiByFeeIdAndDateWise?langId=1&acYear=2021&sid=3&bid=1&feeId=36&fromDate=5/04/2021&toDate=15/01/2022
export const printFeeWiseDengiPavati = BaseURL + "admission/reports/generateDengiPavtiByFeeIdAndDateWise";

//localhost:8080/institute/sss/admission/reports/generateDengiPavtiPahochDateWise?langId=1&acYear=2021&sid=3&bid=1&fromDate=5/06/2021&toDate=24/12/2021
export const printDateWiseDengiPohochPavati = BaseURL + "admission/reports/generateDengiPavtiPahochDateWise";

//localhost:8080/institute/sss/admission/reports/generateDengiPavtiPahochAll?langId=1&acYear=2021&sid=3&bid=1
export const printAllDengiPohochPavati = BaseURL + "admission/reports/generateDengiPavtiPahochAll";

//localhost:8080/institute/sss/admission/reports/generateDengiPavtiPahochByFeeIdAndDateWise?langId=1&acYear=2021&sid=3&bid=1&feeId=36&fromDate=5/06/2021&toDate=24/12/2021
export const printFeeWiseDengiPohochPavati = BaseURL + "admission/reports/generateDengiPavtiPahochByFeeIdAndDateWise";

//generateDengiPavtiPahochAllBranch
export const printAllDengiPohochPavatiForAllBranch = BaseURL + "admission/reports/generateDengiPavtiPahochAllBranch";

//generateDengiPavtiPahochByPavatiNo
export const printDengiPohochPavatiByPavatiNo = BaseURL + "admission/reports/generateDengiPavtiPahochByPavtiNo";

//generateDengiPavtiPahochBetweenDate
export const printDengiPohochPavatiBetweenDate = BaseURL + "admission/reports/generateDengiPavtiPahochBetweenDate";