import { BaseIP, BaseURL, BaseURLMaster, BaseURLAcMaster, InstMasterName } from "./APIURL";

//Login API Links
export const LoginURL = BaseURL + "user/signin";

//medium API Links
export const MediumURL = BaseURL + "user/branchMedium";

//Year for Login
export const GetYearAPIURL = BaseIP + InstMasterName + "/institute/sss/user/secyear/getyears";


//get form processing api
//http://192.168.195.102:8081/institute-sss/institute/sss/service/parameterInfo/getParameterDataByRoleIdAndBranchId?roleId=1&bid=1
export const getFormDataByRoleAndBranch = BaseURL + "service/parameterInfo/getParameterDataByRoleIdAndBranchId";