import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getStudAgeStatisticReportAPIURL } from '../Services/UdiesEReportAPIURL';

const StudAgeStatistics = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Age Statistics";

    const [loaderOption, setLoaderOption] = useState(false);

    const printStudAgeStatisticReport = async () => {
        setLoaderOption(true);
        await axios.get(`${getStudAgeStatisticReportAPIURL}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.");
                }
                else {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Student age statistic report err:" + err);
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <div className=''>
                                <button
                                    type='button'
                                    onClick={() => printStudAgeStatisticReport()}
                                    className='btn btn-primary btn-sm mb-2'
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>
                            </div>
                        </div>
                    </div>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default StudAgeStatistics