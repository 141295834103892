import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";


//localhost:8080/institute/sss/service/serviceForm/getServiceFormCountData

//export const getServiceFormCountDataAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/getServiceFormCountData/";
export const getServiceFormCountDataAPIURL = BaseURL + "service/serviceForm/getServiceFormCountData/";


//localhost:8080/institute/sss/service/serviceForm/getServiceFormCountDataSchoolWise

//export const getServiceFormCountDataSchoolWiseAPIURL="http://192.168.1.102:8080/institute/sss/service/serviceForm/getServiceFormCountDataSchoolWise/";
export const getServiceFormCountDataSchoolWiseAPIURL = BaseURL + "service/serviceForm/getServiceFormCountDataSchoolWise/";