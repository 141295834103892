const labelName = [
    {
        id: 134,
        title: "Old School",
        GetMapping: "oldSchools",
        PostMapping: "saveOldSchool",
        PutMapping: "updateOldSchool",
        DeleteMapping: "deleteOldSchool"
    },
    {
        id: 135,
        title: "Bank",
        GetMapping: "bankNames",
        PostMapping: "saveBank",
        PutMapping: "updateBank",
        DeleteMapping: "deleteBank"
    },
    {
        id: 136,
        title: "Batch",
        GetMapping: "batchNames",
        PostMapping: "saveBatch",
        PutMapping: "updateBatch",
        DeleteMapping: "deleteBatch"
    },

    //batches/{id}

];
export default labelName;