import React, { useState } from 'react'

const ParentDataAssignTextBox = ({ initialText1, initialText2, onChange }) => {
    const [isEdit1, setEdit1] = useState(false);
    const [isEdit2, setEdit2] = useState(false);
    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);

    return (
        <>
            <td style={{ width: "300px", height: "60px" }}>
                <input
                    type='text'
                    className='form-control form-control-sm'
                    //style={{ width: "170px" }}
                    style={(isEdit1 === true) ? { width: "300px", fontSize: "14px", } : { width: "300px", fontSize: "14px", border: "0px" }}
                    onFocus={() => setEdit1(true)}
                    onBlur={() => { setEdit1(false); onChange(text1, text2) }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2) : ""}
                    onChange={(e) => setText1(e.target.value)}
                    value={text1}
                />
            </td>


            <td style={{ width: "300px", height: "60px" }}>
                <input
                    type='text'
                    className='form-control form-control-sm'
                    //style={{ width: "170px" }}
                    style={(isEdit2 === true) ? { width: "300px", fontSize: "14px", } : { width: "300px", fontSize: "14px", border: "0px" }}
                    onFocus={() => setEdit2(true)}
                    onBlur={() => { setEdit2(false); onChange(text1, text2) }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2) : ""}
                    onChange={(e) => setText2(e.target.value)}
                    value={text2}
                />
            </td>
        </>
    )
}

export default ParentDataAssignTextBox