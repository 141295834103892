import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";



export const getStudentReligionByDivId=BaseURL+"administration/getStudentReligionAllocationData";

export const saveStudentReligionByDivClass=BaseURL+"administration/saveStudentReligion";



// /institute/sss/administration/

// GetMapping /getStudentReligionAllocationData

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cdid") long classDivId

// ------------------------------------------------

// PostMapping /saveStudentReligion

// private long id;
//     private long regCode;
//     private String  studentNameMr;
//     private String  studentNameEn;
//     private String casteNameMr;
//     private String casteNameEn;
//     private  long rollNo;
//     private  long religionId;
//     private String religionNameMr;
//     private String religionNameEn;
//     private long sessionYear;
//     private long sectionId;
//     private long branchId;
//     private long classId;
//     private long divisionId;
