import { createSlice } from "@reduxjs/toolkit";

//const initialStateValue={name:"", age:0, email:""};
const initialStateValue = { roleId: null, roleName: "", deptId: null, deptName: "", branchId: null, branchName: "", yearLabel: "", sessionYear: "", fromDate: "", toDate: "", accApl: "", branchMedium: "", username: "", accountingType: "", feeType: "", trustId: null };

const serviceInitialValues = { userName: "", email: "", mobile: "" };


export const userSlice = createSlice({
    name: "user",
    initialState: { value: initialStateValue },
    serviceInitialState: { value: serviceInitialValues },
    reducers: {
        login: (state, action) => {
            state.value = { ...action.payload };
        },

        logout: (state, action) => {
            state.value = initialStateValue;
        },
        serviceLogin: (state, action) => {
            state.service = action.payload;
        },
        serviceLogout: (state, action) => {
            state.service = serviceInitialValues;
        },
        userNotification: (state, action) => {
            state.user.notificationCount = action.payload.notificationCount;
        }
    }
});


export const { login, logout, serviceLogin, serviceLogout, userNotification } = userSlice.actions;

export default userSlice.reducer;