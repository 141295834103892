import React, {useState} from 'react';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from "axios";
import {NationalityMasterGetMapping, NationalityPostMapping} from "../CommonServices/NationalityMasterAPIURL";

const AddNewNationalityModal = ({setNationalityData}) => {
    const [nationalityMarathi, setNationalityMarathi]=useState("");
    const [nationalityEnglish, setNationalityEnglish]=useState("");

    const getnationalityData= async ()=>{
        await axios.get(`${NationalityMasterGetMapping}`)
            .then((response) => {
                setNationalityData(response.data);
            })
    }

    const saveBtnClick = async (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        const saveObject = {            
            nameMr: nationalityMarathi,
            nameEn: nationalityEnglish,
        };
        await axios.post(`${NationalityPostMapping}`, saveObject)
            .then((response) => {
                if (response.data != null) {    
                    getnationalityData();   
                    
                    // get modal
                    const modal = document.getElementById('nationalityModal');

                    // change state like in hidden modal
                    modal.classList.remove('show');
                    modal.setAttribute('aria-hidden', 'true');
                    modal.setAttribute('style', 'display: none');

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                    // remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);

                    toast.success("nationality Saved sucessfully.");
                } else {
                    toast.danger("Operation failed.");
                }
            });

            setNationalityMarathi("");
            setNationalityEnglish("");
     
    };

    return (
        <>
            <div className="modal fade" id="nationalityModal" tabIndex="-1" aria-labelledby="nationalityModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                            <h5 className="modal-title" id="nationalityModalLabel">Add New Nationality</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={saveBtnClick}>
                            <div className="modal-body">

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && nationalityMarathi.length === 0) ? e.preventDefault() : ""}
                                    value={nationalityMarathi}
                                    onChange={(e)=>setNationalityMarathi(e.target.value)}
                                    label="nationality Marathi"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && nationalityEnglish.length === 0) ? e.preventDefault() : ""}
                                    value={nationalityEnglish}
                                    onChange={(e)=>setNationalityEnglish(e.target.value)}
                                    label="nationality English"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Save</button>
                                <button type="reset" className="btn btn-danger" onClick={()=>{setNationalityMarathi("");setNationalityEnglish("");}} data-bs-dismiss="modal">Cancle</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>

    )
}

export default AddNewNationalityModal