import {BaseIP, BaseURL, BaseURLMaster} from "./APIURL";

//Nationalities Master API Link
export const NationalityMasterGetMapping=BaseURLMaster + "Nationalities/";
export const NationalityPostMapping=BaseURLMaster + "saveNationalities/";
export const NationalityPutMapping=BaseURLMaster + "updateNationality/";
export const NationalityDeleteMapping=BaseURLMaster + "deleteNationality/";



// GetMapping:"Nationalities",
//         PostMapping:"saveNationalities",
//         PutMapping:"updateNationality",
//         DeleteMapping:"deleteNationality"