import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';
import {
    BaseURLSection, saveBranchDepartmentsForEndow, updateBranchDepartmentsForEndow,
    getBranchDepartmentsForEndow, deleteBranchDepartmentsForEndow
} from '../../../CommonServices/InstituteMasterAPIURL';
import { useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const DepartmentWiseEndowmentId = ({ branchId }) => {
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const navigate = useNavigate();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Departments`, key: 'departments' },
        { label: `Endowment Id`, key: 'endowId' },
        { label: `Medium`, key: 'deptMedium' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [endowId, setEndowId] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [deptMedium, setDeptMedium] = useState('')

    const [loaderOption, setLoaderOption] = useState(false);
    const [id, setId] = useState('');

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {

        await axios.get(`${BaseURLSection}`)
            .then((response) => {
                const sortData = (response.data).filter(e => e.acApl === 0)
                setDepartmentData(sortData);
            })

        await axios.get(`${getBranchDepartmentsForEndow}?bid=${branchId}`)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
            })


    }

    const UpdateData = (item) => {
        const departmentName = departmentData.find(e => e.id == item.departmentId)

        setDepartmentObj(departmentName);
        setEndowId(item.endowmentId);
        setDeptMedium(item.deptMedium);
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        setId(item.id);
    }

    //console.log("Data : " + JSON.stringify(filteredData))

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = (id) => {

        axios.delete(`${deleteBranchDepartmentsForEndow}?id=${id}`)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    toast.success("Deleted sucessfully");
                }
            });
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.nameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.nameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.feeTypeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.shortName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const saveBtnClick = () => {
        if (endowId > 0) {
            const saveObj = {
                "branchId": branchId,
                "departmentId": departmentObj.id,
                "endowmentId": endowId,
                "deptMedium": deptMedium
            }
            console.log(JSON.stringify(saveObj))
            axios.post(saveBranchDepartmentsForEndow, saveObj)
                .then((response) => {
                    if (response.data === "ENDOWMENTIDEXIST") {
                        setLoaderOption(false);
                        toast.warn(`Branch with endowment id ${endowId} is already exists.`)
                    }
                    else if (response.data == "SAVED") {
                        getData();
                        ClearData();
                        setLoaderOption(false);
                    } else {
                        setLoaderOption(false);
                        toast.error("Operation failed.");
                    }
                });
        }
        else {
            setLoaderOption(false);
            toast.error("Endowment id should be greater than 0/Zero")
        }
    }

    const updateBtnClick = () => {
        if (endowId > 0) {
            const updateObj = {
                "id": id,
                "branchId": branchId,
                "departmentId": departmentObj.id,
                "endowmentId": endowId,
                "deptMedium": deptMedium
            }
            console.log(JSON.stringify(updateObj))
            axios.put(updateBranchDepartmentsForEndow, updateObj)
                .then((response) => {
                    if (response.data === "ENDOWMENTIDEXIST") {
                        setLoaderOption(false);
                        toast.warn(`Branch with endowment id ${endowId} is already exists.`)
                    }
                    else if (response.data == "SAVED") {
                        getData();
                        ClearData();
                        setLoaderOption(false);
                    } else {
                        setLoaderOption(false);
                        toast.error("Operation failed.");
                    }
                });
        }
        else {
            setLoaderOption(false);
            toast.error("Endowment id should be greater than 0/Zero")
        }
    }

    const ClearData = () => {
        setDepartmentObj(null);
        setEndowId('');
        setDeptMedium('');
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            //saveBtnClick();
        }
        if (state.button === 2) {
            //updateBtnClick();
        }
    };

    return (
        <>
            <form>
                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                <div className="row my-3">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Departments :</label>
                    <div className='col-sm-4'>
                        <Autocomplete
                            id="combo-box-demo"
                            options={departmentData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={departmentObj}
                            onChange={(event, newValue) => {
                                setDepartmentObj(newValue);
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Select Department" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                            )}
                        />
                    </div>

                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Endowment Id:</label>
                    <div className='col-sm-2'>
                        <TextField
                            required
                            onKeyDown={(e) => (e.key === " " && endowId.length === 0) ? e.preventDefault() : ""}
                            onWheel={(e) => e.target.blur()}
                            fullWidth
                            type="number"
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="Endowment Id"
                            variant="outlined"
                            margin='dense'
                            value={endowId}
                            onChange={(e) => setEndowId(e.target.value)}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Medium :</label>
                    <div className='col-sm-3'>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Medium</InputLabel>
                            <Select
                                size='small'
                                required
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={deptMedium}
                                label="Medium"
                                onChange={(e) => {
                                    setDeptMedium(e.target.value);
                                }}
                            >
                                <MenuItem value={1}>Marathi</MenuItem>
                                <MenuItem value={2}>English</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="col-sm-12 mt-3">
                    <div className="offset-sm-2">
                        <button type="button" disabled={(departmentObj == '' || departmentObj == null || endowId == '' || deptMedium == '') ? true : false} className={saveBtn} onClick={() => saveBtnClick()}>Save</button>
                        <button type="button" disabled={(departmentObj == '' || departmentObj == null || endowId == '' || deptMedium == '') ? true : false} className={updateBtn} onClick={() => updateBtnClick()}>Update</button>
                        <button type="button" className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/m129/0")}>Cancel</button>
                    </div>
                </div>
            </form>

            {/* <div className='row mt-1'>
                    <div >
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value) }}
                        />
                    </div>
                </div> */}

            <div className='table-responsive mt-5'>
                <table className="table table-bordered">
                    <thead className="table-Default">
                        <tr>
                            {TableHeading.map((item, index) => {
                                return (
                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .reverse().map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                {
                                                    departmentData.map(e => {
                                                        if (e.id == item.departmentId) {
                                                            return (authUser.branchMedium == 1) ? e.nameMr : e.nameEn
                                                        }
                                                    })
                                                }
                                            </td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.endowmentId}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.deptMedium === 1 ? 'Marathi' : item.deptMedium === 2 ? 'English' : ''}</td>
                                            <td>
                                                <Tooltip title="Edit">
                                                    <span className='btn btn-primary btn-sm mx-1 p-1'
                                                        onClick={() => UpdateData(item)} data-bs-toggle="tooltip">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </span>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <Tooltip title="Delete">
                                                    <span className='btn btn-danger btn-sm mx-1 p-1'
                                                        onClick={() => setDeleteId(item.id)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })}
                    </tbody>
                    {/* <tfoot style={{ padding: "0px" }}>
                            <tr>
                                <TablePagination
                                    style={{ padding: "0px" }}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                    onPageChange={onPageChange}
                                    labelRowsPerPage="Rows Per Page :"
                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                            </tr>
                        </tfoot> */}
                </table>
            </div>

            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default DepartmentWiseEndowmentId