// import React, { useState, useEffect } from 'react'
// import makeStyles from "@mui/styles/makeStyles";
// import TitleLabel from "../../CommonComponent/TitleLabel";
// import axios from 'axios';
// import { VoucherEntryGetMapping, getDualSideVoucherEntryListAPI } from '../../CommonServices/VoucherEntryAPIURL';
// import { BaseURLAccountMaster } from '../../CommonServices/AccountMasterAPIURL';
// //import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
// import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
// import { useSelector } from "react-redux";
// import { useParams } from 'react-router-dom';
// import AddIcon from '@mui/icons-material/Add';
// import { ToastContainer, toast } from 'react-toastify';
// import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
// import ReceiptImage from '../Modal/r1.png';
// import ChalanImage from '../Modal/c1.png';
// import { printReceiptAndChalanTransactionWise } from '../Services/ReceiptAndChalanAPIURL';
// import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
// import Loader from '../../CommonComponent/Loader';

// const useStyles = makeStyles({
//     caption: {
//         padding: 0,
//         fontWeight: 600,
//         fontSize: "12px"
//     },
//     toolbar: {
//         fontSize: "12px",
//         fontWeight: 600
//     }
// });


// const DualVoucherEntryTable = () => {
//     const authUser = useSelector((state) => state.user.value);
//     let { toastFlag } = useParams();
//     const titleId = "Dual Voucher Entry (किर्द)";
//     const classes = useStyles();
//     const navigate = useNavigate();

//     const [loaderOption, setLoaderOption] = useState(false);



//     //Note: This below states are declared for Save & Update(Disabled/unDisabled)

//     const saveBtnStyle = "btn btn-primary btn-sm";
//     const updateBtnStyle = "btn btn-primary btn-sm";
//     const displayNoneBtnStyle = "d-none";
//     const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
//     const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

//     const [isAscending, setAscending] = useState(false);
//     const [sortedBy, setSortedBy] = useState(null);
//     const [searchTerm, setSearchTerm] = useState("")
//     const [data, setData] = useState([]);
//     const [accountData, setAccountData] = useState([]);
//     const [page, setPage] = useState(0)
//     const [rowsPerPage, setRowsPerPage] = useState(2);
//     const [filteredData, setFilteredData] = useState(data);

//     const onPageChange = (event, nextPage) => {
//         setPage(nextPage);
//     }
//     const onRowsPerPageChange = (e) => {
//         setRowsPerPage(parseInt(e.target.value));
//         setPage(0);
//     }

//     useEffect(() => {
//         getData();
//         if (toastFlag == 1) {
//             toast.success("Voucher Entry saved successfully.")
//             navigate("/Home/ac04/0")
//         }
//         else if (toastFlag == 2) {
//             toast.success("Voucher Entry updated successfully.")
//             navigate("/Home/ac04/0")
//         }

//     }, [authUser]);

//     const getData = async () => {
//         if (authUser.deptId != null && authUser.sessionYear != "" && authUser.branchId != null) {
//             console.log(`${getDualSideVoucherEntryListAPI}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
//             setLoaderOption(true);
//             await axios(`${getDualSideVoucherEntryListAPI}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
//                 .then(res => {
//                     setData(res.data);
//                     setFilteredData(res.data);
//                     setLoaderOption(false)
//                 }).catch(err => {
//                     console.log(err);
//                     setLoaderOption(false);
//                 })
//         }

//     }

//     const onSort = (key, isAction) => {
//         if (!isAction) {
//             setSortedBy(key)
//             if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
//             else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
//             setAscending(!isAscending)
//         }
//     }

//     useEffect(() => {
//         setFilteredData(
//             data.filter(
//                 (item) =>
//                     item.narration.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                     item.accountCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
//                     item.accountNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                     item.accountNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                     item.debitAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
//                     item.creditAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
//                     item.voucherId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
//                     item.voucherDate.toString().toLowerCase().includes(searchTerm.toLowerCase())
//             )
//         );
//     }, [searchTerm]);

//     const TableHeading = [
//         { label: 'V No क्रमांक', key: 'voucherId' },
//         { label: `V Date तारीख`, key: 'voucherDate' },
//         { label: `Account | खाते`, key: (authUser.branchMedium == 1) ? 'accountNameMr' : 'accountNameEn' },
//         { label: `Narration | तपशील`, key: 'narration' },
//         { label: `Cr. Amt जमा रक्कम`, key: 'creditAmount' },
//         { label: `Dr. Amt नावे रक्कम`, key: 'debitAmount' },
//         { label: `RC`, key: 'RC', isAction: true },
//         // { label: 'Edit', value: 'edit', isAction: true }
//     ];


//     const UpdateData = (item, EditFlag) => {
//         navigate(`/Home/ac04F/${item}/${EditFlag}`);
//     }

//     const printReceiptChalan = async (vId, type) => {
//         setLoaderOption(true);
//         //console.log(`${printReceiptAndChalanTransactionWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&voucherNo=${vId}&isReceipt=1&langId=${authUser.branchMedium}`)
//         await axios.get(`${printReceiptAndChalanTransactionWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&voucherNo=${vId}&isReceipt=${type}&langId=1`)
//             .then((response) => {
//                 //setLoaderOption(false);
//                 if (response.data == "NOTFOUND") {
//                     setLoaderOption(false);
//                     toast.warn("Data not found, please check.")
//                 }
//                 else if (response.data != null) {
//                     console.log(response.data)
//                     window.open(`${getExportedReportAPIURL}${response.data}`);
//                     setLoaderOption(false);
//                 }

//             })
//             .catch(error => {
//                 setLoaderOption(false);
//                 toast.error("Error Occurred, Try after sometime.");
//                 console.log("Receipt and chalan failed: " + error)
//             })
//         setLoaderOption(false);
//     }

//     //console.log(loaderOption);

//     //Get the button:
//     let mybutton = document.getElementById("myBtn");
//     //console.log(mybutton);

//     // When the user scrolls down 20px from the top of the document, show the button
//     window.onscroll = function () { scrollFunction() };

//     const scrollFunction = () => {
//         if (mybutton !== null) {
//             if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
//                 mybutton.style.display = "block";
//             } else {
//                 mybutton.style.display = "none";
//             }
//         }
//     }

//     // When the user clicks on the button, scroll to the top of the document
//     const topFunction = () => {
//         document.body.scrollTop = 0; // For Safari
//         document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
//         //setLoaderOption(false);
//     }

//     const bottomFunction = () => {
//         var element = document.getElementById("bottom");
//         element.scrollIntoView({ behavior: "smooth" });
//         //setLoaderOption(false);
//     }


//     return (
//         <>
//             {(loaderOption == true)
//                 ?
//                 <Loader />
//                 :
//                 <div>
//                     <button onClick={() => topFunction()} id="myBtn" title="Go to top">
//                         <DoubleArrowRoundedIcon style={{ marginTop: "-6px" }} />
//                     </button>

//                     <TitleLabel titleId={titleId} />
//                     <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
//                         <div className=''>
//                             <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/ac04F/0/1")}><AddIcon fontSize="small" /> New Voucher</button>
//                         </div>

//                         <div className='row mb-1' style={{ float: 'right' }}>
//                             <div className='col-sm-10'>
//                                 <input
//                                     className='form-control form-control-sm'
//                                     type="text"
//                                     style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
//                                     placeholder="Search Here"
//                                     value={searchTerm}
//                                     onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
//                                 />
//                             </div>
//                             <div className='col-sm-2'>
//                                 <button onClick={() => bottomFunction()} id="myBtnBottom" title="Go to bottom">
//                                     <DoubleArrowRoundedIcon style={{ marginTop: "-6px" }} />
//                                 </button>
//                             </div>
//                         </div>

//                         <div className='table-responsive' style={{ overflowY: "hidden" }}>
//                             <table className="table table-bordered">
//                                 <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
//                                     <tr>
//                                         {TableHeading.map((item, index) => {
//                                             return (
//                                                 <th style={{ fontSize: "13px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
//                                             )
//                                         })}
//                                     </tr>
//                                 </thead>
//                                 <tbody className="hoverTr" style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
//                                     {filteredData
//                                         .map((item, index) => {
//                                             return (

//                                                 <tr key={index}>
//                                                     <td style={{ fontSize: "13px" }} onClick={() => UpdateData(item.voucherId, 2)}>{item.voucherId}</td>
//                                                     <td style={{ fontSize: "13px" }} onClick={() => UpdateData(item.voucherId, 2)}>{moment(item.voucherDate).format("DD/MM/YY")}</td>
//                                                     {/* <td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{(authUser.branchMedium == 1) ? item.accountCode + " - " + item.accountNameMr : item.accountCode + " - " + item.accountNameEn}</td> */}

//                                                     {(() => {
//                                                         if (authUser.branchId === 17 && authUser.deptId === 4) {
//                                                             return (
//                                                                 <td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{item.accountCode + " - " + item.accountNameMr}</td>
//                                                             )
//                                                         }
//                                                         else {
//                                                             return (
//                                                                 //<td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{(authUser.branchMedium == 1) ? item.accountCode + " - " + item.accountNameMr : item.accountCode + " - " + item.accountNameEn}</td>
//                                                                 <td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{item.accountCode + " - " + item.accountNameMr}</td>
//                                                             )
//                                                         }
//                                                     })()}


//                                                     <td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{item.narration}</td>
//                                                     <td style={{ fontSize: "13px", textAlign: "right", width: "80px" }} onClick={() => UpdateData(item.voucherId, 2)}>{(item.transactionMode == 1) ? parseFloat(item.creditAmount).toFixed(2) : " "}</td>
//                                                     <td style={{ fontSize: "13px", textAlign: "right", width: "80px" }} onClick={() => UpdateData(item.voucherId, 2)}>{(item.transactionMode == 2) ? parseFloat(item.debitAmount).toFixed(2) : " "}</td>

//                                                     <td>
//                                                         {(() => {
//                                                             if (item.transactionMode == 1 && item.isReceipt == 1) {
//                                                                 return (
//                                                                     <img src={ReceiptImage} alt="Receipt Image" title="Receipt" onClick={() => printReceiptChalan(item.voucherId, 1)} style={{ borderRadius: "0", height: "auto", width: "30px" }} />
//                                                                 )
//                                                             } else if (item.transactionMode == 2 && item.isReceipt == 1) {
//                                                                 return (
//                                                                     <img src={ChalanImage} alt="Chalan Image" title="Chalan" onClick={() => printReceiptChalan(item.voucherId, 2)} style={{ borderRadius: "0", height: "auto", width: "30px" }} />
//                                                                 )
//                                                             } else {
//                                                                 return (
//                                                                     ""
//                                                                 )
//                                                             }
//                                                         })()}
//                                                     </td>
//                                                 </tr>

//                                             )
//                                         })}
//                                 </tbody>
//                                 <tfoot style={{ padding: "0px" }}>

//                                 </tfoot>
//                             </table>
//                         </div>
//                     </div>
//                     <div id='bottom'></div>
//                 </div>
//             }

//             {/* <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} /> */}
//             {/* <ToastContainer position="top-right" theme="colored" /> */}

//         </>
//     )

// };

// export default DualVoucherEntryTable


import React, { useState, useEffect } from 'react'
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { VoucherEntryGetMapping, getDualSideVoucherEntryListAPI, getDualSideVoucherEntryTypeAPI } from '../../CommonServices/VoucherEntryAPIURL';
import { BaseURLAccountMaster } from '../../CommonServices/AccountMasterAPIURL';
//import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import ReceiptImage from '../Modal/r1.png';
import ChalanImage from '../Modal/c1.png';
import { printReceiptAndChalanTransactionWise } from '../Services/ReceiptAndChalanAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import Loader from '../../CommonComponent/Loader';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import DualVoucherEntryForm from './DualVoucherEntryForm';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    }
});


const DualVoucherEntryTable = () => {
    const authUser = useSelector((state) => state.user.value);
    let { toastFlag } = useParams();
    const titleId = "Dual Voucher Entry (किर्द)";
    const classes = useStyles();
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);



    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [data, setData] = useState([]);
    const [accountData, setAccountData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(2);
    const [filteredData, setFilteredData] = useState(data);
    const [entryType, setEntryType] = useState('');

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    useEffect(() => {
        getData();
        if (toastFlag == 1) {
            toast.success("Voucher Entry saved successfully.")
            navigate("/Home/ac04/0")
        }
        else if (toastFlag == 2) {
            toast.success("Voucher Entry updated successfully.")
            navigate("/Home/ac04/0")
        }

    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null && authUser.sessionYear != "" && authUser.branchId != null) {
            console.log(`${getDualSideVoucherEntryListAPI}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
            setLoaderOption(true);
            await axios(`${getDualSideVoucherEntryListAPI}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false)
                }).catch(err => {
                    console.log(err);
                    setLoaderOption(false);
                })
        }

    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.narration.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.debitAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.creditAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.voucherId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.voucherDate.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const TableHeading = [
        { label: 'V No क्रमांक', key: 'voucherId' },
        { label: `V Date तारीख`, key: 'voucherDate' },
        { label: `Account | खाते`, key: (authUser.branchMedium == 1) ? 'accountNameMr' : 'accountNameEn' },
        { label: `Narration | तपशील`, key: 'narration' },
        { label: `Cr. Amt जमा रक्कम`, key: 'creditAmount' },
        { label: `Dr. Amt नावे रक्कम`, key: 'debitAmount' },
        { label: `RC`, key: 'RC', isAction: true },
        { label: 'Entry Type', value: 'entryType', isAction: true }
    ];


    const UpdateData = (item, EditFlag) => {
        // navigate(`/Home/ac04F/${item}/${EditFlag}`);
        setLoaderOption(true);
        axios.get(`${getDualSideVoucherEntryTypeAPI}?vno=${item}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
            .then((res) => {
                if (res.data !== null) {

                    if (res.data == 1) {
                        navigate(`/Home/ac04F1/${item}/${EditFlag}`);
                        setLoaderOption(false);
                    }
                    else if (res.data == 2) {
                        navigate(`/Home/ac04F2/${item}/${EditFlag}`);
                        setLoaderOption(false);
                    }
                    else if (res.data == 3) {
                        navigate(`/Home/ac04F3/${item}/${EditFlag}`);
                        setLoaderOption(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                setLoaderOption(false);
            })

    }

    const printReceiptChalan = async (vId, type) => {
        setLoaderOption(true);
        //console.log(`${printReceiptAndChalanTransactionWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&voucherNo=${vId}&isReceipt=1&langId=${authUser.branchMedium}`)
        await axios.get(`${printReceiptAndChalanTransactionWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&voucherNo=${vId}&isReceipt=${type}&langId=1`)
            .then((response) => {
                //setLoaderOption(false);
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found, please check.")
                }
                else if (response.data != null) {
                    console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Receipt and chalan failed: " + error)
            })
        setLoaderOption(false);
    }

    //console.log(loaderOption);

    //Get the button:
    let mybutton = document.getElementById("myBtn");
    //console.log(mybutton);

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction() };

    const scrollFunction = () => {
        if (mybutton !== null) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        //setLoaderOption(false);
    }

    const bottomFunction = () => {
        var element = document.getElementById("bottom");
        element.scrollIntoView({ behavior: "smooth" });
        //setLoaderOption(false);
    }

    // Form Type
    const [formType, setFormType] = useState("")

    const changeFormFunction = (e) => {
        setFormType(e.target.value);
        if (e.target.value == 1) {
            navigate(`/Home/ac04F1/0/1`);
        }
        else if (e.target.value == 2) {
            navigate(`/Home/ac04F2/0/1`);
        }
        else if (e.target.value == 3) {
            navigate(`/Home/ac04F3/0/1`);
        }

    }

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <button onClick={() => topFunction()} id="myBtn" title="Go to top">
                        <DoubleArrowRoundedIcon style={{ marginTop: "-6px" }} />
                    </button>

                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                        <div className='row'>
                            <label className="" style={{ width: '110px', fontSize: "14px", fontWeight: "600", marginTop: '10px' }} >New Entry :</label>
                            {/* <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/ac04F/0/1")}><AddIcon fontSize="small" /> New Voucher</button> */}
                            <div className="col-sm-3">
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="demo-simple-select-label">Voucher Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formType}
                                        label="Voucher Type"
                                        onChange={(e) => changeFormFunction(e)}
                                        size='small'
                                    // style={{ float: 'left' }}
                                    >
                                        <MenuItem value={1}>Cash</MenuItem>
                                        <MenuItem value={2}>Bank</MenuItem>
                                        <MenuItem value={3}>JV Entry</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row mb-1' style={{ float: 'right' }}>
                            <div className='col-sm-10'>
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                />
                            </div>
                            <div className='col-sm-2'>
                                <button onClick={() => bottomFunction()} id="myBtnBottom" title="Go to bottom">
                                    <DoubleArrowRoundedIcon style={{ marginTop: "-6px" }} />
                                </button>
                            </div>
                        </div>

                        <div className='table-responsive' style={{ overflowY: "hidden" }}>
                            <table className="table table-bordered">
                                <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "13px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody className="hoverTr" style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                    {filteredData
                                        .map((item, index) => {
                                            return (

                                                <tr key={index}>
                                                    <td style={{ fontSize: "13px" }} onClick={() => UpdateData(item.voucherId, 2)}>{item.voucherId}</td>
                                                    <td style={{ fontSize: "13px" }} onClick={() => UpdateData(item.voucherId, 2)}>{moment(item.voucherDate).format("DD/MM/YY")}</td>
                                                    {/* <td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{(authUser.branchMedium == 1) ? item.accountCode + " - " + item.accountNameMr : item.accountCode + " - " + item.accountNameEn}</td> */}

                                                    {(() => {
                                                        if (authUser.branchId === 17 && authUser.deptId === 4) {
                                                            return (
                                                                <td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{item.accountCode + " - " + item.accountNameMr}</td>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                //<td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{(authUser.branchMedium == 1) ? item.accountCode + " - " + item.accountNameMr : item.accountCode + " - " + item.accountNameEn}</td>
                                                                <td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{item.accountCode + " - " + item.accountNameMr}</td>
                                                            )
                                                        }
                                                    })()}


                                                    <td style={{ fontSize: "13px", lineHeight: "1.6" }} onClick={() => UpdateData(item.voucherId, 2)}>{item.narration}</td>
                                                    <td style={{ fontSize: "13px", textAlign: "right", width: "80px" }} onClick={() => UpdateData(item.voucherId, 2)}>{(item.transactionMode == 1) ? parseFloat(item.creditAmount).toFixed(2) : " "}</td>
                                                    <td style={{ fontSize: "13px", textAlign: "right", width: "80px" }} onClick={() => UpdateData(item.voucherId, 2)}>{(item.transactionMode == 2) ? parseFloat(item.debitAmount).toFixed(2) : " "}</td>

                                                    <td>
                                                        {(() => {
                                                            if (item.transactionMode == 1 && item.isReceipt == 1) {
                                                                return (
                                                                    <img src={ReceiptImage} alt="Receipt Image" title="Receipt" onClick={() => printReceiptChalan(item.voucherId, 1)} style={{ borderRadius: "0", height: "auto", width: "30px" }} />
                                                                )
                                                            } else if (item.transactionMode == 2 && item.isReceipt == 1) {
                                                                return (
                                                                    <img src={ChalanImage} alt="Chalan Image" title="Chalan" onClick={() => printReceiptChalan(item.voucherId, 2)} style={{ borderRadius: "0", height: "auto", width: "30px" }} />
                                                                )
                                                            } else {
                                                                return (
                                                                    ""
                                                                )
                                                            }
                                                        })()}
                                                    </td>

                                                    <td style={{ fontSize: "13px", textAlign: "center", width: "80px" }} onClick={() => UpdateData(item.voucherId, 2)}>
                                                        {(() => {
                                                            if (item.voucherType == 1) {
                                                                return (
                                                                    <span className='badge' style={{ backgroundColor: "#1EC40D", borderRadius: "10px", fontWeight: "600" }}>Cash Entry</span>
                                                                )
                                                            }
                                                            else if (item.voucherType == 2) {
                                                                return (
                                                                    <span className='badge' style={{ backgroundColor: "#B4281D", borderRadius: "10px", fontWeight: "600" }}>Bank Entry</span>
                                                                )
                                                            }
                                                            else if (item.voucherType == 3) {
                                                                return (
                                                                    <span className='badge' style={{ backgroundColor: "#1D38B4", borderRadius: "10px", fontWeight: "600" }}>JV Entry</span>
                                                                )
                                                            }
                                                        })()}
                                                    </td>
                                                </tr>

                                            )
                                        })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>

                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div id='bottom'></div>
                </div>
            }

            {/* <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} /> */}
            {/* <ToastContainer position="top-right" theme="colored" /> */}



        </>
    )

};

export default DualVoucherEntryTable