import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { deleteHlidatMarkingAPIURL, deleteHolidayMarkingAPIURL } from '../Services/HolidayMarkingAPIURL';
import HolidayMarkingDeleteModal from './HolidayMarkingDeleteModal';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const HoilidayMarkingTable = ({ TableHeading, data, filteredData, setFilteredData, setSaveBtn, setUpdateBtn, updateBtnStyle, displayNoneBtnStyle,
    option, setLoaderOption, getData }) => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const [holidayId, setHolidayId] = useState('');
    const [holidayType, setHolidayType] = useState('');
    const [holidayDay, setHolidayDay] = useState('');

    const DeleteData = async () => {
        setLoaderOption(true);
        await axios.delete(`${deleteHolidayMarkingAPIURL}?id=${(holidayType == 1) ? 0 : holidayId}&holidayType=${holidayType}&year=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data == "DELETED") {
                    setLoaderOption(false);
                    getData();
                    toast.success("Deleted successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Holiday marking delete failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Holiday marking delete" + err);
                toast.error("Something went wrong, please check.")
            })
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(item => (
            item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.holidayDate.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.day.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.detail.toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])


    return (
        <>
            <div className='row mt-1'>
                <div >
                    <input
                        className='form-control form-control-sm'
                        type="text"
                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                    />
                </div>{" "}
            </div>

            <div className='table-responsive'>
                <table className="table table-bordered">
                    <thead className="table-Default">
                        <tr>
                            {TableHeading.map((item, index) => {
                                return (
                                    <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `${item.textAlign}` }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.id}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "200px" }}>
                                                {
                                                    (item.holidayType == 1)
                                                        ?
                                                        'Weekly'
                                                        :
                                                        'Annually'
                                                }
                                            </td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "200px" }}>{item.holidayDate}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "200px" }}>{item.day}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.detail}</td>
                                            <td style={{ width: "100px", textAlign: "center" }}>
                                                <Tooltip title="Delete">
                                                    <span className='btn btn-danger btn-sm mx-1 p-1'
                                                        onClick={() => { setHolidayId(item.id); setHolidayType(item.holidayType); setHolidayDay(item.day) }} data-bs-toggle="modal" data-bs-target="#holidayMarkingModal"
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })}
                    </tbody>
                    <tfoot style={{ padding: "0px" }}>
                        <tr>
                            <TablePagination
                                style={{ padding: "0px" }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onRowsPerPageChange={onRowsPerPageChange}
                                onPageChange={onPageChange}
                                labelRowsPerPage="Rows Per Page :"
                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                        </tr>
                    </tfoot>
                </table>
            </div>
            {/* <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} /> */}
            <HolidayMarkingDeleteModal DeleteData={DeleteData} holidayType={holidayType} holidayDay={holidayDay} />
        </>
    )
}

export default HoilidayMarkingTable