import { BaseIP, BaseURL, BaseURLMaster,dBaseURL } from "../../CommonServices/APIURL";

export const BaseURLSubAllocation = BaseURL + "academics/subjectAllocation/";
export const getClassDivDataForSubAllocation = BaseURLSubAllocation + "getClassAndDivisionList";
export const postClassDivDataForSubAllocation = BaseURLSubAllocation + "saveSubjectAllocation/";
//export const getClassDivDataForSubAllocation = dBaseURL + "academics/subjectAllocation/getClassAndDivisionList";

export const getClassDivisionURL = BaseURLMaster + "classWithDivisionBySectionId/";

export const subjectGetData = BaseURLMaster + "Subjects/"


//Report
// localhost:8080/institute/sss/academic/reports/generateSubjectAllocationReport?langId=2&acYear=2021&sid=3&bid=1
//localhost:8080/institute/sss/academic/reports/generateSubjectWiseSubjectAllocationReport?langId=1&acYear=2021&sid=3&bid=1
export const generateSubjectAllocationReport = BaseURL + "academic/reports/generateSubjectAllocationReport";
export const generateSubjectAllocationReportByClassDiv = BaseURL + "academic/reports/generateClassWiseSubjectAllocationReport";
export const generateSubjectAllocationReportBySubject = BaseURL + "academic/reports/generateSubjectWiseSubjectAllocationReport";