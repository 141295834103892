import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitleLabel from '../../CommonComponent/TitleLabel';
import CircularProgress from '@mui/material/CircularProgress';
import HostelAllocationTable from './HostelAllocationTable';
import Loader from '../../CommonComponent/Loader';
import { getStudentListByBranchAPIURL } from '../../CommonServices/StudentRegistrationAPIURL';
import {
    getUnAllocatedBuildingListAPIURL, getUnAllocatedRoomByBuildingAPIURL, getUnAllocatedBedByRoomAPIURL,
    saveHostelAllocationAPIURL, getHostelAllocationDataAPIURL, updateHostelAllocationAPIURL
} from '../Services/HostelAllocationAPIURL';
import { getRoomMasterAPIURL } from '../Services/RoomMasterAPIURL';
import { getBedMasterAPIURL } from '../Services/BedMasterAPIURL';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}


const HostelAllocation = () => {
    const titleId = 'Hostel Allocation';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Student`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Building`, key: 'buildingName' },
        { label: `Room`, key: 'roomName' },
        { label: `Bed`, key: 'bedName' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "hostelAlloSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "hostelAlloUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [buildingData, setBuildingData] = useState([]);
    const [buildingObj, setBuildingObj] = useState(null);
    const [roomData, setRoomData] = useState([]);
    const [roomObj, setRoomObj] = useState(null);
    const [bedData, setBedData] = useState([]);
    const [bedObj, setBedObj] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [readOnlyField, setReadOnlyField] = useState(false);

    const [roomData1, setRoomData1] = useState([]);
    const [bedData1, setBedData1] = useState([]);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && studentData.length === 0;

    //Student Entry required data
    const [countData, setCountData] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        getData();
    }, [numCal, authUser])

    const getData = async () => {
        // setLoaderOption(true);
        if (authUser.branchId != null) {
            if (offset === 0) {
                setLoaderOption(true);
            }
            // console.log(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
            await axios.get(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
                .then(res => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        //console.log(tempData.length)
                       
                        setStudentData(studentData.concat(tempData).filter(e => e.currentStatus == 0));
                        setLoaderOption(false);
                        //console.log("Data : " + JSON.stringify(tempData))
                        //console.log("____________________________________________")
                        if (tempData.length === limits) {
                            setOffset(offset + limits);
                            setNumCall(numCal + 1);
                            // setLoaderOption(false);
                            //console.log("Offset ===============" + offset);
                        }
                    }

                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get student list err' + err)
                })
        }

        if (authUser.sessionYear != '' && authUser.branchId != null) {
            await axios.get(`${getUnAllocatedBuildingListAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    setBuildingData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get building err' + err)
                })

            await axios.get(`${getHostelAllocationDataAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get hostel allocation err' + err)
                })
        }

        await axios.get(`${getRoomMasterAPIURL}`)
            .then(res => {
                setRoomData1(res.data);
                setLoaderOption(false);
            }).catch(err => {
                setLoaderOption(false);
                console.log('get all room err' + err)
            })

        await axios.get(`${getBedMasterAPIURL}`)
            .then(res => {
                setBedData1(res.data);
                setLoaderOption(false);
            }).catch(err => {
                setLoaderOption(false);
                console.log('get all bedt err' + err)
            })
    }

    //get room data by builidngId
    const getRoomByBuildingId = async (buildingid) => {
        await axios.get(`${getUnAllocatedRoomByBuildingAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}&buildingId=${buildingid}`)
            .then(res => {
                setRoomData(res.data);
            }).catch(err => {
                console.log('get room by building err' + err)
            })
    }

    //get bed data by roomId
    const getBedListByRoom = async (roomid) => {
        await axios.get(`${getUnAllocatedBedByRoomAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}&roomId=${roomid}`)
            .then(res => {
                setBedData(res.data);
            }).catch(err => {
                console.log('get bed by room err' + err)
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setStudentObj(null);
        setBuildingObj(null);
        setRoomObj(null);
        setBedObj(null);
        setRoomData([]);
        setBedData([]);
        setReadOnlyField(false);
    }

    //save function
    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "regNo": studentObj.regCode,
            "bedId": bedObj.id,
            "sessionYear": authUser.sessionYear,
            "branchId": authUser.branchId
        }
        await axios.post(saveHostelAllocationAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Allocation for this student is already done.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Hostel allocation save failed.")
                }
            }).catch(err => {
                console.log("Hostel allocation save err" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    //update function
    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "regNo": studentObj.regCode,
            "bedId": bedObj.id,
            "sessionYear": authUser.sessionYear,
            "branchId": authUser.branchId
        }
        await axios.put(updateHostelAllocationAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Allocation for this student is already done.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Hostel allocation update failed.")
                }
            }).catch(err => {
                console.log("Hostel allocation update err" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    //submit form at enter press
    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.hostelAlloSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.hostelAlloUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }
        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                        <div className='col-sm-4'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                loading={loading}
                                                options={studentData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setStudentObj(newValue);
                                                    setBuildingObj(null);
                                                    setRoomObj(null);
                                                    setBedObj(null);
                                                    setRoomData([]);
                                                    setBedData([]);
                                                }}
                                                readOnly={(readOnlyField == true) ? true : false}
                                                onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                                value={studentObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                renderOption={(props, option) => (
                                                    <div className='' {...props}>
                                                        <div className=''>
                                                            {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                                        </div>
                                                    </div>
                                                )}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Building :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={buildingData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setBuildingObj(newValue);
                                                    setRoomObj(null);
                                                    setBedObj(null);
                                                    setRoomData([]);
                                                    setBedData([]);
                                                    if (newValue != null) {
                                                        getRoomByBuildingId(newValue.id)
                                                    }
                                                }}
                                                value={buildingObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.buildingName}
                                                onKeyPress={(e) => (e.key == "Enter") ? buildingObj : ""}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Building" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Room :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={roomData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setRoomObj(newValue);
                                                    setBedObj(null);
                                                    setBedData([]);
                                                    if (newValue != null) {
                                                        getBedListByRoom(newValue.id);
                                                    }
                                                }}
                                                value={roomObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.roomNo}
                                                onKeyPress={(e) => (e.key == "Enter") ? roomObj : ""}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Room" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Bed :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={bedData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setBedObj(newValue);
                                                }}
                                                value={bedObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.bedName}
                                                onKeyPress={(e) => (e.key == "Enter") ? bedObj : ""}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Bed" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-1">
                                            <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                            <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                            <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3">
                                <HostelAllocationTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    setFilteredData={setFilteredData}
                                    filteredData={filteredData}
                                    getData={getData}
                                    setId={setId}
                                    studentData={studentData}
                                    setStudentObj={setStudentObj}
                                    buildingData={buildingData}
                                    setBuildingObj={setBuildingObj}
                                    roomData1={roomData1}
                                    setRoomObj={setRoomObj}
                                    bedData1={bedData1}
                                    setBedObj={setBedObj}
                                    clearData={clearData}
                                    setRoomData={setRoomData}
                                    setBedData={setBedData}
                                    setReadOnlyField={setReadOnlyField}
                                    setSaveBtn={setSaveBtn}
                                    setUpdateBtn={setUpdateBtn}
                                    updateBtnStyle={updateBtnStyle}
                                    displayNoneBtnStyle={displayNoneBtnStyle}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default HostelAllocation