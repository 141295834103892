import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import AccountMasterDeleteModal from '../../CommonComponent/AcoountMasterDeleteModal';
import { toast } from 'react-toastify';
import { AccountMasterDelete, BaseURLAccountMaster } from '../../CommonServices/AccountMasterAPIURL';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import TitleLabel from '../../CommonComponent/TitleLabel';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { deleteInvestmentEntryAPIURL, getInvestmentEntryAPIURL } from '../Services/InvestmentEntryAPIURL';
import moment from 'moment';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const InvetmentEntryTable = () => {
    const titleId = "Investment Entry"
    const authUser = useSelector((state) => state.user.value);

    const navigate = useNavigate();

    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const TableHeading = [
        { label: 'Id', key: 'id' },
        { label: 'Type', key: (authUser.branchMedium == 1) ? 'typeMasterNameMr' : 'typeMasterNameEn' },
        { label: 'Date', key: 'date' },
        { label: 'Receipt No.', key: 'receiptNo' },
        { label: 'Bank Name', key: 'bankName' },
        { label: 'Amount', key: 'amount' },
        { label: 'Validity Date', key: 'expiryDate' },
        { label: 'Interest Rate', key: 'InterestRate' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const UpdateData = async (item, EditFlag) => {
        navigate(`/Home/aci623F/${item}/${EditFlag}`)
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getInvestmentEntryAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setData(response.data);
                    setFilteredData(response.data);
                })
        }
    }

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = (id) => {

        axios.delete(`${deleteInvestmentEntryAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data == "DELETED") {
                    getData();
                    toast.success("Deleted sucessfully");
                }
            });
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(
            (item) =>
                item.typeMasterNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.typeMasterNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.bankName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.receiptNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.expiryDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.amount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.interestRate.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )

        setFilteredData(tempFilteredData);


    }, [searchTerm]);

    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                {/* <div className='mt-1'> */}
                <div className=''>
                    <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate('/Home/aci623F/0/1')}><AddIcon fontSize="small" /> New Investment Entry</button>
                </div>

                <div className='row mb-1' style={{ float: 'right' }}>
                    <div className=''>
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                        />
                    </div>
                </div>
                {/* </div> */}

                <div className='table-responsive'>
                    <table className="table table-bordered">
                        <thead className="table-Default">
                            <tr>
                                {TableHeading.map((item, index) => {
                                    return (
                                        <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: '1.5' }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .reverse().map((item, index) => {
                                    return (

                                        <tr key={index}>
                                            <td style={{ fontSize: "15px", fontWeight: "500", width: '80px' }}>{item.id}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.5" }}>{(authUser.branchMedium == 1) ? item.typeMasterNameMr : item.typeMasterNameEn}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.5", width: '100px' }}>{moment(item.date).format("DD/MM/YYYY")}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.5", width: '100px' }}>{item.receiptNo}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.5" }}>{item.bankName}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.5", width: '100px' }}>{item.amount}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.5", width: '100px' }}>{moment(item.expiryDate).format("DD/MM/YYYY")}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.5", width: '100px' }}>{item.interestRate}</td>

                                            <td>
                                                <Tooltip title="Edit">
                                                    <span className='btn btn-primary btn-sm mx-1 p-1'
                                                        onClick={() => UpdateData(item.id, 2)} data-bs-toggle="tooltip">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </span>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <Tooltip title="Delete">
                                                    <span className='btn btn-danger btn-sm mx-1 p-1'
                                                        data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                        onClick={() => setDeleteId(item.id)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                </Tooltip>
                                            </td>
                                        </tr>

                                    )
                                })}
                        </tbody>
                        <tfoot style={{ padding: "0px" }}>
                            <tr>
                                <TablePagination
                                    style={{ padding: "0px" }}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                    onPageChange={onPageChange}
                                    labelRowsPerPage="Rows Per Page :"
                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}


export default InvetmentEntryTable
