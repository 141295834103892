import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import TitleLabel from "../../../CommonComponent/TitleLabel";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IosShareIcon from '@mui/icons-material/IosShare';
import { Modal, Button, Form } from "react-bootstrap";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { getAllWithdrwalEntryDetails, reIntroduceBookDetailsAPIURL } from "../Services/WithdrawalEntryAPIURL";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { getAuthorPublisherByTitle } from "../../Acquisition/Services/AccessionEntryAPIURL";
import { updateWithdrawalEntryAPIURL } from "../Services/WithdrawalEntryAPIURL";

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const newdata = [
    {
        id: 1,
        withdrawalDate: "02/01/2022",
        accNo: 3,
        titleName: "The Campus",
        registerTypeName: "Register Type"
    }
]

const WithdrawalEntryTable = () => {
    const titleId = "Withdrawal Table";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [showEditModal, setShowEditModal] = useState(false);
    const [showReIntroduceModal, setShowReIntroduceModal] = useState(false);

    const [accNo, setAccNo] = useState('');
    const [registerType, setRegisterType] = useState('');
    const [bookName, setBookName] = useState('');
    const [author, setAuthor] = useState('');
    const [publisher, setPublisher] = useState('');
    const [withdrawalNo, setWithdrawalNo] = useState('');
    const [withdrawalDate, setWithdrawalDate] = useState(null);
    const [withdrawalType, setWithdrawalType] = useState('');
    const [remark, setRemark] = useState('');
    const [amount, setAmount] = useState('');
    const [id, setId] = useState('');
    const [registerTypeId, setRegisterTypeId] = useState('');

    const [reIntroduceDate, setreIntroduceDate] = useState(null);
    const [reIntroRemark, setreIntroRemark] = useState('');

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        if (authUser.branchId != null && authUser.sessionYear != '') {
            getData();
        }
    }, [authUser])


    const getData = async () => {
        setLoaderOption(true);
        console.log(`${getAllWithdrwalEntryDetails}?bid=${authUser.branchId}&sessionYear=${authUser.sessionYear}`)
        await axios.get(`${getAllWithdrwalEntryDetails}?bid=${authUser.branchId}&sessionYear=${authUser.sessionYear}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setLoaderOption(false);
            })
    }

    const clearForm = () => {
        setAccNo('');
        setRegisterType('');
        setBookName('');
        setAuthor('');
        setPublisher('');
        setWithdrawalNo('');
        setWithdrawalDate(null);
        setWithdrawalType('');
        setRemark('');
        setAmount('');
        setId('');
    }

    const UpdateData = (item) => {
        setShowEditModal(true);
        setId(item.id);
        setAccNo(item.accNo);
        setRegisterType(item.registerTypeName);
        setBookName(item.titleName);

        axios.get(`${getAuthorPublisherByTitle}?id=${item.titleId}`)
            .then(res => {
                setAuthor(res.data.authorName);
                setPublisher(res.data.publisherName);
            })

        setWithdrawalNo(item.withdrawalNo);
        setWithdrawalDate(item.withdrawalDate);
        setWithdrawalType(item.withdrawalType);
        setRemark(item.remark);
        setAmount(item.amount);
    }

    const updateWithdrawalEntry = async () => {
        setLoaderOption(true);
        setShowEditModal(false);
        if (moment(withdrawalDate).format("DD//MM/YYYY") != 'Invalid date') {
            const updateObj = {
                "id": id,
                "withdrawalType": withdrawalType,
                "withdrawalDate": moment(withdrawalDate).format("YYYY-MM-DD"),
                "remark": remark,
                "amount": (withdrawalType != 3) ? 0 : amount,
            }
            await axios.put(updateWithdrawalEntryAPIURL, updateObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        clearForm();
                        getData();
                        setLoaderOption(false);
                        toast.success("Withdrawal entry updated successfully.")
                    }
                }).catch(err => {
                    console.log("Withdrawal update err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const clearReintroduceForm = () => {
        setreIntroduceDate(null);
        setreIntroRemark('');
    }

    const reIntoduceBookDetails = (item) => {
        setShowReIntroduceModal(true);
        setId(item.id);
        setAccNo(item.accNo);
        setRegisterType(item.registerTypeName);
        setBookName(item.titleName);
        setRegisterTypeId(item.registerTypeId);

        axios.get(`${getAuthorPublisherByTitle}?id=${item.titleId}`)
            .then(res => {
                setAuthor(res.data.authorName);
                setPublisher(res.data.publisherName);
            })

    }

    const reIntroduceBook = async () => {
        setLoaderOption(true);
        setShowReIntroduceModal(false);
        if (moment(reIntroduceDate).format("DD//MM/YYYY") != 'Invalid date') {
            const updateObj = {
                "id": id,
                "reintroduceDate": moment(reIntroduceDate).format("YYYY-MM-DD"),
                "reintroduceRemark": reIntroRemark,
                "withdrawalStatus": 2,
                "registerTypeId": registerTypeId,
                "accNo": accNo,
                "branchId": authUser.branchId
            }
            console.log(updateObj);
            await axios.post(reIntroduceBookDetailsAPIURL, updateObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        clearReintroduceForm();
                        getData();
                        setLoaderOption(false);
                        toast.success("Book added to stock successfully.")
                    }
                }).catch(err => {
                    console.log("reintroduce book err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) => (
            item.registerTypeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.titleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.withdrawalDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.accNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.withdrawalNo.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const TableHeading = [
        { label: 'Withdrawal No', key: 'withdrawalNo' },
        { label: `Withdrawal Date`, key: 'withdrawalDate' },
        { label: `Acc No`, key: 'accNo' },
        { label: `Register Type`, key: 'registerTypeName' },
        { label: `Book Name`, key: 'titleName' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Re-Introduce', value: 'reIntroduce', isAction: true }];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <div className=''>
                                <button className='btn btn-primary btn-sm mb-3' style={{ float: 'left' }} onClick={() => navigate(`/Home/lc904F/`)} ><AddIcon fontSize="small" />New Withdrawal Entry</button>
                            </div>

                            {/* <div className=''>
                                <button className='btn btn-sm mb-3 ml-2' style={{ float: 'left', backgroundColor: "#318C09", color: "#fff" }} ><IosShareIcon fontSize="small" /> Export PDF</button>
                            </div> */}

                            <div className='row mt-2' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className='table-responsive'>
                                    <table className="table table-bordered">
                                        <thead className="table-Default">
                                            <tr>
                                                {TableHeading.map((item, index) => {
                                                    return (
                                                        <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>{item.withdrawalNo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{moment(item.withdrawalDate).format("DD/MM/YYYY")}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.accNo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.registerTypeName}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.titleName}</td>
                                                            <td>
                                                                <Tooltip title="Edit">
                                                                    <button className='btn btn-primary btn-sm mx-1 p-1'
                                                                        onClick={() => UpdateData(item)}
                                                                    //disabled={(authUser.roleId === 4 || authUser.roleId === 3) ? true : false}
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </button>
                                                                </Tooltip>
                                                            </td>
                                                            <td>
                                                                <Tooltip title="Delete">
                                                                    <span className="badge" onClick={() => reIntoduceBookDetails(item)} style={{ backgroundColor: "#EE3E22", cursor: "pointer", fontSize: "13px" }}>Re-Introduce</span>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <TablePagination
                                                    style={{ padding: "0px" }}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    count={data.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onRowsPerPageChange={onRowsPerPageChange}
                                                    onPageChange={onPageChange}
                                                    labelRowsPerPage="Rows Per Page :"
                                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}


            <Modal show={showEditModal}>
                <Modal.Header>
                    <Modal.Title>Update Withdrwal Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Acc No :</Form.Label>
                            <TextField
                                type="number"
                                label="Acc No"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "150px" }}
                                value={accNo}
                                onKeyPress={(e) => (e.key == "Enter") ? accNo : ''}
                                onChange={(e) => setAccNo(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Register Type :</Form.Label>
                            <TextField

                                label="Register Type"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={registerType}
                                onKeyPress={(e) => (e.key == "Enter") ? registerType : ''}
                                onChange={(e) => setRegisterType(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Book Name :</Form.Label>
                            <TextField

                                label="Book Name"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={bookName}
                                onKeyPress={(e) => (e.key == "Enter") ? bookName : ''}
                                onChange={(e) => setBookName(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Author :</Form.Label>
                            <TextField

                                label="Author"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={author}
                                onKeyPress={(e) => (e.key == "Enter") ? author : ''}
                                onChange={(e) => setAuthor(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Publisher :</Form.Label>
                            <TextField
                                label="Publisher"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={publisher}
                                onKeyPress={(e) => (e.key == "Enter") ? publisher : ''}
                                onChange={(e) => setPublisher(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <hr />

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Withdrawal No :</Form.Label>
                            <TextField
                                type="number"
                                label="WD No"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "150px" }}
                                value={withdrawalNo}
                                onKeyPress={(e) => (e.key == "Enter") ? withdrawalNo : ''}
                                onChange={(e) => setWithdrawalNo(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Withdrawal Type :</Form.Label>
                            <FormControl fullWidth margin='dense' style={{ width: "280px" }}>
                                <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Withdrawal Type</InputLabel>
                                <Select
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    size='small'
                                    required
                                    fullWidth
                                    margin='dense'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Withdrawal Type"
                                    value={withdrawalType}
                                    onChange={(e) => {
                                        setWithdrawalType(e.target.value);
                                    }}
                                >
                                    <MenuItem value={1}>Missing</MenuItem>
                                    <MenuItem value={2}>Damage</MenuItem>
                                    <MenuItem value={3}>Cash Deposite</MenuItem>
                                    <MenuItem value={4}>Write Off</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'block' }} hidden={(withdrawalType != 3) ? true : false}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Amount :</Form.Label>
                            <TextField
                                fullWidth
                                required={(withdrawalType == 3) ? true : false}
                                onKeyDown={(e) => (e.key === " " && amount.length === 0) ? e.preventDefault() : ""}
                                autoComplete='off'
                                type='number'
                                margin='dense'
                                style={{ width: "300px" }}
                                size='small'
                                variant="outlined"
                                InputProps={{ inputProps: { min: 1 } }}
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                onKeyPress={(e) => (e.key == "Enter") ? amount : ""}
                                label="Amount"
                                value={amount}
                                onChange={(e) => {
                                    setAmount(e.target.value);
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Withdrawal Date :</Form.Label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Withdrawal Date"
                                    inputFormat="dd/MM/yyyy"
                                    value={withdrawalDate}
                                    onKeyDown={(e) => (e.key === " " && withdrawalDate.length === 0) ? e.preventDefault() : ""}
                                    onKeyPress={(e) => (e.key == "Enter") ? withdrawalDate : ""}
                                    onChange={(newValue) => {
                                        // setRemark('');
                                        if (newValue === null) {
                                            setWithdrawalDate(new Date());
                                        }
                                        else if (newValue !== null) {
                                            setWithdrawalDate(newValue);
                                        }

                                    }}
                                    // disableOpenPicker
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            margin='dense'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            required
                                            style={{ width: "300px" }}
                                            //style={{ width: "130px", marginLeft: "15px", backgroundColor: "#fff" }}
                                            fullWidth
                                            autoComplete='off'
                                            size="small" />}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Remark :</Form.Label>
                            <TextField
                                type="number"
                                label="Remark"
                                multiline
                                rows={3}
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={remark}
                                onKeyPress={(e) => (e.key == "Enter") ? remark : ''}
                                onChange={(e) => setRemark(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}

                            />
                        </Box>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => updateWithdrawalEntry()}>Update</Button>
                    <Button variant="danger" onClick={() => setShowEditModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showReIntroduceModal}>
                <Modal.Header>
                    <Modal.Title>Re-Introduce Book</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Acc No :</Form.Label>
                            <TextField
                                type="number"
                                label="Acc No"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "150px" }}
                                value={accNo}
                                onKeyPress={(e) => (e.key == "Enter") ? accNo : ''}
                                onChange={(e) => setAccNo(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Register Type :</Form.Label>
                            <TextField

                                label="Register Type"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={registerType}
                                onKeyPress={(e) => (e.key == "Enter") ? registerType : ''}
                                onChange={(e) => setRegisterType(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Book Name :</Form.Label>
                            <TextField

                                label="Book Name"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={bookName}
                                onKeyPress={(e) => (e.key == "Enter") ? bookName : ''}
                                onChange={(e) => setBookName(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Author :</Form.Label>
                            <TextField

                                label="Author"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={author}
                                onKeyPress={(e) => (e.key == "Enter") ? author : ''}
                                onChange={(e) => setAuthor(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Publisher :</Form.Label>
                            <TextField
                                label="Publisher"
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={publisher}
                                onKeyPress={(e) => (e.key == "Enter") ? publisher : ''}
                                onChange={(e) => setPublisher(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}
                                inputProps={{ readOnly: true }}
                            />
                        </Box>

                        <hr />

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Re-Introduce Date :</Form.Label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Re-Introduce Date"
                                    inputFormat="dd/MM/yyyy"
                                    value={reIntroduceDate}
                                    onKeyDown={(e) => (e.key === " " && reIntroduceDate.length === 0) ? e.preventDefault() : ""}
                                    onKeyPress={(e) => (e.key == "Enter") ? reIntroduceDate : ""}
                                    onChange={(newValue) => {
                                        // setRemark('');
                                        if (newValue === null) {
                                            setreIntroduceDate(new Date());
                                        }
                                        else if (newValue !== null) {
                                            setreIntroduceDate(newValue);
                                        }

                                    }}
                                    // disableOpenPicker
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            margin='dense'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            required
                                            style={{ width: "300px" }}
                                            //style={{ width: "140px", marginLeft: "15px", backgroundColor: "#fff" }}
                                            fullWidth
                                            autoComplete='off'
                                            size="small" />}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box sx={{ display: 'block' }}>
                            <Form.Label className='' style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Remark :</Form.Label>
                            <TextField
                                type="number"
                                label="Remark"
                                multiline
                                rows={3}
                                size='small'
                                fullWidth
                                margin='dense'
                                variant='outlined'
                                style={{ width: "300px" }}
                                value={reIntroRemark}
                                onKeyPress={(e) => (e.key == "Enter") ? reIntroRemark : ''}
                                onChange={(e) => setreIntroRemark(e.target.value)}
                                autoComplete="off"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                required={true}

                            />
                        </Box>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => reIntroduceBook()}>Re-Introduce</Button>
                    <Button variant="danger" onClick={() => setShowReIntroduceModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default WithdrawalEntryTable