import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlusCircle, faPrint } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Loader from '../../CommonComponent/Loader';
import { getTimeTableSlotDataAPIURL } from '../Services/ManageTimeSlotAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const weekData = [
    {
        id: 1,
        weekName: 'Monday'
    },
    {
        id: 2,
        weekName: 'Tuesday'
    },
    {
        id: 3,
        weekName: 'Wednsday'
    },
    {
        id: 4,
        weekName: 'Thursday'
    },
    {
        id: 5,
        weekName: 'Friday'
    },
    {
        id: 6,
        weekName: 'Saturday'
    },

]

const ManageTimeSlotTable = () => {
    const authUser = useSelector((state) => state.user.value);
    let { toastFlag } = useParams();
    const titleId = "Manage Time Slot";
    const classes = useStyles();
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const [filteredData, setFilteredData] = useState(data);

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        getApiData();
    }, [authUser])




    const getApiData = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
            await axios.get(`${getTimeTableSlotDataAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                })
        }
    }

    const getWeekNameById = (id) => {
        const weekObj = weekData.find(week => week.id === id);
        return weekObj ? weekObj.weekName : 'Unknown';
    }

    useEffect(() => {
        setFilteredData(
            data.filter(item =>
                item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.scheduleDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.startTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.periods.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.weekDays
                    .split(',')
                    .map(Number)
                    .some(id =>
                        getWeekNameById(id).toLowerCase().includes(searchTerm.toLowerCase())
                    )
            )
        );
    }, [searchTerm]);

    const UpdateData = (item, EditFlag) => {
        navigate(`/Home/acd406F/${item}/${EditFlag}`);
    }

    const TableHeading = [
        { label: 'SrNo', key: 'id' },
        { label: `Schedule Date`, key: 'scheduleDate' },
        { label: 'Starting Time', value: 'startTime', },
        { label: `No. of periods`, key: 'periods' },
        { label: `Week Days`, key: 'weekDays' },
        { label: 'Edit', value: 'edit', isAction: true },
    ];

    if (loaderOption == true) {
        return (
            <>
                <Loader />
            </>
        )
    }
    else {
        return (
            <div>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '300px' }}>
                    <div className=''>
                        <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/acd406F/0/1")}><AddIcon fontSize="small" /> New Time Slot</button>
                    </div>

                    <div className='row col-sm-4 mb-1' style={{ float: 'right' }}>
                        <div>
                            <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                            />
                        </div>{" "}
                    </div>

                    <div className='table-responsive'>
                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        const weekIds = item.weekDays.split(',').map(Number);
                                        const weekNames = weekIds.map(getWeekNameById);
                                        return (

                                            <tr key={index}>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{index + 1}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{(item.scheduleDate)}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{(item.startTime)}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{(item.periods)}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{weekNames.join(', ')}</td>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <span className='btn btn-primary btn-sm mx-1 p-1'
                                                            onClick={() => UpdateData(item.id, 2)}
                                                            data-bs-toggle="tooltip" >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </span>
                                                    </Tooltip>
                                                </td>


                                            </tr>

                                        )
                                    })}
                            </tbody>
                            <tfoot style={{ padding: "0px" }}>
                                <tr>
                                    <TablePagination
                                        style={{ padding: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        labelRowsPerPage="Rows Per Page :"
                                        classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        )
    }


}

export default ManageTimeSlotTable
