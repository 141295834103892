import { BaseIP, BaseURL, BaseURLMaster, BaseURLAcMaster, dBaseURLAcMaster, dBaseURL } from "./APIURL";

//Account Group Master API Link
export const BaseURLAccountGroupMaster = BaseURLAcMaster + "groupmaster/groupList/";
export const AccountGroupMasterSave = BaseURLAcMaster + "groupmaster/saveAccountGroup/";
export const AccountGroupMasterUpdate = BaseURLAcMaster + "groupmaster/updateAccountGroup/";
export const AccountGroupMasterDelete = BaseURLAcMaster + "groupmaster/deleteAccountGroup/";

//Section get APIURL
export const BaseURLSection = BaseURLMaster + "inst/Sections/";

//Group Code get APIURL
export const getGroupCode = BaseURLAcMaster + "groupmaster/getGroupCode/";

//get groups by group book and group head
//localhost:8080/institute/sss/acMaster/grpHead/getGroupHeadListByGroupTypeAndGroupHeadAndSectionId?groupType=1&groupHead=1&sid=3
export const getGroupHeadListByGroupTypeAndGroupHeadAndSectionId = BaseURL + "acMaster/grpHead/getGroupHeadListByGroupTypeAndGroupHeadAndSectionId";


// /institute/sss/acmaster/groupmaster/

//  @GetMapping("/getGroupCode/{sectionId}")
//  @GetMapping("/groupList/{sectionId}")
