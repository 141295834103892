const PrincipalAccountJSON = [
	{
		MenuType: "dropdown",
		PathName: "#",
		Title: "Master",
		LinkIcon: "dashboard",
		DropDownSubMenu: [
			{
				id: 619,
				SubPath: "/Home/ac619/",
				SubName: "Group Head"
			},
			{
				id: 618,
				SubPath: "/Home/ac618/",
				SubName: "Receipt Payment Group Master"
			},
			{
				id: 601,
				SubPath: "/Home/ac01/",
				SubName: "Account Group"
			},
			{
				id: 602,
				SubPath: "/Home/ac02/",
				SubName: "Account Master"
			},
			{
				id: 603,
				SubPath: "/Home/ac03/",
				SubName: "Narration Master"
			},
		]
	},
	{
		id: 604,
		MenuType: "normal",
		PathName: "/Home/ac04/0",
		Title: "Voucher Entry",
		LinkIcon: "receipt"
	},
	{
		MenuType: "dropdown",
		PathName: "#",
		Title: "Reports",
		LinkIcon: "assignment",
		DropDownSubMenu: [
			{
				id: 615,
				SubPath: "/Home/ar615/",
				SubName: "Account Master"
			},
			{
				id: 606,
				SubPath: "/Home/ar01/",
				SubName: "Account Ledger"
			},
			{
				id: 607,
				SubPath: "/Home/ar05/",
				SubName: "Kird"
			},
			{
				id: 617,
				SubPath: "/Home/ar617/",
				SubName: "Account Summary"
			},
			{
				id: 608,
				SubPath: "/Home/ar02/",
				SubName: "Receipt & Payment Report"
			},
			{
				id: 609,
				SubPath: "/Home/ar03/",
				SubName: "Income & Expendiure"
			},
			{
				id: 610,
				SubPath: "/Home/ar04/",
				SubName: "Balance Sheet"
			},
			{
				id: 611,
				SubPath: "/Home/ar06/",
				SubName: "Daybook"
			}
		]
	},
	{
		MenuType: "dropdown",
		PathName: "#",
		Title: "Certificates",
		LinkIcon: "card_membership",
		DropDownSubMenu: [
			{
				id: 611,
				SubPath: "/Home/acr611/ ",
				SubName: "Certificates -Taslmat "
			},
			{
				id: 612,
				SubPath: "/Home/acr612/",
				SubName: "Certificates - Loan"
			},
			{
				id: 613,
				SubPath: "/Home/acr613/",
				SubName: "Certificates -Anamat"
			},
			{
				id: 614,
				SubPath: "/Home/acr614/",
				SubName: "Certificates - Mudran"
			}
		]
	},
	{
		MenuType: "dropdown",
		PathName: "#",
		Title: "Transaction",
		LinkIcon: "monetization_on",
		DropDownSubMenu: [
			{
				id: 620,
				SubPath: "/Home/act620/ ",
				SubName: "Account Closing Transfer"
			},
		]
	},
	// {
	// 	MenuType: "dropdown",
	// 	PathName: "#",
	// 	Title: "Investment",
	// 	LinkIcon: "savings",
	// 	DropDownSubMenu: [
	// 		{
	// 			id: 622,
	// 			SubPath: "/Home/aci622/ ",
	// 			SubName: "Type Master"
	// 		},
	// 		{
	// 			id: 623,
	// 			SubPath: "/Home/aci623/ ",
	// 			SubName: "Investment Entry"
	// 		},
	// 		{
	// 			id: 624,
	// 			SubPath: "/Home/aci624/ ",
	// 			SubName: "Investment Report"
	// 		},
	// 	]
	// },
	{
		id: 616,
		MenuType: "normal",
		PathName: "/Home/ar616/",
		Title: "Receipt & Chalan",
		LinkIcon: "receipt_long"
	},
];

export default PrincipalAccountJSON;