import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from "axios";
import { CastePostMapping, BaseURLMasterCastes } from "../CommonServices/CasteMasterAPIURL";
import { BaseURLMasterCasteCategory, CasteCategoryPostMapping } from "../CommonServices/CasteCategoryMasterAPIURL";

const AddNewCasteCategory = ({ setCasteCategoryData }) => {
    const [casteCategoryMarathi, setCasteCategoryMarathi] = useState("");
    const [casteCategoryEnglish, setCasteCategoryEnglish] = useState("");
    const [casteCategoryUDise, setCasteCategoryUDise] = useState("");

    const getCasteCategoryData = async () => {
        await axios.get(`${BaseURLMasterCasteCategory}`)
            .then((response) => {
                setCasteCategoryData(response.data);
            })
    }

    const saveBtnClick = async (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        const saveObject = {
            nameMr: casteCategoryMarathi,
            nameEn: casteCategoryEnglish,
            udiseNo: casteCategoryUDise
        };
        await axios.post(`${CasteCategoryPostMapping}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getCasteCategoryData();

                    // get modal
                    const modal = document.getElementById('casteCategoryModal');

                    // change state like in hidden modal
                    modal.classList.remove('show');
                    modal.setAttribute('aria-hidden', 'true');
                    modal.setAttribute('style', 'display: none');

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                    // remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);
                    toast.success("Caste-Category Saved sucessfully.");
                } else {
                    toast.danger("Operation failed.");
                }
            });

        setCasteCategoryMarathi("");
        setCasteCategoryEnglish("");
        setCasteCategoryUDise("");

    };

    return (
        <>
            <div className="modal fade" id="casteCategoryModal" tabIndex="-1" aria-labelledby="religionModalLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                            <h5 className="modal-title" id="religionModalLabel">Add New Caste Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={saveBtnClick}>
                            <div className="modal-body">

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && casteCategoryMarathi.length === 0) ? e.preventDefault() : ""}
                                    value={casteCategoryMarathi}
                                    onChange={(e) => setCasteCategoryMarathi(e.target.value)}
                                    label="Caste-Category Marathi"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && casteCategoryEnglish.length === 0) ? e.preventDefault() : ""}
                                    value={casteCategoryEnglish}
                                    onChange={(e) => setCasteCategoryEnglish(e.target.value)}
                                    label="Caste-Category English"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && casteCategoryUDise.length === 0) ? e.preventDefault() : ""}
                                    value={casteCategoryUDise}
                                    onChange={(e) => setCasteCategoryUDise(e.target.value)}
                                    label="U-Dise No"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" >Save</button>
                                <button type="reset" className="btn btn-danger" onClick={() => { setCasteCategoryUDise(""); setCasteCategoryEnglish(""); setCasteCategoryMarathi(""); }} data-bs-dismiss="modal">Cancle</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AddNewCasteCategory