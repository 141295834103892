import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import PrintIcon from '@mui/icons-material/Print';

const SansthaDetailEntryReportTable = ({ futureYear, data, setData, filteredData, setFilteredData,
    nextYrTotalBudget, previousYrTotalBudget, actualDecTotalBudget, accountName, printSansthReport }) => {
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div className="">
                        <form>
                            <div className="row mt-5 mb-1" >
                                <div className="">
                                    <div style={{ float: "right" }}>
                                        <button
                                            style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                            type='button'
                                            onClick={() => printSansthReport()}
                                            className='btn btn-sm mb-2'
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {/* <span className='mt-3' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}>{schoolNm}</span><br /> */}
                                        <span className='mt-2' style={{ fontSize: "16px", color: "#DC2C0C", fontWeight: "600" }}>{accountName}</span>
                                    </div>
                                </div>
                            </div>

                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>

                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">तपशील (जमा)</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">{parseInt(authUser.sessionYear)}-{futureYear} चा अंदाज</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">31/12/{parseInt(authUser.sessionYear)} अखेर</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `center` }} scope="col">{parseInt(authUser.sessionYear) + 1}-{parseInt(futureYear) + 1} चा अंदाज</th>

                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            filteredData.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.accNameMr}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "160px" }}>{(item.budgetLastYear).toFixed('2')}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "160px" }}>{(item.actualBudget).toFixed('2')}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", width: "160px" }}>{(item.budgetNextYear).toFixed('2')}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right" }}>एकूण</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right" }}>{(previousYrTotalBudget)}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right" }}>{(actualDecTotalBudget)}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "600", textAlign: "right" }}>{(nextYrTotalBudget)}</td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}

export default SansthaDetailEntryReportTable