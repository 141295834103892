import { BaseIP, BaseURL, BaseURLMaster, dBaseURLMaster } from "./APIURL";

export const BaseURLEmployeeMaster = BaseURLMaster + "employee/";
export const EmployeeMasterGetMapping = BaseURLEmployeeMaster + "getEmployeeList/";
export const EmployeeMasterPostMapping = BaseURLEmployeeMaster + "saveEmployee/";
export const EmployeeMasterPutMapping = BaseURLEmployeeMaster + "updateEmployee/";

//APIURl /getEmployeeData/{id}
export const getEmployeeDataById = BaseURLEmployeeMaster + "getEmployeeData/";

//Section get APIURL
export const getSectionURL = BaseURLMaster + "inst/Sections/";

//updateEmployee
// /getBranchWiseEmployeeList
// @RequestParam("bid")  Long branchId
export const EmployeeMasterBranchWiseGetData = BaseURLEmployeeMaster + "getBranchWiseEmployeeList";
//export const EmployeeMasterBranchWiseGetData = "http://192.168.1.102:8080/institute/sss/master/employee/getBranchWiseEmployeeList";

export const empImagePath = BaseIP + "/zData/emp_img/"

// /localhost:8080/institute/sss/master/employee/deleteEmployeeImage?id=735
export const removeEmployeeImage = BaseURLEmployeeMaster + "deleteEmployeeImage";


//Seniority API
//http://192.168.195.102:8081/institute-sss/institute/sss/master/employeeSeniority/getEmployeeSeniorityList?sid=3&bid=1
export const getEmployeeListForSeniorityAPIURL = BaseURLMaster + "employeeSeniority/getEmployeeSeniorityList";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/employeeSeniority/saveEmployeeSeniorityData
export const saveEmployeeListForSeniorityAPIURL = BaseURLMaster + "employeeSeniority/saveEmployeeSeniorityData";

