import { BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

//get vendor master list (table)
// GetMapping
// localhost:8080/institute/sss/library/master/getAllVendorMasterList?branchId=1
export const getVendorMaster = BaseURL + "library/master/getAllVendorMasterList";

// get single vendor master data
// GetMapping
// localhost:8080/institute/sss/library/master/getVendorMasterData?id=1
export const getVendorMasterById = BaseURL + "library/master/getVendorMasterData";

// save Vendor master
// PostMapping
// localhost:8080/institute/sss/library/master/saveVendorMaster
// {
//     "vendorName": "nachiket",
//     "address": "sangli",
//     "city": 1,
//     "mobileNo": "+78789898",
//     "email": "nachiket@gmail.com",
//     "branchId": 1
// }
export const saveVendorMaster = BaseURL + "library/master/saveVendorMaster";

// update vendor master
// PutMapping
// localhost:8080/institute/sss/library/master/updateVendorMaster
// {
//     "id": 2,
//     "vendorName": "nachiket kulkarni",
//     "address": "ram mandir,sangli",
//     "city": 1,
//     "mobileNo": "+666666",
//     "email": "nachiketkulkarni@gmail.com",
//     "branchId": 1
// }
export const updateVendorMaster = BaseURL + "library/master/updateVendorMaster";

// delete vendor master
// DeleteMapping
// localhost:8080/institute/sss/library/master/deleteVendorMaster?id=2
export const deleteVendorMaster = BaseURL + "library/master/deleteVendorMaster";

