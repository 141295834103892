import { BaseURL } from "../../CommonServices/APIURL";

//http://192.168.29.102:8081/institute-sss/institute/sss/hostel/transaction/getStudentLeaveList?year=2023&bid=36
export const getStudentListForReportingAPIURL = BaseURL + "hostel/transaction/getStudentLeaveList";

//http://192.168.29.102:8081/institute-sss/institute/sss/hostel/transaction/getStudentLeaveMasterList?regNo=1142&year=2023&bid=36
export const getLeaveListByStudAPIURL = BaseURL + "hostel/transaction/getStudentLeaveMasterList";

//http://192.168.29.102:8081/institute-sss/institute/sss/hostel/transaction/saveStudentReporting
export const saveStudentReportingAPIURL = BaseURL + "hostel/transaction/saveStudentReporting";

//http://192.168.29.102:8081/institute-sss/institute/sss/hostel/transaction/getAllStudentReportingList?year=2023&bid=36
export const getStudeReportingTableDataAPIURL = BaseURL + "hostel/transaction/getAllStudentReportingList";

//http://192.168.29.102:8081/institute-sss/institute/sss/hostel/transaction/deleteStudentReporting?id=1
export const deleteStudReportingAPIURL = BaseURL + "hostel/transaction/deleteStudentReporting";

//report
//localhost:8081/institute-sss/institute/sss/hostel/transaction/reports/getStudentReportingReport?langId=1&option=3&classId=10&divId=37&regNo=0&dateFrom=2024-01-01&dateTo=2024-08-12&year=2023&bid=36
export const printStudentReportingAPIURL = BaseURL + "hostel/transaction/reports/getStudentReportingReport";