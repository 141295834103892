import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitleLabel from '../../CommonComponent/TitleLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../CommonComponent/Loader';
import { getStudentListByBranchAPIURL } from '../../CommonServices/StudentRegistrationAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getMasterialMasterAPIURL } from '../Services/MaterialMasterAPIURL';
import { printMaterialReportAPIURL } from '../Services/HostelReportAPIURL';
import { getStudentListForHostelByBid } from '../Services/MaterialAllocationAPIURL';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const MaterialReport = () => {
    const titleId = 'Material Report';
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState('');
    const [option, setOption] = useState(1);
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [materialData, setMaterialData] = useState([]);
    const [materialObj, setMaterialObj] = useState(null);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && studentData.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (authUser.branchId != null && authUser.sessionYear != "") {

            await axios.get(`${getStudentListForHostelByBid}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    const presentStudentList = res.data.filter(e => e.currentStatus == 0)
                    setStudentData(presentStudentList);

                    const objIds = presentStudentList.reduce((a, { currentClassId, currentDivisionId, currentClassMr, currentClassEn,
                        currentDivisionMr, currentDivisionEn, branchId, sessionYear, sectionId }) => {
                        a[currentDivisionId] = a[currentDivisionId] || { currentDivisionId }
                        return {
                            ...a, ...{
                                [currentDivisionId]: {
                                    currentDivisionId,
                                    currentClassId,
                                    currentClassMr,
                                    currentClassEn,
                                    currentDivisionMr,
                                    currentDivisionEn,
                                    branchId,
                                    sessionYear,
                                    sectionId
                                }
                            }
                        }
                    }, {})

                    const result = Object.values(objIds)
                    //console.log(JSON.stringify(result))
                    setClassData(result)
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get student list err' + err)
                })
            //console.log(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}`)
            // await axios.get(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}`)
            //     .then(res => {
            //         const presentStudentList = res.data.filter(e => e.currentStatus == 0)
            //         setStudentData(presentStudentList);

            //         const objIds = presentStudentList.reduce((a, { currentClassId, currentDivisionId, currentClassMr, currentClassEn,
            //             currentDivisionMr, currentDivisionEn, branchId, sessionYear, sectionId }) => {
            //             a[currentDivisionId] = a[currentDivisionId] || { currentDivisionId }
            //             return {
            //                 ...a, ...{
            //                     [currentDivisionId]: {
            //                         currentDivisionId,
            //                         currentClassId,
            //                         currentClassMr,
            //                         currentClassEn,
            //                         currentDivisionMr,
            //                         currentDivisionEn,
            //                         branchId,
            //                         sessionYear,
            //                         sectionId
            //                     }
            //                 }
            //             }
            //         }, {})

            //         const result = Object.values(objIds)
            //         //console.log(JSON.stringify(result))
            //         setClassData(result)
            //     }).catch(err => {
            //         console.log('get student list err' + err)
            //     })
        }

        await axios.get(getMasterialMasterAPIURL)
            .then(res => {
                setMaterialData(res.data);
            }).catch(err => {
                console.log('get material err' + err)
            })
    }

    const getMaterialReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        await axios.get(`${printMaterialReportAPIURL}?bid=${authUser.branchId}&year=${authUser.sessionYear}&langId=${authUser.branchMedium}&classId=${(option == 3) ? classObj.currentClassId : 0}&divId=${(option == 3) ? classObj.currentDivisionId : 0}&regNo=${(option == 2) ? studentObj.regCode : 0}&optionId=${option}&materialId=${(option == 4) ? materialObj.id : 0} `)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn('Data not found.')
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log('print material report' + err);
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            {/* Form Data start */}
                            <form onSubmit={getMaterialReport}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                <Select
                                                    size='small'
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Select Option"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    onChange={(e) => {
                                                        setOption(e.target.value);
                                                        setStudentObj(null);
                                                        setClassObj(null);
                                                        setMaterialObj(null);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                                >
                                                    <MenuItem value={1}>All</MenuItem>
                                                    <MenuItem value={2}>Student Wise</MenuItem>
                                                    <MenuItem value={3}>Class Wise</MenuItem>
                                                    <MenuItem value={4}>Material Wise</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {(() => {
                                        if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                                        <div className='col-sm-4'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                open={open}
                                                                onOpen={() => {
                                                                    setOpen(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen(false);
                                                                }}
                                                                loading={loading}
                                                                options={studentData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    setStudentObj(newValue);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                                                value={studentObj}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                                renderOption={(props, option) => (
                                                                    <div className='' {...props}>
                                                                        <div className=''>
                                                                            {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                                                        </div>

                                                                    </div>
                                                                )}
                                                                size="small"
                                                                renderInput={params => (
                                                                    <TextField {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                        margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required={(option == 2) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 3) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                                                        <div className='col-sm-3'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                open={open}
                                                                onOpen={() => {
                                                                    setOpen(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen(false);
                                                                }}
                                                                loading={loading}
                                                                options={classData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    setClassObj(newValue);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? classObj : ""}
                                                                value={classObj}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.currentClassMr + "  " + option.currentDivisionMr : option.currentClassEn + "  " + option.currentDivisionEn}
                                                                renderOption={(props, option) => (
                                                                    <div className='' {...props}>
                                                                        <div className=''>
                                                                            {(authUser.branchMedium == 1) ? option.currentClassMr + "  " + option.currentDivisionMr : option.currentClassEn + "  " + option.currentDivisionEn}
                                                                        </div>

                                                                    </div>
                                                                )}
                                                                size="small"
                                                                renderInput={params => (
                                                                    <TextField {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            // endAdornment: (
                                                                            //     <React.Fragment>
                                                                            //         {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                            //         {params.InputProps.endAdornment}
                                                                            //     </React.Fragment>
                                                                            // ),
                                                                        }}
                                                                        margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required={(option == 3) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 4) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Material :</label>
                                                        <div className='col-sm-4'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                open={open}
                                                                onOpen={() => {
                                                                    setOpen(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen(false);
                                                                }}
                                                                loading={loading}
                                                                options={materialData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    setMaterialObj(newValue);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? materialObj : ""}
                                                                value={materialObj}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => option.materialName}
                                                                renderOption={(props, option) => (
                                                                    <div className='' {...props}>
                                                                        <div className=''>
                                                                            {option.materialName}
                                                                        </div>

                                                                    </div>
                                                                )}
                                                                size="small"
                                                                renderInput={params => (
                                                                    <TextField {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            // endAdornment: (
                                                                            //     <React.Fragment>
                                                                            //         {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                            //         {params.InputProps.endAdornment}
                                                                            //     </React.Fragment>
                                                                            // ),
                                                                        }}
                                                                        margin='dense' label="Select Material" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required={(option == 4) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-1">
                                            <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default MaterialReport