import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/accounts/openingBalance/getAccountClosingTransferList?acYear=2022&sid=3&bid=1
export const getAccClosingTransferAPIURL = BaseURL + "accounts/openingBalance/getAccountClosingTransferList";

//localhost:8080/institute/sss/accounts/openingBalance/saveAccountClosingTransferList
// [
//     {
//         "groupId": 46,
//         "accountCode": 52,
//         "openingBalance": 105584.0,
//         "sessionYear": 2022,
//         "sectionId": 3,
//         "branchId": 1
//     }
// ]
export const saveAccountClosingTransferAPIURL = BaseURL + "accounts/openingBalance/saveAccountClosingTransferList";