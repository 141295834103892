import React from 'react';

const DeleteLcModal = (props) => {
    return (
        <div className="modal fade" id="deleteLcModal" tabIndex="-1" aria-labelledby="deleteLcModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header mt-3">
                        <h4 className="modal-title" id="deleteLcModalLabel">Are you sure you want to delete this Student LC with Reg No {props.deleteId}?</h4>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button"
                            onClick={() => {
                                
                                props.setData([]);
                                props.setFilteredData([]);
                                props.deleteLcModal(props.deleteId);
                            }}
                            className="btn btn-danger" data-bs-dismiss="modal"
                        >Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteLcModal