import React, { useState, useEffect } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getMemberTypeDataAPIURL, getMemberTypeDataById, saveMemberTypeDataAPIURL, updateMemberTypeDataAPIURL } from '../LibraryServices/MemberTypeMasterAPIURL';
import { BaseURLMaster, BaseURL } from '../../CommonServices/APIURL';
import { getStudentList } from '../../CommonServices/LeavingCertificateAPIURL';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const memberTypeJSON = [
    {
        id: 1,
        memberType: "Staff",
        getMapping: "employee/getBranchWiseEmployeeList",
        sectionId: ""
    },
    {
        id: 2,
        memberType: "Secondary Students",
        getMapping: "administration/LC/getStudentLcBaseData",
        sectionId: 3
    },
    {
        id: 3,
        memberType: "Higher-secondary Students",
        getMapping: "administration/LC/getStudentLcBaseData",
        sectionId: 4
    },
]

const MemberTypeMaster = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    let { mtId, EditFlag } = useParams();
    const [titleId, setTitleId] = useState("");

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "memberTypeLibrarySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "memberTypeLibraryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [memberTypeName, setMemberTypeName] = useState(null);
    const [memberType, setMemberType] = useState("");
    const [barCodeLabel, setBarCodeLabel] = useState("");
    const [bookReservedDays, setBookReservedDays] = useState('');
    const [bookMaxCount, setBookMaxCount] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);


    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setTitleId("Member Type Master");
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setTitleId("Update Member Type");
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        console.log(`${getMemberTypeDataById}?id=${mtId}`)
        await axios.get(`${getMemberTypeDataById}?id=${mtId}`)
            .then(res => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setId(res.data.id);
                setMemberType(res.data.memberType);
                setMemberTypeName(res.data.memberTypeName);
                setBarCodeLabel(res.data.barcodeId);
                setBookReservedDays(res.data.bookReservedDays);
                setBookMaxCount(res.data.bookMaxCount);
                setLoaderOption(false);


            }).catch(err => {
                console.log(err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }


    const getApiData = async () => {
        if (authUser.branchId != null) {
            console.log(`${getMemberTypeDataAPIURL}?branchId=${authUser.branchId}`)
            await axios.get(`${getMemberTypeDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                })
        }
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/l812/')
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "memberType": memberType,
            "memberTypeName": memberTypeName,
            "bookReservedDays": bookReservedDays,
            "bookMaxCount": bookMaxCount,
            "barcodeId": barCodeLabel,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveMemberTypeDataAPIURL, saveObj)
            .then(res => {
                if (res.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Member type with this data is already exists.`)
                }
                else if (res.data == "TypeExists") {
                    setLoaderOption(false);
                    toast.warn(`This type is already allocated.`)
                }
                else if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.")
                }
            }).catch(err => {
                console.log(err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "memberType": memberType,
            "memberTypeName": memberTypeName,
            "bookReservedDays": bookReservedDays,
            "bookMaxCount": bookMaxCount,
            "barcodeId": barCodeLabel,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(updateObj))
        await axios.put(updateMemberTypeDataAPIURL, updateObj)
            .then(res => {
                if (res.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Member type with this data is already exists.`)
                }
                else if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Successfully.")
                }
            }).catch(err => {
                console.log(err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.memberTypeLibrarySaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.memberTypeLibraryUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member Type Name :</label>
                                    <div className="col-sm-5">
                                        <TextField
                                            fullWidth
                                            required
                                            onKeyDown={(e) => (e.key === " " && memberTypeName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? memberTypeName : ""}
                                            label="Member Type Name"
                                            value={memberTypeName}
                                            onChange={(e) => {
                                                setMemberTypeName(e.target.value);
                                                // setMemberType('');
                                                // setBarCodeLabel("");
                                                // setBookReservedDays('');
                                                // setBookMaxCount('');
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Type :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Type</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                readOnly={(EditFlag == 2) ? true : false}
                                                margin='dense'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={memberType}
                                                label="Type"
                                                onChange={(e) => {
                                                    setMemberType(e.target.value);
                                                    setBarCodeLabel("");
                                                    setBookReservedDays('');
                                                    setBookMaxCount('');
                                                }}
                                            >

                                                <MenuItem value={1}>Staff</MenuItem>
                                                <MenuItem value={2}>Secondary Student</MenuItem>
                                                <MenuItem value={3}>Higher-Secondary Student</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {/* <div className="row my-2" hidden={(memberType == '') ? true : false}>
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member :</label>
                                    <div className="col-sm-5"> */}


                                {/* <Autocomplete
                                            id="combo-box-demo"
                                            open={open}
                                            onOpen={() => {
                                                setOpen(true);
                                            }}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            loading={loading}
                                            options={getMemberData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            onChange={(event, newValue) => {
                                                setMemberTypeName(newValue);
                                                setBarCodeLabel("");
                                                setBookReservedDays('');
                                                setBookMaxCount('');
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? memberTypeName : ""}
                                            value={memberTypeName}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={(option) => {
                                                if (memberType == 1) {
                                                    return (authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn
                                                }
                                                else {
                                                    return (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + " " + option.currentClassMr : option.regCode + " - " + option.studentNameEn + " " + option.curresntClassEn
                                                }
                                            }}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    margin='dense' label={"Select" + " " + memberName} autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required />
                                            )}
                                        />
                                    </div>

                                </div> */}

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Book Reserved Days :</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            type="number"
                                            onKeyDown={(e) => (e.key === " " && bookReservedDays.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? bookReservedDays : ""}
                                            label="Book Reserved Days"
                                            value={bookReservedDays}
                                            onChange={(e) => setBookReservedDays(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Book Max Count :</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            type="number"
                                            onKeyDown={(e) => (e.key === " " && bookMaxCount.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? bookMaxCount : ""}
                                            label="Book Max Count"
                                            value={bookMaxCount}
                                            onChange={(e) => setBookMaxCount(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Barcode Id :</label>
                                    <div className="col-sm-3">
                                        <TextField
                                            fullWidth
                                            required
                                            onKeyDown={(e) => (e.key === " " && barCodeLabel.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? barCodeLabel : ""}
                                            label="Barcode Id"
                                            value={barCodeLabel}
                                            onChange={(e) => setBarCodeLabel(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} style={{ margin: "0px 4px" }}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger' style={{ margin: "0px 4px" }}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default MemberTypeMaster