import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useNavigate } from 'react-router-dom';
import { deleteInventoryVendorMasterAPIURL, getInventoryVendorMasterAPIURL } from '../Services/InventoryVendorMasterAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const InventoryVendorMasterTable = () => {
    const titleId = "Vendor Master"
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [loaderOption, setLoaderOption] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getInventoryVendorMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Get inventory vendor master err " + err)
                })
        }
    }

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const UpdateData = (item, EditFlag) => {
        navigate(`/Home/sm1303F/${item}/${EditFlag}`)
    }

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = async (id) => {
        setLoaderOption(true);
        await axios.delete(`${deleteInventoryVendorMasterAPIURL}?id=${id}`)
            .then(res => {
                if (res.data == "DELETED") {
                    getData();
                    setLoaderOption(false);
                    toast.success("Deleted Successfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Delete failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Inv. vendor master delete " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.vendorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.contact.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.gstNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.panNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Vendor Name`, key: 'vendorName' },
        { label: `Email`, key: 'email' },
        { label: `Contact`, key: 'contact' },
        { label: `GST No.`, key: 'gstNo' },
        { label: `PAN No.`, key: 'panNo' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }
    ];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <div className=''>
                                <button className='btn btn-primary btn-sm mb-3' style={{ float: 'left' }} onClick={() => navigate(`/Home/sm1303F/0/1`)} ><AddIcon fontSize="small" />New Vendor</button>
                            </div>

                            <div className='row mt-2' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>

                            <div className='table-responsive'>
                                <table className="table table-bordered">
                                    <thead className="table-Default">
                                        <tr>
                                            {TableHeading.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .reverse().map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "80px" }}>{item.id}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.vendorName}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.email}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.contact}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.gstNo}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.panNo}</td>
                                                                <td style={{ width: "80px" }}>
                                                                    <Tooltip title="Edit">
                                                                        <button className='btn btn-primary btn-sm mx-1 p-1'
                                                                            onClick={() => UpdateData(item.id, 2)} data-bs-toggle="tooltip"
                                                                        >
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </button>
                                                                    </Tooltip>
                                                                </td>
                                                                <td style={{ width: "80px" }}>
                                                                    <Tooltip title="Delete">
                                                                        <button className='btn btn-danger btn-sm mx-1 p-1'
                                                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                            onClick={() => setDeleteId(item.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </button>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })}
                                    </tbody>
                                    <tfoot style={{ padding: "0px" }}>
                                        <tr>
                                            <TablePagination
                                                style={{ padding: "0px" }}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                count={data.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onRowsPerPageChange={onRowsPerPageChange}
                                                onPageChange={onPageChange}
                                                labelRowsPerPage="Rows Per Page :"
                                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </>
            }
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default InventoryVendorMasterTable