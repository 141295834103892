import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";



export const getStudentAadharList=BaseURL+"administration/getStudentAadharAllocationData";

export const saveStudentAadharList=BaseURL+"administration/saveStudentAadhar";

// /institute/sss/administration/

// GetMapping  /getStudentAadharAllocationData

// PostMapping /saveStudentAadhar