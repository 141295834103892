import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import moment from 'moment';
import PrintIcon from '@mui/icons-material/Print';

const BudgetCategoryDetailReportTable = ({ setData, setFilteredData, data, filteredData, side, budgetSide,
    previousYrTotalBudget, actualDecTotalBudget, actualMarchTotalBudget, nextYrTotalBudget, schoolNm, totalActualBudget,
    printAllBudgetEntryReport }) => {
    const authUser = useSelector((state) => state.user.value);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }



    return (
        <>
            <div>

                {(() => {
                    //if (optionId == 2) {
                    return (
                        <>
                            <div className="row mt-5 mb-2" >
                                <div className="">
                                    <span className='mt-3' style={{ float: "left", fontSize: "17px", color: "#DC2C0C", fontWeight: "600" }}>{schoolNm}</span>
                                    <div style={{ float: "right" }}>
                                        <button
                                            style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                            type='button'
                                            onClick={() => printAllBudgetEntryReport()}
                                            className='btn btn-sm mb-2'
                                        //disabled={btnDisabled}
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='table-responsive mb-5' style={{ maxHeight: "510px" }}>
                                <table className="table table-bordered">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4", position: "sticky", zIndex: "2", top: "0", backgroundColor: "#EAECEE" }}>

                                        <tr>
                                            {side.map((item, index) => {
                                                return (
                                                    <th rowspan={item.rowspan} colspan={item.colspan} style={{ fontSize: "15px", fontWeight: "600", textAlign: "center" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            {budgetSide.map((item, index) => {
                                                //console.log(item.label)
                                                return (
                                                    <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            filteredData.map((item, index) => {
                                                //console.log(item.accNameMr)
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            {/* <td style={{ fontSize: "14px", fontWeight: "500", width: "100px" }}>{item.categoryNameMr}</td> */}
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.accNameMr}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "160px", textAlign: "right" }}>{(item.budgetLastYear).toFixed(2)}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "160px", textAlign: "right" }}>{(item.actualBudget1).toFixed(2)}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "160px", textAlign: "right" }}>{(item.actualBudget2).toFixed(2)}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "160px", textAlign: "right" }}>{(parseFloat(item.actualBudget1) + parseFloat(item.actualBudget2)).toFixed(2)}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "160px", textAlign: "right" }}>{(item.budgetNextYear).toFixed(2)}</td>
                                                        </tr>

                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>

                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>एकूण</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{previousYrTotalBudget}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{actualDecTotalBudget}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{actualMarchTotalBudget}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{totalActualBudget}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{nextYrTotalBudget}</td>
                                        </tr>

                                    </tfoot>
                                </table>
                            </div>
                        </>
                    )
                })()}

            </div>
        </>
    )
}

export default BudgetCategoryDetailReportTable