import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../../CommonComponent/Loader';
import { printGenderWiseEndowmentreportAPIURL } from '../Services/EndowmentReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const GenderWiseEndowmentReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Gender Wise Endowment";

    const [loaderOption, setLoaderOption] = useState(false);
    const [gender, setGender] = useState(3);

    const getGenderWiseEndowment = async () => {
        setLoaderOption(true);
        await axios.get(`${printGenderWiseEndowmentreportAPIURL}?sessionYear=${authUser.sessionYear}&genderId=${gender}`)
            .then(res => {
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found with this gender.")
                }
                else if (res.data != null) {
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                    setLoaderOption(false);
                }
            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                            <div className='row my-2'>
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Gender:</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth >
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Gender</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={gender}
                                            margin='dense'
                                            label="Select Gender"
                                            onChange={(e) => {
                                                setGender(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={1}>Male / Female</MenuItem>
                                            <MenuItem value={3}>Male</MenuItem>
                                            <MenuItem value={2}>Female</MenuItem>

                                        </Select>
                                    </FormControl>

                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-1">
                                    <button type="button" onClick={() => getGenderWiseEndowment()} className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default GenderWiseEndowmentReport