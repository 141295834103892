import React, { useEffect, useState } from 'react';
import NavItem from './MenuItems/NavItem';
import DropdownNavItem from './MenuItems/DropdownNavItem';
import MegaMenuNavItem from './MenuItems/MegaMenuNavItem';
import TestingMenuId from './Service/TestingMenuId';
import { useSelector } from 'react-redux';

const TestMenu = ({ sendingMenu, refreshMenu, showModal, setShowModal, handleMenuClick }) => {
    const authUser = useSelector((state) => state.user.value);

    const filteredJSON = sendingMenu.map((menu) => {
        if (menu.MenuType === "megamenu") {
            const filteredMegaMenuDropDown = menu.MegaMenuDropDown.map((megaMenu) => {
                const filteredMegaMenuSubItems = megaMenu.MegaMenuSubItems.filter(
                    (subItem) => subItem.id !== 314 && subItem.id !== 321 && subItem.id !== 312
                );
                return { ...megaMenu, MegaMenuSubItems: filteredMegaMenuSubItems };
            });
            return { ...menu, MegaMenuDropDown: filteredMegaMenuDropDown };
        }
        return menu;
    });

    const [newFilteredJson, setNewFiltredJson] = useState([]);
    const [newSendingMenuJson, setNewSendingMenuJson] = useState([]);
    // console.log(JSON.stringify(newSendingMenuJson))



    useEffect(() => {
        getMenus();

    }, [authUser, sendingMenu])

    const getMenus = () => {
        if (authUser.feeType == 2) {
            const filteredJSON1 = filteredJSON.map((menu) => {
                if (menu.MenuType === "megamenu") {
                    const filteredMegaMenuDropDown = menu.MegaMenuDropDown.map((megaMenu) => {
                        const filteredMegaMenuSubItems = megaMenu.MegaMenuSubItems.filter(
                            (subItem) => subItem.id !== 316
                        );
                        return { ...megaMenu, MegaMenuSubItems: filteredMegaMenuSubItems };
                    });
                    return { ...menu, MegaMenuDropDown: filteredMegaMenuDropDown };
                }
                return menu;
            });
            setNewFiltredJson(filteredJSON1)

            const filteredJSON2 = sendingMenu.map((menu) => {
                if (menu.MenuType === "megamenu") {
                    const filteredMegaMenuDropDown = menu.MegaMenuDropDown.map((megaMenu) => {
                        const filteredMegaMenuSubItems = megaMenu.MegaMenuSubItems.filter(
                            (subItem) => subItem.id !== 316
                        );
                        return { ...megaMenu, MegaMenuSubItems: filteredMegaMenuSubItems };
                    });
                    return { ...menu, MegaMenuDropDown: filteredMegaMenuDropDown };
                }
                return menu;
            });
            setNewSendingMenuJson(filteredJSON2)
        }
        else {
            const filteredJSON1 = filteredJSON.map((menu) => {
                if (menu.MenuType === "megamenu") {
                    const filteredMegaMenuDropDown = menu.MegaMenuDropDown.map((megaMenu) => {
                        const filteredMegaMenuSubItems = megaMenu.MegaMenuSubItems.filter(
                            (subItem) => subItem.id !== 326
                        );
                        return { ...megaMenu, MegaMenuSubItems: filteredMegaMenuSubItems };
                    });
                    return { ...menu, MegaMenuDropDown: filteredMegaMenuDropDown };
                }
                return menu;
            });
            setNewFiltredJson(filteredJSON1)

            const filteredJSON2 = sendingMenu.map((menu) => {
                if (menu.MenuType === "megamenu") {
                    const filteredMegaMenuDropDown = menu.MegaMenuDropDown.map((megaMenu) => {
                        const filteredMegaMenuSubItems = megaMenu.MegaMenuSubItems.filter(
                            (subItem) => subItem.id !== 326
                        );
                        return { ...megaMenu, MegaMenuSubItems: filteredMegaMenuSubItems };
                    });
                    return { ...menu, MegaMenuDropDown: filteredMegaMenuDropDown };
                }
                return menu;
            });
            setNewSendingMenuJson(filteredJSON2)
        }
    }





    return (
        <>

            <ul className="nav page-navigation" style={{ marginBottom: "6px" }}>
                {
                    ((authUser.branchId == 50 || authUser.sessionYear <= 2022) ? newSendingMenuJson : newFilteredJson).map((items, index) => {

                        if (items.MenuType === "normal") {
                            return (
                                <NavItem
                                    LinkIcon={items.LinkIcon}
                                    Title={items.Title}
                                    PathName={items.PathName}
                                    refreshMenu={refreshMenu}
                                    key={index}
                                    setShowModal={setShowModal}
                                    handleMenuClick={handleMenuClick}
                                />
                            );
                        }
                        else if (items.MenuType === "dropdown") {
                            return (
                                <DropdownNavItem
                                    LinkIcon={items.LinkIcon}
                                    Title={items.Title}
                                    PathName={items.PathName}
                                    DropDownSubMenu={items.DropDownSubMenu}
                                    refreshMenu={refreshMenu}
                                    key={index}
                                    handleMenuClick={handleMenuClick}
                                />
                            );
                        }
                        else if (items.MenuType === "megamenu") {
                            return (
                                <MegaMenuNavItem
                                    LinkIcon={items.LinkIcon}
                                    Title={items.Title}
                                    PathName={items.PathName}
                                    id={items.id}
                                    MegaMenuDropDown={items.MegaMenuDropDown}
                                    refreshMenu={refreshMenu}
                                    key={index}
                                    handleMenuClick={handleMenuClick}
                                />
                            );
                        }
                    })
                }

            </ul>

        </>
    )
}

export default TestMenu;
