const budgetJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/BudgetDashboard",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Transaction",
        LinkIcon: "monetization_on",
        DropDownSubMenu: [
            {
                id: 1007,
                SubPath: "/Home/bt1007/",
                SubName: "Budget Entry",
            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Report",
        LinkIcon: "assignment",
        DropDownSubMenu: [
            {
                id: 1010,
                SubPath: "/Home/br1010/",
                SubName: "Budget Report",
            },
        ]
    },
    {
        MenuType: "normal",
        PathName: "/Home/b1011/",
        Title: "Budget No. & Date",
        LinkIcon: "event"
    },

]

export default budgetJSON;