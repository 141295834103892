import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

// localhost:8080/institute/sss/service/saveModuleData

// id
// moduleName

export const saveModuleDataAPIURL=BaseURL+"service/saveModuleData/";


//localhost:8080/institute/sss/service/deleteModuleData?id=1
export const deleteModuleDataAPIURL=BaseURL+"service/deleteModuleData"


//localhost:8080/institute/sss/service/getAllModuleData
export const getAllModuleDataAPIURL=BaseURL+"service/getAllModuleData"

//localhost:8080/institute/sss/service/updateModuleData
export const updateModuleDataAPIURL=BaseURL+"service/updateModuleData/"