import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TitleLabel from "../../CommonComponent/TitleLabel";
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { ClassMasterGetMapping } from '../../CommonServices/ClassMasterAPIURL';
import { getDivisionByClassId } from '../../CommonServices/DivisionMasterAPIURL';
import { BaseURLMasterCastes } from '../../CommonServices/CasteMasterAPIURL';
import { SubCasteGetMapping } from '../../CommonServices/SubCasteMasterAPIURL';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { BaseURLMasterMinorities } from '../../CommonServices/MinorityMasterAPIURL';
import { StreamMasterGetMapping } from '../../CommonServices/StreamMasterAPIURL';
import admitedTypeData from '../../CommonComponent/LocalJSON/AdmitedTypeData';
import {
    StudentRegisterPostMapping, newRegCode, getStudentDataByRegNo, updateStudentMasterData,
    getClassData, getDivisionData, getOldSchool, getStudentList, chkAllocatedStudentAPIURL
} from '../../CommonServices/StudentRegistrationAPIURL';
import { CasteCategoryAPIURL, MotherToungMasterGetMapping, NationalityMasterGetMapping, ReligionMasterGetMapping } from '../../CommonServices/StudentRegistrationAPIURL';
import Loader from "../../CommonComponent/Loader";
import moment from 'moment';
import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import { getCityData } from '../../CommonServices/InstituteMasterAPIURL';

// Common Components
import AddNewReligionModal from "../../CommonComponent/AddNewReligionModal";
import AddNewCaste from "../../CommonComponent/AddNewCaste";
import AddNewSubCaste from "../../CommonComponent/AddNewSubCaste";
import AddNewCasteCategory from "../../CommonComponent/AddNewCasteCategory";
import AddNewMinority from "../../CommonComponent/AddNewMinority";
import AddNewNationalityModal from "../../CommonComponent/AddNewNationalityModal";
import AddNewMotherTongueModal from "../../CommonComponent/AddNewMotherTongueModal";


const MasterFormStudent = () => {
    //const titleId = "Student Registration";
    const [titleId, setTitleId] = useState("");
    const authUser = useSelector((state) => state.user.value);
    let { rno, EditFlag } = useParams();
    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "studentSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "studentUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const navigate = useNavigate();
    let { formid } = useParams();

    const [id, setId] = useState();
    const [religionData, setReligionData] = useState([]);
    const [casteData, setCasteData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [subCasteData, setSubCasteData] = useState([]);
    const [minorityData, setMinorityData] = useState([]);
    const [nationalityData, setNationalityData] = useState([]);
    const [casteCategoryData, setCasteCategoryData] = useState([]);
    const [motherToungData, setMotherToungData] = useState([]);
    //console.log(motherToungData[0])
    const [instituteData, setInstituteData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [DivisionData, setDivisionData] = useState([]);
    const [currentStremeData, setCurrentStremeData] = useState([]);
    const [oldSchoolData, setoldSchoolData] = useState([]);
    //console.log(JSON.stringify(oldSchoolData))



    let sectionId = authUser.deptId;
    let secessionYear = authUser.sessionYear;
    let branchedId = authUser.branchId;
    //console.log(secessionYear + "---------" + sectionId + "======" + branchedId)

    //form data variable
    const [regNoShow, setRegNoShow] = useState(true);
    const [regNo, setRegNo] = useState([]);
    const [gender, setGender] = useState(1);
    const [leftSchool, setLeftSchool] = useState(0);
    const [aadharNo, setAadharNo] = useState("");
    const [saralNo, setSaralNo] = useState("");
    const [fnameMr, setFnameMr] = useState("");
    const [fnameEn, setFnameEn] = useState("");
    const [mnameMr, setMnameMr] = useState("");
    const [mnameEn, setMnameEn] = useState("");
    const [lnameMr, setLnameMr] = useState("");
    const [lnameEn, setLnameEn] = useState("");
    const [motherNameMr, setMotherNameMr] = useState("");
    const [motherNameEn, setMotherNameEn] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [religionId, setReligionId] = useState([]);
    const [religionObj, setReligionObj] = useState(null);
    const [castId, setCastId] = useState([]);
    const [castObj, setCastObj] = useState(null);
    const [subCastId, setSubCastId] = useState([]);
    const [subCastObj, setSubCastObj] = useState(null);
    const [categoryId, setCategoryId] = useState([]);
    const [categoryObj, setCategoryObj] = useState(null);
    const [minorityId, setMinorityId] = useState([]);
    const [minorityObj, setMinorityObj] = useState(null);
    const [nationalityId, setNationalityId] = useState([]);
    const [nationalityObj, setNationalityObj] = useState(null);
    const [motherTongueId, setMotherTongueId] = useState([]);
    const [motherTongueObj, setMotherTongueObj] = useState(null);
    const [birthVillageMr, setBirthVillageMr] = useState("");
    const [birthVillageEn, setBirthVillageEn] = useState("");
    const [birthTalukaMr, setBirthTalukaMr] = useState("");
    const [birthTalukaEn, setBirthTalukaEn] = useState("");
    const [birthDistrictEn, setBirthDistrictEn] = useState("");
    const [birthDistrictMr, setBirthDistrictMr] = useState("");
    const [birthStateEn, setBirthStateEn] = useState("Maharashtra");
    const [birthStateMr, setBirthStateMr] = useState("महाराष्ट्र");
    const [birthCountryEn, setBirthCountryEn] = useState("India");
    const [birthCountryMr, setBirthCountryMr] = useState("भारत");
    const [birthDate, setBirthDate] = useState(null);
    const [oldSchool, setOldSchool] = useState("");
    const [oldSchoolStd, setOldSchoolStd] = useState("");
    const [admissionDate, setAdmissionDate] = useState(null);
    const [admittedStdId, setAdmittedStdId] = useState(1);
    const [admittedStdObj, setAdmittedStdObj] = useState(null);
    const [currentStreamId, setCurrrentStreamId] = useState(1);
    const [currentStreamObj, setCurrrentStreamObj] = useState(null);
    const [currentLastStdId, setCurrrentLastStdId] = useState([]);
    const [currentLastStdObj, setCurrrentLastStdObj] = useState(null);
    const [currentStdSince, setCurrrentStdSince] = useState(null);
    const [admittedTypeId, setAdmittedTypeId] = useState([]);
    const [admittedTypeObj, setAdmittedTypeObj] = useState(null);
    const [divisionId, setDivisionId] = useState([]);
    const [divisionObj, setDivisionObj] = useState(null);
    const [textFieldRequiredMr, setTextFieldRequiredMr] = useState(false);
    const [textFieldRequiredEn, setTextFieldRequiredEn] = useState(false);
    const [newFilteredData, setNewFilteredData] = useState([]);
    const [countryId, setCountryId] = useState("");

    const [rollNoFlag, setRollNoFlag] = useState('');
    //console.log(oldSchool);

    const [studentData, setStudentData] = useState([]);
    //console.log(JSON.stringify(newFilteredData));
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);

    useEffect(() => {
        if (authUser.branchMedium == 1) {
            setTextFieldRequiredMr(true);
            setTextFieldRequiredEn(false);
        }
        else if (authUser.branchMedium == 2) {
            setTextFieldRequiredMr(false);
            setTextFieldRequiredEn(true);
        }

    }, [authUser])

    //const [checkRequired, setCheckRequired] = useState("")
    //console.log("checkRequired" + checkRequired)

    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Student Registration");
        }
        else if (EditFlag == 2) {
            setTitleId("Update Student Details");
        }
        getData();
    }, [authUser]);

    //URL List
    const newGetData = async () => {
        setLoaderOption(true);
        if (sectionId != null || branchedId != null) {
            await axios.get(`${newRegCode}?sid=${sectionId}&bid=${branchedId}`)
                .then((response) => {
                    setRegNo(response.data);
                })
        }
        await axios.get(`${ReligionMasterGetMapping}`)
            .then((response) => {
                setReligionData(response.data);
            })
        //console.log(BaseURLMasterCastes)
        await axios.get(`${BaseURLMasterCastes}`)
            .then((response) => {
                setCasteData(response.data);
            })
        await axios.get(`${SubCasteGetMapping}`)
            .then((response) => {
                setSubCasteData(response.data);
            })
        await axios.get(`${CasteCategoryAPIURL}`)
            .then((response) => {
                setCasteCategoryData(response.data);
            })
        await axios.get(`${NationalityMasterGetMapping}`)
            .then((response) => {
                let natData = response.data;
                setNationalityData(response.data);
                setNationalityId(natData[0].id)
                setNationalityObj(natData[0])
            })
        await axios.get(`${MotherToungMasterGetMapping}`)
            .then((response) => {
                let mtData = response.data;
                setMotherToungData(response.data);

                setMotherTongueId(mtData[0].id)
                setMotherTongueObj(mtData[0])
            })
        await axios.get(`${BaseURLInstituteMaster}`)
            .then((response) => {
                setInstituteData(response.data);
            })
        await axios.get(`${getCityData}`)
            .then((response) => {
                //console.log(response.data)
                setCityData(response.data);
            })

        await axios.get(`${getOldSchool}`)
            .then((response) => {
                setoldSchoolData(response.data)
            })
        await axios.get(`${BaseURLMasterMinorities}`)
            .then((response) => {
                setMinorityData(response.data);
                //console.log(response.data[0].nameMr)
            })

        await axios.get(`${StreamMasterGetMapping}`)
            .then((response) => {
                setCurrentStremeData(response.data);
            })
        if (sectionId != null || branchedId != null) {
            //console.log(`${getClassData}?sid=${sectionId}&bid=${branchedId}`);
            await axios.get(`${getClassData}?sid=${sectionId}&bid=${branchedId}`)
                .then((response) => {
                    setClassData(response.data);
                    setLoaderOption(false);
                })
        }

    }

    const editGetData = async () => {

        //console.log(`${getStudentDataByRegNo}?regid=${rno}&sid=${sectionId}&bid=${branchedId}`)
        await axios.get(`${getStudentDataByRegNo}?regid=${rno}&sid=${sectionId}&bid=${branchedId}`)
            .then((response) => {
                setLoaderOption(true);
                console.log(JSON.stringify(response.data))
                let editReligionId = response.data.religionId;
                let editCasteCategoryId = response.data.casteCategoryId;
                let editCasteId = response.data.casteId;
                let editSubCasteId = response.data.subCasteId;
                let editMinorityId = response.data.minorityId;
                let editNationalityId = response.data.nationalityId;
                let editMotherTongueId = response.data.mothertounghId;
                let editAdmitedStdId = response.data.admissionClass;
                let editCurrentStreamId = response.data.currentStreamId;
                let editCurrentLasteStdId = response.data.currentClassId;
                let editDivisionId = response.data.currentDivisionId;
                let editAdmitedTypeId = response.data.admittedType;
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setId(response.data.id);
                setRegNo(response.data.regCode);
                setGender(response.data.genderId);
                setLeftSchool(response.data.currentStatus);
                setAadharNo(response.data.aadharNo);
                setSaralNo(response.data.saralId);
                setFnameMr(response.data.firstNameMr);
                setFnameEn(response.data.firstNameEn);
                setMnameMr(response.data.middleNameMr);
                setMnameEn(response.data.middleNameEn);
                setLnameMr(response.data.lastNameMr);
                setLnameEn(response.data.lastNameEn);
                setMotherNameMr(response.data.motherNameMr);
                setMotherNameEn(response.data.motherNameEn);
                setAddress(response.data.address);
                setCity(response.data.city);
                setBirthVillageMr(response.data.birthPlaceCityMr);
                setBirthVillageEn(response.data.birthPlaceCityEn);
                setBirthTalukaMr(response.data.birthPlaceTalukaMr);
                setBirthTalukaEn(response.data.birthPlaceTalukaEn);
                setBirthDistrictEn(response.data.birthPlaceDistrictEn);
                setBirthDistrictMr(response.data.birthPlaceDistrictMr);
                setBirthStateEn(response.data.birthPlaceStateEn);
                setBirthStateMr(response.data.birthPlaceStateMr);
                setBirthCountryEn(response.data.birthPlaceCountryEn);
                setBirthCountryMr(response.data.birthPlaceCountryMr);
                setBirthDate(response.data.birthDate);
                setOldSchool(response.data.oldSchool);
                setOldSchoolStd(response.data.oldSchoolStd);
                setAdmissionDate(response.data.admissionDate);
                setCurrrentStdSince(response.data.sinceWhen);
                setAdmittedTypeId(editAdmitedTypeId);
                setAdmittedTypeObj(admitedTypeData.find(e => e.id == editAdmitedTypeId));
                setCountryId(response.data.countryId);


                axios.get(`${chkAllocatedStudentAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${response.data.regCode}&sessionYear=${authUser.sessionYear}`)
                    .then((res) => {
                        console.log("roll no flag=====" + res.data)
                        setRollNoFlag(res.data);
                    })

                axios.get(`${ReligionMasterGetMapping}`)
                    .then((response) => {
                        setReligionData(response.data);
                        setReligionId(editReligionId);
                        setReligionObj(response.data.find(e => e.id == editReligionId));
                    })
                    .catch(error => {
                        console.log("Religion Data:" + error)
                    })

                axios.get(`${CasteCategoryAPIURL}`)
                    .then((response) => {
                        // console.log("caste category id :" + editCasteCategoryId + "====" + JSON.stringify(response.data))
                        setCasteCategoryData(response.data);
                        setCategoryId(editCasteCategoryId);
                        setCategoryObj(response.data.find(e => e.id == editCasteCategoryId));
                    })
                    .catch(error => {
                        console.log("caste category:" + error)
                    })

                axios.get(`${BaseURLMasterCastes}`)
                    .then((response) => {
                        setCasteData(response.data);
                        setCastId(editCasteId);
                        setCastObj(response.data.find(e => e.id == editCasteId));

                    })
                    .catch(error => {
                        console.log("caste Master:" + error)
                    })

                axios.get(`${SubCasteGetMapping}`)
                    .then((response) => {
                        setSubCasteData(response.data);
                        setSubCastId(editSubCasteId);
                        setSubCastObj(response.data.find(e => e.id == editSubCasteId));
                    })
                    .catch(error => {
                        console.log("sub caste:" + error)
                    })

                axios.get(`${BaseURLMasterMinorities}`)
                    .then((response) => {
                        setMinorityData(response.data);
                        setMinorityId(editMinorityId);
                        setMinorityObj(response.data.find(e => e.id == editMinorityId));
                    })
                    .catch(error => {
                        console.log("Minority:" + error)
                    })

                axios.get(`${NationalityMasterGetMapping}`)
                    .then((response) => {
                        setNationalityData(response.data);
                        setNationalityId(editNationalityId);
                        setNationalityObj(response.data.find(e => e.id == editNationalityId));
                    })
                    .catch(error => {
                        console.log("Nationality Master:" + error)
                    })

                axios.get(`${MotherToungMasterGetMapping}`)
                    .then((response) => {
                        setMotherToungData(response.data);
                        setMotherTongueId(editMotherTongueId);
                        setMotherTongueObj(response.data.find(e => e.id == editMotherTongueId));
                    })
                    .catch(error => {
                        console.log("Mother Tongue:" + error)
                    })

                axios.get(`${getClassData}?sid=${sectionId}&bid=${branchedId}`)
                    .then((response) => {
                        setClassData(response.data);
                        setAdmittedStdId(editAdmitedStdId);
                        setAdmittedStdObj(response.data.find(e => e.id == editAdmitedStdId));
                        setCurrrentLastStdId(editCurrentLasteStdId);
                        setCurrrentLastStdObj(response.data.find(e => e.id == editCurrentLasteStdId));

                    })
                    .catch(error => {
                        console.log("Class Master:" + error)
                    })

                axios.get(`${StreamMasterGetMapping}`)
                    .then((response) => {
                        setCurrentStremeData(response.data);
                        setCurrrentStreamId(editCurrentStreamId);

                        setCurrrentStreamObj(response.data.find(e => e.id == editCurrentStreamId));

                    })
                    .catch(error => {
                        console.log("Stream Master:" + error)
                    })

                axios.get(`${getOldSchool}`)
                    .then((response) => {
                        setoldSchoolData(response.data);
                    })

                axios.get(`${getCityData}`)
                    .then((response) => {
                        setCityData(response.data);
                    })

                axios.get(`${getDivisionData}?cid=${editCurrentLasteStdId}&sid=${sectionId}&bid=${branchedId}`)
                    .then((response) => {
                        setDivisionData(response.data);
                        setDivisionId(editDivisionId);
                        setDivisionObj(response.data.find(e => e.id == editDivisionId));
                        setLoaderOption(false);
                    })

            })

    }
    //Get Division by class id
    const getDivisionById = async (cid) => {
        await axios.get(`${getDivisionData}?cid=${cid}&sid=${sectionId}&bid=${branchedId}`)
            .then((response) => {
                setDivisionData(response.data);
            })
    }

    // Get Data to form
    const getData = async () => {
        if (EditFlag == 2) {
            setRegNoShow(true);
            editGetData();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
        else if (EditFlag == 1) {
            setRegNoShow(false);
            newGetData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }

    }

    useEffect(() => {
        getStudentData();
    }, [authUser, numCal])


    const getStudentData = async () => {
        //console.log(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
        await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
            .then((res) => {
                if (res.data !== null) {
                    const tempData = res.data;
                    //console.log(tempData.length)
                    setStudentData(studentData.concat(tempData));
                    // if (EditFlag == 2) {
                    //     let newData = tempData.filter(e => e.regCode !== regNo)
                    //     //console.log(JSON.stringify(newData.concat(tempData)));
                    //     setNewFilteredData(newFilteredData.concat(newData));
                    // }
                    setLoaderOption(false)
                    //console.log("Data : " + JSON.stringify(data))
                    //console.log("____________________________________________")
                    if (tempData.length === limits) {
                        setOffset(offset + limits);
                        setNumCall(numCal + 1);
                        //console.log("Offset ===============" + offset);
                    }
                }
            })
    }

    //Student Status(CheckBox)
    const HandleSchoolCheckBox = (e) => {
        setLeftSchool(e.target.checked);
        if (e.target.checked == true) {
            setLeftSchool(1);
        }
        else {
            setLeftSchool(0);
        }
    }

    //Gender management(radio button)
    const handleGender = (event) => {
        setGender(event.target.value);

    };

    // Get sub caste by caste id
    // const getSubCastDataByID = async (id) => {
    //     console.log(id);
    //     await axios.get(`${GetSubCasteByCasteId}` + id)
    //         .then((response) => {
    //             setSubCasteData(response.data);
    //         })
    // }

    const saveData = (e) => {
        //setLoaderOption(true);
        if (regNo > 0 &&
            moment(birthDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(admissionDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(currentStdSince).format("DD/MM/YYYY") != "Invalid date"
            &&
            ((studentData.some(e =>
                ((authUser.branchMedium == 1) ? (e.studentNameMr == lnameMr + " " + fnameMr + " " + mnameMr) : (e.studentNameEn == lnameEn + " " + fnameEn + " " + mnameEn)) &&
                (e.birthDate == moment(birthDate).format("YYYY-MM-DD")) &&
                ((authUser.branchMedium == 1) ? (e.motherNameMr == motherNameMr) : (e.motherNameEn == motherNameEn)) &&
                (e.currentClassId === currentLastStdId) &&
                (e.currentDivisionId == divisionId) &&
                (e.secessionYear == secessionYear) &&
                (e.sectionId == sectionId) &&
                (e.branchId == branchedId) &&
                (e.currentStatus == 0)
            )
            )) == false
        ) {

            const saveStudentEntry = {
                regCode: regNo,
                firstNameMr: fnameMr,
                firstNameEn: fnameEn,
                middleNameMr: mnameMr,
                middleNameEn: mnameEn,
                lastNameMr: lnameMr,
                lastNameEn: lnameEn,
                studentNameMr: "",
                studentNameEn: "",
                motherNameMr: motherNameMr,
                motherNameEn: motherNameEn,
                address: address,
                city: city,
                genderId: gender,
                religionId: religionId,
                casteId: castId,
                subCasteId: (subCastId == "" || subCastId == [] || subCastId == null) ? null : subCastId,
                nationalityId: nationalityId,
                mothertounghId: motherTongueId,
                birthDate: moment(birthDate).format("DD/MM/YYYY"),
                birthPlaceCityMr: birthVillageMr,
                birthPlaceCityEn: birthVillageEn,
                birthPlaceTalukaMr: birthTalukaMr,
                birthPlaceTalukaEn: birthTalukaEn,
                birthPlaceDistrictMr: birthDistrictMr,
                birthPlaceDistrictEn: birthDistrictEn,
                birthPlaceStateMr: birthStateMr,
                birthPlaceStateEn: birthStateEn,
                birthPlaceCountryMr: birthCountryMr,
                birthPlaceCountryEn: birthCountryEn,
                oldSchool: oldSchool,
                oldSchoolStd: oldSchoolStd,
                admissionDate: moment(admissionDate).format("DD/MM/YYYY"),
                admissionClass: admittedStdId,
                currentStreamId: currentStreamId,
                currentClassId: currentLastStdId,
                currentDivisionId: divisionId,
                sinceWhen: moment(currentStdSince).format("DD/MM/YYYY"),
                aadharNo: aadharNo,
                saralId: saralNo,
                casteCategoryId: categoryId,
                minorityId: minorityId,
                admittedType: admittedTypeId,
                tempRegNo: 0,
                currentStatus: leftSchool,
                secessionYear: secessionYear,
                sectionId: sectionId,
                branchId: branchedId,
                countryId: countryId
            }

            console.log(JSON.stringify(saveStudentEntry));
            axios.post(`${StudentRegisterPostMapping}`, saveStudentEntry)
                .then((response) => {
                    if (response.data == "SAVED") {
                        getData();
                        setLoaderOption(false);
                        navigate("/Home/ad01/1");
                        //toast.success("Student registration sucessfully done.");

                    }
                    else if (response.data == "EXIST") {
                        setLoaderOption(true);
                        toast.error("Register No is already exist.");
                    }
                    else {
                        setLoaderOption(true);
                        toast.error("Operation failed.");
                    }
                }).catch((error) => {
                    console.log("save error name: " + error);
                });
            //toast.success("valid")
        }
        else if (((studentData.some(e =>
            ((authUser.branchMedium == 1) ? (e.studentNameMr === lnameMr + " " + fnameMr + " " + mnameMr) : (e.studentNameEn === lnameEn + " " + fnameEn + " " + mnameEn)) &&
            (e.birthDate === moment(birthDate).format("YYYY-MM-DD")) &&
            ((authUser.branchMedium == 1) ? (e.motherNameMr === motherNameMr) : (e.motherNameEn === motherNameEn)) &&
            (e.currentClassId === currentLastStdId) &&
            (e.currentDivisionId === divisionId) &&
            (e.secessionYear === secessionYear) &&
            (e.sectionId === sectionId) &&
            (e.branchId === branchedId) &&
            (e.currentStatus == 0)
        ))) == true
        ) {
            setLoaderOption(false);
            toast.warn("This Student is already exists.")
        }
        else if (moment(birthDate).format("DD/MM/YYYY") === "Invalid date" ||
            moment(admissionDate).format("DD/MM/YYYY") === "Invalid date" ||
            moment(currentStdSince).format("DD/MM/YYYY") === "Invalid date") {
            setLoaderOption(false);
            toast.error("Improper Date, please check.");
        }
        else {
            setLoaderOption(false);
            toast.warn("Reg No. should be greater than 0/Zero.");
        }
        // else if ((studentData.some(e => e.aadharNo === aadharNo)) == true) {
        //     toast.warn("Student with this aadhar number is already exists.")
        // }

    }

    const updateData = () => {
        //setLoaderOption(true);
        if (regNo > 0 &&
            moment(birthDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(admissionDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(currentStdSince).format("DD/MM/YYYY") != "Invalid date"
            // &&
            // ((newFilteredData.some(e => ((authUser.branchMedium == 1) ? (e.studentNameMr === lnameMr + " " + fnameMr + " " + mnameMr) : (e.studentNameEn === lnameEn + " " + fnameEn + " " + mnameEn)) &&
            //     (e.birthDate === moment(birthDate).format("YYYY-MM-DD")) &&
            //     ((authUser.branchMedium == 1) ? (e.motherNameMr === motherNameMr) : (e.motherNameEn === motherNameEn)) &&
            //     (e.currentClassId === currentLastStdId) &&
            //     (e.currentDivisionId === divisionId) &&
            //     (e.secessionYear === secessionYear) &&
            //     (e.sectionId === sectionId) &&
            //     (e.branchId === branchedId)))) == false
        ) {
            const updateStudentEntry = {
                id: id,
                regCode: regNo,
                firstNameMr: fnameMr,
                firstNameEn: fnameEn,
                middleNameMr: mnameMr,
                middleNameEn: mnameEn,
                lastNameMr: lnameMr,
                lastNameEn: lnameEn,
                studentNameMr: "",
                studentNameEn: "",
                motherNameMr: motherNameMr,
                motherNameEn: motherNameEn,
                address: address,
                city: city,
                genderId: gender,
                religionId: religionId,
                casteId: castId,
                subCasteId: (subCastId == "" || subCastId == [] || subCastId == null) ? null : subCastId,
                nationalityId: nationalityId,
                mothertounghId: motherTongueId,
                birthDate: moment(birthDate).format("DD/MM/YYYY"),
                birthPlaceCityMr: birthVillageMr,
                birthPlaceCityEn: birthVillageEn,
                birthPlaceTalukaMr: birthTalukaMr,
                birthPlaceTalukaEn: birthTalukaEn,
                birthPlaceDistrictMr: birthDistrictMr,
                birthPlaceDistrictEn: birthDistrictEn,
                birthPlaceStateMr: birthStateMr,
                birthPlaceStateEn: birthStateEn,
                birthPlaceCountryMr: birthCountryMr,
                birthPlaceCountryEn: birthCountryEn,
                oldSchool: oldSchool,
                oldSchoolStd: oldSchoolStd,
                admissionDate: moment(admissionDate).format("DD/MM/YYYY"),
                admissionClass: admittedStdId,
                currentStreamId: currentStreamId,
                currentClassId: currentLastStdId,
                currentDivisionId: divisionId,
                sinceWhen: moment(currentStdSince).format("DD/MM/YYYY"),
                aadharNo: aadharNo,
                saralId: saralNo,
                casteCategoryId: categoryId,
                minorityId: minorityId,
                admittedType: admittedTypeId,
                tempRegNo: 0,
                currentStatus: leftSchool,
                secessionYear: secessionYear,
                sectionId: sectionId,
                branchId: branchedId,
                countryId: countryId
            }
            console.log(JSON.stringify(updateStudentEntry))
            axios.put(`${updateStudentMasterData}?regid=${rno}&sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`, updateStudentEntry)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setLoaderOption(false);
                        //toast.success("Student registration sucessfully done.");
                        navigate("/Home/ad01/2");
                    }

                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                })
            //toast.success("Valid");
        }
        // else if (((newFilteredData.some(e => ((authUser.branchMedium == 1) ? (e.studentNameMr === lnameMr + " " + fnameMr + " " + mnameMr) : (e.studentNameEn === lnameEn + " " + fnameEn + " " + mnameEn)) &&
        //     (e.birthDate === moment(birthDate).format("YYYY-MM-DD")) &&
        //     ((authUser.branchMedium == 1) ? (e.motherNameMr === motherNameMr) : (e.motherNameEn === motherNameEn)) &&
        //     (e.currentClassId === currentLastStdId) &&
        //     (e.currentDivisionId === divisionId) &&
        //     (e.secessionYear === secessionYear) &&
        //     (e.sectionId === sectionId) &&
        //     (e.branchId === branchedId)))) == true
        // ) {
        //     setLoaderOption(false);
        //     toast.warn("This Student is already exists.")
        // }
        else if (moment(birthDate).format("DD/MM/YYYY") === "Invalid date" ||
            moment(admissionDate).format("DD/MM/YYYY") === "Invalid date" ||
            moment(currentStdSince).format("DD/MM/YYYY") === "Invalid date") {
            setLoaderOption(false);
            toast.error("Improper Date, please check.");
        }
        else {
            setLoaderOption(false);
            toast.warn("Reg No. should be greater than 0/Zero.");
        }
        // else if ((studentData.some(e => e.aadharNo === aadharNo)) == true) {
        //     toast.warn("Student with this aadhar number is already exists.")
        // }

    }

    const ClearData = () => {
        navigate("/Home/ad01/0");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 0
    };
    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveData();
        }
        if (state.button === 2) {
            updateData();
        }
    };

    //console.log("changeState : " + changeState)

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.studentSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.studentUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className="row">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reg No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    margin='dense'
                                    onChange={e => setRegNo(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? regNo : ""}
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Reg No"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: regNoShow }}
                                    value={regNo}
                                />
                            </div>
                            <div className='col-sm-3 mt-2'>
                                <FormControlLabel control={<Checkbox size="small" checked={leftSchool} onChange={HandleSchoolCheckBox} inputProps={{ disabled: true }} />} label="Left School" />
                            </div>

                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2 my-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Gender :</label>
                            <div className='col-sm-3'>
                                <RadioGroup row aria-label="gender" name="customized-radios" value={gender} onChange={handleGender} onKeyPress={(e) => (e.key == "Enter") ? { handleGender } : ""}>
                                    <FormControlLabel value={1} control={<Radio color='primary' />} label="Male" />
                                    <FormControlLabel value={2} control={<Radio color='primary' />} label="Female" />
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Aadhar No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    fullWidth
                                    margin='dense'
                                    value={aadharNo}
                                    onChange={e => { setAadharNo(e.target.value); }}
                                    onKeyPress={(e) => (e.key == "Enter") ? aadharNo : ""}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Aadhar No"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 12 }}
                                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                                />
                                {/* <span style={{ display: (aadharNo == "" || aadharNo == "-") ? "block" : "none" }}><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{checkRequired}</em></span> */}
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Saral No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    // required={(authUser.deptId == 1 || authUser.deptId == 5) ? false : true}
                                    fullWidth
                                    margin='dense'
                                    onWheel={(e) => e.target.blur()}
                                    value={saralNo}
                                    onChange={e => setSaralNo(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? saralNo : ""}
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Saral No"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                                {/* <span style={{ display: (saralNo == "" || saralNo == "-") ? "block" : "none" }}><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{checkRequired}</em></span> */}
                            </div>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >National Code:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    fullWidth
                                    margin='dense'
                                    onWheel={(e) => e.target.blur()}
                                    value={countryId}
                                    onChange={e => setCountryId(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? countryId : ""}
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="National Code"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >First Name :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    onKeyDown={(e) => (e.key === " " && fnameMr.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={fnameMr}
                                    onChange={e => setFnameMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? fnameMr : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="First Name Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    onKeyDown={(e) => (e.key === " " && fnameEn.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={fnameEn}
                                    onChange={e => setFnameEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? fnameEn : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="First Name English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Middle Name :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    onKeyDown={(e) => (e.key === " " && mnameMr.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={mnameMr}
                                    onChange={e => setMnameMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? mnameMr : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Middle Name Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    onKeyDown={(e) => (e.key === " " && mnameEn.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={mnameEn}
                                    onChange={e => setMnameEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? mnameEn : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Middal Name English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Last Name :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    onKeyDown={(e) => (e.key === " " && lnameMr.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={lnameMr}
                                    onChange={e => setLnameMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? lnameMr : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Last Name Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    onKeyDown={(e) => (e.key === " " && lnameEn.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={lnameEn}
                                    onChange={e => setLnameEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? lnameEn : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Last Name English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Name :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    onKeyDown={(e) => (e.key === " " && motherNameMr.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={motherNameMr}
                                    onChange={e => setMotherNameMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? motherNameMr : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mother Name Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    onKeyDown={(e) => (e.key === " " && motherNameEn.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={motherNameEn}
                                    onChange={e => setMotherNameEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? motherNameEn : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mother Name English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Address :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    fullWidth
                                    margin='dense'
                                    value={address}
                                    onKeyDown={(e) => (e.key === " " && address.length === 0) ? e.preventDefault() : ""}
                                    onChange={e => setAddress(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? address : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Address"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>
                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >City :</label>
                            <div className='col-sm-4'>
                                {/* <TextField
                                    onKeyDown={(e) => (e.key === " " && city.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={city}
                                    onChange={e => setCity(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="City"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                /> */}
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    freeSolo
                                    disableClearable
                                    onKeyPress={(e) => (e.key == "Enter") ? city : ""}
                                    options={(city === "") ? [] : cityData}
                                    value={city}
                                    onChange={(event, newValue) => {
                                        //setNarrationId(newValue.id);
                                        //console.log(newValue.narrationDetail);
                                        setCity((authUser.branchMedium == 1) ? newValue.nameMr : newValue.nameEn);
                                    }}
                                    inputValue={city}
                                    onInputChange={(event, newInputValue) => {
                                        //setNarrationId(newInputValue.id);
                                        //console.log(newInputValue);
                                        setCity(newInputValue);
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => {

                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            //console.log("option........"+JSON.stringify(option.inputValue))
                                            return (authUser.branchMedium == 1) ? option.nameMr : option.nameEn;
                                        }
                                        // Regular option
                                        return (authUser.branchMedium == 1) ? option.nameMr : option.nameEn;
                                    }

                                    }
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params}
                                            margin='dense' label="City" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Religion :</label>
                            <div className="col-sm-4">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={religionData}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        </Box>
                                    )}
                                    size="small"
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setReligionId(newValue.id);
                                        }
                                        setReligionObj(newValue)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? religionObj : ""}
                                    value={religionObj}
                                    renderInput={params => (
                                        <TextField {...params}
                                            margin='dense' label="Select Religion" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#religionModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>


                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Caste :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={casteData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setCastId(newValue.id);
                                        }
                                        setCastObj(newValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? castObj : ""}
                                    // onKeyPress={(event, newValue) => {
                                    //     if (event.key == "Enter") {
                                    //         setCastObj(newValue);
                                    //         // if (newValue !== null) {
                                    //         //     setCastId(newValue.id);
                                    //         // }
                                    //     }
                                    // }}
                                    style={{ fontSize: '14px' }}
                                    //getOptionLabel={option => option.nameMr + " / " + option.nameEn}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        </Box>
                                    )}
                                    size="small"
                                    value={castObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Caste" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#casteModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Sub Caste :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={subCasteData}
                                    value={subCastObj}
                                    onChange={(event, newValue) => {
                                        if (newValue == "" || newValue == null || newValue == []) {
                                            setSubCastObj(null);
                                            setSubCastId([])
                                        }
                                        else {
                                            setSubCastObj(newValue);
                                            setSubCastId(newValue.id);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? subCastObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        </Box>
                                    )}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Sub Caste" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#subCasteModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Caste Category :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"

                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={casteCategoryData}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setCategoryId(newValue.id);
                                        }
                                        setCategoryObj(newValue)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? categoryObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    value={categoryObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Caste Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            {/* <div className="col-sm-4">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#casteCategoryModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div> */}
                        </div>


                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Minority :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"

                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={minorityData}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setMinorityId(newValue.id);
                                        }
                                        setMinorityObj(newValue)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? minorityObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    value={minorityObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Minority" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#minorityModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Nationality :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"

                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={nationalityData}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setNationalityId(newValue.id);
                                        }
                                        setNationalityObj(newValue)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? nationalityObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    value={nationalityObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Nationality" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                    defaultValue={[nationalityData[0]]}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#nationalityModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Toung :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"

                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={motherToungData}
                                    value={motherTongueObj}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setMotherTongueId(newValue.id);
                                        }
                                        setMotherTongueObj(newValue)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? motherTongueObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (((authUser.branchMedium == 1) ? option.nameMr : option.nameEn)) ? (authUser.branchMedium == 1) ? option.nameMr : option.nameEn : ""}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Mother Toung" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                    defaultValue={[motherToungData[0]]}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#motherTongueModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Birth Village :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && birthVillageMr.length === 0) ? e.preventDefault() : ""}
                                    margin='dense'
                                    value={birthVillageMr}
                                    onChange={e => setBirthVillageMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? setBirthVillageMr(e.target.value) : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Village Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && birthVillageEn.length === 0) ? e.preventDefault() : ""}
                                    value={birthVillageEn}
                                    onChange={e => setBirthVillageEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? setBirthVillageEn(e.target.value) : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Village English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Birth Taluka :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && birthTalukaMr.length === 0) ? e.preventDefault() : ""}
                                    margin='dense'
                                    value={birthTalukaMr}
                                    onChange={e => setBirthTalukaMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? setBirthTalukaMr(e.target.value) : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Taluka Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && birthTalukaEn.length === 0) ? e.preventDefault() : ""}
                                    value={birthTalukaEn}
                                    onChange={e => setBirthTalukaEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? setBirthTalukaEn(e.target.value) : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Taluka English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth District :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && birthDistrictMr.length === 0) ? e.preventDefault() : ""}
                                    value={birthDistrictMr}
                                    onChange={e => setBirthDistrictMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? birthDistrictMr : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth District Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && birthDistrictEn.length === 0) ? e.preventDefault() : ""}
                                    value={birthDistrictEn}
                                    onChange={e => setBirthDistrictEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? birthDistrictEn : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth District English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth State :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && birthStateMr.length === 0) ? e.preventDefault() : ""}
                                    value={birthStateMr}
                                    onChange={e => setBirthStateMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? birthStateMr : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth State Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && birthStateEn.length === 0) ? e.preventDefault() : ""}
                                    value={birthStateEn}
                                    onChange={e => setBirthStateEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? birthStateEn : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth State English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Country :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredMr}
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && birthCountryMr.length === 0) ? e.preventDefault() : ""}
                                    margin='dense'
                                    value={birthCountryMr}
                                    onChange={e => setBirthCountryMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? birthCountryMr : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Country Marathi"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required={textFieldRequiredEn}
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && birthCountryEn.length === 0) ? e.preventDefault() : ""}
                                    margin='dense'
                                    value={birthCountryEn}
                                    onChange={e => setBirthCountryEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? birthCountryEn : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Country English"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Birth Date :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Birth Date"
                                        value={birthDate}
                                        inputFormat='dd/MM/yyyy'
                                        onChange={(newValue) => {
                                            setBirthDate(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? birthDate : ""}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Old School Name :</label>
                            <div className="col-sm-8 ">
                                {/* <TextField
                                    required
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && oldSchool.length === 0) ? e.preventDefault() : ""}
                                    value={oldSchool}
                                    onChange={e => setOldSchool(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Old School Name"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                /> */}
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    freeSolo
                                    disableClearable
                                    onKeyDown={(e) => (e.key === " " && oldSchool.length === 0) ? e.preventDefault() : ""}
                                    onKeyPress={(e) => (e.key == "Enter") ? oldSchool : ""}
                                    options={(oldSchool === "") ? [] : oldSchoolData}
                                    value={oldSchool}
                                    onChange={(event, newValue) => {
                                        //setNarrationId(newValue.id);
                                        //console.log(newValue.narrationDetail);
                                        setOldSchool(newValue.nameMr);
                                    }}
                                    inputValue={oldSchool}
                                    onInputChange={(event, newInputValue) => {
                                        //setNarrationId(newInputValue.id);
                                        //console.log(newInputValue);
                                        setOldSchool(newInputValue);
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => {

                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            //console.log("option........"+JSON.stringify(option.inputValue))
                                            return option.nameMr;
                                        }
                                        // Regular option
                                        return option.nameMr;
                                    }

                                    }
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Old School" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Old School Std :</label>
                            <div className="col-sm-4 ">
                                <TextField
                                    required
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && oldSchoolStd.length === 0) ? e.preventDefault() : ""}
                                    value={oldSchoolStd}
                                    onChange={e => setOldSchoolStd(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? oldSchoolStd : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Old School Std"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Admission Date :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Admission Date"

                                        inputFormat='dd/MM/yyyy'
                                        value={admissionDate}
                                        onChange={(newValue) => {
                                            setAdmissionDate(newValue);
                                            if (EditFlag == 1) {
                                                setCurrrentStdSince(newValue);
                                            }
                                            if (newValue == null) {
                                                setCurrrentStdSince(null);
                                            }

                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? admissionDate : ""}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Admitted Std :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"

                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    value={admittedStdObj}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setAdmittedStdId(newValue.id);
                                        }
                                        setAdmittedStdObj(newValue)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? admittedStdObj : ""}
                                    style={{ fontSize: '14px' }}
                                    size="small"
                                    options={classData}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    renderInput={params => (
                                        <TextField {...params} label="Select Admitted Std" margin='dense' autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='row my-2' hidden={(authUser.deptId == 4) ? false : true}>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current Stream :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={currentStremeData}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    value={currentStreamObj}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setCurrrentStreamId(newValue.id);
                                        }
                                        setCurrrentStreamObj(newValue)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? currentStreamObj : ""}
                                    style={{ fontSize: '14px' }}

                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Select Current Stream" margin='dense' autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                        </div>

                        {
                            ((EditFlag == 2 && rollNoFlag == 1))
                                ?
                                <div className="offset-sm-2">
                                    <span className='badge' style={{ backgroundColor: "#FA6969", borderRadius: "6px", fontWeight: "520", fontSize: "13px", margin: "4px", padding: "6px" }}>This student is already assigned to this class-division.</span>
                                </div>
                                :
                                ''
                        }


                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current Std :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    readOnly={(EditFlag == 2 && rollNoFlag == 1 && leftSchool == 0) ? true : false}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    options={classData}
                                    value={currentLastStdObj}
                                    onChange={(event, newValue) => {
                                        setCurrrentLastStdObj(newValue)
                                        if (newValue !== null) {
                                            setCurrrentLastStdId(newValue.id);
                                            getDivisionById(newValue.id);
                                        }
                                        setDivisionId(null);
                                        setDivisionObj(null);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? currentLastStdObj : ""}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}

                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Current Std" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Division :</label>
                            <div className="col-sm-4">
                                <Autocomplete
                                    id="combo-box-demo"
                                    readOnly={(EditFlag == 2 && rollNoFlag == 1 && leftSchool == 0) ? true : false}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={DivisionData}
                                    value={divisionObj}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setDivisionId(newValue.id);
                                        }
                                        setDivisionObj(newValue)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? divisionObj : ""}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    style={{ fontSize: '14px' }}

                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Div" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current Std Since :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Current Std Since"

                                        value={currentStdSince}
                                        onChange={(newValue) => {
                                            setCurrrentStdSince(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? currentStdSince : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}  >Admitted Type :</label>
                            <div className="col-sm-4">
                                <Autocomplete
                                    id="combo-box-demo"

                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={admitedTypeData}
                                    value={admittedTypeObj}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setAdmittedTypeId(newValue.id);
                                        }
                                        setAdmittedTypeObj(newValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? admittedTypeObj : ""}
                                    getOptionLabel={option => option.name}
                                    style={{ fontSize: '14px' }}

                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Admitted type" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>

                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
                <AddNewReligionModal setReligionData={setReligionData} />
                <AddNewCaste setCasteData={setCasteData} />
                <AddNewSubCaste setSubCasteData={setSubCasteData} />
                <AddNewCasteCategory setCasteCategoryData={setCasteCategoryData} />
                <AddNewMinority setMinorityData={setMinorityData} />
                <AddNewNationalityModal setNationalityData={setNationalityData} />
                <AddNewMotherTongueModal setMotherToungData={setMotherToungData} />

            </>
        )
    }

};

export default MasterFormStudent;
