import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { getStudentListByBranchAPIURL } from '../../CommonServices/StudentRegistrationAPIURL';
import { getMasterialMasterAPIURL } from '../Services/MaterialMasterAPIURL';
import MaterialAllocationTable from './MaterialAllocationTable';
import CircularProgress from '@mui/material/CircularProgress';
import { saveMaterialAllocationAPIURL, updateMaterialAllocationAPIURL, getMaterialAllocationAPIURL, getStudentListForHostelByBid } from '../Services/MaterialAllocationAPIURL';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const MaterialAllocation = () => {
    const titleId = 'Material Allocation';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Student`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Material`, key: 'materialName' },
        { label: `Quantity`, key: 'qty' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "materialAlloSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "materialAlloUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [materialData, setMaterialData] = useState([]);
    const [materialObj, setMaterialObj] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [readOnlyField, setReadOnlyField] = useState(false);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && studentData.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        setLoaderOption(true);
      
        await axios.get(getMasterialMasterAPIURL)
            .then(res => {
                setMaterialData(res.data);
                setLoaderOption(false);
            }).catch(err => {
                setLoaderOption(false);
                console.log('get material err' + err)
            })

        if (authUser.sessionYear != '' && authUser.branchId != null) {
            console.log(`${getStudentListForHostelByBid}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
            await axios.get(`${getStudentListForHostelByBid}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    const presentStudentList = res.data.filter(e => e.currentStatus == 0)
                    setStudentData(presentStudentList);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get student list err' + err)
                })

            await axios.get(`${getMaterialAllocationAPIURL}?bid=${authUser.branchId}&year=${authUser.sessionYear}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get material allocation err' + err)
                })
        }

    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setStudentObj(null);
        setMaterialObj(null);
        setQuantity('');
        setReadOnlyField(false);
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "regNo": studentObj.regCode,
            "materialId": materialObj.id,
            "qty": quantity,
            "sessionYear": authUser.sessionYear,
            "branchId": authUser.branchId
        }
        await axios.post(saveMaterialAllocationAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Material allocation save failed.")
                }
            }).catch(err => {
                console.log("Material allocation save err" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "materialId": materialObj.id,
            "qty": quantity,
            "sessionYear": authUser.sessionYear,
            "branchId": authUser.branchId
        }
        await axios.put(updateMaterialAllocationAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Material allocation update failed.")
                }
            }).catch(err => {
                console.log("Material allocation update err" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.materialAlloSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.materialAlloUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }
        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                        <div className='col-sm-4'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                loading={loading}
                                                options={studentData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setStudentObj(newValue);
                                                    setMaterialObj(null);
                                                    setQuantity('');
                                                }}
                                                readOnly={(readOnlyField == true) ? true : false}
                                                onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                                value={studentObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                renderOption={(props, option) => (
                                                    <div className='' {...props}>
                                                        <div className=''>
                                                            {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                                        </div>

                                                    </div>
                                                )}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Material :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={materialData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setMaterialObj(newValue);
                                                    setQuantity('');
                                                }}
                                                value={materialObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.materialName}
                                                onKeyPress={(e) => (e.key == "Enter") ? materialObj : ""}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Material" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Quantity:</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && quantity.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                className=''
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                size='small'
                                                id="outlined-basic"
                                                label="Quantity"
                                                variant="outlined"
                                                margin='dense'
                                                value={quantity}
                                                onChange={(e) => setQuantity(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? quantity : ""}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-1">
                                            <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                            <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                            <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3">
                                <MaterialAllocationTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    filteredData={filteredData}
                                    setFilteredData={setFilteredData}
                                    getData={getData}
                                    setMaterialObj={setMaterialObj}
                                    materialData={materialData}
                                    studentData={studentData}
                                    studentObj={studentObj}
                                    setStudentObj={setStudentObj}
                                    setQuantity={setQuantity}
                                    setId={setId}
                                    clearData={clearData}
                                    setReadOnlyField={setReadOnlyField}
                                    updateBtnStyle={updateBtnStyle}
                                    setSaveBtn={setSaveBtn}
                                    setUpdateBtn={setUpdateBtn}
                                    displayNoneBtnStyle={displayNoneBtnStyle}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default MaterialAllocation