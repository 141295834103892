import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faHourglassHalf, faSpinner, faTimes, faCalendarCheck, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';


import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";

import CommentIcon from '@mui/icons-material/Comment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import AppsIcon from '@mui/icons-material/Apps';
//APIURL
import { getServiceFormDataCountAPIURL } from '../Service/allEnquiryAPIURL';
import { getServiceFormCountDataAPIURL, getServiceFormCountDataSchoolWiseAPIURL } from '../Service/DashboardAPIURL';

const ServiceDashboard = () => {

    const navigate = useNavigate();

    const [countData, setCountData] = useState(null);

    const [modalCount, setModalCount] = useState([]);
    const [branchCallCount, setBranchCallCount] = useState([]);

    const getData = async () => {
        await axios.get(getServiceFormDataCountAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    setCountData(res.data);
                    //console.log(JSON.stringify(res.data));
                }
            })
            .catch((err) => {
                console.log(err);
            })

        await axios.get(getServiceFormCountDataAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    setModalCount(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })

        await axios.get(getServiceFormCountDataSchoolWiseAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    setBranchCallCount(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })


    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <div className="container">
                <div className="row p-2">
                    <div className="col-sm-3 cardWrapper">
                        <div className="cardBody blackBackground whiteFont">
                            <span className="cardIcon blackBackground whiteFont"><FontAwesomeIcon icon={faGlobe} /></span>

                            <span className='cardTitle'>All Calls</span>
                            <span className='cardCount'>
                                {(countData) ? countData[0].allStatus : 0}
                            </span>

                            <div className='cardFooter' onClick={() => navigate("../a001/0")}>View More</div>
                        </div>
                    </div>
                    <div className="col-sm-3 cardWrapper">
                        <div className="cardBody blueBackground whiteFont">
                            <span className="cardIcon blueBackground whiteFont"><FontAwesomeIcon icon={faHourglassHalf} spin /></span>

                            <span className='cardTitle'>Pending Calls</span>
                            <span className='cardCount'>
                                {(countData) ? countData[0].pending : 0}
                            </span>

                            <div className='cardFooter' onClick={() => navigate("../a001/1")}>View More</div>
                        </div>
                    </div>
                    <div className="col-sm-3 cardWrapper">
                        <div className="cardBody yellowBackground whiteFont">
                            <span className="cardIcon yellowBackground whiteFont"><FontAwesomeIcon icon={faSpinner} spin /></span>

                            <span className='cardTitle'>In Process</span>
                            <span className='cardCount'>
                                {(countData) ? countData[0].inProcess : 0}
                            </span>

                            <div className='cardFooter' onClick={() => navigate("../a001/2")}>View More</div>
                        </div>
                    </div>
                    <div className="col-sm-3 cardWrapper">
                        <div className="cardBody greenBackground whiteFont">
                            <span className="cardIcon greenBackground whiteFont"><FontAwesomeIcon icon={faCalendarCheck} /></span>

                            <span className='cardTitle'>Completed</span>
                            <span className='cardCount'>
                                {(countData) ? countData[0].completed : 0}
                            </span>

                            <div className='cardFooter' onClick={() => navigate("../a001/3")}>View More</div>
                        </div>
                    </div>
                    <div className="col-sm-3 cardWrapper">
                        <div className="cardBody levenderBackground whiteFont">
                            <span className="cardIcon levenderBackground whiteFont"><FontAwesomeIcon icon={faTimes} /></span>

                            <span className='cardTitle'>Closed</span>
                            <span className='cardCount'>
                                {(countData) ? countData[0].closed : 0}
                            </span>

                            <div className='cardFooter' onClick={() => navigate("../a001/4")}>View More</div>
                        </div>
                    </div>
                    <div className="col-sm-3 cardWrapper">
                        <div className="cardBody redBackground whiteFont">
                            <span className="cardIcon redBackground whiteFont"><FontAwesomeIcon icon={faTrash} /></span>

                            <span className='cardTitle'>Rejected</span>
                            <span className='cardCount'>
                                {(countData) ? countData[0].rejected : 0}
                            </span>

                            <div className='cardFooter' onClick={() => navigate("../a001/5")}>View More</div>
                        </div>
                    </div>
                </div>



                <div className='row mt-4'>
                    <div className="col-sm-6 my-2">
                        <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>
                            {/* Header */}
                            <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                                <span><AppsIcon style={{ color: "#464de4", marginRight: "4px", fontSize: '18px', marginTop: "-4px" }} /></span>
                                <span style={{ fontSize: "16px", fontWeight: "600", color: "#464de4" }}>Module Call Count</span>
                            </div>
                            <div className='bodyWrapper' style={{ maxHeight: "220px", overflowY: "scroll", margin: "4px 0px" }}>


                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9", }}>
                                            <tr>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>ID</th>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Module Name</th>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Form Name</th>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                modalCount.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "13px", padding: "8px" }}> {index + 1} </td>
                                                            <td style={{ fontSize: "13px", padding: "8px" }}>{item.moduleName}</td>
                                                            <td style={{ fontSize: "13px", padding: "8px" }}>{item.formName}</td>
                                                            <td style={{ fontSize: "13px", padding: "8px" }}>{item.count}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 my-2">
                        <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>
                            {/* Header */}
                            <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                                <span><AccountBalanceIcon style={{ color: "#464de4", marginRight: "4px", fontSize: '18px', marginTop: "-4px" }} /></span>
                                <span style={{ fontSize: "16px", fontWeight: "600", color: "#464de4" }}>Branch Call Count</span>
                            </div>
                            <div className='bodyWrapper' style={{ maxHeight: "220px", overflowY: "scroll", margin: "4px 0px" }}>


                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead className='table-default' style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9", position: "sticky", overflow: "hidden", top: "0", }}>
                                            <tr>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>ID</th>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Branch Name</th>
                                                <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                branchCallCount.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "13px", padding: "8px" }}> {index + 1} </td>
                                                            <td style={{ fontSize: "13px", padding: "8px" }}>{item.branchName}</td>
                                                            <td style={{ fontSize: "13px", padding: "8px" }}>{item.count}</td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default ServiceDashboard