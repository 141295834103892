import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { SubCasteSaveSubCaste, SubCasteGetMapping } from "../CommonServices/SubCasteMasterAPIURL";
import { BaseURLMasterCastes } from "../CommonServices/CasteMasterAPIURL";

const AddNewSubCaste = ({ setSubCasteData }) => {
    const [subCasteMarathi, setSubCasteMarathi] = useState("");
    const [subCasteEnglish, setSubCasteEnglish] = useState("");
    const [casteData, setCasteData] = useState([]);
    const [casteId, setCasteId] = useState(null);


    const getSubCasteData = async () => {
        await axios.get(`${SubCasteGetMapping}`)
            .then((response) => {
                setSubCasteData(response.data);
            })
    }

    const getCasteList = async() => {
        await axios.get(`${BaseURLMasterCastes}`)
            .then((response) => {
                setCasteData(response.data);
            })
    }

    const saveBtnClick = async (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        const saveObject = {
            casteId: casteId.id,
            nameMr: subCasteMarathi,
            nameEn: subCasteEnglish,
        };
        await axios.post(`${SubCasteSaveSubCaste}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getSubCasteData();
                   
                    // get modal
                    const modal = document.getElementById('subCasteModal');

                    // change state like in hidden modal
                    modal.classList.remove('show');
                    modal.setAttribute('aria-hidden', 'true');
                    modal.setAttribute('style', 'display: none');

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                    // remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);

                    toast.success("Sub-Caste Saved sucessfully.");
                } else {
                    toast.danger("Operation failed.");
                }
            });

        setSubCasteMarathi("");
        setSubCasteEnglish("");

    };

    useEffect(() => {
        getCasteList()
    }, [])


    return (
        <>
            <div className="modal fade" id="subCasteModal" tabIndex="-1" aria-labelledby="subCasteModalLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                            <h5 className="modal-title" id="subCasteModalLabel">Add New Sub-Caste</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={saveBtnClick}>
                            <div className="modal-body">

                                <Autocomplete
                                    id="combo-box-demo"
                                    options={casteData}                                   
                                    onChange={(event, newValue) => {
                                        setCasteId(newValue);                                        
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.nameMr + " / " + option.nameEn}
                                    size="small"
                                    margin="dense"
                                    value={casteId}
                                    renderInput={params => (
                                        <TextField {...params} label="Select Caste" autoComplete="off" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && subCasteMarathi.length === 0) ? e.preventDefault() : ""}
                                    value={subCasteMarathi}
                                    onChange={(e) => setSubCasteMarathi(e.target.value)}
                                    label="Sub-Caste Marathi"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && subCasteEnglish.length === 0) ? e.preventDefault() : ""}
                                    value={subCasteEnglish}
                                    onChange={(e) => setSubCasteEnglish(e.target.value)}
                                    label="Sub-Caste English"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" >Save</button>
                                <button type="reset" className="btn btn-danger" onClick={()=>{setSubCasteMarathi("");setSubCasteEnglish("");setCasteId(null);}} data-bs-dismiss="modal">Cancle</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AddNewSubCaste