import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import Loader from '../../CommonComponent/Loader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { getDonationFeeList, printDengiPavatiFromTo, printFeeWiseDengiPavati } from '../Services/DengiPavatiAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const DengiPavatiReport = () => {
    const classes = useStyles();
    const titleId = "Dengi Pavati Report";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [dateCheckFrom, setDateCheckFrom] = useState(null);
    const [dateCheckTo, setDateCheckTo] = useState(null);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [id, setId] = useState();
    const [reportOptionId, setReportOptionId] = useState(1);
    const [feeObj, setFeeObj] = useState(null);
    const [feeData, setFeeData] = useState([]);

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 2, 31);

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        await axios(`${getDonationFeeList}`)
            .then((response) => {
                setFeeData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    const allDengiPavatiReport = async () => {
        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            setLoaderOption(true);
            await axios.get(`${printDengiPavatiFromTo}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Dengi Pavati not found for this date")
                    }
                    else if (response.data != null) {
                        console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Dengi pavati Report failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Invalid date");
        }

    }

    const dengiPavatiFeeWiseReport = async () => {
        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            setLoaderOption(true);
            await axios.get(`${printFeeWiseDengiPavati}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeObj.id}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Dengi Pavati not found for this date or fee")
                    }
                    else if (response.data != null) {
                        console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Dengi pavati Report failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Invalid date");
        }
    }

    const getDengiPavatiReport = e => {
        e.preventDefault();
        if (reportOptionId == 1) {
            allDengiPavatiReport();
        }
        else if (reportOptionId == 2) {
            dengiPavatiFeeWiseReport();
        }
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={getDengiPavatiReport}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row my-1">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={reportOptionId}
                                            label="Select Option"
                                            onChange={(e) => {
                                                setReportOptionId(e.target.value);
                                                setFeeObj(null);
                                                setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
                                                setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
                                            }}
                                        >
                                            <MenuItem value={1}>All</MenuItem>
                                            <MenuItem value={2}>Fee Wise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date From"
                                            value={dateFrom}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom(null)
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckFrom("Invalid date");
                                                } else {
                                                    setDateCheckFrom("");
                                                }
                                            }}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheckFrom}</em></span> */}
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date To :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date To"
                                            value={dateTo}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateTo(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckTo("Invalid date");
                                                } else {
                                                    setDateCheckTo("");
                                                }
                                            }}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheckTo}</em></span> */}
                                </div>
                            </div>

                            {(() => {
                                if (reportOptionId == 2) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Fee Name :</label>
                                                <div className='col-sm-3'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        options={feeData}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        size="small"
                                                        value={feeObj}
                                                        onChange={(event, newvalue) => {
                                                            setFeeObj(newvalue);
                                                        }}

                                                        renderInput={params => (
                                                            <TextField {...params} margin="dense" label="Select Fee" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                required={(reportOptionId == 2) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })()}
                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default DengiPavatiReport