import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer, toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loader from "../../CommonComponent/Loader";
// import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { instClassAllocationAPI, saveClassAllocation, InstituteMasterSave, InstituteMasterUpdate, getInstituteDataById, BaseURLSection, getCityData } from '../../CommonServices/InstituteMasterAPIURL';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from "@mui/styles/makeStyles";
import DepartmentWiseEndowmentId from './DepartmentMasterForInstitute/DepartmentWiseEndowmentId';
import BranchWiseDepartment from './DepartmentMasterForInstitute/BranchWiseDepartment';
import FooterSignature from './DepartmentMasterForInstitute/FooterSignature';

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`,
//   };
// }

const useStyles = makeStyles(theme => ({
  select: {
    maxHeight: 160,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  },

}));

// const CityData = [
//   {
//     id: 1,
//     cityNameMr: "सांगली",
//     cityNameEn: "Sangli"
//   },
//   {
//     id: 2,
//     cityNameMr: "मिरज",
//     cityNameEn: "Miraj"
//   }
// ]

const InstituteMaster = () => {
  const classes = useStyles();
  const theme = useTheme();
  const authUser = useSelector((state) => state.user.value);
  const [titleId, setTitleId] = useState("")
  const navigate = useNavigate();
  let { instId, EditFlag } = useParams();

  // loader on/off
  const [loaderOption, setLoaderOption] = useState(false);

  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      setTitleId("Update Institute Data");
    }
    else if (newValue == 2) {
      setTitleId("Endowment Id Allocation");
    }
    else if (newValue == 3) {
      setTitleId("Class-Division Allocation");
    }
    else if (newValue == 4) {
      setTitleId("Departments Allocation");
    } else if (newValue == 5) {
      setTitleId("Footer Signature");
    }
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [classData, setClassData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [id, setId] = useState();
  const [schoolNameMr, setSchoolNameMr] = useState('');
  const [schoolNameEn, setSchoolNameEn] = useState('');
  const [cityId, setCityId] = useState(null);
  const [cityObj, setCityObj] = useState(null);
  const [udiseNo, setudiseNo] = useState('');
  const [schoolAddress, setSchoolAddress] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [email, setEmail] = useState('');
  const [instituteType, setInstituteType] = useState('');
  const [medium, setMedium] = useState('');
  const [salgnataNo, setSalgnataNo] = useState('');
  const [lowerClass, setLowerClass] = useState('');
  const [higherClass, setHigherClass] = useState('');
  const [sscBoardNo, setSscBoardNo] = useState('');
  const [hscBoardNo, setHscBoardNo] = useState('');
  const [principalCode, setPrincipalCode] = useState('');
  const [shortName, setShortName] = useState('');
  const [panNo, setPanNo] = useState('');
  const [tanNo, setTanNo] = useState('');
  const [endowmentId, setEndowmentId] = useState('');

  const [sectionData, setSectionData] = useState([]);
  const [sectionId, setSectionId] = useState(null);
  const [sectionObj, setSectionObj] = useState(null);
  const [accountingType, setAccountingType] = useState(1)

  const [searchTerm, setSearchTerm] = useState("");

  const [bId, setBid] = useState('');


  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "btn btn-primary btn-sm";
  const updateBtnStyle = "btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

  const [checkValue, setCheckValue] = useState([]);

  const [feeType, setFeeType] = useState(1);

  //console.log("checkValue=" + JSON.stringify(checkValue));

  useEffect(() => {
    getData();
  }, [authUser]);

  const getData = () => {

    if (EditFlag == 1) {
      setTitleId("Add Institute Data");
      //toast.success("Flagg 1")
      getAPIDetails();
      setSaveBtnDisabled(false);
      setUpdateBtnDisabled(true);
    }
    else if (EditFlag == 2) {
      setTitleId("Update Institute Data");
      //toast.success("Flagg 2")
      getEditDetails();
      setSaveBtnDisabled(true);
      setUpdateBtnDisabled(false);
    }

  }

  const getAPIDetails = async () => {
    await axios.get(`${getCityData}`)
      .then((response) => {
        setCityData(response.data);

      })
  }


  const [tempData, setTempData] = useState([]);
  const [divisionIdFalse, setDivisionIdFalse] = useState('');
  const [divisionIdTrue, setDivisionIdTrue] = useState('');
  const [Val, setVal] = useState(false);

  const handleCheckChange = (val, divId, cId, Bid, Sid) => {
    setVal(val);

    const SaveObjectData = {
      classId: cId,
      divisionId: divId,
      sectionId: sectionId,
      branchId: bId,
    }
    if (val == true) {
      setCheckValue(checkValue.concat(SaveObjectData));
      //console.log(JSON.stringify(SaveObjectData));

      setDivisionIdTrue(SaveObjectData.divisionId);
      setDivisionIdFalse('');

      //if checkbox is checked
      let updatedData = tempData;
      tempData.map((e, index) => {
        if (e.divisionId == divisionIdTrue) {
          updatedData[index] = ({ ...e, sectionId: sectionId, branchId: bId, isAllocated: 1 })
        }

      })
      //console.log("updatedData=======" + JSON.stringify(updatedData));
      if (searchTerm == "") {
        setClassData(updatedData);
        setFilteredData(updatedData)
      }
    }
    else if (val == false) {
      setCheckValue(checkValue.filter((item) => item.divisionId != divId))

      setDivisionIdFalse(SaveObjectData.divisionId);
      setDivisionIdTrue('');

      //if checkbox is unChecked
      let updatedData = tempData;
      tempData.map((e, index) => {
        if (e.divisionId == divisionIdFalse) {
          updatedData[index] = ({ ...e, sectionId: sectionId, branchId: bId, isAllocated: 0 })
        }

      })
      //console.log("updatedData=======" + JSON.stringify(updatedData));
      if (searchTerm == "") {
        setClassData(updatedData);
        setFilteredData(updatedData);
      }
    }

  }


  useEffect(() => {

    //if checkbox is checked after search
    let updatedData2 = tempData;
    tempData.map((e, index) => {
      if (e.divisionId == divisionIdTrue) {
        updatedData2[index] = ({ ...e, sectionId: sectionId, branchId: bId, isAllocated: 1 })
      }

    })

    //if checkbox is unChecked after search
    let updatedData = tempData;
    tempData.map((e, index) => {
      if (e.divisionId == divisionIdFalse) {
        updatedData[index] = ({ ...e, sectionId: sectionId, branchId: bId, isAllocated: 0 })
      }

    })

    if (Val == false) {
      setDivisionIdTrue('');
      if (searchTerm == "") {
        setClassData(updatedData);
        setFilteredData(updatedData);
      }
    }
    else if (Val == true) {
      setDivisionIdFalse('');
      if (searchTerm == "") {
        setClassData(updatedData2);
        setFilteredData(updatedData2);
      }
    }

  }, [searchTerm, divisionIdTrue, divisionIdFalse, Val])


  const saveClassData = async () => {
    //e.preventDefault();
    setLoaderOption(true);
    let removedAllData;
    if (checkValue.length === 0) {
      //toast.error("Empty Array........")
      removedAllData = [{
        classId: 0,
        divisionId: 0,
        sectionId: sectionId,
        branchId: bId
      }]
      //console.log(JSON.stringify(removedAllData));
      await axios.post(`${saveClassAllocation}`, removedAllData)
        .then((response) => {
          //console.log(response.data)
          if (response.data == "SAVED") {
            //alert("class Allocation successfully done.");
            getData();
            setFilteredData([]);
            setClassData([]);
            setTempData([]);
            setCheckValue([]);
            setSectionObj(null);
            setSearchTerm("");
            setLoaderOption(false);
            toast.success("Class-division allocation successfully done.");

          } else {
            toast.error("Operation failed.");
          }
        }).catch((error) => {
          console.log("error name: " + error);
        });
    }
    else {
      await axios.post(`${saveClassAllocation}`, checkValue)
        .then((response) => {
          //console.log(response.data)
          if (response.data == "SAVED") {
            //alert("class Allocation successfully done.");
            getData();
            setFilteredData([]);
            setClassData([]);
            setTempData([]);
            setCheckValue([]);
            setSectionObj(null);
            setSearchTerm("");
            setLoaderOption(false);
            toast.success("Class-division allocation successfully done.");

          } else {
            toast.error("Operation failed.");
          }
        }).catch((error) => {
          console.log("error name: " + error);
        });
    }
  }

  const getEditDetails = async () => {

    await axios.get(`${getInstituteDataById}` + instId)
      .then((response) => {
        setLoaderOption(true);
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        setSchoolNameMr(response.data.schoolNameMr);
        setSchoolNameEn(response.data.schoolNameEn);
        setShortName(response.data.shortName);
        setSchoolAddress(response.data.schoolAddress);
        const selectedCity = response.data.city;
        // setCityId(response.data.city);
        // setCityObj(selectedCity);
        setPhone1(response.data.phone1);
        setPhone2(response.data.phone2);
        setEmail(response.data.emailId);
        setudiseNo(response.data.udiseNo);
        setInstituteType((response.data.instituteType == 0) ? instituteType : response.data.instituteType);
        setMedium((response.data.medium == 0) ? medium : response.data.medium);
        setSalgnataNo(response.data.salgnataNo);
        setLowerClass(response.data.lowerClass);
        setHigherClass(response.data.higherClass);
        setSscBoardNo(response.data.sscBoardNo);
        setHscBoardNo(response.data.hscBoardNo);
        setPrincipalCode(response.data.principalCode);
        setPanNo(response.data.panNo);
        setTanNo(response.data.tanNo);
        setBid(response.data.id);
        setEndowmentId(response.data.endowmentId);
        setAccountingType(response.data.accountingType)
        setFeeType(response.data.feeType)

        axios.get(`${getCityData}`)
          .then((response) => {
            setCityData(response.data);
            setCityId(selectedCity);
            setCityObj((response.data).find(e => e.id == selectedCity));
            setLoaderOption(false);
          })
      })

    await axios.get(`${BaseURLSection}`)
      .then((response) => {
        const sortData = (response.data).filter(e => e.acApl === 0)
        setSectionData(sortData);
      })


  }

  const getClassDivData = async (sectionId) => {
    setLoaderOption(true);
    setClassData([]);
    setFilteredData([]);
    setSearchTerm("");
    //console.log(`${instClassAllocationAPI}?sid=${sectionId}&bid=${bId}`);
    await axios.get(`${instClassAllocationAPI}?sid=${sectionId}&bid=${bId}`)
      .then((response) => {
        setClassData(response.data);
        setFilteredData(response.data);
        setTempData(response.data);
        setLoaderOption(false);

        let SaveObj = (response.data).filter(e => {
          return e.isAllocated == 1
        })
        let updatedData = SaveObj;
        SaveObj.map((item, index) => {
          updatedData[index] = ({ ...item, sectionId: sectionId, branchId: bId })
        })
        setCheckValue(checkValue.concat(updatedData))

      })
  }

  const saveBtnClick = () => {
    setLoaderOption(true);
    const saveObject = {
      id: id,
      schoolNameMr: schoolNameMr,
      schoolNameEn: schoolNameEn,
      city: cityId,
      udiseNo: udiseNo,
      schoolAddress: schoolAddress,
      phone1: phone1,
      phone2: phone2,
      emailId: email,
      instituteType: (instituteType == '') ? 0 : instituteType,
      medium: (medium == '') ? 0 : medium,
      salgnataNo: salgnataNo,
      lowerClass: lowerClass,
      higherClass: higherClass,
      sscBoardNo: sscBoardNo,
      hscBoardNo: hscBoardNo,
      principalCode: principalCode,
      shortName: shortName,
      endowmentId: endowmentId,
      panNo: panNo,
      tanNo: tanNo,
      accountingType: accountingType,
      feeType: feeType
    };
    //console.log(JSON.stringify(saveObject))
    axios.post(InstituteMasterSave, saveObject)
      .then((response) => {
        if (response.data == "ENDOWMENTIDEXIST") {
          setLoaderOption(false);
          toast.warn(`Endowment Id ${endowmentId} is already exists.`)
        }
        else if (response.data != null) {
          getData();
          setClassData([]);
          setFilteredData([]);
          setSectionId(null);
          setSectionObj(null);
          setSaveBtn(saveBtnStyle);
          setUpdateBtn(displayNoneBtnStyle);
          setLoaderOption(false);
          navigate("/Home/m129/1")

        } else {
          setLoaderOption(false);
          toast.error("Operation failed.");
        }
      });

  }

  const updateBtnClick = () => {
    setLoaderOption(true);
    const updateObject = {
      id: id,
      schoolNameMr: schoolNameMr,
      schoolNameEn: schoolNameEn,
      city: cityId,
      udiseNo: udiseNo,
      schoolAddress: schoolAddress,
      phone1: phone1,
      phone2: phone2,
      emailId: email,
      instituteType: (instituteType == '') ? 0 : instituteType,
      medium: (medium == '') ? 0 : medium,
      salgnataNo: salgnataNo,
      lowerClass: lowerClass,
      higherClass: higherClass,
      sscBoardNo: sscBoardNo,
      hscBoardNo: hscBoardNo,
      principalCode: principalCode,
      shortName: shortName,
      endowmentId: endowmentId,
      panNo: panNo,
      tanNo: tanNo,
      accountingType: accountingType,
      feeType: feeType
    };
    //console.log(JSON.stringify(updateObject))
    axios.put(`${InstituteMasterUpdate}` + instId, updateObject)
      .then((response) => {
        if (response.data == "ENDOWMENTIDEXIST") {
          setLoaderOption(false);
          toast.warn(`Endowment Id ${endowmentId} is already exists.`)
        }
        else if (response.data != null) {
          setSaveBtn(saveBtnStyle);
          setUpdateBtn(displayNoneBtnStyle);
          getData();
          setLoaderOption(false);
          navigate("/Home/m129/2")

        }
      });


  };

  const ClearData = () => {
    navigate("/Home/m129/0")
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
  }


  const state = {
    button: 1
  };

  const onSubmit = e => {
    e.preventDefault();
    if (state.button === 1) {
      saveBtnClick();
    }
    if (state.button === 2) {
      updateBtnClick();
    }
  };


  const FormTableHeading = [{ label: "Class", key: "class" }, { label: "Division", key: "division" }, { label: "Status", key: "status" }]

  useEffect(() => {
    setFilteredData([])
    let tempFilteredData =
      classData.filter(
        (item) =>
          item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.divisionNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.divisionNameMr.toLowerCase().includes(searchTerm.toLowerCase())
      )
    setFilteredData(tempFilteredData);
  }, [searchTerm]);


  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            {(() => {
              if (EditFlag == 1) {
                return (
                  <>
                    <ToastContainer position="top-right" theme="colored" />
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "550px" }}>
                      <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School Name Marathi:</label>
                          <div className='col-sm-8'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && schoolNameMr.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="School Name Marathi"
                              variant="outlined"
                              margin='dense'
                              value={schoolNameMr}
                              onChange={(e) => setSchoolNameMr(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School Name English:</label>
                          <div className='col-sm-8'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && schoolNameEn.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="School Name English"
                              variant="outlined"
                              margin='dense'
                              value={schoolNameEn}
                              onChange={(e) => setSchoolNameEn(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Short Name:</label>
                          <div className='col-sm-4'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && shortName.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Short Name"
                              variant="outlined"
                              margin='dense'
                              value={shortName}
                              onChange={(e) => setShortName(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                            //inputProps={{ maxLength: 120, style: { textTransform: "uppercase" } }}
                            />
                          </div>
                        </div>


                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Address:</label>
                          <div className='col-sm-8'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && schoolAddress.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Address"
                              variant="outlined"
                              margin='dense'
                              value={schoolAddress}
                              onChange={(e) => setSchoolAddress(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >City:</label>
                          <div className='col-sm-4'>
                            <Autocomplete
                              id="combo-box-demo"
                              options={cityData}
                              PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                              )}
                              onChange={(event, newValue) => {
                                setCityId(newValue.id);
                                setCityObj(newValue);
                              }}
                              value={cityObj}
                              style={{ fontSize: '14px' }}
                              getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                              size="small"
                              renderInput={params => (
                                <TextField {...params} margin='dense' label="Select City" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                              )}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Phone No 1:</label>
                          <div className='col-sm-4'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && phone1.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Phone No 1"
                              variant="outlined"
                              margin='dense'
                              value={phone1}
                              onChange={(e) => setPhone1(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 12 }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Phone No 2:</label>
                          <div className='col-sm-4'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && phone2.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Phone No 2"
                              variant="outlined"
                              margin='dense'
                              value={phone2}
                              onChange={(e) => setPhone2(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 12 }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Email Id:</label>
                          <div className='col-sm-4'>
                            <ValidatorForm>
                              <TextValidator
                                required
                                fullWidth
                                onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                label="Email"
                                autoComplete='off'
                                size='small'
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                value={email}
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                              />
                            </ValidatorForm>
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >PAN No:</label>
                          <div className='col-sm-4'>
                            <TextField
                              fullWidth
                              onKeyDown={(e) => (e.key === " " && panNo.length === 0) ? e.preventDefault() : ""}
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="PAN No"
                              variant="outlined"
                              margin='dense'
                              value={panNo}
                              onChange={(e) => setPanNo(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                            // inputProps={{
                            //   // pattern: "[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}",
                            //   // style: { textTransform: "uppercase" },
                            //   maxLength: 10
                            // }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >TAN No:</label>
                          <div className='col-sm-4'>
                            <TextField
                              fullWidth
                              onKeyDown={(e) => (e.key === " " && tanNo.length === 0) ? e.preventDefault() : ""}
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="TAN No"
                              variant="outlined"
                              margin='dense'
                              value={tanNo}
                              onChange={(e) => setTanNo(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                            // inputProps={{
                            //   pattern: "[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}",
                            //   style: { textTransform: "uppercase" },
                            //   maxLength: 10
                            // }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >U-Dise No:</label>
                          <div className='col-sm-4'>
                            <TextField
                              fullWidth
                              onKeyDown={(e) => (e.key === " " && udiseNo.length === 0) ? e.preventDefault() : ""}
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="U-Dise No"
                              variant="outlined"
                              margin='dense'
                              value={udiseNo}
                              onChange={(e) => setudiseNo(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                            />
                          </div>
                        </div>

                        <div className='row my-3'>
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Institute Type:</label>
                          <div className="col-sm-4 ">
                            <FormControl fullWidth>
                              <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Institute Type</InputLabel>
                              <Select
                                size='small'
                                fullWidth
                                PaperComponent={({ children }) => (
                                  <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                labelId="demo-simple-select-label"
                                margin='dense'
                                id="demo-simple-select"
                                MenuProps={{
                                  classes: { paper: classes.select },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center"
                                  },
                                  getContentAnchorEl: null
                                }}
                                value={instituteType}
                                label="Institute Type"
                                onChange={(e) => setInstituteType(e.target.value)}
                              >
                                <MenuItem value={1}>{(authUser.branchMedium == 1) ? "खाजगी" : "Private"}</MenuItem>
                                <MenuItem value={2}>{(authUser.branchMedium == 1) ? "सार्वजनिक" : "Public"}</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className='row my-3'>
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Medium:</label>
                          <div className="col-sm-4 ">
                            <FormControl fullWidth>
                              <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Medium</InputLabel>
                              <Select
                                size='small'
                                PaperComponent={({ children }) => (
                                  <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                variant="outlined"
                                fullWidth
                                labelId="demo-simple-select-label"
                                margin='dense'
                                id="demo-simple-select"
                                MenuProps={{
                                  classes: { paper: classes.select },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center"
                                  },
                                  getContentAnchorEl: null
                                }}
                                value={medium}
                                label="Medium"
                                onChange={(e) => setMedium(e.target.value)}
                              >
                                <MenuItem value={1}>{(authUser.branchMedium == 1) ? "मराठी" : "Marathi"}</MenuItem>
                                <MenuItem value={2}>{(authUser.branchMedium == 1) ? "इंग्रजी" : "English"}</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Salgnata No:</label>
                          <div className='col-sm-4'>
                            <TextField
                              fullWidth
                              onKeyDown={(e) => (e.key === " " && salgnataNo.length === 0) ? e.preventDefault() : ""}
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Salgnata No"
                              variant="outlined"
                              margin='dense'
                              value={salgnataNo}
                              onChange={(e) => setSalgnataNo(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Lower Class:</label>
                          <div className='col-sm-4'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && lowerClass.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Lower Class"
                              variant="outlined"
                              margin='dense'
                              value={lowerClass}
                              onChange={(e) => setLowerClass(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>

                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Higher Class:</label>
                          <div className='col-sm-4'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && higherClass.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Higher Class"
                              margin='dense'
                              variant="outlined"
                              value={higherClass}
                              onChange={(e) => setHigherClass(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >SSC Board No:</label>
                          <div className='col-sm-4'>
                            <TextField
                              fullWidth
                              onKeyDown={(e) => (e.key === " " && sscBoardNo.length === 0) ? e.preventDefault() : ""}
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="SSC Board No"
                              variant="outlined"
                              margin='dense'
                              value={sscBoardNo}
                              onChange={(e) => setSscBoardNo(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>

                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >HSC Board No:</label>
                          <div className='col-sm-4'>
                            <TextField
                              fullWidth
                              onKeyDown={(e) => (e.key === " " && hscBoardNo.length === 0) ? e.preventDefault() : ""}
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="HSC Board No"
                              variant="outlined"
                              margin='dense'
                              value={hscBoardNo}
                              onChange={(e) => setHscBoardNo(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Principal Code:</label>
                          <div className='col-sm-4'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && principalCode.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Principal Code"
                              variant="outlined"
                              margin='dense'
                              value={principalCode}
                              onChange={(e) => setPrincipalCode(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>

                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Endowment ID:</label>
                          <div className='col-sm-4'>
                            <TextField
                              //required
                              fullWidth
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) => (e.key === " " && endowmentId.length === 0) ? e.preventDefault() : ""}
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Endowment Id"
                              variant="outlined"
                              margin='dense'
                              value={endowmentId}
                              onChange={(e) => setEndowmentId(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Accounting Type:</label>
                          <div className='col-sm-4'>
                            {/* <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && principalCode.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="Principal Code"
                              variant="outlined"
                              margin='dense'
                              value={principalCode}
                              onChange={(e) => setPrincipalCode(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            /> */}
                            <Select
                              required
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={accountingType}
                              label="Accounting Type"
                              onChange={(e) => setAccountingType(e.target.value)}
                              size='small'
                            >
                              <MenuItem value={1}>Single Side</MenuItem>
                              <MenuItem value={2}>Dual Side</MenuItem>
                            </Select>
                          </div>
                        </div>

                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Fee Type:</label>
                          <div className='col-sm-4'>
                            <FormControl fullWidth margin='dense'>
                              <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Fee Type</InputLabel>
                              <Select
                                size='small'
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={feeType}
                                label="Select Fee Type"
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                onChange={(e) => setFeeType(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? feeType : ""}
                              >

                                <MenuItem value={1}>Single Type</MenuItem>
                                <MenuItem value={2}>Multi Type</MenuItem>
                              </Select>
                            </FormControl>

                          </div>
                        </div>



                        <div className="col-sm-12 mt-3">
                          <div className="offset-sm-2">
                            <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                            <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                            <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                )
              }
              else if (EditFlag == 2) {
                return (
                  <>
                    <ToastContainer position="top-right" theme="colored" />
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                      <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className="row my-3">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School Name Marathi:</label>
                          <div className='col-sm-8'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && schoolNameMr.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="School Name Marathi"
                              variant="outlined"
                              margin='dense'
                              value={schoolNameMr}
                              onChange={(e) => setSchoolNameMr(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>
                        </div>

                        <div className="row my-2">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School Name English:</label>
                          <div className='col-sm-8'>
                            <TextField
                              required
                              onKeyDown={(e) => (e.key === " " && schoolNameEn.length === 0) ? e.preventDefault() : ""}
                              fullWidth
                              autoComplete='off'
                              size='small'
                              id="outlined-basic"
                              label="School Name English"
                              variant="outlined"
                              margin='dense'
                              value={schoolNameEn}
                              onChange={(e) => setSchoolNameEn(e.target.value)}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{ maxLength: 120 }}
                            />
                          </div>
                        </div>

                        <div className='' style={{ backgroundColor: "#FDFEFE", padding: "", boxShadow: "0px 3px 10px grey", marginTop: "40px", borderRadius: "8px", minHeight: "350px" }}>
                          <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example"
                                  style={{
                                    backgroundColor: "#63a4ff",
                                    backgroundImage: "linear-gradient(315deg, #63a4ff 0%, #83eaf1 74%)",
                                    borderTopLeftRadius: "8px",
                                    borderTopRightRadius: "8px"
                                  }}>
                                  {/* <div style={{ border: "1px solid black", backgroundColor: "#fff", borderRadius: "8px", margin: "10px" }}> */}
                                  <Tab label="Details" value={1} style={{ fontSize: "16px", fontWeight: "700", textTransform: "capitalize" }} />
                                  {/* </div> */}
                                  <Tab label="Endowment Id Allocation" value={2} style={{ fontSize: "16px", fontWeight: "700", textTransform: "capitalize" }} />
                                  <Tab label="Class-division Allocation" value={3} style={{ fontSize: "16px", fontWeight: "700", textTransform: "capitalize" }} />
                                  <Tab label="Budget Departments Allocation" value={4} style={{ fontSize: "16px", fontWeight: "700", textTransform: "capitalize" }} />
                                  <Tab label="Footer Signature" value={5} style={{ fontSize: "16px", fontWeight: "700", textTransform: "capitalize" }} />
                                </TabList>
                              </Box>
                              <TabPanel value={1}>
                                <>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Short Name:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && shortName.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Short Name"
                                        variant="outlined"
                                        margin='dense'
                                        //style={{ backgroundColor: "#fff" }}
                                        value={shortName}
                                        onChange={(e) => setShortName(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Address:</label>
                                    <div className='col-sm-8'>
                                      <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && schoolAddress.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Address"
                                        variant="outlined"
                                        margin='dense'
                                        value={schoolAddress}
                                        onChange={(e) => setSchoolAddress(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >City:</label>
                                    <div className='col-sm-4'>
                                      <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={cityData}
                                        onChange={(event, newValue) => {
                                          setCityId(newValue.id);
                                          setCityObj(newValue);
                                        }}
                                        value={cityObj}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                          <TextField {...params} margin='dense' label="Select City" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Phone No 1:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && phone1.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Phone No 1"
                                        variant="outlined"
                                        margin='dense'
                                        value={phone1}
                                        onChange={(e) => setPhone1(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 12 }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Phone No 2:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && phone2.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Phone No 2"
                                        variant="outlined"
                                        margin='dense'
                                        value={phone2}
                                        onChange={(e) => setPhone2(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 12 }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Email Id:</label>
                                    <div className='col-sm-4'>
                                      <ValidatorForm>
                                        <TextValidator
                                          required
                                          fullWidth
                                          onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                          label="Email"
                                          autoComplete='off'
                                          size='small'
                                          onChange={(e) => setEmail(e.target.value)}
                                          name="email"
                                          value={email}
                                          variant="outlined"
                                          margin='dense'
                                          InputLabelProps={{ style: { fontSize: 14 } }}
                                          inputProps={{ maxLength: 120 }}
                                          validators={['required', 'isEmail']}
                                          errorMessages={['this field is ', 'email is not valid']}
                                        />
                                      </ValidatorForm>
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >PAN No:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && panNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="PAN No"
                                        variant="outlined"
                                        margin='dense'
                                        value={panNo}
                                        onChange={(e) => setPanNo(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                      // inputProps={{
                                      //   pattern: "[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}",
                                      //   style: { textTransform: "uppercase" },
                                      //   maxLength: 10
                                      // }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >TAN No:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && tanNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="TAN No"
                                        variant="outlined"
                                        margin='dense'
                                        value={tanNo}
                                        onChange={(e) => setTanNo(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                      // inputProps={{
                                      //   pattern: "[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}",
                                      //   style: { textTransform: "uppercase" },
                                      //   maxLength: 10
                                      // }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >U-Dise No:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && udiseNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="U-Dise No"
                                        variant="outlined"
                                        margin='dense'
                                        value={udiseNo}
                                        onChange={(e) => setudiseNo(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}

                                      />
                                    </div>
                                  </div>

                                  <div className='row my-3'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Institute Type:</label>
                                    <div className="col-sm-4 ">
                                      <FormControl fullWidth>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Institute Type</InputLabel>
                                        <Select
                                          size='small'
                                          PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                          )}
                                          fullWidth
                                          margin='dense'
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={{
                                            classes: { paper: classes.select },
                                            anchorOrigin: {
                                              vertical: "bottom",
                                              horizontal: "left"
                                            },
                                            transformOrigin: {
                                              vertical: "top",
                                              horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                          }}
                                          value={instituteType}
                                          label="Institute Type"
                                          onChange={(e) => setInstituteType(e.target.value)}
                                        >
                                          <MenuItem value={1}>{(authUser.branchMedium == 1) ? "खाजगी" : "Private"}</MenuItem>
                                          <MenuItem value={2}>{(authUser.branchMedium == 1) ? "सार्वजनिक" : "Public"}</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>

                                  <div className='row my-3'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Medium:</label>
                                    <div className="col-sm-4 ">
                                      <FormControl fullWidth>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Medium</InputLabel>
                                        <Select
                                          size='small'
                                          variant="outlined"
                                          PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                          )}
                                          fullWidth
                                          margin='dense'
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={{
                                            classes: { paper: classes.select },
                                            anchorOrigin: {
                                              vertical: "bottom",
                                              horizontal: "left"
                                            },
                                            transformOrigin: {
                                              vertical: "top",
                                              horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                          }}
                                          value={medium}
                                          label="Medium"
                                          onChange={(e) => setMedium(e.target.value)}
                                        >
                                          <MenuItem value={1}>{(authUser.branchMedium == 1) ? "मराठी" : "Marathi"}</MenuItem>
                                          <MenuItem value={2}>{(authUser.branchMedium == 1) ? "इंग्रजी" : "English"}</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Salgnata No:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && salgnataNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Salgnata No"
                                        variant="outlined"
                                        margin='dense'
                                        value={salgnataNo}
                                        onChange={(e) => setSalgnataNo(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 10 }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Lower Class:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && lowerClass.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Lower Class"
                                        variant="outlined"
                                        margin='dense'
                                        value={lowerClass}
                                        onChange={(e) => setLowerClass(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      />
                                    </div>

                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Higher Class:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && higherClass.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Higher Class"
                                        margin='dense'
                                        variant="outlined"
                                        value={higherClass}
                                        onChange={(e) => setHigherClass(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >SSC Board No:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && sscBoardNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="SSC Board No"
                                        variant="outlined"
                                        margin='dense'
                                        value={sscBoardNo}
                                        onChange={(e) => setSscBoardNo(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      />
                                    </div>

                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >HSC Board No:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && hscBoardNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="HSC Board No"
                                        variant="outlined"
                                        margin='dense'
                                        value={hscBoardNo}
                                        onChange={(e) => setHscBoardNo(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Principal Code:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && principalCode.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Principal Code"
                                        variant="outlined"
                                        margin='dense'
                                        value={principalCode}
                                        onChange={(e) => setPrincipalCode(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      />
                                    </div>

                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Endowment ID:</label>
                                    <div className='col-sm-4'>
                                      <TextField
                                        //required
                                        fullWidth
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => (e.key === " " && endowmentId.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Endowment Id"
                                        variant="outlined"
                                        margin='dense'
                                        value={endowmentId}
                                        onChange={(e) => setEndowmentId(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Accounting Type:</label>
                                    <div className='col-sm-4'>
                                      {/* <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && principalCode.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Principal Code"
                                        variant="outlined"
                                        margin='dense'
                                        value={principalCode}
                                        onChange={(e) => setPrincipalCode(e.target.value)}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                      /> */}
                                      <FormControl fullWidth margin='dense'>
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Accounting Type</InputLabel>
                                        <Select
                                          required
                                          fullWidth
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={accountingType}
                                          label="Accounting Type"
                                          onChange={(e) => setAccountingType(e.target.value)}
                                          size='small'
                                        >
                                          <MenuItem value={1}>Single Side</MenuItem>
                                          <MenuItem value={2}>Dual Side</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>

                                  <div className="row my-3">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Fee Type:</label>
                                    <div className='col-sm-4'>
                                      <FormControl fullWidth margin='dense'>
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Fee Type</InputLabel>
                                        <Select
                                          size='small'
                                          required
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={feeType}
                                          label="Select Fee Type"
                                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                                          onChange={(e) => setFeeType(e.target.value)}
                                          onKeyPress={(e) => (e.key == "Enter") ? feeType : ""}
                                        >

                                          <MenuItem value={1}>Single Type</MenuItem>
                                          <MenuItem value={2}>Multi Type</MenuItem>
                                        </Select>
                                      </FormControl>

                                    </div>
                                  </div>

                                  <div className="col-sm-12 mt-3">
                                    <div className="offset-sm-2">
                                      <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                      <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                      <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                    </div>
                                  </div>
                                </>
                              </TabPanel>

                              <TabPanel value={2}>
                                <>
                                  <DepartmentWiseEndowmentId branchId={bId} />
                                </>
                              </TabPanel>

                              <TabPanel value={3}>
                                <>
                                  <div className='row my-3 mx-1'>
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Section:</label>
                                    <div className="col-sm-4">
                                      <Autocomplete
                                        id="combo-box-demo"
                                        options={sectionData}
                                        PaperComponent={({ children }) => (
                                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        onChange={(event, newValue) => {
                                          setSectionId(newValue.id);
                                          setSectionObj(newValue);
                                          getClassDivData(newValue.id);
                                        }}
                                        value={sectionObj}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                          <TextField {...params} margin='dense' label="Select Section" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                      />
                                    </div>
                                  </div>
                                  {(classData == null || classData == "")
                                    ?
                                    <h4 className='mt-5'>No records found, Select Section</h4>
                                    :
                                    <div>
                                      <div className='row my-1'>
                                        <div className='offset-sm-6 mt-1'>
                                          <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "left", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            value={searchTerm}
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                          />
                                        </div>
                                      </div>
                                      <div className='table-responsive col-sm-8 offset-sm-1' style={{ maxHeight: "330px" }} >
                                        <table className="table table-bordered">
                                          <thead className="" style={{ position: "sticky", zIndex: "1", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                            <tr>
                                              {FormTableHeading.map((item, index) => {
                                                return (
                                                  <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col" key={index}>{item.label}</th>
                                                )
                                              })}
                                            </tr>
                                          </thead>
                                          <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.map((item, index) => {
                                              return (
                                                <tr key={index} className="table-sm">
                                                  <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                  <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.divisionNameMr : item.divisionNameEn}</td>
                                                  <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                    <Checkbox defaultChecked={(item.isAllocated == 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                      onChange={(e) => {
                                                        let val = e.target.checked;
                                                        handleCheckChange(val, item.divisionId, item.classId, item.branchId, item.sectionId);
                                                      }
                                                      }
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      </div>


                                      <div className="row mt-3 mx-1">
                                        <div className="offset-sm-1">
                                          <button type="button" className="btn btn-sm btn-primary" onClick={() => saveClassData()}>Save</button>
                                          <button type="button" onClick={() => { setFilteredData([]); setClassData([]); setSectionObj(null); }} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </>
                              </TabPanel>

                              <TabPanel value={4}>
                                <>
                                  <BranchWiseDepartment branchId={bId} />
                                </>
                              </TabPanel>
                              <TabPanel value={5}>
                                <>
                                  <FooterSignature branchId={bId} />
                                </>
                              </TabPanel>
                            </TabContext>
                          </Box>
                        </div>

                        {/* <Box sx={{ bgcolor: 'background.paper', width: 500 }}>
                          <AppBar position="static">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="secondary"
                              textColor="inherit"
                              variant="fullWidth"
                              aria-label="full width tabs example"
                            >
                              <Tab label="Item One" {...a11yProps(0)} />
                              <Tab label="Item Two" {...a11yProps(1)} />
                              <Tab label="Item Three" {...a11yProps(2)} />
                              <Tab label="Item Four" {...a11yProps(3)} />
                            </Tabs>
                          </AppBar>
                          <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                          >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                              Item One
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                              Item Two
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                              Item Three
                            </TabPanel>
                            <TabPanel value={value} index={3} dir={theme.direction}>
                              Item Four
                            </TabPanel>
                          </SwipeableViews>
                        </Box> */}
                      </form>
                    </div>
                  </>
                )
              }
            })()}
          </>
      }

      <ToastContainer position="top-right" theme="colored" />
    </>
  )

};

export default InstituteMaster;
