import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-svg-core/styles.css'
import './index.css';
import './vendors/horizontal-layout/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import userReducer from './reduxFeature/user';
import { HashRouter } from 'react-router-dom'


const store = configureStore({
  reducer: {
    user: userReducer,
  }
})

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>,
  document.getElementById('root')
);

reportWebVitals();
