import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

////localhost:8080/institute/sss/administration/reports/generateBonafideReport?langId=1&regNo=10011&sid=3&bid=1
//localhost:8080/institute/sss/administration/reports/generateBonafideReport?langId=1&regNo=10008&sid=3&bid=1
export const printBonafideAPIURL = BaseURL + "administration/reports/generateBonafideReport";

export const printMultiStudentBonafideAPIURL = BaseURL + "administration/reports/generateMultiStudentBonafideReport";

export const getPrintBonafideAPIURL = BaseIP + "/output/";


export const BaseURLStudenEntry = BaseURL + "administration/studentEntry/"
export const getStudentList = BaseURLStudenEntry + "studentEntryList/";
export const classWithDivisionURL = BaseURLMaster + "classWithDivisionBySectionId";

//get students by classId
//localhost:8080/institute/sss/administration/reports/generateStudentDataForBonafideReport?classId=10&divId=37&acYear=2021&sid=3&bid=1

export const getStudentListByClass = BaseURL + "administration/reports/generateStudentDataForBonafideReport";

//localhost:8080/institute/sss/administration/reports/generateBulkBonafideReport
export const generateBulkBonafideReportAPIURL = BaseURL + "administration/reports/generateBulkBonafideReport"

//PostMapping
//localhost:8080/institute/sss/administration/reports/generateBonafideReportManually
export const manualBonafideSaveAndPrint = BaseURL + "administration/reports/generateBonafideReportManually"

//localhost:8081/institute-sss/institute/sss/administration/bonafideOptions/getBonafideOptions?sid=3&bid=1
export const getBonafideOptionsAPIURL = BaseURL + "administration/bonafideOptions/getBonafideOptions";

