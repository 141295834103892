import React from 'react'

const TimeSlotUpdationModal = (props) => {
    return (

        <div className="modal fade" id="UpdateTimeTableModal" tabIndex="-1" aria-labelledby="UpdateTimeTableModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header mt-3">
                        <h4 className="modal-title" id="UpdateTimeTableModalLabel">Teacher allocated time table data will be deleted if your changes applied. Are you sure?</h4>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" onClick={() => props.updateDataForDataPresent()} className="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeSlotUpdationModal
