import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { printReceiptAndChalanDateWise, printReceiptAndChalanTransactionWise } from '../Services/ReceiptAndChalanAPIURL';
import moment from 'moment';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const ReceiptAndChalanReport = () => {
    const classes = useStyles();
    const titleId = "Receipt And Chalan";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    //console.log(defaultMaxDate);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [reportOptionId, setReportOptionId] = useState(1);
    const [filterOptionId, setFilterOptionId] = useState(3);
    const [id, setId] = useState();
    const [transactionId, setTransactionId] = useState();
    const [dateCheckFrom, setDateCheckFrom] = useState(null);
    const [dateCheckTo, setDateCheckTo] = useState(null);

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 2, 31);

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])

    const ReceiptAndChalanDateWisePrint = async () => {

        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom !== "Invalid date" && dateCheckTo !== "Invalid date") {
            setLoaderOption(true);
            //console.log(`${printReceiptAndChalanDateWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}&isReceipt=${filterOptionId}&langId=${authUser.branchMedium}`)
            await axios.get(`${printReceiptAndChalanDateWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}&isReceipt=${filterOptionId}&langId=1`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found, please check.")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        setLoaderOption(false);
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Receipt and chalan failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check");
        }
    }

    const ReceiptAndChalanTransactionWisePrint = async () => {
        setLoaderOption(true);
        //console.log(`${printReceiptAndChalanTransactionWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&voucherNo=${transactionId}&isReceipt=${filterOptionId}&langId=${authUser.branchMedium}`)
        await axios.get(`${printReceiptAndChalanTransactionWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&deptName=${authUser.deptName}&voucherNo=${transactionId}&isReceipt=${filterOptionId}&langId=1`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found, please check.")
                }
                else if (response.data != null) {
                    //console.log(response.data)
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                //console.log("Receipt and chalan failed: " + error)
            })
        //toast.success("Valid")       
    }

    const getReceiptAndChalanReport = e => {
        e.preventDefault();
        if (reportOptionId == 1) {
            ReceiptAndChalanDateWisePrint();
        }
        else if (reportOptionId == 2) {
            ReceiptAndChalanTransactionWisePrint();
        }
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={getReceiptAndChalanReport}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Filter Choice :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Choice</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={reportOptionId}
                                            label="Select Choice"
                                            onChange={(e) => {
                                                setReportOptionId(e.target.value);
                                                setFilterOptionId(3);
                                                setTransactionId('');
                                                setDateFrom(null);
                                                setDateTo(null);
                                            }}
                                        >
                                            <MenuItem value={1}>Date Range</MenuItem>
                                            <MenuItem value={2}>Transaction Id</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(() => {
                                if (reportOptionId == 1) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>From :</label>
                                                <div className='col-sm-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="From"
                                                            value={dateFrom}
                                                            minDate={defaultMinDate}
                                                            maxDate={defaultMaxDate}
                                                            inputFormat="dd/MM/yyyy"
                                                            onChange={(newValue) => {
                                                                setDateFrom(newValue);
                                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom(null)
                                                                const maxDate = new Date(defaultMaxDate);
                                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                                    setDateCheckFrom("Invalid date");
                                                                } else {
                                                                    setDateCheckFrom("");
                                                                }
                                                            }}
                                                            openTo="day"
                                                            // Show defaultMaxDate selected when the DatePicker is opened
                                                            // If dateFrom is not set, defaultMaxDate is used
                                                            onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(reportOptionId == 1) ? true : false}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    size="small" />}
                                                        />
                                                    </LocalizationProvider>
                                                    {/* <span style={dateCheck}><em style={{fontSize:"12px",color:"red",fontWeight:"600"}}>*Invalid Date Format</em></span> */}
                                                </div>

                                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                                <div className='col-sm-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="To"
                                                            value={dateTo}
                                                            minDate={defaultMinDate}
                                                            maxDate={defaultMaxDate}
                                                            inputFormat="dd/MM/yyyy"
                                                            onChange={(newValue) => {
                                                                setDateTo(newValue);
                                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                                                const maxDate = new Date(defaultMaxDate);
                                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                                    setDateCheckTo("Invalid date");
                                                                } else {
                                                                    setDateCheckTo("");
                                                                }
                                                            }}
                                                            openTo="day"
                                                            // Show defaultMaxDate selected when the DatePicker is opened
                                                            // If dateFrom is not set, defaultMaxDate is used
                                                            onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(reportOptionId == 1) ? true : false}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    size="small" />}
                                                        />
                                                    </LocalizationProvider>
                                                    {/* <span style={dateCheck}><em style={{fontSize:"12px",color:"red",fontWeight:"600"}}>*Invalid Date Format</em></span> */}
                                                </div>
                                            </div>


                                        </>
                                    )
                                }
                                else if (reportOptionId == 2) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Transaction Id :</label>
                                                <div className='col-sm-3'>
                                                    <TextField
                                                        required={(reportOptionId == 2) ? true : false}
                                                        fullWidth
                                                        onWheel={(e) => e.target.blur()}
                                                        type="number"
                                                        autoComplete='off'
                                                        size='small'
                                                        id="outlined-basic"
                                                        label="Transaction Id"
                                                        variant="outlined"
                                                        margin='dense'
                                                        value={transactionId}
                                                        onChange={e => setTransactionId(e.target.value)}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        inputProps={{ maxLength: 120 }}
                                                    />
                                                </div>
                                            </div>


                                        </>
                                    )
                                }
                            })()}

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Filter R/C :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterOptionId}
                                            label="Select Option"
                                            onChange={(e) => setFilterOptionId(e.target.value)}
                                        >
                                            <MenuItem value={3}>All</MenuItem>
                                            <MenuItem value={1}>Receipt</MenuItem>
                                            <MenuItem value={2}>Chalan</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm" >Show</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }


}

export default ReceiptAndChalanReport