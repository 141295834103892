import React, { useState } from 'react'

const MonthlyWorkDaysTextBox = ({ initialText1, initialText2, initialText3, onChange }) => {
    const [isEdit1, setEdit1] = useState(false);
    const [isEdit2, setEdit2] = useState(false);
    const [isEdit3, setEdit3] = useState(false);
    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    const [text3, setText3] = useState(initialText3);

    // if (isEdit1 == true) {
    //     return (
    //         <>
    //             <td style={{ width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }}>
    //                 <input
    //                     type='number'
    //                     className='form-control form-control-sm' style={{ width: "100px", fontWeight: "500",fontSize: "15px" }}
    //                     onFocus={() => setEdit1(true)}
    //                     onBlur={() => { setEdit1(false); onChange(text1, text2, text3) }}

    //                     onChange={(e) => setText1(e.target.value)}
    //                     value={text1}
    //                 />
    //             </td>
    //             <td style={{ width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }} onClick={() => setEdit2(true)}>{text2}</td>
    //             <td style={{ width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }} onClick={() => setEdit3(true)}>{text3}</td>
    //         </>
    //     )
    // }
    // else if (isEdit2 == true) {
    //     return (
    //         <>
    //             <td style={{width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }} onClick={() => setEdit1(true)}>{text1}</td>
    //             <td style={{ width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }}>
    //                 <input
    //                     type='number'
    //                     className='form-control form-control-sm' style={{ width: "100px", fontWeight: "500",fontSize: "15px" }}
    //                     onFocus={() => setEdit2(true)}
    //                     onBlur={() => {setEdit2(false); onChange(text1, text2, text3) }}

    //                     onChange={(e) => setText2(e.target.value)}
    //                     value={text2}
    //                 />
    //             </td>
    //             <td style={{ width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }} onClick={() => setEdit3(true)}>{text3}</td>

    //         </>
    //     )
    // }
    // else if (isEdit3 == true) {
    //     return (
    //         <>
    //             <td style={{ width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }} onClick={() => setEdit1(true)}>{text1}</td>
    //             <td style={{width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }} onClick={() => setEdit2(true)}>{text2}</td>
    //             <td style={{ width:"140px",height: "70px" , fontWeight: "500",fontSize: "15px"}}>
    //                 <input
    //                     type='number'
    //                     className='form-control form-control-sm' style={{ width: "100px", fontWeight: "500",fontSize: "15px" }}
    //                     onFocus={() => setEdit3(true)}
    //                     onBlur={() => { setEdit3(false); onChange(text1, text2, text3) }}

    //                     onChange={(e) => setText3(e.target.value)}
    //                     value={text3}
    //                 />
    //             </td>
    //         </>
    //     )
    // }
    // else if (isEdit2 == false && isEdit1 == false && isEdit3 == false) {
    //     return (
    //         <>
    //             <td style={{ width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }} onClick={() => setEdit1(true)}>{text1}</td>
    //             <td style={{ width:"140px", height: "70px" , fontWeight: "500",fontSize: "15px"}} onClick={() => setEdit2(true)}>{text2}</td>
    //             <td style={{ width:"140px", height: "70px", fontWeight: "500",fontSize: "15px" }} onClick={() => setEdit3(true)}>{text3}</td>
    //         </>
    //     )
    // }

    return (
        <>
            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    //style={{ width: "100px", fontWeight: "500",fontSize: "15px" }}
                    style={(isEdit1 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit1(true)}
                    onBlur={() => {
                        if (text1 == "" || text1 == null) {
                            setText1(0);
                        }
                        setEdit1(false);
                        onChange(text1, text2, text3)
                    }}

                    onChange={(e) => setText1(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                    value={text1}
                />
            </td>
            {/* <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }} onClick={() => setEdit2(true)}>{text2}</td>
            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }} onClick={() => setEdit3(true)}>{text3}</td> */}


            {/* <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }} onClick={() => setEdit1(true)}>{text1}</td> */}
            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    //style={{ width: "100px", fontWeight: "500", fontSize: "15px" }}
                    style={(isEdit2 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit2(true)}
                    onBlur={() => {
                        if (text2 == "" || text2 == null) {
                            setText2(0);
                        }
                        setEdit2(false);
                        onChange(text1, text2, text3)
                    }}

                    onChange={(e) => setText2(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                    value={text2}
                />
            </td>
            {/* <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }} onClick={() => setEdit3(true)}>{text3}</td> */}


            {/* <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }} onClick={() => setEdit1(true)}>{text1}</td>
            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }} onClick={() => setEdit2(true)}>{text2}</td> */}
            <td style={{ width: "140px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    //style={{ width: "100px", fontWeight: "500", fontSize: "15px" }}
                    style={(isEdit3 === true) ? { width: "100px", fontSize: "15px", } : { width: "100px", fontSize: "15px", border: "0px" }}
                    onFocus={() => setEdit3(true)}
                    onBlur={() => {
                        if (text3 == "" || text3 == null) {
                            setText3(0);
                        }
                        setEdit3(false);
                        onChange(text1, text2, text3)
                    }}

                    onChange={(e) => setText3(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                    value={text3}
                />
            </td>

        </>
    )

}

export default MonthlyWorkDaysTextBox