import React, { useState, useEffect } from 'react';
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { classWithDivisionURL, getExamListForResultSheet } from '../Services/ExamReportAPIURL';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import makeStyles from "@mui/styles/makeStyles";
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';

import { getSubjectDataForSubMarkSheet } from '../Services/ExamReportAPIURL';
import { printTermWiseStudentRank, printExamWiseStudentRank } from '../Services/StudentRankAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import Loader from '../../CommonComponent/Loader';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const StudentRank = () => {
    const titleId = "Rank";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState();
    const [examData, setExamData] = useState([]);
    const [examObj, setExamObj] = useState(null);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [leftFilteredData, setLeftFilteredData] = useState([]);
    const [leftSearchTerm, setLeftSearchTerm] = useState('');
    const [right, setRight] = useState([]);
    const [rightFilteredData, setRightFilteredData] = useState([]);
    const [rightSearchTerm, setRightSearchTerm] = useState('');
    const [reportOptionId, setReportOptionId] = useState(1);
    const [term, setTerm] = useState('');
    const [rankCount, setRankCount] = useState('');
    const [subOption, setSubOption] = useState(1);

    //console.log("rightData=" + JSON.stringify(right))
    //+"................."+"LeftData="+JSON.stringify(left))

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const getSubjectWiseRankDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (reportOptionId == 1) {
            let singleObj = [];
            if (subOption == 1) {
                singleObj = [{
                    "id": 0,
                    "subjectNameEn": "",
                    "subjectNameMr": "",
                    "viewType": 0,
                    "markOrGrade": 0
                }]
            }
            else if (subOption == 2) {
                singleObj = right
            }

            //console.log(JSON.stringify(singleObj))
            console.log(`${printTermWiseStudentRank}?langId=${authUser.branchMedium}&classId=${classObj.classId}&divId=${classObj.divisionId}&termId=${term}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}&rankCount=${(rankCount == '') ? 0 : rankCount}&subOption=${subOption}`)
            await axios.post(`${printTermWiseStudentRank}?langId=${authUser.branchMedium}&classId=${classObj.classId}&divId=${classObj.divisionId}&termId=${term}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}&rankCount=${(rankCount == '') ? 0 : rankCount}&subOption=${subOption}`, singleObj)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`)
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Student rank term wise list" + err)
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (reportOptionId == 2) {
            let singleObj = [];
            if (subOption == 1) {
                singleObj = [{
                    "id": 0,
                    "subjectNameEn": "",
                    "subjectNameMr": "",
                    "viewType": 0,
                    "markOrGrade": 0
                }]
            }
            else if (subOption == 2) {
                singleObj = right
            }
            console.log(`${printExamWiseStudentRank}?examId=${examObj.id}&langId=${authUser.branchMedium}&classId=${classObj.classId}&divId=${classObj.divisionId}&termId=${term}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}&rankCount=${(rankCount == '') ? 0 : rankCount}&subOption=${subOption}`)
            await axios.post(`${printExamWiseStudentRank}?examId=${examObj.id}&langId=${authUser.branchMedium}&classId=${classObj.classId}&divId=${classObj.divisionId}&termId=${term}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}&rankCount=${(rankCount == '') ? 0 : rankCount}&subOption=${subOption}`, singleObj)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`)
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Student rank exam wise list" + err)
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }

    }

    const getSubjects = async (cId, dId) => {
        setLeft([]);
        setLeftFilteredData([]);
        setLeftSearchTerm('');
        setRight([]);
        setRightFilteredData([]);
        setRightSearchTerm('');
        //console.log(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}&branchMedium=${authUser.branchMedium}`)
        await axios(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {
                //console.log(response.data);
                let filterMarkSubjects = response.data.filter(e => e.markOrGrade == 1)
                setLeftFilteredData(filterMarkSubjects);
                setLeft(filterMarkSubjects);

            }).catch((error) => {
                console.log(error);
            })
    }

    const getExamListTermWise = async (tId) => {
        setExamData([]);
        if (classObj != null) {
            //console.log(`${getExamListForResultSheet}?classId=${classObj.classId}&divId=${classObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
            await axios(`${getExamListForResultSheet}?classId=${classObj.classId}&divId=${classObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
                .then((response) => {
                    setExamData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const handleToggle = (value) => () => {
        //console.log(value);    
        const currentIndex = checked.indexOf(value);
        //console.log("Data="+currentIndex)       
        const newChecked = [...checked];

        if (currentIndex === -1) {
            //console.log("Checked="+JSON.stringify(value.id))           
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        //console.log(newChecked.map(e=>e.id).join(','))
    };

    const handleAllRight = () => {
        setRight((right.concat(left)));
        setRightFilteredData((right.concat(left)));
        setLeft([]);
        setLeftFilteredData([]);
        setLeftSearchTerm('');
        setRightSearchTerm('');
    };

    const handleCheckedRight = () => {
        setRight((right.concat(leftChecked)));
        setRightFilteredData((right.concat(leftChecked)));
        setLeft(not(left, leftChecked));
        setLeftFilteredData(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        setLeftSearchTerm('');
        setRightSearchTerm('');
    };

    const handleCheckedLeft = () => {
        setLeft((left.concat(rightChecked)));
        setLeftFilteredData((left.concat(rightChecked)));
        setRight(not(right, rightChecked));
        setRightFilteredData(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        setLeftSearchTerm('');
        setRightSearchTerm('');
    };

    const handleAllLeft = () => {
        setLeft((left.concat(right)));
        setLeftFilteredData((left.concat(right)));
        setRight([]);
        setRightFilteredData([]);
        setLeftSearchTerm('');
        setRightSearchTerm('');
    };

    useEffect(() => {
        setLeftFilteredData(
            left.filter((item) =>
                item.subjectNameMr.toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                item.subjectNameEn.toLowerCase().includes(leftSearchTerm.toLowerCase())

            ))
    }, [leftSearchTerm])

    useEffect(() => {
        setRightFilteredData(
            right.filter((item) =>
                item.subjectNameMr.toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                item.subjectNameEn.toLowerCase().includes(rightSearchTerm.toLowerCase())

            ))
    }, [rightSearchTerm])

    const leftListData = (leftdata) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>

            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "170px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={leftSearchTerm}
                        onChange={e => setLeftSearchTerm(e.target.value)}
                    /></span>
                </div>

                {leftdata.map((value) => {
                    const labelId = value.id;
                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={(authUser.branchMedium == 1) ? value.subjectNameMr : value.subjectNameEn} primaryTypographyProps={{ fontSize: '14px', fontWeight: "600" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const rightListData = (rightData) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>
            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "170px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={rightSearchTerm}
                        onChange={e => setRightSearchTerm(e.target.value)}
                    /></span>
                </div>

                {rightData.map((value) => {
                    const labelId = value.id;
                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={(authUser.branchMedium == 1) ? value.subjectNameMr : value.subjectNameEn} primaryTypographyProps={{ fontSize: '14px', fontWeight: "600" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={getSubjectWiseRankDetails}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={classData}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            value={classObj}
                                            onChange={(event, newValue) => {
                                                setClassObj(newValue);
                                                setExamData([]);
                                                setExamObj(null);
                                                setReportOptionId(1);
                                                setTerm('');
                                                setRankCount('');
                                                setSubOption(1);
                                                if (newValue != null) {
                                                    getSubjects(newValue.classId, newValue.divisionId);
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField margin='dense' {...params} label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={reportOptionId}
                                                label="Select Option"
                                                onChange={(e) => {
                                                    setReportOptionId(e.target.value);
                                                    setExamData([]);
                                                    setExamObj(null);
                                                    setRankCount('');
                                                    if (classObj != null) {
                                                        getSubjects(classObj.classId, classObj.divisionId);
                                                    }
                                                    setTerm('');
                                                    setSubOption(1);
                                                }}
                                            >
                                                <MenuItem value={1}>Term Wise</MenuItem>
                                                <MenuItem value={2}>Exam Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={term}
                                                label="Select Term"
                                                onChange={(e) => {
                                                    setTerm(e.target.value);
                                                    getExamListTermWise(e.target.value);
                                                    setExamData([]);
                                                    setExamObj(null);
                                                    setRankCount('');
                                                    if (classObj != null) {
                                                        getSubjects(classObj.classId, classObj.divisionId);
                                                    }
                                                    setSubOption(1);
                                                }}
                                            >
                                                {subjectMarkTermData.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {(() => {
                                        if (reportOptionId == 2) {
                                            return (
                                                <>
                                                    {/* <div className="row my-2"> */}
                                                    <label className='' hidden={(term == 3) ? true : false} style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam :</label>
                                                    <div className='col-sm-3' hidden={(term == 3) ? true : false}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            options={examData}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            size="small"
                                                            value={examObj}
                                                            onChange={(event, newValue) => {
                                                                setExamObj(newValue);
                                                                setRankCount('');
                                                                if (classObj != null) {
                                                                    getSubjects(classObj.classId, classObj.divisionId);
                                                                }
                                                                setSubOption(1);
                                                            }}
                                                            renderInput={params => (
                                                                <TextField margin='dense' {...params} label="Select Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(reportOptionId == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                    {/* </div> */}
                                                </>
                                            )
                                        }
                                        else if (reportOptionId == 1) {
                                            return (
                                                <>
                                                    <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Option :</label>
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth margin='dense'>
                                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Sub-Option</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                required
                                                                fullWidth
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={subOption}
                                                                label="Select Sub-Option"
                                                                onChange={(e) => {
                                                                    setSubOption(e.target.value);
                                                                    setRankCount('');
                                                                    if (classObj != null) {
                                                                        getSubjects(classObj.classId, classObj.divisionId);
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={1}>All Subjects</MenuItem>
                                                                <MenuItem value={2}>Subject Wise</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}
                                </div>



                                <div className="row my-2" hidden={(reportOptionId == 1) ? true : false}>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Sub-Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={subOption}
                                                label="Select Sub-Option"
                                                onChange={(e) => {
                                                    setSubOption(e.target.value);
                                                    setRankCount('');
                                                    if (classObj != null) {
                                                        getSubjects(classObj.classId, classObj.divisionId);
                                                    }
                                                }}
                                            >
                                                <MenuItem value={1}>All Subjects</MenuItem>
                                                <MenuItem value={2}>Subject Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {(() => {
                                    if (subOption == 2) {
                                        return (
                                            <>
                                                <div className="row mt-3 mb-3">
                                                    <div className='col-sm-12 offset-sm-2' >
                                                        <Grid container spacing={2} alignItems="center" >
                                                            <Grid item >{leftListData(leftFilteredData)}</Grid>
                                                            <Grid item>
                                                                <Grid container direction="column" alignItems="center"  >
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllRight}
                                                                        disabled={left.length === 0}
                                                                        aria-label="move all right"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        ≫
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleCheckedRight}
                                                                        disabled={leftChecked.length === 0}
                                                                        aria-label="move selected right"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        &gt;
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleCheckedLeft}
                                                                        disabled={rightChecked.length === 0}
                                                                        aria-label="move selected left"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        &lt;
                                                                    </button>
                                                                    <button
                                                                        className='my-1'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllLeft}
                                                                        disabled={right.length === 0}
                                                                        aria-label="move all left"
                                                                        style={{ width: "30px" }}
                                                                    >
                                                                        ≪
                                                                    </button>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>{rightListData(rightFilteredData)}</Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}


                                <div className="row my-2" >
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Rank Count :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Rank Count"
                                            variant="outlined"
                                            margin='dense'
                                            onWheel={(e) => e.target.blur()}
                                            value={rankCount}
                                            onChange={(e) => setRankCount(e.target.value)}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120 }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type="submit" disabled={((subOption == 2) ? (rightFilteredData.length == 0 || right.length == 0) : '') ? true : false} className="btn btn-primary btn-sm">Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default StudentRank