import React, { useState } from 'react'

const BudgetAccountWiseEntryInput = ({ initialText1, onChange }) => {

    const txtBox = {
        borderRadius: "5px",
        padding: "4px",
        fontSize: "14px",
        fontWeight: "500",
        width: "200px",
        //textAlign: "right",
        border: "1px solid #B2ADAD",
        mozAppearance: 'none',

    }

    const [text1, setText1] = useState(initialText1);
    
    return (
        <>
            <td style={{ width: "250px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => { onChange(text1) }}
                    style={txtBox}
                    onChange={(e) => setText1(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1) : ""}
                    value={text1}
                />
            </td>
        </>
    )
}

export default BudgetAccountWiseEntryInput