import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getClassesBySectionAndBranch } from '../../Services/ExamMarkAllocationAPIURL';
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import Loader from '../../../CommonComponent/Loader';
import { getProgressCardMsgAPIURL, saveProgressCardMsgAPIURL } from '../../Services/ProgressCardMessageAPIURL';
import { toast } from 'react-toastify';
import subjectMarkTermData from '../../../CommonComponent/LocalJSON/SubjectMarkTermData';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const ProgressCardMessage = () => {
    const classes = useStyles();
    const titleId = "Progress Card Message";
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [firstTerm, setFirstTerm] = useState('');
    const [secondTerm, setSecondTerm] = useState('');
    const [annual, setAnnual] = useState('');
    const [remark, setRemark] = useState('');
    const [termObj, setTermObj] = useState('');

    useEffect(() => {
        getClassData();
    }, [authUser])

    const getClassData = async () => {
        await axios(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassData(res.data);
            });
    }

    const getRemarkByClassAndTerm = async (tid) => {
        //setLoaderOption(true);
        setRemark('');
        if (classObj != null) {
            await axios.get(`${getProgressCardMsgAPIURL}?classId=${classObj.id}&termId=${tid}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data != null) {
                        setRemark(res.data.remark);
                    }
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("get Remark by class & term " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    const clearData = () => {
        setClassObj(null);
        setRemark('');
        setTermObj('')
    }

    const saveProgressCardMessage = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const saveObj = {
            "classId": classObj.id,
            "termId": termObj,
            "remark": remark,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.post(saveProgressCardMsgAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Progress card ms save err " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={saveProgressCardMessage}>
                                <div className="row">
                                    <label className='col-sm-1' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={classData}
                                            value={classObj}
                                            onChange={(event, newValue) => {
                                                setClassObj(newValue);
                                                setRemark('');
                                                setTermObj('');
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Class" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                size='small'
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                required
                                                id="demo-simple-select"
                                                value={termObj}
                                                label="Term"
                                                onChange={(e) => {
                                                    setTermObj(e.target.value);
                                                    setRemark('');
                                                    getRemarkByClassAndTerm(e.target.value);
                                                }}
                                            >
                                                {subjectMarkTermData.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Remark :</label>
                                    <div className="col-sm-8">
                                        <TextField
                                            //required
                                            fullWidth
                                            type="text"
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Remark"
                                            margin='dense'
                                            variant="outlined"
                                            value={remark}
                                            onChange={e => setRemark(e.target.value)}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-1">
                                        <button type='submit' className='btn btn-sm btn-primary'>Save</button>
                                        <button type='button' className='btn btn-sm btn-danger ml-2' onClick={clearData}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default ProgressCardMessage