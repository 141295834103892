import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";


export const getLcDefaultData = BaseURL + "administration/LC/defaultData/getData";
export const updateLcDefaultData = BaseURL + "administration/LC/defaultData/updateData"

// /institute/sss/administration/LC/defaultData/

// @GetMapping("/getData")

//  @RequestParam("sid") Long sectionId, @RequestParam("bid") Long branchId
// -----------------------------------------------------------
//  @PostMapping("/updateData")
// @RequestBody DefaultLCData defaultLCData
// long id,
// String defaultLCDate,
// String defaultLeftDate,
// long progressBoy,
//  long progressGirl,
// long conductBoy,
//  long conductGirl,
// String reasonMrBoy,
//  String reasonEnBoy,
// String reasonMrGirl,
// String reasonEnGirl,
// String remarkMrBoy,
// String remarkEnBoy,
// String remarkMrGirl,
// String remarkEnGirl,
//  long sessionYear,
// long sectionId,
// long branchId

export const getStudentData = BaseURL + "administration/LC/getStudentAllocationDataForLC";


// /institute/sss/administration/LC/
//  @GetMapping("/getStudentAllocationDataForLC")
// @RequestParam("syear") Long sessionYear, @RequestParam("sid") Long sectionId, @RequestParam("bid") Long branchId
// long id, 
// long regCode, 
// String studentNameMr, 
// String studentNameEn, 
// long rollNo, 
// long isAllocated, 
// long sessionYear, 
// long sectionId, 
// long branchId


// /institute/sss / administration / LC /
//     PostMapping  saveBulkLCData
// @RequestBody List < BulkLCTableDto > bulkLCTableDtoList

export const saveStudentForLc = BaseURL + "administration/LC/saveBulkLCData";
