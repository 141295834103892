import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/academics/exam/getSubjectListByClassAndDivision?classId=5&divId=17&year=2022&sid=3&medium=1
export const getSubjectListByMarkAllocated = BaseURL + "academics/exam/getSubjectListByClassAndDivision";

//localhost:8080/institute/sss/academics/exam/getGraceMarkEntryList?classId=5&divId=17&subId=1&year=2022&sid=3&bid=1&medium=1
export const getStudentDetailsForGraceExamMarkEntry = BaseURL + "academics/exam/getGraceMarkEntryList";

// /localhost:8080/institute/sss/academics/exam/saveGraceMarkEntryList
// [
//    {
//         "classId": 5,
//         "divisionId": 17,
//         "regNo": 12707,
//         "subjectId": 1,
//         "graceMarks": 11,
//         "sessionYear": 2022,
//         "sectionId": 3,
//         "branchId": 1
//     }
// ]
export const saveGraceExamMarkEntryAPIURL = BaseURL + "academics/exam/saveGraceMarkEntryList";