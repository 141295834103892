import {BaseIP, BaseURL, BaseURLMaster} from "./APIURL";

//Mother Tongue Master API Link
export const MotherTongueMasterGetMapping=BaseURLMaster + "Mothertongues/";
export const MotherTonguePostMapping=BaseURLMaster + "saveMothertongue/";
export const MotherTonguePutMapping=BaseURLMaster + "updateMothertongue/";
export const MotherTongueDeleteMapping=BaseURLMaster + "deleteMothertongue/";


// GetMapping:"Mothertongues",
// PostMapping:"saveMothertongue",
// PutMapping:"updateMothertongue",
// DeleteMapping:"deleteMothertongue"