import { BaseURL, dBaseURL } from "../../../CommonServices/APIURL";

// /localhost:8080/institute/sss/library/circulation/getIssuedBookList?sessionYear=2022&bid=1
export const getBookIssueReturnDataAPIURL = BaseURL + "library/circulation/getIssuedBookList";

// localhost:8080/institute/sss/library/circulation/saveIssuedBook
// {
//     "memberType":1,
//     "memberId":25,
//     "registerTypeId":1,
//     "accNo":3,
//     "IssueDate":"2022-12-15",
//     "returnDate":"2022-12-20",
//     "sessionYear":2022,
//     "branchId":1
// }
export const saveBookIssueAPIURL = BaseURL + "library/circulation/saveIssuedBook";
//saveReturnedBook
export const saveBookReturnAPIURL = BaseURL + "library/circulation/saveReturnedBook";
//saveRenewalBook
export const saveBookRenewalAPIURL = BaseURL + "library/circulation/saveRenewalBook";

//localhost:8080/institute/sss/library/acquisition/getAllAccessionEntryDataByRegisterTypeId?registerTypeId=1&bid=1
export const getAllAccessionDataByRegisterType = BaseURL + "library/acquisition/getAllAccessionEntryDataByRegisterTypeId";




