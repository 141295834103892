const LibraryAcademicsJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/LibraryDashboard/",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "megamenu",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        MegaMenuDropDown: [
            {
                HeaddingName: "Accession Master",
                MegaMenuSubItems: [
                    {
                        id: 809,
                        SubPath: "/Home/l809/",
                        SubName: "Register Type Master"
                    },
                    {
                        id: 805,
                        SubPath: "/Home/l805/",
                        SubName: "Title Master"
                    },
                    {
                        id: 804,
                        SubPath: "/Home/l804/",
                        SubName: "Author Master"
                    },
                    {
                        id: 808,
                        SubPath: "/Home/l808/",
                        SubName: "Publisher Master"
                    },
                    {
                        id: 807,
                        SubPath: "/Home/l807/",
                        SubName: "Vendor Master"
                    },
                    {
                        id: 813,
                        SubPath: "/Home/l813/",
                        SubName: "Binder Master"
                    },

                ]
            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [
                    {
                        id: 806,
                        SubPath: "/Home/lm001/806",
                        SubName: "Binding Master"
                    },
                    {
                        id: 801,
                        SubPath: "/Home/lm001/801",
                        SubName: "Department Master"
                    },
                    {
                        id: 803,
                        SubPath: "/Home/lm001/803",
                        SubName: "Location Master"
                    },
                    {
                        id: 810,
                        SubPath: "/Home/lm001/810",
                        SubName: "Source Master"
                    },
                    {
                        id: 811,
                        SubPath: "/Home/l811/",
                        SubName: "Sub-budget Type Master"
                    },
                    {
                        id: 802,
                        SubPath: "/Home/lm001/802",
                        SubName: "Currency Master"
                    },

                ]
            },
            {
                HeaddingName: "Member Master",
                MegaMenuSubItems: [
                    {
                        id: 812,
                        SubPath: "/Home/l812/",
                        SubName: "Member Type Master"
                    },
                ]
            },
            {
                HeaddingName: "Master Reports",
                MegaMenuSubItems: [
                    {
                        id: 814,
                        SubPath: "/Home/lr814/",
                        SubName: "Master Report"
                    },
                ]
            }
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Acquisition",
        LinkIcon: "menu_book",
        DropDownSubMenu: [
            {
                id: 901,
                SubPath: "/Home/la901/",
                SubName: "Accession Entry"
            },
            {
                id: 902,
                SubPath: "/Home/la902/",
                SubName: "Accession Report"
            },
        ]
    },
    {
        MenuType: "megamenu",
        PathName: "#",
        Title: "Circulation",
        LinkIcon: "desktop_mac",
        MegaMenuDropDown: [
            {
                HeaddingName: "Circulation",
                MegaMenuSubItems: [
                    {
                        id: 903,
                        SubPath: "/Home/lc903/",
                        SubName: "Book Issue Return"
                    },
                    {
                        id: 904,
                        SubPath: "/Home/lc904/",
                        SubName: "Withdrawal Entry"
                    },
                    {
                        id: 905,
                        SubPath: "/Home/lm001/905",
                        SubName: "Book-bank Group"
                    },
                    {
                        id: 906,
                        SubPath: "/Home/lc906/",
                        SubName: "Book-bank Issue"
                    },                    
                ]
            },
            {
                HeaddingName: "Reports",
                MegaMenuSubItems: [
                    {
                        id: 911,
                        SubPath: "/Home/cr911/",
                        SubName: "Book Barcode"
                    },
                    {
                        id: 913,
                        SubPath: "/Home/cr913/",
                        SubName: "Book Bank Report"
                    },
                    {
                        id: 920,
                        SubPath: "/Home/cr920/",
                        SubName: "Withdrawal Register"
                    },
                    {
                        id: 916,
                        SubPath: "/Home/cr916/",
                        SubName: "Book Defaulter List"
                    },
                ]
            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [
                   
                    {
                        id: 917,
                        SubPath: "/Home/cr917/",
                        SubName: "Issue Register"
                    },
                    {
                        id: 918,
                        SubPath: "/Home/cr918/",
                        SubName: "Return Register"
                    },
                    {
                        id: 915,
                        SubPath: "/Home/cr915/",
                        SubName: "Daily Transaction"
                    },
                    {
                        id: 919,
                        SubPath: "/Home/cr919/",
                        SubName: "Member Statement"
                    },
                ]
            },
        ]
    },
]

export default LibraryAcademicsJSON;