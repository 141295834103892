const transactionModeData = [
    {
        id: 1,
        Label: "जमा"
    },
    {
        id: 2,
        Label: "नावे"
    }
]

export default transactionModeData;