import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import { classWithDivURL, printCatalogEndOfMonth } from '../Services/CatalogReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { useNavigate } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel } from '@mui/material';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const CatalogEndOfMonth = () => {
    const classes = useStyles();
    const titleId = "Catalog - End Of Month";
    const authUser = useSelector((state) => state.user.value);

    const navigate = useNavigate();

    const defaultMinDate = new Date(`${authUser.sessionYear}/06/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [dateCheck, setDateCheck] = useState(null);


    const [dateFrom, setDateFrom] = useState(null);
    //console.log(moment(dateFrom).format("MM/YYYY"))
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [zeroDaysAttend, setZeroDaysAttend] = useState(0);

    const handleZeroDaysAttendChkBox = (e) => {
        setZeroDaysAttend(e.target.checked);
        if (e.target.checked == true) {
            setZeroDaysAttend(1);
        }
        else if (e.target.checked == false) {
            setZeroDaysAttend(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${classWithDivURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    //did=40&mdata=03/2022&langId=1&acYear=2021&sid=3&bid=1

    const catalogEndOfMonthReportPrint = async (e) => {
        e.preventDefault();
        if (moment(dateFrom).format("MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            setLoaderOption(true);
            //console.log(`${printCatalogEndOfMonth}?did=${classDivObj.divisionId}&mdata=${moment(dateFrom).format("MM/YYYY")}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${printCatalogEndOfMonth}?did=${classDivObj.divisionId}&mdata=${moment(dateFrom).format("MM/YYYY")}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&withZero=${zeroDaysAttend}`)
                .then((response) => {

                    if (response.data === "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Subject or class Teacher is not allocated to this class-division.")
                        //console.log("aaaaaaaaaa")
                    }
                    else if (response.data === "WORKINGDAYSNOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Working or present days are not allocated to this class-division.")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }
                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Catalog End Of Month failed: " + error)
                })
            //toast.success("valid Date")
        }
        else {
            toast.error("Improper date, Please check.");
        }

    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={catalogEndOfMonthReportPrint}>
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row mb-3">
                                <div className='col-sm-12'>
                                    <button className='btn btn-warning btn-sm' onClick={() => navigate('/Home/ss420F/')}>Set Subject Serial</button>
                                </div>
                            </div>

                            <div className="row">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Notice Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Notice Date"
                                            value={dateFrom}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            views={['year', 'month']}
                                            inputFormat="MM/yyyy"
                                            // mask="__/____"
                                            //disableMaskedInput={false}
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                                (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)

                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{(moment((dateFrom == null) ? new Date() : dateFrom).format("MM/YYYY") == "Invalid date") ? "Date format should be MM/YYYY" : ''}</em></span> */}
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={classDivData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                        size="small"
                                        value={classDivObj}
                                        onChange={(event, newvalue) => {
                                            setClassDivObj(newvalue);
                                        }}

                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-sm-4 offset-sm-2">
                                    <FormControlLabel control={<Checkbox checked={zeroDaysAttend} onChange={handleZeroDaysAttendChkBox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 600, fontSize: "15px", marginTop: "2px" } }} label="With Zero(0) Days Attendace" />
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm" >Show</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }
}

export default CatalogEndOfMonth