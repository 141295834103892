import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { getMasterialMasterAPIURL, saveMaterialMasterAPIURL, updateMaterialMasterAPIURL } from '../Services/MaterialMasterAPIURL';
import MaterialMasterTable from './MaterialMasterTable';


const MaterialMaster = () => {
    const titleId = 'Material Master';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `Material Name`, key: 'materialName' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "materialMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "materialMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState("");
    const [materialName, setMaterialName] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(getMasterialMasterAPIURL)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
            }).catch(err => {
                console.log('get material master err' + err)
            })
    }

    const ClearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setMaterialName('');
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "materialName": materialName,
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveMaterialMasterAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Material Master Save Failed.")
                }
            }).catch(err => {
                console.log("Material Master save" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "materialName": materialName,
        }
        console.log(JSON.stringify(updateObj))
        await axios.put(updateMaterialMasterAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Material Master Update Failed.")
                }
            }).catch(err => {
                console.log("Material Master update" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.materialMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.materialMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }
        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        {/* Form Data start */}
                        <form onSubmit={onSubmit}>
                            <div className="form-data mt-1">
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Material Name :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && materialName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Material Name"
                                            variant="outlined"
                                            margin='dense'
                                            value={materialName}
                                            onChange={(e) => setMaterialName(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? materialName : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 mt-3">
                                    <div className="offset-sm-2">
                                        <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                        <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                        <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="tableData mt-3">
                            <MaterialMasterTable
                                TableHeading={TableHeading}
                                data={data}
                                setFilteredData={setFilteredData}
                                filteredData={filteredData}
                                setId={setId}
                                setMaterialName={setMaterialName}
                                getData={getData}
                                ClearData={ClearData}
                                setSaveBtn={setSaveBtn}
                                setUpdateBtn={setUpdateBtn}
                                updateBtnStyle={updateBtnStyle}
                                displayNoneBtnStyle={displayNoneBtnStyle}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MaterialMaster