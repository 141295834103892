import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlusCircle, faPrint } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import DeleteModalBox from '../../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Loader from '../../../CommonComponent/Loader';
import { getEndowmentmasterDataAPIURL, deleteEndowmentMasterAPIURL } from '../Services/EndowmentMasterAPIURL';
import { printEndowmentByPrizeCodeAPIURL } from '../Services/EndowmentReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const EndowmentMasterTable = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Endowment Master Table";
    const classes = useStyles();
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [filteredData, setFilteredData] = useState([]);


    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(getEndowmentmasterDataAPIURL)
            .then(res => {
                //console.log(res.data)
                setData(res.data);
                setFilteredData(res.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const UpdateData = (item, EditFlag) => {
        navigate(`/Home/m701F/${item}/${EditFlag}`);
    }

    const [deleteId, setDeleteId] = useState("");

    const DeleteData = (id) => {
        axios.delete(`${deleteEndowmentMasterAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    toast.success("Deleted successfully");
                }
            });
    }

    const printEndowmentMasterReportByPrizeCode = (prizeId) => {
        setLoaderOption(true);
        axios.get(`${printEndowmentByPrizeCodeAPIURL}?prizeCode=${prizeId}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)   
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {

                toast.error("Error Occurred, Try after sometime.");
                console.log("Endowment Master Report failed: " + error)
            })
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.prizeCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.prizeName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const TableHeading = [
        { label: 'खाते क्रमांक', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `Edit`, key: 'edit', isAction: true },
        { label: `Print`, key: 'print', isAction: true },
        { label: `Delete`, key: 'delete', isAction: true }
    ];

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                        <div className=''>
                            <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/m701F/0/1")}><AddIcon fontSize="small" /> New Endowment</button>
                        </div>

                        <div className='row mb-2' style={{ float: 'right' }}>
                            <div className=''>
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                />
                            </div>
                        </div>

                        <div className='table-responsive' style={{ overflowY: "hidden" }}>
                            <table className="table table-bordered">
                                <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .reverse().map((item, index) => {
                                            return (

                                                <tr key={index}>
                                                    <td style={{ fontSize: "15px", fontWeight: "500", width: "130px" }}>{item.prizeCode}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{item.prizeName}</td>                                                  

                                                    <td style={{ width: "100px" }}>
                                                        <Tooltip title="Edit">
                                                            <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                onClick={() => UpdateData(item.id, 2)}
                                                                data-bs-toggle="tooltip" >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{ width: "100px" }}>
                                                        <Tooltip title="print">
                                                            <span className='btn btn-sm mx-1 p-1' style={{ backgroundColor: "#F1C40F", color: "white" }}
                                                                onClick={() => printEndowmentMasterReportByPrizeCode(item.prizeCode)}
                                                                data-bs-toggle="tooltip" >
                                                                <FontAwesomeIcon icon={faPrint} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{ width: "100px" }}>
                                                        <Tooltip title="Delete">
                                                            <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                onClick={() => setDeleteId(item.id)}
                                                                data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                </tr>

                                            )
                                        })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>

            }

            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default EndowmentMasterTable