import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { EmployeeMasterGetMapping, getClassDivisionURL } from '../../CommonServices/ClassTeacherAllocationAPIURL';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { printEmployeeTimeTableReportAPIURL } from '../Services/ManageTimeSlotAPIURL';
import { getClassesBySectionAndBranch } from '../../Exam/Services/ExamMarkAllocationAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const optionData = [
    {
        id: 1,
        optionName: "All"
    },
    {
        id: 2,
        optionName: "Class-Division Wise"
    },
    {
        id: 3,
        optionName: "Employee Wise"
    },
    {
        id: 4,
        optionName: "Off Lecture Employee"
    }
]

const weekData = [
    {
        id: 1,
        weekName: 'Monday'
    },
    {
        id: 2,
        weekName: 'Tuesday'
    },
    {
        id: 3,
        weekName: 'Wednsday'
    },
    {
        id: 4,
        weekName: 'Thursday'
    },
    {
        id: 5,
        weekName: 'Friday'
    },
    {
        id: 6,
        weekName: 'Saturday'
    },

]


const EmployeeTimeTableReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Employee Time Table";

    const [employeeData, setEmployeeData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [employeeObj, setEmployeeObj] = useState(null);
    const [classObj, setClassObj] = useState(null);
    const [subOption, setSubOption] = useState(null);
    const [optionId, setOptionId] = useState(1);
    const [empClassData, setEmpClassData] = useState([]);
    const [empClassObj, setEmpClassObj] = useState(null);
    const [weekDay, setWeekDay] = useState(null);

    const [loaderOption, setLoaderOption] = useState(false);

    // console.log("employeeObjemployeeObjemployeeObjemployeeObj : " + JSON.stringify(employeeObj))
    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                })

            await axios(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setEmpClassData(res.data);
                })
        }
        if (authUser.branchId != null) {
            await axios(`${EmployeeMasterGetMapping}?bid=${authUser.branchId}`)
                .then(res => {
                    setEmployeeData(res.data);
                })
        }

    }



    const getClassTeacherAllocationReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        console.log(`${printEmployeeTimeTableReportAPIURL}?langId=${authUser.branchMedium}&option=${optionId}&empId=${(optionId === 3) ? employeeObj.id : 0}&classId=${(optionId == 2) ? classObj.classId : ((optionId == 4) ? empClassObj.id : 0)}&divId=${(optionId === 2) ? classObj.divisionId : 0}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&weekDay=${(optionId === 4) ? weekDay.id : 0}`)
        await axios.get(`${printEmployeeTimeTableReportAPIURL}?langId=${authUser.branchMedium}&option=${optionId}&empId=${(optionId === 3) ? employeeObj.id : 0}&classId=${(optionId == 2) ? classObj.classId : ((optionId == 4) ? empClassObj.id : 0)}&divId=${(optionId === 2) ? classObj.divisionId : 0}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&weekDay=${(optionId === 4) ? weekDay.id : 0}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data) 
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log(" failed: " + error)
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={getClassTeacherAllocationReport}>
                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={optionId}
                                                margin="dense"
                                                label="Select Report Option"
                                                onChange={(e) => {
                                                    setOptionId(e.target.value);
                                                    setClassObj(null);
                                                    setEmployeeObj(null);
                                                    setWeekDay(null);
                                                    setEmpClassObj(null);
                                                }}
                                            >
                                                {optionData.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{item.optionName}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {(() => {
                                    if (optionId == 2) {
                                        return (
                                            <>
                                                <div className='row'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={classData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setClassObj(item);
                                                            }}
                                                            value={classObj}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(optionId == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    else if (optionId == 3) {
                                        return (
                                            <>
                                                <div className='row'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Employee :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={employeeData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setEmployeeObj(item);
                                                            }}
                                                            value={employeeObj}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(optionId == 3) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    else if (optionId == 4) {
                                        return (
                                            <>

                                                <div className='row'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={empClassData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setEmpClassObj(item);
                                                                setWeekDay(null);
                                                            }}
                                                            value={empClassObj}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(optionId == 3) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row my-2'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Week Day :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={weekData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, newValue) => {
                                                                setWeekDay(newValue);
                                                            }}
                                                            value={weekDay}
                                                            onKeyPress={(e) => (e.key == "Enter") ? weekDay : ""}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => option.weekName}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Week Day" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(optionId == 3) ? true : false}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    }
                                })()}

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default EmployeeTimeTableReport
