import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import { clear } from '@testing-library/user-event/dist/clear';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import ItemOutwardTable from './ItemOutwardTable';
import { getItemMasterAPIURL } from '../Services/ItemMasterAPIURL';
import { getItemOutwardAPIURL, saveItemOutwardAPIURL, updateItemOutwardAPIURL } from '../Services/ItemOutwardAPIURL';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const ItemOutward = () => {
    const titleId = 'Item Outward';
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);
    const navigate = useNavigate();
    const { ioId, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "itemOutwardSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "itemOutwardUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState("");
    const [date, setDate] = useState(null);
    const [outwardType, setOutwardType] = useState('');
    const [itemObj, setItemObj] = useState(null);
    const [itemData, setItemData] = useState([]);
    const [rate, setRate] = useState('');
    const [quantity, setQuantity] = useState('');
    const [amount, setAmount] = useState('');
    const [remark, setRemark] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(false);
        if (authUser.sessionYear != '' && authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getItemOutwardAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);

                    const selectedItem = res.data.find(e => e.id == ioId)

                    setId(selectedItem.id);
                    setDate(selectedItem.date);
                    setOutwardType(selectedItem.type);
                    setQuantity(selectedItem.quantity);
                    setRate(selectedItem.rate);
                    setAmount(selectedItem.amount);
                    setRemark(selectedItem.remark);

                    axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            setItemData(res.data);
                            setItemObj(res.data.find(e => e.id == selectedItem.itemId))
                        }).catch(err => {
                            console.log("Item outward get err " + err);
                        })

                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Item outward get err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    const getApiData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setItemData(res.data);
                }).catch(err => {
                    console.log("Item outward get err " + err);
                })
        }
    }

    const getAmount = () => {

        let totalAmt = parseFloat((rate == '') ? 0 : rate) * parseFloat((quantity == '') ? 0 : quantity)
        setAmount(parseFloat(totalAmt));
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setDate(null);
        setOutwardType('');
        setItemObj(null);
        setRate('');
        setQuantity('');
        setAmount('');
        setRemark('');
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        navigate('/Home/st1306/')
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        if (moment(date).format("DD/MM/YYYY") != "Invalid date") {
            const saveObj = {
                "itemId": itemObj.id,
                "type": outwardType,
                "date": moment(date).format("YYYY-MM-DD"),
                "quantity": quantity,
                "rate": rate,
                "amount": amount,
                "remark": remark,
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
            await axios.post(saveItemOutwardAPIURL, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        clearData();
                        getData();
                        setLoaderOption(false);
                        toast.success("Saved Successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Item outward save failed.");
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Item outward save " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        if (moment(date).format("DD/MM/YYYY") != "Invalid date") {
            const updateObj = {
                "id": id,
                "itemId": itemObj.id,
                "type": outwardType,
                "date": moment(date).format("YYYY-MM-DD"),
                "quantity": quantity,
                "rate": rate,
                "amount": amount,
                "remark": remark,
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
            await axios.put(updateItemOutwardAPIURL, updateObj)
                .then(res => {
                    if (res.data == "UPDATED") {
                        clearData();
                        getData();
                        setLoaderOption(false);
                        toast.success("Updated successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Item outward update failed.")
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Item outward update err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        else if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.itemOutwardSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.itemOutwardUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    })

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date :</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Type :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Type</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                margin='dense'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={outwardType}
                                                label="Type"
                                                onChange={(e) => {
                                                    setOutwardType(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={1}>Write-Off</MenuItem>
                                                <MenuItem value={2}>Damage</MenuItem>
                                                <MenuItem value={3}>Unused</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Item :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            id="combo-box-demo"
                                            onChange={(e, newValue) => {
                                                setItemObj(newValue);
                                            }}
                                            value={itemObj}
                                            options={itemData}
                                            onKeyPress={(e) => (e.key == "Enter") ? itemObj : ""}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.itemName}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} label="Select Item" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Rate :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && rate.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Rate"
                                            variant="outlined"
                                            margin='dense'
                                            value={rate}
                                            onChange={(e) => {
                                                setRate(e.target.value);

                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? rate : ""}
                                            onBlur={() => getAmount()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Quantity:</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && quantity.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Quantity"
                                            variant="outlined"
                                            margin='dense'
                                            value={quantity}
                                            onChange={(e) => {
                                                setQuantity(e.target.value);

                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? quantity : ""}
                                            onBlur={() => getAmount()}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Amount :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && amount.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Amount"
                                            variant="outlined"
                                            margin='dense'
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? amount : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Remark :</label>
                                    <div className='col-sm-7'>
                                        <TextField
                                            //required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && remark.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Remark"
                                            variant="outlined"
                                            margin='dense'
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? remark : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-1">
                                        <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default ItemOutward