import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import moment from 'moment';

const ReadOnlyTimeTableRow = ({ item, handleEditClick, index }) => {
    const authUser = useSelector((state) => state.user.value);
    return (
        <>
            <tr style={{ height: "50px", backgroundColor: (item.isRecess == 1) ? '#D6EAF8' : '' }}>
                <td style={{ width: "80px", fontSize: "14px", fontWeight: "500" }}>{index + 1}</td>
                <td style={{ lineHeight: "1.3", textAlign: 'center', fontSize: "14px", fontWeight: "500", position: "relative" }}>
                    <span>
                        {moment(item.fromTime, 'HH:mm:ss').format('HH:mm') + " == " + moment(item.toTime, 'HH:mm:ss').format('HH:mm')}
                    </span>
                    {item.isRecess === 1 && <span style={{ position: "absolute", right: 0, fontWeight: "bold", padding: '2px', paddingRight: '30px' }}>{item.recessLabel}</span>}
                </td>
                <td style={{ padding: "0px", width: "80px" }}>
                    <Tooltip title="Edit">
                        <span className='btn btn-primary btn-sm mx-1 p-1'
                            data-bs-toggle="tooltip" onClick={(event) => handleEditClick(event, item, index)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </span>
                    </Tooltip>
                </td>
            </tr>
        </>
    )
}


export default ReadOnlyTimeTableRow
