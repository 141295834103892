import React, { useState, useEffect, useCallback } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import TablePagination from '@mui/material/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {  getClassWithDiv } from '../../Academic/Services/StudentRollNoAPIURL';
import { ClassMasterGetMapping } from '../../CommonServices/ClassMasterAPIURL';
import { DivisioneMasterGetMapping } from '../../CommonServices/DivisionMasterAPIURL';
import RollNoAssignTextBox from '../../Academic/Modal/RollNoAssignTextBox';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    }
});


const StudentMedium = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Medium";


    const classes = useStyles();

    const TableHeading = [
     { label: 'Reg No', key: 'regno' },           
     { label: `Student Name`, key: 'sname' },              
     { label: `Roll No`, key: 'rollno' },
     { label: 'Code', key: 'code' },
     { label: 'Medium', key: 'cwsn' }];

    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);


    //console.log("division data: "+JSON.stringify(divisionData));
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        await axios(`${getClassWithDiv}`)
            .then(res => {
                setDivisionData(res.data);
            }
            )

    }


    const [filteredData, setFilteredData] = useState([]);
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(data.filter((item) => item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.categoryNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.genderLabel.toLowerCase().includes(searchTerm.toLowerCase()) || item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    }, [searchTerm])


   
    //console.log("get data : " + JSON.stringify(data))

   

    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>

                <div className='row'>
                    <div className='col-sm-4'>
                        <Autocomplete
                            id="combo-box-demo"
                            options={divisionData}
                            style={{ fontSize: '14px' }}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            onChange={(event, newValue) => {
                                //getDivisionData(newValue.divisionId)
                                setClassDivObj(newValue)
                            }}
                            value={classDivObj}
                            getOptionLabel={option => option.classNameMr + " " + option.divisionNameMr + " / " + option.classNameEn + " " + option.divisionNameEn}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                            )}
                        />
                    </div>
                </div>


                <div className='row col-sm-4 my-1' style={{ float: 'right' }}>
                    <div>
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                            placeholder="Search Here"
                            onChange={(event) => { setSearchTerm(event.target.value) }}
                            value={searchTerm}
                        />
                    </div>
                </div>
                <div className='table-responsive' style={{ maxHeight: "500px" }}>
                    <table className="table table-bordered ">
                        <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                            <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                {TableHeading.map((item, index) => {
                                    return (
                                        <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                            {filteredData
                                .map((item, index) => {
                                    return (

                                        <tr key={index}>
                                            
                                        </tr>

                                    )
                                })}
                        </tbody>
                        <tfoot style={{ padding: "0px" }}>
                            {/* <TablePagination
                                style={{ padding: "0px" }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onRowsPerPageChange={onRowsPerPageChange}
                                onPageChange={onPageChange}
                                classes={{ toolbar: classes.toolbar, caption: classes.caption }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                nextIconButtonProps={{ "aria-label": "Next Page" }} /> */}
                        </tfoot>
                    </table>

                </div>

                <div className='mt-4'>
                    <button className='btn btn-primary btn-sm mb-2' ><AddIcon fontSize="small" />Save Changes</button>
                </div>

            </div>
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default StudentMedium;





