import React, { useState } from 'react'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const ExamMarkEntryHead2 = ({ bulkMarkEntryFun, setS1, s1, setS2, s2, examMarkStatus }) => {

    return (

        <>
            <thead className="table-Default" >
                <tr style={{ border: "none" }}>
                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}></td>
                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}></td>
                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}></td>
                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}>
                        <button type='button' disabled={(examMarkStatus === 1) ? true : false} className='btn btn-warning btn-sm' onClick={() => { bulkMarkEntryFun() }} >
                            Set-Default
                        </button>
                    </td>
                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}>
                        <input
                            type="number"
                            disabled={(examMarkStatus == 1) ? true : false}
                            value={s1}
                            onChange={(e) => { setS1(e.target.value) }}
                            className='form-control form-control-sm'
                            style={{ width: "80px", fontSize: "14px", fontWeight: "600", padding: "4px" }}
                            min="0"
                            onBlur={(e) => {
                                ((e.target.value) == "" ? setS1(0) : setS1(e.target.value))
                            }}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                        />
                    </td>

                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}>
                        <input
                            type="number"
                            disabled={(examMarkStatus == 1) ? true : false}
                            value={s2}
                            onChange={(e) => { setS2(e.target.value) }}
                            className='form-control form-control-sm'
                            style={{ width: "80px", fontSize: "14px", fontWeight: "600", padding: "4px" }}
                            min="0"
                            onBlur={(e) => {
                                ((e.target.value) == "" ? setS2(0) : setS2(e.target.value))
                            }}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                        />
                    </td>
                </tr>
            </thead>
        </>
    )
}

export default ExamMarkEntryHead2