import { BaseIP, BaseURL, BaseURLMaster } from "./APIURL";

export const BaseURLClassTeacherMaster = BaseURL + "academics/classTeacherAllocation/";
export const ClassDivisionGetData = BaseURLClassTeacherMaster + "getClassAndDivisionList";
export const ClassDivisionSaveData = BaseURLClassTeacherMaster + "saveClassTeacherAllocation/";

export const BaseURLEmployeeMaster = BaseURLMaster + "employee/";
export const EmployeeMasterGetMapping = BaseURLEmployeeMaster + "getBranchWiseTeacherList";
//getBranchWiseTeacherList/{branchId}
//@PathVariable Long branchId


export const getClassDivisionURL = BaseURLMaster + "classWithDivisionBySectionId/";

//http://192.168.1.111:8080/institute-sss/institute/sss/master/employee/getBranchWiseTeacherList?bid=1