import React, { useState, useEffect } from 'react';
import TitleLabel from "../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../CommonComponent/Loader';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModalBox from "../CommonComponent/DeleteModalBox";
import { saveBudgetResolutionNoDateAPIURL, deleteBudgetResolutionNoDateAPIURL, getBudgetResolutionNoDateAPIURL } from './Services/BudgetNoAndDateAPIURL';
import moment from 'moment/moment';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const BudgetNoAndDateForm = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Budget No. And Date";
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "budgetAccountSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "budgetAccountUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState("");
    const [resolutionNo, setResolutionNo] = useState('');
    const [resolutionDate, setResolutionDate] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const TableHeading = [
        { label: 'ठराव नं. / R.No.', key: 'id' },
        { label: `तारीख / Date`, key: 'authorName' },
        { label: 'Delete', value: 'delete', isAction: true }];


    useEffect(() => {
        if (authUser.sessionYear != '' && authUser.branchId != null) {
            getData();
        }
    }, [authUser])


    const getData = async () => {
        //console.log(`${getBudgetResolutionNoDateAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)
        await axios.get(`${getBudgetResolutionNoDateAPIURL}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&sid=${authUser.deptId}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
            })
    }

    const clearForm = () => {
        setResolutionNo('');
        setResolutionDate(null);
    }

    const saveResolutionNoAndDate = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const saveObj = {
            "resolutionNo": resolutionNo,
            "date": moment(resolutionDate).format("YYYY-MM-DD"),
            "sessionYear": authUser.sessionYear,
            "branchId": authUser.branchId,
            "sectionId": authUser.deptId
        }
        await axios.post(saveBudgetResolutionNoDateAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    clearForm();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.");
                }
            }).catch(err => {
                console.log("Budget No & date err:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const [deleteId, setDeleteId] = useState('');

    const DeleteData = async (id) => {
        setLoaderOption(true);
        await axios.delete(`${deleteBudgetResolutionNoDateAPIURL}?id=${id}`)
            .then(res => {
                if (res.data == "DELETED") {
                    getData();
                    setLoaderOption(false);
                    toast.success("Deleted successfully.");
                }
            }).catch(err => {
                console.log("Budget no & date delete err:" + err);
                setLoaderOption(false);
                toast.error("something went wrong, please check.")
            })
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={saveResolutionNoAndDate}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>ठराव नं. / R.No. :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => (e.key === " " && resolutionNo.length === 0) ? e.preventDefault() : ''}
                                                autoComplete='off'
                                                className=''
                                                size='small'
                                                id="outlined-basic"
                                                label="ठराव नं. / R.No."
                                                variant="outlined"
                                                margin='dense'
                                                value={resolutionNo}
                                                onChange={(e) => setResolutionNo(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? resolutionNo : ""}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >तारीख / Date :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="तारीख / Date"
                                                    value={resolutionDate}
                                                    onChange={(newValue) => {
                                                        setResolutionDate(newValue);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? resolutionDate : ""}
                                                    inputFormat="dd/MM/yyyy"
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin='dense'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' disabled={(data.length == 0 || filteredData.length == 0) ? false : true} className={saveBtn}>Save</button>
                                            <button type='button' className='btn btn-sm btn-danger ml-2' onClick={() => clearForm()}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div>

                                <div className="mt-5 row">
                                    <div className="col-sm-8">
                                        <div className='table-responsive' style={{ maxHeight: "350px" }}>
                                            <table className="table table-bordered">
                                                <thead className="table-Default">
                                                    <tr>
                                                        {TableHeading.map((item, index) => {
                                                            return (
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col">{item.label}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                    {filteredData
                                                        .reverse().map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.resolutionNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{moment(item.date).format("DD/MM/YYYY")}</td>


                                                                    <td>
                                                                        <Tooltip title="Delete">
                                                                            <button className='btn btn-danger btn-sm mx-1 p-1'
                                                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                                onClick={() => setDeleteId(item.id)}
                                                                            //disabled={(authUser.roleId === 4 || authUser.roleId === 3) ? true : false}                                            
                                                                            >
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default BudgetNoAndDateForm