import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    getClassesBySectionAndBranch,
    getDivisionsByClassAndSectionAndBranch,
} from "../../Exam/Services/ExamMarkAllocationAPIURL";
import { allCategorySummaryReportAPIURL, classDivWiseCategorySummaryReportAPIURL } from '../Services/CategoryReportAPIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CategorySummary = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Category Summary";

    const [loaderOption, setLoaderOption] = useState(false);

    const [optionId, setOptionId] = useState(1);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [divisionObj, setDivisionObj] = useState([]);
    const [divisionId, setDivisionId] = useState([]);

    useEffect(() => {
        axios.get(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassData(res.data);
            })
    }, [authUser])

    const getDivisionData = async (cId) => {
        await axios.get(`${getDivisionsByClassAndSectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}&cid=${cId}`)
            .then(res => {
                setDivisionData(res.data);
            })
    }

    const allCategorySummaryReport = async () => {
        setLoaderOption(true);
        await axios.get(`${allCategorySummaryReportAPIURL}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })
    }

    const classDivCategorySummaryReport = async () => {
        setLoaderOption(true);
        await axios.get(`${classDivWiseCategorySummaryReportAPIURL}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classObj.id}&divId=${divisionId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })
    }

    const getCategorySummary = e => {
        e.preventDefault();
        if (optionId == 1) {
            allCategorySummaryReport();
        }
        else if (optionId == 2) {
            classDivCategorySummaryReport();
        }
    }

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={getCategorySummary}>
                        <div className='row my-1'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={optionId}
                                        label="Select Report Option"
                                        onChange={(e) => {
                                            setOptionId(e.target.value);
                                            setClassObj(null);
                                            setDivisionObj([]);
                                        }}
                                    >
                                        <MenuItem value={1}>All</MenuItem>
                                        <MenuItem value={2}>Class-Division Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {(() => {
                            if (optionId == 2) {
                                return (
                                    <>
                                        <div className='row my-2'>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                            <div className='col-sm-3'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={classData}
                                                    style={{ fontSize: '14px' }}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, item) => {
                                                        setClassObj(item);
                                                        setDivisionObj([]);
                                                        if (item != null) {
                                                            getDivisionData(item.id);
                                                        }

                                                    }}
                                                    value={classObj}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required={(optionId == 2) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className='row my-2'>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Division :</label>
                                            <div className='col-sm-3'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    multiple
                                                    //filterSelectedOptions
                                                    disableCloseOnSelect
                                                    options={divisionData}
                                                    style={{ fontSize: '14px' }}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, item) => {
                                                        setDivisionObj(item);
                                                        if (item != null) {
                                                            setDivisionId(item.map(e => e.id).join(','));
                                                        }
                                                    }}
                                                    // renderOption={(props, option, { selected }) => (
                                                    //     <li {...props}>
                                                    //         <Checkbox
                                                    //             icon={icon}
                                                    //             checkedIcon={checkedIcon}
                                                    //             style={{ marginRight: 8 }}
                                                    //             checked={selected}
                                                    //         />
                                                    //         {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    //     </li>
                                                    // )}
                                                    value={divisionObj}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 15 } }}
                                                            required={(optionId == 2 && divisionObj.length == 0) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}

                        <div className="row mt-3">
                            <div className="col-sm-12 offset-sm-2">
                                <button type="submit" className="btn btn-primary btn-sm">Show</button>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default CategorySummary