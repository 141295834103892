import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { printCatalogAllStdMainPageAPIURL, printCatalogAllStdDetailPageAPIURL } from '../Services/CatalogReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const CatalogAllStd = () => {
    const classes = useStyles();
    const titleId = "Catalog - All Std";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(`${authUser.sessionYear}/06/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    const [dateFrom, setDateFrom] = useState(null);
    const [printBlank, setPrintBlank] = useState(0);
    const [blankLinesBoysGirls, setBlankLinesBoysGirls] = useState(0);
    const [blankLinesCatalogEnd, setBlankLinesCatalogEnd] = useState(0);
    const [option, setOption] = useState(1);
    const [id, setId] = useState();
    const [dateCheck, setDateCheck] = useState(null);

    const HandlePrintBlankCheckbox = (e) => {
        setPrintBlank(e.target.checked);
        if (e.target.checked == true) {
            setPrintBlank(1);
        }
        else {
            setPrintBlank(0);
        }
    }

    const printAllStdCatalogMainPage = async () => {
        if (moment(dateFrom).format("MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            setLoaderOption(true);
            //console.log(`${printCatalogAllStdMainPageAPIURL}?mdata=${moment(dateFrom).format("MM/YYYY")}&blgb=${blankLinesBoysGirls}&ble=${blankLinesCatalogEnd}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${printCatalogAllStdMainPageAPIURL}?mdata=${moment(dateFrom).format("MM/YYYY")}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    // if (response.data === "Warring : Class Teacher not assigned ") {
                    //     setLoaderOption(false);
                    //     toast.warn("Class Teacher not assigned for this class-division.")
                    //     //console.log("aaaaaaaaaa")
                    // }
                    if (response.data === "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                        //console.log("aaaaaaaaaa")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }
                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Catalog failed: " + error)
                })
            //toast.success("valid Date")
        }
        else {
            toast.error("Improper date, Please check.");
        }
    }

    const printAllStdCatalogReport = async () => {
        if (moment(dateFrom).format("MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            setLoaderOption(true);
            //console.log(`${printCatalogAllStdDetailPageAPIURL}?mdata=${moment(dateFrom).format("MM/YYYY")}&blgb=${blankLinesBoysGirls}&ble=${blankLinesCatalogEnd}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${printCatalogAllStdDetailPageAPIURL}?mdata=${moment(dateFrom).format("MM/YYYY")}&blgb=${blankLinesBoysGirls}&ble=${blankLinesCatalogEnd}&langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}`)
                .then((response) => {
                    // if (response.data === "Warring : Class Teacher not assigned ") {
                    //     setLoaderOption(false);
                    //     toast.warn("Class Teacher not assigned for this class-division.")
                    //     //console.log("aaaaaaaaaa")
                    // }
                    if (response.data === "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                        //console.log("aaaaaaaaaa")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }
                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Catalog failed: " + error)
                })
            //toast.success("valid Date")
        }
        else {
            toast.error("Improper date, Please check.");
        }
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            printAllStdCatalogMainPage();
        }
        if (state.button === 2) {
            printAllStdCatalogReport();
        }
    };

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={onSubmit}>
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row">
                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin="dense">
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            fullWidth

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={option}
                                            label="Select Option"
                                            onChange={(e) => setOption(e.target.value)}
                                        >
                                            <MenuItem value={1}>Main Page</MenuItem>
                                            <MenuItem value={2}>Detailed Pages</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date"
                                            value={dateFrom}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            views={['year', 'month']}
                                            inputFormat="MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                                (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                            }}
                                            mask="__/____"
                                            disableMaskedInput={false}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small"
                                                //inputProps={{ maxLength: 7 }}
                                                />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <div className='col-sm-2 mt-2' hidden={(option == 1) ? true : false}>
                                    <FormControlLabel control={<Checkbox checked={printBlank} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 540, fontSize: "15px", marginTop: "2px" } }} label="Print Blank" />
                                </div>
                            </div>

                            {(() => {
                                if (option == 1) {
                                    return (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-sm-12 offset-sm-1">
                                                    <button type="submit" onClick={() => (state.button = 1)} className="btn btn-primary btn-sm">Show</button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                else if (option == 2) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-4" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >No. of Blank Lines Between Boys and Girls :</label>
                                                <div className='col-sm-3'>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        autoComplete='off'
                                                        size='small'
                                                        id="outlined-basic"
                                                        label="No. Of Lines"
                                                        variant="outlined"
                                                        margin='dense'
                                                        value={blankLinesBoysGirls}
                                                        onChange={e => setBlankLinesBoysGirls(e.target.value)}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        inputProps={{ maxLength: 120 }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row my-2">
                                                <label className="col-sm-4" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >No. of Blank Lines at the End of Catalog :</label>
                                                <div className='col-sm-3'>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        autoComplete='off'
                                                        size='small'
                                                        id="outlined-basic"
                                                        label="No. Of Lines"
                                                        variant="outlined"
                                                        margin='dense'
                                                        value={blankLinesCatalogEnd}
                                                        onChange={e => setBlankLinesCatalogEnd(e.target.value)}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        inputProps={{ maxLength: 120 }}

                                                    />
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-sm-12">
                                                    <button type="submit" onClick={() => (state.button = 2)} className="btn btn-primary btn-sm">Show</button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })()}

                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }


}

export default CatalogAllStd