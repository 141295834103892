import { dBaseURL,BaseURL } from "../../CommonServices/APIURL";

//get list
//localhost:8080/institute/sss/hostel/master/getAllMaterialMaster
export const getMasterialMasterAPIURL = BaseURL + "hostel/master/getAllMaterialMaster";

//save
// localhost:8080/institute/sss/hostel/master/saveMaterialMaster
// {
//     "materialName": "materialName"
// }
export const saveMaterialMasterAPIURL = BaseURL + "hostel/master/saveMaterialMaster";

//localhost:8080/institute/sss/hostel/master/updateMaterialMaster
export const updateMaterialMasterAPIURL = BaseURL + "hostel/master/updateMaterialMaster";

// /localhost:8080/institute/sss/hostel/master/deleteMaterialMaster?id=1
export const deleteMaterialMasterAPIURL = BaseURL + "hostel/master/deleteMaterialMaster";