import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/entranceExamDetail/getEntranceExamMarksDataByFormNo?acYear=2021&sid=3&bid=1&formNo=2
//export const getEntranceExamMarksDataByFormNoAPIURL = "http://192.168.1.102:8080/institute/sss/entranceExamDetail/getEntranceExamMarksDataByClassDivId?acYear=2022&sid=3&bid=36&divId=5";
export const getEntranceExamMarksDataByFormNoAPIURL = BaseURL + "entranceExamDetail/getEntranceExamMarksDataByClassDivId";


//PutMapping
//localhost:8080/institute/sss/entranceExamDetail/updateEntranceExamMarks
//export const updateEntranceExamMarksAPIURL = "http://192.168.1.102:8080/institute/sss/entranceExamDetail/updateEntranceExamMarks";
export const updateEntranceExamMarksAPIURL = BaseURL + "entranceExamDetail/updateEntranceExamMarks";


//localhost:8080/institute/sss/entranceExamDetail/getAllEntranceExamMarksData?acYear=2021&sid=3&bid=1
//export const getAllEntranceExamMarksDataAPIURL = "http://192.168.1.102:8080/institute/sss/entranceExamDetail/getAllEntranceExamMarksData";
export const getAllEntranceExamMarksDataAPIURL = BaseURL + "entranceExamDetail/getAllEntranceExamMarksData";
