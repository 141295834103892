import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

export const getClassDivisionURL = BaseURLMaster + "classWithDivisionBySectionId/";

// /institute/sss/academics/cpromotion/
// GetMapping /getData
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cdid") long classDivId
// long id, 
// long regCode, 
// String studentNameMr, 
// String studentNameEn, 
// long rollNo,
//  long isPromoted, 
// long sessionYear, 
// long sectionId,
//  long branchId,
//  long classId, 
// long divisionId, 
// long toClassId, 
// long toDivisionId

export const getStudentsForPramot = BaseURL + "academics/cpromotion/getData";

// /institute/sss/academics/cpromotion/
// @PostMapping("/saveData")
// @RequestBody List<ClassPromotionDto> classPromotionDtos

export const saveStudentsForPromotion = BaseURL + "academics/cpromotion/saveData";