import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useSelector } from "react-redux";
import { getSchoolDataAPIURL, saveTransferStudentDateAPIURL } from '../Services/TransferStudentsAPIURL';
import { getStudentList } from '../../CommonServices/StudentRegistrationAPIURL';
import Loader from '../../CommonComponent/Loader';
import { getClassDivisionURL } from '../Services/PramotShiftStudentsAPIURL';
import { BaseURLSection } from '../../CommonServices/InstituteMasterAPIURL'
import { GetStudentLcData } from '../Services/LcPrintAPIURL';
import { newRegCode } from '../../CommonServices/StudentRegistrationAPIURL';
import moment from 'moment';

const TransferStudents = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Transfer (Import) Students";

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [schoolData, setSchoolData] = useState([]);
    const [schoolObj, setSchoolObj] = useState(null);
    const [sectionData, setSectionData] = useState([]);
    const [sectionObj, setSectionObj] = useState(null);
    const [studentData, setStudentData] = useState([]);
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [studentObj, setStudentObj] = useState(null);
    const [newRegNo, setNewRegNo] = useState("");
    const [dateValue, setDateValue] = useState(null);
    //console.log(studentData.length)

    useEffect(() => {
        getData();
        getNewRegCode();
    }, [authUser]);

    const getData = async () => {
        await axios(`${getSchoolDataAPIURL}`)
            .then(res => {
                setSchoolData(res.data);
                //console.log("getSchoolDataAPIURL" + JSON.stringify(res.data))
            })
        await axios(`${BaseURLSection}`)
            .then(res => {
                setSectionData(res.data);
                //console.log("BaseURLSection" + JSON.stringify(res.data))
            })
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                })
        }
    }

    const getNewRegCode = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios.get(`${newRegCode}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setNewRegNo(response.data);
                })
        }
    }

    //Student Entry required data
    const [countData, setCountData] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);

    useEffect(() => {
        getAllStudentList();
    }, [numCal])

    const getAllStudentList = async () => {
        if (sectionObj != null || schoolObj != null) {
            await axios.get(`${GetStudentLcData}?sid=${sectionObj.id}&bid=${schoolObj.id}&limit=${limits}&offset=${offset}`)
                .then((res) => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        //console.log(tempData.length)
                        setStudentData(studentData.concat(tempData));
                        //setFilteredData(filteredData.concat(tempData));
                        //console.log("Data : " + JSON.stringify(data))
                        //console.log("____________________________________________")
                        if (tempData.length === limits) {
                            setOffset(offset + limits);
                            setNumCall(numCal + 1);
                            //console.log("Offset ===============" + offset);
                        }
                    }
                })
        }
    }

    const clearForm = () => {
        //setSchoolObj(null);
        //setSectionObj(null);        
        //setNewRegNo("");
        setStudentObj(null);
        setClassDivObj(null);
        //setStudentData([])
    }

    const saveFunction = async () => {

        const saveObj = {
            "oldRegCode": studentObj.regCode,
            "oldBranchId": studentObj.branchId,
            "oldSectionId": studentObj.sectionId,
            "newRegCode": newRegNo,
            "newBranchId": authUser.branchId,
            "newSectionId": authUser.deptId,
            "classDivisionId": classDivObj.divisionId,
            "admissionDate": moment(dateValue).format("DD/MM/YYYY"),
            "langId": authUser.branchMedium,
            "sessionYear": authUser.sessionYear
        }
        //console.log(saveObj)
        await axios.post(saveTransferStudentDateAPIURL, saveObj)
            .then((res) => {
                if (res.data === "SAVED") {
                    toast.success("Sucessfully saved")
                    clearForm()
                    getNewRegCode()
                }
                else if(res.data == "EXIST"){
                    toast.warn("Data already exists with this Reg No.")
                }
                else {
                    toast.error("Something went wrong")
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Error occured")
            })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        document.getElementById("modalToggle").click()
    }

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className="row">
                            <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >From School :</label>
                            <div className='col-sm-5'>
                                <Autocomplete
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    onChange={(e, newValue) => {
                                        setSchoolObj(newValue);
                                        //getStudentData(newValue.id);
                                        setStudentObj(null);
                                        setClassDivObj(null);
                                        setStudentData([]);
                                    }}
                                    value={schoolObj}
                                    options={schoolData}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Select School" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >From Department :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    onChange={(e, newValue) => {
                                        setSectionObj(newValue);
                                        setClassDivObj(null);
                                        setStudentObj(null);
                                        setStudentData([]);
                                    }}
                                    value={sectionObj}
                                    options={sectionData}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Select Department" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <button onClick={() => getAllStudentList()} style={{ marginTop: "14px", backgroundColor: "#46CC51", color: "#fff" }} className='btn btn-sm' type='button'>Get Data</button>
                            </div>
                        </div>
                        <hr />

                        <div className="row my-2">
                            <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    onChange={(e, newValue) => {
                                        setStudentObj(newValue);
                                    }}
                                    value={studentObj}
                                    options={studentData}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + " - " + option.classNameMr : option.regCode + " - " + option.studentNameEn + " - " + option.classNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Select Student" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", width: '140px' }} >New Reg No :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="New Reg No"
                                    variant="outlined"
                                    margin='dense'
                                    value={newRegNo}
                                    onChange={e => setNewRegNo(e.target.value)}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Admitted Class :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    onChange={(e, newValue) => {
                                        setClassDivObj(newValue);
                                    }}
                                    value={classDivObj}
                                    options={classDivData}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} label="Select Admitted Class" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", width: "140px" }} >Admitted Date :</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={dateValue}
                                        // minDate={defaultMinDate}
                                        // maxDate={defaultMaxDate}
                                        onChange={(newValue) => {
                                            setDateValue(newValue);
                                            //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-12 offset-sm-2">
                                <button type="submit" className={saveBtn}>Add</button>
                                <button type="button" onClick={() => clearForm()} className="btn btn-sm btn-warning" style={{ margin: "0px 4px" }}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
                <span id='modalToggle' data-bs-toggle="modal" data-bs-target="#exampleModal"></span>
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header mt-3">
                                <h4 className="modal-title" id="exampleModalLabel">Are you sure you want to add this student ?</h4>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" onClick={() => saveFunction()} className="btn btn-primary" data-bs-dismiss="modal">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


}

export default TransferStudents