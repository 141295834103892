import React, { useState } from 'react'


const SubjectSettingTextBox = ({ initialText1, initialText2, initialText3, initialText4, initialText5, initialText6, onChange, viewTypeData, markTypeData }) => {

    const txtBox = {
        borderRadius: "5px",
        padding: "4px",
        fontSize: "15px",
        fontWeight: "500",

    }

    // const [isEdit1, setEdit1] = useState(false);
    // const [isEdit2, setEdit2] = useState(false);
    // const [isEdit3, setEdit3] = useState(false);
    // const [isEdit4, setEdit4] = useState(false);
    // const [isEdit5, setEdit5] = useState(false);
    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    const [text3, setText3] = useState(initialText3);
    const [text4, setText4] = useState(initialText4);
    const [text5, setText5] = useState(initialText5);
    const [text6, setText6] = useState(initialText6);
    // const [viewTypeOption, setViewTypeOption] = useState(viewTypeData.find((e) => e.id == text2));
    // const [markTypeOption, setMarkTypeOption] = useState(markTypeData.find((e) => e.id == text4));


    //console.log("aaaaaaa"+viewTypeOption.viewTypeName)

    return (
        <>
            <td style={{ width: "130px", height: "70px", fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => { onChange(text1, text2, text3, text4, text5, text6) }}
                    style={txtBox}
                    onChange={(e) => setText1(e.target.value)}
                    value={text1}
                />
            </td>


            <td style={{ width: '170px', fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <select value={text2}
                    onChange={(e) => { setText2(e.target.value); }}
                    className='form-select form-select-sm'
                    style={{ width: "130px", fontSize: "14px" }}
                    onBlur={() => { onChange(text1, text2, text3, text4, text5, text6) }}
                    autoFocus
                >
                    {viewTypeData.map((e) => {
                        return (
                            <option value={e.id} key={e.id}>
                                {e.viewTypeName}
                            </option>
                        )
                    })}

                </select>
            </td>

            <td style={{ width: "250px", height: "70px", fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <input
                    className='form-control form-control-sm'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit3(true)}
                    onBlur={() => { onChange(text1, text2, text3, text4, text5, text6) }}
                    style={txtBox}
                    onChange={(e) => { setText3(e.target.value); }}
                    value={text3}

                />
            </td>

            <td style={{ width: "250px", height: "70px", fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <input
                    className='form-control form-control-sm'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit3(true)}
                    onBlur={() => { onChange(text1, text2, text3, text4, text5, text6) }}
                    style={txtBox}
                    onChange={(e) => { setText6(e.target.value); }}
                    value={text6}

                />
            </td>

            <td style={{ width: '170px', fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <select value={text4}
                    onChange={(e) => { setText4(e.target.value); }}
                    className='form-select form-select-sm'
                    style={{ width: "130px", fontSize: "14px" }}
                    onBlur={() => { onChange(text1, text2, text3, text4, text5, text6) }}
                    autoFocus
                >
                    {markTypeData.map((e) => {
                        return (
                            <option value={e.id} key={e.id}>
                                {e.markTypeName}
                            </option>
                        )
                    })}

                </select>
            </td>

            <td style={{ width: "130px", height: "70px", fontWeight: "500", fontSize: "14px" }} className='p-2'>
                <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit5(true)}
                    onBlur={() => { onChange(text1, text2, text3, text4, text5, text6) }}
                    style={txtBox}
                    onChange={(e) => setText5(e.target.value)}
                    value={text5}
                />
            </td>


        </>
    )
}

export default SubjectSettingTextBox