import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";


//localhost:8080/institute/sss/hostel/master/getUnallocatedBuildingList?year=2022&bid=36
export const getUnAllocatedBuildingListAPIURL = BaseURL + "hostel/master/getUnallocatedBuildingList";

//localhost:8080/institute/sss/hostel/master/getUnallocatedRoomList?year=2022&bid=36&buildingId=1
export const getUnAllocatedRoomByBuildingAPIURL = BaseURL + "hostel/master/getUnallocatedRoomList";

// /localhost:8080/institute/sss/hostel/master/getUnallocatedBedList?year=2022&bid=36&roomId=3
export const getUnAllocatedBedByRoomAPIURL = BaseURL + "hostel/master/getUnallocatedBedList";

// /localhost:8080/institute/sss/hostel/transaction/getAllHostelBedAllocationToStudentList?bid=36&year=2022
export const getHostelAllocationDataAPIURL = BaseURL + "hostel/transaction/getAllHostelBedAllocationToStudentList";

// /localhost:8080/institute/sss/hostel/transaction/saveHostelBedAllocationToStudent
// {
//     "regNo": 1069,
//     "bedId": 4,
//     "sessionYear": 2022,
//     "branchId": 36
// }
export const saveHostelAllocationAPIURL = BaseURL + "hostel/transaction/saveHostelBedAllocationToStudent";

//updateHostelBedAllocationToStudent
export const updateHostelAllocationAPIURL = BaseURL + "hostel/transaction/updateHostelBedAllocationToStudent";

//deleteHostelBedAllocationToStudent
export const deleteHostelAllocationAPIURL = BaseURL + "hostel/transaction/deleteHostelBedAllocationToStudent";