import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/hostel/transaction/getAllMedicalVisitList?bid=36&year=2022
export const getMedicalVisitAPIURL = BaseURL + "hostel/transaction/getAllMedicalVisitList";

//localhost:8080/institute/sss/hostel/transaction/saveMedicalVisit
// {
//     "regNo": 1069,
//     "date": "2023-03-21",
//     "reason": "reason",
//     "solution": "solution",
//     "medicine": "medicine",
//     "otherConsult": "otherConsult",
//     "sessionYear": 2022,
//     "branchId": 36
// }
export const saveMedicalVisitAPIURL = BaseURL + "hostel/transaction/saveMedicalVisit";

// /localhost:8080/institute/sss/hostel/transaction/updateMedicalVisit
export const updateMedicalVisitAPIURL = BaseURL + "hostel/transaction/updateMedicalVisit";

// /localhost:8080/institute/sss/hostel/transaction/deleteMedicalVisit?id=1
export const deleteMedicalVisitAPIURL = BaseURL + "hostel/transaction/deleteMedicalVisit";