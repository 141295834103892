import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//School Sttistics Report APIURL
// minorityWise
// localhost:8080/institute/sss/administration/reports/getMinorityWiseSchoolStatisticsReport?langId=2&sessionYear=2022&sid=3&bid=1
export const getMinorityWiseSchoolStatisticsReport = BaseURL + "administration/reports/getMinorityWiseSchoolStatisticsReport";

// category Wise
// localhost:8080/institute/sss/administration/reports/getCategoryWiseSchoolStatisticsReport?langId=2&sessionYear=2022&sid=3&bid=1
export const getCategoryWiseSchoolStatisticsReport = BaseURL + "administration/reports/getCategoryWiseSchoolStatisticsReport";

//Student Age Statistics Report APIURL
//localhost:8080/institute/sss/administration/reports/getAgeWiseSchoolStatisticsReport?langId=2&sessionYear=2022&sid=3&bid=1
export const getStudAgeStatisticReportAPIURL = BaseURL + "administration/reports/getAgeWiseSchoolStatisticsReport";