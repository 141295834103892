import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TitleLabel from "../../CommonComponent/TitleLabel";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getClassData } from '../../CommonServices/StudentRegistrationAPIURL';
import Loader from "../../CommonComponent/Loader";
import moment from 'moment';
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import { manualBonafideSaveAndPrint, getPrintBonafideAPIURL } from '../Services/BonafideAPIURL';

const useStyles = makeStyles(() => ({
    label: {
        fontWeight: 548
    }
}));

const ManualBonafide = () => {
    const titleId = "Manual Bonafide";
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);

    const navigate = useNavigate();

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);


    const [id, setId] = useState();
    const [classData, setClassData] = useState([]);
    const [languageId, setLanguageId] = useState('');
    const [waterMark, setWaterMark] = useState(0);

    const HandleWaterMarkCheckbox = (e) => {
        setWaterMark(e.target.checked);
        if (e.target.checked == true) {
            setWaterMark(1);
        }
        else {
            setWaterMark(0);
        }
    }



    let sectionId = authUser.deptId;
    let branchedId = authUser.branchId;
    //console.log( sectionId + "======" + branchedId)

    //form data variable

    const [regNo, setRegNo] = useState('');
    const [aadharNo, setAadharNo] = useState("");
    const [saralNo, setSaralNo] = useState("");
    const [fname, setFname] = useState("");
    const [mname, setMname] = useState("");
    const [lname, setLname] = useState("");
    const [motherName, setMotherName] = useState("");
    const [religionObj, setReligionObj] = useState("");
    const [castObj, setCastObj] = useState("");
    const [subCastObj, setSubCastObj] = useState("");
    const [nationalityObj, setNationalityObj] = useState("");
    const [classObj, setClassObj] = useState(null);

    const [birthVillage, setBirthVillage] = useState("");
    const [birthTaluka, setBirthTaluka] = useState("");
    const [birthDistrict, setBirthDistrict] = useState("");
    const [birthState, setBirthState] = useState("");
    const [birthCountry, setBirthCountry] = useState("");
    const [birthDate, setBirthDate] = useState(null);
    const [sinceWhen, setSinceWhen] = useState(null);
    const [gender, setGender] = useState(1);

    //Gender management(radio button)
    const handleGender = (event) => {
        setGender(event.target.value);

    };


    // useEffect(() => {
    //     if (authUser.branchMedium == 1) {
    //         setBirthState("महाराष्ट्र");
    //         setBirthCountry("भारत");
    //         setNationalityObj("भारतीय");
    //     }
    //     else if (authUser.branchMedium == 2) {
    //         setBirthState("Maharashtra");
    //         setBirthCountry("India");
    //         setNationalityObj("Indian");
    //     }
    // }, [authUser])


    useEffect(() => {
        getData();
    }, [authUser]);

    //URL List
    const getData = async () => {
        if (sectionId != null || branchedId != null) {
            //console.log(`${getClassData}?sid=${sectionId}&bid=${branchedId}`);
            await axios.get(`${getClassData}?sid=${sectionId}&bid=${branchedId}`)
                .then((response) => {
                    setClassData(response.data);
                })
        }

    }


    const printManualBonafide = async (e) => {

        e.preventDefault();
        setLoaderOption(true);
        const saveObj = {
            "langId": languageId,
            "aadhaarNo": aadharNo,
            "saralId": saralNo,
            "regNo": regNo,
            "firstName": fname,
            "middleName": mname,
            "lastName": lname,
            "gender": gender,
            "classId": classObj.id,
            "sinceWhen": moment(sinceWhen).format("YYYY-MM-DD"),
            "birthDate": moment(birthDate).format("YYYY-MM-DD"),
            "religion": religionObj,
            "caste": castObj,
            "subCaste": subCastObj,
            "birthPlace": birthVillage,
            "birthTaluka": birthTaluka,
            "birthDistrict": birthDistrict,
            "birthState": birthState,
            "birthCountry": birthCountry,
            "nationality": nationalityObj,
            "motherName": motherName,
            "branchId": authUser.branchId,
            "sectionId": authUser.deptId,
            "isWatermark": waterMark
        }
        //console.log(JSON.stringify(saveObj))
        await axios.post(`${manualBonafideSaveAndPrint}`, saveObj)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    clearData();
                    setLoaderOption(false);
                    toast.warn("Data not found.")

                }
                else if (response.data != null) {       
                    clearData();             
                    window.open(`${getPrintBonafideAPIURL}${response.data}`);                    
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Manual Bonafide Report failed: " + error)
            })
    }

    const clearData = () => {
        setLanguageId(1);
        setGender(1);
        setFname("");
        setMname("");
        setLname("");
        setAadharNo("");
        setSaralNo("");
        setMotherName("");
        setReligionObj("");
        setCastObj("");
        setSubCastObj("");
        setNationalityObj("");
        setBirthVillage("");
        setBirthCountry("");
        setBirthState("");
        setBirthDistrict("");
        setBirthDate(null);
        setSinceWhen(null);
        setClassObj(null);
        setBirthTaluka("");
        setWaterMark(0);
        setRegNo('');
    }


    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={printManualBonafide}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className='row my-3'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Language:</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth>
                                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        margin='dense'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={languageId}
                                        label="Select Language"
                                        onChange={(e) => {
                                            setLanguageId(e.target.value);
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Marathi</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>English</MenuItem>
                                    </Select>
                                </FormControl>

                            </div>

                            <div className='col-sm-2'>
                                <FormControlLabel classes={{ label: classes.label }} control={<Checkbox checked={waterMark} onChange={HandleWaterMarkCheckbox} />} label="Watermark" />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "14px" }} >Gender:</label>
                            <div className='col-sm-3'>
                                <RadioGroup row aria-label="gender" name="customized-radios" value={gender} onChange={handleGender}>
                                    <FormControlLabel value={1} control={<Radio color='primary' />} label="Male" />
                                    <FormControlLabel value={2} control={<Radio color='primary' />} label="Female" />
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Full Name:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    onKeyDown={(e) => (e.key === " " && fname.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={fname}
                                    onChange={e => setFname(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="First Name"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    onKeyDown={(e) => (e.key === " " && mname.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={mname}
                                    onChange={e => setMname(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Middle Name"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    onKeyDown={(e) => (e.key === " " && lname.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={lname}
                                    onChange={e => setLname(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Last Name"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reg No:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    margin='dense'
                                    onChange={e => setRegNo(e.target.value)}
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Reg No"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    value={regNo}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Name:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    onKeyDown={(e) => (e.key === " " && motherName.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={motherName}
                                    onChange={e => setMotherName(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mother Name"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>



                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Aadhar No:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    fullWidth
                                    margin='dense'
                                    value={aadharNo}
                                    onChange={e => { setAadharNo(e.target.value); }}
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Aadhar No"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 12 }}
                                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                                />
                                {/* <span style={{ display: (aadharNo == "" || aadharNo == "-") ? "block" : "none" }}><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{checkRequired}</em></span> */}
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Saral No:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    // required={(authUser.deptId == 1 || authUser.deptId == 5) ? false : true}
                                    fullWidth
                                    margin='dense'
                                    value={saralNo}
                                    onChange={e => setSaralNo(e.target.value)}
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Saral No"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                                {/* <span style={{ display: (saralNo == "" || saralNo == "-") ? "block" : "none" }}><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{checkRequired}</em></span> */}
                            </div>
                        </div>




                        <div className='row my-3'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Religion:</label>
                            <div className="col-sm-3">
                                <TextField
                                    required
                                    onKeyDown={(e) => (e.key === " " && religionObj.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={religionObj}
                                    onChange={e => setReligionObj(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Religion"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Nationality:</label>
                            <div className="col-sm-3 ">
                                <TextField
                                    required
                                    onKeyDown={(e) => (e.key === " " && nationalityObj.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={nationalityObj}
                                    onChange={e => setNationalityObj(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Nationality"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>


                        <div className='row my-3'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Caste:</label>
                            <div className="col-sm-3 ">
                                <TextField
                                    required
                                    onKeyDown={(e) => (e.key === " " && castObj.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={castObj}
                                    onChange={e => setCastObj(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Caste"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Sub Caste:</label>
                            <div className="col-sm-3 ">
                                <TextField                                    
                                    onKeyDown={(e) => (e.key === " " && subCastObj.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    margin='dense'
                                    value={subCastObj}
                                    onChange={e => setSubCastObj(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Sub Caste"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Birth Date:</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Birth Date"

                                        value={birthDate}
                                        inputFormat='dd/MM/yyyy'
                                        onChange={(newValue) => {
                                            setBirthDate(newValue);
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Birth Place:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && birthVillage.length === 0) ? e.preventDefault() : ""}
                                    margin='dense'
                                    value={birthVillage}
                                    onChange={e => setBirthVillage(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Place"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>


                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Birth Taluka:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && birthTaluka.length === 0) ? e.preventDefault() : ""}
                                    margin='dense'
                                    value={birthTaluka}
                                    onChange={e => setBirthTaluka(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Taluka"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth District:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && birthDistrict.length === 0) ? e.preventDefault() : ""}
                                    value={birthDistrict}
                                    onChange={e => setBirthDistrict(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth District"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>



                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth State:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    margin='dense'
                                    onKeyDown={(e) => (e.key === " " && birthState.length === 0) ? e.preventDefault() : ""}
                                    value={birthState}
                                    onChange={e => setBirthState(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth State"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Country:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && birthCountry.length === 0) ? e.preventDefault() : ""}
                                    margin='dense'
                                    value={birthCountry}
                                    onChange={e => setBirthCountry(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Country"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className='row my-3'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Current Std:</label>
                            <div className="col-sm-3 ">
                                <Autocomplete
                                    id="combo-box-demo"

                                    options={classData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setClassObj(newValue);
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    value={classObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Std" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Current Std Since:</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Current Std Since"

                                        value={sinceWhen}
                                        inputFormat='dd/MM/yyyy'
                                        onChange={(newValue) => {
                                            setSinceWhen(newValue);
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>


                        </div>


                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" disabled={saveBtnDisabled} className={saveBtn}>Print</button>
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => navigate('/Home/ad221/')} style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>

                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

};


export default ManualBonafide