import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from "@mui/material";

const MegaMenuNavItem = (props) => {

    const [menuData, setMenuData] = useState();


    return (
        <>
            <li className="nav-item mega-menu">
                <NavLink to={props.PathName} className="nav-link"><i><Icon style={{ fontSize: "16px", color: "#464de4", verticalAlign: "middle", marginRight: "2px" }}>{props.LinkIcon}</Icon></i><span className="menu-title"> {props.Title}</span></NavLink>
                <div className="submenu" style={{ maxHeight: "600px", overflowY: "auto" }}>
                    <div className="col-group-wrapper row" >
                        {
                            props.MegaMenuDropDown.map((item, index) => {
                                return (
                                    <div key={index} className="col-group col-md-3">
                                        <p className="category-heading">{item.HeaddingName}</p>
                                        <ul className="submenu-item">
                                            {
                                                item.MegaMenuSubItems.map((i, n) => {
                                                    return (
                                                        <li key={n} className="nav-item"><NavLink className="nav-link" onClick={() => { props.refreshMenu(); props.handleMenuClick(props.Title, props.PathName) }} to={i.SubPath}>
                                                            {i.SubName}
                                                        </NavLink>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </li>
        </>
    )
}

export default MegaMenuNavItem;
