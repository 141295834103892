import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//get list
//localhost:8080/institute/sss/hostel/master/getAllStudentLeaveMaster
export const getStudLeaveMasterAPIURL = BaseURL + "hostel/master/getAllStudentLeaveMaster";

// /save
// localhost:8080/institute/sss/hostel/master/saveStudentLeaveMaster
export const saveStudLeaveMasterAPIURL = BaseURL + "hostel/master/saveStudentLeaveMaster";

// /update
//localhost:8080/institute/sss/hostel/master/updateStudentLeaveMaster
export const updateStudleaveMasterAPIURL = BaseURL + "hostel/master/updateStudentLeaveMaster";

// /delete
//localhost:8080/institute/sss/hostel/master/deleteStudentLeaveMaster?id=1
export const deleteStudLeaveMasterAPIURL = BaseURL + "hostel/master/deleteStudentLeaveMaster";