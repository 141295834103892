import { Autocomplete, Checkbox, FormControlLabel, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitleLabel from '../../CommonComponent/TitleLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../CommonComponent/Loader';
import { getStudentListByBranchAPIURL } from '../../CommonServices/StudentRegistrationAPIURL';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getStudLeaveMasterAPIURL } from '../Services/StudentLeaveMasterAPIURL';
import StudentLeaveTable from './StudentLeaveTable';
import moment from 'moment';
import { getStudentLeaveAPIURL, saveStudentLeaveAPIURL, updateStudentLeaveAPIURL } from '../Services/StudentLeaveAPIURL';
import { getStudentListForHostelByBid } from '../Services/MaterialAllocationAPIURL';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const StudentLeaveForm = () => {
  const titleId = 'Student Leave';
  const authUser = useSelector((state) => state.user.value);
  const [loaderOption, setLoaderOption] = useState(false);

  const TableHeading = [
    { label: 'ID', key: 'id' },
    { label: `Student`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
    { label: `From Date`, key: 'dateFrom' },
    { label: `To Date`, key: 'dateTo' },
    { label: `Reason of Leave`, key: 'leaveReason' },
    { label: `Reporting`, key: 'isReported' },
    { label: `Sanction`, key: 'isSanction' },
    { label: 'Edit', value: 'edit', isAction: true },
    { label: 'Delete', value: 'delete', isAction: true }];

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "studentLeaveSaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "studentLeaveUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [id, setId] = useState('');
  const [studentData, setStudentData] = useState([]);
  const [studentObj, setStudentObj] = useState(null);
  const [leaveData, setLeaveData] = useState([]);
  const [leaveObj, setLeaveObj] = useState(null);
  const [leaveId, setleaveId] = useState(null);
  const [otherLeave, setOtherLeave] = useState('');
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [sanctionChkBox, setSanctionChkBox] = useState(0);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [readOnlyField, setReadOnlyField] = useState(false);

  const HandleSanctionCheckbox = (e) => {
    setSanctionChkBox(e.target.checked);
    if (e.target.checked == true) {
      setSanctionChkBox(1);
    }
    else if (e.target.checked == false) {
      setSanctionChkBox(0);
    }
  }

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && studentData.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...studentData]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    getData();
  }, [authUser])

  const getData = async () => {
    setLoaderOption(true);

    if (authUser.sessionYear != '' && authUser.branchId != null) {
      //console.log(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}`)
      await axios.get(`${getStudentListForHostelByBid}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        .then(res => {
          const presentStudentList = res.data.filter(e => e.currentStatus == 0)
          setStudentData(presentStudentList);
          setLoaderOption(false);
        }).catch(err => {
          setLoaderOption(false);
          console.log('get student list err' + err)
        })

      await axios.get(`${getStudentLeaveAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        .then(res => {
          setData(res.data);
          setFilteredData(res.data);
          setLoaderOption(false);
        }).catch(err => {
          setLoaderOption(false);
          console.log('get student leave err' + err)
        })

    }

    await axios.get(`${getStudLeaveMasterAPIURL}`)
      .then(res => {
        const newLeaveData = [...res.data, { id: 0, leaveName: "Other" }]
        setLeaveData(newLeaveData);
        setLoaderOption(false);
      }).catch(err => {
        setLoaderOption(false);
        console.log('get student leave master err' + err)
      })
  }

  const clearData = () => {
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
    setStudentObj(null);
    setLeaveObj(null);
    setOtherLeave('');
    setDateFrom(null);
    setDateTo(null);
    setSanctionChkBox(0);
    setleaveId(null);
    setReadOnlyField(false);
  }

  //save function
  const saveBtnClick = async () => {
    setLoaderOption(true);
    if (moment(dateFrom).format('DD/MM/YYYY') != 'Invalid date' && moment(dateTo).format('DD/MM/YYYY') != 'Invalid date') {
      const saveObj = {
        "regNo": studentObj.regCode,
        "leaveId": leaveId,
        "leaveReason": (otherLeave == '') ? null : otherLeave,
        "dateFrom": moment(dateFrom).format('YYYY-MM-DD'),
        "dateTo": moment(dateTo).format('YYYY-MM-DD'),
        "isSanction": sanctionChkBox,
        "sessionYear": authUser.sessionYear,
        "branchId": authUser.branchId
      }
      await axios.post(saveStudentLeaveAPIURL, saveObj)
        .then(res => {
          if (res.data == "SAVED") {
            getData();
            clearData();
            setLoaderOption(false);
            toast.success("Saved successfully.")
          }
          // else if (res.data == "ALREADYEXISTS") {
          //   setLoaderOption(false);
          //   toast.warn("Allocation for this student is already done.")
          // }
          else {
            setLoaderOption(false);
            toast.error("student leave save failed.")
          }
        }).catch(err => {
          console.log("student leave save err" + err);
          setLoaderOption(false);
          toast.error("Something went wrong, please check.")
        })
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }
  }

  //update function
  const updateBtnClick = async () => {
    setLoaderOption(true);
    if (moment(dateFrom).format('DD/MM/YYYY') != 'Invalid date' && moment(dateTo).format('DD/MM/YYYY') != 'Invalid date') {
      const updateObj = {
        "id": id,
        "leaveId": leaveId,
        "leaveReason": (otherLeave == '') ? null : otherLeave,
        "dateFrom": moment(dateFrom).format('YYYY-MM-DD'),
        "dateTo": moment(dateTo).format('YYYY-MM-DD'),
        "isSanction": sanctionChkBox,
        "sessionYear": authUser.sessionYear,
        "branchId": authUser.branchId
      }
      //console.log(JSON.stringify(updateObj))
      await axios.put(updateStudentLeaveAPIURL, updateObj)
        .then(res => {
          if (res.data == "UPDATED") {
            getData();
            clearData();
            setLoaderOption(false);
            toast.success("Updated successfully.")
          }
          // else if (res.data == "ALREADYEXISTS") {
          //   setLoaderOption(false);
          //   toast.warn("Allocation for this student is already done.")
          // }
          else {
            setLoaderOption(false);
            toast.error("student leave update failed.")
          }
        }).catch(err => {
          console.log("student leave update err" + err);
          setLoaderOption(false);
          toast.error("Something went wrong, please check.")
        })
    }
    else {
      setLoaderOption(false);
      toast.error('Improper date, please check.')
    }
  }

  const state = {
    button: 1
  }

  const onSubmit = e => {
    e.preventDefault();
    if (state.button == 1) {
      saveBtnClick();
    }
    if (state.button == 2) {
      updateBtnClick();
    }
  }

  //submit form at enter press
  useEffect(() => {

    function handlekeydownEvent(event) {
      if (event.key === "Enter") {
        console.log("Enter is pressed!")
        event.preventDefault();

        let btn1 = document.querySelector('.studentLeaveSaveBtn')
        if (btn1 !== null) {
          btn1.click();
        }
        let btn2 = document.querySelector('.studentLeaveUpdateBtn')
        if (btn2 !== null) {
          btn2.click();
        }
      }
    }
    document.addEventListener('keypress', handlekeydownEvent)
    return () => {
      document.removeEventListener('keypress', handlekeydownEvent)
    }

  }, []);

  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
              {/* Form Data start */}
              <form onSubmit={onSubmit}>
                <div className="form-data mt-1">
                  <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                  <div className="row">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                    <div className='col-sm-4'>
                      <Autocomplete
                        id="combo-box-demo"
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        loading={loading}
                        options={studentData}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                        )}
                        onChange={(event, newValue) => {
                          setStudentObj(newValue);
                          setLeaveObj(null);
                          setOtherLeave('');
                          setDateFrom(null);
                          setDateTo(null);
                          setSanctionChkBox(0);
                        }}
                        readOnly={(readOnlyField == true) ? true : false}
                        onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                        value={studentObj}
                        style={{ fontSize: '14px' }}
                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                        renderOption={(props, option) => (
                          <div className='' {...props}>
                            <div className=''>
                              {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                            </div>

                          </div>
                        )}
                        size="small"
                        renderInput={params => (
                          <TextField {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                            required />
                        )}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reason of Leave :</label>
                    <div className='col-sm-4'>
                      <Autocomplete
                        id="combo-box-demo"
                        options={leaveData}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                        )}
                        onChange={(event, newValue) => {
                          setLeaveObj(newValue);
                          setOtherLeave('');
                          setDateFrom(null);
                          setDateTo(null);
                          setSanctionChkBox(0);
                          if (newValue != null) {
                            setleaveId(newValue.id);
                          }

                        }}
                        onKeyPress={(e) => (e.key == "Enter") ? leaveObj : ""}
                        value={leaveObj}
                        style={{ fontSize: '14px' }}
                        getOptionLabel={option => option.leaveName}

                        size="small"
                        renderInput={params => (
                          <TextField {...params}
                            margin='dense' label="Select leave" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                            required />
                        )}
                      />
                    </div>
                  </div>

                  {(() => {
                    if (leaveId != null) {
                      if (leaveId == 0) {
                        return (
                          <>
                            <div className="row my-2">
                              <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Other Leave Detail :</label>
                              <div className='col-sm-4'>
                                <TextField
                                  required={(leaveId == 0) ? true : false}
                                  fullWidth
                                  onKeyDown={(e) => (e.key === " " && otherLeave.length === 0) ? e.preventDefault() : ""}
                                  autoComplete='off'
                                  size='small'
                                  id="outlined-basic"
                                  label="Other Leave Detail"
                                  variant="outlined"
                                  margin='dense'
                                  value={otherLeave}
                                  onChange={e => setOtherLeave(e.target.value)}
                                  onKeyPress={(e) => (e.key == "Enter") ? otherLeave : ""}
                                  InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                              </div>
                            </div>
                          </>
                        )
                      }
                    }
                  })()}

                  <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date From :</label>
                    <div className='col-sm-2'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date From"
                          value={dateFrom}
                          onChange={(newValue) => {
                            setDateFrom(newValue);
                          }}
                          onKeyPress={(e) => (e.key == "Enter") ? dateFrom : ""}
                          inputFormat="dd/MM/yyyy"
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              margin='dense'
                              variant="outlined"
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              required
                              fullWidth
                              autoComplete='off'
                              size="small" />}
                        />
                      </LocalizationProvider>
                    </div>


                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >To :</label>
                    <div className='col-sm-2'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="To"
                          value={dateTo}
                          onChange={(newValue) => {
                            setDateTo(newValue);
                          }}
                          onKeyPress={(e) => (e.key == "Enter") ? dateTo : ""}
                          inputFormat="dd/MM/yyyy"
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              margin='dense'
                              variant="outlined"
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              required
                              fullWidth
                              autoComplete='off'
                              size="small" />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className='col-sm-2 offset-sm-2'>
                      <FormControlLabel control={<Checkbox checked={sanctionChkBox} onChange={HandleSanctionCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Sanction" />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-sm-4 offset-sm-2">
                      <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                      <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                      <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                    </div>
                  </div>
                </div>
              </form>

              <div className="mt-3">
                <StudentLeaveTable
                  TableHeading={TableHeading}
                  data={data}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  getData={getData}
                  clearData={clearData}
                  setId={setId}
                  studentData={studentData}
                  setStudentObj={setStudentObj}
                  leaveData={leaveData}
                  setLeaveObj={setLeaveObj}
                  setDateFrom={setDateFrom}
                  setDateTo={setDateTo}
                  setSanctionChkBox={setSanctionChkBox}
                  setOtherLeave={setOtherLeave}
                  setleaveId={setleaveId}
                  setReadOnlyField={setReadOnlyField}
                  setSaveBtn={setSaveBtn}
                  setUpdateBtn={setUpdateBtn}
                  updateBtnStyle={updateBtnStyle}
                  displayNoneBtnStyle={displayNoneBtnStyle}
                />
              </div>
            </div>
          </>
      }
    </>
  )
}

export default StudentLeaveForm