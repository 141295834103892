import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const postStudentDataEntry = BaseURL + "datatransfer/student/excel";
// /institute/sss/datatransfer/student/
// PostMapping  /excel
// @RequestParam("file") MultipartFile filePath, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId


 
// /institute/sss/datatransfer/student/

// PostMapping  /updateTempLCData
// @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId

export const saveTempLCDataExcel = BaseURL + "datatransfer/student/updateTempLCData";

// ========================================================================================

// /institute/sss/datatransfer/student/

// PostMapping  /updateTempLCData
// @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId

// ========================================================================================


// /institute/sss/datatransfer/student/

//  PostMapping /saveData
// @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId ,@RequestParam("lid") long langId

export const saveStudentDataEntryExcel = BaseURL + "datatransfer/student/saveData";

//==========================================================================================================


export const updateNameDataEntryExcel = BaseURL + "datatransfer/student/updateNameData";

// /institute/sss/datatransfer/student/

// @PutMapping("/updateNameData")

//  @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId ,@RequestParam("lid") long langId

