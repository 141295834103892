import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitleLabel from '../../CommonComponent/TitleLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../CommonComponent/Loader';
import { getStudentListByBranchAPIURL } from '../../CommonServices/StudentRegistrationAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { printMedicalVisitReportAPIURL } from '../Services/HostelReportAPIURL';
import moment from 'moment';
import { getStudentListForHostelByBid } from '../Services/MaterialAllocationAPIURL';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const MedicalVisitReport = () => {
    const titleId = 'Medical Visit Report';
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState('');
    const [option, setOption] = useState(1);
    const [subOption, setSubOption] = useState(1);
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && studentData.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (authUser.branchId != null && authUser.sessionYear != "") {

            await axios.get(`${getStudentListForHostelByBid}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    const presentStudentList = res.data.filter(e => e.currentStatus == 0)
                    setStudentData(presentStudentList);

                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get student list err' + err)
                })
            //console.log(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}`)
            // await axios.get(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}`)
            //     .then(res => {
            //         const presentStudentList = res.data.filter(e => e.currentStatus == 0)
            //         setStudentData(presentStudentList);
            //     }).catch(err => {
            //         console.log('get student list err' + err)
            //     })
        }
    }

    const getMedicalVisitReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (moment((dateFrom == null) ? new Date() : dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment((dateTo == null) ? new Date() : dateTo).format('DD/MM/YYYY') != 'Invalid date') {
            await axios.get(`${printMedicalVisitReportAPIURL}?langId=${authUser.branchMedium}&optionId=${option}&subOption=${subOption}&regNo=${(subOption == 2) ? studentObj.regCode : 0}&dateFrom=${(subOption == 3) ? moment(dateFrom).format('YYYY-MM-DD') : null}&dateTo=${(subOption == 3) ? moment(dateTo).format('YYYY-MM-DD') : null}&year=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data bot found.");
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Print Medical Visit report" + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            {/* Form Data start */}
                            <form onSubmit={getMedicalVisitReport}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                <Select
                                                    size='small'
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Select Option"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    onChange={(e) => {
                                                        setOption(e.target.value);
                                                        setStudentObj(null);
                                                        setDateFrom(null);
                                                        setDateTo(null);
                                                        setSubOption(1);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                                >
                                                    <MenuItem value={1}>Detail</MenuItem>
                                                    <MenuItem value={2}>Summary</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Sub - Option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Sub-Option</InputLabel>
                                                <Select
                                                    size='small'
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={subOption}
                                                    label="Select Sub-Option"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    onChange={(e) => {
                                                        setSubOption(e.target.value);
                                                        setStudentObj(null);
                                                        setDateFrom(null);
                                                        setDateTo(null);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? subOption : ""}
                                                >
                                                    <MenuItem value={1}>All</MenuItem>
                                                    <MenuItem value={2}>Student Wise</MenuItem>
                                                    <MenuItem value={3}>Date Wise</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {(() => {
                                        if (subOption == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                                        <div className='col-sm-4'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                open={open}
                                                                onOpen={() => {
                                                                    setOpen(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen(false);
                                                                }}
                                                                loading={loading}
                                                                options={studentData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    setStudentObj(newValue);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                                                value={studentObj}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                                renderOption={(props, option) => (
                                                                    <div className='' {...props}>
                                                                        <div className=''>
                                                                            {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                                                        </div>

                                                                    </div>
                                                                )}
                                                                size="small"
                                                                renderInput={params => (
                                                                    <TextField {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                        margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required={(subOption == 2) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (subOption == 3) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date From :</label>
                                                        <div className='col-sm-3'>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="Date From"
                                                                    value={dateFrom}
                                                                    onChange={(newValue) => {
                                                                        setDateFrom(newValue);
                                                                    }}
                                                                    onKeyPress={(e) => (e.key == "Enter") ? dateFrom : ""}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            margin='dense'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required={(subOption == 3) ? true : false}
                                                                            fullWidth
                                                                            autoComplete='off'
                                                                            size="small" />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>


                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >To :</label>
                                                        <div className='col-sm-3'>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="To"
                                                                    value={dateTo}
                                                                    onChange={(newValue) => {
                                                                        setDateTo(newValue);
                                                                    }}
                                                                    onKeyPress={(e) => (e.key == "Enter") ? dateTo : ""}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            margin='dense'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required={(subOption == 3) ? true : false}
                                                                            fullWidth
                                                                            autoComplete='off'
                                                                            size="small" />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default MedicalVisitReport