import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { getFeeListData, getStudentListForStudWiseFeeDemand, saveStudentsForStudWiseFeeDemand } from '../Services/FeeDemandAPIURL';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { classWithDivisionURL } from '../Services/FeeReportAPIURL';
import StudentWiseFeeDemandText from './StudentWiseFeeDemandTextBox/StudentWiseFeeDemandText';

const StudentWiseFeeDemand = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Wise Fee Demand";

    const [loaderOption, setLoaderOption] = useState(false);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);

    const TableHeading = [
        { label: 'Reg No', key: 'regNo' },
        { label: `Student`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: 'Roll No', key: 'rollNo' },
        { label: `Amount`, key: 'amount', isAction: true },
    ];

    const [feeData, setFeeData] = useState([]);
    const [data, setData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [id, setId] = useState();

    const [classDivData, setClassDivData] = useState([]);
    const [feeObj, setFeeObj] = useState(null);
    const [classDivObj, setClassDivObj] = useState(null);
    const [amount, setAmount] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [regNum, setRegNum] = useState('');
    const [updateData, setUpdateData] = useState([]);
    const [checkValue, setCheckValue] = useState([]);




    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassDivData(res.data);
            })

        await axios(`${getFeeListData}`)
            .then(res => {
                setFeeData(res.data);
            })
    }

    const getStudentDataForFeeDemand = async () => {
        setLoaderOption(true);
        setFilteredData([]);
        setData([]);
        setTempData([]);
        setSearchTerm("");
        setSortedBy(null);
        setAscending(false);
        await axios.get(`${getStudentListForStudWiseFeeDemand}?feeId=${feeObj.id}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((res) => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Students are not allocated to this class-division.")
                }
                else {
                    setData(res.data);
                    setFilteredData(res.data);
                    setTempData(res.data);
                    setLoaderOption(false);
                }

            }).catch(error => {
                console.log(error)
            })
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentListForStudWiseFeeDemand}?feeId=${feeObj.id}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    useEffect(() => {
        let updatedData = tempData;
        tempData.map((e, index) => {
            if (e.regNo == regNum) {
                updatedData[index] = ({ ...e, amount: updateData })
            }
        })

        setData(updatedData);
        if (searchTerm == "") {
            setFilteredData(updatedData);
        }

    }, [searchTerm, regNum])


    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(item =>
            item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.amount.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const saveStudentFee = async (e) => {
        setLoaderOption(true);
        e.preventDefault();
        //console.log("savedata====" + JSON.stringify(checkValue))
        await axios.post(saveStudentsForStudWiseFeeDemand, checkValue)
            .then(res => {
                if (res.data == "SAVED") {
                    setFilteredData([]);
                    setData([]);
                    setTempData([]);
                    setCheckValue([]);
                    setFeeObj(null);
                    setClassDivObj(null);
                    setSearchTerm("");
                    setLoaderOption(false);
                    toast.success("Fee allocation successfully done.")
                }
            }).catch(error => {
                console.log("Update Fire Query: " + error)
            })
    }

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (data != null || data != "") {
                    console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.studWiseFeeBtn')
                    if (btn !== null) {
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row my-1">
                        <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Fee :</label>
                        <div className='col-sm-4'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={feeData}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                    setFeeObj(newValue);
                                }}
                                value={feeObj}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} margin='dense' label="Select Fee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    <div className="row my-1">
                        <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                        <div className='col-sm-4'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={classDivData}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                    setClassDivObj(newValue);
                                }}
                                value={classDivObj}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} margin='dense' label="Select Class-division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <div className="col-sm-4 mt-3">
                            <button type='button' onClick={() => getStudentDataForFeeDemand()} className='btn btn-sm btn-primary' disabled={(classDivObj == null || classDivObj == "" || feeObj == null || feeObj == "") ? true : false}>VIEW</button>
                        </div>
                    </div>

                    {
                        (data == [] || data == null || data == "")
                            ?
                            <h4 className='mt-5'>No records found, Select class-division & fee</h4>
                            :
                            <div>
                                <div className='mt-3'>
                                    <div className='row col-sm-4 mb-1' style={{ float: 'right' }}>
                                        <div>
                                            <input
                                                className='form-control form-control-sm'
                                                type="text"
                                                style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                placeholder="Search Here"
                                                value={searchTerm}
                                                onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                            />
                                        </div>
                                    </div>

                                    <form onSubmit={saveStudentFee}>
                                        <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                            <table className="table table-bordered ">
                                                <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                    <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                        {TableHeading.map((item, index) => {
                                                            return (
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                    {filteredData
                                                        .map((item, index) => {
                                                            return (
                                                                <React.Fragment key={index} >
                                                                    <tr style={{ height: "65px" }} >
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "130px" }}>{item.regNo}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "130px" }}>{item.rollNo}</td>

                                                                        <StudentWiseFeeDemandText initialText={item.amount} onChange={(value) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, amount: value });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);
                                                                            setRegNum(updatedData[index].regNo);
                                                                            setUpdateData(value);
                                                                            let saveObj = [{
                                                                                "classId": classDivObj.classId,
                                                                                "divisionId": classDivObj.divisionId,
                                                                                "regNo": updatedData[index].regNo,
                                                                                "feeId": feeObj.id,
                                                                                "amount": updatedData[index].amount,
                                                                                "acYear": authUser.sessionYear,
                                                                                "sectionId": authUser.deptId,
                                                                                "branchId": authUser.branchId
                                                                            }]
                                                                            setCheckValue(checkValue.concat(saveObj));
                                                                            //console.log(JSON.stringify(updatedData[index]));
                                                                        }} />
                                                                    </tr>
                                                                </React.Fragment>

                                                            )
                                                        })}
                                                </tbody>
                                            </table>

                                        </div>

                                        <div className='mt-4'>
                                            <button type='submit' className='studWiseFeeBtn btn btn-warning btn-sm mb-2' ><AddIcon fontSize="small" />Save Changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                    }


                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default StudentWiseFeeDemand