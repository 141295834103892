import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../../CommonComponent/Loader';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { getRegisterTypeDataAPIURL } from '../../LibraryServices/RegisterTypeMasterAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import { printWithdrawalEntryPdfReport, printWithdrawalEntryExcelReport } from '../Services/CirculationReportAPIURL';

const useStyles = makeStyles({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
});

const WithdrawalRegisterReport = () => {
    const titleId = "Withdrawal Regsiter";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);
   
    const [view, setView] = useState(1);
    const [registerTypeData, setRegisterTypeData] = useState([]);
    const [registerTypeObj, setRegisterTypeObj] = useState(null);
    const [allRegisterType, setAllRegisterType] = useState(0);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [option, setOption] = useState(0);
    const [accNoFrom, setAccNoFrom] = useState('');
    const [accNoTo, setAccNoTo] = useState('');
    const [withdrawalNoFrom, setWithdrawalNoFrom] = useState('');
    const [withdrawalNoTo, setWithdrawalNoTo] = useState('');
    const [withdrawalType, setWithdrawalType] = useState(0);

    const handleRegisterTypeCheckBox = (e) => {
        setAllRegisterType(e.target.checked);
        setRegisterTypeObj(null);
        if (e.target.checked == true) {
            setAllRegisterType(0);
        }
        else if (e.target.checked == false) {
            setAllRegisterType(1);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.branchId != null) {
            await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setRegisterTypeData(res.data);
                })
        }
    }

    const getPdfWithdrawalEntryReport = async () => {
        setLoaderOption(true);
        if (((dateFrom == null) ? moment(new Date()).format("DD/MM/YYYY") : moment(dateFrom).format("DD/MM/YYYY") != "Invalid date") && ((dateTo == null) ? moment(new Date()).format("DD/MM/YYYY") : moment(dateTo).format("DD/MM/YYYY") != "Invalid date")) {
            let noFrom = 0;
            let noTo = 0;
            if (option == 2 && withdrawalNoFrom != '' && withdrawalNoTo != '') {
                noFrom = withdrawalNoFrom;
                noTo = withdrawalNoTo;
            }
            else if (option == 3 && accNoFrom != '' && accNoTo != '') {
                noFrom = accNoFrom;
                noTo = accNoTo;
            }
            else {
                noFrom = 0;
                noTo = 0;
            }

            console.log(`${printWithdrawalEntryPdfReport}?langId=${authUser.branchMedium}&regType=${(allRegisterType == 0) ? allRegisterType : registerTypeObj.id}&option=${option}&withdrlType=${withdrawalType}&dateFrom=${(option == 1) ? moment(dateFrom).format("YYYY-MM-DD") : null}&dateTo=${(option == 1) ? moment(dateTo).format("YYYY-MM-DD") : null}&noFrom=${noFrom}&noTo=${noTo}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)
            await axios.get(`${printWithdrawalEntryPdfReport}?langId=${authUser.branchMedium}&regType=${(allRegisterType == 0) ? allRegisterType : registerTypeObj.id}&option=${option}&withdrlType=${withdrawalType}&dateFrom=${(option == 1) ? moment(dateFrom).format("YYYY-MM-DD") : null}&dateTo=${(option == 1) ? moment(dateTo).format("YYYY-MM-DD") : null}&noFrom=${noFrom}&noTo=${noTo}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Withdrawal entry report PDF" + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const getExcelWithdrawalEntryReport = async () => {
        setLoaderOption(true);
        if (((dateFrom == null) ? moment(new Date()).format("DD/MM/YYYY") : moment(dateFrom).format("DD/MM/YYYY") != "Invalid date") && ((dateTo == null) ? moment(new Date()).format("DD/MM/YYYY") : moment(dateTo).format("DD/MM/YYYY") != "Invalid date")) {
            let noFrom = 0;
            let noTo = 0;
            if (option == 2 && withdrawalNoFrom != '' && withdrawalNoTo != '') {
                noFrom = withdrawalNoFrom;
                noTo = withdrawalNoTo;
            }
            else if (option == 3 && accNoFrom != '' && accNoTo != '') {
                noFrom = accNoFrom;
                noTo = accNoTo;
            }
            else {
                noFrom = 0;
                noTo = 0;
            }

            console.log(`${printWithdrawalEntryExcelReport}?langId=${authUser.branchMedium}&regType=${(allRegisterType == 0) ? allRegisterType : registerTypeObj.id}&option=${option}&withdrlType=${withdrawalType}&dateFrom=${(option == 1) ? moment(dateFrom).format("YYYY-MM-DD") : null}&dateTo=${(option == 1) ? moment(dateTo).format("YYYY-MM-DD") : null}&noFrom=${noFrom}&noTo=${noTo}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)
            await axios.get(`${printWithdrawalEntryExcelReport}?langId=${authUser.branchMedium}&regType=${(allRegisterType == 0) ? allRegisterType : registerTypeObj.id}&option=${option}&withdrlType=${withdrawalType}&dateFrom=${(option == 1) ? moment(dateFrom).format("YYYY-MM-DD") : null}&dateTo=${(option == 1) ? moment(dateTo).format("YYYY-MM-DD") : null}&noFrom=${noFrom}&noTo=${noTo}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Withdrawal entry report Excel" + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }


    const getWithdrawalRegisterReport = async (e) => {
        e.preventDefault();
        if (view == 1) {
            getPdfWithdrawalEntryReport();
        }
        else if (view == 2) {
            getExcelWithdrawalEntryReport();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                            <form onSubmit={getWithdrawalRegisterReport}>
                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View Type :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>View Type</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={view}
                                                label="View Type"
                                                onChange={(e) => {
                                                    setView(e.target.value);
                                                    setRegisterTypeObj(null);
                                                    setAllRegisterType(0);
                                                    setDateFrom(null);
                                                    setDateTo(null);
                                                    setOption(0);
                                                    setAccNoFrom('');
                                                    setAccNoTo('');
                                                    setWithdrawalNoFrom('');
                                                    setWithdrawalNoTo('');
                                                    setWithdrawalType(0);
                                                }}
                                            >
                                                <MenuItem value={1}>PDF</MenuItem>
                                                <MenuItem value={2}>Excel</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Register Type :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            disabled={(allRegisterType == 0) ? true : false}
                                            options={registerTypeData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.registerTypeName}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setRegisterTypeObj(newValue);
                                                setDateFrom(null);
                                                setDateTo(null);
                                                setOption(0);
                                                setAccNoFrom('');
                                                setAccNoTo('');
                                                setWithdrawalNoFrom('');
                                                setWithdrawalNoTo('');
                                                setWithdrawalType(0);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? registerTypeObj : ""}
                                            value={registerTypeObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Register Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required={((allRegisterType == 1) ? true : false)} />
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-2 mt-2">
                                        <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={(allRegisterType == 0) ? true : false} onChange={handleRegisterTypeCheckBox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "16px", marginTop: "2px" } }} label="All" />
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={option}
                                                label="Option"
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setDateFrom(null);
                                                    setDateTo(null);
                                                    setAccNoFrom('');
                                                    setAccNoTo('');
                                                    setWithdrawalNoFrom('');
                                                    setWithdrawalNoTo('');
                                                    setWithdrawalType(0);
                                                }}
                                            >
                                                <MenuItem value={0}>All</MenuItem>
                                                <MenuItem value={1}>Date Wise</MenuItem>
                                                <MenuItem value={2}>Withdrwal No Wise</MenuItem>
                                                <MenuItem value={3}>Accsession No Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {
                                    (() => {
                                        if (option == 1) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Date From :</label>
                                                        <div className='col-sm-3'>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="From"
                                                                    value={dateFrom}
                                                                    onChange={(newValue) => {
                                                                        setDateFrom(newValue);
                                                                    }}
                                                                    onKeyPress={(e) => (e.key == "Enter") ? dateFrom : ""}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            margin='dense'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required={(option == 1) ? true : false}
                                                                            fullWidth
                                                                            autoComplete='off'
                                                                            size="small" />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>

                                                        <label className="" style={{ width: "60px", fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >To :</label>
                                                        <div className='col-sm-3'>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="To"
                                                                    value={dateTo}
                                                                    onChange={(newValue) => {
                                                                        setDateTo(newValue);
                                                                    }}
                                                                    onKeyPress={(e) => (e.key == "Enter") ? dateTo : ""}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            margin='dense'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required={(option == 1) ? true : false}
                                                                            fullWidth
                                                                            autoComplete='off'
                                                                            size="small" />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>WD No. From :</label>
                                                        <div className="col-sm-2">
                                                            <TextField
                                                                fullWidth
                                                                onWheel={(e) => e.target.blur()}
                                                                type="number"
                                                                onKeyDown={(e) => (e.key === " " && withdrawalNoFrom.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? withdrawalNoFrom : ""}
                                                                label="From"
                                                                value={withdrawalNoFrom}
                                                                onChange={(e) => setWithdrawalNoFrom(e.target.value)}
                                                            />
                                                        </div>

                                                        <label className='' style={{ width: "60px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                                        <div className="col-sm-2">
                                                            <TextField
                                                                fullWidth
                                                                onWheel={(e) => e.target.blur()}
                                                                type="number"
                                                                onKeyDown={(e) => (e.key === " " && withdrawalNoTo.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? withdrawalNoTo : ""}
                                                                label="To"
                                                                value={withdrawalNoTo}
                                                                onChange={(e) => setWithdrawalNoTo(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 3) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Acc No. From :</label>
                                                        <div className="col-sm-2">
                                                            <TextField
                                                                fullWidth
                                                                onWheel={(e) => e.target.blur()}
                                                                type="number"
                                                                onKeyDown={(e) => (e.key === " " && accNoFrom.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? accNoFrom : ""}
                                                                label="From"
                                                                value={accNoFrom}
                                                                onChange={(e) => setAccNoFrom(e.target.value)}
                                                            />
                                                        </div>

                                                        <label className='' style={{ width: "60px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                                        <div className="col-sm-2">
                                                            <TextField
                                                                fullWidth
                                                                onWheel={(e) => e.target.blur()}
                                                                type="number"
                                                                onKeyDown={(e) => (e.key === " " && accNoTo.length === 0) ? e.preventDefault() : ""}
                                                                autoComplete='off'
                                                                margin='dense'
                                                                size='small'
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? accNoTo : ""}
                                                                label="To"
                                                                value={accNoTo}
                                                                onChange={(e) => setAccNoTo(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()
                                }

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Withdrawal Type :</label>
                                    <div className="col-sm-3">
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Withdrawal Type</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Withdrawal Type"
                                                value={withdrawalType}
                                                onChange={(e) => {
                                                    setWithdrawalType(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={0}>All</MenuItem>
                                                <MenuItem value={1}>Missing</MenuItem>
                                                <MenuItem value={2}>Damage</MenuItem>
                                                <MenuItem value={3}>Cash Deposite</MenuItem>
                                                <MenuItem value={4}>Write Off</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

            }
        </>
    )
}

export default WithdrawalRegisterReport