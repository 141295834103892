import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../../CommonComponent/Loader';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import { getEndowmentInterestReportAPIURL, getEndowmentInterestPrizeCodeWiseReport } from '../Services/EndowmentReportAPIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const EndowmentInterestReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Endowment Interest Report";

    const [loaderOption, setLoaderOption] = useState(false);
    const [optionId, setOptionId] = useState(1);
    const [prizeCode, setPrizeCode] = useState('');
    const [year, setYear] = useState(null);

    const allEndowmentInterestReport = async () => {
        setLoaderOption(true);
        //console.log(`${generateSubjectAllocationReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${getEndowmentInterestReportAPIURL}?sessionYear=${moment(year).format("YYYY")}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Endowment Report failed: " + error)
            })
    }

    const endowmentInterestWithPrizeCodeReport = async () => {
        setLoaderOption(true);
        //console.log(`${generateSubjectAllocationReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${getEndowmentInterestPrizeCodeWiseReport}?sessionYear=${moment(year).format("YYYY")}&prizeCode=${prizeCode}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found with this prize code.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Endowment Report failed: " + error)
            })
    }


    const printEndowmentReport = () => {
        if (optionId == 1) {
            allEndowmentInterestReport();
        }
        else if (optionId == 2) {
            endowmentInterestWithPrizeCodeReport();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <form onSubmit={printEndowmentReport}>
                            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Report Option:</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth >
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={optionId}
                                                margin='dense'
                                                label="Select Report Option"
                                                onChange={(e) => {
                                                    setOptionId(e.target.value);
                                                }}
                                            >

                                                <MenuItem value={1}>All</MenuItem>
                                                <MenuItem value={2}>Prize Code Wise</MenuItem>

                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Year :</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Year"
                                                value={year}
                                                inputFormat="yyyy"
                                                views={['year']}
                                                onChange={(newValue) => {
                                                    setYear(newValue);
                                                    // (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        margin='dense'
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        style={{
                                                            caretColor: "transparent",
                                                        }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                {(() => {
                                    if (optionId == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Prize Code:</label>
                                                    <div className='col-sm-3'>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            margin='dense'
                                                            onChange={e => setPrizeCode(e.target.value)}
                                                            onWheel={(e) => e.target.blur()}
                                                            type="number"
                                                            autoComplete='off'
                                                            size='small'
                                                            id="outlined-basic"
                                                            label="PrizeCode"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            value={prizeCode}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="col-sm-12 mt-3">
                                    <div className="offset-sm-2">
                                        <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default EndowmentInterestReport