import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

import TextFieldsIcon from '@mui/icons-material/TextFields';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

//table
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';




//APIURL

import {
    getUserRolesAPIURL,
    BaseURLInstituteMaster,
    sansthaMessageAPIURL,
    sansthaMessageWithoutFileAPIURL,
    getSansthaNoticeAllDataAPIURL,
    updateSansthaNoticeStatusAPIURL,
    deleteSansthaNoticeAPIURL,
    updateSansthaNoticeDataWithoutFileAPIURL,
    updateSansthaNoticeDataAPIURL
} from '../Services/SansthaAPIURL';

import { format } from 'date-fns';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const SansthaNotice = () => {
    const titleId = "Sanstha Notice";
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [switchChecked, setSwitchChecked] = useState();
    const [deleteId, setDeleteId] = useState("");

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    //Table Start

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Notice Title`, key: 'noticeTitle' },
        { label: `Date`, key: 'date' },
        { label: `Priority`, key: 'priority' },
        { label: 'Type', value: 'type', isAction: true },
        { label: 'Status', value: 'status', isAction: true },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.noticeSubject.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.noticeDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    //Table End

    const [userData, setUserData] = useState(null);
    const [branchData, setBranchData] = useState(null);

    const [id, setId] = useState(null);
    const [dateValue, setDateValue] = useState(null);
    const [userObj, setUserObj] = useState(null);
    const [branchObj, setBrancjObj] = useState(null);
    const [noticeTitle, setNoticeTitle] = useState("");
    const [option, setOption] = useState(1);
    const [file, setFile] = useState(null);
    const [desce, setDesce] = useState("");

    const [priority, setPriority] = useState(3)

    const getUserData = async () => {
        setLoaderOption(true);
        await axios.get(getUserRolesAPIURL)
            .then((response) => {
                if (response.data !== null) {
                    let newData = response.data;
                    let updatedData = [{ "id": 0, "name": "All" }, ...newData]
                    setUserData(updatedData);
                    setLoaderOption(false);
                    //console.log(JSON.stringify(updatedData))
                }
            })
            .catch((error) => {
                console.log(error);
                setLoaderOption(false);
            })
    }

    const getBranchData = async () => {
        setLoaderOption(true);
        await axios.get(BaseURLInstituteMaster)
            .then((response) => {
                if (response.data !== null) {

                    let newData = response.data;
                    let updatedData = [{ "id": 0, "schoolNameEn": "All", "schoolNameMr": "All" }, ...newData]
                    setBranchData(updatedData);
                    setLoaderOption(false);
                    //console.log(JSON.stringify(updatedData))
                }
            })
            .catch((error) => { console.log(error); setLoaderOption(false); })
    }

    useEffect(() => {
        getUserData();
        getBranchData();
        getSansthaNoticeAllDataFun();
    }, [authUser])

    const updateStatus = async (id, statusId) => {
        await axios.put(`${updateSansthaNoticeStatusAPIURL}?id=${id}&statusId=${statusId}`)
            .then((res) => {
                if (res.data === "SAVED") {
                    getSansthaNoticeAllDataFun();
                    toast.success("Status Updated")
                }
                else {
                    toast.success("Something went wrong.")
                }
            })
            .catch((err) => {
                console.log(err)
                toast.success("Something went wrong.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveFun();
        }
        if (state.button === 2) {
            updateFun();
        }
    };

    const saveFun = async () => {
        setLoaderOption(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("noticeDate", moment(dateValue).format("DD/MM/YYYY"))
        formData.append("userId", userObj.id)
        formData.append("branchId", branchObj.id)
        formData.append("noticeSubject", noticeTitle)
        formData.append("option", option)
        formData.append("noticePriority", priority)

        const sansthaNotice = {
            "noticeDate": moment(dateValue).format("DD/MM/YYYY"),
            "userId": userObj.id,
            "branchId": branchObj.id,
            "noticeSubject": noticeTitle,
            "option": option,
            "description": desce,
            "noticePriority": priority
        }
        // formData.append("sansthaNotice", sansthaNotice)
        //http://192.168.1.102:8080/institute/sss/sanstha/notice/uploadNotice
        //sansthaMessageWithoutFileAPIURL
        //sansthaMessageAPIURL

        if (option === 1) {
            await axios.post(sansthaMessageWithoutFileAPIURL, sansthaNotice)
                .then((res) => {
                    if (res.data === "SAVED") {
                        getSansthaNoticeAllDataFun();
                        clearForm();
                        setLoaderOption(false);
                        toast.success("Sucessfully saved")
                    }
                    else if (res.data === "File not saved") {
                        getSansthaNoticeAllDataFun();
                        setLoaderOption(false);
                        toast.error("Something went wrong.")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else if (option === 2) {
            await axios.post(sansthaMessageAPIURL, formData)
                .then((res) => {
                    if (res.data === "SAVED") {
                        clearForm();
                        getSansthaNoticeAllDataFun();
                        setLoaderOption(false);
                        toast.success("Sucessfully saved")
                    }
                    else if (res.data === "File not saved") {
                        getSansthaNoticeAllDataFun();
                        setLoaderOption(false);
                        toast.error("Something went wrong.")
                    }
                })
                .catch((err) => {
                    setLoaderOption(false);
                    console.log(err)
                    toast.error("Something went wrong.")
                })
        }

    }

    const updateFun = async () => {
        setLoaderOption(true);
        const formData = new FormData();
        formData.append("id", id);
        formData.append("file", file);
        formData.append("noticeDate", moment(dateValue).format("DD/MM/YYYY"))
        formData.append("userId", userObj.id)
        formData.append("branchId", branchObj.id)
        formData.append("noticeSubject", noticeTitle)
        formData.append("option", option)
        formData.append("noticePriority", priority)

        console.log(Object.fromEntries(formData))
        const sansthaNotice = {
            "id": id,
            "noticeDate": moment(dateValue).format("DD/MM/YYYY"),
            "userId": userObj.id,
            "branchId": branchObj.id,
            "noticeSubject": noticeTitle,
            "option": option,
            "description": desce,
            "noticePriority": priority
        }

        if (option === 1) {
            await axios.put(updateSansthaNoticeDataWithoutFileAPIURL, sansthaNotice)
                .then((res) => {
                    if (res.data === "SAVED") {
                        getSansthaNoticeAllDataFun();
                        clearForm();
                        setLoaderOption(false);
                        toast.success("Sucessfully Updated")
                    }
                    else if (res.data === "File not Updated") {
                        getSansthaNoticeAllDataFun();
                        setLoaderOption(false);
                        toast.error("Something went wrong.")
                    }
                })
                .catch((err) => {
                    setLoaderOption(false);
                    console.log(err)
                    toast.error("Something went wrong.")
                })
        }
        else if (option === 2) {
            await axios.put(updateSansthaNoticeDataAPIURL, formData)
                .then((res) => {
                    if (res.data === "SAVED") {
                        clearForm();
                        getSansthaNoticeAllDataFun();
                        setLoaderOption(false);
                        toast.success("Sucessfully Updated")
                    }
                    else if (res.data === "File not Updated") {
                        getSansthaNoticeAllDataFun();
                        setLoaderOption(false);
                        toast.error("Something went wrong.")
                    }
                })
                .catch((err) => {
                    setLoaderOption(false);
                    console.log(err)
                    toast.error("Something went wrong.")
                })
        }

    }

    const clearForm = () => {
        setDateValue(null);
        setBrancjObj(null);
        setUserObj(null);
        setNoticeTitle("");
        setOption(1);
        setDesce("");
        setFile(null);
        setPriority(3)
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        setId(null);
    }

    const getSansthaNoticeAllDataFun = async () => {
        setLoaderOption(true);
        await axios.get(getSansthaNoticeAllDataAPIURL)
            .then((res) => {
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    setFilteredData([]);
                    //console.log("all records:" + JSON.stringify(res.data))
                }
                else {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                    //console.log("all records:" + JSON.stringify(res.data))
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                console.log(err)
            })
    }

    const DeleteData = async (id) => {
        setLoaderOption(true);
        await axios.delete(`${deleteSansthaNoticeAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data === "DELETED") {
                    setLoaderOption(false);
                    getSansthaNoticeAllDataFun();
                    toast.error("Deleted sucessfully");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Something went wrong");
                console.log(err)
            })

    }

    return (
        <>

            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data mt-1">

                                    <div className="row">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date:</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date"
                                                    value={dateValue}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateValue(newValue);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label className="col-sm-2 mt-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px", textAlign: "right" }} >Branch:</label>
                                        <div className='col-sm-6'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={branchData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setBrancjObj(newValue);
                                                }}
                                                value={branchObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Branch" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>



                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >User:</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={userData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setUserObj(newValue);
                                                }}
                                                value={userObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.name}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select User" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>

                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", textAlign: "right" }} >Notice Title: </label>
                                        <div className='col-sm-6'>
                                            <TextField
                                                fullWidth
                                                required
                                                value={noticeTitle}
                                                onChange={(e) => setNoticeTitle(e.target.value)}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Notice Title"
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Priority:</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth  margin="dense">
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Select Priority:</InputLabel>
                                                <Select
                                                    size='small'
                                                    fullWidth                                                   
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={priority}
                                                    label="Applicable"
                                                    onChange={(e) => setPriority(e.target.value)}
                                                >
                                                    <MenuItem value={1}>High Priority</MenuItem>
                                                    <MenuItem value={2}>Medium Priority</MenuItem>
                                                    <MenuItem value={3}>Low Priority</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px", textAlign: "right" }} >Option:</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth  margin="dense">
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Select Option</InputLabel>
                                                <Select
                                                    size='small'
                                                    fullWidth                                                   
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Applicable"
                                                    onChange={(e) => setOption(e.target.value)}
                                                >
                                                    <MenuItem value={1}>Text Details</MenuItem>
                                                    <MenuItem value={2}>Document</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2">

                                        {(() => {
                                            if (option == 1) {
                                                return (
                                                    <>
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Details:</label>
                                                        <div className='col-sm-11'>
                                                            <TextField
                                                                fullWidth
                                                                value={desce}
                                                                onChange={(e) => setDesce(e.target.value)}
                                                                multiline
                                                                rows={4}
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Details"
                                                                variant="outlined"
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                            />
                                                        </div>

                                                    </>
                                                )
                                            }
                                            else if (option == 2) {
                                                return (
                                                    <>
                                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Document:</label>
                                                        <div className='col-sm-3 mt-2'>
                                                            <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])} className='form-control form-control-sm' accept='.pdf' required />
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-12 offset-sm-1">
                                            <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                            <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                            <button type="button" onClick={() => { clearForm() }} className="btn btn-danger btn-sm mx-2">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <hr />
                            <div className='mt-4'>
                                <div className='row mt-1'>
                                    <div>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            value={searchTerm}
                                            onChange={(event) => { setSearchTerm(event.target.value); }}
                                        />
                                    </div>
                                </div>

                                <div className='table-responsive' >
                                    <table className="table table-bordered">
                                        <thead className="table-Default">
                                            <tr>
                                                {TableHeading.map((item, index) => {
                                                    return (
                                                        <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.noticeSubject}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.noticeDate}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                    {
                                                                        (() => {
                                                                            if (item.noticePriority === 1) {
                                                                                return <span>High</span>
                                                                            }
                                                                            else if (item.noticePriority === 2) {
                                                                                return <span>Medium</span>
                                                                            }
                                                                            else if (item.noticePriority === 3) {
                                                                                return <span>Low</span>
                                                                            }
                                                                        })()
                                                                    }
                                                                </td>

                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                    {
                                                                        (() => {
                                                                            if (item.fileOption === 1) {
                                                                                return <TextFieldsIcon fontSize='small' />
                                                                            }
                                                                            else if (item.fileOption === 2) {
                                                                                return <PictureAsPdfIcon fontSize='small' style={{ color: '#CF323B' }} />
                                                                            }
                                                                        })()
                                                                    }
                                                                </td>

                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                    <Switch aria-label="Switch demo" color="secondary"
                                                                        onChange={() => {
                                                                            let val = item.noticeStatus;
                                                                            if (val === 1) {
                                                                                updateStatus(item.id, 0)
                                                                            }
                                                                            else if (val === 0) {
                                                                                updateStatus(item.id, 1)
                                                                            }
                                                                        }}
                                                                        checked={(item.noticeStatus === 1) ? true : false}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (item.fileOption === 1)
                                                                            ?
                                                                            <Tooltip title="Edit">
                                                                                <span className='btn btn-primary btn-sm mx-1 p-1' d
                                                                                    onClick={() => {
                                                                                        setSaveBtn(displayNoneBtnStyle);
                                                                                        setUpdateBtn(updateBtnStyle);
                                                                                        setSaveBtnDisabled(true);
                                                                                        setUpdateBtnDisabled(false);


                                                                                        setId(item.id);
                                                                                        setDateValue(item.noticeDate);
                                                                                        setBrancjObj(branchData.find((e) => e.id === item.branchId));
                                                                                        setUserObj(userData.find((e) => e.id === item.userId));
                                                                                        setNoticeTitle(item.noticeSubject);
                                                                                        setOption(item.fileOption);
                                                                                        setDesce(item.fileDescription);
                                                                                        setPriority(item.noticePriority);
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                </span>
                                                                            </Tooltip>
                                                                            : ""
                                                                    }

                                                                </td>

                                                                <td>
                                                                    <Tooltip title="Delete">
                                                                        <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                            onClick={() => setDeleteId(item.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })}
                                        </tbody>
                                        <tfoot style={{ padding: "0px" }}>
                                            <tr>
                                                <TablePagination
                                                    style={{ padding: "0px" }}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    count={data.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onRowsPerPageChange={onRowsPerPageChange}
                                                    onPageChange={onPageChange}
                                                    labelRowsPerPage="Rows Per Page :"
                                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
                    </>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default SansthaNotice