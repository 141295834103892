import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel'
import { ToastContainer, toast } from 'react-toastify';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import moment from 'moment';
import PrintIcon from '@mui/icons-material/Print';
import { getBudgetDetailsForSansthaConsoInEng, printBudgetDetailsForSansthaConsoInEng } from './Services/BudgetReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';

const SansthaConsolidatedEnglishReport = () => {
    const titleId = "Sanstha Consolidated Report (English)"
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);
    const [schoolName, setSchoolName] = useState('');

    //states to get values
    const [endowmentReceipt, setEndowmentReceipt] = useState(0);
    const [sansthaReceipt, setSansthaReceipt] = useState(0);
    const [noTwoBReceipt, setNoTwoBReceipt] = useState(0);
    const [sansthaBuildingPayment, setSansthaBuildingPayment] = useState(0);
    const [noOneAPayment, setNoOneAPayment] = useState(0);
    const [endowmentPayment, setEndowmentPayment] = useState(0);
    const [sansthaLoanPayment, setSansthaLoanPayment] = useState(0);
    const [noOneBPayment, setNoOneBPayment] = useState(0);
    const [sansthaMaintenancePayment, setSanthaMaintenancePaymnet] = useState(0);
    const [miscellaneousPayment, setMiscellaneousPayment] = useState(0);
    const [objectOfTrust, setObjectOfTrust] = useState(0);

    //states to get total
    const [totalEndowmentReceipt, setTotalEndowmentReceipt] = useState(0);
    const [totalSantshaReceipt, setTotalSansthaReceipt] = useState(0);
    const [totalEndowmentPayment, setTotalEndowmentPayment] = useState(0);
    const [totalSansthaPayment, setTotalSansthaPayment] = useState(0);
    const [totalReceiptside, setTotalReceiptSide] = useState(0);
    const [totalPaymentSide, setTotalPaymentSide] = useState(0);
    const [totalDeficit, setTotalDeficit] = useState(0);
    const [totalSurplus, setTotalSurplus] = useState(0);

    //set annual budget period
    let futureYear = moment(new Date()).format(`YYYY`)

    //get institute data & budget details
    useEffect(() => {
        getData();
        getBudgetDetailsInEnglish();
    }, [authUser])

    //get institute data
    const getData = async () => {
        await axios.get(`${BaseURLInstituteMaster}`)
            .then(res => {
                res.data.map(e => {
                    if (e.id == authUser.branchId) {
                        setSchoolName(e.schoolNameEn);
                    }
                })
            })
    }

    // /get budget details
    const getBudgetDetailsInEnglish = async () => {
        setLoaderOption(true);
        await axios.get(`${getBudgetDetailsForSansthaConsoInEng}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data != null) {
                    setEndowmentReceipt(parseFloat(res.data.endowment_receipt));
                    setSansthaReceipt(parseFloat(res.data.sanstha_receipt));
                    setNoTwoBReceipt(parseFloat(res.data.no_two_b_receipt));
                    setSansthaBuildingPayment(parseFloat(res.data.sanstha_building_payment));
                    setNoOneAPayment(parseFloat(res.data.no_one_a_payment));
                    setEndowmentPayment(parseFloat(res.data.endowment_payment));
                    setSansthaLoanPayment(parseFloat(res.data.sanstha_loan_payment));
                    setNoOneBPayment(parseFloat(res.data.no_one_b_payment));
                    setSanthaMaintenancePaymnet(parseFloat(res.data.sanstha_maintenance_payment));
                    setMiscellaneousPayment(parseFloat(res.data.miscellaneous_payment));
                    setObjectOfTrust(parseFloat(res.data.object_of_trust));

                    setTotalEndowmentReceipt(parseFloat(res.data.endowment_receipt))

                    let totalSansthRec = parseFloat(res.data.sanstha_receipt) + parseFloat(res.data.no_two_b_receipt)
                    setTotalSansthaReceipt(parseFloat(totalSansthRec))

                    let totalEndowPay = parseFloat(res.data.sanstha_building_payment) + parseFloat(res.data.no_one_a_payment) + parseFloat(res.data.endowment_payment)
                    setTotalEndowmentPayment(parseFloat(totalEndowPay))

                    let totalSansthaPay = parseFloat(res.data.sanstha_loan_payment) + parseFloat(res.data.no_one_b_payment) + parseFloat(res.data.sanstha_maintenance_payment)
                    setTotalSansthaPayment(parseFloat(totalSansthaPay))

                    let totalRecSide = parseFloat(res.data.endowment_receipt) + parseFloat(totalSansthRec)
                    setTotalReceiptSide(parseFloat(totalRecSide));

                    let totalPaySide = parseFloat(totalEndowPay) + parseFloat(totalSansthaPay)
                        + parseFloat(res.data.miscellaneous_payment) + parseFloat(res.data.object_of_trust)
                    setTotalPaymentSide(parseFloat(totalPaySide))

                    let totalDeficitSurplus = parseFloat(totalPaySide) - parseFloat(totalSansthRec) - parseFloat(res.data.endowment_receipt)

                    // let totlaSur = parseFloat(totalRecSide) - parseFloat(totalEndowPay)
                    //     - parseFloat(totalSansthaPay) - parseFloat(res.data.miscellaneous_payment) -
                    //     parseFloat(res.data.object_of_trust)
                    // console.log(totalDeficitSurplus)
                    if (totalDeficitSurplus > 0) {
                        setTotalDeficit(parseFloat(totalDeficitSurplus))
                    }
                    else if (totalDeficitSurplus < 0) {
                        setTotalSurplus(parseFloat(-totalDeficitSurplus))
                    }
                    setLoaderOption(false);
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                }


            }).catch(err => {
                setLoaderOption(false);
                console.log("English Budget Report" + err);
            })
    }

    const printEnglishBudgetSansthaReport = async () => {
        setLoaderOption(true);
        await axios.get(`${printBudgetDetailsForSansthaConsoInEng}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.");
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                setLoaderOption(false);
                toast.error("Something went wrong, please check.");
                console.log('print english sanstha budget' + err);
            })

    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <div className=''>
                                <div className="mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <h3 className='mt-1' style={{ color: "#DC2C0C", justifyContent: "center" }}>SCHEDULE NO.VII A</h3>
                                </div>
                                <div style={{ float: 'left' }} className='mb-2'>
                                    <h6 className='mt-1' style={{ color: "#000", justifyContent: "center" }}>Name Of Trust :  <span style={{ fontSize: '14px', fontWeight: '500' }}> {schoolName}, Sangli</span></h6>
                                    <h6 className='mt-1' style={{ color: "#000", justifyContent: "center" }}>Annual budget for the period :  <span style={{ fontSize: '14px', fontWeight: '500' }}> {`1/4/${parseInt(authUser.sessionYear) + 1}`}  to   {`31/3/${parseInt(authUser.sessionYear) + 2}`}</span></h6>
                                    <h6 className='mt-1' style={{ color: "#000", justifyContent: "center" }}>Registration No. SS F - 4</h6>
                                </div>
                            </div>
                            <div style={{ float: "right" }} className='mt-5 mb-2'>
                                <button
                                    style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                    type='button'
                                    onClick={() => printEnglishBudgetSansthaReport()}
                                    className='btn btn-sm'
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>
                            </div>

                            <div className='table-responsive'>
                                <table className="table table-bordered border-dark">
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: 'center' }} colspan="4">ESTIMATED RECEIPTS</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: 'center' }}>Rs.</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: 'center' }}>Rs.</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: 'center' }} colspan="4">ESTIMATED DISBURSEMENTS</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: 'center' }}>Rs.</th>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: 'center' }}>Rs.</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-sm'>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>I</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="3">Opening Balance</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>I</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="3">Estimated Disbursements</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(i)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">Cash in hand</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>(a)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="2">Non-recurring</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(ii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">Cash at bank</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(i)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>
                                                Major repairs and rebuilding of the Assets, suchcascbuilding,
                                                wells,canals,first manuring of agriculture lands etc.
                                            </td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(sansthaBuildingPayment).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="3"></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(ii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>
                                                New purchase of immovable properties scripts for investments.
                                                Valuable and other moveables etc.
                                            </td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(noOneAPayment).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>II</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="3">Estimated Receipts</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(iii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>
                                                Fixed Deposits with Banks and other comapnies
                                            </td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(endowmentPayment).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(totalEndowmentPayment).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>(a)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="2">Non-recurring</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(i)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Donation to be received towards Corpus or for capital objects</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(endowmentReceipt).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>(b)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="2">Recurring</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(ii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Ordinary Donations to be received for specific or earmarked objects</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(i)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>
                                                Rents,Rates,Taxes and Insurance
                                            </td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(sansthaLoanPayment).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(iii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Ordinary Donations</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(totalEndowmentReceipt).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(ii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Administrative Expenses</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(iii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>
                                                Payment of salaries and perquisties to the staff
                                            </td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(noOneBPayment).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>(b)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="2">Recurring</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(iv)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Transfer to depreciation fund</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(i)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Rents, lease rents on immovable property.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(sansthaReceipt).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(v)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Special and Current Repairs to building, furniture and other assets.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(sansthaMaintenancePayment).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(totalSansthaPayment).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(ii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Interest debentures,securities,deposits, etc.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>II</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="3">
                                                Miscellneous expenses not covered by the items above.
                                            </td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(miscellaneousPayment).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(iii)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Dividends on shares, etc.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>III</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="3">
                                                Expensed on the object of the trust
                                            </td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(objectOfTrust).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(iv)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Income from agriculture lands.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>IV</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="3">Surplus of receipts over expenditure</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(v)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Other revenue receipts</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(noTwoBReceipt).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(totalSantshaReceipt).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(1)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">To be retained in cash or bank</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(2)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">To be transferred to reserve fund</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>III</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textDecoration: 'underline' }} colspan="3">Realisation from disposal of assets,repayment of deposits etc.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(3)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">To be added to corpus under the instrument of trust</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(a)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">Sale of share, securities, loans etc.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(4)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">Closing Balance</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(b)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">Repayment of deposits,securities,loans etc.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(1)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Cash in hand Rs.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(c)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">Deisposal of assets</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(2)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>Cash at Bank Rs.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}>(d)</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="2">Others</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>0.00</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>IV</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }} colspan="3">Deficit for the year</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(totalDeficit).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }}>V</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600" }} colspan="3">Surplus for the year</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500", textAlign: 'right' }}>{(totalSurplus).toFixed('2')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="3"></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textAlign: 'right' }}>TOTAL RS.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textAlign: 'right' }}>{parseFloat(parseFloat(totalDeficit) + parseFloat(totalReceiptside)).toFixed('2')}</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }}></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "500" }} colspan="3"></td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textAlign: 'right' }}>TOTAL RS.</td>
                                            <td style={{ fontSize: "13px", lineHeight: '1.5', fontWeight: "600", textAlign: 'right' }}>{parseFloat(parseFloat(totalSurplus) + parseFloat(totalPaymentSide)).toFixed('2')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default SansthaConsolidatedEnglishReport