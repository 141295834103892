import React, { useState } from 'react'

const ParentPhoneNoAssignTextBox = ({ initialText1, initialText2, onChange }) => {
    const [isEdit1, setEdit1] = useState(false);
    const [isEdit2, setEdit2] = useState(false);
    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);

    // if (isEdit1==true) {
    return (
        <>
            <td style={{ width: "210px", height: "70px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm' 
                    //style={{ width: "170px" }}
                    style={(isEdit1 === true) ? { width: "170px", fontSize: "14px", } : { width: "170px", fontSize: "14px", border: "0px" }}
                    onFocus={() => setEdit1(true)}
                    onBlur={() => { setEdit1(false); onChange(text1, text2) }}
                    onKeyPress={(e) => (e.key == "Enter") ?  onChange(text1, text2) : ""}
                    onChange={(e) => setText1(e.target.value)}
                    value={text1}
                />
            </td>
            {/* <td style={{ width: "210px", height: "70px" }} onClick={() => setEdit2(true)}>{text2}</td> */}
            {/* </>
        )
    }
    else if (isEdit2==true) {
        return (
            <> */}
            {/* <td style={{ width: "210px", height: "70px" }} onClick={() => setEdit1(true)}>{text1}</td> */}
            <td style={{ width: "210px", height: "70px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm' 
                    //style={{ width: "170px" }}
                    style={(isEdit2 === true) ? { width: "170px", fontSize: "14px", } : { width: "170px", fontSize: "14px", border: "0px" }}
                    onFocus={() => setEdit2(true)}
                    onBlur={() => { setEdit2(false); onChange(text1, text2) }}
                    onKeyPress={(e) => (e.key == "Enter") ?  onChange(text1, text2) : ""}
                    onChange={(e) => setText2(e.target.value)}
                    value={text2}
                />
            </td>
            {/* </>
        )
    }
    else if(isEdit2==false && isEdit1 ==false){
        return(
            <>
                 <td style={{ width: "210px", height: "70px" }} onClick={() => setEdit1(true)}>{text1}</td>
                 <td style={{ width: "210px", height: "70px" }} onClick={() => setEdit2(true)}>{text2}</td>
            </>
        )
    } */}
        </>
    )
}

export default ParentPhoneNoAssignTextBox

// isEdit1 ?

        //     <>
        //         <td style={{ width: "210px", height: "70px" }}>
        //             <input
        //                 type='number'
        //                 className='form-control form-control-sm' style={{ width: "170px" }}
        //                 onFocus={() => setEdit1(true)}
        //                 onBlur={() => { setEdit1(false); onChange(text1, text2) }}

        //                 onChange={(e) => setText1(e.target.value)}
        //                 value={text1}
        //             />
        //         </td>
        //         <td style={{ width: "210px", height: "70px" }}>
        //             <input
        //                 type='number'
        //                 className='form-control form-control-sm' style={{ width: "170px" }}
        //                 onFocus={() => setEdit2(true)}
        //                 onBlur={() => { setEdit2(false); onChange(text1, text2) }}

        //                 onChange={(e) => setText2(e.target.value)}
        //                 value={text2}
        //             />
        //         </td>
        //     </>
        //     :
        //     <>
        //         <td style={{ width: "210px", height: "70px" }} onClick={() => setEdit1(true)}>{text1}</td>
        //         <td style={{ width: "210px", height: "70px" }} onClick={() => setEdit2(true)}>{text2}</td>
        //     </>