import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

// /institute/sss/academics/subjectTeacherAllocation/

// GetMapping   -  /getSubjectList
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId
// ------------------------------------
// GetMapping     /getClassAndDivisionList
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("subid") long subjectId, @RequestParam("empid") long employeeId

export const classWithDiv = BaseURL + "academics/subjectTeacherAllocation/getClassAndDivisionList";
export const getSubjectData = BaseURL + "academics/subjectTeacherAllocation/getSubjectList";
export const getClassDivisionURL = BaseURLMaster + "classWithDivisionBySectionId/";

// @PostMapping("/saveSubjectTeacherAllocation")
// RequestBody object list
export const saveSubjectTeacherAllocation = BaseURL + "academics/subjectTeacherAllocation/saveSubjectTeacherAllocation";

//localhost:8080/institute/sss/academic/reports/generateAllClassAndDivisionAndTeacherAndSubjectWiseReport?langId=1&acYear=2021&sid=3&bid=1
export const printAllClassDivSubjectTeacher = BaseURL + "academic/reports/generateAllClassAndDivisionAndTeacherAndSubjectWiseReport";

//localhost:8080/institute/sss/academic/reports/generateSubjectTeacherWiseReport?langId=1&acYear=2021&sid=3&bid=1&empId=7
export const printTeacherWise = BaseURL + "academic/reports/generateSubjectTeacherWiseReport";

//localhost:8080/institute/sss/academic/reports/generateSubjectWiseReport?langId=1&subId=1&acYear=2021&sid=3&bid=1
export const printSubjectWise = BaseURL + "academic/reports/generateSubjectWiseReport";

//localhost:8080/institute/sss/academic/reports/generateClassAndDivisionWiseReport?langId=1&acYear=2021&sid=3&bid=1&classId=6&divId=21
export const printClassDivWise = BaseURL + "academic/reports/generateClassAndDivisionWiseReport";
