import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from '../../CommonComponent/TitleLabel';
import ReceiptPaymentGroupMasterTable from './ReceiptPaymentGroupMasterTable';
import { getNegativeBalGroupAPIURL, getReceiptPatmentGroupAPIURL, saveReceiptPaymentGroupAPIURL, updateReceiptpaymentGroupAPIURL } from '../Services/ReceiptPaymentGroupAPIURL';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const ReceiptPaymentGroupMaster = () => {
    const titleId = 'Receipt Payment Group Form';
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);

    const navigate = useNavigate();
    let { rpId, EditFlag } = useParams();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "receiptPaymentGroupSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "receiptPaymentGroupUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: 'Group Name Marathi', key: 'nameMr' },
        { label: 'Group Name English', key: 'nameEn' },
        { label: 'Group Head', key: 'rcHeaderName' },
        { label: 'Negative Bal. Group', key: (authUser.branchMedium == 1) ? 'negativeBalGroupMr' : 'negativeBalGroupEn' },
        { label: 'Index', key: 'ind' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    const [id, setId] = useState('');
    const [groupHead, setGroupHead] = useState('');
    const [groupNameMr, setGroupNameMr] = useState('');
    const [groupNameEn, setGroupNameEn] = useState('');
    const [index, setIndex] = useState('');
    const [negativeBalGroupData, setNegativeBalGroupData] = useState([]);
    const [negativeBalGroupObj, setNegativeBalGroupObj] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${getReceiptPatmentGroupAPIURL}?sid=${authUser.deptId}`)
            .then(res => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);

                const selectedItem = res.data.find(e => e.id == rpId)

                setId(selectedItem.id);
                setGroupHead(selectedItem.rcHeader)
                setGroupNameMr(selectedItem.nameMr)
                setGroupNameEn(selectedItem.nameEn)
                setIndex(selectedItem.ind)


                axios.get(`${getNegativeBalGroupAPIURL}?rcHeader=${selectedItem.rcHeader}&sid=${authUser.deptId}`)
                    .then(res => {
                        setNegativeBalGroupData(res.data);
                        //console.log(res.data.map(e => e.id) + "======" + selectedItem.negativeBalGroup)
                        setNegativeBalGroupObj(res.data.find(e => e.id == selectedItem.negativeBalGroup))
                        setLoaderOption(false);
                    }).catch(err => {
                        console.log('get negative bal.' + err)
                        setLoaderOption(false);
                    })
            }).catch(err => {
                console.log('get Receipt payment group' + err)
                setLoaderOption(false);
            })
    }

    const getApiData = async () => {
        if (authUser.deptId != null) {
            await axios.get(`${getReceiptPatmentGroupAPIURL}?sid=${authUser.deptId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log('get Receipt payment group' + err)
                })
        }
    }

    const getNegativeBalGroupData = async (recPayid) => {
        await axios.get(`${getNegativeBalGroupAPIURL}?rcHeader=${recPayid}&sid=${authUser.deptId}`)
            .then(res => {
                setNegativeBalGroupData(res.data);
            }).catch(err => {
                console.log('get negative bal.' + err)
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setGroupHead('');
        setGroupNameMr('');
        setGroupNameEn('');
        setIndex('');
        setNegativeBalGroupData([]);
        setNegativeBalGroupObj(null);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        navigate('/Home/ac618/')
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObject = {
            "nameMr": groupNameMr,
            "nameEn": groupNameEn,
            "rcHeader": groupHead,
            "negativeBalGroup": (negativeBalGroupObj == null) ? null : negativeBalGroupObj.id,
            "index": index,
            "sectionId": authUser.deptId
        };
        console.log(JSON.stringify(saveObject))
        await axios.post(`${saveReceiptPaymentGroupAPIURL}`, saveObject)
            .then((response) => {
                console.log(response.data)
                if (response.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log('Receipt payment group save' + err);
                toast.error("Something went wrong, please check.")
            })
    };

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObject = {
            "id": id,
            "nameMr": groupNameMr,
            "nameEn": groupNameEn,
            "rcHeader": groupHead,
            "negativeBalGroup": (negativeBalGroupObj == null) ? null : negativeBalGroupObj.id,
            "index": index,
            "sectionId": authUser.deptId
        };
        console.log(JSON.stringify(updateObject));
        await axios.put(`${updateReceiptpaymentGroupAPIURL}`, updateObject)
            .then((response) => {
                if (response.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated sucessfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log('Receipt payment group update' + err);
                toast.error("Something went wrong, please check.")
            })
    };

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.receiptPaymentGroupSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.receiptPaymentGroupUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Name :</label>
                                        <div className='col-sm-4'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && groupNameMr.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Group Name Marathi"
                                                variant="outlined"
                                                margin='dense'
                                                value={groupNameMr}
                                                onChange={(e) => setGroupNameMr(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? groupNameMr : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>

                                        <div className='col-sm-4'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && groupNameEn.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Group Name English"
                                                variant="outlined"
                                                margin='dense'
                                                value={groupNameEn}
                                                onChange={(e) => setGroupNameEn(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? groupNameEn : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >RP Head :</label>
                                        <div className='col-sm-4'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>RP Head</InputLabel>
                                                <Select
                                                    size='small'
                                                    fullWidth
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    value={groupHead}
                                                    onChange={(e) => {
                                                        setGroupHead(e.target.value);
                                                        getNegativeBalGroupData(e.target.value);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? groupHead : ""}
                                                    label="RP Head"
                                                >
                                                    <MenuItem value={1}>Receipt</MenuItem>
                                                    <MenuItem value={2}>Payment</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Negative Bal. Group :</label>
                                        <div className='col-sm-4'>
                                            <Autocomplete
                                                fullWidth
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                id="combo-box-demo"
                                                options={negativeBalGroupData}
                                                onChange={(event, newValue) => {
                                                    setNegativeBalGroupObj(newValue)
                                                }}
                                                value={negativeBalGroupObj}
                                                onKeyPress={(e) => (e.key == "Enter") ? negativeBalGroupObj : ""}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Negative Bal. Group" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}

                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Index :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required
                                                fullWidth
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => (e.key === " " && index.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Index"
                                                variant="outlined"
                                                margin='dense'
                                                value={index}
                                                onChange={(e) => setIndex(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? index : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                            <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                            <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3">
                                {/* <ReceiptPaymentGroupMasterTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    filteredData={filteredData}
                                    setFilteredData={setFilteredData}
                                    setId={setId}
                                    getData={getData}
                                    setGroupHead={setGroupHead}
                                    setGroupNameMr={setGroupNameMr}
                                    setGroupNameEn={setGroupNameEn}
                                    setIndex={setIndex}
                                    negativeBalGroupData={negativeBalGroupData}
                                    setNegativeBalGroupObj={setNegativeBalGroupObj}
                                    setNegativeBalGroupData={setNegativeBalGroupData}
                                    updateBtnStyle={updateBtnStyle}
                                    displayNoneBtnStyle={displayNoneBtnStyle}
                                    setSaveBtn={setSaveBtn}
                                    setUpdateBtn={setUpdateBtn}
                                /> */}
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default ReceiptPaymentGroupMaster