import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const GetClassWithDivData = BaseURLMaster + "classWithDivisionBySectionId/";
export const GetSubjectData = BaseURLMaster + "Subjects/";

// localhost:8080/institute/sss/academics/monthlyLessons/saveMonthlyLessons
// {
//     "classId":5,
//     "divisionId":17,
//     "subjectId":1,
//     "lessonPlanId":7,
//     "lessonComplete":"lessonComplete lessonComplete lessonComplete",
//     "monthId":7,
//     "yearId":2022,
//     "sessionYear":2022,
//     "sectionId":3,
//     "branchId":1
// }
export const saveMonthlyLessonPlanAPIURL = BaseURL + "academics/monthlyLessons/saveMonthlyLessons";

//localhost:8080/institute/sss/academics/lessonPlan/getLessonPlanByClassIdAndDivisionIdAndSubjectId?sessionYear=2022&sid=3&bid=1&classId=5&divId=17&subId=1
export const getLessonsByClassAndDivAndSubject = BaseURL + "academics/lessonPlan/getLessonPlanByClassIdAndDivisionIdAndSubjectId";

//Get All Monthly Lesson (Table)
//localhost:8080/institute/sss/academics/monthlyLessons/getAllMonthlyLessons?sessionYear=2022&sid=3&bid=1
export const getMonthlyLessonsAPIURL = BaseURL + "academics/monthlyLessons/getAllMonthlyLessons";

// Update Monthly Lesson
// localhost:8080/institute/sss/academics/monthlyLessons/updateMonthlyLessons
// {
//     "id":2,
//     "classId":5,
//     "divisionId":17,
//     "subjectId":1,
//     "lessonPlanId":7,
//     "lessonComplete":"lessonComplete 12345 lessonComplete",
//     "monthId":7,
//     "yearId":2022,
//     "sessionYear":2022,
//     "sectionId":3,
//     "branchId":1
// }
export const updateMonthlyLessonPlanAPIURL = BaseURL + "academics/monthlyLessons/updateMonthlyLessons";

//localhost:8080/institute/sss/academics/monthlyLessons/deleteMonthlyLessons?id=2
export const deleteMonthlyLessonPlanAPIURL = BaseURL + "academics/monthlyLessons/deleteMonthlyLessons";


