import React, { useEffect, useState } from "react";
import { Navigate, useParams } from 'react-router-dom';
import axios from "axios";
import TitleLabel from "../../CommonComponent/TitleLabel";
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import libraryFormLabelName from "../LibraryServices/LibraryMaster2LabelFormArray";
import { useNavigate } from "react-router-dom";
import { BaseUrlLibraryMaster, dBaseUrlLibraryMaster } from '../../CommonServices/APIURL';

const LibraryMaster2LabelForm = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    let { formid, mId, EditFlag } = useParams();
    const [loaderOption, setLoaderOption] = useState(false);

    //console.log(mId);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "oneLabelLibraryFormSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "oneLabelLibraryFormUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);


    const [id, setId] = useState("");
    const [titleId, setTitleId] = useState("");
    const [name, setName] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [getMapping, setGetMapping] = useState("");
    const [postMapping, setPostMapping] = useState("");
    const [putMapping, setPutMapping] = useState("");
    const [deleteMapping, setDeleteMapping] = useState("");
    const [getMappingById, setGetMappingById] = useState("");

    const [saveBtnDisabled, setSaveBtnDisabeld] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [getLabelName, setGetLabelName] = useState(libraryFormLabelName)


    const mapData = () => {
        getLabelName.map((labels) => {
            setGetLabelName([]);
            if (labels.id == formid) {
                setTitleId(labels.title);
                setGetMapping(BaseUrlLibraryMaster + "" + labels.GetMapping);
                setPostMapping(BaseUrlLibraryMaster + "" + labels.PostMapping);
                setPutMapping(BaseUrlLibraryMaster + "" + labels.PutMapping);
                setDeleteMapping(BaseUrlLibraryMaster + "" + labels.DeleteMapping);
                setGetMappingById(BaseUrlLibraryMaster + "" + labels.GetmappingById);
            }
        });
    }

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtn(saveBtnStyle);
            setUpdateBtn(displayNoneBtnStyle);
            setSaveBtnDisabeld(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtn(displayNoneBtnStyle);
            setUpdateBtn(updateBtnStyle);
            setSaveBtnDisabeld(true);
            setUpdateBtnDisabled(false);
        }
    }

    useEffect(() => {
        mapData();
        //getData();
        if (getMapping != "") {
            getData();
        }

    }, [getMapping, postMapping, putMapping, deleteMapping, getMappingById, titleId, formid])

    const getApiData = async () => {
        if (authUser.branchId != null) {
            //setLoaderOption(true);
            await axios.get(`${getMapping}?branchId=${authUser.branchId}`)
                .then((response) => {
                    setData(response.data);
                    setFilteredData(response.data);
                    setLoaderOption(false);
                })
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${getMappingById}?id=${mId}`)
            .then((response) => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setName(response.data.name);
                setId(response.data.id);
                setLoaderOption(false);
            })
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "branchId": authUser.branchId,
            "name": name,
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(postMapping, saveObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`This ${titleId} is already exists.`)
                }
                else if (response.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "branchId": authUser.branchId,
            "name": name,
        }
        console.log(JSON.stringify(updateObj))
        await axios.put(putMapping, updateObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`This ${titleId} is already exists.`)
                }
                else if (response.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const clearData = () => {
        setName("");
        navigate(`/Home/lm001/${formid}`)
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.oneLabelLibraryFormSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabeld(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.oneLabelLibraryFormUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabeld(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    return (
        <>
            <div>
                <TitleLabel titleId={`${titleId} Master`} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>

                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                        <div className="row my-2">
                            <label className={(formid == 905) ? "col-sm-3" : "col-sm-2"} style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>{titleId} Name :</label>
                            <div className="col-sm-4">
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && name.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    className=''
                                    size='small'
                                    id="outlined-basic"
                                    label={titleId + " " + "Name"}
                                    variant="outlined"
                                    margin='dense'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? name : ""}
                                    inputProps={{ maxLength: 120 }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className={(formid == 905) ? "col-sm-4 offset-sm-3" : "col-sm-4 offset-sm-2"}>
                                <button type='submit' disabled={saveBtnDisabled} className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                                <button type='submit' disabled={updateBtnDisabled} className={updateBtn} onClick={() => (state.button = 2)} style={{ margin: "0px 4px" }}>Update</button>
                                <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger' style={{ margin: "0px 4px" }}>Cancel</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div >
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default LibraryMaster2LabelForm