import { BaseURLMaster } from "../../CommonServices/APIURL"


// localhost:8081/institute-sss/institute/sss/master/saveExternalExam
export const saveExternalExamAPIURL = BaseURLMaster + "saveExternalExam";

//localhost:8081/institute-sss/institute/sss/master/getExternalExamList
export const getExternalExamAPIURL = BaseURLMaster + "getExternalExamList";

//localhost:8081/institute-sss/institute/sss/master/updateExternalExam
export const updateExternalExamAPIURL = BaseURLMaster + "updateExternalExam";

//localhost:8081/institute-sss/institute/sss/master/deleteExternalExam?id=3
export const deleteExternalExamAPIURL = BaseURLMaster + "deleteExternalExam";