import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../../CommonServices/APIURL";

//EndowmentList APIURL
//localhost:8080/institute/sss/endowment/reports/getEndowmentDataReport
export const printEndowmentReportAPIURL = BaseURL + "endowment/reports/getEndowmentDataReport";

//Endowment Transaction Report APIURL
//localhost:8080/institute/sss/endowment/reports/getEndowmentDataDetailReport
export const printEndowmentWithConditionReport = BaseURL + "endowment/reports/getEndowmentDataDetailReport";

//localhost:8080/institute/sss/endowment/reports/getEndowmentDataDetailReportByPrizeCode?prizeCode=5
export const printEndowmentByPrizeCodeAPIURL = BaseURL + "endowment/reports/getEndowmentDataDetailReportByPrizeCode";

//Endowment Interest report APIURL
//localhost:8080/institute/sss/endowment/reports/getPrizeDistributionSummaryYearWise?sessionYear=2022
export const getEndowmentInterestReportAPIURL = BaseURL + "endowment/reports/getPrizeDistributionSummaryYearWise";

//localhost:8080/institute/sss/endowment/reports/getPrizeDistributionSummaryYearAndPrizeCodeWise?sessionYear=2022&prizeCode=2
export const getEndowmentInterestPrizeCodeWiseReport = BaseURL + "endowment/reports/getPrizeDistributionSummaryYearAndPrizeCodeWise";

//Sanstha award blank APIURL
//localhost:8080/institute/sss/endowment/reports/getEndowmentPrizeList?sessionYear=2022
export const sansthaAwardBlankEndowmentListAPIURL = BaseURL + "endowment/reports/getEndowmentPrizeList";

//sanstha award distribution summary APIURL
//localhost:8080/institute/sss/endowment/reports/getSansthaAwardDistributionSummary?sessionYear=2022
export const getSansthaAwardDistSummaryAllAPIURL = BaseURL + "endowment/reports/getSansthaAwardDistributionSummary";

//localhost:8080/institute/sss/endowment/reports/getSansthaAwardDistributionSummaryByEndowmentId?sessionYear=2022&endowmentId=1
export const getSansthaAwardDistSummarySchoolAPIURL = BaseURL + "endowment/reports/getSansthaAwardDistributionSummaryByEndowmentId";

//School Wise Endowment Report APIURL
//localhost:8080/institute/sss/endowment/reports/getAllSchoolEndowmentSummary?sessionYear=2022
export const printAllSchoolWiseEndowmentAPIURL = BaseURL + "endowment/reports/getAllSchoolEndowmentSummary";

//localhost:8080/institute/sss/endowment/reports/getSchoolWiseEndowmentSummary?sessionYear=2022&endowmentId=1
export const printSchoolWiseEndowmentAPIURL = BaseURL + "endowment/reports/getSchoolWiseEndowmentSummary";


//ClassWise Endowment Report
//localhost:8080/institute/sss/endowment/reports/getClassWiseEndowmentPrizeDistributionList?sessionYear=2022&classId=4
export const printClassWiseEndowmentReportAPIURL = BaseURL + "endowment/reports/getClassWiseEndowmentPrizeDistributionList";

//SubjectWise Endowment report
//localhost:8080/institute/sss/endowment/reports/getSubjectWiseEndowmentPrizeDistributionList?sessionYear=2022&subId=1
export const printSubWiseEndowmentReportAPIURL = BaseURL + "endowment/reports/getSubjectWiseEndowmentPrizeDistributionList";

//genderWise Endowment report
//localhost:8080/institute/sss/endowment/reports/getGenderWiseEndowmentPrizeDistributionList?sessionYear=2022&genderId=3
export const printGenderWiseEndowmentreportAPIURL = BaseURL + "endowment/reports/getGenderWiseEndowmentPrizeDistributionList";

//Endowment Register APIURL
//localhost:8080/institute/sss/endowment/reports/endowmentRegisterPrinting
export const printEndowmentRegister = BaseURL + "endowment/reports/endowmentRegisterPrinting";

//Schoolwise endowmentwise detail report
//localhost:8080/institute/sss/endowment/reports/getAllSchoolEndowmentDetailReport?sessionYear=2022
export const printAllSchoolWiseEndowmentWiseReport = BaseURL + "endowment/reports/getAllSchoolEndowmentDetailReport";

//localhost:8080/institute/sss/endowment/reports/getSchoolWiseEndowmentDetailReport?sessionYear=2022&endowmentId=1
export const printSchoolWiseEndowmentWiseReport = BaseURL + "endowment/reports/getSchoolWiseEndowmentDetailReport";