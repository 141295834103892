import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

export const getSchoolDataAPIURL = BaseURLMaster + "inst/Institutes/";



// /institute/sss/administration/transfer/

// PostMapping   /saveTransferStudentDate

// Object

export const saveTransferStudentDateAPIURL=BaseURL+"administration/transfer/saveTransferStudentDate";

