import { dBaseUrlLibraryMaster, BaseUrlLibraryMaster } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/library/master/getAllRegisterTypeMasterList?branchId=1
export const getRegisterTypeDataAPIURL = BaseUrlLibraryMaster + "getAllRegisterTypeMasterList";


// /localhost:8080/institute/sss/library/master/getRegisterTypeMasterData?id=2
export const getRegisterTypeDataById = BaseUrlLibraryMaster + "getRegisterTypeMasterData";

// localhost:8080/institute/sss/library/master/saveRegisterTypeMaster
// {
//     "registerTypeName": "registerTypeName",
//     "barcodeId": "barcodeId",
//     "isPeriodical": 2,
//     "branchId": 1
// }
export const saveRegisterTypeDataAPIURL = BaseUrlLibraryMaster + "saveRegisterTypeMaster";

// localhost:8080/institute/sss/library/master/updateRegisterTypeMaster
// {
//     "id":1,
//     "registerTypeName": "registerName",
//     "barcodeId": "Id",
//     "isPeriodical": 1,
//     "branchId": 1
// }
export const updateRegisterTypeDataAPIURL = BaseUrlLibraryMaster + "updateRegisterTypeMaster";

//localhost:8080/institute/sss/library/master/deleteRegisterTypeMaster?id=2
export const deleteRegisterTypeDataAPIURL = BaseUrlLibraryMaster + "deleteRegisterTypeMaster";