import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment/moment";
import PrintIcon from '@mui/icons-material/Print';

const BudgetAssetEntryReportTable = ({ TableHeading8, setData, setFilteredData, data, filteredData, schoolNm,
    printAllBudgetEntryReport, totalAssetEntryBudget }) => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div className="">
                        <form>
                            <div className="row mt-5 mb-2" >
                                <div className="">
                                    <span className='mt-3' style={{ float: "left", fontSize: "17px", color: "#DC2C0C", fontWeight: "600" }}>{schoolNm}</span>
                                    <div style={{ float: "right" }}>
                                        <button
                                            style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                            type='button'
                                            onClick={() => printAllBudgetEntryReport()}
                                            className='btn btn-sm mb-2'
                                        //disabled={btnDisabled}
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='table-responsive mb-5'>
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            {TableHeading8.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `${item.textAlign}` }} scope="col">{item.label}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            filteredData.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.accNameMr}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "160px", }}>{item.quantity}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "160px", textAlign: "right" }}>{(item.rate).toFixed('2')}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "160px", textAlign: "right" }}>{(item.totalRate).toFixed('2')}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "600", lineHeight: "1.4" }}>एकूण</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}></td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}></td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(totalAssetEntryBudget).toFixed('2')}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}

export default BudgetAssetEntryReportTable