import { dBaseURL, BaseURL } from "../../../CommonServices/APIURL";

//localhost:8080/institute/sss/budget/master/getAllBudgetCategory
export const allBudgetCategoriesAPIURL = BaseURL + "budget/master/getAllBudgetCategory";

//localhost:8080/institute/sss/budget/master/getBudgetCategory?id=1
export const getBudgetCategoryById = BaseURL + "budget/master/getBudgetCategory";

//localhost:8080/institute/sss/budget/master/saveBudgetCategory
// {
//     "departmentId": 3,
//     "optionId": 1,
//     "nameMr": "name",
//     "nameEn": "name"
// }
export const saveBudgetCategoryAPIURL = BaseURL + "budget/master/saveBudgetCategory";

//localhost:8080/institute/sss/budget/master/updateBudgetCategory
export const updateBudgetCategoryAPIURL = BaseURL + "budget/master/updateBudgetCategory";

//localhost:8080/institute/sss/budget/master/deleteBudgetCategory?id=3
export const deleteBudgetCategoryAPIURL = BaseURL + "budget/master/deleteBudgetCategory";