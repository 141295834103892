import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import TitleLabel from "../../CommonComponent/TitleLabel";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModalBox from "../../CommonComponent/DeleteModalBox";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Switch from '@mui/material/Switch';
import { deleteMobileEventAPIURL, getMobileEventAPIURL, updateMobileEventStatusAPIURL } from "../Services/MobileAppAllAPI";
import moment from "moment";

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const EventTable = () => {
    const titleId = "Event Table";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        setLoaderOption(true);
        //console.log(``)
        if (authUser.branchId != null) {
            await axios.get(`${getMobileEventAPIURL}?bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                })
        }
    }

    const updateData = (item, EditFlag) => {
        navigate(`/Home/ma1004F/${item}/${EditFlag}`)
    }

    const [deleteId, setDeleteId] = useState('');

    const DeleteData = async (id) => {
        setLoaderOption(true);
        await axios.delete(`${deleteMobileEventAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data === "DELETED") {
                    setLoaderOption(false);
                    getData();
                    toast.success("Deleted successfully");
                }

            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Something went wrong");
                console.log(err)
            })

    }

    const updateStatus = async (id, status) => {
        setLoaderOption(true);
        await axios.put(`${updateMobileEventStatusAPIURL}?status=${status}&id=${id}`)
            .then(res => {
                if (res.data == "UPDATED") {
                    console.log(res.data);
                    getData();
                    setLoaderOption(false);
                    toast.success("Status Updated Successfully.")
                }
            }).catch(err => {
                console.log("Notice error:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) => (
            item.eventForString.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.eventName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.eventDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const TableHeading = [
        { label: 'Id', key: 'id' },
        { label: `Event For`, key: 'eventForString' },
        { label: `Event Name`, key: 'eventName' },
        { label: `Date`, key: 'eventDate' },
        { label: 'Status', value: 'eventStatus', isAction: true },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <div className=''>
                                <button className='btn btn-primary btn-sm mb-3' style={{ float: 'left' }} onClick={() => navigate(`/Home/ma1004F/0/1`)} ><AddIcon fontSize="small" />New Event</button>
                            </div>

                            {/* <div className=''>
                                <button className='btn btn-sm mb-3 ml-2' style={{ float: 'left', backgroundColor: "#318C09", color: "#fff" }} ><IosShareIcon fontSize="small" /> Export PDF</button>
                            </div> */}

                            <div className='row col-sm-4 mt-2' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>

                            <div className='mt-4'>
                                <div className='table-responsive' >
                                    <table className="table table-bordered">
                                        <thead className="table-Default">
                                            <tr>
                                                {TableHeading.map((item, index) => {
                                                    return (
                                                        <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.eventForString}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.eventName}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{moment(item.eventDate).format("DD/MM/YYYY")}</td>

                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                    <Switch aria-label="Switch demo" color="secondary"
                                                                        onChange={() => {
                                                                            let val = item.eventStatus;
                                                                            if (val === 1) {
                                                                                updateStatus(item.id, 0)
                                                                            }
                                                                            else if (val === 0) {
                                                                                updateStatus(item.id, 1)
                                                                            }
                                                                        }}
                                                                        checked={(item.eventStatus === 1) ? true : false}
                                                                    />
                                                                </td>
                                                                <td>

                                                                    <Tooltip title="Edit">
                                                                        <span className='btn btn-primary btn-sm mx-1 p-1' d
                                                                            onClick={() => {
                                                                                updateData(item.id, 2);
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </span>
                                                                    </Tooltip>

                                                                </td>

                                                                <td>
                                                                    <Tooltip title="Delete">
                                                                        <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                            onClick={() => setDeleteId(item.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })}
                                        </tbody>
                                        <tfoot style={{ padding: "0px" }}>
                                            <tr>
                                                <TablePagination
                                                    style={{ padding: "0px" }}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    count={data.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onRowsPerPageChange={onRowsPerPageChange}
                                                    onPageChange={onPageChange}
                                                    labelRowsPerPage="Rows Per Page :"
                                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default EventTable