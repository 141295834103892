import React, { useState, useEffect } from 'react';
import TitleLabel from '../../CommonComponent/TitleLabel';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { getClassDivisionURL } from '../Services/PramotShiftStudentsAPIURL';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import StudentAllowanceCheckBox from './StudentAllowanceChkBox/StudentAllowanceCheckBox';
import Loader from '../../CommonComponent/Loader';
import { getStudentData } from '../../Academic/Services/StudentBatchAPIURL';
import { getStudentListForAllowanceAPIURL, saveStudentAllowancesAPIURL } from '../Services/StudAllowencesAPIURL';


const StudentAllowences = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Allowances"

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const TableHeading = [
        { label: 'Reg No', key: 'regNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Roll No`, key: 'rollNo' },
        { label: `Sub Caste`, key: (authUser.branchMedium == 1) ? 'subCateNameMr' : 'subCateNameEn' },
        { label: `Category`, key: (authUser.branchMedium == 1) ? 'categoryNameMr' : 'categoryNameEn' },
        { label: `Transport Allowance`, key: 'transportAllowance', isAction: true },
        { label: `Uniform Allowance`, key: 'uniformAllowance', isAction: true },
        { label: `Attendance Allowance`, key: 'attendanceAllowance', isAction: true },
    ];

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [id, setId] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [studAllowanceData, setStudAllowanceData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [tempData, setTempData] = useState([]);

    //console.log("checkValue: " + JSON.stringify(checkValue));

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                })
        }
    }

    const getStudentData = async (cId, dId) => {
        setLoaderOption(true);
        setStudAllowanceData([]);
        setFilteredData([]);
        setTempData([]);
        setSearchTerm("");
        setSortedBy(null);
        setAscending(false);
        await axios.get(`${getStudentListForAllowanceAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${cId}&divId=${dId}`)
            .then(res => {
                setStudAllowanceData(res.data);
                setFilteredData(res.data);
                setTempData(res.data);
                setLoaderOption(false);
            }).catch(error => {
                console.log(error);
            })
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...studAllowanceData.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...studAllowanceData.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
        getSortedData(studAllowanceData, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentListForAllowanceAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => (a[key] || '').toString().toLowerCase() < (b[key] || '').toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => (a[key] || '').toString().toLowerCase() > (b[key] || '').toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === studAllowanceData.length && list.every((o, i) => Object.keys(o).length === Object.keys(studAllowanceData[i]).length && Object.keys(o).every(k => o[k] === studAllowanceData[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(studAllowanceData);
                }
            })
    }


    const [transport, setTransport] = useState('');
    const [uniform, setUniform] = useState('');
    const [attendace, setAttendace] = useState('');
    const [regNum, setRegNum] = useState('');

    //console.log(JSON.stringify(regNum))
    useEffect(() => {
        setRegNum('');

        tempData.map((e, index) => {
            //console.log("new reg No=========" + regNum + "-------" + "old reg no==========" + e.regNo)
            if (e.regNo == regNum) {
                tempData[index] = ({
                    ...e, transportAllowance: transport,
                    uniformAllowance: uniform,
                    attendanceAllowance: attendace,
                })
            }
        })

        tempData.map((e, index) => {
            tempData[index] = ({
                ...e,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            })

        })
        //console.log(JSON.stringify(tempData))
        setStudAllowanceData(tempData);
        if (searchTerm == "") {
            setFilteredData(tempData);
        }
    }, [regNum, searchTerm])


    const savestudentAllowence = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        let SaveObj = tempData.filter(e => {
            return e.uniformAllowance == 1 || e.attendanceAllowance == 1 || e.transportAllowance == 1
        })
        // let updatedData = SaveObj;
        // SaveObj.map((e, index) => {
        //     if (e.regNo == regNum) {
        //         updatedData[index] = ({
        //             ...e,
        //             sessionYear: authUser.sessionYear,
        //             sectionId: authUser.deptId,
        //             branchId: authUser.branchId,
        //             uniformAllowance: uniform,
        //             attendanceAllowance: attendace,
        //             transportAllowance: transport
        //         })
        //     }
        // })
        console.log("studAllowanceData data: " + JSON.stringify(studAllowanceData));
        if (SaveObj.length === 0) {
            const removeObj = [{
                classId: classDivObj.classId,
                divisionId: classDivObj.divisionId,
                regNo: 0,
                rollNo: 0,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
            }]
            await axios.post(`${saveStudentAllowancesAPIURL}`, removeObj)
                .then(res => {
                    if (res.data != null) {
                        setStudAllowanceData([]);
                        setFilteredData([]);
                        setTempData([]);
                        setClassDivObj(null);
                        setSearchTerm("");
                        setLoaderOption(false);
                        toast.success("Student Allowence Allocation Successfully done.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    }
                }).catch(error => {
                    console.log(error);
                })
        }
        else {
            await axios.post(`${saveStudentAllowancesAPIURL}`, studAllowanceData)
                .then(res => {
                    if (res.data != null) {
                        setStudAllowanceData([]);
                        setFilteredData([]);
                        setTempData([]);
                        setClassDivObj(null);
                        setSearchTerm("");
                        setLoaderOption(false);
                        toast.success("Student Allowence Allocation Successfully done.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    }
                }).catch(error => {
                    console.log(error);
                })
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = studAllowanceData.filter((item) =>
            (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.subCateNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.subCateNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.categoryNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.categoryNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.rollNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (classDivObj !== null || classDivObj !== "" || studAllowanceData != null || studAllowanceData != "" || studAllowanceData != []) {
                    console.log('Enter is pressed!');
                    let btn = document.getElementById('btnDiv')
                    if (btn !== null) {
                        btn.querySelector('.studAllowenceBtn').click();
                    }

                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
        
    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row">
                        {/* <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class-Division:</label> */}
                        <div className='col-sm-3'>
                            <Autocomplete
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                id="combo-box-demo"
                                onChange={(e, newValue) => {
                                    setClassDivObj(newValue);
                                    if (newValue != null) {
                                        getStudentData(newValue.classId, newValue.divisionId)
                                    }
                                    else if (newValue == null) {
                                        setFilteredData([]);
                                        setStudAllowanceData([]);
                                    }
                                }}
                                value={classDivObj}
                                options={classDivData}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Class-Division" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    {
                        (studAllowanceData == "" || studAllowanceData == null || studAllowanceData == [])
                            ?
                            <h4 className='mt-5'>No Records, Select Class-Division</h4>
                            :
                            <div>
                                <div>
                                    <div className='row col-sm-4 mb-1 mt-2' style={{ float: 'right' }}>
                                        <div>
                                            <input
                                                className='form-control form-control-sm'
                                                type="text"
                                                style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                placeholder="Search Here"
                                                onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                                value={searchTerm}
                                            />
                                        </div>
                                    </div>

                                    <form onSubmit={savestudentAllowence}>
                                        <div className='table-responsive mt-5' style={{ maxHeight: "510px" }}>
                                            <table className="table table-bordered">
                                                <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                    <tr>
                                                        {TableHeading.map((item, index) => {
                                                            return (
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                            )
                                                        })}

                                                    </tr>
                                                </thead>
                                                <tbody className='studentChkBox' style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                    {filteredData.map((item, index) => {
                                                        return (
                                                            <tr key={index} className="table-sm">
                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "10px" }} >{item.regNo}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "100px" }} >{item.rollNo}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                    {/* {(authUser.branchMedium == 1) ? ((item.subCateNameMr == null || item.subCateNameMr == "") ? item.subCateNameEn : item.subCateNameMr) :
                                                                    ((item.subCateNameEn == null || item.subCateNameEn == "") ? item.subCateNameMr : item.subCateNameEn)} */}
                                                                    {(() => {
                                                                        if ((item.subCateNameMr == null || item.subCateNameMr == "") && (item.subCateNameEn == null || item.subCateNameEn == "")) {
                                                                            return <div style={{ textAlign: "center" }}><p style={{ fontSize: "10px", marginTop: "10px" }}> — </p></div>
                                                                        }
                                                                        else if (authUser.branchMedium == 1) {
                                                                            if (item.subCateNameMr == null || item.subCateNameMr == "") {
                                                                                return item.subCateNameEn
                                                                            }
                                                                            else {
                                                                                return item.subCateNameMr
                                                                            }
                                                                        }
                                                                        else if (authUser.branchMedium == 2) {
                                                                            if (item.subCateNameEn == null || item.subCateNameEn == "") {
                                                                                return item.subCateNameMr
                                                                            }
                                                                            else {
                                                                                return item.subCateNameEn
                                                                            }
                                                                        }
                                                                    })()}
                                                                </td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }} >{(authUser.branchMedium == 1) ? item.categoryNameMr : item.categoryNameEn}</td>

                                                                <StudentAllowanceCheckBox initialText1={item.transportAllowance} initialText2={item.uniformAllowance} initialText3={item.attendanceAllowance}
                                                                    onChange={(value1, value2, value3) => {
                                                                        let updatedData = filteredData;
                                                                        updatedData[index] = ({
                                                                            ...item,
                                                                            transportAllowance: value1,
                                                                            uniformAllowance: value2,
                                                                            attendanceAllowance: value3,

                                                                        })
                                                                        updatedData.map((e, index) => {
                                                                            updatedData[index] = ({
                                                                                ...e,
                                                                                sessionYear: authUser.sessionYear,
                                                                                sectionId: authUser.deptId,
                                                                                branchId: authUser.branchId
                                                                            })
                                                                        })
                                                                        setTransport(value1);
                                                                        setUniform(value2);
                                                                        setAttendace(value3);
                                                                        setRegNum(updatedData[index].regNo)
                                                                        setStudAllowanceData(updatedData);
                                                                        setFilteredData(updatedData);
                                                                        //console.log(JSON.stringify(checkValue.concat(updatedData2[index])))
                                                                    }}
                                                                />
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-sm-12" id="btnDiv">
                                                <button type="submit" className="studAllowenceBtn btn btn-sm btn-primary"><AddIcon fontSize="small" />Save Changes</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                    }


                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default StudentAllowences