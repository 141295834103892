import React, { useState, useEffect } from 'react';
import ServiceHeader from './ServiceHeader';
import ServiceContent from './ServiceContent';

import { serviceLogin, serviceLogout } from '../../reduxFeature/user';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";

const ServiceMaster = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const getData = sessionStorage.getItem("servicePanel");
    if (getData) {
      console.log(JSON.parse(getData))
      const localUserData = (JSON.parse(getData));

      dispatch(serviceLogin({ userName: localUserData.userName, email: localUserData.email, mobile: localUserData.mobile }));
      //navigate("/service/s001/d001");
    }
    else{
      dispatch(serviceLogout())
      navigate("/service");
    }
  }, [])
  return (
    <>
      <ServiceHeader />
      <ServiceContent />
    </>
  )
}

export default ServiceMaster