import React from 'react'

const TitleLabel = (props) => {
    return (
        <>
            <div className="title align-middle" style={{userSelect:"none"}}>
                <span className="vr p2" style={{ backgroundColor: '#464de4', width: '5px', opacity: '70%', fontSize: '25px', }}> </span>
                <span style={{ width: "100%", fontSize: "1.5rem", marginLeft:"4px", fontWeight:"600" }}>{props.titleId}</span>
            </div>
        </>
    )
}

export default TitleLabel;
