import { BaseURL } from "../../CommonServices/APIURL";



//institute/sss/academics/StudentWiseExternalExam/getStudentWiseExternalExamAllocatedData
export const getExternalExamAllocationAPIURL = BaseURL + "academics/StudentWiseExternalExam/getStudentWiseExternalExamAllocatedData";

//institute/sss/academics/StudentWiseExternalExam/saveStudentWiseExternalExamAllocatedData
export const saveExternalExamAllocationAPIURL = BaseURL + "academics/StudentWiseExternalExam/saveStudentWiseExternalExamAllocatedData";



//Report
//localhost:8081/institute-sss/institute/sss/academic/reports/generateStudentWiseExternalExamReport?langId=1&extExamId=1&classId=5&divId=17&acYear=2023&sid=3&bid=1
export const generateStudentWiseExternalExamReportAPIURL = BaseURL + "academic/reports/generateStudentWiseExternalExamReport";