import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";


export const getStudentCasteCategory=BaseURL+"administration/getStudentCastCategoryAllocationData";

export const saveStudentCasteCategory=BaseURL+"administration/saveStudentCasteCategory";




// private long id;
// private long regCode;
// private String  studentNameMr;
// private String  studentNameEn;
// private String casteNameMr;
// private String casteNameEn;
// private  long rollNo;
// private  long categoryId;
// private String categoryNameMr;
// private String categoryNameEn;
// private long sessionYear;
// private long sectionId;
// private long branchId;
// private long classId;
// private long divisionId;

// =============================================

// /institute/sss/administration/

// GetMapping   /getStudentCastCategoryAllocationData

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cdid") long classDivId
