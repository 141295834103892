import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { width } from '@mui/system';
import { useSelector } from "react-redux";

const EditableRow = ({ editFormData, handleEditFormChange, handleCancelClick, }) => {
    const authUser = useSelector((state) => state.user.value);
    return (
        <>
            <tr style={{ backgroundColor: "#F4ECF7", height: "65px" }}>
                <td style={{ height: "50px", width: "80px" }}>{editFormData.regCode}</td>

                <td style={{ height: "50px", lineHeight:"1.3" }}>{(authUser.branchMedium == 1) ? editFormData.studentNameMr : editFormData.studentNameEn}</td>

                <td style={{ height: "50px", width: "80px" }}>{editFormData.rollNo}</td>

                <td style={{ width: "180px", height: "50px" }}>
                    <input type="text" className='form-control form-control-sm' style={{ width: "120px" }} name='accountName' value={editFormData.accountName} onChange={handleEditFormChange} />
                </td>

                <td style={{ width: "180px", height: "50px" }}>
                    <input type="text" className='form-control form-control-sm' style={{ width: "150px" }} name='bankAccountNo' value={editFormData.bankAccountNo} onChange={handleEditFormChange} />
                </td>

                <td style={{ width: "180px", height: "50px" }}>
                    <input type="text" className='form-control form-control-sm' name='bankName' value={editFormData.bankName} onChange={handleEditFormChange} />
                </td>

                <td style={{ width: "180px", height: "50px" }}>
                    <input type="text" className='form-control form-control-sm' name='branchName' value={editFormData.branchName} onChange={handleEditFormChange} />
                </td>

                <td style={{ width: "150px", height: "50px" }}>
                    <input type="text" className='form-control form-control-sm' style={{ width: "120px" }} name='bankIFSCCode' value={editFormData.bankIFSCCode} onChange={handleEditFormChange} />
                </td>



                <input type="hidden" name='regCode' value={editFormData.regCode} />
                <input type="hidden" name='studentNameMr' value={(authUser.branchMedium == 1) ? editFormData.studentNameMr : editFormData.studentNameEn} />
                <input type="hidden" name='rollNo' value={editFormData.rollNo} />

                <input type="hidden" name='id' value={editFormData.id} />

                <input type="hidden" name='sectionId' value={editFormData.sectionId} />


                <input type="hidden" name='branchId' value={editFormData.branchId} />

                <input type="hidden" name='classId' value={editFormData.classId} />

                <input type="hidden" name='divisionId' value={editFormData.divisionId} />

                <td style={{ padding: "0px", width: "100px" }}>
                    <Tooltip title="Save">
                        <button type='submit' className='btn btn-sm mx-1 p-1' data-bs-toggle="tooltip"  style={{ backgroundColor: "#27AE60", color: "white" }}>
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </Tooltip>

                    <Tooltip title="Cancle">
                        <span onClick={handleCancelClick} className='btn btn-sm ml-1 p-1' data-bs-toggle="tooltip"  style={{ backgroundColor: "#CC0505", color: "white" }}>
                            <FontAwesomeIcon icon={faWindowClose} onClick={handleCancelClick} />
                        </span>
                    </Tooltip>
                </td>

            </tr>
        </>
    )
}

export default EditableRow