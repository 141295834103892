import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

export const LcPrintBaseURL = BaseURL + "administration/reports/";
export const printLcAPIURL = LcPrintBaseURL + "generateLCReport";

export const getPrintLcAPIURL = BaseIP + "/output/";

// get Student List
export const studentListBaseURL = BaseURL + "administration/studentEntry/";
export const getStudentListURL = studentListBaseURL + "leftStudentEntryList/";

//LC BaseUrl
export const BaseURLLc = BaseURL + "administration/LC/";
export const GetStudentLcData = BaseURLLc + "getIssueLeavingCertificateData/";

//Bulk LC
//localhost:8080/institute/sss/administration/reports/generateBulkLCReport?langId=1&sid=3&bid=1&waterMark=1
//export const bulkLcPrint = LcPrintBaseURL + "generateBulkLCReport";

//localhost:8080/institute/sss/administration/reports/generateBulkLCReport?langId=1&sid=3&bid=1&waterMark=1&classId=10&divId=37&acYear=2021
export const lcPrintClassDivWise = LcPrintBaseURL + "generateBulkLCReport";



//LC Summary Report APIURL
// PostMapping
// localhost:8080/institute/sss/administration/reports/generateLCReportManually

//export const generateLCReportManuallyAPIURL="http://192.168.1.102:8080/institute/sss/administration/reports/generateLCReportManually"
export const generateLCReportManuallyAPIURL = BaseURL + "administration/reports/generateLCReportManually";

//localhost:8080/institute/sss/administration/reports/generateLCSummaryReport?langId=1&acYear=2022&sid=3&bid=1&classId=7&divId=25
export const classDivWiseLcSummaryPrint = BaseURL + "administration/reports/generateLCSummaryReport";

//localhost:8080/institute/sss/administration/reports/generateLCSummaryExcelReport?langId=1&classId=10&divId=37&acYear=2022&sid=3&bid=1
export const printLcSummaryExcelReport = BaseURL + "administration/reports/generateLCSummaryExcelReport"
