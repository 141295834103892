import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../CommonComponent/Loader';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import { printMemberStatementPdfReport, printMemberStatementExcelReport } from '../Services/CirculationReportAPIURL';

const useStyles = makeStyles({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
});

const MemberStatement = () => {
    const titleId = "Member Statement";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [view, setView] = useState(1);
    const [memberType, setMemberType] = useState('');
    const [memberNoFrom, setMemberNoFrom] = useState('');
    const [memberNoTo, setMemberNoTo] = useState('');

    const getMemberStatementPdfReport = async () => {
        setLoaderOption(true);
        console.log(`${printMemberStatementPdfReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&memType=${memberType}&memFrom=${memberNoFrom}&memTo=${memberNoTo}`)
        await axios.get(`${printMemberStatementPdfReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&memType=${memberType}&memFrom=${memberNoFrom}&memTo=${memberNoTo}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Member statement pdf report" + err)
                toast.error("Something went wrong, please check.")
            })
    }

    const getMemberStatementExcelReport = async () => {
        setLoaderOption(true);
        console.log(`${printMemberStatementExcelReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&memType=${memberType}&memFrom=${memberNoFrom}&memTo=${memberNoTo}`)
        await axios.get(`${printMemberStatementExcelReport}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&memType=${memberType}&memFrom=${memberNoFrom}&memTo=${memberNoTo}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Member statement excel report" + err)
                toast.error("Something went wrong, please check.")
            })
    }

    const getMemberStatementReport = async (e) => {
        e.preventDefault();
        if (view == 1) {
            getMemberStatementPdfReport();
        }
        else if (view == 2) {
            getMemberStatementExcelReport();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                            <form onSubmit={getMemberStatementReport}>
                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View Type :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>View Type</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={view}
                                                label="View Type"
                                                onChange={(e) => {
                                                    setView(e.target.value);
                                                    setMemberType('');
                                                    setMemberNoFrom('');
                                                    setMemberNoTo('');
                                                }}
                                            >
                                                <MenuItem value={1}>PDF</MenuItem>
                                                <MenuItem value={2}>Excel</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member Type :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Member Type</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                margin='dense'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={memberType}
                                                label="Member Type"
                                                onChange={(e) => {
                                                    setMemberType(e.target.value);
                                                    setMemberNoFrom('');
                                                    setMemberNoTo('');
                                                }}
                                            >
                                                <MenuItem value={1}>Staff</MenuItem>
                                                <MenuItem value={2} hidden={(authUser.deptId != 3) ? true : false}>Secondary Students</MenuItem>
                                                <MenuItem value={3} hidden={(authUser.deptId != 4) ? true : false}>Higher-Secondary Students</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Member No. From :</label>
                                    <div className="col-sm-2">
                                        <TextField
                                            fullWidth
                                            required
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            onKeyDown={(e) => (e.key === " " && memberNoFrom.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? memberNoFrom : ""}
                                            label="From"
                                            value={memberNoFrom}
                                            onChange={(e) => setMemberNoFrom(e.target.value)}
                                        />
                                    </div>

                                    <label className='' style={{ width: "70px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                    <div className="col-sm-2">
                                        <TextField
                                            fullWidth
                                            required
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            onKeyDown={(e) => (e.key === " " && memberNoTo.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? memberNoTo : ""}
                                            label="To"
                                            value={memberNoTo}
                                            onChange={(e) => setMemberNoTo(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }
        </>
    )
}

export default MemberStatement