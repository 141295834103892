import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from "axios";
import { ReligionPostMapping, ReligionMasterGetMapping } from "../CommonServices/ReligionMasterAPIURL";

const AddNewReligionModal = ({ setReligionData }) => {
    const [religionMarathi, setReligionMarathi] = useState("");
    const [religionEnglish, setReligionEnglish] = useState("");
    const [religionUDise, setReligionUDise] = useState("");

    const getReligionData = async () => {
        await axios.get(`${ReligionMasterGetMapping}`)
            .then((response) => {
                setReligionData(response.data);
            })
    }

    const saveBtnClick = async (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        const saveObject = {
            nameMr: religionMarathi,
            nameEn: religionEnglish,
            udiseNo: religionUDise
        };
        await axios.post(`${ReligionPostMapping}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getReligionData();

                    // get modal
                    const modal = document.getElementById('religionModal');

                    // change state like in hidden modal
                    modal.classList.remove('show');
                    modal.setAttribute('aria-hidden', 'true');
                    modal.setAttribute('style', 'display: none');

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                    // remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);

                    toast.success("Religion Saved sucessfully.");
                } else {
                    toast.danger("Operation failed.");
                }
            });

        setReligionMarathi("");
        setReligionEnglish("");
        setReligionUDise("");

    };

    return (
        <>
            <div className="modal fade" id="religionModal" tabIndex="-1" aria-labelledby="religionModalLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#EAECEE" }}>
                            <h5 className="modal-title" id="religionModalLabel">Add New Religion</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={saveBtnClick}>
                            <div className="modal-body">

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && religionMarathi.length === 0) ? e.preventDefault() : ""}
                                    value={religionMarathi}
                                    onChange={(e) => setReligionMarathi(e.target.value)}
                                    label="Religion Marathi"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && religionEnglish.length === 0) ? e.preventDefault() : ""}
                                    value={religionEnglish}
                                    onChange={(e) => setReligionEnglish(e.target.value)}
                                    label="Religion English"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                                <TextField
                                    required
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && religionUDise.length === 0) ? e.preventDefault() : ""}
                                    value={religionUDise}
                                    onChange={(e) => setReligionUDise(e.target.value)}
                                    label="U-Dise No"
                                    id="outlined-size-small"
                                    size="small"
                                    className='form-control'
                                    margin="dense"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Save</button>
                                <button type="reset" className="btn btn-danger" onClick={()=>{setReligionMarathi("");setReligionEnglish("");setReligionUDise("");}} data-bs-dismiss="modal">Cancle</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>

    )
}

export default AddNewReligionModal