import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//get list
//localhost:8080/institute/sss/hostel/master/getAllBuildingMaster
export const getBuildingMasterAPIURL = BaseURL + "hostel/master/getAllBuildingMaster";

//save
// localhost:8080/institute/sss/hostel/master/saveBuildingMaster
// {
//     "buildingName": "buildingName"
// }
export const saveBuildingMasterAPIURL = BaseURL + "hostel/master/saveBuildingMaster";

// /update
//localhost:8080/institute/sss/hostel/master/updateBuildingMaster
export const updateBuildingMasterAPIURL = BaseURL + "hostel/master/updateBuildingMaster";

// /localhost:8080/institute/sss/hostel/master/deleteBuildingMaster?id=1
export const deleteBuildingMasterAPIURL = BaseURL + "hostel/master/deleteBuildingMaster";