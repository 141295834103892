import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import { useNavigate } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { GetProgressConduct } from '../../CommonServices/LeavingCertificateAPIURL';
import { updateLcDefaultData, getLcDefaultData } from '../Services/StudentBulkLcAPIURL';


const LcDefault = () => {
    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate()
    const titleId = "LC Default";

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState();
    const [defaultLcDate, setDefaultLcDate] = useState(null);
    const [defaultLeftDate, setDefaultLeftDate] = useState(null);
    const [sinceWhen, setSinceWhen] = useState(null);
    //console.log(defaultLeftDate);
    const [progressConductData, setProgressConductData] = useState([]);
    const [progressBoyMrObj, setProgressBoyMrObj] = useState(null);
    const [progressBoyEnObj, setProgressBoyEnObj] = useState(null);
    const [progressGirlMrObj, setProgressGirlMrObj] = useState(null);
    const [progressGirlEnObj, setProgressGirlEnObj] = useState(null);
    const [conductBoyMrObj, setConductBoyMrObj] = useState(null);
    const [conductBoyEnObj, setConductBoyEnObj] = useState(null);
    const [conductGirlMrObj, setConductGirlMrObj] = useState(null);
    const [conductGirlEnObj, setConductGirlEnObj] = useState(null);
    const [reasonMrBoy, setReasonMrBoy] = useState("");
    const [reasonEnBoy, setReasonEnBoy] = useState("");
    const [reasonMrGirl, setReasonMrGirl] = useState("");
    const [reasonEnGirl, setReasonEnGirl] = useState("");
    const [remarkMrBoy, setRemarkMrBoy] = useState("");
    const [remarkEnBoy, setRemarkEnBoy] = useState("");
    const [remarkMrGirl, setRemarkMrGirl] = useState("");
    const [remarkEnGirl, setRemarkEnGirl] = useState("");


    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {

        await axios.get(`${GetProgressConduct}`)
            .then((response) => {
                setProgressConductData(response.data);
            })

        //console.log(`${getLcDefaultData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${getLcDefaultData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setLoaderOption(true);
                console.log(response.data.sinceWhen);
                setDefaultLcDate(response.data.defaultLCDate);
                setDefaultLeftDate(response.data.defaultLeftDate);
                const selectedProgressBoy = response.data.progressBoy;
                const selectedProgressGirl = response.data.progressGirl;
                const selectedConductBoy = response.data.conductBoy;
                const selectedConductGirl = response.data.conductGirl;
                setReasonMrBoy(response.data.reasonMrBoy);
                setReasonEnBoy(response.data.reasonEnBoy);
                setReasonMrGirl(response.data.reasonMrGirl);
                setReasonEnGirl(response.data.reasonEnGirl);
                setRemarkMrBoy(response.data.remarkMrBoy);
                setRemarkEnBoy(response.data.remarkEnBoy);
                setRemarkMrGirl(response.data.remarkMrGirl);
                setRemarkEnGirl(response.data.remarkEnGirl);
                setSinceWhen(response.data.sinceWhen);
                //setLoaderOption(false);

                axios.get(`${GetProgressConduct}`)
                    .then((response) => {
                        setLoaderOption(false);
                        setProgressConductData(response.data);
                        setProgressBoyMrObj(response.data.find(e => e.id == selectedProgressBoy));
                        setProgressBoyEnObj(response.data.find(e => e.id == selectedProgressBoy));
                        setProgressGirlMrObj(response.data.find(e => e.id == selectedProgressGirl));
                        setProgressGirlEnObj(response.data.find(e => e.id == selectedProgressGirl));
                        setConductBoyMrObj(response.data.find(e => e.id == selectedConductBoy));
                        setConductBoyEnObj(response.data.find(e => e.id == selectedConductBoy));
                        setConductGirlMrObj(response.data.find(e => e.id == selectedConductGirl));
                        setConductGirlEnObj(response.data.find(e => e.id == selectedConductGirl));
                       
                    })
                //setLoaderOption(false);

            }).catch((error) => {
                console.warn('error' + error);
            })

    }

    const updateLcData = (e) => {
        e.preventDefault();
        if (moment(defaultLcDate).format("DD/MM/YYYY") !== "Invalid date" && moment(defaultLeftDate).format("DD/MM/YYYY") !== "Invalid date") {
            const updateObject = {
                id: id,
                defaultLCDate: moment(defaultLcDate).format("DD/MM/YYYY"),
                defaultLeftDate: moment(defaultLeftDate).format("DD/MM/YYYY"),
                progressBoy: progressBoyMrObj.id,
                progressGirl: progressGirlMrObj.id,
                conductBoy: conductBoyMrObj.id,
                conductGirl: conductGirlMrObj.id,
                reasonMrBoy: reasonMrBoy,
                reasonEnBoy: reasonEnBoy,
                reasonMrGirl: reasonMrGirl,
                reasonEnGirl: reasonEnGirl,
                remarkMrBoy: remarkMrBoy,
                remarkEnBoy: remarkEnBoy,
                remarkMrGirl: remarkMrGirl,
                remarkEnGirl: remarkEnGirl,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                sinceWhen: moment(sinceWhen).format("DD/MM/YYYY"),
                branchId: authUser.branchId
            }
            console.log(JSON.stringify(updateObject));
            axios.post(`${updateLcDefaultData}`, updateObject)
                .then((response) => {
                    if (response.data != null) {
                        //getData();
                        //navigate('/Home/ad210/');
                        toast.success("LC Default Saved Successfully.");
                    }
                    else {
                        toast.error("LC Default Failed.")
                    }
                }).catch((error) => {
                    console.warn("Error:" + error)
                })
            //toast.success("Valid Date")
        }
        else {
            toast.error("Improper date, please check.")
        }

    }


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                        <form onSubmit={updateLcData}>
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Default LC Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Default LC Date"
                                            value={defaultLcDate}
                                            onChange={(newValue) => {
                                                setDefaultLcDate(newValue);
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Default Left Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Default Left Date"
                                            value={defaultLeftDate}
                                            onChange={(newValue) => {
                                                setDefaultLeftDate(newValue);
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Since When:</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Since When"
                                            value={sinceWhen}
                                            onChange={(newValue) => {
                                                setSinceWhen(newValue);
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Progress Boy:</label>
                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={progressConductData}
                                        value={progressBoyMrObj}
                                        onChange={(event, newValue) => {
                                            //setProgressBoyMrId(newValue.id);
                                            setProgressBoyMrObj(newValue);
                                            // setProgressEnId(newValue.id);
                                            setProgressBoyEnObj(newValue);
                                        }}

                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameMr}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Progress Mar Boy" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={progressConductData}
                                        value={progressBoyEnObj}
                                        onChange={(event, newValue) => {
                                            //setProgressEnId(newValue.id);
                                            setProgressBoyEnObj(newValue);
                                            //setProgressMrId(newValue.id);
                                            setProgressBoyMrObj(newValue);
                                        }}

                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Progress Eng Boy" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Progress Girl:</label>
                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={progressConductData}
                                        value={progressGirlMrObj}
                                        onChange={(event, newValue) => {
                                            //setProgressMrId(newValue.id);
                                            setProgressGirlMrObj(newValue);
                                            //setProgressEnId(newValue.id);
                                            setProgressGirlEnObj(newValue);
                                        }}

                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameMr}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Progress Mar Girl" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={progressConductData}
                                        value={progressGirlEnObj}
                                        onChange={(event, newValue) => {
                                            //setProgressEnId(newValue.id);
                                            setProgressGirlEnObj(newValue);
                                            //setProgressMrId(newValue.id);
                                            setProgressGirlMrObj(newValue);
                                        }}

                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Progress Eng Girl" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Conduct Boy:</label>
                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={progressConductData}
                                        onChange={(event, newValue) => {
                                            //setConductMrId(newValue.id);
                                            setConductBoyMrObj(newValue);
                                            //setConductEnId(newValue.id);
                                            setConductBoyEnObj(newValue);
                                        }}
                                        value={conductBoyMrObj}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameMr}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Conduct Mar Boy" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={progressConductData}
                                        onChange={(event, newValue) => {
                                            //setConductEnId(newValue.id);
                                            setConductBoyEnObj(newValue);
                                            //setConductMrId(newValue.id);
                                            setConductBoyMrObj(newValue);
                                        }}
                                        value={conductBoyEnObj}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Conduct Eng Boy" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Conduct Girl:</label>
                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={progressConductData}
                                        onChange={(event, newValue) => {
                                            //setConductMrId(newValue.id);
                                            setConductGirlMrObj(newValue);
                                            //setConductEnId(newValue.id);
                                            setConductGirlEnObj(newValue);
                                        }}
                                        value={conductGirlMrObj}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameMr}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Conduct Mar Girl" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={progressConductData}
                                        onChange={(event, newValue) => {
                                            //setConductEnId(newValue.id);
                                            setConductGirlEnObj(newValue);
                                            //setConductMrId(newValue.id);
                                            setConductGirlMrObj(newValue);
                                        }}
                                        value={conductGirlEnObj}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Conduct Eng Girl" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Reason Mar Boy:</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e)=>(e.key === " " && reasonMrBoy.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        value={reasonMrBoy}
                                        onChange={e => setReasonMrBoy(e.target.value)}
                                        id="outlined-basic"
                                        label="LC Reason Mar Boy"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Reason Eng Boy:</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e)=>(e.key === " " && reasonEnBoy.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        value={reasonEnBoy}
                                        onChange={e => setReasonEnBoy(e.target.value)}
                                        label="LC Reason Eng Boy"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Reason Mar Girl:</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e)=>(e.key === " " && reasonMrGirl.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        value={reasonMrGirl}
                                        onChange={e => setReasonMrGirl(e.target.value)}
                                        id="outlined-basic"
                                        label="LC Reason Mar Girl"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Reason Eng Girl:</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e)=>(e.key === " " && reasonEnGirl.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        value={reasonEnGirl}
                                        onChange={e => setReasonEnGirl(e.target.value)}
                                        id="outlined-basic"
                                        label="LC Reason Eng Girl"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Remark Mar Boy:</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e)=>(e.key === " " && remarkMrBoy.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        value={remarkMrBoy}
                                        onChange={e => setRemarkMrBoy(e.target.value)}
                                        id="outlined-basic"
                                        label="LC Remark Mar Boy"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Remark Eng Boy:</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e)=>(e.key === " " && remarkEnBoy.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        value={remarkEnBoy}
                                        onChange={e => setRemarkEnBoy(e.target.value)}
                                        id="outlined-basic"
                                        label="LC Remark Eng Boy"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Remark Mar Girl:</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e)=>(e.key === " " && remarkMrGirl.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        value={remarkMrGirl}
                                        onChange={e => setRemarkMrGirl(e.target.value)}
                                        id="outlined-basic"
                                        label="LC Remark Mar Girl"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Remark Eng Girl:</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={(e)=>(e.key === " " && remarkEnGirl.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        size='small'
                                        value={remarkEnGirl}
                                        onChange={e => setRemarkEnGirl(e.target.value)}
                                        id="outlined-basic"
                                        label="LC Remark Eng Girl"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 mt-3">
                                <div className="offset-sm-2">
                                    <button type="submit" className={saveBtn} >Save</button>
                                    <button type="button" onClick={() => navigate("/Home/ad210/")} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Back</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )


}

export default LcDefault