import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitleLabel from '../../CommonComponent/TitleLabel';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getStudentListForHostelByBid } from '../Services/MaterialAllocationAPIURL';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { printStudentReportingAPIURL } from '../Services/StudReportingAPIURL';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 200,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  }
}));

const StudentReportingReport = () => {
  const titleId = 'Student Reporting Report';
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);
  const [loaderOption, setLoaderOption] = useState(false);

  const [id, setId] = useState('');
  const [option, setOption] = useState(1);
  const [studentData, setStudentData] = useState([]);
  const [studentObj, setStudentObj] = useState(null);
  const [classData, setClassData] = useState([]);
  const [classObj, setClassObj] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  useEffect(() => {
    getData();
  }, [authUser])

  const getData = async () => {
    if (authUser.branchId != null && authUser.sessionYear != "") {
      console.log(`${getStudentListForHostelByBid}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
      await axios.get(`${getStudentListForHostelByBid}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        .then(res => {
          const presentStudentList = res.data.filter(e => e.currentStatus == 0)
          setStudentData(presentStudentList);

          const objIds = presentStudentList.reduce((a, { currentClassId, currentDivisionId, currentClassMr, currentClassEn,
            currentDivisionMr, currentDivisionEn, branchId, sessionYear, sectionId }) => {
            a[currentDivisionId] = a[currentDivisionId] || { currentDivisionId }
            return {
              ...a, ...{
                [currentDivisionId]: {
                  currentDivisionId,
                  currentClassId,
                  currentClassMr,
                  currentClassEn,
                  currentDivisionMr,
                  currentDivisionEn,
                  branchId,
                  sessionYear,
                  sectionId
                }
              }
            }
          }, {})

          const result = Object.values(objIds)
          //console.log(JSON.stringify(result))
          setClassData(result)
          setLoaderOption(false);
        }).catch(err => {
          setLoaderOption(false);
          console.log('get student list err' + err)
        })
      //console.log(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}`)
      // await axios.get(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}`)
      //     .then(res => {
      //         const presentStudentList = res.data.filter(e => e.currentStatus == 0)
      //         setStudentData(presentStudentList);

      //         const objIds = presentStudentList.reduce((a, { currentClassId, currentDivisionId, currentClassMr, currentClassEn,
      //             currentDivisionMr, currentDivisionEn, branchId, sessionYear, sectionId }) => {
      //             a[currentDivisionId] = a[currentDivisionId] || { currentDivisionId }
      //             return {
      //                 ...a, ...{
      //                     [currentDivisionId]: {
      //                         currentDivisionId,
      //                         currentClassId,
      //                         currentClassMr,
      //                         currentClassEn,
      //                         currentDivisionMr,
      //                         currentDivisionEn,
      //                         branchId,
      //                         sessionYear,
      //                         sectionId
      //                     }
      //                 }
      //             }
      //         }, {})

      //         const result = Object.values(objIds)
      //         //console.log(JSON.stringify(result))
      //         setClassData(result)
      //     }).catch(err => {
      //         console.log('get student list err' + err)
      //     })
    }

  }

  const getStudentReportingReport = async (e) => {
    e.preventDefault();
    setLoaderOption(true);
    console.log(`${printStudentReportingAPIURL}?langId=${authUser.branchMedium}&option=${option}&classId=${(option == 3) ? classObj.currentClassId : 0}&divId=${(option == 3) ? classObj.currentDivisionId : 0}&regNo=${(option == 2) ? studentObj.regCode : 0}&dateFrom=${moment(dateFrom).format('YYYY-MM-DD')}&dateTo=${moment(dateTo).format('YYYY-MM-DD')}&year=${authUser.sessionYear}&bid=${authUser.branchId}`)
    if (moment(dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment(dateTo).format('DD/MM/YYYY') != 'Invalid date') {
      await axios.get(`${printStudentReportingAPIURL}?langId=${authUser.branchMedium}&option=${option}&classId=${(option == 3) ? classObj.currentClassId : 0}&divId=${(option == 3) ? classObj.currentDivisionId : 0}&regNo=${(option == 2) ? studentObj.regCode : 0}&dateFrom=${moment(dateFrom).format('YYYY-MM-DD')}&dateTo=${moment(dateTo).format('YYYY-MM-DD')}&year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        .then(res => {
          if (res.data == "NOTFOUND") {
            setLoaderOption(false);
            toast.warn("Data not found.");
          }
          else {
            window.open(`${getExportedReportAPIURL}${res.data}`);
            setLoaderOption(false);
          }
        }).catch(err => {
          console.log("Student reporting report err" + err);
          setLoaderOption(false);
          toast.error("Something went wrong, please check.")
        })
    }
  }

  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
              {/* Form Data start */}
              <form onSubmit={getStudentReportingReport}>
                <div className="form-data mt-1">
                  <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                  <div className="row">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                    <div className='col-sm-3'>
                      <FormControl fullWidth margin='dense'>
                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                        <Select
                          size='small'
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={option}
                          label="Select Option"
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          onChange={(e) => {
                            setOption(e.target.value);
                            setStudentObj(null);
                            setClassObj(null);
                          }}
                          onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                        >
                          <MenuItem value={1}>All</MenuItem>
                          <MenuItem value={2}>Student Wise</MenuItem>
                          <MenuItem value={3}>Class Wise</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {(() => {
                    if (option == 2) {
                      return (
                        <>
                          <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                            <div className='col-sm-4'>
                              <Autocomplete
                                id="combo-box-demo"

                                options={studentData}
                                PaperComponent={({ children }) => (
                                  <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                  setStudentObj(newValue);
                                }}
                                onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                value={studentObj}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}

                                size="small"
                                renderInput={params => (
                                  <TextField {...params}

                                    margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                    required={(option == 2) ? true : false} />
                                )}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }
                    else if (option == 3) {
                      return (
                        <>
                          <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                            <div className='col-sm-3'>
                              <Autocomplete
                                id="combo-box-demo"

                                options={classData}
                                PaperComponent={({ children }) => (
                                  <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                  setClassObj(newValue);
                                }}
                                onKeyPress={(e) => (e.key == "Enter") ? classObj : ""}
                                value={classObj}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.currentClassMr + "  " + option.currentDivisionMr : option.currentClassEn + "  " + option.currentDivisionEn}

                                size="small"
                                renderInput={params => (
                                  <TextField {...params}
                                    margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                    required={(option == 3) ? true : false} />
                                )}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }

                  })()}

                  <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date From :</label>
                    <div className='col-sm-3'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date From"
                          value={dateFrom}
                          onChange={(newValue) => {
                            setDateFrom(newValue);
                          }}
                          onKeyPress={(e) => (e.key == "Enter") ? dateFrom : ""}
                          inputFormat="dd/MM/yyyy"
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              margin='dense'
                              variant="outlined"
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              required
                              fullWidth
                              autoComplete='off'
                              size="small" />}
                        />
                      </LocalizationProvider>
                    </div>


                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >To :</label>
                    <div className='col-sm-3'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="To"
                          value={dateTo}
                          onChange={(newValue) => {
                            setDateTo(newValue);
                          }}
                          onKeyPress={(e) => (e.key == "Enter") ? dateTo : ""}
                          inputFormat="dd/MM/yyyy"
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              margin='dense'
                              variant="outlined"
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              required
                              fullWidth
                              autoComplete='off'
                              size="small" />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-4 offset-sm-2">
                      <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
      }
    </>
  )
}

export default StudentReportingReport

