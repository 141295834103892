const InventoryStoreJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/StoreDashboard",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        DropDownSubMenu: [
            {
                id: 1301,
                SubPath: "/Home/sm1301/",
                SubName: "Item Category Master"
            },
            {
                id: 1302,
                SubPath: "/Home/sm1302/",
                SubName: "Item Master"
            },
            {
                id: 1303,
                SubPath: "/Home/sm1303/",
                SubName: "Vendor Master"
            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Transaction",
        LinkIcon: "monetization_on",
        DropDownSubMenu: [
            {
                id: 1304,
                SubPath: "/Home/st1304/",
                SubName: "Opening Item"
            },
            {
                id: 1305,
                SubPath: "/Home/st1305/",
                SubName: "Item Inward"
            },
            {
                id: 1306,
                SubPath: "/Home/st1306/",
                SubName: "Item Outward"
            },
        ]
    },
]

export default InventoryStoreJSON;