import React, { useEffect, useState } from 'react';
import './BudgetDashboard.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCategoryBySectionAndBranch } from './BudgetReports/Services/BudgetReportAPIURL';
import axios from 'axios';

const colorDetails = [
    {
        id: 1,
        background1: "00d2ff",
        background2: "3a7bd5",
    },
    {
        id: 2,
        background1: "f2709c",
        background2: "ff9472",
    },
    {
        id: 3,
        background1: "B58ECC",
        background2: "5DE6DE",
    },
    {
        id: 4,
        background1: "E975A8",
        background2: "726CF8",
    },
    {
        id: 5,
        background1: "FF928B",
        background2: "FFAC81",
    },
    {
        id: 6,
        background1: "83EAF1",
        background2: "63A4FF",
    },
    {
        id: 7,
        background1: "EBF928",
        background2: "D425B5",
    },
    {
        id: 8,
        background1: "E58C8A",
        background2: "EEC0C6",
    }
]

const BudgetDashboard = () => {
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);

    const [categoryData, setCategoryData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            //console.log(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    let newData = res.data.filter(e => e.categoryType != 2)
                    let categoryDataWithColor = newData.map((item, i) => Object.assign({}, item, colorDetails[i]));
                    //console.log(JSON.stringify(categoryDataWithColor))
                    setCategoryData(categoryDataWithColor);
                })
        }
    }

    const backgroundColors = ["0077c2", "1890c8", "4ea1d3", "82b2dd", "b6c3e7", "fba100", "ffaf42", "ffc98b", "ffe2c6", "fff2e8"];

    return (
        <>
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                <div className="row">
                    <div className="col-md-3">
                        <div className="card bg-c-first card-height">
                            <div class="card-body">
                                <h5 class="card-title">एकत्रित बजेट</h5>
                            </div>
                            <div class="card-footer text-muted"
                                style={{
                                    // backgroundColor: `#FB8085`,
                                    // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                    height: "35px",
                                    borderTopLeftRadius: "20px",
                                    borderTopRightRadius: "20px",
                                    textAlign: "center",
                                    padding: "5px",
                                    cursor: "pointer"
                                }}
                            >
                                <div className='' onClick={() => navigate('/Home/br1010/')}>
                                    <span className="badge rounded-pill badge-text-first">View Report <ArrowForwardIcon fontSize='small' /></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        categoryData.map((e, index) => {
                            {/* const i = index % backgroundColors.length;
                            const background1 = backgroundColors[i];
                            const background2 = backgroundColors[(i + 5) % backgroundColors.length]; */}

                            return (
                                <>
                                    <div className="col-md-3" key={index}>
                                        <div className="card card-height"
                                            style={{
                                                backgroundColor: `#${e.background1}`,
                                                backgroundImage: `linear-gradient(315deg, #${e.background1} 0%, #${e.background2} 74%)`,
                                            }}
                                        >
                                            <div class="card-body">
                                                <h5 class="card-title">{e.nameMr}</h5>
                                            </div>
                                            <div class="card-footer text-muted"
                                                style={{
                                                    // backgroundColor: `#FB8085`,
                                                    // backgroundImage: `linear-gradient(315deg, #FB8085 0%, #F9C1B1 74%)`,
                                                    height: "35px",
                                                    borderTopLeftRadius: "20px",
                                                    borderTopRightRadius: "20px",
                                                    textAlign: "center",
                                                    padding: "5px",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <div className='' onClick={() => navigate('/Home/br1010/')}>
                                                    <span className="badge rounded-pill badge-text-first">View Report <ArrowForwardIcon fontSize='small' /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default BudgetDashboard