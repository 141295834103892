import { Autocomplete, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Loader from '../../../CommonComponent/Loader';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate, useParams } from 'react-router-dom';
import { getStudentListWithSeatNo, getSubjectDataByClassId, saveBoardExamMarkEntryAPIURL } from '../../Services/BoardExamMarkEntryAPIURL';
import axios from 'axios';
import BoardExamEntryInput from './BoardExamInput/BoardExamEntryInput';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const markTypeData = [
    {
        id: 1,
        markTypeName: "Mark"
    },
    {
        id: 2,
        markTypeName: "Grade"
    },
]


const BoardExamMarkEntry = () => {
    const titleId = "Board Exam Mark Entry"
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    const navigate = useNavigate();

    const { sNo, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "boardExamEntrySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "boardExamEntryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [loaderOption, setLoaderOption] = useState(false);
    const [id, setId] = useState('');
    const [seatNo, setSeatNo] = useState('');
    const [studData, setStudData] = useState([]);
    const [studObj, setStudObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [maxMark, setMaxMark] = useState('');
    const [obtainedMarks, setObtainedMarks] = useState('');
    const [markSystem, setMarkSystem] = useState('');

    const [totalOtained, setTotalObtained] = useState(0);
    const [totalMaxMark, setTotalMaxMark] = useState(0);
    const [percentage, setPercentage] = useState(0.00);

    const [bulkMaxMark, setBulkMaxMark] = useState(0);

    useEffect(() => {
        getData();

        if (EditFlag == 2) {
            getEditDetails();
        }
    }, [authUser])


    const getData = async () => {
        if (authUser.sessionnYear != '' && authUser.deptId != null && authUser.branchId != null) {
            //console.log(`${getStudentListWithSeatNo}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getStudentListWithSeatNo}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setStudData(res.data);
                }).catch(err => {
                    console.log("Student with seat no" + err);
                })
        }
    }

    //get subjects by class div
    const getSubjectListByClassDiv = async (cId, dId, rNo) => {
        setTotalObtained(0);
        setTotalMaxMark(0);
        setPercentage(0);
        setSubjectData([]);
        console.log(`${getSubjectDataByClassId}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bmedium=${authUser.branchMedium}&classId=${cId}&divId=${dId}&bid=${authUser.branchId}&regNo=${rNo}`);
        await axios.get(`${getSubjectDataByClassId}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bmedium=${authUser.branchMedium}&classId=${cId}&divId=${dId}&bid=${authUser.branchId}&regNo=${rNo}`)
            .then(res => {
                setSubjectData(res.data);

                res.data.map(e => {
                    setTotalObtained(e.totalObtainMark);
                    setTotalMaxMark(e.totalMaxMrk);
                    setPercentage(parseFloat(e.percentage).toFixed('2'));
                })
            }).catch(err => {
                console.log("subject by class-div" + err);
            })
    }

    //get student details on edit
    const getEditDetails = async () => {
        setLoaderOption(true);
        setTotalObtained(0);
        setTotalMaxMark(0);
        setPercentage(0);
        setSubjectData([]);
        setSeatNo(sNo);
        await axios.get(`${getStudentListWithSeatNo}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setStudData(res.data);
                setStudObj(res.data.find(e => e.boardSeatNo == sNo));

                let newData = res.data.filter(e => e.boardSeatNo == sNo)

                newData.map(e => {
                    axios.get(`${getSubjectDataByClassId}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bmedium=${authUser.branchMedium}&classId=${e.classId}&divId=${e.divisionId}&bid=${authUser.branchId}&regNo=${e.regNo}`)
                        .then(res => {
                            setSubjectData(res.data);

                            res.data.map(e => {
                                setTotalObtained(e.totalObtainMark);
                                setTotalMaxMark(e.totalMaxMrk);
                                setPercentage(parseFloat(e.percentage).toFixed('2'));
                            })

                            setLoaderOption(false);
                        }).catch(err => {
                            setLoaderOption(false);
                            console.log("subject by class-div" + err);
                        })
                })


            }).catch(err => {
                console.log("Student with seat no" + err);
            })
        // console.log(`${getSubjectDataByClassId}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bmedium=${authUser.branchMedium}&classId=${studObj.classId}&divId=${studObj.divisionId}&bid=${authUser.branchId}&regNo=${sNo}`);

    }

    //apply same max marks to all subjects
    const applyBulkMaxMark = async () => {
        setLoaderOption(true);
        setBulkMaxMark(0);
        await axios.get(`${getSubjectDataByClassId}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bmedium=${authUser.branchMedium}&classId=${studObj.classId}&divId=${studObj.divisionId}&bid=${authUser.branchId}&regNo=${studObj.regNo}`)
            .then(res => {
                let updateData = res.data;
                res.data.map((e, index) => {
                    updateData[index] = ({ ...e, maxMarks: bulkMaxMark })
                })

                console.log(JSON.stringify(updateData))

                setSubjectData(updateData);

                updateData.map(e => {
                    setTotalObtained(e.totalObtainMark);
                    setTotalMaxMark(e.totalMaxMrk);
                    setPercentage(parseFloat(e.percentage).toFixed('2'));
                })

                setLoaderOption(false);
            }).catch(err => {
                console.log("subject by class-div" + err);
            })
    }

    //get student name and subjects by seat no
    const getStudentNameBySeatNo = async (seatNo) => {
        setStudObj(studData.find(e => e.boardSeatNo == seatNo));

        let newData = studData.filter(e => e.boardSeatNo == seatNo)

        newData.map(e => {
            axios.get(`${getSubjectDataByClassId}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bmedium=${authUser.branchMedium}&classId=${e.classId}&divId=${e.divisionId}&bid=${authUser.branchId}&regNo=${e.regNo}`)
                .then(res => {
                    setSubjectData(res.data);

                    res.data.map(e => {
                        setTotalObtained(e.totalObtainMark);
                        setTotalMaxMark(e.totalMaxMrk);
                        setPercentage(parseFloat(e.percentage).toFixed('2'));
                    })

                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("subject by class-div" + err);
                })
        })
    }

    //save exam entry
    const saveBoardExamMarkEntry = async () => {
        // /setLoaderOption(true);
        let updatedData = subjectData
        subjectData.map((e, index) => {
            updatedData[index] = ({
                ...e, regNo: studObj.regNo, boardSeatNo: studObj.boardSeatNo,
                totalObtainMark: totalOtained,
                totalMaxMrk: totalMaxMark,
                percentage: percentage
            })
        })
        console.log(JSON.stringify(updatedData))
        await axios.post(saveBoardExamMarkEntryAPIURL, updatedData)
            .then(res => {
                if (res.data == "SAVED") {
                    setLoaderOption(false);
                    navigate('/Home/ex539/');
                    toast.success('Board exam entry done successfully.')
                }
                else {
                    setLoaderOption(false);
                    toast.error("Board exam entry save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Board exam entry save " + err);
                toast.error("Something went wrong, please check.")
            })

    }

    const state = {
        button: 1
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.button == 1) {
            saveBoardExamMarkEntry();
        }
    }


    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (subjectData == null || subjectData == "" || subjectData == []) {
                    event.preventDefault();
                    let btn = document.querySelector('.boardExamEntrySaveBtn')
                    if (btn !== null) {
                        console.log('Enter is pressed!');
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }


    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row mb-5">
                                        <label className="" style={{ width: "140px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Seat No :</label>
                                        <div className='col-sm-2'>
                                            <TextField
                                                required={(EditFlag == 1) ? true : false}
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && seatNo.length === 0) ? e.preventDefault() : ''}
                                                autoComplete='off'
                                                className=''
                                                size='small'
                                                id="outlined-basic"
                                                label="Seat No"
                                                variant="outlined"
                                                margin='dense'
                                                onWheel={(e) => e.target.blur()}
                                                value={seatNo}
                                                onChange={(e) => {
                                                    setSeatNo(e.target.value);
                                                    setStudObj(null);
                                                    setTotalObtained(0);
                                                    setTotalMaxMark(0);
                                                    setPercentage(0);
                                                    setSubjectData([]);
                                                    setSubjectObj(null);
                                                    setMaxMark('');
                                                    setObtainedMarks('');
                                                    setMarkSystem('');
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? seatNo : ""}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                InputProps={{
                                                    readOnly: (EditFlag == 2) ? true : false,
                                                }}
                                                onBlur={(e) => getStudentNameBySeatNo(e.target.value)}
                                            />
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                        <div className='col-sm-5'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                readOnly={(EditFlag == 2) ? true : false}
                                                options={studData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setStudObj(newValue);
                                                    setSubjectObj(null);
                                                    setSeatNo('');
                                                    setSubjectData([]);
                                                    setMaxMark('');
                                                    setObtainedMarks('');
                                                    setMarkSystem('');
                                                    if (newValue != null) {
                                                        setSeatNo(newValue.boardSeatNo);
                                                        getSubjectListByClassDiv(newValue.classId, newValue.divisionId, newValue.regNo)
                                                    }
                                                }}
                                                value={studObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.boardSeatNo + " - " + ((authUser.branchMedium == 1) ? option.studentNameMr + " " + option.classNameMr + " " + option.divNameMr : option.studentNameEn + " " + option.classNameEn + " " + option.divNameEn)}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(EditFlag == 1) ? true : false} />
                                                )}
                                            />
                                        </div>

                                        <div className="col-sm-2 mt-3">
                                            <button type='button' onClick={() => navigate('/Home/ex539/')} className='btn btn-sm btn-danger ml-2' hidden={(EditFlag == 2) ? true : false}>Back</button>
                                        </div>
                                    </div>

                                    {(() => {
                                        if (subjectData.length != 0) {
                                            return (
                                                <>
                                                    <div className="row mb-4">
                                                        <div className="col-sm-8">
                                                            <div className="">
                                                                <div
                                                                // style={{
                                                                //     backgroundColor: "#4884EE",
                                                                //     backgroundImage: "linear-gradient(315deg, #4884EE 0%, #06BCFB 74%)",
                                                                //     color: "#fff", boxShadow: "2px 5px 10px #7F8C8D"
                                                                // }}
                                                                //     background-color: #0499f2;
                                                                // background-image: linear-gradient(315deg, #0499f2 0%, # 74%);
                                                                >
                                                                    <div className='table-responsive' style={{ maxHeight: "450px" }}>
                                                                        <table className="table table-bordered">
                                                                            <thead className="" style={{
                                                                                position: "sticky", zIndex: "2", overflow: "hidden",
                                                                                top: "0",
                                                                                backgroundColor: "#fff",
                                                                                // backgroundImage: "linear-gradient(315deg, #4884EE 0%, #06BCFB 74%)",
                                                                                // color: "#fff", boxShadow: "2px 5px 10px #7F8C8D"
                                                                            }}>
                                                                                <tr style={{ border: "none" }}>
                                                                                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}></td>
                                                                                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}></td>
                                                                                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}></td>
                                                                                    <td style={{ border: "none", padding: "4px 16px", textAlign: "center" }}>
                                                                                        <input
                                                                                            type="number"
                                                                                            value={bulkMaxMark}
                                                                                            onChange={(e) => { setBulkMaxMark(e.target.value) }}
                                                                                            className='form-control form-control-sm mb-2'
                                                                                            style={{ width: "120px", fontSize: "14px", fontWeight: "600", padding: "4px", border: '1px solid #000' }}
                                                                                            min="0"
                                                                                            onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ border: "none", padding: "4px 16px" }}>
                                                                                        <button type='button' className='btn btn-sm btn-warning mb-2' onClick={() => applyBulkMaxMark()}>Apply</button>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style={{ backgroundColor: "#E0E0E0", borderTop: "2px solid #ADADAD", }}>
                                                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Id</th>
                                                                                    <th style={{ fontSize: "15px", fontWeight: "600", width: "180px" }}>Subject</th>
                                                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Mark System</th>
                                                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Max Mark</th>
                                                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Obtain Mark</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className='table-sm'>
                                                                                {subjectData.map((item, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <tr>
                                                                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                                                                    {index + 1}
                                                                                                </td>
                                                                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                                                                    {(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}
                                                                                                </td>

                                                                                                <BoardExamEntryInput markTypeData={markTypeData}
                                                                                                    initialText1={item.markSystem}
                                                                                                    initialText2={item.maxMarks}
                                                                                                    initialText3={(item.markSystem == 1) ? item.obtainedMarks : item.grade}

                                                                                                    onChange={(value1, value2, value3) => {
                                                                                                        let updatedData = subjectData;
                                                                                                        updatedData[index] = ({
                                                                                                            ...item,
                                                                                                            markSystem: value1,
                                                                                                            maxMarks: value2,
                                                                                                            obtainedMarks: (value1 == 2) ? 0 : value3,
                                                                                                            grade: (value1 == 1) ? '' : value3
                                                                                                        });
                                                                                                        setSubjectData(updatedData);
                                                                                                        console.log(JSON.stringify(updatedData));

                                                                                                        const TotalMaxMarks = updatedData.reduce((prev, curr) => prev + parseFloat(curr.maxMarks), 0);
                                                                                                        setTotalMaxMark(parseFloat(TotalMaxMarks))

                                                                                                        const TotalObtainMarks = updatedData.reduce((prev, curr) => prev + parseFloat(curr.obtainedMarks), 0);
                                                                                                        setTotalObtained(parseFloat(TotalObtainMarks))

                                                                                                        let getPercentage = parseFloat(TotalObtainMarks) / parseFloat(TotalMaxMarks) * 100
                                                                                                        setPercentage(parseFloat(getPercentage).toFixed('2'))
                                                                                                    }} />
                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4 mt-5">

                                                            <div className="row mb-1 mt-4">
                                                                <div className="">
                                                                    <h4 style={{
                                                                        textAlign: "left",
                                                                        borderBottom: "1px solid #5D6D7E",
                                                                        lineHeight: "0.1em",
                                                                        margin: "10px 0 20px",
                                                                        color: "#0543E7",
                                                                    }}><span style={{
                                                                        background: "#fff",
                                                                        padding: "0 8px",
                                                                    }}>Total Marks</span></h4>
                                                                </div>
                                                            </div>

                                                            <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Total Obtained :</label>

                                                            <TextField
                                                                value={totalOtained}
                                                                onChange={(e) => setTotalObtained(e.target.value)}
                                                                fullWidth
                                                                required
                                                                type={(markSystem == 1) ? 'number' : 'text'}
                                                                onWheel={(e) => e.target.blur()}
                                                                style={{ width: '200px' }}
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Total obtained"
                                                                variant="outlined"
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />

                                                            <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Total Max :</label>
                                                            <TextField
                                                                value={totalMaxMark}
                                                                onChange={(e) => setTotalMaxMark(e.target.value)}
                                                                style={{ width: '200px', marginTop: '10px' }}
                                                                fullWidth
                                                                required
                                                                type='number'
                                                                onWheel={(e) => e.target.blur()}
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Total Max"
                                                                variant="outlined"
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />

                                                            <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Percentage :</label>
                                                            <TextField
                                                                value={percentage}
                                                                onChange={(e) => setPercentage(e.target.value)}
                                                                style={{ width: '200px', marginTop: '10px' }}
                                                                fullWidth
                                                                required
                                                                type='number'
                                                                onWheel={(e) => e.target.blur()}
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Percentage"
                                                                variant="outlined"
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                inputProps={{ maxLength: 120 }}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="row mt-4">
                                                        <div className="col-sm-4">
                                                            <button type='submit' disabled={saveBtnDisabled} className={saveBtn} onClick={() => (state.button = 1)}>Save Changes</button>
                                                            <button type='submit' disabled={updateBtnDisabled} className={updateBtn}>Update Changes</button>
                                                            <button type='button' hidden={(EditFlag == 2) ? true : false} onClick={() => { setSubjectData([]); setStudObj(null); setSeatNo(''); }} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                                            <button type='button' onClick={() => navigate('/Home/ex539/')} className='btn btn-sm btn-danger ml-2' hidden={(EditFlag == 1) ? true : false}>Back</button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default BoardExamMarkEntry