import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import BudgetAllSchoolDepartmentWiseReportTable from './BudgetConsolidatedReportTables/BudgetAllSchoolDepartmentWiseReportTable';
import {
    getBudgetDetailAllSchoolReceiptDetail, getBudgetDetailAllSchoolPaymentDetail,
    getBudgetDetailAllSessionPaymentDetail, getBudgetDetailAllsessionReceiptDetail,
    printBudgetDetailAllSchoolReceiptDetail, printBudgetDetailAllSchoolPaymentDetail,
    printBudgetDetailAllsessionReceiptDetail, printBudgetDetailAllSessionPaymentDetail,
    getDepartmentWiseAllSchoolReceiptDetail, getDepartmentWiseAllSchoolPaymentDetail,
    getDepartmentWiseAllSessionReceiptDetail, getDepartmentWiseAllSessionPaymentDetail,
    printDepartmentWiseAllSchoolReceiptDetail, printDepartmentWiseAllSchoolPaymentDetail,
    printDepartmentWiseAllSessionReceiptDetail, printDepartmentWiseAllSessionPaymentDetail,
    getDepartmentListForBudget
} from './Services/BudgetReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

//category data
const categoryFixedData = [
    {
        id: 1,
        nameMr: "शाळा किर्द जमा रुपये"
    },
    {
        id: 2,
        nameMr: "सत्र फी किर्द जमा रुपये"
    },
    {
        id: 3,
        nameMr: "शाळा किर्द खर्च रुपये"
    },
    {
        id: 4,
        nameMr: "सत्र फी किर्द खर्च रुपये"
    },
]

const BudgetConsolidatedDepartmentWiseReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Budget Consolidated Report";
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [optionId, setOptionId] = useState(1);
    const [categoryData, setCategoryData] = useState(categoryFixedData);
    const [categoryObj, setCategoryObj] = useState(null);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [accountName, setAccountName] = useState([]);

    const [totalAmount, setTotalAmount] = useState([]);

    const [getAccountlength, setGetAccountLength] = useState('');

    const [totalAmountValue, setTotalAmountValue] = useState(0);
    const [totalDefferenceAmountValue, setTotalDifferenceAmountValue] = useState(0);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        await axios.get(`${getDepartmentListForBudget}`)
            .then(res => {
                let updatedData = [{ "id": 0, "nameMr": "सर्व शाळा", "nameEn": "All School" }, ...res.data]
                setDepartmentData(updatedData);
            })
    }

    //get Budget details
    const getBudgetSchoolWiseDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setAccountName([]);
        setData([]);
        setFilteredData([]);
        setGetAccountLength('');
        //console.log(categoryObj.id)

        if (categoryObj.id == 1) {
            //get consolidated report for category school receipt
            if (departmentObj.id == 0) {
                console.log(`${getBudgetDetailAllSchoolReceiptDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                await axios.get(`${getBudgetDetailAllSchoolReceiptDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found");
                        }
                        else {
                            //for get single school name from multiple objects with similar schools
                            const objIds = res.data.reduce((a, { schoolNameMr, amount, feePayment, differenceAmount,
                                optionId, accNameMr, accNameEn }) => {
                                a[schoolNameMr] = a[schoolNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [schoolNameMr]: {
                                            schoolNameMr,
                                            amount: a[schoolNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr,
                                                accNameEn: accNameEn,
                                            }),
                                            feePayment,
                                            differenceAmount,
                                            optionId,
                                        }
                                    }
                                }
                            }, {})
                            // const objIds = res.data.reduce((acc, curr) => {
                            //     const key = `${curr.schoolNameMr}-${curr.branchId}`;
                            //     if (!acc[key]) {
                            //         acc[key] = {
                            //             schoolNameMr: curr.schoolNameMr,
                            //             optionId: curr.optionId,
                            //             feePayment: curr.feePayment,
                            //             differenceAmount: curr.differenceAmount,
                            //             branchId: curr.branchId,
                            //             amount: []
                            //         };
                            //     }
                            //     acc[key].amount.push({
                            //         accNameMr: curr.accNameMr,
                            //         accNameEn: curr.accNameEn,
                            //         amount: curr.amount
                            //     });
                            //     return acc;
                            // }, {});

                            const result = Object.values(objIds)
                            console.log(JSON.stringify(result));
                            setData(result);
                            setFilteredData(result);

                            //for making total of all amounts
                            let newFilterData = result
                            newFilterData.map((e, index) => {
                                newFilterData[index] = ({ ...e, totalAmount: e.amount.reduce((prev, curr) => prev + parseFloat(curr.amount), 0) })
                            })

                            let newTotalAmount = newFilterData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0)
                            //console.log(JSON.stringify(newTotalAmount))
                            setTotalAmountValue(newTotalAmount)

                            //for total of difference amount
                            const TotalDifferenceValue = result.reduce((prev, curr) => prev + parseFloat(curr.differenceAmount), 0);
                            setTotalDifferenceAmountValue(TotalDifferenceValue);

                            //for getting single account name from multiple objects with similat accounts
                            const objIds1 = res.data.reduce((a, { accNameMr, accNameEn, amount, totalAmount }) => {
                                a[accNameMr] = a[accNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [accNameMr]: {
                                            accNameMr,
                                            accNameEn,
                                            amount: a[accNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr
                                            }),
                                        }
                                    }
                                }
                            }, {})

                            const result2 = Object.values(objIds1)
                            setGetAccountLength(result2.length)

                            //total of amount with similar account name
                            let newResult = result2.map(e => {
                                return e.amount
                            })

                            let newResult2 = newResult.reduce((acc, curr) => acc.concat(curr.map((e) => ({ ...e }))), [])

                            let summedAges = newResult2.reduce((a, c) => {
                                let filtered = a.filter(el => el.accNameMr === c.accNameMr);
                                if (filtered.length > 0) {
                                    a[a.indexOf(filtered[0])].amount += +c.amount;
                                } else {
                                    a.push(c);
                                }
                                return a;
                            }, []);
                            //console.log(JSON.stringify(summedAges))

                            setTotalAmount(summedAges);


                            //Table heading for consolidated report
                            let newTableHeading = ([...result2,
                            { accNameMr: "एकूण", accNameEn: "Total" },
                            { accNameMr: "तूट", accNameEn: "Deficit" },
                            { accNameMr: "सर्व एकूण", accNameEn: "All total" },
                            ])

                            setAccountName(newTableHeading)

                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("sanstha Budget Consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //get department wise report for category school receipt
            else {
                console.log(`${getDepartmentWiseAllSchoolReceiptDetail}?sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                await axios.get(`${getDepartmentWiseAllSchoolReceiptDetail}?sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                    .then(res => {
                        if (res.data == "NOTFOUND" || res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found");
                        }
                        else {
                            //for get single school name from multiple objects with similar schools
                            const objIds = res.data.reduce((a, { schoolNameMr, amount, feePayment, differenceAmount,
                                optionId, accNameMr, accNameEn }) => {
                                a[schoolNameMr] = a[schoolNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [schoolNameMr]: {
                                            schoolNameMr,
                                            amount: a[schoolNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr,
                                                accNameEn: accNameEn,
                                            }),
                                            feePayment,
                                            differenceAmount,
                                            optionId,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)
                            setData(result);
                            setFilteredData(result);

                            //for making total of all amounts
                            let newFilterData = result
                            newFilterData.map((e, index) => {
                                newFilterData[index] = ({ ...e, totalAmount: e.amount.reduce((prev, curr) => prev + parseFloat(curr.amount), 0) })
                            })

                            let newTotalAmount = newFilterData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0)
                            //console.log(JSON.stringify(newTotalAmount))
                            setTotalAmountValue(newTotalAmount)

                            //for total of difference amount
                            const TotalDifferenceValue = result.reduce((prev, curr) => prev + parseFloat(curr.differenceAmount), 0);
                            setTotalDifferenceAmountValue(TotalDifferenceValue);

                            //for getting single account name from multiple objects with similat accounts
                            const objIds1 = res.data.reduce((a, { accNameMr, accNameEn, amount, totalAmount }) => {
                                a[accNameMr] = a[accNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [accNameMr]: {
                                            accNameMr,
                                            accNameEn,
                                            amount: a[accNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr
                                            }),
                                        }
                                    }
                                }
                            }, {})

                            const result2 = Object.values(objIds1)
                            setGetAccountLength(result2.length)

                            //total of amount with similar account name
                            let newResult = result2.map(e => {
                                return e.amount
                            })

                            let newResult2 = newResult.reduce((acc, curr) => acc.concat(curr.map((e) => ({ ...e }))), [])

                            let summedAges = newResult2.reduce((a, c) => {
                                let filtered = a.filter(el => el.accNameMr === c.accNameMr);
                                if (filtered.length > 0) {
                                    a[a.indexOf(filtered[0])].amount += +c.amount;
                                } else {
                                    a.push(c);
                                }
                                return a;
                            }, []);
                            //console.log(JSON.stringify(summedAges))

                            setTotalAmount(summedAges);

                            //Table heading for department wise report
                            let newTableHeading = ([...result2,
                            { accNameMr: "एकूण", accNameEn: "Total" },
                            { accNameMr: "तूट", accNameEn: "Deficit" },
                            { accNameMr: "सर्व एकूण", accNameEn: "All total" },
                            ])

                            setAccountName(newTableHeading)


                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("sanstha Budget Consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
        else if (categoryObj.id == 2) {
            //get consolidated report for category session receipt
            if (departmentObj.id == 0) {
                await axios.get(`${getBudgetDetailAllsessionReceiptDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found");
                        }
                        else {
                            //for get single school name from multiple objects with similar schools
                            const objIds = res.data.reduce((a, { schoolNameMr, amount, feePayment, differenceAmount,
                                optionId, accNameMr, accNameEn, branchId }) => {
                                a[schoolNameMr] = a[schoolNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [schoolNameMr]: {
                                            schoolNameMr,
                                            amount: a[schoolNameMr].amount.concat({
                                                schoolNameMr: schoolNameMr,
                                                amount: amount,
                                                accNameMr: accNameMr,
                                                accNameEn: accNameEn,
                                            }),
                                            feePayment,
                                            differenceAmount,
                                            optionId,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)
                            setData(result);
                            setFilteredData(result);
                            //console.log(JSON.stringify(result));

                            //for making total of all amounts
                            let newFilterData = result
                            newFilterData.map((e, index) => {
                                newFilterData[index] = ({ ...e, totalAmount: e.amount.reduce((prev, curr) => prev + parseFloat(curr.amount), 0) })
                            })

                            let newTotalAmount = newFilterData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0)
                            //console.log(JSON.stringify(newTotalAmount))
                            setTotalAmountValue(newTotalAmount)

                            //for total of difference amount
                            const TotalDifferenceValue = result.reduce((prev, curr) => prev + parseFloat(curr.differenceAmount), 0);
                            setTotalDifferenceAmountValue(TotalDifferenceValue);

                            //for getting single account name from multiple objects with similat accounts
                            const objIds1 = res.data.reduce((a, { accNameMr, accNameEn, amount, totalAmount }) => {
                                a[accNameMr] = a[accNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [accNameMr]: {
                                            accNameMr,
                                            accNameEn,
                                            amount: a[accNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr
                                            }),
                                        }
                                    }
                                }
                            }, {})

                            const result2 = Object.values(objIds1)
                            setGetAccountLength(result2.length)
                            //console.log(JSON.stringify(result2))

                            //total of amount with similar account name
                            let newResult = result2.map(e => {
                                return e.amount
                            })

                            let newResult2 = newResult.reduce((acc, curr) => acc.concat(curr.map((e) => ({ ...e }))), [])

                            let summedAges = newResult2.reduce((a, c) => {
                                let filtered = a.filter(el => el.accNameMr === c.accNameMr);
                                if (filtered.length > 0) {
                                    a[a.indexOf(filtered[0])].amount += +c.amount;
                                } else {
                                    a.push(c);
                                }
                                return a;
                            }, []);
                            //console.log(JSON.stringify(summedAges))

                            setTotalAmount(summedAges);

                            //Table heading for consolidated report
                            let newTableHeading = ([...result2,
                            { accNameMr: "एकूण", accNameEn: "Total" },
                            { accNameMr: "तूट", accNameEn: "Deficit" },
                            { accNameMr: "सर्व एकूण", accNameEn: "All total" },
                            ])

                            setAccountName(newTableHeading);
                            //console.log(newTableHeading)                        
                            let newResult1 = result.map(e => {
                                return e.amount
                            })

                            let res2 = result.reduce((acc, curr) => acc.concat(curr.amount.map((e) => ({ ...e }))), [])

                            let check = newTableHeading.map(element => (element.accNameMr) == (res2.some(item => item.accNameMr)));
                            //console.log(JSON.stringify(check));                       

                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("sanstha Budget Consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //get department wise report for category session receipt
            else {
                //console.log(`${getDepartmentWiseAllSessionReceiptDetail}?sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                await axios.get(`${getDepartmentWiseAllSessionReceiptDetail}?sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                    .then(res => {
                        if (res.data == "NOTFOUND" || res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found");
                            console.log(res.data);
                        }
                        else {
                            //for get single school name from multiple objects with similar schools
                            const objIds = res.data.reduce((a, { schoolNameMr, amount, feePayment, differenceAmount,
                                optionId, accNameMr, accNameEn, branchId }) => {
                                a[schoolNameMr] = a[schoolNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [schoolNameMr]: {
                                            schoolNameMr,
                                            amount: a[schoolNameMr].amount.concat({
                                                schoolNameMr: schoolNameMr,
                                                amount: amount,
                                                accNameMr: accNameMr,
                                                accNameEn: accNameEn,
                                            }),
                                            feePayment,
                                            differenceAmount,
                                            optionId,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)
                            setData(result);
                            setFilteredData(result);
                            //console.log(JSON.stringify(result));

                            //for making total of all amounts
                            let newFilterData = result
                            newFilterData.map((e, index) => {
                                newFilterData[index] = ({ ...e, totalAmount: e.amount.reduce((prev, curr) => prev + parseFloat(curr.amount), 0) })
                            })

                            let newTotalAmount = newFilterData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0)
                            //console.log(JSON.stringify(newTotalAmount))
                            setTotalAmountValue(newTotalAmount)

                            //for total of difference amount
                            const TotalDifferenceValue = result.reduce((prev, curr) => prev + parseFloat(curr.differenceAmount), 0);
                            setTotalDifferenceAmountValue(TotalDifferenceValue);

                            //for getting single account name from multiple objects with similat accounts
                            const objIds1 = res.data.reduce((a, { accNameMr, accNameEn, amount, totalAmount }) => {
                                a[accNameMr] = a[accNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [accNameMr]: {
                                            accNameMr,
                                            accNameEn,
                                            amount: a[accNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr
                                            }),
                                        }
                                    }
                                }
                            }, {})

                            const result2 = Object.values(objIds1)
                            setGetAccountLength(result2.length)
                            console.log(JSON.stringify(result2))

                            //total of amount with similar account name
                            let newResult = result2.map(e => {
                                return e.amount
                            })

                            let newResult2 = newResult.reduce((acc, curr) => acc.concat(curr.map((e) => ({ ...e }))), [])

                            let summedAges = newResult2.reduce((a, c) => {
                                let filtered = a.filter(el => el.accNameMr === c.accNameMr);
                                if (filtered.length > 0) {
                                    a[a.indexOf(filtered[0])].amount += +c.amount;
                                } else {
                                    a.push(c);
                                }
                                return a;
                            }, []);
                            //console.log(JSON.stringify(summedAges))

                            setTotalAmount(summedAges);

                            //Table heading for department wise report
                            let newTableHeading = ([...result2,
                            { accNameMr: "एकूण", accNameEn: "Total" },
                            { accNameMr: "तूट", accNameEn: "Deficit" },
                            { accNameMr: "सर्व एकूण", accNameEn: "All total" },
                            ])

                            setAccountName(newTableHeading);
                            //console.log(newTableHeading)                        
                            let newResult1 = result.map(e => {
                                return e.amount
                            })

                            let res2 = result.reduce((acc, curr) => acc.concat(curr.amount.map((e) => ({ ...e }))), [])

                            let check = newTableHeading.map(element => (element.accNameMr) == (res2.some(item => item.accNameMr)));
                            //console.log(JSON.stringify(check));                       

                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("sanstha Budget Consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
        else if (categoryObj.id == 3) {
            //get consolidated report for category school payment
            if (departmentObj.id == 0) {
                await axios.get(`${getBudgetDetailAllSchoolPaymentDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found");
                        }
                        else {
                            //for get single school name from multiple objects with similar schools
                            const objIds = res.data.reduce((a, { schoolNameMr, amount, feePayment, differenceAmount,
                                optionId, accNameMr, accNameEn }) => {
                                a[schoolNameMr] = a[schoolNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [schoolNameMr]: {
                                            schoolNameMr,
                                            amount: a[schoolNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr,
                                                accNameEn: accNameEn,
                                            }),
                                            feePayment,
                                            differenceAmount,
                                            optionId,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)
                            setData(result);
                            setFilteredData(result);

                            //for making total of all amounts
                            let newFilterData = result
                            newFilterData.map((e, index) => {
                                newFilterData[index] = ({ ...e, totalAmount: e.amount.reduce((prev, curr) => prev + parseFloat(curr.amount), 0) })
                            })

                            let newTotalAmount = newFilterData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0)
                            //console.log(JSON.stringify(newTotalAmount))
                            setTotalAmountValue(newTotalAmount)

                            //for total of difference amount
                            const TotalDifferenceValue = result.reduce((prev, curr) => prev + parseFloat(curr.differenceAmount), 0);
                            setTotalDifferenceAmountValue(TotalDifferenceValue);

                            //for getting single account name from multiple objects with similat accounts
                            const objIds1 = res.data.reduce((a, { accNameMr, accNameEn, amount, totalAmount }) => {
                                a[accNameMr] = a[accNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [accNameMr]: {
                                            accNameMr,
                                            accNameEn,
                                            amount: a[accNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr,
                                            }),
                                        }
                                    }
                                }
                            }, {})

                            const result2 = Object.values(objIds1)
                            setGetAccountLength(result2.length)
                            //console.log(JSON.stringify(result2.length))

                            //total of amount with similar account name
                            let newResult = result2.map(e => {
                                return e.amount
                            })

                            let newResult2 = newResult.reduce((acc, curr) => acc.concat(curr.map((e) => ({ ...e }))), [])

                            let summedAges = newResult2.reduce((a, c) => {
                                let filtered = a.filter(el => el.accNameMr === c.accNameMr);
                                if (filtered.length > 0) {
                                    a[a.indexOf(filtered[0])].amount += +c.amount;
                                } else {
                                    a.push(c);
                                }
                                return a;
                            }, []);
                            //console.log(JSON.stringify(summedAges))

                            setTotalAmount(summedAges);

                            //Table heading for consolidated report
                            let newTableHeading = ([...result2,
                            { accNameMr: "एकूण खर्च", accNameEn: "All Total" },
                            { accNameMr: "शिल्लक", accNameEn: "Balance" },
                            { accNameMr: "सर्व एकूण", accNameEn: "All total" },
                            ])

                            setAccountName(newTableHeading)

                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("sanstha Budget Consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //get department wise report for category school payment
            else {
                await axios.get(`${getDepartmentWiseAllSchoolPaymentDetail}?sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                    .then(res => {
                        if (res.data == "NOTFOUND" || res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found");
                        }
                        else {
                            //for get single school name from multiple objects with similar schools
                            const objIds = res.data.reduce((a, { schoolNameMr, amount, feePayment, differenceAmount,
                                optionId, accNameMr, accNameEn }) => {
                                a[schoolNameMr] = a[schoolNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [schoolNameMr]: {
                                            schoolNameMr,
                                            amount: a[schoolNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr,
                                                accNameEn: accNameEn,
                                            }),
                                            feePayment,
                                            differenceAmount,
                                            optionId,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)
                            setData(result);
                            setFilteredData(result);

                            //for making total of all amounts
                            let newFilterData = result
                            newFilterData.map((e, index) => {
                                newFilterData[index] = ({ ...e, totalAmount: e.amount.reduce((prev, curr) => prev + parseFloat(curr.amount), 0) })
                            })

                            let newTotalAmount = newFilterData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0)
                            //console.log(JSON.stringify(newTotalAmount))
                            setTotalAmountValue(newTotalAmount)

                            //for total of difference amount
                            const TotalDifferenceValue = result.reduce((prev, curr) => prev + parseFloat(curr.differenceAmount), 0);
                            setTotalDifferenceAmountValue(TotalDifferenceValue);

                            //for getting single account name from multiple objects with similat accounts
                            const objIds1 = res.data.reduce((a, { accNameMr, accNameEn, amount, totalAmount }) => {
                                a[accNameMr] = a[accNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [accNameMr]: {
                                            accNameMr,
                                            accNameEn,
                                            amount: a[accNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr
                                            }),
                                        }
                                    }
                                }
                            }, {})

                            const result2 = Object.values(objIds1)
                            setGetAccountLength(result2.length)
                            //console.log(JSON.stringify(result2.length))

                            //total of amount with similar account name
                            let newResult = result2.map(e => {
                                return e.amount
                            })

                            let newResult2 = newResult.reduce((acc, curr) => acc.concat(curr.map((e) => ({ ...e }))), [])

                            let summedAges = newResult2.reduce((a, c) => {
                                let filtered = a.filter(el => el.accNameMr === c.accNameMr);
                                if (filtered.length > 0) {
                                    a[a.indexOf(filtered[0])].amount += +c.amount;
                                } else {
                                    a.push(c);
                                }
                                return a;
                            }, []);
                            //console.log(JSON.stringify(summedAges))

                            setTotalAmount(summedAges);

                            //Table heading for department wise report
                            let newTableHeading = ([...result2,
                            { accNameMr: "एकूण खर्च", accNameEn: "All Total" },
                            { accNameMr: "शिल्लक", accNameEn: "Balance" },
                            { accNameMr: "सर्व एकूण", accNameEn: "All total" },
                            ])

                            setAccountName(newTableHeading)

                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("sanstha Budget Consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
        else if (categoryObj.id == 4) {
            //get consolidated report for category session payment
            if (departmentObj.id == 0) {
                await axios.get(`${getBudgetDetailAllSessionPaymentDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found");
                        }
                        else {
                            //for get single school name from multiple objects with similar schools
                            const objIds = res.data.reduce((a, { schoolNameMr, amount, feePayment, differenceAmount,
                                optionId, accNameMr, accNameEn }) => {
                                a[schoolNameMr] = a[schoolNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [schoolNameMr]: {
                                            schoolNameMr,
                                            amount: a[schoolNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr,
                                                accNameEn: accNameEn,
                                            }),
                                            feePayment,
                                            differenceAmount,
                                            optionId,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)
                            setData(result);
                            setFilteredData(result);

                            //for making total of all amounts
                            let newFilterData = result
                            newFilterData.map((e, index) => {
                                newFilterData[index] = ({ ...e, totalAmount: e.amount.reduce((prev, curr) => prev + parseFloat(curr.amount), 0) })
                            })

                            let newTotalAmount = newFilterData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0)
                            //console.log(JSON.stringify(newTotalAmount))
                            setTotalAmountValue(newTotalAmount)

                            //for total of difference amount
                            const TotalDifferenceValue = result.reduce((prev, curr) => prev + parseFloat(curr.differenceAmount), 0);
                            setTotalDifferenceAmountValue(TotalDifferenceValue);

                            //for getting single account name from multiple objects with similat accounts
                            const objIds1 = res.data.reduce((a, { accNameMr, accNameEn, amount, totalAmount }) => {
                                a[accNameMr] = a[accNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [accNameMr]: {
                                            accNameMr,
                                            accNameEn,
                                            amount: a[accNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr
                                            }),
                                        }
                                    }
                                }
                            }, {})

                            const result2 = Object.values(objIds1)
                            setGetAccountLength(result2.length)
                            //console.log(JSON.stringify(result2.length))

                            //total of amount with similar account name
                            let newResult = result2.map(e => {
                                return e.amount
                            })

                            let newResult2 = newResult.reduce((acc, curr) => acc.concat(curr.map((e) => ({ ...e }))), [])

                            let summedAges = newResult2.reduce((a, c) => {
                                let filtered = a.filter(el => el.accNameMr === c.accNameMr);
                                if (filtered.length > 0) {
                                    a[a.indexOf(filtered[0])].amount += +c.amount;
                                } else {
                                    a.push(c);
                                }
                                return a;
                            }, []);
                            //console.log(JSON.stringify(summedAges))

                            setTotalAmount(summedAges);

                            //Table heading for consolidated report
                            let newTableHeading = ([...result2,
                            { accNameMr: "एकूण खर्च", accNameEn: "All Total" },
                            { accNameMr: "शिल्लक", accNameEn: "Balance" },
                            { accNameMr: "सर्व एकूण", accNameEn: "All total" },
                            ])

                            setAccountName(newTableHeading)

                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("sanstha Budget Consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //get department wise report for category session payment
            else {
                await axios.get(`${getDepartmentWiseAllSessionPaymentDetail}?sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                    .then(res => {
                        if (res.data == "NOTFOUND" || res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found");
                        }
                        else {
                            //for get single school name from multiple objects with similar schools
                            const objIds = res.data.reduce((a, { schoolNameMr, amount, feePayment, differenceAmount,
                                optionId, accNameMr, accNameEn }) => {
                                a[schoolNameMr] = a[schoolNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [schoolNameMr]: {
                                            schoolNameMr,
                                            amount: a[schoolNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr,
                                                accNameEn: accNameEn,
                                            }),
                                            feePayment,
                                            differenceAmount,
                                            optionId,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)
                            setData(result);
                            setFilteredData(result);

                            //for making total of all amounts
                            let newFilterData = result
                            newFilterData.map((e, index) => {
                                newFilterData[index] = ({ ...e, totalAmount: e.amount.reduce((prev, curr) => prev + parseFloat(curr.amount), 0) })
                            })

                            let newTotalAmount = newFilterData.reduce((prev, curr) => prev + parseFloat(curr.totalAmount), 0)
                            //console.log(JSON.stringify(newTotalAmount))
                            setTotalAmountValue(newTotalAmount)

                            //for total of difference amount
                            const TotalDifferenceValue = result.reduce((prev, curr) => prev + parseFloat(curr.differenceAmount), 0);
                            setTotalDifferenceAmountValue(TotalDifferenceValue);

                            //for getting single account name from multiple objects with similat accounts
                            const objIds1 = res.data.reduce((a, { accNameMr, accNameEn, amount, totalAmount }) => {
                                a[accNameMr] = a[accNameMr] || { amount: [] }
                                return {
                                    ...a, ...{
                                        [accNameMr]: {
                                            accNameMr,
                                            accNameEn,
                                            amount: a[accNameMr].amount.concat({
                                                amount: amount,
                                                accNameMr: accNameMr
                                            }),
                                        }
                                    }
                                }
                            }, {})

                            const result2 = Object.values(objIds1)
                            setGetAccountLength(result2.length)
                            //console.log(JSON.stringify(result2.length))

                            //total of amount with similar account name
                            let newResult = result2.map(e => {
                                return e.amount
                            })

                            let newResult2 = newResult.reduce((acc, curr) => acc.concat(curr.map((e) => ({ ...e }))), [])

                            let summedAges = newResult2.reduce((a, c) => {
                                let filtered = a.filter(el => el.accNameMr === c.accNameMr);
                                if (filtered.length > 0) {
                                    a[a.indexOf(filtered[0])].amount += +c.amount;
                                } else {
                                    a.push(c);
                                }
                                return a;
                            }, []);
                            //console.log(JSON.stringify(summedAges))

                            setTotalAmount(summedAges);

                            //Table heading for department wise report
                            let newTableHeading = ([...result2,
                            { accNameMr: "एकूण खर्च", accNameEn: "All Total" },
                            { accNameMr: "शिल्लक", accNameEn: "Balance" },
                            { accNameMr: "सर्व एकूण", accNameEn: "All total" },
                            ])

                            setAccountName(newTableHeading)

                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("sanstha Budget Consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
    }

    //print budget reports
    const printAllConsolidatedReport = async () => {
        setLoaderOption(true);
        if (categoryObj.id == 1) {
            //print consolidated report for categoryId school receipt
            if (departmentObj.id == 0) {
                await axios.get(`${printBudgetDetailAllSchoolReceiptDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget consolidate report sanstha err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //print department wise report for categoryId school receipt
            else {
                await axios.get(`${printDepartmentWiseAllSchoolReceiptDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                    .then(res => {
                        if (res.data == "NOTFOUND" || res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget department wise report sanstha err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
        else if (categoryObj.id == 2) {
            //print consolidated report for categoryId session receipt
            if (departmentObj.id == 0) {
                await axios.get(`${printBudgetDetailAllsessionReceiptDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget consolidate report sanstha err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //print department wise report for categoryId session receipt
            else {
                await axios.get(`${printDepartmentWiseAllSessionReceiptDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                    .then(res => {
                        if (res.data == "NOTFOUND" || res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget department wise report sanstha err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
        else if (categoryObj.id == 3) {
            //print consolidated report for categoryId school payment
            if (departmentObj.id == 0) {
                await axios.get(`${printBudgetDetailAllSchoolPaymentDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget consolidate report sanstha err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //print department wise report for categoryId school payment
            else {
                await axios.get(`${printDepartmentWiseAllSchoolPaymentDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                    .then(res => {
                        if (res.data == "NOTFOUND" || res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget department wise report sanstha err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
        else if (categoryObj.id == 4) {
            //print consolidated report for categoryId session payment
            if (departmentObj.id == 0) {
                await axios.get(`${printBudgetDetailAllSessionPaymentDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget consolidate report sanstha err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //print department wise report for categoryId session payment
            else {
                await axios.get(`${printDepartmentWiseAllSessionPaymentDetail}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}`)
                    .then(res => {
                        if (res.data == "NOTFOUND" || res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget department wise report sanstha err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <div className=''>
                                <form onSubmit={getBudgetSchoolWiseDetails}>
                                    <div className="form-data">

                                        <div className="row my-2">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={categoryData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setCategoryObj(newValue);
                                                        setOptionId(1);
                                                        setDepartmentObj(null);
                                                        setAccountName([]);
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={categoryObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    // renderOption={(props, option) => (
                                                    //     <div className='' {...props}>
                                                    //         <div className=''>
                                                    //             {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                                    //             <div
                                                    //                 className=''
                                                    //                 style={{
                                                    //                     display: "flex",
                                                    //                     flex: 1,
                                                    //                     fontSize: "11px",
                                                    //                     flexDirection: "row",
                                                    //                     //justifyContent: "space-between",
                                                    //                     padding: "0px 5px",
                                                    //                     color: "#464964",
                                                    //                     fontWeight: "550"
                                                    //                     //gridRow: 1
                                                    //                 }}
                                                    //             >
                                                    //                 <span><AccountBalanceIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {(authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}</span>
                                                    //                 <span className='ml-1 mr-1 mb-1' style={{ fontSize: "14px" }}>|</span>
                                                    //                 <span>{(option.side == "Receipt") ? <ReceiptLongIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <PaymentsIcon style={{ color: "#354AFA", fontSize: "14px" }} />} {option.side}</span>
                                                    //             </div>
                                                    //         </div>

                                                    //     </div>
                                                    // )}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row my-2">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Department :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={departmentData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setDepartmentObj(newValue);
                                                        setAccountName([]);
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={departmentObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Department" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="row my-2">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                            <div className='col-sm-4'>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                    <Select
                                                        size='small'
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={optionId}
                                                        label="Select Option"
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                        onChange={(e) => {
                                                            setOptionId(e.target.value);
                                                            setDepartmentObj(null);
                                                            setAccountName([]);
                                                            setData([]);
                                                            setFilteredData([]);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? optionId : ""}
                                                    >

                                                        <MenuItem value={1}>Consolidated</MenuItem>
                                                        <MenuItem value={2}>Department Wise</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div> */}

                                        <div className="row mt-3 mb-4">
                                            <div className="col-sm-4" style={{ marginLeft: "150px" }}>
                                                <button type='submit' className='btn btn-sm btn-warning'>Show</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* form data ends */}

                                {/* BudgetAllSchoolDepartmentWiseReportTable */}
                                <div className=''>
                                    {(() => {
                                        if (data.length != 0 || filteredData.length != 0) {
                                            return (
                                                <>
                                                    <BudgetAllSchoolDepartmentWiseReportTable
                                                        data={data}
                                                        setData={setData}
                                                        filteredData={filteredData}
                                                        setFilteredData={setFilteredData}
                                                        accountName={accountName}
                                                        totalAmount={totalAmount}
                                                        getAccountlength={getAccountlength}
                                                        printAllConsolidatedReport={printAllConsolidatedReport}
                                                        totalAmountValue={totalAmountValue}
                                                        totalDefferenceAmountValue={totalDefferenceAmountValue}
                                                    />
                                                </>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default BudgetConsolidatedDepartmentWiseReport