import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import makeStyles from "@mui/styles/makeStyles";
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import { useNavigate, useParams } from 'react-router-dom';
import { getMobileEventAPIURL, saveMobileEventAPIURL, updateMobileEventAPIURL } from '../Services/MobileAppAllAPI';

const useStyles = makeStyles({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
});

const Event = () => {

    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);
    const navigate = useNavigate();
    const classes = useStyles();

    const [titleId, setTitleId] = useState('');

    const { eId, EditFlag } = useParams();

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "mobileEventSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "mobileEventUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Event");
        }
        else if (EditFlag == 2) {
            setTitleId("Update Event Details");
        }
        getData();
    }, [authUser])

    const [id, setId] = useState('');
    const [eventFor, setEventFor] = useState(1);
    const [eventName, setEventName] = useState('');
    const [dateValue, setDateValue] = useState(null);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [desce, setDesce] = useState("");

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${getMobileEventAPIURL}?bid=${authUser.branchId}`)
            .then(res => {
                res.data.map(e => {
                    setSaveBtn(displayNoneBtnStyle)
                    setUpdateBtn(updateBtnStyle)

                    if (e.id == eId) {
                        setId(e.id)
                        setEventFor(e.eventFor)
                        setEventName(e.eventName)
                        console.log(e.eventDate)
                        // Parse the date using Moment.js with the correct format
                        const parsedDate = moment(e.eventDate, "DD/MM/YYYY");

                        // setDateValue(parsedDate.format("DD/MM/YYYY"))
                        setDateValue(e.eventDate)
                        setDesce(e.eventDesc)

                        axios.get(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                            .then(res => {
                                setClassObj(res.data.find(item => item.id == e.classId))
                                setClassData(res.data);
                            })
                    }
                })
                setLoaderOption(false);
            })
    }

    const getApiData = async () => {
        await axios.get(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassData(res.data);
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setEventFor(1);
        setEventName('');
        setDateValue(null);
        setClassObj(null);
        setDesce("");
        navigate('/Home/ma1004')
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "eventFor": eventFor,
            "classId": (eventFor == 1) ? 0 : classObj.id,
            "eventDate": moment(dateValue).format("YYYY-MM-DD"),
            "eventName": eventName,
            "eventDesc": desce,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(saveObj));
        await axios.post(saveMobileEventAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }
            }).catch(err => {
                console.log('Event save error:' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "eventFor": eventFor,
            "classId": (eventFor == 1) ? 0 : classObj.id,
            "eventDate": moment(dateValue).format("YYYY-MM-DD"),
            "eventName": eventName,
            "eventDesc": desce,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(updateObj));
        await axios.put(updateMobileEventAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }
            }).catch(err => {
                console.log('home work update error:' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.mobileEventSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.mobileEventUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                    <div className="row">
                                        <label className="" style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Event For :</label>
                                        <div className='col-sm-3' >
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Event For</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    fullWidth
                                                    required
                                                    margin="dense"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={eventFor}
                                                    label="Event For"
                                                    onChange={(e) => {
                                                        setEventFor(e.target.value);
                                                        setEventName('');
                                                        setDateValue(null);
                                                        setClassObj(null);
                                                        setDesce("");
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? eventFor : ''}
                                                >
                                                    <MenuItem value={1}>All</MenuItem>
                                                    <MenuItem value={2}>Class Wise</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                        {(() => {
                                            if (eventFor == 2) {
                                                return (
                                                    <>
                                                        <label className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                                                        <div className='col-sm-3'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                options={classData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    setClassObj(newValue);
                                                                    setEventName('');
                                                                    setDateValue(null);
                                                                    setDesce("");
                                                                }}
                                                                value={classObj}
                                                                onKeyPress={(e) => (e.key == "Enter") ? classObj : ''}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                size="small"
                                                                renderInput={params => (
                                                                    <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                                )}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                value={dateValue}
                                                inputFormat="dd/MM/yyyy"
                                                onChange={(newValue) => {
                                                    setDateValue(newValue);
                                                    // setEventName('');
                                                    // setDesce("");
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? dateValue : ''}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <label className="" style={{ width: "110px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Event Name:</label>
                                    <div className='' style={{ width: "535px" }}>
                                        <TextField
                                            fullWidth
                                            required
                                            value={eventName}
                                            onChange={(e) => setEventName(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? eventName : ''}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Event Name"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                            inputProps={{ maxLength: 120 }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Details :</label>
                                    <div className='col-sm-10'>
                                        <TextField
                                            fullWidth
                                            value={desce}
                                            onChange={(e) => setDesce(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? desce : ''}
                                            multiline
                                            rows={4}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Details"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4" style={{ marginLeft: "120px" }}>
                                        <button type="submit" onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn}>Save</button>
                                        <button type="submit" onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn} >Update</button>
                                        <button type="button" className="btn btn-danger btn-sm mx-1" onClick={() => clearData()}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default Event