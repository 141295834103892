import { BaseURL } from "../../CommonServices/APIURL";


//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/saveInvestmentEntry
export const saveInvestmentEntryAPIURL = BaseURL + "accounts/investment/saveInvestmentEntry";

//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/updateInvestmentEntry
export const updateInvestmentEntryAPIURL = BaseURL + "accounts/investment/updateInvestmentEntry";

//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/getInvestmentEntryList?sid=3&bid=1
export const getInvestmentEntryAPIURL = BaseURL + "accounts/investment/getInvestmentEntryList";

//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/deleteInvestmentEntry?id=2
export const deleteInvestmentEntryAPIURL = BaseURL + "accounts/investment/deleteInvestmentEntry";


//Report
//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/getInvestmentEntryReport?langId=1&rptType=2&option=1&typeId=0&subOption=1&fromDate=2022-01-01&toDate=2027-01-01&sid=3&bid=1
export const printInvestmentReportAPIURL = BaseURL + "accounts/investment/getInvestmentEntryReport";
