import React, { useState, useEffect } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';

import { getAllAdmissionFormReceiveListAPIURL, deleteAdmissionFormReceiveData } from '../Services/AdmFormReceiveAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const AdmissionFormReceiveTable = () => {

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Form Received";
    const classes = useStyles();
    let { toastFlag } = useParams();
    const navigate = useNavigate();
    const [deleteId, setDeleteId] = useState("");

    const [loaderOption, setLoaderOption] = useState(false);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);


    //Table Data
    const TableHeading = [
        { label: 'Form No', key: 'formNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'firstNameMr' : 'firstNameEn' },
        { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
        { label: `Receive Date`, key: 'formReceivedDate' },
        { label: `Status`, key: 'confirmationStatus' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }
    ];

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter((item) =>
                (item.firstNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.firstNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.middleNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.middleNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.lastNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.lastNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.confirmationStatus || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.formNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.classNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.classNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.formReceivedDate || '').toString().toLowerCase().includes(searchTerm.toLowerCase())
            ))
    }, [searchTerm])

    useEffect(() => {
        if (authUser.sessionYear !== null && authUser.deptId !== null && authUser.branchId !== null) {
            getData();
        }
    }, [authUser])

    const getData = async () => {
        setLoaderOption(true);
        await axios.get(`${getAllAdmissionFormReceiveListAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((res) => {
                if (res.data !== null) {
                    //console.log(JSON.stringify(res.data))
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                console.log(err)
            })
    }

    const UpdateData = (fNo, EditFlag) => {
        navigate(`/Home/adm302F/${fNo}/${EditFlag}`);
    }

    useEffect(() => {
        if (toastFlag == 1) {
            //toast.success("Saved Sucessful.")
            navigate("/Home/adm302/0")
        }
        else if (toastFlag == 2) {
            //toast.success("Updation Sucessful.")
            navigate("/Home/adm302/0")
        }
    }, [authUser]);

    const DeleteData = async (id) => {
        await axios.delete(`${deleteAdmissionFormReceiveData}?id=${id}`)
            .then((response) => {
                if (response.data === "DELETED") {
                    getData();
                    toast.error("Deleted sucessfully");
                }
            });
    }

    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            <div className=''>
                                <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/adm302F/0/1")} ><AddIcon fontSize="small" />New Form</button>
                            </div>
                            <div className='row col-sm-4 mb-1' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>

                            <div className='table-responsive'>
                                <table className="table table-bordered">
                                    <thead className="table-Default">
                                        <tr>
                                            {TableHeading.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.formNo}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>
                                                            {(authUser.branchMedium == 1) ? item.firstNameMr + " " + item.middleNameMr + " " + item.lastNameMr : item.firstNameEn + " " + item.middleNameEn + " " + item.lastNameEn}
                                                        </td>

                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>

                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.formReceivedDate}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                            {
                                                                (item.confirmationStatus === "Received") ?
                                                                    <span className="badge" style={{ backgroundColor: "#F39C12" }}>{item.confirmationStatus}</span>
                                                                    :
                                                                    <span className="badge badge-success" style={{ backgroundColor: "#32C123" }}>{item.confirmationStatus}</span>
                                                            }

                                                        </td>
                                                        <td>
                                                            <Tooltip title="Edit">
                                                                <button type="button" className='btn btn-primary btn-sm mx-1 p-1'
                                                                    onClick={() => UpdateData(item.formNo, 2)}
                                                                    disabled={(item.confirmationStatus === "Received") ? false : true}
                                                                    data-bs-toggle="tooltip">
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </button>
                                                            </Tooltip>
                                                        </td>

                                                        <td>
                                                            <Tooltip title="Delete">
                                                                <button type="button" className='btn btn-danger btn-sm mx-1 p-1'
                                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                    onClick={() => setDeleteId(item.id)}
                                                                    disabled={(item.confirmationStatus === "Received") ? false : true}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <TablePagination
                                                style={{ padding: "0px" }}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                count={data.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onRowsPerPageChange={onRowsPerPageChange}
                                                onPageChange={onPageChange}
                                                labelRowsPerPage="Rows Per Page :"
                                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                    </div>
            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default AdmissionFormReceiveTable