import {BaseIP, BaseURL, BaseURLMaster} from "./APIURL";

//Caste Category Master API link
export const BaseURLMasterCasteCategory=BaseURLMaster + "Categories/";



export const CasteCategoryPostMapping=BaseURLMaster + "saveCategory/";
export const CasteCategoryPutMapping=BaseURLMaster + "updateCategory/";
export const CasteCategoryDeleteMapping=BaseURLMaster + "deleteCategory/";


        // title: "Caste Category",
        // GetMapping:"Categories",
        // PostMapping:"saveCategory",
        // PutMapping:"updateCategory",
        // DeleteMapping:"deleteCategory" 

