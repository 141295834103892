import { BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

// get binder master list (table)
// GetMapping
// localhost:8080/institute/sss/library/master/getAllBinderMasterList?branchId=1
export const getBinderMaster = BaseURL + "library/master/getAllBinderMasterList";

// get single binder master data 
// GetMapping
// localhost:8080/institute/sss/library/master/getBinderMasterData?id=2
export const getBinderMasterById = BaseURL + "library/master/getBinderMasterData";

// save binder master
// PostMapping
// localhost:8080/institute/sss/library/master/saveBinderMaster
// {
//     "name": "nachiket",
//     "address": "sangli",
//     "city": 1,
//     "mobileNo": "+78789898",
//     "email": "nachiket@gmail.com",
//     "branchId": 1
// }
export const saveBinderMaster = BaseURL + "library/master/saveBinderMaster";

// update binder master
// PutMapping
// localhost:8080/institute/sss/library/master/updateBinderMaster
// {
//     "id": 2,
//     "name": "nachiket kulkarni",
//     "address": "ram mandir,sangli",
//     "city": 1,
//     "mobileNo": "+666666",
//     "email": "nachiketkulkarni@gmail.com",
//     "branchId": 1
// }
export const updateBinderMaster = BaseURL + "library/master/updateBinderMaster";

// delete Binder master
// DeleteMapping
// localhost:8080/institute/sss/library/master/deleteBinderMaster?id=1
export const deleteBinderMaster = BaseURL + "library/master/deleteBinderMaster";

