import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const AdmissionFormCancel = () => {
    const titleId = "Admission Form Cancellation";
    const authUser = useSelector((state) => state.user.value);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState();
    const [data, setData] = useState([]);

    return (
        <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                <form>
                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row my-2">
                        <label style={{ width: "135px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student:</label>
                        <div className="col-sm-4">
                            <Autocomplete
                                id="combo-box-demo"
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                options={data}

                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <label style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Payment No:</label>
                        <div className='col-sm-2'>
                            <TextField
                                required
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Payment No"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>

                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date :</label>
                        <div className='col-sm-2'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date"
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            margin='dense'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            required
                                            fullWidth
                                            autoComplete='off'
                                            size="small" />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className="row">
                        <label style={{ width: "135px", fontSize: "14px", fontWeight: "600", marginTop: "12px" }} >Payment Mode :</label>
                        <div className='col-sm-3 mt-2'>
                            <FormControl fullWidth >
                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Payment Mode</InputLabel>
                                <Select
                                    size='small'
                                    required
                                    fullWidth
                                    margin='dense'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Payment Mode"
                                >
                                    <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Cash</MenuItem>
                                    {/* <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Free</MenuItem> */}
                                </Select>
                            </FormControl>

                        </div>

                        <label style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >DD / Cheque No:</label>
                        <div className='col-sm-2'>
                            <TextField
                                required
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="DD / Cheque No"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>

                        <label style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Cheque Date :</label>
                        <div className='col-sm-2'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Cheque Date"
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            margin='dense'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            required
                                            fullWidth
                                            autoComplete='off'
                                            size="small" />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className="row my-2">
                        <label style={{ width: "135px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Details:</label>
                        <div className='col-sm-5'>
                            <TextField
                                required
                                fullWidth
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Details"
                                variant="outlined"
                                margin='dense'
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 mt-3">
                        <div className="offset-sm-1">
                            <button type="submit" className="btn btn-primary btn-sm" style={{ margin: '0px 4px 0px 32px' }}>Show</button>
                            <button type="button" className="btn btn-danger btn-sm" >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AdmissionFormCancel