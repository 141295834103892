import React, { useState, useEffect } from 'react'
import axios from 'axios';
import makeStyles from "@mui/styles/makeStyles";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { getAccClosingTransferAPIURL } from '../Services/AccountClosingTransferAPIURL';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import AccountClosingTransferTable from './AccountClosingTransferTable';
import Loader from '../../CommonComponent/Loader';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const AccountClosingTransfer = () => {

    const titleId = "Account Closing Transfer"
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [currentAccYear, setCurrentAccYear] = useState('');
    const [nextAccYear, setNextAccYear] = useState('');
    const [option, setOption] = useState('');

    useEffect(() => {
        if (authUser.yearLabel != "") {
            setCurrentAccYear(authUser.yearLabel);

            const startYear = authUser.sessionYear + 1;
            const endYear = startYear + 1;
            setNextAccYear(`${startYear}-${endYear % 100}`)
        }
    }, [authUser])

    const getData = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        await axios.get(`${getAccClosingTransferAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&option=${option}`)
            .then((response) => {
                console.log(response.data);
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data.length == 0) {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    setData(response.data);
                    setFilteredData(response.data);
                    setLoaderOption(false);
                }

            }).catch(err => {
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
                console.log("Get A/C closing transfer err " + err)
            })

    }

    const TableHeading = [
        { label: 'A/C No.', key: 'accountCode' },
        { label: 'Account Name', key: (authUser.branchMedium == 1) ? 'accountNameMr' : 'accountNameEn' },
        { label: 'Group Name', key: (authUser.branchMedium == 1) ? 'groupNameMr' : 'groupNameEn' },
        { label: `Current Year (${authUser.sessionYear}) Closing`, key: 'closingAmount', isAction: true },
        { label: `Next Year (${parseInt(authUser.sessionYear) + 1}) Opening`, key: 'closingAmount', isAction: true },
    ];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '500px' }}>
                            <form onSubmit={getData}>
                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current A/C Year :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && currentAccYear.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Current A/C Year"
                                            variant="outlined"
                                            margin='dense'
                                            value={currentAccYear}
                                            onChange={(e) => setCurrentAccYear(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? currentAccYear : ""}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120, readOnly: true }}
                                        />
                                    </div>

                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Next A/C Year :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && nextAccYear.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Next A/C Year"
                                            variant="outlined"
                                            margin='dense'
                                            value={nextAccYear}
                                            onChange={(e) => setNextAccYear(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? nextAccYear : ""}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120, readOnly: true }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                    <div className='col-sm-4'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                                            <Select
                                                size='small'
                                                fullWidth
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                value={option}
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setData([]);
                                                    setFilteredData([]);
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                                label="Option"
                                            >
                                                <MenuItem value={1}>Only Bank & Cash</MenuItem>
                                                <MenuItem value={2}>All Asset Site Except Bank & Cash</MenuItem>
                                                <MenuItem value={3}>All Liability Site Except Bank & Cash</MenuItem>
                                                <MenuItem value={4}>All</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-sm-4 mt-3">
                                        <button type='submit' className='btn btn-sm btn-primary'>VIEW</button>
                                        <button type='button' className='btn btn-sm btn-danger ml-2' onClick={() => { setData([]); setFilteredData([]); }}>Cancel</button>
                                    </div>
                                </div>
                            </form>

                            <div className="" style={{ marginTop: '40px' }}>
                                {(() => {
                                    if (data.length != 0 || filteredData.length != 0) {
                                        return (
                                            <AccountClosingTransferTable
                                                TableHeading={TableHeading}
                                                data={data}
                                                filteredData={filteredData}
                                                setFilteredData={setFilteredData}
                                                setData={setData}
                                                setLoaderOption={setLoaderOption}
                                            />
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </>
            }
        </>
    )
}


export default AccountClosingTransfer