import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import PrintIcon from '@mui/icons-material/Print';
import $ from 'jquery';
import '../../../Payrole/empAttendTable.css'

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const BudgetConsoidatedReportTable = ({ filteredData, data, setData, setFilteredData, accountName, totalAmount,
    getAccountlength, printAllConsolidatedReport, totalAmountValue, totalDefferenceAmountValue }) => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    //get total of all amounts
    const totalOfallTotalAmount = parseFloat(totalAmountValue) + parseFloat(totalDefferenceAmountValue);


    useEffect(() => {
        $(function () {
            var tableContainer = $(".large-table-container-3");
            var table = $(".large-table-container-3 table");
            var fakeContainer = $(".large-table-fake-top-scroll-container-3");
            var fakeDiv = $(".large-table-fake-top-scroll-container-3 div");

            var tableWidth = table.width();
            fakeDiv.width(tableWidth);

            fakeContainer.scroll(function () {
                tableContainer.scrollLeft(fakeContainer.scrollLeft());
            });
            tableContainer.scroll(function () {
                fakeContainer.scrollLeft(tableContainer.scrollLeft());
            });
        });

    }, [])

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div className="mt-4">
                        <div className="">
                            <div style={{ float: "right" }}>
                                <button
                                    style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                    type='button'
                                    onClick={() => printAllConsolidatedReport()}
                                    className='btn btn-sm mb-2'
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>
                            </div>
                        </div>
                        <form>
                            <div class="large-table-fake-top-scroll-container-3">
                                <div>&nbsp;</div>
                            </div>
                            <div className='large-table-container-3'>
                                {/* <div className='table-responsive'> */}
                                <table className="table table-bordered border-dark">
                                    <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", }} scope="col">शाळेचे नाव</th>
                                            {accountName.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", lineHeight: "1.5" }} key={index} scope="col">{item.accNameMr}</th>
                                                )
                                            })}

                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {
                                            filteredData.map((item, index) => {
                                                //if budget is not filled for that account from accountdata length
                                                const arr = Array(getAccountlength).fill({
                                                    amount: 0.00,
                                                    accNameMr: '',
                                                    accNameEn: '',
                                                    TotalAmountValue: ''
                                                })

                                                //for make a total of all total amounts
                                                let updateData = arr
                                                item.amount.map((e, index) => {
                                                    updateData[index] = ({
                                                        ...e,
                                                        amount: e.amount,
                                                        accNameMr: e.accNameMr,
                                                        accNameEn: e.accNameEn,
                                                        TotalAmountValue: ''
                                                    })

                                                })

                                                //total of all amount
                                                const TotalAmountValue = updateData.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);

                                                //total of toal amount & difference amount for all schools
                                                const allTotalAmount = parseFloat(TotalAmountValue) + parseFloat(item.differenceAmount);


                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.5" }}>{item.schoolNameMr}</td>
                                                            {
                                                                updateData.map(a => {
                                                                    return (
                                                                        <>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(a.amount).toFixed('2')}</td>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(TotalAmountValue).toFixed('2')}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(item.differenceAmount).toFixed('2')}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{(allTotalAmount).toFixed('2')}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>Total Rs.</td>
                                            {
                                                totalAmount.map(e => {
                                                    return (
                                                        <>
                                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(e.amount).toFixed('2')}</td>
                                                        </>
                                                    )
                                                })
                                            }
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(totalAmountValue).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(totalDefferenceAmountValue).toFixed('2')}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(totalOfallTotalAmount).toFixed('2')}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                {/* </div> */}
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}

export default BudgetConsoidatedReportTable