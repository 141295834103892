import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/academics/exam/getTermObservationMasterList?year=2022&sid=3&bid=1
export const getTermObsMasterAPIURL = BaseURL + "academics/exam/getTermObservationMasterList";

//localhost:8080/institute/sss/academics/exam/saveTermObservationMaster
// {
//     "observationType": 1,
//     "boysObservation": "boysObservation",
//     "girlsObservation": "girlsObservation",
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveTermObsMasterAPIURL = BaseURL + "academics/exam/saveTermObservationMaster";

//localhost:8080/institute/sss/academics/exam/updateTermObservationMaster
export const updateTermObsMasterAPIURL = BaseURL + "academics/exam/updateTermObservationMaster";

//localhost:8080/institute/sss/academics/exam/deleteTermObservationMaster?id=1
export const deleteTermObsMasterAPIURL = BaseURL + "academics/exam/deleteTermObservationMaster";