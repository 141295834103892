const HostelAcademicJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/HostelDashboard/",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        DropDownSubMenu: [
            {
                id: 1201,
                SubPath: "/Home/hm1201/",
                SubName: "Building Master"
            },
            {
                id: 1202,
                SubPath: "/Home/hm1202/",
                SubName: "Room Master"
            },
            {
                id: 1203,
                SubPath: "/Home/hm1203/",
                SubName: "Bed Master"
            },
            {
                id: 1204,
                SubPath: "/Home/hm1204/",
                SubName: "Student Leave Master"
            },
            {
                id: 1205,
                SubPath: "/Home/hm1205/",
                SubName: "Material Master"
            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Transaction",
        LinkIcon: "monetization_on",
        DropDownSubMenu: [
            {
                id: 1206,
                SubPath: "/Home/ht1206/",
                SubName: "Hostel Allocation"
            },
            {
                id: 1207,
                SubPath: "/Home/ht1207/",
                SubName: "Material Allocation"
            },
            {
                id: 1208,
                SubPath: "/Home/ht1208/",
                SubName: "Medical Visit"
            },
            {
                id: 1209,
                SubPath: "/Home/ht1209/",
                SubName: "Student Leave"
            },
            {
                id: 1215,
                SubPath: "/Home/ht1215/",
                SubName: "Student Reporting"
            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Reports",
        LinkIcon: "assignment",
        DropDownSubMenu: [
            {
                id: 1210,
                SubPath: "/Home/hr1210/",
                SubName: "Student Bed Allocation"
            },
            {
                id: 1211,
                SubPath: "/Home/hr1211/",
                SubName: "Vacant Beds Report"
            },
            {
                id: 1212,
                SubPath: "/Home/hr1212/",
                SubName: "Material Report"
            },
            {
                id: 1213,
                SubPath: "/Home/hr1213/",
                SubName: "Medical Visit Report"
            },
            {
                id: 1214,
                SubPath: "/Home/hr1214/",
                SubName: "Leave Report"
            },
            {
                id: 1216,
                SubPath: "/Home/hr1216/",
                SubName: "Student Reporting Report"
            },
        ]
    },
]

export default HostelAcademicJSON;