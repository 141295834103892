import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const StudentsVillageTextBox = ({ initialText1, initialText2, onChange }) => {
  const [isEdit1, setEdit1] = useState(false);
  const [isEdit2, setEdit2] = useState(false);
  const [text1, setText1] = useState(initialText1);
  const [text2, setText2] = useState(initialText2);

  return (
    <>
      <td style={{ width: "240px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
        <input
          className='form-control form-control-sm'
          //style={{ width: "100px", fontWeight: "500",fontSize: "15px" }}
          style={(isEdit1 === true) ? { width: "220px", fontSize: "15px", } : { width: "220px", fontSize: "15px", border: "0px" }}
          onFocus={() => setEdit1(true)}
          onBlur={() => {
            setEdit1(false);
            onChange(text1, text2)
          }}
          onKeyPress={(e) => (e.key == "Enter") ?  onChange(text1, text2) : ""}
          onChange={(e) => setText1(e.target.value)}
          value={text1}
        />
      </td>

      <td style={{ width: "220px", height: "70px", fontWeight: "500", fontSize: "15px" }}>
        <input

          className='form-control form-control-sm'
          //style={{ width: "220px", fontWeight: "500", fontSize: "15px" }}
          style={(isEdit2 === true) ? { width: "200px", fontSize: "15px", } : { width: "200px", fontSize: "15px", border: "0px" }}
          onFocus={() => setEdit2(true)}
          onBlur={() => {
            setEdit2(false);
            onChange(text1, text2)
          }}
          onKeyPress={(e) => (e.key == "Enter") ?  onChange(text1, text2) : ""}
          onChange={(e) => setText2(e.target.value)}
          value={text2}
        />
      </td>
    </>
  )
}

export default StudentsVillageTextBox