import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import { getFeeList, saveFeeList, updateFeeList } from '../../CommonServices/FeeMasterAPIURL';
import FeeMasterTable from './FeeMasterTable';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 200,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  }
}));

const feeTypeData = [
  {
    id: 1,
    feeLabel: "One Time"
  },
  {
    id: 2,
    feeLabel: "Yearly"
  },
  {
    id: 3,
    feeLabel: "Donation"
  },
  {
    id: 4,
    feeLabel: "Sanstha Receipt"
  },
  {
    id: 5,
    feeLabel: "EBC Fee"
  }
]

const FeeMaster = () => {
  const authUser = useSelector((state) => state.user.value);
  const titleId = "Fee Master";
  const classes = useStyles();   

  const [loaderOption, setLoaderOption] = useState(false);

  const TableHeading = [
    { label: 'ID', key: 'id' },
    { label: `Fee Name Mr`, key: 'nameMr' },
    { label: `Fee Name En`, key: 'nameEn' },
    { label: `Fee Type`, key: 'feeTypeName' },
    { label: `Short Name`, key: 'shortName' },
    { label: 'Edit', value: 'edit', isAction: true },
    { label: 'Delete', value: 'delete', isAction: true }];

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "feeMasterSaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "feeMasterUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [id, setId] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [marathiName, setMarathiName] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [feeType, setFeeType] = useState("");
  const [showGuardianNm, setShowGuardianNm] = useState(0);
  const [shortName, setShortName] = useState("");

  const HandleGuardianNameCheckbox = (e) => {
    setShowGuardianNm(e.target.checked);
    if (e.target.checked == true) {
      setShowGuardianNm(1);
    }
    else {
      setShowGuardianNm(0);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    //console.log(getFeeList);
    await axios(`${getFeeList}`)
      .then(res => {
        setData(res.data);
        setFilteredData(res.data);
      })
  }

  const saveBtnClick = () => {
    setLoaderOption(true);
    const saveObject = {
      id: id,
      nameMr: marathiName,
      nameEn: englishName,
      feeType: feeType,
      shortName: shortName,
      isShowGuardianName: showGuardianNm
    };
    //console.log(JSON.stringify(saveObject))
    axios.post(`${saveFeeList}`, saveObject)
      .then((response) => {
        if (response.data != null) {
          getData();
          setLoaderOption(false);
          toast.success("Saved successfully.");
        } else {
          toast.error("Operation failed.");
        }
      });
    setId("");
    setMarathiName("");
    setEnglishName("");
    setFeeType("");
    setShortName("");
    setShowGuardianNm(0);
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
  };

  const updateBtnClick = () => {
    setLoaderOption(true);
    const updateObject = {
      id: id,
      nameMr: marathiName,
      nameEn: englishName,
      feeType: feeType,
      shortName: shortName,
      isShowGuardianName: showGuardianNm
    };
    //console.log(JSON.stringify(updateObject))
    axios.put(`${updateFeeList}` + id, updateObject)
      .then((response) => {
        if (response.data != null) {
          getData();
          setLoaderOption(false);
          toast.success("Update successfully.");
        }
      });
    setId("");
    setMarathiName("");
    setEnglishName("");
    setFeeType("");
    setShortName("");
    setShowGuardianNm(0);
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
  };

  const ClearData = () => {
    setMarathiName("");
    setEnglishName("");
    setFeeType("");
    setShortName("");
    setShowGuardianNm(0);
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
  }

  const state = {
    button: 1
  };

  const onSubmit = e => {
    e.preventDefault();
    if (state.button === 1) {
      saveBtnClick();
    }
    if (state.button === 2) {
      updateBtnClick();
    }
  };

  const handleKeyDownMr = (e) => {
    if (e.key === " " && marathiName.length === 0) {
      e.preventDefault();
    }
  };

  const handleKeyDownEn = (e) => {
    if (e.key === " " && englishName.length === 0) {
      e.preventDefault();
    }
  };

  const handleKeyDownSname = (e) => {
    if (e.key === " " && shortName.length === 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {

    function handlekeydownEvent(event) {
      if (event.key === "Enter") {
        console.log("Enter is pressed!")
        event.preventDefault();

        let btn1 = document.querySelector('.feeMasterSaveBtn')
        if (btn1 !== null) {
          btn1.click();
        }
        let btn2 = document.querySelector('.feeMasterUpdateBtn')
        if (btn2 !== null) {
          btn2.click();
        }

      }
    }

    document.addEventListener('keypress', handlekeydownEvent)
    return () => {
      document.removeEventListener('keypress', handlekeydownEvent)
    }

  }, []);

  if (loaderOption == true) {
    return <Loader />
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
          {/* Form Data start */}
          <form onSubmit={onSubmit}>
            <div className="form-data mt-1">
              <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
              <div className="row">
                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Fee Name :</label>
                <div className='col-sm-4'>
                  <TextField
                    required
                    fullWidth
                    onKeyDown={handleKeyDownMr}
                    autoComplete='off'
                    className=''
                    size='small'
                    id="outlined-basic"
                    label="Fee Name Marathi"
                    variant="outlined"
                    margin='dense'
                    value={marathiName}
                    onChange={(e) => setMarathiName(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? marathiName : ""}
                    inputProps={{ maxLength: 120 }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </div>

                <div className='col-sm-4'>
                  <TextField
                    required
                    fullWidth
                    onKeyDown={handleKeyDownEn}
                    autoComplete='off'
                    size='small'
                    value={englishName}
                    onChange={(e) => setEnglishName(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? englishName : ""}
                    id="outlined-basic"
                    label="Fee Name English"
                    variant="outlined"
                    margin='dense'
                    inputProps={{ maxLength: 120 }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </div>
              </div>

              <div className="row my-2">
                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Fee Type:</label>
                <div className='col-sm-4'>

                  <FormControl fullWidth margin='dense'>
                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Fee Type</InputLabel>
                    <Select
                      size='small'
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={feeType}
                      label="Select Fee Type"
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      onChange={(e) => setFeeType(e.target.value)}
                      onKeyPress={(e) => (e.key == "Enter") ? feeType : ""}
                    >
                      {feeTypeData.map((item, index) => {
                        return (
                          <MenuItem value={item.id} key={index}>{item.feeLabel}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>

                </div>
              </div>

              <div className="row">
                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Short Name :</label>
                <div className='col-sm-4'>
                  <TextField
                    required
                    fullWidth
                    onKeyDown={handleKeyDownSname}
                    autoComplete='off'
                    className=''
                    size='small'
                    id="outlined-basic"
                    label="Short Name"
                    variant="outlined"
                    margin='dense'
                    value={shortName}
                    onChange={(e) => setShortName(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? shortName : ""}
                    inputProps={{ maxLength: 120 }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </div>

                <div className='col-sm-4 mt-2'>
                  <FormControlLabel style={{ fontSize: "15px", fontWeight: "600" }} control={<Checkbox checked={showGuardianNm} onChange={HandleGuardianNameCheckbox} />} label="Show Guardian Name" />
                </div>
              </div>

              <div className="col-sm-12 mt-3">
                <div className="offset-sm-2">
                  <button type="submit" className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                  <button type="submit" className={updateBtn} onClick={() => (state.button = 2)}>Update</button>
                  <button type="button" className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} onClick={ClearData}>Cancel</button>
                </div>
              </div>
            </div>
          </form>
          {/* Form Data end */}

          <div className="tableData">
            <FeeMasterTable
              TableHeading={TableHeading}
              data={data}
              setId={setId}
              setData={setData}
              setShortName={setShortName}
              setShowGuardianNm={setShowGuardianNm}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              setEnglishName={setEnglishName}
              setMarathiName={setMarathiName}
              setFeeType={setFeeType}
              feeTypeData={feeTypeData}
              saveBtnStyle={saveBtnStyle}
              updateBtnStyle={updateBtnStyle}
              displayNoneBtnStyle={displayNoneBtnStyle}
              setSaveBtn={setSaveBtn}
              setUpdateBtn={setUpdateBtn}
              getData={getData}
            />
          </div>

        </div>
        <ToastContainer position="top-right" theme="colored" />
      </>
    )
  }

}

export default FeeMaster