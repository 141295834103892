import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

export const classWithDivURL = BaseURLMaster + "classWithDivisionBySectionId";
export const getSubjects = BaseURLMaster + "Subjects/";

//Catalog print APIURL
//localhost:8080/institute/sss/academic/reports/generateCatlogReport?did=37&mdata=03/2022&blgb=2&ble=2&langId=1&acYear=2021&sid=3&bid=1
export const printCatalog = BaseURL + "academic/reports/generateCatlogReport";

//localhost:8080/institute/sss/academic/reports/generateCatlogMainPageReport?did=38&mdata=03/2022&langId=1&acYear=2021&sid=3&bid=1
export const printCatalogMainPage = BaseURL + "academic/reports/generateCatlogMainPageReport";


//Catalog end of month APIURL
//localhost:8080/institute/sss/academic/reports/generateCatlogMonthEndPageReport?did=40&mdata=03/2022&langId=1&acYear=2021&sid=3&bid=1
export const printCatalogEndOfMonth = BaseURL + "academic/reports/generateCatlogMonthEndPageReport";


//subject serial APIURL
//localhost:8080/institute/sss/academics/StudentWiseSubjectAllocation/getSubjectSerialNoAllocationData?classId=6&divId=21&acYear=2022&sid=3&bid=1
export const getSubjectDataAPIURL = BaseURL + "academics/StudentWiseSubjectAllocation/getSubjectSerialNoAllocationData";

//localhost:8080/institute/sss/academics/StudentWiseSubjectAllocation/saveSubjectSerialNoAllocationData
export const saveSubjectSerialAPIURL = BaseURL + "academics/StudentWiseSubjectAllocation/saveSubjectSerialNoAllocationData";

//Catalog All Std APIURL
//localhost:8080/institute/sss/academic/reports/generateCatlogMainPageReportAllClass?mdata=09/2022&langId=1&acYear=2022&sid=3&bid=1
export const printCatalogAllStdMainPageAPIURL = BaseURL + "academic/reports/generateCatlogMainPageReportAllClass";

//localhost:8080/institute/sss/academic/reports/generateCatlogReportAllClass?mdata=09/2022&blgb=2&ble=3&langId=1&acYear=2022&sid=3&bid=1
export const printCatalogAllStdDetailPageAPIURL = BaseURL + "academic/reports/generateCatlogReportAllClass";