import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { useSelector } from "react-redux";
import Loader from "../../CommonComponent/Loader";
import Paper from "@mui/material/Paper";
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { printEmployeeReport } from '../Services/EmployeeReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { ToastContainer, toast } from 'react-toastify';
import makeStyles from "@mui/styles/makeStyles";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const leftData = [
    {
        srNo: 1,
        fieldName: "Gender"
    },
    {
        srNo: 2,
        fieldName: "First Name"
    },
    {
        srNo: 3,
        fieldName: "Middle Name"
    },
    {
        srNo: 4,
        fieldName: "Last Name"
    },
    {
        srNo: 5,
        fieldName: "Mobile No"
    },
    {
        srNo: 6,
        fieldName: "Date Of Birth"
    },
    {
        srNo: 7,
        fieldName: "Religion"
    },
    {
        srNo: 8,
        fieldName: "Caste"
    },
    {
        srNo: 9,
        fieldName: "Sub-caste"
    },
    {
        srNo: 10,
        fieldName: "Category"
    },
    {
        srNo: 11,
        fieldName: "Minority"
    },
    {
        srNo: 12,
        fieldName: "PAN No"
    },
    {
        srNo: 13,
        fieldName: "Aadhar No"
    },
    {
        srNo: 14,
        fieldName: "PF Joining Date"
    },
    {
        srNo: 15,
        fieldName: "PF Relieving Date"
    },
    {
        srNo: 16,
        fieldName: "Designation"
    },
    {
        srNo: 17,
        fieldName: "Appointment Date"
    },
    {
        srNo: 18,
        fieldName: "Retirement Date"
    },
    {
        srNo: 19,
        fieldName: "Working Status"
    },
]

const EmployeeReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Employee Report"

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState();
    const [language, setLanguage] = useState(1);
    const [view, setView] = useState(1);
    const [option, setOption] = useState(0);
    //console.log(option)
    const [schoolData, setSchoolData] = useState([]);
    const [schoolObj, setSchoolObj] = useState(null);
    const [schoolId, setSchoolId] = useState(0);
    //console.log(schoolId);
    const [employeeType, setEmployeeType] = useState(0);
    const [employeeStatus, setEmployeeStatus] = useState(1);

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState(leftData);
    const [leftFilteredData, setLeftFilteredData] = useState(leftData);
    const [leftSearchTerm, setLeftSearchTerm] = useState("");
    const [right, setRight] = useState([]);
    const [rightFilteredData, setRightFilteredData] = useState([]);
    const [rightSearchTerm, setRightSearchTerm] = useState("");
    //console.log("rightData=" + JSON.stringify(right.sort((a, b) => a.srNo > b.srNo ? 1 : -1)))
    //+"................."+"LeftData="+JSON.stringify(left))

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        await axios.get(`${BaseURLInstituteMaster}`)
            .then((response) => {
                setSchoolData(response.data);
            })
            .catch((error) => {
                console.log("Institute error:" + error)
            })
    }

    const printEmployeeMasterReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const fieldObjects = right.map(item => ({ fieldName: item.fieldName }));

        console.log(fieldObjects);
        //console.log(`${printEmployeeReport}?fileType=${view}&langId=${language}&schoolId=${schoolId}&employeeType=${employeeType}`)
        await axios.post(`${printEmployeeReport}?fileType=${view}&langId=${language}&schoolId=${(authUser.branchId == 50) ? schoolId : authUser.branchId}&employeeType=${employeeType}&status=${employeeStatus}`, fieldObjects)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("employee Master Report failed: " + error)
            })
    }

    const handleToggle = (value) => () => {
        //console.log(value);    
        const currentIndex = checked.indexOf(value);
        //console.log("Data="+currentIndex)       
        const newChecked = [...checked];

        if (currentIndex === -1) {
            //console.log("Checked="+JSON.stringify(value.id))           
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        //console.log(newChecked.map(e=>e.id).join(','))
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setRightFilteredData(right.concat(left));
        setLeft([]);
        setLeftFilteredData([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setRightFilteredData(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setLeftFilteredData(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setLeftFilteredData(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setRightFilteredData(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setLeftFilteredData(left.concat(right));
        setRight([]);
        setRightFilteredData([]);
    };

    useEffect(() => {
        setLeftFilteredData(
            left.filter((item) =>
                item.srNo.toString().toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                item.fieldName.toLowerCase().includes(leftSearchTerm.toLowerCase())
            ))
    }, [leftSearchTerm])

    useEffect(() => {
        setRightFilteredData(
            right.filter((item) =>
                item.srNo.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                item.fieldName.toLowerCase().includes(rightSearchTerm.toLowerCase())
            ))
    }, [rightSearchTerm])

    const leftListData = (leftdata) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>

            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "200px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={leftSearchTerm}
                        onChange={e => setLeftSearchTerm(e.target.value)}
                    /></span>
                </div>

                {leftdata.sort((a, b) => a.srNo > b.srNo ? 1 : -1).map((value) => {
                    const labelId = value.srNo;
                    return (
                        <ListItem
                            key={value.srNo}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.srNo + ". " + value.fieldName} primaryTypographyProps={{ fontSize: '16px', fontWeight: "540", lineHeight: '1.5' }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const rightListData = (rightData) => (
        <Paper sx={{ width: 250, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>
            <List dense component="div" role="list">
                <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}>
                    <span style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0" }}><input
                        className='form-control form-control-sm my-2'
                        type="text"
                        style={{ border: "1px solid #AFAFAE", float: "right", width: "200px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                        placeholder="Search Here"
                        value={rightSearchTerm}
                        onChange={e => setRightSearchTerm(e.target.value)}
                    /></span>
                </div>
                {rightData.sort((a, b) => a.srNo > b.srNo ? 1 : -1).map((value) => {
                    const labelId = value.srNo;
                    return (
                        <ListItem
                            key={value.srNo}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.srNo + ". " + value.fieldName} primaryTypographyProps={{ fontSize: '16px', fontWeight: "540" }} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                    <form onSubmit={printEmployeeMasterReport}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className="row">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Language :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Language</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={language}
                                        label="Language"
                                        onChange={(e) => {
                                            setLanguage(e.target.value);
                                            setView(1);
                                            setOption(0);
                                            setSchoolId(0);
                                            setSchoolObj(null);
                                            setEmployeeType(0);
                                            setEmployeeStatus(1);
                                        }}
                                    >
                                        <MenuItem value={1}>Marathi</MenuItem>
                                        <MenuItem value={2}>English</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <label className='' style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>View</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={view}
                                        label="View"
                                        onChange={(e) => {
                                            setView(e.target.value);
                                            setOption(0);
                                            setSchoolId(0);
                                            setSchoolObj(null);
                                            setEmployeeType(0);
                                            setEmployeeStatus(1);
                                        }}
                                    >
                                        <MenuItem value={1}>PDF</MenuItem>
                                        <MenuItem value={2}>Excel</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row my-2' hidden={(authUser.branchId == 50) ? false : true}>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required={(authUser.branchId == 50) ? true : false}
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={option}
                                        label="Option"
                                        onChange={(e) => {
                                            setOption(e.target.value);
                                            if (e.target.value == 1) {
                                                setSchoolId(0);
                                            }
                                            setEmployeeType(0);
                                            setEmployeeStatus(1);
                                            setSchoolObj(null);
                                        }}
                                    >
                                        <MenuItem value={0}>All Schools</MenuItem>
                                        <MenuItem value={1}>Selected School</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            {(() => {
                                if (option == 1) {
                                    return (
                                        <>

                                            <label className='' style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>School :</label>
                                            <div className='col-sm-5'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    options={schoolData}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (language == 1) ? option.schoolNameMr : option.schoolNameEn}
                                                    size="small"
                                                    value={schoolObj}
                                                    onChange={(event, newValue) => {
                                                        setSchoolObj(newValue);
                                                        setSchoolId(newValue.id);
                                                        setEmployeeType(0);
                                                        setEmployeeStatus(1);
                                                    }}
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select School" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 1) ? true : false} />
                                                    )}
                                                />
                                            </div>

                                        </>
                                    )
                                }
                            })()}
                        </div>


                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Employee Type :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Employee Type</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={employeeType}
                                        label="Employee Type"
                                        onChange={(e) => {
                                            setEmployeeType(e.target.value);
                                            setEmployeeStatus(1);

                                        }}
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        <MenuItem value={1}>Teaching</MenuItem>
                                        <MenuItem value={2}>Non-Teaching</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <label style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Employee Status :</label>
                            <div className='col-sm-3'>

                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Employee Status</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth
                                        margin='dense'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={employeeStatus}
                                        label="Employee Status"
                                        onChange={(e) => {
                                            setEmployeeStatus(e.target.value);

                                        }}
                                    >
                                        <MenuItem value={1}>All</MenuItem>
                                        <MenuItem value={2}>Current</MenuItem>
                                        <MenuItem value={3}>Retired</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>


                        {(() => {
                            if (view == 2) {
                                return (
                                    <>
                                        <div className="row mt-3">
                                            <div className='col-sm-12 offset-sm-2'>
                                                <Grid container spacing={2} alignItems="center"  >
                                                    <Grid item >{leftListData(leftFilteredData)}</Grid>
                                                    <Grid item>
                                                        <Grid container direction="column" alignItems="center"  >
                                                            <button
                                                                className='my-1'
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleAllRight}
                                                                disabled={left.length === 0}
                                                                aria-label="move all right"
                                                                style={{ width: "30px" }}
                                                            >
                                                                ≫
                                                            </button>
                                                            <button
                                                                className='my-1'
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleCheckedRight}
                                                                disabled={leftChecked.length === 0}
                                                                aria-label="move selected right"
                                                                style={{ width: "30px" }}
                                                            >
                                                                &gt;
                                                            </button>
                                                            <button
                                                                className='my-1'
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleCheckedLeft}
                                                                disabled={rightChecked.length === 0}
                                                                aria-label="move selected left"
                                                                style={{ width: "30px" }}
                                                            >
                                                                &lt;
                                                            </button>
                                                            <button
                                                                className='my-1'
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleAllLeft}
                                                                disabled={right.length === 0}
                                                                aria-label="move all left"
                                                                style={{ width: "30px" }}
                                                            >
                                                                ≪
                                                            </button>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>{rightListData(rightFilteredData)}</Grid>
                                                </Grid>
                                            </div>
                                        </div></>
                                )
                            }
                        })()}


                        <div className="row mt-3">
                            <div className="col-sm-12 offset-sm-2">
                                <button type="submit" className={saveBtn} disabled={((view == 2) ? ((right == [] || right == null || right == "") ? true : false) : false)}>Show</button>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }



}

export default EmployeeReport