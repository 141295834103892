import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

export const categoryDataURl = BaseURLMaster + "Categories/"
export const classDivisionURL = BaseURLMaster + "classWithDivisionBySectionId/";
export const dBaseURL = "http://192.168.1.102:8080/institute/sss/"

//AllCategoryWise report
//localhost:8080/institute/sss/administration/reports/generateCategoryWiseStudentReport?langId=1&acYear=2021&sid=3&bid=1
export const allCategoryReport = BaseURL + "administration/reports/generateCategoryWiseStudentReport";

//AllCategoryClassDivisionWise report
//localhost:8080/institute/sss/administration/reports/generateCategoryWiseClassAndDivisionStudentReport?langId=1&acYear=2021&sid=3&bid=1&classId=10&divId=37
export const categoryClassDivisionReport = BaseURL + "administration/reports/generateCategoryWiseClassAndDivisionStudentReport";

//categoryWise report
//localhost:8080/institute/sss/administration/reports/generateStudentCategoryWiseReport?langId=1&acYear=2021&sid=3&bid=1&categoryId=4
export const categoryWiseReport = BaseURL + "administration/reports/generateStudentCategoryWiseReport";

//classDivAndCategoryWise
//localhost:8080/institute/sss/administration/reports/generateClassDivisionAndCategoryWiseReport?langId=1&acYear=2021&sid=3&bid=1&classId=10&divId=37&categoryId=15
export const classDivAndCategoryWiseReport = BaseURL + "administration/reports/generateClassDivisionAndCategoryWiseReport";


//Category summary APIURL
//localhost:8080/institute/sss/administration/reports/generateAllClassAndDivisionCategorySummaryReport?sid=3&bid=1&acYear=2022&langId=1
export const allCategorySummaryReportAPIURL = BaseURL + "administration/reports/generateAllClassAndDivisionCategorySummaryReport";

//localhost:8080/institute/sss/administration/reports/generateClassAndDivisionWiseCategorySummaryReport?sid=3&bid=1&acYear=2022&classId=5&divId=17,18,19,20&langId=1
export const classDivWiseCategorySummaryReportAPIURL = BaseURL + "administration/reports/generateClassAndDivisionWiseCategorySummaryReport";