import React, { useState, useEffect } from 'react'
import './servicepanel.css';
import TecnoAarvLogo from "../../assets/img/technoAarvLogo.png";
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { serviceLogin, serviceLogout } from '../../reduxFeature/user';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { servicePanelLoginPanel } from '../Service/allEnquiryAPIURL';

const ServiceIndex = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    // const [mobile, setMobile] = useState("")
    // const [email, setEmail] = useState("")


    const loginCheck = async (e) => {
        e.preventDefault();
        await axios.get(`${servicePanelLoginPanel}?username=${userName}&password=${password}`)
            .then((res) => {
                if (res.data !== null) {
                    if (res.data === "NOTFOUND") {
                        toast.error("Incorrect Login Details")
                    }
                    else {

                        const serviceLoginData = {
                            userName: res.data.userName,
                            email: res.data.email,
                            mobile: res.data.mobileNo
                        }

                        dispatch(serviceLogin({ userName: res.data.userName, email: res.data.email, mobile: res.data.mobileNo }))
                        sessionStorage.setItem("servicePanel", JSON.stringify(serviceLoginData));
                        navigate("/service/s001/d001")

                    }
                }
                else {
                    toast.error("Login Failed, Something went Wrong")
                }
            })


    }

    useEffect(() => {
        const getData = sessionStorage.getItem("servicePanel");
        if (getData) {
            console.log(JSON.parse(getData))
            const localUserData = (JSON.parse(getData));

            dispatch(serviceLogin({ userName: localUserData.userName, email: localUserData.email, mobile: localUserData.mobile }));
            navigate("/service/s001/d001");
        }
    }, [])


    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className="row px-4">
                        <form onSubmit={loginCheck}>
                            <div className="LoginContainer col-sm-4 offset-sm-4 mb-3">
                                <div className="loginHeader p-3">
                                    <img src={TecnoAarvLogo} alt="" style={{ width: "180px" }} />
                                </div>
                                <div className="mb-2 px-2">
                                    <label for="formGroupExampleInput" className="form-label fw-bolder lableFont">User Name</label>
                                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control form-control-sm" id="formGroupExampleInput" placeholder="Enter user name" />
                                </div>
                                <div className="mb-2 px-2">
                                    <label for="formGroupExampleInput2" className="form-label fw-bolder lableFont">Password</label>
                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control form-control-sm" id="formGroupExampleInput2" placeholder="Enter password" />
                                </div>
                                <div className="mb-3 p-2">
                                    <button type='submit' className='btn btn-sm btnLogin'>Login</button>
                                </div>
                            </div>
                        </form>
                        <div className='text-center'>
                            <p>Purna Academics Service Panel</p>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme='colored' />
        </>
    )
}

export default ServiceIndex