import React, { useState, useEffect } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Loader from '../../CommonComponent/Loader';
import { ToastContainer, toast } from 'react-toastify';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddNewAuthorMasterModal from '../CommonComponents/AddNewAuthorMasterModal';
import { getAuthorMasterData } from '../LibraryServices/AuthorMasterAPIURL';
import { getPublisherMasterData } from '../LibraryServices/PublisherMasterAPIURL';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { saveTitleMaster, getTitleMasterById, getTitleMasterData, updateTitleMaster } from '../LibraryServices/TitleMasterAPIURL';
import AddNewPublisherModal from '../CommonComponents/AddNewPublisherModal';

const TitleMaster = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const [titleId,setTitleId]=useState("");
    let { tId, EditFlag } = useParams();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "titleLibraryFormSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "titleLibraryFormUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [titleName, setTitleName] = useState("");
    const [subTitleName, setSubTitleName] = useState("");
    const [authorData, setAuthorData] = useState([]);
    const [authorObj, setAuthorObj] = useState([]);
    const [authorId, setAuthorId] = useState(null);
    const [publisherData, setPublisherData] = useState([]);
    const [publisherObj, setPublisherObj] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [showAuthorModal, setShowAuthorModal] = useState(false);
    const [showPublisherModal, setShowPublisherModal] = useState(false);

    let res2 = authorData.filter((el1) => {
        if (authorObj.some((el2) => el2.id === el1.id) == false) {
            return el1
        }
    })

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setTitleId("Title Master");
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setTitleId("Update Title Master");
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getApiData = async () => {

        if (authUser.branchId != null) {
            //console.log(`${getAuthorMasterData}?branchId=${authUser.branchId}`)
            await axios.get(`${getAuthorMasterData}?branchId=${authUser.branchId}`)
                .then(res => {
                    setAuthorData(res.data);
                })

            //console.log(`${getPublisherMasterData}?branchId=${authUser.branchId}`)
            await axios.get(`${getPublisherMasterData}?branchId=${authUser.branchId}`)
                .then(res => {
                    setPublisherData(res.data);
                })

            console.log(`${getTitleMasterData}?branchId=${authUser.branchId}`)
            await axios.get(`${getTitleMasterData}?branchId=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                })
        }


    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        console.log(`${getTitleMasterById}?id=${tId}`)
        await axios.get(`${getTitleMasterById}?id=${tId}`)
            .then(res => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setTitleName(res.data.titleName);
                setSubTitleName(res.data.subTitleName);
                let publisherId = res.data.publisherId
                setAuthorObj(res.data.authorId);
                setAuthorId((res.data.authorId).map(e => e.id).join(','));
                setId(res.data.id);

                if (authUser.branchId != null) {
                    axios.get(`${getAuthorMasterData}?branchId=${authUser.branchId}`)
                        .then(res1 => {
                            //console.log(res2);
                            setAuthorData(res1.data);
                        })

                    axios.get(`${getPublisherMasterData}?branchId=${authUser.branchId}`)
                        .then(res => {
                            setPublisherData(res.data);
                            setPublisherObj((res.data).find(e => e.id == publisherId))
                        })
                }

                setLoaderOption(false);
            })
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "titleName": titleName,
            "subTitleName": subTitleName,
            "authorId": authorId,
            "publisherId": publisherObj.id,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveTitleMaster, saveObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`This title is already exists.`)
                }
                else if (response.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "titleName": titleName,
            "subTitleName": subTitleName,
            "authorId": authorId,
            "publisherId": publisherObj.id,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(updateObj))
        await axios.put(updateTitleMaster, updateObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`This title is already exists.`)
                }
                else if (response.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/l805/')
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.titleLibraryFormSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.titleLibraryFormUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Title Name :</label>
                                    <div className="col-sm-4">
                                        <TextField
                                            fullWidth
                                            required
                                            onKeyDown={(e) => (e.key === " " && titleName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? titleName : ""}
                                            label="Title Name"
                                            value={titleName}
                                            onChange={(e) => setTitleName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Sub-Title Name :</label>
                                    <div className="col-sm-4">
                                        <TextField
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && subTitleName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? subTitleName : ""}
                                            label="Sub-Title Name"
                                            value={subTitleName}
                                            onChange={(e) => setSubTitleName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Author Name :</label>
                                    <div className="col-sm-4 ">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            multiple
                                            disableCloseOnSelect
                                            filterSelectedOptions
                                            options={(EditFlag == 2) ? res2 : authorData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.authorName}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setAuthorObj(newValue);
                                                setAuthorId(newValue.map(e => e.id).join(','))
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? authorObj : ""}
                                            value={authorObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Author" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(authorObj.length == 0) ? true : false}/>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-4 mt-2">
                                        <button type='button' className='btn p-0 mt-2' onClick={() => setShowAuthorModal(true)}>
                                            <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                        </button>
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Publisher Name :</label>
                                    <div className="col-sm-4 ">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={publisherData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.name}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setPublisherObj(newValue);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? publisherObj : ""}
                                            value={publisherObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Publisher" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-4 mt-2">
                                        <button type='button' className='btn p-0 mt-2' onClick={() => setShowPublisherModal(true)}>
                                            <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                        </button>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} style={{ margin: "0px 4px" }}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger' style={{ margin: "0px 4px" }}>Cancel</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
            }
            <ToastContainer position="top-right" theme="colored" />
            <AddNewAuthorMasterModal showAuthorModal={showAuthorModal} setShowAuthorModal={setShowAuthorModal} getApiData={getApiData} />
            <AddNewPublisherModal showPublisherModal={showPublisherModal} setShowPublisherModal={setShowPublisherModal} getApiData={getApiData} />
        </>

    )
}

export default TitleMaster