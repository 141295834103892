import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";


//Internal Seat No APIURL
//localhost:8080/institute/sss/academics/seatNumberAllocation/getSeatNoAllocationList?classId=10&divId=37&sessionYear=2022&sid=3&bid=1
export const getStudentListForInternalSeatNo = BaseURL + "academics/seatNumberAllocation/getSeatNoAllocationList";

//localhost:8080/institute/sss/academics/seatNumberAllocation/saveSeatNoAllocationList
// {
//     "branchId": 1,
//     "classId": 10,
//     "rollNo": 1,
//     "regNo": 11660,
//     "divisionId": 37,
//     "gender": "F",
//     "studentNameMr": "आठवले समिक्षा अतुल",
//     "studentNameEn": "  ",
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "seatNo": 103
// }
export const saveStudentListForInternalSeatNo = BaseURL + "academics/seatNumberAllocation/saveSeatNoAllocationList";

//Board Seat No APIURL
//localhost:8080/institute/sss/academics/seatNumberAllocation/getBoardSeatNoAllocationList?classId=5&divId=17&sessionYear=2022&sid=3&bid=1
export const getStudentListForBoardSeatNo = BaseURL + "academics/seatNumberAllocation/getBoardSeatNoAllocationList";

//localhost:8080/institute/sss/academics/seatNumberAllocation/saveBoardSeatNoAllocationList
// [
//     {
//         "classId": 5,
//         "divisionId": 17,
//         "regNo": 12707,
//         "boardSeatNo": 47706,
//         "prefix": "F0",
//         "sessionYear": 2022,
//         "sectionId": 3,
//         "branchId": 1
//     }
// ]
export const saveStudentBoardSeatNo = BaseURL + "academics/seatNumberAllocation/saveBoardSeatNoAllocationList";