import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/budget/transaction/saveBudgetResolutionNo
// {
//     "resolutionNo": 1,
//     "date": "2023-01-04",
//     "sessionYear": 2022,
//     "branchId": 1
// }
export const saveBudgetResolutionNoDateAPIURL = BaseURL + "budget/transaction/saveBudgetResolutionNo";

//localhost:8080/institute/sss/budget/transaction/deleteBudgetResolutionNo?id=1
export const deleteBudgetResolutionNoDateAPIURL = BaseURL + "budget/transaction/deleteBudgetResolutionNo";

//localhost:8080/institute/sss/budget/transaction/getBudgetResolutionNo?sessionYear=2022&bid=1
export const getBudgetResolutionNoDateAPIURL = BaseURL + "budget/transaction/getBudgetResolutionNo";