import React from 'react'
import { useSelector } from 'react-redux';
import FeeReceipt from './FeeReceipt';
import MultipleFeeReceiptForm from './MultipleFeeReceiptForm'

const FeeReceiptForm = () => {
    const authUser = useSelector((state) => state.user.value);
    // console.log(authUser)

    let disableEditFlag = sessionStorage.getItem('FormProcessingFlag'); // Assuming it's a string
    disableEditFlag = JSON.parse(disableEditFlag); // Parse string to object

    // if (authUser.feeType != "" || authUser.feeType != null) {
    //     if (authUser.feeType === 2) {
    //         return (

    //             <MultipleFeeReceiptForm />
    //         )
    //     }
    //     else {
    //         return (
    //             <FeeReceipt />

    //         )
    //     }
    // }


    if (disableEditFlag && disableEditFlag[4] == 1) {
        return (

            <MultipleFeeReceiptForm />
        )
    }
    else {
        return (
            <FeeReceipt />

        )
    }

}

export default FeeReceiptForm
