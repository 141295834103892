import React, { useState } from 'react'

const ExamMarkEntryTableRow1 = ({ examMarkStatus, status, s1, s1_max, onChange }) => {

    const [statuss, setStatuss] = useState(status);
    const [s1text, sets1Text] = useState(s1);

    const [disableText, setDisableText] = useState((status == 1) ? false : true);

    console.log(disableText)

    const handleTextBox = (num) => {
        sets1Text("");
        if (num == 1) {
            setDisableText(false);
        }
        else if (num == 2) {
            setDisableText(true);
        }
    }
    return (
        <>
            <td>
                <select
                    value={statuss}
                    disabled={(examMarkStatus == 1) ? true : false}
                    onChange={e => {
                        setStatuss(e.target.value);
                        handleTextBox(e.target.value);
                    }}
                    onBlur={() => { onChange(statuss, s1text) }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text) : ""}
                >
                    <option value={1}>Present</option>
                    <option value={2}>Absent</option>
                </select>
            </td>
            <td>
                <input
                    type='number'
                    disabled={disableText || examMarkStatus == 1}
                    style={{ width: "50px", fontSize: "14px", fontWeight: "600" }}
                    onBlur={() => { onChange(statuss, s1text) }}
                    onChange={(e) => sets1Text((e.target.value != "") ? e.target.value : "")}
                    value={s1text}
                    min="0"
                    max={s1_max}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(statuss, s1text) : ""}
                    onKeyDown={(e) => (e.which === 38 || e.which === 40) ? e.preventDefault() : ''}
                />
            </td>
        </>
    )
}

export default ExamMarkEntryTableRow1