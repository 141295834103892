import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';
import {
    BaseURLSection, deleteInstituteDepartmentAllocation, getInstituteDepartmentAllocation, saveInstituteDepartmentAllocation
} from '../../../CommonServices/InstituteMasterAPIURL';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const BranchWiseDepartment = ({ branchId }) => {
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const navigate = useNavigate();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: 'Sr No', key: 'srNo' },
        { label: `Departments`, key: 'departments' },
        { label: 'Header Name', key: 'headerName' },
        { label: 'Delete', value: 'delete', isAction: true }];

    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [loaderOption, setLoaderOption] = useState(false);
    const [id, setId] = useState('');
    const [srNo, setSrNo] = useState('');
    const [headerName, setHeaderName] = useState('')


    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {

        await axios.get(`${BaseURLSection}`)
            .then((response) => {
                const sortData = (response.data).filter(e => e.acApl === 0)
                setDepartmentData(response.data);
            })

        await axios.get(`${getInstituteDepartmentAllocation}?bid=${branchId}`)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
            })
    }


    //console.log("Data : " + JSON.stringify(filteredData))

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = (id) => {

        axios.delete(`${deleteInstituteDepartmentAllocation}?id=${id}`)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    toast.success("Deleted successfully");
                }
            });
    }


    const saveBtnClick = () => {

        const saveObj = {
            "branchId": branchId,
            "departmentId": departmentObj.id,
            "srNo": srNo,
            "headerName": headerName,
        }
        console.log(JSON.stringify(saveObj))
        axios.post(saveInstituteDepartmentAllocation, saveObj)
            .then((response) => {
                if (response.data === "DepartmentExist") {
                    setLoaderOption(false);
                    toast.warn(`This department is already exists for this branch.`)
                }
                else if (response.data === "SrNoExist") {
                    setLoaderOption(false);
                    toast.warn(`This sr No is already allocated.`)
                }
                else if (response.data == "SAVED") {
                    getData();
                    ClearData();
                    setLoaderOption(false);
                    toast.success("Department successfully allocated to branch.")
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });

    }


    const ClearData = () => {
        setDepartmentObj(null);
        setSrNo('');
        setHeaderName('')
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    return (
        <>
            <form>
                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Departments :</label>
                    <div className='col-sm-4'>
                        <Autocomplete
                            id="combo-box-demo"
                            options={departmentData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={departmentObj}
                            onChange={(event, newValue) => {
                                setDepartmentObj(newValue);
                                setSrNo('');
                                setHeaderName('')
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Select Department" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                            )}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Header Name:</label>
                    <div className='col-sm-4'>
                        <TextField
                            required
                            onKeyDown={(e) => (e.key === " " && headerName.length === 0) ? e.preventDefault() : ""}
                            fullWidth
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="Header Name"
                            variant="outlined"
                            margin='dense'
                            value={headerName}
                            onChange={(e) => setHeaderName(e.target.value)}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ maxLength: 120 }}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Sr No:</label>
                    <div className='col-sm-3'>
                        <TextField
                            required
                            onKeyDown={(e) => (e.key === " " && srNo.length === 0) ? e.preventDefault() : ""}
                            fullWidth
                            type='number'
                            onWheel={(e) => e.target.blur()}
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="Sr No"
                            variant="outlined"
                            margin='dense'
                            value={srNo}
                            onChange={(e) => setSrNo(e.target.value)}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ maxLength: 120 }}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-4 offset-sm-2">
                        <button type="button" disabled={(departmentObj == '' || departmentObj == null) ? true : false} className={saveBtn} onClick={() => saveBtnClick()}>Save</button>
                        {/* <button type="button" disabled={(departmentObj == '' || departmentObj == null) ? true : false} className={updateBtn} onClick={() => updateBtnClick()}>Update</button> */}
                        <button type="button" className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/m129/0")}>Cancel</button>
                    </div>
                </div>
            </form>

            <div className="row mt-5">
                <div className="col-sm-8">
                    <div className='table-responsive' style={{ maxHeight: "350px" }}>
                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <th style={{ fontSize: "14px", fontWeight: "600" }} key={index} scope="col">{item.label}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                {filteredData.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.srNo}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        {
                                                            departmentData.map(e => {
                                                                if (e.id == item.departmentId) {
                                                                    return (authUser.branchMedium == 1) ? e.nameMr : e.nameEn
                                                                }
                                                            })
                                                        }
                                                    </td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.headerName}</td>
                                                    <td>
                                                        <Tooltip title="Delete">
                                                            <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                onClick={() => setDeleteId(item.id)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default BranchWiseDepartment