import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'

const EditTimeTableDataModal = ({ getClassByEmpAndDiv, getSubByEmpClassAndDiv, classData, classObj, setClassObj, divData, divObj, setDivObj, subjectData, subjectObj, setSubjectObj,
    periodType, setPeriodType, batchName, setBatchName, authUser, setClassData, setDivData, setSubjectData, saveTimeTable,
    saveBtn, updateBtn, state }) => {


    // Function to clear data
    const clearData = () => {
        setClassObj(null);
        setDivObj(null);
        setPeriodType('');
        setSubjectObj(null);
        setBatchName('');
        setDivData([]);
        setSubjectData([]);
    };

    // Effect to clear data when modal closes
    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                clearData();

            }
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
            clearData(); // Clear data when unmounting
        };
    }, []); // Empty dependency array ensures this effect runs only once

    return (
        <div className="modal fade" id="timeTableModal" tabIndex="-1" aria-labelledby="timeTableModalLabel" aria-hidden="true" data-backdrop="false">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header" style={{ backgroundColor: "#E5E7E9" }}>
                        <h4 className="modal-title" id="timeTableModalLabel">Modify Period</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setClassObj(null); setDivObj(null); setPeriodType(''); setSubjectObj(null);
                                setBatchName(''); setDivData([]); setSubjectData([]);
                            }}
                        ></button>
                    </div>
                    <form onSubmit={saveTimeTable}>
                        <div className="modal-body">

                            <div className="row">
                                <label htmlFor="lastname3" className="" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px", width: '120px' }}>Class :</label>
                                <div className='col-sm-8'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={classData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        onChange={(event, newValue) => {
                                            setClassObj(newValue);
                                            setDivObj(null);
                                            setSubjectObj(null);
                                            setPeriodType('');
                                            setBatchName('');
                                            if (newValue != null) {
                                                getClassByEmpAndDiv(newValue.id)
                                            }
                                        }}
                                        value={classObj}
                                        onKeyPress={(e) => (e.key == "Enter") ? classObj : ""}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label htmlFor="lastname3" className="" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px", width: '120px' }}>Division :</label>
                                <div className='col-sm-8'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={divData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        onChange={(event, newValue) => {
                                            setDivObj(newValue);
                                            setSubjectObj(null);
                                            setPeriodType('');
                                            setBatchName('');
                                            if (newValue != null) {
                                                getSubByEmpClassAndDiv(newValue.id)
                                            }
                                        }}
                                        value={divObj}
                                        onKeyPress={(e) => (e.key == "Enter") ? divObj : ""}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label htmlFor="lastname3" className="" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px", width: '120px' }}>Subject :</label>
                                <div className='col-sm-8'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={subjectData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        onChange={(event, newValue) => {
                                            setSubjectObj(newValue);
                                            setPeriodType('');
                                            setBatchName('');

                                        }}
                                        value={subjectObj}
                                        onKeyPress={(e) => (e.key == "Enter") ? subjectObj : ""}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px", width: '120px' }}>Period-Type :</label>
                                <div className="col-sm-8">
                                    <FormControl fullWidth>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Period-Type</InputLabel>
                                        <Select
                                            size='small'
                                            required
                                            fullWidth
                                            margin='dense'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={periodType}
                                            label="Select Period-Type"
                                            onChange={(e) => {
                                                setPeriodType(e.target.value);
                                                setBatchName('');
                                            }}
                                        >
                                            <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Theory</MenuItem>
                                            <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Practical</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", width: '120px' }}>Batch Name :</label>
                                <div className="col-sm-8">
                                    <TextField
                                        // required
                                        type="text"
                                        onKeyDown={(e) => (e.key === " " && batchName.length === 0) ? e.preventDefault() : ""}
                                        fullWidth
                                        onWheel={(e) => e.target.blur()}
                                        value={batchName}
                                        onChange={(e) => setBatchName(e.target.value)}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Batch Name"
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="submit" className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                            <button type="submit" className={updateBtn} onClick={() => (state.button = 2)}>Update</button>
                            <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal"
                                onClick={() => {
                                    setClassObj(null); setDivObj(null); setPeriodType(''); setSubjectObj(null);
                                    setBatchName(''); setDivData([]); setSubjectData([]);
                                }}
                            >Cancle</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditTimeTableDataModal
