import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../../CommonComponent/Loader';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { getBookBankGroupDetails, getBookBankIssueMemberList, printOnlyIssuedBookBankReport, printAllBookBankIssueReport } from '../Services/CirculationReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';

const useStyles = makeStyles({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
});

const BookBankReport = () => {
    const titleId = "Book Bank Report";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState('');
    const [option, setOption] = useState(1);
    const [memberData, setMemberData] = useState(null);
    const [memberObj, setMemberObj] = useState(null);
    const [groupObj, setGroupObj] = useState(null);
    const [groupData, setGroupData] = useState([]);
    const [type, setType] = useState(1);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.branchId != null) {
            await axios.get(`${getBookBankGroupDetails}?branchId=${authUser.branchId}`)
                .then(res => {
                    setGroupData(res.data);
                })
        }

        if (authUser.branchId != null && authUser.sessionYear != "") {
            await axios.get(`${getBookBankIssueMemberList}?sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    setMemberData(res.data);
                })
        }
    }

    const getOnlyIssuedBookBankReport = async () => {
        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != "Invalid date" && moment(dateTo).format("DD/MM/YYYY") != "Invalid date") {
            //console.log(`${printOnlyIssuedBookBankReport}?langId=${authUser.branchMedium}&dateFrom=${moment(dateFrom).format("YYYY-MM-DD")}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&option=${option}&grpMemId=${(option == 1) ? groupObj.id : memberObj.memberId}`)
            await axios.get(`${printOnlyIssuedBookBankReport}?langId=${authUser.branchMedium}&dateFrom=${moment(dateFrom).format("YYYY-MM-DD")}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&option=${option}&grpMemId=${(option == 1) ? groupObj.id : memberObj.memberId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Book is not issued to this member.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Book Bank Report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const getAllBookbankReport = async () => {
        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != "Invalid date" && moment(dateTo).format("DD/MM/YYYY") != "Invalid date") {
            //console.log(`${printAllBookBankIssueReport}?langId=${authUser.branchMedium}&dateFrom=${moment(dateFrom).format("YYYY-MM-DD")}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&option=${option}&grpMemId=${(option == 1) ? groupObj.id : memberObj.memberId}`)
            await axios.get(`${printAllBookBankIssueReport}?langId=${authUser.branchMedium}&dateFrom=${moment(dateFrom).format("YYYY-MM-DD")}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}&sessionYear=${authUser.sessionYear}&bid=${authUser.branchId}&option=${option}&grpMemId=${(option == 1) ? groupObj.id : memberObj.memberId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Book Bank Report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const printBookBankreport = (e) => {
        e.preventDefault();
        if (type == 2) {
            getOnlyIssuedBookBankReport();
        }
        else if (type == 1) {
            getAllBookbankReport();
        }
    }

    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "250px" }}>
                            {/* Form Data start */}
                            <form onSubmit={printBookBankreport}>
                                <div className="form-data">

                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />                                    

                                    <div className='row my-2'>
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Option</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    required
                                                    fullWidth
                                                    margin='dense'
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Option"
                                                    onChange={(e) => {
                                                        setOption(e.target.value);
                                                        setMemberObj(null);
                                                        setGroupObj(null);
                                                        setType(1);
                                                        setDateFrom(null);
                                                        setDateTo(null);
                                                    }}
                                                >
                                                    <MenuItem value={1}>Group Wise</MenuItem>
                                                    <MenuItem value={2}>Member Wise</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {(() => {
                                        if (option == 1) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Book-bank Group :</label>
                                                        <div className='col-sm-3'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                options={groupData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={(option) => option.name}
                                                                size="small"
                                                                onChange={(event, newValue) => {
                                                                    setGroupObj(newValue);
                                                                    setType(1);
                                                                    setDateFrom(null);
                                                                    setDateTo(null);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? groupObj : ""}
                                                                value={groupObj}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin='dense' label="Select Book-bank Group" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Member :</label>
                                                        <div className='col-sm-3'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                options={memberData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={(option) => option.memberName}
                                                                size="small"
                                                                onChange={(event, newValue) => {
                                                                    setMemberObj(newValue);
                                                                    setType(1);
                                                                    setDateFrom(null);
                                                                    setDateTo(null);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? memberObj : ""}
                                                                value={memberObj}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin='dense' label="Select Member" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                    <div className='row my-2'>
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Type :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size='small' style={{ fontSize: '14px' }}>Type</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    required
                                                    fullWidth
                                                    margin='dense'
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={type}
                                                    label="Type"
                                                    onChange={(e) => {
                                                        setType(e.target.value);
                                                        setDateFrom(null);
                                                        setDateTo(null);
                                                    }}
                                                >
                                                    <MenuItem value={1}>All</MenuItem>
                                                    <MenuItem value={2}>Only Issued</MenuItem>
                                                    {/* <MenuItem value={3}>Only Returned</MenuItem> */}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Issue Date From:</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date From"
                                                    value={dateFrom}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateFrom(newValue);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            margin='dense'
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date To"
                                                    value={dateTo}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateTo(newValue);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            margin='dense'
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default BookBankReport