import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";


// /localhost:8080/institute/sss/academics/exam/getSubjectObservationMasterList?year=2022&sid=3&bid=1
export const getSubjectObsMasterAPIURL = BaseURL + "academics/exam/getSubjectObservationMasterList";

//localhost:8080/institute/sss/academics/exam/saveSubjectObservationMaster
// {
//     "classId": 5,
//     "subjectId": 1,
//     "boysObservation": "boysObservation marathi",
//     "girlsObservation": "girlsObservation marathi",
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveSubjectObsMasterAPIURL = BaseURL + "academics/exam/saveSubjectObservationMaster";

// /localhost:8080/institute/sss/academics/exam/updateSubjectObservationMaster
export const updateSubjectObsMasterAPIURL = BaseURL + "academics/exam/updateSubjectObservationMaster";

//localhost:8080/institute/sss/academics/exam/deleteSubjectObservationMaster?id=1
export const deleteSubjectObsMasterAPIURL = BaseURL + "academics/exam/deleteSubjectObservationMaster";