import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AccTransferModal from './AccTransferModal';
import { toast } from 'react-toastify';
import { saveAccountClosingTransferAPIURL } from '../Services/AccountClosingTransferAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const AccountClosingTransferTable = ({ TableHeading, setFilteredData, setData, filteredData, data, setLoaderOption }) => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [modal, setModal] = useState(false);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.groupNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.groupNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.groupCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.closingAmount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.accountCode.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const onSubmit = e => {
        e.preventDefault();
        setModal(true);
    }

    const saveAccClosingTransfer = async () => {
        setLoaderOption(true);
        const updatedData = data
        updatedData.map((item, index) => {
            updatedData[index] = {
                "groupId": item.groupId,
                "accountCode": item.accountCode,
                "openingBalance": item.closingAmount,
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
        })
        console.log(JSON.stringify(updatedData))
        await axios.post(saveAccountClosingTransferAPIURL, updatedData)
            .then(res => {
                if (res.data == "SAVED") {
                    setFilteredData([]);
                    setData([]);
                    setLoaderOption(false);
                    toast.success("Next year A/C opening updation successfully done.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("A/C closing transfer save err " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const handelkeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            let btn = document.querySelector('.accClosingSaveBtn')
            if (btn !== null) {
                btn.click();
            }
        }
    };

    useEffect(() => {
        //console.log('useEffect Called');
        document.addEventListener("keypress", handelkeyPress);

        return () => {
            // remove the event handelkeyPress
            document.removeEventListener("keypress", handelkeyPress);
        };
    }, []);

    return (
        <>
            <div className='row'>
                <div >
                    <input
                        className='form-control form-control-sm'
                        type="text"
                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                    />
                </div>
            </div>

            <form onSubmit={onSubmit}>
                <div className='table-responsive'>
                    <table className="table table-bordered">
                        <thead className="table-Default">
                            <tr>
                                {TableHeading.map((item, index) => {
                                    return (
                                        <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: '1.5' }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td style={{ fontSize: "15px", fontWeight: "500", width: '100px' }}>{item.accountCode}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5', }}>{(authUser.branchMedium == 1) ? item.accountNameMr : item.accountNameEn}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5', }}>{item.groupCode + " - " + ((authUser.branchMedium == 1) ? item.groupNameMr : item.groupNameEn)}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5', textAlign: 'right', width: '200px' }}>{parseFloat(item.closingAmount).toFixed('2')}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5', textAlign: 'right', width: '200px' }}>{parseFloat(item.closingAmount).toFixed('2')}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                        </tbody>
                        <tfoot style={{ padding: "0px" }}>
                            <tr>
                                <TablePagination
                                    style={{ padding: "0px" }}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                    onPageChange={onPageChange}
                                    labelRowsPerPage="Rows Per Page :"
                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-4">
                        <button type='submit' className='accClosingSaveBtn btn btn-sm btn-warning'><DoubleArrowIcon fontSize='small' /> Transfer</button>

                    </div>
                </div>
            </form>

            <AccTransferModal modal={modal} setModal={setModal} authUser={authUser} saveAccClosingTransfer={saveAccClosingTransfer} />
        </>
    )
}

export default AccountClosingTransferTable