import React, { useState, useEffect } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getRegisterTypeDataAPIURL, getRegisterTypeDataById, saveRegisterTypeDataAPIURL, updateRegisterTypeDataAPIURL } from "../LibraryServices/RegisterTypeMasterAPIURL";
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const RegisterTypeMaster = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    let { rId, EditFlag } = useParams();
    const [titleId, setTitleId] = useState('');

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "registerTypeLibrarySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "registerTypeLibraryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [registerName, setRegisterName] = useState("");
    const [barCodeLabel, setBarCodeLabel] = useState("");
    const [isSubscription, setIsSubscription] = useState(0);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const HandleIsSubscriptionCheckbox = (e) => {
        setIsSubscription(e.target.checked);
        if (e.target.checked == true) {
            setIsSubscription(1);
        }
        else {
            setIsSubscription(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setTitleId("Register Type Master");
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setTitleId("Update Register Type");
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${getRegisterTypeDataById}?id=${rId}`)
            .then(res => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setId(res.data.id);
                setRegisterName(res.data.registerTypeName);
                setBarCodeLabel(res.data.barcodeId);
                setIsSubscription(res.data.isPeriodical);
                setLoaderOption(false);
            }).catch(err => {
                console.log(err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }


    const getApiData = async () => {
        if (authUser.branchId != null) {
            await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                })
        }
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/l809/')
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "registerTypeName": registerName,
            "barcodeId": barCodeLabel,
            "isPeriodical": isSubscription,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveRegisterTypeDataAPIURL, saveObj)
            .then(res => {
                if (res.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Register type with this data is already exists.`)
                }
                else if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.")
                }
            }).catch(err => {
                console.log(err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "registerTypeName": registerName,
            "barcodeId": barCodeLabel,
            "isPeriodical": isSubscription,
            "branchId": authUser.branchId
        }
        console.log(JSON.stringify(updateObj))
        await axios.put(updateRegisterTypeDataAPIURL, updateObj)
            .then(res => {
                if (res.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Register type with this data is already exists.`)
                }
                else if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Successfully.")
                }
            }).catch(err => {
                console.log(err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.registerTypeLibrarySaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.registerTypeLibraryUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Register Type Name :</label>
                                    <div className="col-sm-4">
                                        <TextField
                                            fullWidth
                                            required
                                            onKeyDown={(e) => (e.key === " " && registerName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? registerName : ""}
                                            label="Register Type Name"
                                            value={registerName}
                                            onChange={(e) => setRegisterName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Barcode Id :</label>
                                    <div className="col-sm-4">
                                        <TextField
                                            fullWidth
                                            required
                                            onKeyDown={(e) => (e.key === " " && barCodeLabel.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? barCodeLabel : ""}
                                            label="Barcode Id"
                                            value={barCodeLabel}
                                            onChange={(e) => setBarCodeLabel(e.target.value)}
                                        />
                                    </div>
                                </div>

                                {/* <div className="row my-3">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "10px" }}>Is Periodical :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Periodical</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                margin='dense'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={isSubscription}
                                                label="Select Periodical"
                                                onChange={(e) => {
                                                    setIsSubscription(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={1}>Yes</MenuItem>
                                                <MenuItem value={2}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div> */}

                                <div className="row my-2">
                                    <div className="col-sm-3 offset-sm-2">
                                        <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} checked={isSubscription} onChange={HandleIsSubscriptionCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Is Periodical" />
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} style={{ margin: "0px 4px" }}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger' style={{ margin: "0px 4px" }}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default RegisterTypeMaster