import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/academics/progressCardRemark/getProgressCardRemark?classId=5&termId=1&year=2022&sid=3&bid=1
export const getProgressCardMsgAPIURL = BaseURL + "academics/progressCardRemark/getProgressCardRemark";

// /localhost:8080/institute/sss/academics/progressCardRemark/saveProgressCardRemark
// {
//     "classId": 5,
//     "termId": 1,
//     "remark": "remark",
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveProgressCardMsgAPIURL = BaseURL + "academics/progressCardRemark/saveProgressCardRemark";