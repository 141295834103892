import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { getClassWithDiv } from '../../Academic/Services/StudentRollNoAPIURL';
import Checkbox from '@mui/material/Checkbox';
import { getStudentListForBPLAssign, saveBplStudentsAPIURl } from '../Services/BPLStudentsAPIURL';
import Loader from '../../CommonComponent/Loader';



const BplStudents = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "BPL Students";

    const TableHeading = [
        { label: 'Reg No', key: 'regNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Roll No`, key: 'rollNo' },
        { label: 'BPL', key: 'isBPL', isAction: true },
    ];

    const [loaderOption, setLoaderOption] = useState(false);
    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [filteredData, setFilteredData] = useState([]);
    const [checkValue, setCheckValue] = useState([]);
    const [chkRegNum, setChkRegNum] = useState('');
    const [Val, setVal] = useState(false);

    //console.log("checkValue: " + JSON.stringify(checkValue));

    const handleChkBox = (val, regNum, dId, cId, rNo) => {
        setVal(val);

        const saveObject = {
            classId: cId,
            divisionId: dId,
            regNo: regNum,
            rollNo: rNo,
            sessionYear: authUser.sessionYear,
            sectionId: authUser.deptId,
            branchId: authUser.branchId,
            isBPL: 1
        }

        if (val == true) {
            setCheckValue(checkValue.concat(saveObject));

            //if checkbox is checked
            let updatedData = data;
            data.map((e, index) => {
                if (e.regNo == saveObject.regNo) {
                    updatedData[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isBPL: 1
                    })
                }

            })

            if (searchTerm == "") {
                setData(updatedData);
                setFilteredData(updatedData)
            }
        }
        else if (val == false) {
            const removedData = checkValue.filter((item) => item.regNo != regNum)
            setCheckValue(removedData);

            //if checkbox is unChecked
            let updatedData = data;
            data.map((e, index) => {
                if (e.regNo == saveObject.regNo) {
                    updatedData[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isBPL: 0
                    })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setData(updatedData);
                setFilteredData(updatedData);
            }
        }
        setChkRegNum(saveObject.regNo);
    }

    useEffect(() => {
        if (Val == true) {
            let updatedDataTrue = data
            data.map((e, index) => {
                if (e.regNo == chkRegNum) {
                    updatedDataTrue[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isBPL: 1
                    })
                }
            })
            if (searchTerm == "") {
                setChkRegNum('');
                setData(updatedDataTrue);
                setFilteredData(updatedDataTrue);
            }
        }
        else if (Val == false) {
            //console.log(checkDivId)
            let updatedDataFalse = data
            data.map((e, index) => {
                if (e.regNo == chkRegNum) {
                    updatedDataFalse[index] = ({
                        ...e,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isBPL: 0
                    })
                }
            })

            //console.log(JSON.stringify(updatedDataFalse))
            if (searchTerm == "") {
                setChkRegNum('');
                setData(updatedDataFalse);
                setFilteredData(updatedDataFalse);
            }
        }
    }, [searchTerm, Val, chkRegNum])


    //get student list for bpl allocation
    const getStudentData = async (cId, dId) => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        setCheckValue([]);
        setSearchTerm("");
        setSortedBy(null);
        setAscending(false);
        await axios.get(`${getStudentListForBPLAssign}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${cId}&divId=${dId}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setLoaderOption(false);

                //singleChecked data
                let SaveObj = (res.data).filter(e => {
                    return e.isBPL == 1
                })
                let updatedData = SaveObj;
                SaveObj.map((e, index) => {
                    updatedData[index] = ({
                        classId: e.classId,
                        divisionId: e.divisionId,
                        regNo: e.regNo,
                        rollNo: e.rollNo,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isBPL: 1
                    })
                })
                setCheckValue(updatedData);

            }).catch(error => {
                console.log(error);
            })
    }


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setDivisionData(res.data);
            })
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
        getSortedData(data, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentListForBPLAssign}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => (a[key] || '').toString().toLowerCase() < (b[key] || '').toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => (a[key] || '').toString().toLowerCase() > (b[key] || '').toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) =>
            (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.rollNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData);
    }, [searchTerm])


    const saveStudentsForBPL = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        //console.log("checkValue data: " + JSON.stringify(checkValue));
        if (checkValue.length === 0) {
            const removeObj = [{
                classId: classDivObj.classId,
                divisionId: classDivObj.divisionId,
                regNo: 0,
                rollNo: 0,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
            }]
            await axios.post(`${saveBplStudentsAPIURl}`, removeObj)
                .then(res => {
                    if (res.data != null) {
                        setData([]);
                        setFilteredData([]);
                        setClassDivObj(null);
                        setSearchTerm("");
                        setCheckValue([]);
                        setLoaderOption(false);
                        toast.success("BPL student Allocation Successfully done.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    }
                }).catch(error => {
                    console.log(error);
                })
        }
        else {
            await axios.post(`${saveBplStudentsAPIURl}`, checkValue)
                .then(res => {
                    if (res.data != null) {
                        setData([]);
                        setFilteredData([]);
                        setClassDivObj(null);
                        setSearchTerm("");
                        setCheckValue([]);
                        setLoaderOption(false);
                        toast.success("BPL Student Allocation Successfully done.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    }
                }).catch(error => {
                    console.log(error);
                })
        }

    }

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (classDivObj != null || classDivObj != "" || data.length != 0) {
                    console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.bplStudentBtn')
                    if (btn !== null) {
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>

                    <div className='row'>
                        <div className='col-sm-3'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={divisionData}
                                style={{ fontSize: '14px' }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                    setClassDivObj(newValue);
                                    if (newValue != null) {
                                        getStudentData(newValue.classId, newValue.divisionId)
                                    }
                                    else if (newValue == null) {
                                        setData([]);
                                        setFilteredData([]);
                                        setCheckValue([]);
                                    }
                                }}
                                value={classDivObj}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    {
                        (data == null || data == "" || data == [])
                            ?
                            <h4 className='mt-5'>No Records, Select Class-Division</h4>
                            :
                            <div>
                                <div className='row col-sm-4 my-1 mt-4' style={{ float: 'right' }}>
                                    <div>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>

                                <form onSubmit={saveStudentsForBPL}>
                                    <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                        <table className="table table-bordered ">
                                            <thead className="table-Default" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData
                                                    .map((item, index) => {
                                                        return (

                                                            <tr key={index} className="table-sm">
                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "10px" }} >{item.regNo}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.rollNo}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px" }} >
                                                                    <Checkbox defaultChecked={(item.isBPL === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                        onChange={(e) => {
                                                                            let val = e.target.checked;
                                                                            handleChkBox(val, item.regNo, item.divisionId, item.classId, item.rollNo);
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}
                                            </tbody>
                                        </table>

                                    </div>

                                    <div className='mt-4'>
                                        <button className='bplStudentBtn btn btn-primary btn-sm mb-2' type='submit'><AddIcon fontSize="small" />Save Changes</button>
                                    </div>
                                </form>
                            </div>
                    }


                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default BplStudents;




