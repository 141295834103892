import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { getBedMasterAPIURL, saveBedMsaterAPIURL, updateBedMasterAPIURL } from '../Services/BedMasterAPIURL';
import { getRoomMasterAPIURL } from '../Services/RoomMasterAPIURL';
import BedMasterTable from './BedMasterTable';
import HomeIcon from '@mui/icons-material/Home';

const BedMaster = () => {
    const titleId = 'Bed Master';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `Bed Name`, key: 'bedName' }, { label: `Room`, key: 'roomNo' }, { label: `Building`, key: 'buildingName' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "bedMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "bedMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState("");
    const [roomData, setRoomData] = useState([]);
    const [roomObj, setRoomObj] = useState(null);
    const [bedName, setBedName] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(getRoomMasterAPIURL)
            .then(res => {
                setRoomData(res.data);
            }).catch(err => {
                console.log('get room master err' + err)
            })

        await axios.get(getBedMasterAPIURL)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
            }).catch(err => {
                console.log('get bed master err' + err)
            })
    }

    const ClearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setRoomObj(null);
        setBedName('');
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "bedName": bedName,
            "roomId": roomObj.id
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveBedMsaterAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Bed Master Save Failed.")
                }
            }).catch(err => {
                console.log("Bed Master save" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "bedName": bedName,
            "roomId": roomObj.id
        }
        console.log(JSON.stringify(updateObj))
        await axios.put(updateBedMasterAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Bed Master Update Failed.")
                }
            }).catch(err => {
                console.log("Bed Master update" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };


    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.bedMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.bedMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }
        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);
    
    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        {/* Form Data start */}
                        <form onSubmit={onSubmit}>
                            <div className="form-data mt-1">
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Room :</label>
                                    <div className='col-sm-4'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={roomData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            onChange={(event, newValue) => {
                                                setRoomObj(newValue);
                                            }}
                                            value={roomObj}
                                            onKeyPress={(e) => (e.key == "Enter") ? roomObj : ""}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.roomNo}
                                            renderOption={(props, option) => (
                                                <div className='' {...props}>
                                                    <div className=''>
                                                        {option.roomNo} <br />
                                                        <div
                                                            className=''
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                fontSize: "12px",
                                                                flexDirection: "row",
                                                                justifyContent: "space-between",
                                                                padding: "0px 10px",
                                                                color: "#464964",
                                                                fontWeight: "550"
                                                                //gridRow: 1
                                                            }}
                                                        >
                                                            <span><HomeIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {option.buildingName}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Room" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Bed Name :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && bedName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Bed Name"
                                            variant="outlined"
                                            margin='dense'
                                            value={bedName}
                                            onChange={(e) => setBedName(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? bedName : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 mt-3">
                                    <div className="offset-sm-2">
                                        <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                        <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                        <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="tableData mt-3">
                            <BedMasterTable
                                TableHeading={TableHeading}
                                data={data}
                                setFilteredData={setFilteredData}
                                filteredData={filteredData}
                                setId={setId}
                                setBedName={setBedName}
                                setRoomObj={setRoomObj}
                                roomData={roomData}
                                getData={getData}
                                ClearData={ClearData}
                                setSaveBtn={setSaveBtn}
                                setUpdateBtn={setUpdateBtn}
                                updateBtnStyle={updateBtnStyle}
                                displayNoneBtnStyle={displayNoneBtnStyle}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default BedMaster