import { BaseUrlLibraryMaster, BaseURL, dBaseURL, dBaseUrlLibraryMaster, BaseURLMaster } from "../../../CommonServices/APIURL";

export const getLocationDataAPIURL = BaseUrlLibraryMaster + "getAllLocationMasterList";

export const getSourceDataAPIURL = BaseUrlLibraryMaster + "getAllSourceMasterList";

export const getCurrencyDataAPIURL = BaseUrlLibraryMaster + "getAllCurrencyMasterList";

export const getBindingDataAPIURL = BaseUrlLibraryMaster + "getAllBindingMasterList";

export const getDepartmentDataAPIURL = BaseUrlLibraryMaster + "getAllDepartmentMasterList";

export const getLanguageDataAPIURL = BaseURLMaster + "Languages";

export const getSubjectDataAPIURL = BaseURLMaster + "Subjects";

//localhost:8080/institute/sss/library/master/getTitleMasterDataDetail?id=4
export const getAuthorPublisherByTitle = BaseUrlLibraryMaster + "getTitleMasterDataDetail";

//localhost:8080/institute/sss/library/acquisition/getAllAccessionEntryData?branchId=1
export const getAccessionRegisterDataAPIURL = BaseURL + "library/acquisition/getAllAccessionEntryData";

// localhost:8080/institute/sss/library/acquisition/saveAccessionEntryData
// {
//     "registerTypeId": 1,
//     "accNo": 123,
//     "quantity": 2,
//     "titleId": 1,
//     "edition": 3,
//     "year": 2022,
//     "volume": 1,
//     "departmentId": 1,
//     "sourceId": 1,
//     "locationId": 1,
//     "callNo": "+9194684635",
//     "authorShortName": "authorShortName",
//     "bindingTypeId": 1,
//     "subjectId": 1,
//     "webLink": "www.example.com",
//     "remark": "remark",
//     "vendorId": 1,
//     "accDate": "2022-12-06",
//     "currencyId": 2,
//     "prize": 99.00,
//     "netAmt": 100.00,
//     "billNo": 1,
//     "billDate": "2022-12-06",
//     "iSBNNo": "ISBNNo",
//     "page": 100,
//     "size": "A4",
//     "languageId": 2,
//     "branchId": 1
// }
export const saveAccessionEntryAPIURL = BaseURL + "library/acquisition/saveAccessionEntryData";

//localhost:8080/institute/sss/library/acquisition/getAccNo?branchId=1
export const getAccessionNoAPIURL = BaseURL + "library/acquisition/getAccNo";

//localhost:8080/institute/sss/library/acquisition/updateAccessionEntryData
export const updateAccessionEntryDataAPIURL = BaseURL + "library/acquisition/updateAccessionEntryData";

//localhost:8080/institute/sss/library/acquisition/getAccessionEntryData?id=4
export const getAccessiondataByidAPIURL = BaseURL + "library/acquisition/getAccessionEntryData";

//localhost:8080/institute/sss/library/acquisition/updateAccessionEntryStatus?id=4&statusId=5
export const updateBookstatusAPIURL = BaseURL + "library/acquisition/updateAccessionEntryStatus";