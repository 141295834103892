import { BaseIP, BaseURL, BaseURLMaster } from "./APIURL";

//Sub-Caste API link
export const SubCasteGetMapping=BaseURLMaster + "SubCastes/";
//export const SubCasteGetMapping = "http://192.168.1.102:8080/institute/sss/master/SubCastesData/";
export const SubCasteSaveSubCaste = BaseURLMaster + "saveSubCaste/";
export const SubCasteUpdateSubCaste = BaseURLMaster + "updateSubCaste/";
export const SubCasteDeleteSubCaste = BaseURLMaster + "deleteSubCaste/";

//get all subcaste by casteId
export const GetSubCasteByCasteId = BaseURLMaster + "getSubCastesByCasteId/";