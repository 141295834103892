import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/inventoryStore/master/getItemCategoryList?sid=3&bid=1
export const getItemCategoryMasterAPIURL = BaseURL + "inventoryStore/master/getItemCategoryList";

//localhost:8080/institute/sss/inventoryStore/master/saveItemCategory
// {
//     "categoryName": "categoryName",
//     "section": 3,
//     "branchId": 1
// }
export const saveItemCategoryMasterAPIURL = BaseURL + "inventoryStore/master/saveItemCategory";

//localhost:8080/institute/sss/inventoryStore/master/updateItemCategory
export const updateItemCategoryMasterAPIURL = BaseURL + "inventoryStore/master/updateItemCategory";

//localhost:8080/institute/sss/inventoryStore/master/deleteItemCategory?id=3
export const deleteItemCategoryMasterAPIURL = BaseURL + "inventoryStore/master/deleteItemCategory";

