import { BaseIP, BaseURL, BaseURLMaster } from "./APIURL";

//Division Master API Link
export const DivisioneMasterGetMapping = BaseURLMaster + "Divisions/";
//export const DivisioneMasterGetMapping = "http://192.168.1.102:8080/institute/sss/master/Divisions/";
export const DivisionePostMapping = BaseURLMaster + "saveDivision/";
export const DivisionePutMapping = BaseURLMaster + "updateDivision/";
export const DivisioneDeleteMapping = BaseURLMaster + "deleteDivision/";

//Get Division by class id: getClassWiseDivisions/{id}
export const getDivisionByClassId = BaseURLMaster + "getClassWiseDivisions/";