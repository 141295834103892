import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//Employee Biometric Attendace APIURL
//localhost:8080/institute/sss/payroll/employee/getEmployeeAttendanceList?option=2&attDate=2023-03-09&empId=25&bid=1&year=2022
export const getEmployeeAttendanceDataAPIURL = BaseURL + "payroll/employee/getEmployeeAttendanceList";


//Employee Leave Opening APIURL
// /localhost:8080/institute/sss/payroll/employeeLeave/getEmployeeLeaveOpeningList?year=2022&bid=1
export const getEmployeeLeaveOpeningAPIURL = BaseURL + "payroll/employeeLeave/getEmployeeLeaveOpeningList";

//localhost:8080/institute/sss/payroll/employeeLeave/saveEmployeeLeaveOpening
// {
//     "empId": 25,
//     "leaveId": 3,
//     "leaveQty": 4,
//     "remark": "remark",
//     "sessionYear": 2022
// }
// ALREADYEXISTS
export const saveEmployeeLeaveOpeningAPIURL = BaseURL + "payroll/employeeLeave/saveEmployeeLeaveOpening";

// /localhost:8080/institute/sss/payroll/employeeLeave/updateEmployeeLeaveOpening
// {
//     "id": 1,
//     "empId": 25,
//     "leaveId": 5,
//     "leaveQty": 4,
//     "remark": "remark",
//     "sessionYear": 2022
// }
export const updateEmployeeLeaveOpeningAPIURL = BaseURL + "payroll/employeeLeave/updateEmployeeLeaveOpening";

// /localhost:8080/institute/sss/payroll/employeeLeave/deleteEmployeeLeaveOpening?id=3
export const deleteEmployeeLeaveOpeningAPIURL = BaseURL + "payroll/employeeLeave/deleteEmployeeLeaveOpening";


//http://192.168.1.102:8080/institute/sss/payroll/employee/getEmployeeAttendanceMonthWise?option=2&bid=1&date=03/2023&empId=25
export const getMonthWiseEmployeeAttendAPIURL = BaseURL + "payroll/employee/getEmployeeAttendanceMonthWise";

//localhost:8080/institute/sss/payroll/employee/getEmployeeAttendanceByEmpId?bid=1&date=2023-03-09&empId=25
export const getEmployeeAttendaceDetailsByEmpId = BaseURL + "payroll/employee/getEmployeeAttendanceByEmpId";


//Employee Leave Entry APIURL
//localhost:8080/institute/sss/payroll/employee/getAvailableLeaves?empId=25&leaveId=3&year=2022&bid=1
export const getEmpAvailableDaysAPIURL = BaseURL + "payroll/employee/getAvailableLeaves";

//getList
//localhost:8080/institute/sss/payroll/employee/getEmployeeLeaveEntryList?year=2022&bid=1
export const getEmployeeLeaveEntryAPIURL = BaseURL + "payroll/employee/getEmployeeLeaveEntryList";

//Save
// localhost:8080/institute/sss/payroll/employee/saveEmployeeLeaveEntry
// {
//     "empId": 25,
//     "leaveId": 3,
//     "leaveQty": 4,
//     "date": "2023-03-13",
//     "reason": "reason",
//     "sessionYear": 2022,
//     "branchId": 1
// }
export const saveEmployeeLeaveEntryAPIURL = BaseURL + "payroll/employee/saveEmployeeLeaveEntry";

//Update
// localhost:8080/institute/sss/payroll/employee/updateEmployeeLeaveEntry
// {
//     "empId": 25,
//     "leaveId": 3,
//     "leaveQty": 3,
//     "date": "2023-03-13",
//     "reason": "reason",
//     "uniqueId": 1,
//     "sessionYear": 2022,
//     "branchId": 1
// }
export const updateEmployeeLeaveEntryAPIURL = BaseURL + "payroll/employee/updateEmployeeLeaveEntry";

//localhost:8080/institute/sss/payroll/employee/deleteEmployeeLeaveEntry?year=2022&bid=1&empId=25&leaveId=3&uniqueId=1
export const deleteEmployeeLeaveEntryAPIURL = BaseURL + "payroll/employee/deleteEmployeeLeaveEntry";