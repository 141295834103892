import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { getNoticeDataAPIURL, saveNoticeDataAPIURL } from '../Service/SystemUpdateAPIURL';
import { toast, ToastContainer } from 'react-toastify';


const SystemUpdate = () => {

    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [id, setId] = useState(null)
    const [status, setStatus] = useState(1)
    const [remark, setRemark] = useState("")

    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked === true) {
            setStatus(1)
        }
        else {
            setStatus(0)
        }
    };


    const getData = async () => {
        //console.log(getNoticeDataAPIURL)
        await axios.get(getNoticeDataAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    console.log(JSON.stringify(res.data))
                    let { remark, id, status } = res.data[0];
                    if (status === 1) {
                        setStatus(1)
                        setChecked(true)
                    }
                    else {
                        setStatus(0)
                        setChecked(false)
                    }

                    setRemark(remark)
                    setId(id)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const updateFun = async () => {
        const updateObj = {
            "id": id,
            "remark": remark,
            "status": status

        }
        await axios.post(saveNoticeDataAPIURL, updateObj)
            .then((res) => {
                if (res.data === "SAVED") {

                    toast.success("Data Updated Sucessfully.")
                    getData()
                }
                else {
                    toast.error("Updation Failed")
                }
            })
            .catch((err) => {
                toast.error("Error Occured Updation Failed")
                console.log(err)
            })
    }

    useEffect(() => {
        getData();
    }, [])


    return (
        <>
            <div className='container'>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label className='font-weight-bolder'>Display Message :</Form.Label>
                        <Switch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Form.Control as="textarea" rows={7} placeholder="Type your message here..."
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Button variant="contained" onClick={() => updateFun()}>UPDATE</Button>
                    </Form.Group>
                </Form>
                <ToastContainer theme='colored' />
            </div>
        </>
    )
}

export default SystemUpdate