const payrolAcademicJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/payrollDashboard",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        DropDownSubMenu: [
            {
                id: 1101,
                SubPath: "/Home/pm1101/",
                SubName: "Leave Master"
            },
            {
                id: 1102,
                SubPath: "/Home/pm1102/",
                SubName: "Holiday Marking"
            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Transaction",
        LinkIcon: "monetization_on",
        DropDownSubMenu: [
            {
                id: 1103,
                SubPath: "/Home/pt1103/",
                SubName: "Employee Attendance"
            },
            {
                id: 1105,
                SubPath: "/Home/pt1105/",
                SubName: "Employee Leave Opening"
            },
            {
                id: 1104,
                SubPath: "/Home/pt1104/",
                SubName: "Employee Leave Entry"
            },
            {
                id: 1106,
                SubPath: "/Home/404/",
                SubName: "Salary Process"
            },
        ]
    },
    // {
    //     MenuType: "normal",
    //     PathName: "#",
    //     Title: "Reports",
    //     LinkIcon: "assignment"
    // },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Reports",
        LinkIcon: "assignment",
        DropDownSubMenu: [
            {
                id: 1107,
                SubPath: "/Home/pr1107/",
                SubName: "Employee Attendace Report"
            },
            {
                id: 1108,
                SubPath: "/Home/pr1108/",
                SubName: "Employee Leave Entry Report"
            },
        ]
    },
]

export default payrolAcademicJSON;