const meritData = [
    {
        id: 1,
        meritNo: '1'
    },
    {
        id: 2,
        meritNo: '2'
    },
    {
        id: 3,
        meritNo: '3'
    },
    {
        id: 4,
        meritNo: '4'
    },
    {
        id: 5,
        meritNo: '5'
    },
    {
        id: 6,
        meritNo: '6'
    },
    {
        id: 7,
        meritNo: '7'
    },
    {
        id: 8,
        meritNo: '8'
    },
    {
        id: 9,
        meritNo: '9'
    },
    {
        id: 10,
        meritNo: '10'
    },
]

export default meritData