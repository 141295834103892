import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//Bulk Exam Allocation APIURL
//localhost:8080/institute/sss/academics/exam/savePreviousYearExamMarkAllocationData?sessionYear=2023&sid=3&branchMedium=1&fromYear=2022
export const saveLastYearYearExamMarkAllocation = BaseURL + "academics/exam/savePreviousYearExamMarkAllocationData";

//Exam List (Exam Result Setting)
//localhost:8080/institute/sss/academics/exam/savePreviousYearFinalExamList?sessionYear=2023&sid=3&branchMedium=1&fromYear=2022
export const saveLastYearYearExamResultSettingExamList = BaseURL + "academics/exam/savePreviousYearFinalExamList";

//Exam Subject (Exam Result Setting)
//localhost:8080/institute/sss/academics/exam/savePreviousYearFinalExamResultSubject?sessionYear=2023&sid=3&branchMedium=1&fromYear=2022
export const saveLastYearYearExamResultSettingSubjectList = BaseURL + "academics/exam/savePreviousYearFinalExamResultSubject";

//Exam Allocation
//localhost:8080/institute/sss/academics/exam/savePreviousYearExamAllocationData?sessionYear=2023&sid=3&branchMedium=1&fromYear=2022
export const saveLastYearYearExamAllocation = BaseURL + "academics/exam/savePreviousYearExamAllocationData";

//subject Allocation
// /localhost:8080/institute/sss/academics/subjectAllocation/savePreviousYearSubjectAllocation?sessionYear=2023&sid=3&fromYear=2022&bid=1
export const saveLastYearSubjectAllocation = BaseURL + "academics/subjectAllocation/savePreviousYearSubjectAllocation";

//check exam allocation is done or not
//localhost:8080/institute/sss/academics/exam/getIsExamAllocationData?sessionYear=2023&sid=3&branchMedium=1&fromYear=2022
export const getExamAllocationDetalis = BaseURL + "academics/exam/getIsExamAllocated";

//check exam mark allocation is done or not
//localhost:8080/institute/sss/academics/exam/getIsExamMarkAllocated?sessionYear=2023&sid=3&branchMedium=1&fromYear=2022
export const getExamMarkAllocationDetalis = BaseURL + "academics/exam/getIsExamMarkAllocated";

//check exam setting is done or not
// /localhost:8080/institute/sss/academics/exam/getIsFinalExamAllocated?sessionYear=2023&sid=3&branchMedium=1
export const getExamSettingAllocationDetails = BaseURL + "academics/exam/getIsFinalExamAllocated";

//check subject aalocation is done or not
// /localhost:8080/institute/sss/academics/subjectAllocation/getIsSubjectAllocated?sessionYear=2023&sid=3&bid=1
export const getSubjectAllocationDetails = BaseURL + "academics/subjectAllocation/getIsSubjectAllocated";