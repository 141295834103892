import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

//EBC Report APIURL
//localhost:8080/institute/sss/academic/reports/generateEBCReportClassWise?langId=1&classId=5&acYear=2022&sid=3&bid=1
export const printEbcReportClassWiseAPIURL = BaseURL + "academic/reports/generateEBCReportClassWise";

//localhost:8080/institute/sss/academic/reports/generateEBCReportAllClass?langId=1&acYear=2022&sid=3&bid=1
export const printEbcReportAllClassAPIURL = BaseURL + "academic/reports/generateEBCReportAllClass";

//localhost:8080/institute/sss/academic/reports/generateEBCReportClassAndDivisionWise?langId=1&divId=17,18,19&acYear=2022&sid=3&bid=15&classId=5
export const printEbcReportClassDivWiseAPIURL = BaseURL + "academic/reports/generateEBCReportClassAndDivisionWise";


//EBC summary APIURL
//localhost:8080/institute/sss/academic/reports/generateEBCSummary?langId=1&classId=5,6,7&acYear=2022&sid=3&bid=1
export const printEbcSummaryClassWiseAPIURL = BaseURL + "academic/reports/generateEBCSummary"

//summery 
//localhost:8080/institute/sss/academic/reports/generateEBCSummaryClassAndDivisionWise?langId=1&divId=17,18,19&acYear=2022&sid=3&bid=15
export const printClassDivWiseEBCSummaryReport = BaseURL + "academic/reports/generateEBCSummaryClassAndDivisionWise";