import { BaseIP, BaseURL, BaseURLMaster, BaseURLAcMaster, dBaseURL } from "./APIURL";

//VoucherEntry API Link
export const BaseURLVoucherEntryMaster = BaseURL + "accounts/voucherEntry/";
export const BaseURLDualVoucherEntryMaster = BaseURL + "accounts/dualSideVoucherEntry/"


export const VoucherEntryGetMapping = BaseURLVoucherEntryMaster + "voucherEntryList/";
//export const VoucherEntryGetMapping = "http://192.168.1.102:8080/institute/sss/accounts/voucherEntry/voucherEntryList";
export const VoucherEntryNoGetMapping = BaseURLVoucherEntryMaster + "getVoucherNo/";
export const VoucherEntryPostMapping = BaseURLVoucherEntryMaster + "saveVoucherEntry/";

// Dual Account 
// http://192.168.29.102:8080/institute-sss/institute/sss/accounts/dualSideVoucherEntry/getDualSideVoucherEntryType?vno=1&acyr=2023&sid=3&bid=1
export const getDualSideVoucherEntryTypeAPI = BaseURLDualVoucherEntryMaster + "getDualSideVoucherEntryType"

//localhost:8080/institute-sss/institute/sss/accounts/dualSideVoucherEntry/saveDualSideVoucherEntry
export const dualAccountVoucherEntryAPI = BaseURLDualVoucherEntryMaster + "saveDualSideVoucherEntry"
// localhost:8080/institute-sss/institute/sss/accounts/dualSideVoucherEntry/getDualSideVoucherEntryList?acyr=2023&sid=3&bid=1
export const getDualSideVoucherEntryListAPI = BaseURLDualVoucherEntryMaster + "getDualSideVoucherEntryList"

// getDualSideVoucherNoData
export const getDualSideVoucherNoDataAPI = BaseURLDualVoucherEntryMaster + "getDualSideVoucherNoData"

// getDualSideAccountClosingBalance
export const getDualSideAccountClosingBalanceAPI = BaseURLDualVoucherEntryMaster + "getDualSideAccountClosingBalance"

// getDualSideClosingBalance
export const getDualSideClosingBalanceAPI = BaseURLDualVoucherEntryMaster + "getDualSideClosingBalance"

// updateDualSideVoucherEntry
export const updateDualSideVoucherEntryAPI = BaseURLDualVoucherEntryMaster + "updateDualSideVoucherEntry"

//export const VoucherEntryPostMapping = "http://192.168.1.102:8080/institute/sss/accounts/voucherEntry/saveVoucherEntry"
export const VoucherEntryPutMapping = BaseURLVoucherEntryMaster + "updateVoucherEntry/";


export const VoucherEntryGetData = BaseURLVoucherEntryMaster + "getVoucherNoData/";

//Year
// ***** for year
export const getKirdReportYear = BaseURLVoucherEntryMaster + "getVoucherEntryReportByYear";


// ***** for month
export const getKirdReportMonth = BaseURLVoucherEntryMaster + "getVoucherEntryReportByMonth";

// ***** for date
export const getKirdReportDate = BaseURLVoucherEntryMaster + "getVoucherEntryReportByDate";

// ***** for daybook
export const getDaybookReport = BaseURLVoucherEntryMaster + "getDaybookReport";

// For kird report
// /institute/sss/accounts/voucherEntry

// ***** for year
// GetMapping
// /getVoucherEntryReportByYear

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId
// -------------------

// ***** for month
// GetMapping
// /getVoucherEntryReportByMonth
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId,@RequestParam("month") long byMonth
// ------------------


// ***** for date
// GetMapping
// /getVoucherEntryReportByDate
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("date") String byDate
// ------------------


//Get Account Closing Amount
// localhost:8080/institute-sss/institute/sss/accounts/openingBalance/getClosingBalance?acYear=2022&sid=3&bid=1
export const getClosingBalanceAmtAPIURL = BaseURL + "accounts/openingBalance/getClosingBalance";

// localhost:8080/institute-sss/institute/sss/accounts/openingBalance/getAccountClosingBalance?accCode=5001&acYear=2022&sid=3&bid=1
export const getAccClosingBalAmtAPIURL = BaseURL + "accounts/openingBalance/getAccountClosingBalance";

