import React from 'react';
import { useSelector } from "react-redux";
//Note here this modal box require two parameters i.e ID and DeleteData Function
const AccountMasterDeleteModal = (props) => {
    const authUser = useSelector((state) => state.user.value);
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header mt-3">
                            {(authUser.branchId !== 50)
                                ?
                                <h4 className="modal-title" id="exampleModalLabel">You don't have rights to delete.</h4>
                                :
                                <h4 className="modal-title" id="exampleModalLabel">Are you sure you want to delete this?</h4>
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {(authUser.branchId !== 50)
                                ?
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Ok</button>
                                :
                                <button type="button" onClick={() => props.DeleteData(props.deleteId)} className="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountMasterDeleteModal;
