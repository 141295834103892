import React, { useState, useEffect } from 'react';
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import {
    classWithDivisionURL, getExamData,
    getExamListForResultSheet,
    getSubjectData, getSubjectDataForSubMarkSheet,
    printBoardInternalAssessmentReport
} from '../Services/ExamReportAPIURL';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from "@mui/styles/makeStyles";
import Loader from '../../CommonComponent/Loader';
import { getSubExamByExamId } from '../Services/ExamAllocationAPIURL';
import { Checkbox, FormControlLabel, ListSubheader } from '@mui/material';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const BoardInternalAssessmentReport = () => {
    const titleId = "Board Internal Assessment Mark Sheet";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState();
    const [examData, setExamData] = useState([]);
    const [examObj, setExamObj] = useState(null);
    const [subExamObj, setSubExamObj] = useState([]);
    const [subExamId, setSubExamId] = useState([]);
    const [subExamData, setSubExamData] = useState([]);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [reportOptionId, setReportOptionId] = useState(1);
    const [boardSeatNoFrom, setBoardSeatNoFrom] = useState('');
    const [boardSeatNoTo, setBoardSeatNoTo] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [printBlank, setPrintBlank] = useState(0);
    const [boardSeatNochkBox, setBoardSeatNoChkBox] = useState(0);
    const [termObj, setTermObj] = useState('');

    const HandleBoardSeatNoCheckbox = (e) => {
        setBoardSeatNoChkBox(e.target.checked);
        setBoardSeatNoFrom('');
        setBoardSeatNoTo('');
        setReportOptionId(1);
        if (e.target.checked == true) {
            setBoardSeatNoChkBox(1);
        }
        else {
            setBoardSeatNoChkBox(0);
        }
    }

    const HandlePrintBlankCheckbox = (e) => {
        setPrintBlank(e.target.checked);
        if (e.target.checked == true) {
            setPrintBlank(1);
        }
        else {
            setPrintBlank(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {

        if (authUser.branchId != null && authUser.deptId != null) {
            await axios.get(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                });
        }
    };

    const getExamListTermWise = async (tId) => {
        setExamData([]);
        if (classDivObj != null) {
            //console.log(`${getExamListForResultSheet}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
            await axios(`${getExamListForResultSheet}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
                .then((response) => {
                    setExamData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const getSubExamDetails = async (examId) => {
        setSubExamData([]);
        await axios.get(`${getSubExamByExamId}` + examId)
            .then(res => {
                setSubExamData(res.data);
            });
    }

    const getSubjects = async (cId, dId) => {
        //console.log(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}&branchMedium=${authUser.branchMedium}`)
        await axios(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {
                //console.log(response.data);
                let filterMarkSubjects = response.data.filter(e => e.markOrGrade == 1)
                setSubjectData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    const getBoardInternalAssessmentReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        console.log(`${printBoardInternalAssessmentReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${(reportOptionId == 1 && boardSeatNochkBox == 1) ? 0 : classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&branchMedium=${authUser.branchMedium}&subId=${subjectObj.id}&printBlank=${printBlank}&viewType=${subjectObj.viewType}&examId=${(termObj == 3) ? 0 : examObj.id}&subExamId=${(termObj == 3) ? 0 : subExamId}&isBoardSeatNoWise=${(boardSeatNochkBox == 0) ? boardSeatNochkBox : reportOptionId}&termId=${termObj}`)
        await axios.get(`${printBoardInternalAssessmentReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${(reportOptionId == 1 && boardSeatNochkBox == 1) ? 0 : classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&branchMedium=${authUser.branchMedium}&subId=${subjectObj.id}&printBlank=${printBlank}&viewType=${subjectObj.viewType}&examId=${(termObj == 3) ? 0 : examObj.id}&subExamId=${(termObj == 3) ? 0 : subExamId}&isBoardSeatNoWise=${(boardSeatNochkBox == 0) ? boardSeatNochkBox : reportOptionId}&termId=${termObj}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.");
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                console.log("Board Internal Assessment Report" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.");
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "480px" }}>
                            <form onSubmit={getBoardInternalAssessmentReport}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={classDivData}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            value={classDivObj}
                                            onChange={(event, newValue) => {
                                                setClassDivObj(newValue);
                                                setTermObj('');
                                                setExamObj(null);
                                                setExamData([]);
                                                setSubExamData([]);
                                                setSubExamObj([]);
                                                setSubjectObj(null);
                                                setReportOptionId(1);
                                                setBoardSeatNoFrom('');
                                                setBoardSeatNoTo('');
                                                setPrintBlank(0);
                                                setBoardSeatNoChkBox(0);
                                                if (newValue != null) {
                                                    getSubjects(newValue.classId, newValue.divisionId);
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField margin='dense' {...params} label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "18px" }}>Term :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                fullWidth
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={termObj}
                                                label="Term"
                                                onChange={(e) => {
                                                    setTermObj(e.target.value);
                                                    getExamListTermWise(e.target.value);
                                                    setExamObj(null);
                                                    setExamData([]);
                                                    setSubExamData([]);
                                                    setSubExamObj([]);
                                                    setSubjectObj(null);
                                                    setReportOptionId(1);
                                                    setBoardSeatNoFrom('');
                                                    setBoardSeatNoTo('');
                                                    setBoardSeatNoChkBox(0);
                                                    setPrintBlank(0);
                                                }}
                                            >
                                                {subjectMarkTermData.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2" hidden={(termObj == 3) ? true : false}>
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={examData}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            value={examObj}
                                            onChange={(event, newValue) => {
                                                setExamObj(newValue);
                                                setSubExamData([]);
                                                setSubExamObj([]);
                                                setSubjectObj(null);
                                                setReportOptionId(1);
                                                setBoardSeatNoFrom('');
                                                setBoardSeatNoTo('');
                                                setBoardSeatNoChkBox(0);
                                                setPrintBlank(0);
                                                if (newValue != null) {
                                                    getSubExamDetails(newValue.id);
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField margin='dense' {...params} label="Select Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required={(termObj == 3) ? false : true} />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2" hidden={(termObj == 3) ? true : false}>
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Exam :</label>
                                    <div className='col-sm-5'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={subExamData}
                                            multiple
                                            disableCloseOnSelect
                                            filterSelectedOptions
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            value={subExamObj}
                                            onChange={(event, newValue) => {
                                                setSubExamObj(newValue);
                                                setSubjectObj(null);
                                                setReportOptionId(1);
                                                setBoardSeatNoFrom('');
                                                setBoardSeatNoTo('');
                                                setBoardSeatNoChkBox(0);
                                                setPrintBlank(0);
                                                if (newValue != null) {
                                                    setSubExamId(newValue.map(e => e.id).join(','))
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField margin='dense' {...params} label="Select Sub-Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required={(subExamObj.length == 0 && termObj != 3) ? true : false} />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={subjectData}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.subjectNameMr : option.subjectNameEn}
                                            size="small"
                                            value={subjectObj}
                                            onChange={(event, newValue) => {
                                                setSubjectObj(newValue);
                                                setReportOptionId(1);
                                                setBoardSeatNoFrom('');
                                                setBoardSeatNoTo('');
                                                setBoardSeatNoChkBox(0);
                                                setPrintBlank(0);
                                            }}
                                            renderInput={params => (
                                                <TextField margin='dense' {...params} label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className='' style={{ width: "220px" }}>
                                        <FormControlLabel control={<Checkbox checked={boardSeatNochkBox} onChange={HandleBoardSeatNoCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Board Seat No Wise" />
                                    </div>

                                    <div className='col-sm-3' hidden={(boardSeatNochkBox == 1) ? true : false}>
                                        <FormControlLabel control={<Checkbox checked={printBlank} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Print Blank" />
                                    </div>
                                </div>

                                {(() => {
                                    if (boardSeatNochkBox == 1) {
                                        return (
                                            <>
                                                <div className="row">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth margin='dense'>
                                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                required={(boardSeatNochkBox == 1) ? true : false}
                                                                fullWidth
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={reportOptionId}
                                                                label="Select Option"
                                                                onChange={(e) => {
                                                                    setReportOptionId(e.target.value);
                                                                    setBoardSeatNoFrom('');
                                                                    setBoardSeatNoTo('');
                                                                    setPrintBlank(0);
                                                                }}
                                                            >
                                                                <MenuItem value={1}>All Class</MenuItem>
                                                                <MenuItem value={2}>Class-Division Wise</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                {/* {(() => {
                                                    if (reportOptionId == 1) {
                                                        return (
                                                            <>
                                                                <div className="row my-2">
                                                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Board Seat No From :</label>
                                                                    <div className="col-sm-2">
                                                                        <TextField
                                                                            fullWidth
                                                                            //required={(reportOptionId == 1) ? true : false}
                                                                            type="number"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            onKeyDown={(e) => (e.key === " " && boardSeatNoFrom.length === 0) ? e.preventDefault() : ""}
                                                                            autoComplete='off'
                                                                            margin='dense'
                                                                            size='small'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            onKeyPress={(e) => (e.key == "Enter") ? boardSeatNoFrom : ""}
                                                                            label="From"
                                                                            value={boardSeatNoFrom}
                                                                            onChange={(e) => setBoardSeatNoFrom(e.target.value)}
                                                                        />
                                                                    </div>

                                                                    <label className='col-sm-1' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                                                    <div className="col-sm-2">
                                                                        <TextField
                                                                            fullWidth
                                                                            //required={(reportOptionId == 1) ? true : false}
                                                                            type="number"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            onKeyDown={(e) => (e.key === " " && boardSeatNoTo.length === 0) ? e.preventDefault() : ""}
                                                                            autoComplete='off'
                                                                            margin='dense'
                                                                            size='small'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            onKeyPress={(e) => (e.key == "Enter") ? boardSeatNoTo : ""}
                                                                            label="To"
                                                                            value={boardSeatNoTo}
                                                                            onChange={(e) => setBoardSeatNoTo(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })()} */}
                                            </>
                                        )
                                    }
                                })()}

                                <div className="row mt-1" hidden={(boardSeatNochkBox == 0) ? true : false}>
                                    <div className='col-sm-3'>
                                        <FormControlLabel control={<Checkbox checked={printBlank} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Print Blank" />
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-sm-4">
                                        <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default BoardInternalAssessmentReport