import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";



export const getStudentNationalCodeList=BaseURL+"administration/getStudentNationalCodeAllocationData";

export const saveStudentNationalCodeList=BaseURL+"administration/saveStudentNationalCode";

// /institute/sss/administration/

// GetMapping  /getStudentAadharAllocationData

// PostMapping /saveStudentAadhar