import React, { useState, useEffect } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { getCityData } from '../../CommonServices/InstituteMasterAPIURL';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getBinderMaster, getBinderMasterById, saveBinderMaster, updateBinderMaster } from '../LibraryServices/BinderMasterAPIURL';

const BinderMaster = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const [titleId, setTitleId] = useState("");
    const { bId, EditFlag } = useParams()
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "binderLibrarySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "binderLibraryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [binderName, setBinderName] = useState("");
    const [cityData, setCityData] = useState([]);
    const [cityObj, setCityObj] = useState(null);
    const [address, setAddress] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    useEffect(() => {
        getData();
    }, [authUser])


    const getApiData = async () => {
        if (authUser.branchId != null) {
            console.log(`${getBinderMaster}?branchId=${authUser.branchId}`)
            await axios.get(`${getBinderMaster}?branchId=${authUser.branchId}`)
                .then(res => {
                    //console.log(res.data);
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                })
            // .catch(error => {
            //     setLoaderOption(false);
            //     console.log(error);
            //     toast.error("Something went wrong, please check.")
            // })
        }

        await axios.get(getCityData)
            .then(res => {
                setCityData(res.data);
            })
    }

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setTitleId("Binder Master");
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setTitleId("Update Binder Master");
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        console.log(`${getBinderMasterById}?id=${bId}`);
        await axios.get(`${getBinderMasterById}?id=${bId}`)
            .then((response) => {

                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setBinderName(response.data.name);
                setAddress(response.data.address);
                let cityId = response.data.city
                setMobileNo(response.data.mobileNo);
                setEmail(response.data.email);
                setId(response.data.id)


                axios.get(getCityData)
                    .then(res => {
                        setCityData(res.data);
                        setCityObj((res.data).find(e => e.id == cityId))
                    })
                setLoaderOption(false);

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/l813/')
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "branchId": authUser.branchId,
            "name": binderName,
            "address": address,
            "city": cityObj.id,
            "mobileNo": mobileNo,
            "email": email,

        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveBinderMaster, saveObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Binder with this data is already exists.`)
                }
                else if (response.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "branchId": authUser.branchId,
            "name": binderName,
            "address": address,
            "city": cityObj.id,
            "mobileNo": mobileNo,
            "email": email,
        }
        console.log(JSON.stringify(updateObj))
        await axios.put(updateBinderMaster, updateObj)
            .then((response) => {
                if (response.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Binder with this data is already exists.`)
                }
                else if (response.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.binderLibrarySaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.binderLibraryUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className="row my-2">

                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Binder Name :</label>
                                    <div className="col-sm-5">
                                        <TextField
                                            fullWidth
                                            required
                                            onKeyDown={(e) => (e.key === " " && binderName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? binderName : ""}
                                            label="Binder Name"
                                            value={binderName}
                                            onChange={(e) => setBinderName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-1">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Address :</label>
                                    <div className="col-sm-7">
                                        <TextField
                                            fullWidth
                                            required
                                            onKeyDown={(e) => (e.key === " " && address.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? address : ""}
                                            label="Address"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className='row my-1'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >City :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={cityData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setCityObj(newValue);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? cityObj : ""}
                                            value={cityObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select City" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-1">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mobile No :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && mobileNo.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            type="number"
                                            size='small'
                                            id="outlined-basic"
                                            label="Mobile No"
                                            variant="outlined"
                                            margin='dense'
                                            value={mobileNo}
                                            onChange={(e) => {
                                                setMobileNo(e.target.value);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? mobileNo : ""}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Email :</label>
                                    <div className='col-sm-3'>
                                        <ValidatorForm>
                                            <TextValidator
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                                label="Email"
                                                autoComplete='off'
                                                size='small'
                                                margin='dense'
                                                onChange={(e) => setEmail(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? email : ""}
                                                name="email"
                                                value={email}
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ maxLength: 120 }}
                                                validators={['isEmail']}
                                                errorMessages={['email is not valid']}
                                            />
                                        </ValidatorForm>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' disabled={saveBtnDisabled} className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabled} className={updateBtn} onClick={() => (state.button = 2)} style={{ margin: "0px 4px" }}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger' style={{ margin: "0px 4px" }}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}


export default BinderMaster