import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from "@mui/material/Paper";
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { classWithDivisionURL, getSubjectData } from '../Services/ExamReportAPIURL';

const FreqDistribution = () => {
  const titleId = "Freq Distribution";
  const authUser = useSelector((state) => state.user.value);

  // loader on/off
  const [loaderOption, setLoaderOption] = useState(false);

  const [reportOptionId, setReportOptionId] = useState(1);
  const [id, setId] = useState();
  const [classDivData, setClassDivData] = useState([]);
  const [classDivObj, setClassDivObj] = useState(null);
  const [resultSheetData, setResultSheetData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [subjectObj, setSubjectObj] = useState(null);
  

  useEffect(() => {
    getData();
  }, [authUser])


  const getData = async () => {
    await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then((response) => {
        setClassDivData(response.data);

      }).catch((error) => {
        console.log(error);
      })
    await axios(`${getSubjectData}`)
      .then((response) => {
        setSubjectData(response.data);

      }).catch((error) => {
        console.log(error);
      })
  }

  if (loaderOption == true) {
    return <><Loader /></>
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />

        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
          {/* Form Data start */}
          <form >
            <div className="form-data">
              <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

              <div className="row">
                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division:</label>
                <div className='col-sm-3'>
                  <Autocomplete
                    id="combo-box-demo"
                    PaperComponent={({ children }) => (
                      <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                    )}
                    options={classDivData}
                    style={{ fontSize: '14px' }}
                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + option.divisionNameMr : option.classNameEn + option.divisionNameEn}
                    size="small"
                    value={classDivObj}
                    onChange={(event, newvalue) => {
                      setClassDivObj(newvalue);
                    }}
                    renderInput={params => (
                      <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                    )}
                  />
                </div>

                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Result-sheet Type:</label>
                <div className='col-sm-3'>
                  <Autocomplete
                    id="combo-box-demo"
                    PaperComponent={({ children }) => (
                      <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                    )}
                    options={resultSheetData}
                    style={{ fontSize: '14px' }}
                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + option.divisionNameMr : option.classNameEn + option.divisionNameEn}
                    size="small"
                    renderInput={params => (
                      <TextField {...params} margin="dense" label="Select Result-sheet Type" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                    )}
                  />
                </div>
              </div>

              <div className="row my-2">
                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Report Option:</label>
                <div className='col-sm-3'>
                  <FormControl fullWidth>
                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                    <Select
                      size='small'
                      required
                      fullWidth
                      margin='dense'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reportOptionId}
                      label="Select Option"
                      onChange={(e) => setReportOptionId(e.target.value)}
                    >
                      <MenuItem value={1}>All</MenuItem>
                      <MenuItem value={2}>Subject Wise</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {(() => {
                  if (reportOptionId == 1) {
                    return (
                      <>
                        <div className="col-sm-12 mt-3">
                          <div className="offset-sm-2">
                            <button type="button" className="btn btn-primary btn-sm" style={{ margin: '0px 4px' }}>Show</button>
                          </div>
                        </div>
                      </>
                    )
                  }
                  else if (reportOptionId == 2) {
                    return (
                      <>
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject:</label>
                        <div className='col-sm-3'>
                          <Autocomplete
                            id="combo-box-demo"
                            PaperComponent={({ children }) => (
                              <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            options={subjectData}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            renderInput={params => (
                              <TextField {...params} margin="dense" label="Select Subject" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                            )}
                          />
                        </div>

                        <div className="col-sm-12 mt-2">
                          <div className="offset-sm-2">
                            <button type="button" className="btn btn-primary btn-sm" style={{ margin: '0px 4px' }}>Show</button>
                          </div>
                        </div>
                      </>
                    )
                  }
                })()}
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }


}

export default FreqDistribution 