import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/administration/getStudentAllowancesData?acYear=2022&sid=3&bid=1&classId=5&divId=17
export const getStudentListForAllowanceAPIURL = BaseURL + "administration/getStudentAllowancesData";

//localhost:8080/institute/sss/administration/saveStudentAllowancesData
// {
//     "classId": 5,
//     "divisionId": 17,
//     "regNo": 12707,
//     "attendanceAllowance": 1,
//     "uniformAllowance": 0,
//     "transportAllowance": 0,
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveStudentAllowancesAPIURL = BaseURL + "administration/saveStudentAllowancesData";