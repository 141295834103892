import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/inventoryStore/transaction/getItemOutwardList?year=2022&sid=3&bid=1
export const getItemOutwardAPIURL = BaseURL + "inventoryStore/transaction/getItemOutwardList";

//localhost:8080/institute/sss/inventoryStore/transaction/saveItemOutward
// {
//     "itemId": 1,
//     "type":1,
//     "date": "2023-05-04",
//     "quantity": 12,
//     "rate": 100,
//     "amount": 1200,
//     "remark": "remark",
//     "sessionYear": 2022,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveItemOutwardAPIURL = BaseURL + "inventoryStore/transaction/saveItemOutward";

//localhost:8080/institute/sss/inventoryStore/transaction/updateItemOutward
export const updateItemOutwardAPIURL = BaseURL + "inventoryStore/transaction/updateItemOutward";

//localhost:8080/institute/sss/inventoryStore/transaction/deleteItemOutward?id=1
export const deleteItemOutwardAPIURL = BaseURL + "inventoryStore/transaction/deleteItemOutward";