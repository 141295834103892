import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getTotalTimeSlotReportAPIURL } from '../Services/ManageTimeSlotAPIURL';

const TotalTimeTableReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Total Time Table";

    const [loaderOption, setLoaderOption] = useState(false);

    const printTotalTimeTableReport = async () => {
        setLoaderOption(true);

        await axios.get(`${getTotalTimeSlotReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (res.data != null) {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                }
            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <div className=''>
                                <button
                                    type='button'
                                    onClick={() => printTotalTimeTableReport()}
                                    className='btn btn-primary btn-sm mb-2'
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>
                            </div>
                        </div>
                    </div>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default TotalTimeTableReport
