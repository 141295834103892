import React, { useState, useEffect } from 'react'
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlusCircle, faEye } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { EmployeeMasterBranchWiseGetData } from '../../CommonServices/EmployeeMasterAPIURL';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import Loader from '../../CommonComponent/Loader';
import { CategoryMasterGetMapping, MotherToungMasterGetMapping, ReligionMasterGetMapping } from '../../CommonServices/StudentRegistrationAPIURL';
import { BaseURLMasterCastes } from '../../CommonServices/CasteMasterAPIURL';
import { SubCasteGetMapping } from '../../CommonServices/SubCasteMasterAPIURL';
import { BaseURLMasterMinorities } from '../../CommonServices/MinorityMasterAPIURL';
// import Collapse from 'bootstrap/js/dist/collapse'; 
import PersonIcon from '@mui/icons-material/Person';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const employeeTypeData = [
    {
        id: 1,
        employee: "Teaching"
    },
    {
        id: 2,
        employee: "Non Teaching"
    }
]

const MasterFormEmployeeTable = () => {
    const authUser = useSelector((state) => state.user.value);
    let { toastFlag } = useParams();
    const titleId = "Employee Master Table";
    const classes = useStyles();
    const navigate = useNavigate();
    const TableHeading = [{ label: 'ID', key: 'employeeId' }, { label: `Employee Name`, key: (authUser.branchMedium == 1) ? 'employeeNameMr' : 'employeeNameEn' }, { label: `Mobile No`, key: 'mobileNo' }, { label: `Employee Type`, key: 'employeeType' }, { label: `School Name`, key: (authUser.branchMedium == 1) ? 'branchNameMr' : 'branchNameEn' }, { label: 'Edit', value: 'edit', isAction: true }, , { label: 'View', value: 'view', isAction: true }];
    const TableHeading1 = [{ label: 'ID', key: 'employeeId' }, { label: `Employee Name`, key: (authUser.branchMedium == 1) ? 'employeeNameMr' : 'employeeNameEn' }, { label: `Mobile No`, key: 'mobileNo' }, { label: `Employee Type`, key: 'employeeType' }, { label: `School Name`, key: (authUser.branchMedium == 1) ? 'branchNameMr' : 'branchNameEn' }];

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [isAscending, setAscending] = useState("");
    const [sortedBy, setSortedBy] = useState(false);
    const [data, setData] = useState([]);
    //console.log(JSON.stringify(data))
    const [searchTerm, setSearchTerm] = useState("")
    const [schoolData, setSchoolData] = useState([]);
    const [motherTongueData, setMotherTongueData] = useState([]);
    const [religionData, setReligionData] = useState([]);
    const [casteData, setCasteData] = useState([]);
    const [subCasteData, setSubCasteData] = useState([]);
    const [casteCategoryData, setCaseCategoryData] = useState([]);
    const [minorityData, setMinorityData] = useState([]);

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [schoolId, setSchoolId] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [searchData, setSearchData] = useState([]);

    // useEffect(() => {
    //     if (authUser.roleId == 5 || authUser.roleId == 6 || authUser.roleId == 3) {
    //         setDisableBtn(false);
    //     }
    //     else {
    //         setDisableBtn(true);
    //     }
    // }, [authUser])

    let disableEditFlag = sessionStorage.getItem('FormProcessingFlag'); // Assuming it's a string
    disableEditFlag = JSON.parse(disableEditFlag); // Parse string to object

    useEffect(() => {
        if (disableEditFlag) {
            if (disableEditFlag[1] == 1) {
                setDisableBtn(false);
            }
            else {
                setDisableBtn(true);
            }
        }
    }, [authUser, disableEditFlag])


    const [selectedMenu1, setSelectedMenu1] = useState("Gender")
    const [selectedMenu2, setSelectedMenu2] = useState("Employee Type")
    const [selectedMenu3, setSelectedMenu3] = useState("Employee Status")
    const [selectedMenu4, setSelectedMenu4] = useState("Appointment Nature")
    const [selectedMenu5, setSelectedMenu5] = useState("School")
    const [selectedMenu6, setSelectedMenu6] = useState("Mother Tongue")
    const [selectedMenu7, setSelectedMenu7] = useState("Religion")
    const [selectedMenu8, setSelectedMenu8] = useState("Caste")
    const [selectedMenu9, setSelectedMenu9] = useState("Sub-Caste")
    const [selectedMenu10, setSelectedMenu10] = useState("Caste Category")
    const [selectedMenu11, setSelectedMenu11] = useState("Minority")


    const maleGenderFilter = data.filter(e => e.genderId == 1)
    const femaleGenderFilter = data.filter(e => e.genderId == 2)

    const teachingEmployeeFilter = data.filter(e => e.employeeType == "Teaching")
    const nonTeachingEmployeeFilter = data.filter(e => e.employeeType == "Non Teaching")

    const currentEmployeeFilter = data.filter(e => e.workingStatus == 1)
    const terminateEmployeeFilter = data.filter(e => e.workingStatus == 2)
    const voluntaryEmployeeFilter = data.filter(e => e.workingStatus == 3)
    const retiredEmployeeFilter = data.filter(e => e.workingStatus == 4)

    const permanentEmployeeFilter = data.filter(e => e.natureOfAppointment == 1)
    const chbEmployeeFilter = data.filter(e => e.natureOfAppointment == 2)
    const specialEmployeeFilter = data.filter(e => e.natureOfAppointment == 3)


    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    useEffect(() => {
        if (toastFlag == 1) {
            toast.success("Employee registration successfully done.")
            navigate("/Home/m009/0")
        }
        else if (toastFlag == 2) {
            toast.success("Employee updation successfully done.")
            navigate("/Home/m009/0")
        }
        getData();

    }, [authUser]);

    const getData = async () => {
        setLoaderOption(true);
        //console.log(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`);
        if (authUser.branchId != null) {
            await axios(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                    setSearchData(res.data);
                    setLoaderOption(false);
                })
        }
        await axios.get(`${BaseURLInstituteMaster}`)
            .then((response) => {
                setSchoolData(response.data);
            })

        await axios.get(`${ReligionMasterGetMapping}`)
            .then((response) => {
                setReligionData(response.data);
            })
        await axios.get(`${BaseURLMasterCastes}`)
            .then((response) => {
                setCasteData(response.data);
            })
        await axios.get(`${SubCasteGetMapping}`)
            .then((response) => {
                setSubCasteData(response.data);
            })
        await axios.get(`${CategoryMasterGetMapping}`)
            .then((response) => {
                setCaseCategoryData(response.data);
            })
        await axios.get(`${MotherToungMasterGetMapping}`)
            .then((response) => {
                setMotherTongueData(response.data);
            })

        await axios.get(`${BaseURLMasterMinorities}`)
            .then((response) => {
                setMinorityData(response.data);
            })

    }

    const [filteredData, setFilteredData] = useState(data);

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...filteredData.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...filteredData.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const [selectedFilters, setSelectedFilters] = useState({
        gender: null,
        employeeType: null,
        employeeStatus: null,
        appointmentNature: null,
        school: null,
        motherTongue: null,
        religion: null,
        caste: null,
        subCaste: null,
        casteCategory: null,
        minority: null,
    });

    const initialFilters = {
        gender: null,
        employeeType: null,
        employeeStatus: null,
        appointmentNature: null,
        school: null,
        motherTongue: null,
        religion: null,
        caste: null,
        subCaste: null,
        casteCategory: null,
        minority: null,
    };

    const [selectedMaster, setSelectedMaster] = useState({
        workingStatus: [],
        natureOfAppointment: [],
        branchId: [],
        motherTongueId: [],
        religionId: [],
        casteId: [],
        subCasteId: [],
        categoryId: [],
        minorityId: [],
        gender: [],
        employeeType: []
    });

    const handleCheckboxChange = (sId, event, masterId) => {

        const { checked } = event.target;
        let updatedSelectedFilters = { ...selectedMaster };

        // Update the corresponding filter array based on sId
        const filterKey = getFilterKeyBySId(sId);
        if (checked) {
            updatedSelectedFilters[filterKey] = [...updatedSelectedFilters[filterKey], masterId.id];
        } else {
            updatedSelectedFilters[filterKey] = updatedSelectedFilters[filterKey].filter(id => id !== masterId.id);
        }

        setSelectedMaster(updatedSelectedFilters);

        // Clear checkboxes from other dropdowns based on their sId
        // const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        // checkboxes.forEach(checkbox => {
        //     const checkboxSid = parseInt(checkbox.getAttribute('data-dropdown-id'), 10);
        //     if (!isNaN(checkboxSid) && checkboxSid != sId) {
        //         checkbox.checked = false;
        //     }
        // });

        // Filter data based on updated selected checkboxes
        filterData(updatedSelectedFilters);
    };

    const getFilterKeyBySId = (sId) => {
        switch (sId) {
            case 1: return 'gender';
            case 2: return 'employeeType';
            case 3: return 'workingStatus';
            case 5: return 'natureOfAppointment';
            case 6: return 'branchId';
            case 7: return 'motherTongueId';
            case 8: return 'religionId';
            case 9: return 'casteId';
            case 10: return 'subCasteId';
            case 11: return 'categoryId';
            case 12: return 'minorityId';
            default: return '';
        }
    };


    const filterData = (filters) => {
        let filtered = data;

        if (filters.gender.length > 0) {
            filtered = filtered.filter(item => filters.gender.includes(item.genderId));
        }
        if (filters.employeeType.length > 0) {
            filtered = filtered.filter(item => filters.employeeType.includes(item.employeeType === "Teaching" ? 1 : 2));
        }

        Object.keys(filters).forEach(key => {
            if (filters[key].length > 0 && key !== 'gender' && key !== 'employeeType') {
                filtered = filtered.filter(item => filters[key].includes(parseInt(item[key]) || item[key]));
            }
        });

        setFilteredData(filtered);
    };

    const getFilteredData = (sId, value) => {

        setAscending("");
        setSortedBy(false);
        setSearchTerm("");

        // Keep track of selected filters
        let updatedFilters = { ...selectedFilters };

        // if (sId == 1) {
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.gender = value;
        // } else if (sId == 2) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.employeeType = value;
        // }
        // else if (sId == 3) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.employeeStatus = value;
        // } else if (sId == 5) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.appointmentNature = value;
        // } else if (sId == 6) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.school = value;
        // } else if (sId == 7) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.motherTongue = value;
        // } else if (sId == 8) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.religion = value;
        // } else if (sId == 9) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.caste = value;
        // } else if (sId == 10) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu10("Caste Category");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.subCaste = value;
        // } else if (sId == 11) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu11("Minority");

        //     updatedFilters.casteCategory = value;
        // } else if (sId == 12) {
        //     setSelectedMenu1('Gender');
        //     setSelectedMenu2('Employee Type');
        //     setSelectedMenu3('Employee Status');
        //     setSelectedMenu4("Appointment Nature");
        //     setSelectedMenu5("School");
        //     setSelectedMenu6("Mother Tongue");
        //     setSelectedMenu7("Religion");
        //     setSelectedMenu8("Caste");
        //     setSelectedMenu9("Sub-Caste");
        //     setSelectedMenu10("Caste Category");

        //     updatedFilters.minority = value;
        // }
        if (sId == 4) {
            // Reset all filters for school when a specific school is selected
            updatedFilters = { ...initialFilters };
            setSelectedMenu1('Gender');
            setSelectedMenu2('Employee Type');
            setSelectedMenu3('Employee Status');
            setSelectedMenu4("Appointment Nature");
            setSelectedMenu5("School");
            setSelectedMenu6("Mother Tongue");
            setSelectedMenu7("Religion");
            setSelectedMenu8("Caste");
            setSelectedMenu9("Sub-Caste");
            setSelectedMenu10("Caste Category");
            setSelectedMenu11("Minority");

            // Close all open dropdowns
            const accordions = document.querySelectorAll('.accordion-collapse.show');
            accordions.forEach((accordion) => {
                accordion.classList.remove('show');
                const button = document.querySelector(`[data-bs-target="#${accordion.id}"]`);
                button.classList.add('collapsed');
                button.setAttribute('aria-expanded', 'false');
            });

            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach(checkbox => {
                checkbox.checked = false;
            });

        }

        // Apply filters
        let filteredDataCopy = [...data];

        if (updatedFilters.gender !== null) {
            filteredDataCopy = applyGenderFilter(filteredDataCopy, updatedFilters.gender);

            if (updatedFilters.gender == 1) {
                setSelectedMenu1('Male' + " " + `(${maleGenderFilter.length})`);
            }
            else if (updatedFilters.gender == 2) {
                setSelectedMenu1('Female' + " " + `(${femaleGenderFilter.length})`);
            }
        }

        if (updatedFilters.employeeType !== null) {
            filteredDataCopy = applyEmployeeTypeFilter(filteredDataCopy, updatedFilters.employeeType);
            if (updatedFilters.employeeType == 1) {
                setSelectedMenu2('Teaching' + " " + `(${teachingEmployeeFilter.length})`);
            }
            else if (updatedFilters.employeeType == 2) {
                setSelectedMenu2('Non-Teaching' + " " + `(${nonTeachingEmployeeFilter.length})`);
            }
        }

        // if (updatedFilters.employeeStatus !== null) {
        //     filteredDataCopy = applyEmployeeStatusFilter(filteredDataCopy, updatedFilters.employeeStatus);

        //     if (updatedFilters.employeeStatus == 1) {
        //         setSelectedMenu3('Current' + " " + `(${currentEmployeeFilter.length})`);
        //     }
        //     else if (updatedFilters.employeeStatus == 2) {
        //         setSelectedMenu3('Terminate' + " " + `(${terminateEmployeeFilter.length})`);
        //     }
        //     else if (updatedFilters.employeeStatus == 3) {
        //         setSelectedMenu3('Voluntary' + " " + `(${voluntaryEmployeeFilter.length})`);
        //     }
        //     else if (updatedFilters.employeeStatus == 4) {
        //         setSelectedMenu3('Retired' + " " + `(${retiredEmployeeFilter.length})`);
        //     }
        // }

        // if (updatedFilters.appointmentNature !== null) {
        //     filteredDataCopy = applyAppointmentNatureFilter(filteredDataCopy, updatedFilters.appointmentNature);
        //     if (updatedFilters.appointmentNature == 1) {
        //         setSelectedMenu4('Permanent' + " " + `(${permanentEmployeeFilter.length})`);
        //     }
        //     else if (updatedFilters.appointmentNature == 2) {
        //         setSelectedMenu4('CHB' + " " + `(${chbEmployeeFilter.length})`);
        //     }
        //     else if (updatedFilters.appointmentNature == 3) {
        //         setSelectedMenu4('Special' + " " + `(${specialEmployeeFilter.length})`);
        //     }
        // }

        // if (updatedFilters.school !== null) {
        //     filteredDataCopy = applySchoolFilter(filteredDataCopy, updatedFilters.school.id);
        //     setSelectedMenu5((authUser.branchMedium == 1) ? updatedFilters.school.schoolNameMr : updatedFilters.school.schoolNameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.motherTongue !== null) {
        //     filteredDataCopy = applyMotherTongueFilter(filteredDataCopy, updatedFilters.motherTongue.id);
        //     setSelectedMenu6((authUser.branchMedium == 1) ? updatedFilters.motherTongue.nameMr : updatedFilters.motherTongue.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.religion !== null) {
        //     filteredDataCopy = applyReligionFilter(filteredDataCopy, updatedFilters.religion.id);
        //     setSelectedMenu7((authUser.branchMedium == 1) ? updatedFilters.religion.nameMr : updatedFilters.religion.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.caste !== null) {
        //     filteredDataCopy = applyCasteFilter(filteredDataCopy, updatedFilters.caste.id);
        //     setSelectedMenu8((authUser.branchMedium == 1) ? updatedFilters.caste.nameMr : updatedFilters.caste.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.subCaste !== null) {
        //     filteredDataCopy = applySubCasteFilter(filteredDataCopy, updatedFilters.subCaste.id);
        //     setSelectedMenu9((authUser.branchMedium == 1) ? updatedFilters.subCaste.nameMr : updatedFilters.subCaste.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.casteCategory !== null) {
        //     filteredDataCopy = applyCasteCategoryFilter(filteredDataCopy, updatedFilters.casteCategory.id);
        //     setSelectedMenu10((authUser.branchMedium == 1) ? updatedFilters.casteCategory.nameMr : updatedFilters.casteCategory.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.minority !== null) {
        //     filteredDataCopy = applyMinorityFilter(filteredDataCopy, updatedFilters.minority.id);
        //     setSelectedMenu11((authUser.branchMedium == 1) ? updatedFilters.minority.nameMr : updatedFilters.minority.nameEn + " " + `(${filteredDataCopy.length})`);
        // }



        // counts=================
        if (updatedFilters.gender !== null) {
            if (updatedFilters.gender == 1) {
                setSelectedMenu1('Male' + " " + `(${filteredDataCopy.length})`);
            }
            else if (updatedFilters.gender == 2) {
                setSelectedMenu1('Female' + " " + `(${filteredDataCopy.length})`);
            }
        }

        if (updatedFilters.employeeType !== null) {
            if (updatedFilters.employeeType == 1) {
                setSelectedMenu2('Teaching' + " " + `(${filteredDataCopy.length})`);
            }
            else if (updatedFilters.employeeType == 2) {
                setSelectedMenu2('Non-Teaching' + " " + `(${filteredDataCopy.length})`);
            }
        }

        // if (updatedFilters.employeeStatus !== null) {
        //     if (updatedFilters.employeeStatus == 1) {
        //         setSelectedMenu3('Current' + " " + `(${filteredDataCopy.length})`);
        //     }
        //     else if (updatedFilters.employeeStatus == 2) {
        //         setSelectedMenu3('Terminate' + " " + `(${filteredDataCopy.length})`);
        //     }
        //     else if (updatedFilters.employeeStatus == 3) {
        //         setSelectedMenu3('Voluntary' + " " + `(${filteredDataCopy.length})`);
        //     }
        //     else if (updatedFilters.employeeStatus == 4) {
        //         setSelectedMenu3('Retired' + " " + `(${filteredDataCopy.length})`);
        //     }
        // }

        // if (updatedFilters.appointmentNature !== null) {
        //     if (updatedFilters.appointmentNature == 1) {
        //         setSelectedMenu4('Permanent' + " " + `(${filteredDataCopy.length})`);
        //     }
        //     else if (updatedFilters.appointmentNature == 2) {
        //         setSelectedMenu4('CHB' + " " + `(${filteredDataCopy.length})`);
        //     }
        //     else if (updatedFilters.appointmentNature == 3) {
        //         setSelectedMenu4('Special' + " " + `(${filteredDataCopy.length})`);
        //     }
        // }

        // if (updatedFilters.school !== null) {
        //     setSelectedMenu5((authUser.branchMedium == 1) ? updatedFilters.school.schoolNameMr : updatedFilters.school.schoolNameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.motherTongue !== null) {
        //     setSelectedMenu6((authUser.branchMedium == 1) ? updatedFilters.motherTongue.nameMr : updatedFilters.motherTongue.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.religion !== null) {
        //     setSelectedMenu7((authUser.branchMedium == 1) ? updatedFilters.religion.nameMr : updatedFilters.religion.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.caste !== null) {
        //     setSelectedMenu8((authUser.branchMedium == 1) ? updatedFilters.caste.nameMr : updatedFilters.caste.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.subCaste !== null) {
        //     setSelectedMenu9((authUser.branchMedium == 1) ? updatedFilters.subCaste.nameMr : updatedFilters.subCaste.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.casteCategory !== null) {
        //     setSelectedMenu10((authUser.branchMedium == 1) ? updatedFilters.casteCategory.nameMr : updatedFilters.casteCategory.nameEn + " " + `(${filteredDataCopy.length})`);
        // }

        // if (updatedFilters.minority !== null) {
        //     setSelectedMenu11((authUser.branchMedium == 1) ? updatedFilters.minority.nameMr : updatedFilters.minority.nameEn + " " + `(${filteredDataCopy.length})`);
        // }
        // count end

        setFilteredData(filteredDataCopy);
        setSearchData(filteredDataCopy);

        setSelectedMaster({
            workingStatus: [],
            natureOfAppointment: [],
            branchId: [],
            motherTongueId: [],
            religionId: [],
            casteId: [],
            subCasteId: [],
            categoryId: [],
            minorityId: [],
            gender: [],
            employeeType: []
        })

        setPage(0);

        // Update selected filters state
        //setSelectedFilters(updatedFilters);
    };

    // Helper functions for specific filter logic
    const applyGenderFilter = (data, value) => {
        return value == 1 ? data.filter(e => e.genderId == 1) : data.filter(e => e.genderId == 2);
    };

    const applyEmployeeTypeFilter = (data, value) => {
        return value == 1 ? data.filter(e => e.employeeType == "Teaching") : data.filter(e => e.employeeType == "Non Teaching");
    };

    // const applyEmployeeStatusFilter = (data, value) => {

    //     switch (value) {
    //         case 1:
    //             return data.filter(e => e.workingStatus == 1);
    //         case 2:
    //             return data.filter(e => e.workingStatus == 2);
    //         case 3:
    //             return data.filter(e => e.workingStatus == 3);
    //         case 4:
    //             return data.filter(e => e.workingStatus == 4);
    //         default:
    //             return data;
    //     }
    // };

    // const applyAppointmentNatureFilter = (data, value) => {
    //     switch (value) {
    //         case 1:
    //             return data.filter(e => e.natureOfAppointment == 1);
    //         case 2:
    //             return data.filter(e => e.natureOfAppointment == 2);
    //         case 3:
    //             return data.filter(e => e.natureOfAppointment == 3);
    //         default:
    //             return data;
    //     }
    // };

    // const applySchoolFilter = (data, value) => {
    //     return data.filter(e => e.branchId == value);
    // };

    // const applyMotherTongueFilter = (data, value) => {
    //     return data.filter(e => e.motherTongueId == value);
    // };

    // const applyReligionFilter = (data, value) => {
    //     return data.filter(e => e.religionId == value);
    // };

    // const applyCasteFilter = (data, value) => {
    //     return data.filter(e => e.casteId == value);
    // };

    // const applySubCasteFilter = (data, value) => {
    //     return data.filter(e => e.subCasteId == value);
    // };

    // const applyCasteCategoryFilter = (data, value) => {
    //     return data.filter(e => e.categoryId == value);
    // };

    // const applyMinorityFilter = (data, value) => {
    //     return data.filter(e => e.minorityId == value);
    // };


    useEffect(() => {
        setFilteredData(
            searchData.filter((item) =>
                (item.employeeId || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.employeeNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.employeeNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.branchNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.branchNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.employeeType || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.mobileNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase())
            ))
    }, [searchTerm])


    const UpdateData = (item, EditFlag) => {
        navigate(`/Home/m009F/${item}/${EditFlag}`);
    }

    const showEmployeeDetails = (item, eFlag) => {
        navigate(`/Home/m009F/${item}/${eFlag}`);
    }

    // const handleAccordionShow = (event) => {
    //     const accordionId = event.target.getAttribute('aria-controls');
    //     const otherAccordions = document.querySelectorAll(`.accordion-collapse:not(#${accordionId})`);

    //     otherAccordions.forEach((accordion) => {
    //         const checkboxes = accordion.querySelectorAll('input[type="checkbox"]');
    //         const isAnyChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);

    //         if (!isAnyChecked) {
    //             accordion.classList.remove('show');
    //         }
    //     });
    // };


    // useEffect(() => {
    //     const accordions = document.querySelectorAll('.accordion-collapse');

    //     accordions.forEach(accordion => {
    //         accordion.addEventListener('show.bs.collapse', handleAccordionShow);
    //     });

    //     return () => {
    //         accordions.forEach(accordion => {
    //             accordion.removeEventListener('show.bs.collapse', handleAccordionShow);
    //         });
    //     };
    // }, []);


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />

                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>

                        <div className="mb-4">
                            <div class="accordion" id="accordionFilters">

                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    style={{ backgroundColor: "#29A01E", color: "#fff", padding: '10px' }}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne"
                                                >
                                                    {selectedMenu1}
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingOne"
                                            >
                                                <div className="accordion-body" style={{ height: "150px" }}>
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <span
                                                                className="dropdown-item"
                                                                style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer", marginRight: "10px" }}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    data-dropdown-id={1}
                                                                    onChange={(event) => handleCheckboxChange(1, event, { id: 1 })}
                                                                />
                                                                Male ({maleGenderFilter.length})
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                className="dropdown-item"
                                                                style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer", marginRight: "10px" }}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    data-dropdown-id={1}
                                                                    onChange={(event) => handleCheckboxChange(1, event, { id: 2 })}
                                                                />
                                                                Female ({femaleGenderFilter.length})
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    style={{ backgroundColor: "#F7CF5A", color: "#000", padding: '10px' }}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo"
                                                >
                                                    {selectedMenu2}
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingTwo"
                                            >
                                                <div className="accordion-body" style={{ height: "150px" }}>
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <span
                                                                className="dropdown-item"
                                                                style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    data-dropdown-id={2}
                                                                    onChange={(event) => handleCheckboxChange(2, event, { id: 1 })}
                                                                    style={{ marginRight: "10px" }}
                                                                />
                                                                Teaching ({teachingEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                className="dropdown-item"
                                                                style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    data-dropdown-id={2}
                                                                    onChange={(event) => handleCheckboxChange(2, event, { id: 2 })}
                                                                    style={{ marginRight: "10px" }}
                                                                />
                                                                Non-Teaching ({nonTeachingEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" style={{ backgroundColor: "#F9543A", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    {selectedMenu3}
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree">
                                                <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                    <ul class="list-unstyled">
                                                        <li>
                                                            <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                <input type="checkbox" data-dropdown-id={3} onChange={(event) => handleCheckboxChange(3, event, { id: 1 })} style={{ marginRight: "10px" }} />
                                                                Current ({currentEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                <input type="checkbox" data-dropdown-id={3} onChange={(event) => handleCheckboxChange(3, event, { id: 2 })} style={{ marginRight: "10px" }} />
                                                                Terminate ({terminateEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                <input type="checkbox" data-dropdown-id={3} onChange={(event) => handleCheckboxChange(3, event, { id: 3 })} style={{ marginRight: "10px" }} />
                                                                Voluntary ({voluntaryEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                <input type="checkbox" data-dropdown-id={3} onChange={(event) => handleCheckboxChange(3, event, { id: 4 })} style={{ marginRight: "10px" }} />
                                                                Retired ({retiredEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" style={{ backgroundColor: "#A654BF", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    {selectedMenu4}
                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour">
                                                <div class="accordion-body" style={{ height: "150px" }}>
                                                    <ul class="list-unstyled">
                                                        <li>
                                                            <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                <input type="checkbox" data-dropdown-id={5} onChange={(event) => handleCheckboxChange(5, event, { id: 1 })} style={{ marginRight: "10px" }} />
                                                                Permanent ({permanentEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                <input type="checkbox" data-dropdown-id={5} onChange={(event) => handleCheckboxChange(5, event, { id: 2 })} style={{ marginRight: "10px" }} />
                                                                CHB ({chbEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                <input type="checkbox" data-dropdown-id={5} onChange={(event) => handleCheckboxChange(5, event, { id: 3 })} style={{ marginRight: "10px" }} />
                                                                Special ({specialEmployeeFilter.length})
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    {(() => {
                                        if (authUser.branchId == 50) {
                                            return (
                                                <div className="col-md-3">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingFive">
                                                            <button class="accordion-button collapsed" style={{ backgroundColor: "#FA9932", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                {selectedMenu5}
                                                            </button>
                                                        </h2>
                                                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive">
                                                            <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                                <ul class="list-unstyled">
                                                                    {
                                                                        schoolData.map((e, index) => (
                                                                            <li key={index}>
                                                                                <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                                    <input type="checkbox" data-dropdown-id={6} onChange={(event) => handleCheckboxChange(6, event, e)} style={{ marginRight: "10px" }} />
                                                                                    {(authUser.branchMedium == 1) ? e.schoolNameMr : e.schoolNameEn} ({data.filter(e1 => e1.branchId === e.id).length})
                                                                                </span>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })()}


                                    <div className="col-md-3">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingSix">
                                                <button class="accordion-button collapsed" style={{ backgroundColor: "#334CBD", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    {selectedMenu6}
                                                </button>
                                            </h2>
                                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix">
                                                <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                    <ul class="list-unstyled">
                                                        {
                                                            motherTongueData.map((e, index) => (
                                                                <li key={index}>
                                                                    <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                        <input type="checkbox" data-dropdown-id={7} onChange={(event) => handleCheckboxChange(7, event, e)} style={{ marginRight: "10px" }} />
                                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.motherTongueId === e.id).length})
                                                                    </span>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingSeven">
                                                <button class="accordion-button collapsed" style={{ backgroundColor: "#7033BD", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    {selectedMenu7}
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven">
                                                <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                    <ul class="list-unstyled">
                                                        {
                                                            religionData.map((e, index) => (
                                                                <li key={index}>
                                                                    <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                        <input type="checkbox" data-dropdown-id={8} onChange={(event) => handleCheckboxChange(8, event, e)} style={{ marginRight: "10px" }} />
                                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.religionId === e.id).length})
                                                                    </span>
                                                                </li>
                                                            ))
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingEight">
                                                <button class="accordion-button collapsed" style={{ backgroundColor: "#26B45C", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    {selectedMenu8}
                                                </button>
                                            </h2>
                                            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight">
                                                <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                    <ul class="list-unstyled">
                                                        {
                                                            casteData.map((e, index) => (
                                                                <li key={index}>
                                                                    <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                        <input type="checkbox" data-dropdown-id={9} onChange={(event) => handleCheckboxChange(9, event, e)} style={{ marginRight: "10px" }} />
                                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.casteId === e.id).length})
                                                                    </span>
                                                                </li>
                                                            ))
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3" hidden={(authUser.branchId == 50) ? true : false}>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingNine">
                                                <button class="accordion-button collapsed" style={{ backgroundColor: "#BD3380", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                    {selectedMenu9}
                                                </button>
                                            </h2>
                                            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine">
                                                <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                    <ul class="list-unstyled">
                                                        {
                                                            subCasteData.map((e, index) => (
                                                                <li key={index}>
                                                                    <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                        <input type="checkbox" data-dropdown-id={10} onChange={(event) => handleCheckboxChange(10, event, e)} style={{ marginRight: "10px" }} />
                                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.subCasteId === e.id).length})
                                                                    </span>
                                                                </li>
                                                            ))
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            {/* <div className="btn-group mr-2 mt-2">
                                <button type="button" style={{ backgroundColor: "#29A01E", color: "#fff" }} className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu1}
                                </button>
                                <ul className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input style={{ marginRight: "10px" }} type="checkbox" data-dropdown-id={1} onChange={(event) => handleCheckboxChange(1, event, { id: 1 })} />

                                            Male ({maleGenderFilter.length})

                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input
                                                style={{ marginRight: "10px" }}
                                                type="checkbox"
                                                data-dropdown-id={1}
                                                onChange={(event) => handleCheckboxChange(1, event, { id: 2 })}
                                            />

                                            Female ({femaleGenderFilter.length})

                                        </span>
                                    </li>
                                </ul>
                            </div>

                            <div className="btn-group mr-2 mt-2">
                                <button type="button" style={{ backgroundColor: "#F7CF5A", color: "#000" }} className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu2}
                                </button>
                                <ul className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input style={{ marginRight: "10px" }} type="checkbox" data-dropdown-id={2} onChange={(event) => handleCheckboxChange(2, event, { id: 1 })} />

                                            Teaching ({teachingEmployeeFilter.length})

                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input style={{ marginRight: "10px" }} type="checkbox" data-dropdown-id={2} onChange={(event) => handleCheckboxChange(2, event, { id: 2 })} />

                                            Non-Teaching ({nonTeachingEmployeeFilter.length})

                                        </span>
                                    </li>
                                </ul>
                            </div> */}


                            {/* <div className="btn-group mr-2 mt-2">
                                <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#F9543A", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu3}
                                </button>
                                <ul className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input
                                                type="checkbox"
                                                data-dropdown-id={3}
                                                onChange={(event) => handleCheckboxChange(3, event, { id: 1 })}
                                                style={{ marginRight: "10px" }}
                                            />
                                            Current ({currentEmployeeFilter.length})
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input
                                                type="checkbox"
                                                data-dropdown-id={3}
                                                onChange={(event) => handleCheckboxChange(3, event, { id: 2 })}
                                                style={{ marginRight: "10px" }}
                                            />
                                            Terminate ({terminateEmployeeFilter.length})
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input
                                                type="checkbox"
                                                data-dropdown-id={3}
                                                onChange={(event) => handleCheckboxChange(3, event, { id: 3 })}
                                                style={{ marginRight: "10px" }}
                                            />
                                            Voluntary ({voluntaryEmployeeFilter.length})
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input
                                                type="checkbox"
                                                data-dropdown-id={3}
                                                onChange={(event) => handleCheckboxChange(3, event, { id: 4 })}
                                                style={{ marginRight: "10px" }}
                                            />
                                            Retired ({retiredEmployeeFilter.length})
                                        </span>
                                    </li>
                                </ul>
                            </div> */}

                            {/* <div className="btn-group mt-2 mr-2">
                                <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#A654BF", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu4}
                                </button>
                                <ul className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input
                                                type="checkbox"
                                                data-dropdown-id={5}
                                                onChange={(event) => handleCheckboxChange(5, event, { id: 1 })}
                                                style={{ marginRight: "10px" }}
                                            />
                                            Permanent ({permanentEmployeeFilter.length})
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input
                                                type="checkbox"
                                                data-dropdown-id={5}
                                                onChange={(event) => handleCheckboxChange(5, event, { id: 2 })}
                                                style={{ marginRight: "10px" }}
                                            />
                                            CHB ({chbEmployeeFilter.length})
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                            <input
                                                type="checkbox"
                                                data-dropdown-id={5}
                                                onChange={(event) => handleCheckboxChange(5, event, { id: 3 })}
                                                style={{ marginRight: "10px" }}
                                            />
                                            Special ({specialEmployeeFilter.length})
                                        </span>
                                    </li>
                                </ul>
                            </div> */}


                            {/* {(() => {
                                if (authUser.branchId == 50) {
                                    return (
                                        <>
                                            <div className="btn-group mt-2 mr-2">
                                                <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#FA9932", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                                    {selectedMenu5}
                                                </button>
                                                <ul className="dropdown-menu" style={{ height: "150px", overflowY: "scroll" }} onClick={(e) => e.stopPropagation()}>
                                                    {

                                                        schoolData.map((e, index) => (
                                                            <li key={index}>
                                                                <label className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        data-dropdown-id={6}
                                                                        onChange={(event) => handleCheckboxChange(6, event, e)}
                                                                        style={{ marginRight: "10px" }}
                                                                    />
                                                                    {(authUser.branchMedium == 1) ? e.schoolNameMr : e.schoolNameEn} ({data.filter(e1 => e1.branchId === e.id).length})
                                                                </label>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>


                                        </>
                                    )
                                }
                            })()} */}

                            {/* <div className="btn-group mt-2 mr-2">
                                <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#334CBD", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedMenu6}
                                </button>
                                <ul className="dropdown-menu" style={{ height: "150px", overflowY: "scroll" }} onClick={(e) => e.stopPropagation()}>
                                    {
                                        motherTongueData.map((e, index) => (
                                            <li key={index}>
                                                <label className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                    <input
                                                        type="checkbox"
                                                        data-dropdown-id={7}
                                                        onChange={(event) => handleCheckboxChange(7, event, e)}
                                                        style={{ marginRight: "10px" }}
                                                    />
                                                    {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.motherTongueId === e.id).length})
                                                </label>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div> */}

                            <div className="row">
                                <div className="col-md-3" hidden={(authUser.branchId == 50) ? false : true}>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingNine">
                                            <button class="accordion-button collapsed" style={{ backgroundColor: "#BD3380", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                {selectedMenu9}
                                            </button>
                                        </h2>
                                        <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine">
                                            <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                <ul class="list-unstyled">
                                                    {
                                                        subCasteData.map((e, index) => (
                                                            <li key={index}>
                                                                <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                    <input type="checkbox" data-dropdown-id={10} onChange={(event) => handleCheckboxChange(10, event, e)} style={{ marginRight: "10px" }} />
                                                                    {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.subCasteId === e.id).length})
                                                                </span>
                                                            </li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTen">
                                            <button class="accordion-button collapsed" style={{ backgroundColor: "#BD334C", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                {selectedMenu10}
                                            </button>
                                        </h2>
                                        <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen">
                                            <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                <ul class="list-unstyled">
                                                    {
                                                        casteCategoryData.map((e, index) => (
                                                            <li key={index}>
                                                                <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                    <input type="checkbox" data-dropdown-id={11} onChange={(event) => handleCheckboxChange(11, event, e)} style={{ marginRight: "10px" }} />
                                                                    {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.categoryId === e.id).length})
                                                                </span>
                                                            </li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingEleven">
                                            <button class="accordion-button collapsed" style={{ backgroundColor: "#26B440", color: "#fff", padding: '10px' }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                {selectedMenu11}
                                            </button>
                                        </h2>
                                        <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven">
                                            <div class="accordion-body" style={{ height: "150px", "overflowY": "scroll" }}>
                                                <ul class="list-unstyled">
                                                    {
                                                        minorityData.map((e, index) => (
                                                            <li key={index}>
                                                                <span class="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                                    <input type="checkbox" data-dropdown-id={12} onChange={(event) => handleCheckboxChange(12, event, e)} style={{ marginRight: "10px" }} />
                                                                    {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.minorityId === e.id).length})
                                                                </span>
                                                            </li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="btn-group mr-2">
                                    <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#7033BD", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedMenu7}
                                    </button>
                                    <ul className="dropdown-menu" style={{ height: "150px", overflowY: "scroll" }} onClick={(e) => e.stopPropagation()}>
                                        {
                                            religionData.map((e, index) => (
                                                <li key={index}>
                                                    <label className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                        <input
                                                            type="checkbox"
                                                            data-dropdown-id={8}
                                                            onChange={(event) => handleCheckboxChange(8, event, e)}
                                                            style={{ marginRight: "10px" }}
                                                        />
                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.religionId === e.id).length})
                                                    </label>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                <div className="btn-group mr-2">
                                    <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#26B45C", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedMenu8}
                                    </button>
                                    <ul className="dropdown-menu" style={{ height: "150px", overflowY: "scroll" }} onClick={(e) => e.stopPropagation()}>
                                        {
                                            casteData.map((e, index) => (
                                                <li key={index}>
                                                    <label className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                        <input
                                                            type="checkbox"
                                                            data-dropdown-id={9}
                                                            onChange={(event) => handleCheckboxChange(9, event, e)}
                                                            style={{ marginRight: "10px" }}
                                                        />
                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.casteId === e.id).length})
                                                    </label>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                <div className="btn-group mr-2">
                                    <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#BD3380", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedMenu9}
                                    </button>
                                    <ul className="dropdown-menu" style={{ height: "150px", overflowY: "scroll" }} onClick={(e) => e.stopPropagation()}>
                                        {
                                            subCasteData.map((e, index) => (
                                                <li key={index}>
                                                    <label className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                        <input
                                                            type="checkbox"
                                                            data-dropdown-id={10}
                                                            onChange={(event) => handleCheckboxChange(10, event, e)}
                                                            style={{ marginRight: "10px" }}
                                                        />
                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.subCasteId === e.id).length})
                                                    </label>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div> */}

                                {/* <div className="btn-group mr-2">
                                    <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#BD334C", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedMenu10}
                                    </button>
                                    <ul className="dropdown-menu" style={{ height: "150px", overflowY: "scroll" }} onClick={(e) => e.stopPropagation()}>
                                        {
                                            casteCategoryData.map((e, index) => (
                                                <li key={index}>
                                                    <label className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                        <input
                                                            type="checkbox"
                                                            data-dropdown-id={11}
                                                            onChange={(event) => handleCheckboxChange(11, event, e)}
                                                            style={{ marginRight: "10px" }}
                                                        />
                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.categoryId === e.id).length})
                                                    </label>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                <div className="btn-group mr-2">
                                    <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#26B440", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedMenu11}
                                    </button>
                                    <ul className="dropdown-menu" style={{ height: "150px", overflowY: "scroll" }} onClick={(e) => e.stopPropagation()}>
                                        {
                                            minorityData.map((e, index) => (
                                                <li key={index}>
                                                    <label className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }}>
                                                        <input
                                                            type="checkbox"
                                                            data-dropdown-id={12}
                                                            onChange={(event) => handleCheckboxChange(12, event, e)}
                                                            style={{ marginRight: "10px" }}
                                                        />
                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({data.filter(e1 => e1.minorityId === e.id).length})
                                                    </label>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div> */}

                                <div className="col-sm-3 mt-2">
                                    <button className='btn mr-2' style={{ backgroundColor: "#02C1C1", color: "#fff" }} onClick={() => getFilteredData(4, 1)}>Reset</button>
                                </div>

                            </div>
                        </div>

                        <div className=''>
                            <button className='btn btn-primary btn-sm mb-2 mt-3' style={{ float: 'left' }} onClick={() => navigate("/Home/m009F/0/1")}><AddIcon fontSize="small" />New Employee Entry</button>
                            <button className='btn btn-sm mb-2 mt-3 ml-2' style={{ float: 'left', backgroundColor: '#B41D5D', color: '#fff' }} onClick={() => navigate("/Home/m009sF/")}><PersonIcon fontSize="small" />Set Seniority</button>
                        </div>

                        <div className='row col-sm-4 mb-1 mt-3' style={{ float: 'right' }}>
                            <div>
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                />
                            </div>
                        </div>

                        <div className='table-responsive'>
                            <table className="table table-bordered">
                                <thead className="table-Default">
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy == item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}

                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .reverse().map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr>

                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.employeeId}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{(authUser.branchMedium == 1) ? item.employeeNameMr : item.employeeNameEn}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.mobileNo}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.employeeType}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{(authUser.branchMedium == 1) ? item.branchNameMr : item.branchNameEn}</td>
                                                        <td>
                                                            <Tooltip title="Edit">
                                                                <button className='btn btn-primary btn-sm mx-1 p-1'
                                                                    disabled={disableBtn}
                                                                    onClick={() => UpdateData(item.employeeId, 2)} data-bs-toggle="tooltip" >
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </button>
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            <Tooltip title="View">
                                                                <span className='btn btn-sm mx-1 p-1' style={{ backgroundColor: "#F03B0A", color: "#fff" }}
                                                                    // data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                    onClick={() => showEmployeeDetails(item.employeeId, 3)}
                                                                >
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div >
            }
            {/* <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} /> */}
            {/* <ToastContainer position="top-right" theme="colored" /> */}

        </>
    )

}

export default MasterFormEmployeeTable