import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

// putMapping
////localhost:8080/institute/sss/admissionFormReceive/saveAdmissionFormConfirmationData

//export const saveAdmissionFormConfirmationDataAPIURL = "http://192.168.1.102:8080/institute/sss/admissionFormReceive/saveAdmissionFormConfirmationData";
export const saveAdmissionFormConfirmationDataAPIURL = BaseURL + "admissionFormReceive/saveAdmissionFormConfirmationData";


//localhost:8080/institute/sss/admissionDocument/saveAdmissionDocument?acYear=2021&sid=3&bid=1&formNo=2&documentId=1&file
//export const saveAdmissionDocumentAPIURL = "http://192.168.1.102:8080/institute/sss/admissionDocument/saveAdmissionDocument";
export const saveAdmissionDocumentAPIURL = BaseURL + "admissionDocument/saveAdmissionDocument";


//get document data
//localhost:8080/institute/sss/admissionDocument/getAdmissionDocumentListByFormNo?acYear=2021&sid=3&bid=1&formNo=123
export const getAdmissionDocumentListByFormNo = BaseURL + "admissionDocument/getAdmissionDocumentListByFormNo"
//export const getAdmissionDocumentListByFormNo = "http://192.168.1.102:8080/institute/sss/admissionDocument/getAdmissionDocumentListByFormNo"

//Get uploaded document location

export const getSDocumentAPIURL = BaseIP + "/zData/sDocument/";

// //delete uploaded document
// DeleteMapping 

// localhost:8080/institute/sss/admissionDocument/deleteAdmissionDocumentByDocumentId?acYear=2021&sid=3&bid=1&formNo=123&documentId=1

//export const deleteAdmissionDocumentByDocumentId = "http://192.168.1.102:8080/institute/sss/admissionDocument/deleteAdmissionDocumentByDocumentId";
export const deleteAdmissionDocumentByDocumentId = BaseURL + "admissionDocument/deleteAdmissionDocumentByDocumentId";