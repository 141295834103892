import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



const SansthaDashboardAllStudentCount = ({dataChart}) => {
    const data = {
        labels: ['Total Un-Assigned', 'Total Assigned', 'Total Left'],
        datasets: [
            {
                label: 'Votes',
                data: [dataChart.totalUnAssigned, dataChart.totalAssigned, dataChart.totalLeft],
                backgroundColor: [
                    '#EB984E',
                    '#5DADE2',
                     '#CD6155',
                ],
                borderWidth: 2,
            },
        ],
    };
    return (
        <>
            <div className="col-sm-12" >
                <Doughnut data={data}
                    width={250}
                    height={250}
                    options={{ maintainAspectRatio: false }}
                />
            </div>
        </>
    )
}

export default SansthaDashboardAllStudentCount