import React from 'react';
import { Modal, Button } from "react-bootstrap";

const ConfirmBoxStudentPromotion = (props) => {
    //console.log("Chcked=======" + props.totalSelectedCheckboxes)
    return (
        <>
            {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header mt-3">
                            <h4 className="modal-title" id="exampleModalLabel">Are you sure to promot   {props.totalSelectedCheckboxes}   students from   {props.fromClassName}  to  {props.toClassName}  class?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"  data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={() => props.confirmStudent()} data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div> */}

            <Modal show={props.modal}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to promot   {props.totalSelectedCheckboxes}   students from   {props.fromClassName}  to  {props.toClassName}  class?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setModal(false)}>Close</Button>
                    <Button variant="danger" onClick={() => props.confirmStudent()}>Save</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ConfirmBoxStudentPromotion;
