import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/acMaster/rpMaster/getNegativeBalGroupList?rcHeader=2
export const getNegativeBalGroupAPIURL = BaseURL + "acMaster/rpMaster/getNegativeBalGroupList";

//localhost:8080/institute/sss/acMaster/rpMaster/getReceiptPaymentMasterList?sid=3
export const getReceiptPatmentGroupAPIURL = BaseURL + "acMaster/rpMaster/getReceiptPaymentMasterList";

//localhost:8080/institute/sss/acMaster/rpMaster/saveReceiptPaymentMaster
// {
//     "nameMr": "nameMr",
//     "nameEn": "nameEn",
//     "rcHeader": 1,
//     "negativeBalGroup": null,
//     "index": 1,
//     "sectionId": 3
// }
export const saveReceiptPaymentGroupAPIURL = BaseURL + "acMaster/rpMaster/saveReceiptPaymentMaster";

//localhost:8080/institute/sss/acMaster/rpMaster/updateReceiptPaymentMaster
export const updateReceiptpaymentGroupAPIURL = BaseURL + "acMaster/rpMaster/updateReceiptPaymentMaster";


// /localhost:8080/institute/sss/acMaster/rpMaster/deleteReceiptPaymentMaster?id=1
export const deleteReceiptPaymentGroupAPIURL = BaseURL + "acMaster/rpMaster/deleteReceiptPaymentMaster";