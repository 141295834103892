import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitleLabel from '../../CommonComponent/TitleLabel';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { getBuildingMasterAPIURL } from '../Services/BuildingMasterAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { printVacantBedReportAPIURL } from '../Services/HostelReportAPIURL';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 200,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  }
}));

const VacantBedReport = () => {
  const titleId = 'Vacant Beds Report';
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);
  const [loaderOption, setLoaderOption] = useState(false);

  const [id, setId] = useState('');
  const [option, setOption] = useState(1);
  const [buildingData, setBuildingData] = useState([]);
  const [buildingObj, setBuildingObj] = useState(null);

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    await axios.get(getBuildingMasterAPIURL)
      .then(res => {
        setBuildingData(res.data);
      }).catch(err => {
        console.log("Get building err" + err)
      })
  }

  const getVacantBedReport = async (e) => {
    e.preventDefault();
    setLoaderOption(true);
    //console.log(`${printVacantBedReportAPIURL}?bid=${authUser.branchId}&year=${authUser.sessionYear}&langId=${authUser.branchMedium}&optionId=${option}&buildingId=${(option == 1) ? 0 :buildingObj.id}`)
    await axios.get(`${printVacantBedReportAPIURL}?bid=${authUser.branchId}&year=${authUser.sessionYear}&langId=${authUser.branchMedium}&optionId=${option}&buildingId=${(option == 1) ? 0 : buildingObj.id}`)
      .then(res => {
        if (res.data == "NOTFOUND") {
          setLoaderOption(false);
          toast.warn("Data not found.")
        }
        else {
          window.open(`${getExportedReportAPIURL}${res.data}`);
          setLoaderOption(false);
        }
      }).catch(err => {
        setLoaderOption(false);
        console.log("Print vacant bed report" + err);
        toast.error("Something went wrong, please check.")
      })
  }

  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
              {/* Form Data start */}
              <form onSubmit={getVacantBedReport}>
                <div className="form-data mt-1">
                  <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                  <div className="row">
                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option:</label>
                    <div className='col-sm-3'>
                      <FormControl fullWidth margin='dense'>
                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                        <Select
                          size='small'
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={option}
                          label="Select Option"
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          onChange={(e) => {
                            setOption(e.target.value);
                            setBuildingObj(null);
                          }}
                          onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                        >
                          <MenuItem value={1}>All</MenuItem>
                          <MenuItem value={2}>Building Wise</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {(() => {
                    if (option == 2) {
                      return (
                        <>
                          <div className="row my-2">
                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Building :</label>
                            <div className='col-sm-3'>
                              <Autocomplete
                                id="combo-box-demo"
                                options={buildingData}
                                PaperComponent={({ children }) => (
                                  <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                  setBuildingObj(newValue);
                                }}
                                value={buildingObj}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => option.buildingName}
                                onKeyPress={(e) => (e.key == "Enter") ? buildingObj : ""}
                                size="small"
                                renderInput={params => (
                                  <TextField {...params} margin='dense' label="Select Building" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 2) ? true : false} />
                                )}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }
                  })()}
                </div>

                <div className="row mt-3">
                  <div className="col-sm-4 offset-sm-1">
                    <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                  </div>
                </div>
              </form>
            </div>
          </>
      }
    </>
  )
}

export default VacantBedReport