import { BaseIP, BaseURL } from "../../CommonServices/APIURL";

//Rehistration Approval APIURL
//http://192.168.1.111:8080/institute-sss/institute/sss/mobiapi/registration/registrationListByBranchId?bid=1
export const getStudentRegistrationDataAPIURL = BaseURL + "mobiapi/registration/registrationListByBranchId";

//http://192.168.1.111:8080/institute-sss/institute/sss/administration/studentEntry/checkStudentDataForMobileApp?regNo=3139&sessionYear=2022&cid=5&bid=1
export const setStudentDataBySchoolRegistration = BaseURL + "administration/studentEntry/checkStudentDataForMobileApp";

// /institute/sss/mobiapi/registration/
// PutMapping
// /updateRegUserStatus
// @RequestParam("id")  Long id, @RequestParam("status") long status, @RequestParam("sid") long sectionId
export const updateStatusForStudRegistration = BaseURL + "mobiapi/registration/updateRegUserStatus"

//Notice APIURL
//     / institute / sss / mobiapi / notice /
//     ----Save with file
// @PostMapping /uploadMobileNotice
// @RequestParam("noticefor") long noticeFor,@RequestParam("classOption") long classOption,@RequestParam("classId") long classId,@RequestParam("noticeDate") String noticeDate,@RequestParam("subOption") long subOption,@RequestParam("noticeTitle") String noticeTitle,@RequestParam("file") MultipartFile file, @RequestParam("branchId") long branchId
export const saveNoticeWithFile = BaseURL + "mobiapi/notice/uploadMobileNotice";
// --------------------------------------------------------

// @PostMapping /uploadMobileNoticeWithoutFile
// ----Save without file
// object -
//     private Long id;
//     private Long noticeFor;
//     private Long classOption;
//     private Long classId;
//     private String noticeDate;
//     private Long subOption;
//     private String noticeTitle;
//     private String noticeDesc;
//     private String noticeDoc;
//     private Long noticeStatus;
//     private Long branchId;

export const saveNoticeWithoutFile = BaseURL + "mobiapi/notice/uploadMobileNoticeWithoutFile";
// ---------------------------------------------------------------------
// @GetMapping
// ----get list
//     / getMobileNoticeData
// @RequestParam("bid") long branchId

export const getMobileNoticeDetails = BaseURL + "mobiapi/notice/getMobileNoticeData";

// -----------------------------------------------------------------------
//     PutMapping
// ----update status
//     / updateMobileNoticeStatus
// @RequestParam("statusId") long statusId, @RequestParam("id") long id

export const updateNoticeStatus = BaseURL + "mobiapi/notice/updateMobileNoticeStatus";

// -------------------------------------------------------------------
//     DeleteMapping
// ---- delete data by id
//     / deleteMobileNotice
// @RequestParam("id") long id

export const deleteNoticeDetails = BaseURL + "mobiapi/notice/deleteMobileNotice";
// ------------------------------------------------------------

//file path
export const noticeFilePathAPIURL = BaseIP + "/mNotices/";




//Home work
//localhost:8081/institute-sss/institute/sss/mobiapi/homework/saveMobiHomeWork


export const saveHomeWorkMobileAPIURL = BaseURL + "mobiapi/homework/saveMobiHomeWork";

//localhost:8081/institute-sss/institute/sss/mobiapi/homework/getMobiHomeWorkList?bid=1
export const getHomeWorkMobileAPIURL = BaseURL + "mobiapi/homework/getMobiHomeWorkList";

//localhost:8081/institute-sss/institute/sss/mobiapi/homework/updateMobiHomeWork
export const updateHomeWorkMobileAPIURL = BaseURL + "mobiapi/homework/updateMobiHomeWork";

//localhost:8081/institute-sss/institute/sss/mobiapi/homework/deleteMobiHomeWork?id=1
export const deleteHomeWorkMobileAPIURL = BaseURL + "mobiapi/homework/deleteMobiHomeWork";

//localhost:8081/institute-sss/institute/sss/mobiapi/homework/updateMobiHomeWorkStatus?id=1&status=1
export const updateHomeWorkStatusMobileAPIURL = BaseURL + "mobiapi/homework/updateMobiHomeWorkStatus";



//Event
//localhost:8081/institute-sss/institute/sss/mobiapi/event/saveMobiEvent
export const saveMobileEventAPIURL = BaseURL + "mobiapi/event/saveMobiEvent";

//localhost:8081/institute-sss/institute/sss/mobiapi/event/getMobiEventList?bid=1
export const getMobileEventAPIURL = BaseURL + "mobiapi/event/getMobiEventList";

//localhost:8081/institute-sss/institute/sss/mobiapi/event/updateMobiEvent
export const updateMobileEventAPIURL = BaseURL + "mobiapi/event/updateMobiEvent";

//localhost:8081/institute-sss/institute/sss/mobiapi/event/deleteMobiEvent?id=1
export const deleteMobileEventAPIURL = BaseURL + "mobiapi/event/deleteMobiEvent";

//localhost:8081/institute-sss/institute/sss/mobiapi/event/updateMobiEventStatus?id=1&status=1
export const updateMobileEventStatusAPIURL = BaseURL + "mobiapi/event/updateMobiEventStatus";



