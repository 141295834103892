import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useSelector } from "react-redux";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import {
    GetClassWithDivData, GetSubjectData, saveMonthlyLessonPlanAPIURL, getLessonsByClassAndDivAndSubject,
    getMonthlyLessonsAPIURL, updateMonthlyLessonPlanAPIURL, deleteMonthlyLessonPlanAPIURL
} from '../Services/MonthlyLessonPlanAPIURL'

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const MonthlyLessonPlan = () => {
    const titleId = "Monthly Lesson Plan";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "monthlyLessonSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "monthlyLessonUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState('');
    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [monthWiseDate, setMonthWiseDate] = useState(null);
    //console.log(monthWiseDate);
    const [lessons, setLessons] = useState('');
    const [lessonsCompleted, setLessonsCompleted] = useState('');
    const [lessonId, setLessonId] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [disableFieldsOnUpdate, setDisableFieldsOnUpdate] = useState(false);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        await axios(`${GetClassWithDivData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setDivisionData(res.data);
            })

        await axios(`${GetSubjectData}`)
            .then(res => {
                setSubjectData(res.data);
            })

        await axios.get(`${getMonthlyLessonsAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
            })

    }

    const getLessonByClassDivSubject = async (subId) => {
        if (classDivObj != null) {
            console.log(`${getLessonsByClassAndDivAndSubject}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&subId=${subId}`)
            await axios.get(`${getLessonsByClassAndDivAndSubject}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&subId=${subId}`)
                .then(res => {
                    console.log(res.data);
                    if (res.data == '') {
                        setLessonId('');
                        setLessons('');
                        toast.warn("Lesson for this class-division and subject is not found.")
                    }
                    else {
                        setLessonId(res.data.id);
                        setLessons(res.data.lessonPlan);
                    }

                })
        }
    }

    const clearData = () => {
        setDisableFieldsOnUpdate(false);
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setClassDivObj(null);
        setSubjectObj(null);
        setMonthWiseDate(null);
        setLessons('');
        setLessonsCompleted('');
    }

    const UpdateData = (item) => {
        setDisableFieldsOnUpdate(true);
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        setId(item.id);
        setClassDivObj(divisionData.find(e => e.classId == item.classId && e.divisionId == item.divisionId));
        setSubjectObj(subjectData.find(e => e.id == item.subjectId));
        setMonthWiseDate(item.monthId);
        setLessons(item.lessonPlan);
        setLessonsCompleted(item.lessonComplete);
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        if (moment(monthWiseDate).format("MM/YYYY") != "Invalid date" && lessons != '') {

            const saveObj = {
                "classId": classDivObj.classId,
                "divisionId": classDivObj.divisionId,
                "subjectId": subjectObj.id,
                "lessonPlanId": lessonId,
                "lessonComplete": lessonsCompleted,
                "monthId": moment(monthWiseDate).format("M"),
                "yearId": moment(monthWiseDate).format("YYYY"),
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
            console.log(JSON.stringify(saveObj))
            await axios.post(saveMonthlyLessonPlanAPIURL, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        clearData();
                        getData();
                        setLoaderOption(false);
                        toast.success("Saved Successfully.")
                    }
                }).catch(err => {
                    console.log("Monthly lesson save error:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            if (lessons == '') {
                setLoaderOption(false);
                toast.warn("Lessons field should not be blank.")
            }
            else if (moment(monthWiseDate).format("MM/YYYY") == 'Invalid date') {
                setLoaderOption(false);
                toast.error("Improper date. please check.")
            }
        }
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        if (moment(monthWiseDate).format("MM/YYYY") != 'Invalid date' && lessons != '') {
            const updateObj = {
                "id": id,
                "classId": classDivObj.classId,
                "divisionId": classDivObj.divisionId,
                "subjectId": subjectObj.id,
                "lessonPlanId": lessonId,
                "lessonComplete": lessonsCompleted,
                "monthId": moment(monthWiseDate).format("M"),
                "yearId": moment(monthWiseDate).format("YYYY"),
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
            console.log(JSON.stringify(updateObj))
            await axios.put(updateMonthlyLessonPlanAPIURL, updateObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        clearData();
                        getData();
                        setLoaderOption(false);
                        toast.success("Updated Successfully.")
                    }
                }).catch(err => {
                    console.log("Monthly lesson update error:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            if (lessons == '') {
                setLoaderOption(false);
                toast.warn("Lessons field should not be blank.")
            }
            else if (moment(monthWiseDate).format("MM/YYYY") == 'Invalid date') {
                setLoaderOption(false);
                toast.error("Improper date. please check.")
            }
        }
    }

    const state = {
        button: 1
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    const [deleteId, setDeleteId] = useState('');

    const DeleteData = async (id) => {
        setLoaderOption(true);
        await axios.delete(`${deleteMonthlyLessonPlanAPIURL}?id=${id}`)
            .then(res => {
                if (res.data == "DELETED") {
                    setLoaderOption(false);
                    getData();
                    toast.success("Deleted Successfully.")
                }
            }).catch(err => {
                console.log("Monthly Lesson plan delete error:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.monthlyLessonSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.monthlyLessonUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }
        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item, index) => (
            item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.subjectNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.subjectNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.monthId.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const TableHeading = [
        { label: 'Id', key: 'id' },
        { label: `Class-Division`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
        { label: `Subject`, key: (authUser.branchMedium == 1) ? 'subjectNameMr' : 'subjectNameEn' },
        { label: `Date`, key: 'monthId' },
        { label: `Edit`, key: 'edit', isAction: true },
        { label: 'Delete', key: 'delete', isAction: true }
    ];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>

                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date :</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Month Wise Date"
                                                views={['year', 'month']}
                                                inputFormat="MM/yyyy"
                                                mask="__/____"
                                                disableMaskedInput={false}
                                                value={monthWiseDate}
                                                onChange={(newValue) => {
                                                    //console.log(newValue);
                                                    setMonthWiseDate(newValue);
                                                    setClassDivObj(null);
                                                    setSubjectObj(null);
                                                    setLessons('');
                                                    setLessonsCompleted('');
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        margin="dense"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class-Division :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={divisionData}
                                            style={{ fontSize: '14px' }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            onChange={(event, newValue) => {
                                                setClassDivObj(newValue);
                                                setSubjectObj(null);
                                                setLessons('');
                                                setLessonsCompleted('');
                                            }}
                                            value={classDivObj}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin="dense" label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Subject :</label>
                                    <div className='col-sm-4'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={subjectData}
                                            style={{ fontSize: '14px' }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            onChange={(event, newValue) => {
                                                setSubjectObj(newValue);
                                                setLessons('');
                                                setLessonsCompleted('');
                                                if (newValue != null) {
                                                    getLessonByClassDivSubject(newValue.id);
                                                }
                                            }}
                                            value={subjectObj}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin="dense" label="Select Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Lessons :</label>
                                    <div className='col-sm-8'>
                                        <TextField
                                            required
                                            fullWidth
                                            multiline
                                            rows={2}
                                            autoComplete='off'
                                            size='small'
                                            margin="dense"
                                            id="outlined-basic"
                                            label="Lessons"
                                            variant="outlined"
                                            value={lessons}
                                            onChange={(e) => setLessons(e.target.value)}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}

                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='responsive col-sm-10 offset-sm-2'>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Lessons Completed"
                                            margin="dense"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            value={lessonsCompleted}
                                            onChange={(e) => setLessonsCompleted(e.target.value)}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            style={{ width: '710px' }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type="submit" className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                                        <button type="submit" className={updateBtn} onClick={() => (state.button = 2)} style={{ margin: "0px 4px" }}>Update</button>
                                        <button type="button" className='btn btn-sm btn-danger' onClick={() => clearData()} style={{ margin: "0px 4px" }}>Cancel</button>
                                    </div>
                                </div>

                                <div className='row mt-4 mb-1' style={{ float: 'right' }}>
                                    <div className=''>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className='table-responsive'>
                                        <table className="table table-bordered">
                                            <thead className="table-Default">
                                                <tr>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .reverse().map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>{item.id}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "200px" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.subjectNameMr : item.subjectNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>{moment(item.monthId).format("MM/YYYY")}</td>

                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>
                                                                    <Tooltip title="Edit">
                                                                        <button type='button' className='btn btn-primary btn-sm mx-1 p-1'
                                                                            onClick={() => UpdateData(item)} data-bs-toggle="tooltip"
                                                                        >
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </button>
                                                                    </Tooltip>
                                                                </td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>
                                                                    <Tooltip title="Delete">
                                                                        <button type='button' className='btn btn-danger btn-sm mx-1 p-1'
                                                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                            onClick={() => setDeleteId(item.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </button>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <TablePagination
                                                        style={{ padding: "0px" }}
                                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                                        count={data.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onRowsPerPageChange={onRowsPerPageChange}
                                                        onPageChange={onPageChange}
                                                        labelRowsPerPage="Rows Per Page :"
                                                        classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                        nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }

            <ToastContainer position="top-right" theme="colored" />
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default MonthlyLessonPlan

