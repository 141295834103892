import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//get list
//localhost:8080/institute/sss/hostel/master/getAllRoomMaster
export const getRoomMasterAPIURL = BaseURL + "hostel/master/getAllRoomMaster";

//save
// localhost:8080/institute/sss/hostel/master/saveRoomMaster
// {    
//     "roomNo": "room 2",
//     "buildingId":2
// }
export const saveRoomMasterAPIURL = BaseURL + "hostel/master/saveRoomMaster";

// /update 
// localhost:8080/institute/sss/hostel/master/updateRoomMaster
export const updateRoomMasterAPIURL = BaseURL + "hostel/master/updateRoomMaster";

// /delete
//localhost:8080/institute/sss/hostel/master/deleteRoomMaster?id=1
export const deleteRoomMasterAPIURL = BaseURL + "hostel/master/deleteRoomMaster";