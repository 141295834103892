import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

//Post
// http://192.168.29.102:8081/institute-sss/institute/sss/service/parameterInfo/saveParameterInfoData

// {
//     "description": "Employee Edit",
//     "flag": 1,
//     "roleIds": "1,2",
//     "branchIds": "1,2,3"
// }

// Response :: SAVED
export const saveFormProcessingAPIURL = BaseURL + "service/parameterInfo/saveParameterInfoData";


//Get
//http://192.168.29.102:8081/institute-sss/institute/sss/service/parameterInfo/getParameterInfoList
export const getFormProcessingAPIURL = BaseURL + "service/parameterInfo/getParameterInfoList";

//Put

// http://192.168.29.102:8081/institute-sss/institute/sss/service/parameterInfo/updateParameterInfoData

// {
//     "id": 2,
//     "description": "Employee Delete",
//     "flag": 1,
//     "roleIds": "1,2,3",
//     "branchIds": "1,2,3"
// }

// Response :: UPDATED
export const updateFormProcessingAPIURL = BaseURL + "service/parameterInfo/updateParameterInfoData";
