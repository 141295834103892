import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";


//http://192.168.1.111:8080/institute-sss/institute/sss/administration/getStudentsBankDetails?acyr=2021&sid=3&bid=1&cdid=47
export const getStudentListAccountNo = BaseURL + "administration/getStudentsBankDetails";
//export const getStudentListAccountNo = "http://192.168.1.102:8080/institute/sss/administration/getStudentsBankDetails";

export const saveStudentBankData = BaseURL + "administration/saveSelectedStudentBankData";
//export const saveStudentBankData = "http://192.168.1.102:8080/institute/sss/administration/saveSelectedStudentBankData";

// /saveSelectedStudentBankData

// /institute/sss/administration/

// GetMapping  /getStudentsBankDetails
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cdid") long classDivId


// ----------------------------

//  private long id;
//     private long regCode;
//     private String  studentNameMr;
//     private  long rollNo;
//private String  accountName;
//     private String  bankAccountNo;
//     private String  bankName;
//     private String  branchName;
//     private String  bankIFSCCode;
//     private long sectionId;
//     private long branchId;
//     private long classId;
//     private long divisionId;
