import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";



export const getStudentListByDivClass=BaseURL+"administration/getStudentMinorityAllocationData";

export const saveStudentMinorityByDivClass=BaseURL+"administration/saveStudentMinority";


// /institute/sss/administration/

// Minority get Data

// GetMapping  /getStudentMinorityAllocationData
// -- para 
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cdid") long classDivId

// --------------------------------------------
// PostMapping /saveStudentMinority

// @RequestBody
// private long id;
//     private long regCode;
//     private String  studentNameMr;
//     private String  studentNameEn;
//     private String casteNameMr;
//     private String casteNameEn;
//     private  long rollNo;
//     private  long minorityId;
//     private String minorityNameMr;
//     private String minorityNameEn;
//     private long sessionYear;
//     private long sectionId;
//     private long branchId;
//     private long classId;
//     private long divisionId;

