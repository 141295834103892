import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

export const minorityDataURl= BaseURLMaster + "Minorities/"
export const classDivisionURL= BaseURLMaster + "classWithDivisionBySectionId/";

//AllMinorityWise report
//localhost:8080/institute/sss/administration/reports/generateMinorityWiseStudentReport?langId=1&acYear=2021&sid=3&bid=1
export const allMinorityReport= BaseURL + "administration/reports/generateMinorityWiseStudentReport";

//AllMinorityClassDivisionWise report
//localhost:8080/institute/sss/administration/reports/generateMinorityWiseClassAndDivisionStudentReport?langId=1&acYear=2021&sid=3&bid=1&classId=10&divId=37
export const minorityClassDivisionReport= BaseURL + "administration/reports/generateMinorityWiseClassAndDivisionStudentReport";


//localhost:8080/institute/sss/administration/reports/generateStudentMinorityWiseReport?langId=1&acYear=2021&sid=3&bid=1&minorityId=2
export const minorityWiseReport=BaseURL + "administration/reports/generateStudentMinorityWiseReport";

//localhost:8080/institute/sss/administration/reports/generateClassDivisionAndMinorityWiseReport?langId=1&acYear=2021&sid=3&bid=1&minorityId=2&classId=10&divId=3
export const classDivAndminorityWiseReport=BaseURL + "administration/reports/generateClassDivisionAndMinorityWiseReport";