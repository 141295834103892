const libraryFormLabelName = [
    {
        id: 801,
        title: "Department",
        GetMapping: "getAllDepartmentMasterList",
        PostMapping: "saveDepartmentMaster",
        PutMapping: "updateDepartmentMaster",
        DeleteMapping: "deleteDepartmentMaster",
        GetmappingById: "getDepartmentMasterData"
    },
    {
        id: 802,
        title: "Currency",
        GetMapping: "getAllCurrencyMasterList",
        PostMapping: "saveCurrencyMaster",
        PutMapping: "updateCurrencyMaster",
        DeleteMapping: "deleteCurrencyMaster",
        GetmappingById: "getCurrencyMasterData"
    },
    {
        id: 803,
        title: "Location",
        GetMapping: "getAllLocationMasterList",
        PostMapping: "saveLocationMaster",
        PutMapping: "updateLocationMaster",
        DeleteMapping: "deleteLocationMaster",
        GetmappingById: "getLocationMasterData"
    },
    {
        id: 806,
        title: "Binding",
        GetMapping: "getAllBindingMasterList",
        PostMapping: "saveBindingMaster",
        PutMapping: "updateBindingMaster",
        DeleteMapping: "deleteBindingMaster",
        GetmappingById: "getBindingMasterData"
    },
    {
        id: 810,
        title: "Source",
        GetMapping: "getAllSourceMasterList",
        PostMapping: "saveSourceMaster",
        PutMapping: "updateSourceMaster",
        DeleteMapping: "deleteSourceMaster",
        GetmappingById: "getSourceMasterData"
    },
    {
        id: 905,
        title: "Book-bank Group",
        GetMapping: "getAllBookBankGroup",
        PostMapping: "saveBookBankGroup",
        PutMapping: "updateBookBankGroup",
        DeleteMapping: "deleteBookBankGroup",
        GetmappingById: "getBookBankGroup"
    },

];

export default libraryFormLabelName;