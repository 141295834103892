import React, { useState } from 'react'

const BudgetDetailedEntryInput = ({ initialText1, initialText2, initialText3, initialText4, onChange }) => {

    const txtBox = {
        borderRadius: "5px",
        padding: "4px",
        fontSize: "14px",
        fontWeight: "500",
        //textAlign: "right",
        border: "1px solid #B2ADAD",
        mozAppearance: 'none',

    }

    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    const [text3, setText3] = useState(initialText3);
    const [text4, setText4] = useState(initialText4);

    const getTotalValue = () => {
        let totalBudget = parseFloat(text1) + parseFloat(text2);
        setText4(totalBudget);
    }

    return (
        <>
            <td style={{ width: "130px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => { onChange(text1, text2, text3, text4); getTotalValue(); }}
                    style={txtBox}
                    onChange={(e) => setText1(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4) : ""}
                    value={text1}
                />
            </td>

            <td style={{ width: "130px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => { onChange(text1, text2, text3, text4); getTotalValue(); }}
                    style={txtBox}
                    onChange={(e) => setText2(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4) : ""}
                    value={text2}
                />
            </td>

            <td style={{ fontSize: "14px", fontWeight: "500", width: "120px", textAlign: "right" }}
                //onBlur={() => { onChange(text1, text2, text3, text4); }}
            >{text4}</td>

            <td style={{ width: "130px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => { onChange(text1, text2, text3, text4) }}
                    style={txtBox}
                    onChange={(e) => setText3(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3, text4) : ""}
                    value={text3}
                />
            </td>
        </>
    )
}

export default BudgetDetailedEntryInput