import React from 'react';

const ConfirmBoxLC = (props) => {
    //console.log("Chcked=======" + props.totalSelectedCheckboxes)
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header mt-3">
                            <h4 className="modal-title" id="exampleModalLabel">Are you sure to left   {props.totalSelectedCheckboxes}   students from   {props.classDivName}   class?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"  data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={() => props.confirmBulkLc()} data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default ConfirmBoxLC;
