import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import PrintIcon from '@mui/icons-material/Print';
import MasterReportData from '../LibraryServices/LibraryMasterReportData';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { generateLibraryMasterReport } from '../LibraryServices/LibraryReportServices';


const LibraryMasterReport = () => {
  const titleId = "Master Report";
  const authUser = useSelector((state) => state.user.value);
  const [loaderOption, setLoaderOption] = useState(false);

  const [masterObj, setMasterObj] = useState(null);
  const [id, setId] = useState(null);

  const printLibraryMasterReport = async (e) => {
    e.preventDefault();
    setLoaderOption(true);
    await axios.get(`${generateLibraryMasterReport}?langId=${authUser.branchMedium}&optionId=${masterObj.id}&bid=${authUser.branchId}`)
      .then(res => {
        if (res.data == "NOTFOUND") {
          setLoaderOption(false);
          toast.warn("Data not found.")
        }
        else {
          setLoaderOption(false);
          console.log(res.data);
          window.open(`${getExportedReportAPIURL}${res.data}`)
        }
      }).catch(err => {
        console.log('Library Master Report Error:' + err)
        setLoaderOption(false);
        toast.error("Something went wrong, please check.")
      })
  }


  return (
    <>
      {
        (loaderOption === true) ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
              {/* Form Data start */}
              <form onSubmit={printLibraryMasterReport}>
                <div className="form-data">
                  <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                  <div className="row">
                    <div className="col-sm-4">
                      <Autocomplete
                        id="combo-box-demo"
                        options={MasterReportData}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                        )}
                        style={{ fontSize: '14px' }}
                        getOptionLabel={(option) => option.masterName}
                        size="small"
                        onChange={(event, newValue) => {
                          setMasterObj(newValue);
                        }}
                        onKeyPress={(e) => (e.key == "Enter") ? masterObj : ""}
                        value={masterObj}
                        renderInput={params => (
                          <TextField {...params} margin='dense' label="Select Masters" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                            required />
                        )}
                      />
                    </div>
                  </div>

                  <div className='mt-3'>
                    <button
                      type='submit'
                      className='btn btn-primary btn-sm'
                    >
                      <PrintIcon fontSize="small" /> Print
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
      }
      <ToastContainer position="top-right" theme="colored" />
    </>
  )
}

export default LibraryMasterReport