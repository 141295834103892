import React from "react";
import BrandImg from './branding-img.jpg';
import PurnaSchoolTitle from './Purna-School-Title.jpg';
const BrandingImg=()=>{
    return(
        <>
            <div className="col-sm-12 p-0 m-0">
                <img src={PurnaSchoolTitle} alt="Branding Image" title="" className="img-fluid"/>
            </div>
        </>
    )
}

export default BrandingImg;