import React, { useState, useEffect } from 'react';
import axios from "axios";
import '../../Dashboard.css'
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useSelector } from "react-redux";
import prize from '../../assets/dashboard/prize.png';
import school from '../../assets/dashboard/school.png';
import sanstha from '../../assets/dashboard/sanstha2.png';
import { EndowDashboardData } from './Services/EndowmentMasterAPIURL';


const EndowmentDashboard = () => {
    const authUser = useSelector((state) => state.user.value);

    useEffect(() => {
        getSansthaEndowmentAPIData();
    }, [])


    const [data, setData] = useState([]);

    const getSansthaEndowmentAPIData = async () => {
        //console.log(`${EndowDashboardData}`);
        await axios(`${EndowDashboardData}`)
            .then(res => {
                setData(res.data);
            })
    }


    return (
        <>
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                <div className="row">
                    <div className="col-sm-3">
                        <div className="card en-dash-bg-c1-color order-card-e">
                            <div className="card-block">
                                <span className="text-center" >
                                    <img src={prize} className="f-left mt-2" style={{ width: "80px" }}></img>
                                    <div className='text-right'>
                                        <span className="" style={{ fontSize: "22px", fontWeight: "600" }}>पारितोषिक</span><br />
                                        <span className="" style={{ fontSize: "20px", fontWeight: "600" }}>{data.totalPrizeAmount}</span><br />
                                        <span className="" style={{ fontSize: "20px", fontWeight: "600" }}>{data.totalPrizeCount}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="card en-dash-bg-c2-color order-card-e">
                            <div className="card-block">
                                <span className="text-center">
                                    <img src={sanstha} className="f-left mb-5" style={{ width: "90px" }}></img>
                                    <div className='text-right'>
                                        <span className="" style={{ fontSize: "22px", fontWeight: "600" }}>संस्था</span><br />
                                        <span className="" style={{ fontSize: "20px", fontWeight: "600" }}>{data.sansthaPrizeAmount}</span><br />
                                        <span className="" style={{ fontSize: "20px", fontWeight: "600" }}>{data.sansthaPrizeCount}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="card en-dash-bg-c3-color order-card-e">
                            <div className="card-block">
                                <span className="text-center">
                                    <img src={school} className="f-left mt-2" style={{ width: "100px" }}></img>
                                    <div className='text-right'>
                                        <span className="" style={{ fontSize: "22px", fontWeight: "600" }}>शाळा</span><br />
                                        <span className="" style={{ fontSize: "20px", fontWeight: "600" }}>{data.schoolPrizeAmount}</span><br />
                                        <span className="" style={{ fontSize: "20px", fontWeight: "600" }}>{data.schoolPrizeCount}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* < div className="col-sm-3" >
                        <div className="card en-dash-bg-c1-color order-card-e">
                            <div className="card-block">
                                <h3 className="text-center">Prize</h3>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <span className="f-left">
                                            <h5>Count</h5>
                                            <span className="f-left" style={{ fontSize: "20px", fontWeight: "600" }}>10</span>
                                        </span>

                                        <span className="f-right">
                                            <h5>Amount</h5>
                                            <span className="f-right" style={{ fontSize: "20px", fontWeight: "600" }}>10000</span>
                                        </span>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div >

                    <div className="col-sm-3">
                        <div className="card en-dash-bg-c2-color order-card-e">
                            <div className="card-block">
                                <h3 className="text-center">Sanstha</h3>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <span className="f-left">
                                            <h5>Count</h5>
                                            <span className="f-left" style={{ fontSize: "20px", fontWeight: "600" }}>6</span>
                                        </span>

                                        <span className="f-right">
                                            <h5>Amount</h5>
                                            <span className="f-right" style={{ fontSize: "20px", fontWeight: "600" }}>8000</span>
                                        </span>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="card en-dash-bg-c3-color order-card-e">
                            <div className="card-block">
                                <h3 className="text-center">School</h3>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <span className="f-left">
                                            <h5>Count</h5>
                                            <span className="f-left" style={{ fontSize: "20px", fontWeight: "600" }}>4</span>
                                        </span>

                                        <span className="f-right">
                                            <h5>Amount</h5>
                                            <span className="f-right" style={{ fontSize: "20px", fontWeight: "600" }}>4000</span>
                                        </span>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div> */}
                </div >
            </div >
        </>
    )
}

export default EndowmentDashboard