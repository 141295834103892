import { BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

//localhost:8081/institute-sss/institute/sss/academics/timeTable/getTimeTableData?empId=1&weekDay=1&year=2023&sid=3&bid=1
export const getTimeTableDataAPIURL = BaseURL + "academics/timeTable/getTimeTableData";

//class data
//localhost:8081/institute-sss/institute/sss/master/getEmployeeWiseClassData?empId=25&year=2023&sid=3&bid=1
export const getClassesByEmployeeAPIURL = BaseURLMaster + "getEmployeeWiseClassData";

//div data
//localhost:8081/institute-sss/institute/sss/master/getEmployeeWiseDivisionData?empId=25&year=2023&sid=3&bid=1
export const getDivisionByEmployeeAPIURL = BaseURLMaster + "getEmployeeWiseDivisionData";

//localhost:8081/institute-sss/institute/sss/master/getEmployeeWiseSubjectData?empId=25&classId=5&divId=18&year=2023&sid=3&bid=1
export const getSubjectByEmployeeAPIURL = BaseURLMaster + "getEmployeeWiseSubjectData";

//Post
// localhost:8081/institute-sss/institute/sss/academics/timeTable/saveTimeTableData
// {
//     "classId": 5,
//     "divId": 18,
//     "empId": 25,
//     "subjectId": 7,
//     "weekDay": 1,
//     "timeSlotDetailId": 38,
//     "lectureType": 1,
//     "batchId": 0,
//     "sessionYear": 2023,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveTimeTableDataAPIURL = BaseURL + "academics/timeTable/saveTimeTableData";

//localhost:8081/institute-sss/institute/sss/academics/timeTable/deleteTimeTableData?id=1
export const deleteTimeTableDataAPIURL = BaseURL + "academics/timeTable/deleteTimeTableData";

//localhost:8081/institute-sss/institute/sss/academics/timeTable/updateTimeTableData
export const updateTimeTableDataAPIURL = BaseURL + "academics/timeTable/updateTimeTableData";

//http://192.168.195.102:8081/institute-sss/institute/sss/academics/timeTable/getLectCountByEmpId?empId=25&year=2023&sid=3&bid=1
export const getLecturescountAPIURL = BaseURL + "academics/timeTable/getLectCountByEmpId";




