import { BaseIP, BaseURL, BaseURLMaster , dBaseURL} from "../../CommonServices/APIURL";

export const getSubjectsByExamAllocation = BaseURLMaster + "getSubjectsByExamAllocation";

export const getSubjectExamMarkDataByTerm = BaseURL + "academics/exam/getSubjectExamMarkDataByTerm";

export const getSubExamMarkDataByTermSubjectExam = BaseURL + "academics/exam/getSubExamMarkDataByTermSubjectExam";

// Subject Data API

// /institute/sss/master/

// GetMapping   /getSubjectsByExamAllocation
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cid") long classId, @RequestParam("did") long divisionId, @RequestParam("tid") long termId
// -------------------------------------------------------
// Exam Data API

// /institute/sss/academics/exam/

// GetMapping    /getSubjectExamMarkDataByTerm

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cid") long classId, @RequestParam("did") long divisionId, @RequestParam("tid") long termId, @RequestParam("subjid") long subjectId




// ====================================================

// /institute/sss/academics/exam/

// GetMapping /getSubExamMarkDataByTermSubjectExam


// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cid") long classId, @RequestParam("did") long divisionId, @RequestParam("tid") long termId, @RequestParam("subjid") long subjectId , @RequestParam("eid") long examId


// getMapping

export const getExamMarkEntryDataAllData = BaseURL + "academics/exam/getExamMarkEntryData";

// http://192.168.1.111:8080/institute-sss/institute/sss/academics/exam/getExamMarkEntryData?acyr=2021&sid=3&bid=1&cid=10&did=37&eid=9&tid=1&sbjid=1

export const saveExamMarkEntryData = BaseURL + "academics/exam/saveExamMarkEntryData";

// PostMapping /saveExamMarkEntryData