import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { getClassWithDiv } from '../../Academic/Services/StudentRollNoAPIURL';
import Loader from '../../CommonComponent/Loader';
import StudentsVillageTextBox from './StudentVillageTableRow/StudentsVillageTextBox';
import { getStudentListForVillageUpdation, saveStudentListForVillageUpdation } from '../Services/StudentsVillageAPIURL';



const StudentVillageUpdation = () => {
  const authUser = useSelector((state) => state.user.value);
  const titleId = "Students Village Assignment";

  const [loaderOption, setLoaderOption] = useState(false);

  const TableHeading = [
    { label: 'Reg No', key: 'regCode' },
    { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
    { label: 'Roll No', key: 'rollNo' },
    { label: 'Area', key: 'address', isAction: true },
    { label: `City`, key: 'city', isAction: true }

  ];

  const [classDivObj, setClassDivObj] = useState(null);
  const [divisionData, setDivisionData] = useState([]);


  //console.log("division data: "+JSON.stringify(divisionData));
  const [isAscending, setAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState(null);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");


  const [tempData, setTempData] = useState([]);
  const [regNum, setRegNum] = useState('');

  useEffect(() => {
    if (authUser.branchId !== null || authUser.deptId !== null) {
      getData();
    }

  }, [authUser]);

  const getData = async () => {

    await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setDivisionData(res.data);
      })

  }

  const [filteredData, setFilteredData] = useState([]);
  const onSort = (key, isAction) => {
    if (!isAction) {
      setSortedBy(key)
      if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
      else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
      setAscending(!isAscending)
    }
    setFilteredData(data);
    setData(data);
    getSortedData(data, key, isAction);
  }

  const getSortedData = async (list, key, isAction) => {

    await axios(`${getStudentListForVillageUpdation}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${classDivObj.divisionId}`)
      .then(res => {
        //console.log(res); 
        if (!isAction) {
          setSortedBy(key)
          if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
          else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
          setAscending(!isAscending)
          //console.log("Data============" + JSON.stringify(data))
        }
        setFilteredData([]);
        const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
        //setData(checkedData);
        //console.log("checkedData......" + JSON.stringify(checkedData))
        if (checkedData == true) {
          setFilteredData(data);
        }
      })
  }


  const getStudentList = async (dId) => {
    setLoaderOption(true);
    setData([]);
    setFilteredData([]);
    setTempData([]);
    let link = `${getStudentListForVillageUpdation}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${dId}`;
    //console.log(link);
    axios(`${getStudentListForVillageUpdation}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${dId}`)
      .then(res => {
        //console.log(res.data.length);
        setData(res.data);
        setFilteredData(res.data);
        setTempData(res.data);
        setLoaderOption(false);
      })
  }


  useEffect(() => {
    setFilteredData([])
    let tempFilteredData = data.filter((item) =>
      item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.address.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredData(tempFilteredData)
  }, [searchTerm])

  const [area, setArea] = useState('');
  const [cityName, setCityName] = useState('');
  //console.log(JSON.stringify(tempData))
  useEffect(() => {
    setRegNum('');

    tempData.map((e, index) => {
      if (e.regCode == regNum) {
        tempData[index] = ({ ...e, address: area, city: cityName })
      }

    })
    //console.log(JSON.stringify(tempData))
    setData(tempData);

    if (searchTerm == "") {
      setFilteredData(tempData)
    }
  }, [searchTerm, regNum])



  const saveStudentsVillage = () => {
    //console.log(JSON.stringify(data))
    setLoaderOption(true);
    axios.post(`${saveStudentListForVillageUpdation}`, data)
      .then((response) => {
        if (response.data == "SAVED") {

          setFilteredData([]);
          setData([]);
          setTempData([]);
          setClassDivObj(null);
          setLoaderOption(false);
          toast.success("student Village updation sucessfully done.");
        }

      })
      .catch(error => {
        console.log("Update Fire Query: " + error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveStudentsVillage();
  }

  useEffect(() => {
    function handlekeydownEvent(event) {
      if (event.key === "Enter") {
        if (classDivObj != null || classDivObj != "") {
          console.log('Enter is pressed!');
          event.preventDefault();
          let btn = document.querySelector('.studentVillageBtn')
          if (btn !== null) {
            btn.click();
          }
        }
      }
    }

    document.addEventListener('keypress', handlekeydownEvent)
    return () => {
      document.removeEventListener('keypress', handlekeydownEvent)
    }

  }, []);




  if (loaderOption == true) {
    return <Loader />
  }
  else {
    return (
      <>

        <TitleLabel titleId={titleId} />
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
          <div className='row'>
            <div className='col-sm-4'>
              <Autocomplete
                id="combo-box-demo"
                options={divisionData}
                style={{ fontSize: '14px' }}
                PaperComponent={({ children }) => (
                  <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                )}
                onChange={(event, newValue) => {
                  setClassDivObj(newValue);
                  getStudentList(newValue.divisionId);
                  setSearchTerm("");
                  setAscending(false);
                  setSortedBy(null);
                }}
                value={classDivObj}
                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                size="small"
                renderInput={params => (
                  <TextField {...params} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                )}
              />
            </div>
          </div>

          {
            (data == null || data == "")
              ?
              <h4 className='mt-5'>No Records, Select Class-Division</h4>
              :
              <div>
                <div className='row col-sm-4 my-1' style={{ float: 'right' }}>
                  <div>
                    <input
                      className='form-control form-control-sm'
                      type="text"
                      style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                      placeholder="Search Here"
                      onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                      value={searchTerm}
                    />
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className='table-responsive' style={{ maxHeight: "500px" }}>
                    <table className="table table-bordered ">
                      <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                        <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                          {TableHeading.map((item, index) => {
                            return (
                              <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                        {filteredData
                          .map((item, index) => {
                            return (
                              <tr key={index} style={{ height: "65px" }}>
                                <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.regCode}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.rollNo}</td>

                                <StudentsVillageTextBox initialText1={item.address} initialText2={item.city}
                                  onChange={(value1, value2) => {
                                    let updatedData = filteredData;
                                    updatedData[index] = ({ ...item, address: value1, city: value2 })
                                    setFilteredData(updatedData)
                                    setData(updatedData)
                                    setArea(value1);
                                    setCityName(value2);
                                    setRegNum(updatedData[index].regCode)
                                    //console.log(JSON.stringify(updatedData))
                                  }}
                                />

                              </tr>

                            )
                          })}
                      </tbody>
                      <tfoot style={{ padding: "0px" }}>

                      </tfoot>
                    </table>

                  </div>
                  <div className='mt-4'>
                    <button className='studentVillageBtn btn btn-primary btn-sm mb-2' type='submit' ><AddIcon fontSize="small" />Save Changes</button>
                  </div>
                </form>
              </div>
          }
        </div>
        <ToastContainer position="top-right" theme="colored" />
      </>
    )
  }
}

export default StudentVillageUpdation