import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

//Bed Allocation to student report
//localhost:8080/institute/sss/hostel/transaction/reports/getBedAllocationToStudentReport?bid=36&year=2022&langId=1&classId=10&divId=37&regNo=1069&optionId=1
export const printBedAllocationToStudReportAPIURL = BaseURL + "hostel/transaction/reports/getBedAllocationToStudentReport";


//Vacant bed report APIURL
//localhost:8080/institute/sss/hostel/transaction/reports/getVacantBedListReport?bid=36&year=2022&langId=1&optionId=1&buildingId=0
export const printVacantBedReportAPIURL = BaseURL + "hostel/transaction/reports/getVacantBedListReport";


//Material Report APIURL
//localhost:8080/institute/sss/hostel/transaction/reports/getMaterialAllocationReport?bid=36&year=2022&langId=1&classId=10&divId=37&regNo=1069&optionId=3&materialId=1
export const printMaterialReportAPIURL = BaseURL + "hostel/transaction/reports/getMaterialAllocationReport";


//Medical Visit Report APIURL
//localhost:8080/institute/sss/hostel/transaction/reports/getMedicalVisitReport?langId=1&optionId=1&subOption=3&regNo=1079&dateFrom=2023-01-01&dateTo=2023-03-25&year=2022&bid=36
export const printMedicalVisitReportAPIURL = BaseURL + "hostel/transaction/reports/getMedicalVisitReport";


//Student Leave Report APIURL
//localhost:8080/institute/sss/hostel/transaction/reports/getStudentLeaveReport?langId=1&optionId=3&regNo=1079&year=2022&bid=36&leaveId=0
export const printLeaveReportAPIURL = BaseURL + "hostel/transaction/reports/getStudentLeaveReport";