import {BaseIP, BaseURL, BaseURLMaster} from "../../CommonServices/APIURL";


export const getStudentParentPhoneList=BaseURL+"administration/getStudentParentMobileNoData";


export const saveStudentParentPhone=BaseURL+"administration/saveStudentParentMobileNoData";


// /institute/sss/administration/

// GetMapping   /getStudentParentMobileNoData

// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId, @RequestParam("cdid") long classDivId

// ----------------------------------------------------------

// PostMapping        /saveStudentParentMobileNoData

// Object ---

//  private long id;
//     private long regCode;
//     private String  studentNameMr;
//     private String  studentNameEn;
//     private String fatherMobileNo;
//     private String motherMobileNo;
//     private long sessionYear;
//     private long sectionId;
//     private long branchId;
//     private long classId;
//     private long divisionId;
