import { BaseURL } from "../../CommonServices/APIURL";


//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/saveInvestmentTypeMaster
export const saveTypeMasteAPIURL = BaseURL + "accounts/investment/saveInvestmentTypeMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/updateInvestmentTypeMaster
export const updateTypeMasteAPIURL = BaseURL + "accounts/investment/updateInvestmentTypeMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/getInvestmentTypeMasterList
export const getTypeMasteAPIURL = BaseURL + "accounts/investment/getInvestmentTypeMasterList";

//http://192.168.29.102:8081/institute-sss/institute/sss/accounts/investment/deleteInvestmentTypeMaster?id=1
export const delteTypeMasteAPIURL = BaseURL + "accounts/investment/deleteInvestmentTypeMaster";
