import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from "@mui/material";

const DropdownNavItem = (props) => {
    // console.log(props.DropDownSubMenu)
    return (
        <>
            <li className="nav-item">
                <NavLink to={props.PathName} className="nav-link"><i><Icon style={{ fontSize: "16px", color: "#464de4", verticalAlign: "middle", marginRight: "2px" }}>{props.LinkIcon}</Icon></i><span className="menu-title">{props.Title}</span></NavLink>
                <div className="submenu">
                    <ul className="submenu-item" style={{ minWidth: "250px" }}>
                        {
                            props.DropDownSubMenu.map((item, index) => {
                                return <li className="nav-item" key={index}> <NavLink onClick={() => { props.refreshMenu(); props.handleMenuClick(props.Title, props.PathName) }} className="nav-link" to={item.SubPath}>{item.SubName}</NavLink> </li>
                            })
                        }
                    </ul>
                </div>
            </li>
        </>
    )
}

export default DropdownNavItem;
