import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { postStudentDataEntry, saveStudentDataEntryExcel, saveTempLCDataExcel, updateNameDataEntryExcel } from '../Services/StudentEntryExcelAPIURL';
//APIURL Import
import { BaseURLInstituteMaster, BaseURLSection } from '../../CommonServices/InstituteMasterAPIURL'



const languagesData = [
    {
        id: 1,
        name: "Marathi"
    },
    {
        id: 2,
        name: "English"
    }
]

const StudentEntryExcel = () => {

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Entry Excel Import";

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [schoolData, setSchoolData] = useState([]);
    const [secDept, setSecDept] = useState([]);

    const [responseData, setResponseData] = useState(null);

    const [schoolObj, setSchoolObj] = useState(null);
    const [secDeptObj, setSecDeptObj] = useState(null);
    const [languageObj, setLanguageObj] = useState(null);
    const [file, setFile] = useState();


    //console.log("schoolObj" + JSON.stringify(schoolObj))

    //console.log("secDeptObj" + JSON.stringify(secDeptObj))

    const getInstituteData = async () => {
        await axios.get(BaseURLInstituteMaster)
            .then((response) => {

                setSchoolData(response.data)
            })
            .catch((error) => {
                console.log("School Data Error : " + error)
            })
    }

    const getSecDeptData = async () => {
        await axios.get(BaseURLSection)
            .then((response) => {
                //console.log(JSON.stringify(response.data))
                setSecDept(response.data)
            })
    }

    useEffect(() => {
        getInstituteData();
        getSecDeptData();
    }, [authUser])



    const saveData = async () => {
        setLoaderOption(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("sid", secDeptObj.id);
        formData.append("bid", schoolObj.id)
        formData.append("lid", languageObj.id)

        //console.log(formData)
        //let demoLink = "http://192.168.1.102:8080/institute/excel";


        await axios.post(`${postStudentDataEntry}`, formData)
            .then((response) => {


                setResponseData(response.data);
                setLoaderOption(false);
            })
            .catch((error) => {
                toast(error)
            })
        //console.log(postStudentDataEntry)
    }

    const finalSaveData = async () => {
        setLoaderOption(true);
        //console.log(`${saveStudentDataEntryExcel}?sid=${secDeptObj.id}&bid=${schoolObj.id}&lid=${languageObj.id}`)
        await axios.post(`${saveStudentDataEntryExcel}?sid=${secDeptObj.id}&bid=${schoolObj.id}&lid=${languageObj.id}`)
            .then((response) => {
                if (response.data === "Student data saved") {
                    setLoaderOption(false);
                    toast.success("Saved Sucessfully");
                    //toast(response.data);
                    setResponseData(null);
                    // setSchoolObj(null);
                    // setSecDeptObj(null);
                    // setLanguageObj(null);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong")
                setLoaderOption(false);
                console.log(error)
            })
    }

    const finalLcSave = async () => {
        if (secDeptObj !== null && schoolObj !== null && languageObj !== null) {
            setLoaderOption(true);
            await axios.post(`${saveTempLCDataExcel}?sid=${secDeptObj.id}&bid=${schoolObj.id}`)
                .then((res) => {
                    if (res.data === "Successfully updated LC Data") {
                        setLoaderOption(false);
                        toast.success("LC Data Processed Sucessfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Something went wrong.")
                    }
                })
                .catch((error) => {
                    setLoaderOption(false);
                    console.log(error)
                })
        }
        else {
            toast.warn("Fields not be empty")
        }
    }

    const updateFullName = async () => {
        
        if (secDeptObj !== null && schoolObj !== null && languageObj !== null) {
            setLoaderOption(true);
            await axios.put(`${updateNameDataEntryExcel}?sid=${secDeptObj.id}&bid=${schoolObj.id}&lid=${languageObj.id}`)
                .then((res) => {
                    if (res.data === "Student data saved") {
                        setLoaderOption(false);
                        toast.success("Name Updated Sucessfully.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Something went wrong.");
                    }
                })
                .catch((error) => {
                    setLoaderOption(false);
                    console.log(error);
                })
        }
        else {
            toast.warn("Fields not be empty");
        }
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveData();
        }
        if (state.button === 2) {
            finalLcSave();
        }
    };

    if (loaderOption === true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                    <form onSubmit={onSubmit}>
                        <div className='row'>
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>School Selection :</label>
                            <div className='col-sm-8'>
                                <Autocomplete
                                    id="schoolData"
                                    //disableClearable

                                    options={schoolData}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}

                                    onChange={(event, item) => {
                                        setSchoolObj(item)
                                    }}
                                    value={schoolObj}

                                    getOptionLabel={option => option.schoolNameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" inputProps={{ ...params.inputProps, autoComplete: "off" }} label="School Selection" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />

                            </div>
                        </div>

                        <div className='row'>
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Section Department :</label>
                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="sectiomData"
                                    //disableClearable

                                    options={secDept}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}

                                    onChange={(event, item) => {
                                        setSecDeptObj(item)
                                    }}
                                    value={secDeptObj}

                                    getOptionLabel={option => option.nameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Section Department" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />

                            </div>

                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Section Language :</label>
                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="langData"
                                    //disableClearable

                                    options={languagesData}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}

                                    onChange={(event, item) => {
                                        setLanguageObj(item)
                                    }}
                                    value={languageObj}

                                    getOptionLabel={option => option.name}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Section Language" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />

                            </div>


                        </div>

                        <div className='row'>
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Select Excel :</label>
                            <div className='col-sm-3'>
                                <input type="file" onChange={(e) => setFile(e.target.files[0])} className='form-control form-control-sm mt-2' name="excelFile" id="excelFile" accept=".xls, .xlsx" required />

                            </div>

                        </div>

                        <div className="row mt-2">
                            <div className="col-sm-2 offset-sm-2">
                                <button type='submit' onClick={() => (state.button = 1)} className='btn btn-primary' style={{ maxWidth: "100px" }} >UPLOAD</button>
                            </div>

                            <div className="col-sm-3">
                                <button type='button' onClick={() => updateFullName()} style={{ backgroundColor: "#4ED859", color: "#292827" }} className='btn' >Update Full Name</button>
                            </div>

                            <div className="col-sm-2">
                                <button type='button' onClick={() => finalLcSave()} className='btn' style={{ maxWidth: "180px", backgroundColor: "#FFBE12", color: "#292827" }} >Final LC Process</button>
                            </div>
                        </div>
                    </form>
                    <hr />
                    {
                        (!responseData) ? <h1 style={{ color: "#D5D8DC", fontSize: "80px", textAlign: "center", userSelect: "none" }}>No Data</h1> :

                            <div className="row mt-4">
                                <h4>Uploading Data</h4>
                                <div className="table-responsive" style={{ maxHeight: "260px" }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Reg No</th>
                                                <th>Name</th>
                                                <th>Gender</th>
                                                <th>Std</th>
                                                <th>Division</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                responseData.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.regNo}</td>
                                                            <td>{item.fname + " " + item.mname + " " + item.lname}</td>
                                                            <td>{item.sex}</td>
                                                            <td>{item.std}</td>
                                                            <td>{item.division}</td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-sm-4">
                                        <button type='button' onClick={() => finalSaveData()} className='btn' style={{ maxWidth: "200px", fontWeight: "500", color: "#fff", backgroundColor: "#96c8fb", backgroundImage: "linear-gradient(315deg, #96c8fb 0%, #ddbdfc 74%)", color: "#292827" }} >SAVE STUDENT DATA</button>
                                    </div>                                    
                                </div>
                            </div>
                    }


                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default StudentEntryExcel


