import React, { useState } from 'react'
import { getTTFB } from 'web-vitals';

const BudgetAssetEntryInput = ({ initialText1, initialText2, initialText3, onChange }) => {
    const txtBox = {
        borderRadius: "5px",
        padding: "4px",
        fontSize: "14px",
        fontWeight: "500",
        //textAlign: "right",
        border: "1px solid #B2ADAD",
        mozAppearance: 'none',

    }

    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText2);
    const [text3, setText3] = useState(initialText3);

    const getTotalValue = () => {
        let total = parseFloat(text1) * parseFloat(text2)
        setText3(total.toFixed('2'));
    }

    return (
        <>
            <td style={{ width: "160px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={(e) => { onChange(text1, text2, text3); getTotalValue() }}
                    style={txtBox}
                    onChange={(e) => { setText1(e.target.value) }}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                    value={text1}
                />
            </td>

            <td style={{ width: "160px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={(e) => { onChange(text1, text2, text3); getTotalValue() }}
                    style={txtBox}
                    onChange={(e) => setText2(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                    value={text2}
                />
            </td>

            <td style={{ width: "160px", height: "70px", }} className='p-2'>
                <input
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    className='form-control form-control-sm no-spinner'
                    //style={{ width: "170px", fontWeight: "500", fontSize: "14px" }}
                    //onFocus={() => setEdit1(true)}
                    onBlur={() => { onChange(text1, text2, text3) }}
                    style={txtBox}
                    onChange={(e) => setText3(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text1, text2, text3) : ""}
                    value={text3}
                />
            </td>
        </>
    )
}

export default BudgetAssetEntryInput