import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import makeStyles from "@mui/styles/makeStyles";
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import { useNavigate } from 'react-router-dom';
import { getMobileNoticeDetails, saveNoticeWithFile, saveNoticeWithoutFile } from '../Services/MobileAppAllAPI';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    },
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
});

const Notice = () => {
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);
    const [titleId, setTitleId] = useState('Notice');
    const navigate = useNavigate();

    useEffect(() => {
        getApiData();
    }, [authUser])

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "mobileNoticeSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "mobileNoticeUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const classes = useStyles();

    const [id, setId] = useState(null);
    const [dateValue, setDateValue] = useState(null);
    const [noticeTitle, setNoticeTitle] = useState("");
    const [noticeOption, setNoticeOption] = useState(1);
    const [file, setFile] = useState(null);
    const [desce, setDesce] = useState("");
    const [noticeFor, setNoticeFor] = useState('');
    const [option, setOption] = useState('');
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);


    const getApiData = async () => {
        if (authUser.branchId != null && authUser.deptId != null) {
            await axios.get(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                })
        }

        // await axios.get(`${getMobileNoticeDetails}?bid=${authUser.branchId}`)
        //     .then(res => {
        //         setData(res.data);
        //         setFilteredData(res.data);
        //         setLoaderOption(false);
        //     })
    }

    const clearData = () => {       
        navigate(`/Home/ma1002/`)
    }

    const saveBtnClick = async () => {
        //setLoaderOption(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("noticeDate", moment(dateValue).format("DD/MM/YYYY"))
        formData.append("subOption", option)
        formData.append("noticefor", noticeFor)
        formData.append("noticeTitle", noticeTitle)
        formData.append("classOption", noticeOption)
        formData.append("classId", (classObj != null) ? classObj.id : 0)
        formData.append("branchId", authUser.branchId)

        const saveObj = {
            id: id,
            noticeFor: noticeFor,
            classOption: noticeOption,
            classId: (classObj != null) ? classObj.id : 0,
            noticeDate: moment(dateValue).format("DD/MM/YYYY"),
            subOption: option,
            noticeTitle: noticeTitle,
            noticeDesc: desce,
            noticeDoc: null,
            noticeStatus: 0,
            branchId: authUser.branchId,
        }

        if (option == 2) {
            console.log(Object.fromEntries(formData))

            if (moment(dateValue).format("DD/MM/YYYY") != 'Invalid date') {
                await axios.post(saveNoticeWithFile, formData)
                    .then(res => {
                        if (res.data == "SAVED") {
                            getApiData();
                            clearData();
                            setLoaderOption(false);
                            toast.success("Saved successfully.")
                        }
                    }).catch(err => {
                        console.log('mobole app notice error:' + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else {
                setLoaderOption(false);
                toast.error("Improper date, please check.");
            }
        }
        else if (option == 1) {
            console.log(JSON.stringify(saveObj))
            if (moment(dateValue).format("DD/MM/YYYY") != 'Invalid date') {
                await axios.post(saveNoticeWithoutFile, saveObj)
                    .then(res => {
                        if (res.data == "SAVED") {
                            getApiData();
                            clearData();
                            setLoaderOption(false);
                            toast.success("Saved successfully.")
                        }
                    }).catch(err => {
                        console.log('mobole app notice error:' + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else {
                setLoaderOption(false);
                toast.error("Improper date, please check.");
            }
        }

    }

    const state = {
        button: 1
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
    }

    // useEffect(() => {

    //     function handlekeydownEvent(event) {
    //         if (event.key === "Enter") {
    //             console.log("Enter is pressed!")
    //             event.preventDefault();
    //             let btn1 = document.querySelector('.mobileNoticeSaveBtn')
    //             if (btn1 !== null) {
    //                 btn1.click();
    //             }
    //         }
    //     }

    //     document.addEventListener('keypress', handlekeydownEvent)
    //     return () => {
    //         document.removeEventListener('keypress', handlekeydownEvent)
    //     }

    // }, []);



    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data">

                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                    <div className="row">
                                        <label className="" style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Notice For :</label>
                                        <div className='col-sm-3' >
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Notice For</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    fullWidth
                                                    required                                                    
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={noticeFor}
                                                    label="Notice For"
                                                    onChange={(e) => {
                                                        setNoticeFor(e.target.value);
                                                        setDateValue(null);
                                                        setNoticeTitle("");
                                                        setFile(null);
                                                        setDesce("");
                                                        setOption('');
                                                        setNoticeOption(1);
                                                        setClassObj(null);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? noticeFor : ''}
                                                >
                                                    <MenuItem value={1}>Employee</MenuItem>
                                                    <MenuItem value={2}>Student</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2" hidden={(noticeFor == 1 || noticeFor == '') ? true : false}>
                                        <label className="" style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                        <div className='col-sm-3' >
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    fullWidth
                                                    margin="dense"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={noticeOption}
                                                    label="Option"
                                                    onChange={(e) => {
                                                        setNoticeOption(e.target.value);
                                                        setDateValue(null);
                                                        setNoticeTitle("");
                                                        setFile(null);
                                                        setDesce("");
                                                        setOption('');
                                                        setClassObj(null);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? noticeOption : ''}
                                                >
                                                    <MenuItem value={1}>All</MenuItem>
                                                    <MenuItem value={2}>Class Wise</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                        {(() => {
                                            if (noticeOption == 2) {
                                                return (
                                                    <>
                                                        <label className="" style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                                                        <div className='col-sm-3'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                options={classData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    setClassObj(newValue);
                                                                    setDateValue(null);
                                                                    setNoticeTitle("");
                                                                    setFile(null);
                                                                    setDesce("");
                                                                    setOption('');
                                                                }}
                                                                value={classObj}
                                                                onKeyPress={(e) => (e.key == "Enter") ? classObj : ''}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                size="small"
                                                                renderInput={params => (
                                                                    <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                                )}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </div>

                                    <div className="row my-2">
                                        <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date"
                                                    value={dateValue}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateValue(newValue);
                                                        setNoticeTitle("");
                                                        setFile(null);
                                                        setDesce("");
                                                        setOption('');
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? dateValue : ''}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label className="" style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Sub-option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Select Sub-option</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.select } }}
                                                    size='small'
                                                    fullWidth
                                                    margin="dense"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Select Sub-option"
                                                    onChange={(e) => {
                                                        setOption(e.target.value);
                                                        setFile(null);
                                                        setDesce("");
                                                        setNoticeTitle('');
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? option : ''}
                                                >
                                                    <MenuItem value={1}>Text Details</MenuItem>
                                                    <MenuItem value={2}>Document</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Notice Title :</label>
                                        <div className='' style={{ width: "675px" }}>
                                            <TextField
                                                fullWidth
                                                required
                                                value={noticeTitle}
                                                onChange={(e) => setNoticeTitle(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? noticeTitle : ''}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Notice Title"
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        {(() => {
                                            if (option == 1) {
                                                return (
                                                    <>
                                                        <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Details:</label>
                                                        <div className='' style={{ width: "675px" }}>
                                                            <TextField
                                                                fullWidth
                                                                value={desce}
                                                                onChange={(e) => setDesce(e.target.value)}
                                                                onKeyPress={(e) => (e.key == "Enter") ? desce : ''}
                                                                multiline
                                                                rows={4}
                                                                autoComplete='off'
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Details"
                                                                variant="outlined"
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            else if (option == 2) {
                                                return (
                                                    <>
                                                        <label className="" style={{ width: "120px", fontSize: "15px", fontWeight: "600", marginTop: "12px" }} >Document :</label>
                                                        <div className='col-sm-3 mt-2'>
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                onChange={(e) => setFile(e.target.files[0])}
                                                                className='form-control form-control-sm'
                                                                accept='.pdf'
                                                                required
                                                                onKeyPress={(e) => (e.key == "Enter") ? setFile(e.target.files[0]) : ''}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-sm-4" style={{ marginLeft: "120px" }}>
                                            <button type="submit" onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                            <button type="button" className="btn btn-danger btn-sm mx-1" onClick={() => clearData()}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {/* form data ends */}
                        </div>
                    </>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default Notice